import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import {
  ADD_INVENTORY_KEYBOARD_ITEMS,
  ADD_INVENTRY_COLUMN_ITEMS,
  initialKeyboardField,
  performerFilters,
} from "../../../helpers/const";
import { Event, Performer } from "./_models";

export interface LayoutContextModel {
  searchFieldValue: any;
  setSearchFieldValue: (data: any) => void;
  events: Array<Event>;
  setEvents: (data: any) => void;
  performer: Array<Performer>;
  setPerformer: (data: any) => void;

  tempPerformer: any;
  setTempPerformer: (data: any) => void;

  // Performer Pagination Data
  performerPaginationData: any;
  setperformerPaginationData: (data: any) => void;

  // Performer Keyboard field values
  performerKeyboardField: any;
  setPerformerKeyboardField: (data: any) => void;

  keyboardField: any;
  setKeyboardField: (data: any) => void;
  ticketType: any;
  setTicketType: (data: any) => void;
  deliveryType: any;
  setDeliveryType: (data: any) => void;
  splitType: any;
  setSplitType: (data: any) => void;
  benefits: any;
  setBenefits: (data: any) => void;
  restrictions: any;
  setRestrictions: (data: any) => void;
  currencyRates: any;
  setCurrencyRates: (data: any) => void;

  addAllEvents: boolean;
  setAddAllEvents: (data: boolean) => void;

  triggered: boolean;
  setTriggered: (data: boolean) => void;

  // Searching events
  eventOrPerformer: string;
  setEventOrPerformer: (data: any) => void;

  //use: Edit
  isTableEditable: [];
  setIsTableEditable: (data: any) => void;
  isPublish: boolean;
  setIsPublish: (data: boolean) => void;
  tableDataEditChanging: any;
  setTableDataEditChanging: (data: any) => void;
  countSelectedEdit: any;
  setCountSelectedEdit: (data: any) => void;
  getselectedCount: any;
  setGetSelectedCount: (data: any) => void;
  errorShowInPublish: boolean;
  setErrorShowInPublish: (data: any) => void;

  singleCollapse: boolean;
  setSingleCollapse: (data: any) => void;

  tempEvent: any;
  setTempEvent: (data: any) => void;

  //delet event record
  confirmRowDelete: boolean;
  setConfirmRowDelete: (data: any) => void;
  eTicketDataField: any;
  setETicketDataField: (data: any) => void;

  keyboardItems: Array<any>;
  setKeyboardItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  isDeleteActive: boolean;
  SetIsDeleteActive: (data: any) => void;

  isOpen: boolean;
  SetIsEventOpen: (data: any) => void;

  virtuosoRecordIndex: any;
  setVirtuosoRecordIndex: (data: any) => void;

  mobileEditView: any;
  setMobileEditView: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  draggedColumns: Array<any>;
  setDraggedColumns: (data: any) => void;

  proceedPriceToggle: any;
  setProceedPriceToggle: (data: any) => void;
}

const AddInventoryContext = createContext<LayoutContextModel>({
  performerPaginationData: {},
  setperformerPaginationData: (data: any) => {},

  triggered: false,
  setTriggered: (data: any) => {},

  searchFieldValue: {},
  setSearchFieldValue: (data: string) => {},

  tempPerformer: [],
  setTempPerformer: (data: any) => {},

  keyboardField: {},
  setKeyboardField: (data: string) => {},
  performerKeyboardField: [],
  setPerformerKeyboardField: (data: string) => {},
  ticketType: [],
  setTicketType: (data: string) => {},
  deliveryType: [],
  setDeliveryType: (data: string) => {},
  splitType: [],
  setSplitType: (data: string) => {},
  benefits: [],
  setBenefits: (data: string) => {},
  restrictions: [],
  setRestrictions: (data: string) => {},
  currencyRates: [],
  setCurrencyRates: (data: any) => {},
  events: [],
  setEvents: (data: any) => {},
  performer: [],
  setPerformer: (data: any) => {},
  addAllEvents: false,
  setAddAllEvents: (data: boolean) => {},

  // Buttons state
  eventOrPerformer: "",
  setEventOrPerformer: (data: string) => {},

  isTableEditable: [],
  setIsTableEditable: (data: any) => {},
  isPublish: false,
  setIsPublish: (data: boolean) => {},

  tableDataEditChanging: [],
  setTableDataEditChanging: (data: string) => {},
  countSelectedEdit: [],
  setCountSelectedEdit: (data: any) => {},
  getselectedCount: "",
  setGetSelectedCount: (data: any) => {},
  errorShowInPublish: false,
  setErrorShowInPublish: (data: any) => {},

  //delete event record
  confirmRowDelete: false,
  setConfirmRowDelete: (data: any) => {},

  singleCollapse: false,
  setSingleCollapse: (data: any) => {},
  eTicketDataField: {},
  setETicketDataField: (data: any) => {},

  tempEvent: [],
  setTempEvent: (data: any) => {},

  keyboardItems: [],
  setKeyboardItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  isDeleteActive: false,
  SetIsDeleteActive: (data: any) => {},

  isOpen: false,
  SetIsEventOpen: (data: any) => {},

  virtuosoRecordIndex: {},
  setVirtuosoRecordIndex: (data: any) => {},

  mobileEditView: {},
  setMobileEditView: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  draggedColumns: [],
  setDraggedColumns: (data: any) => {},

  proceedPriceToggle: false,
  setProceedPriceToggle: (data: any) => {},
});

const AddInventoryProvider = ({ children }: PropsWithChildren) => {
  const globalLayout = useContext(LayoutContext);
  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveKeyboardItems;

  // COLUMN FILTER - CONDITION
  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveAddInventoryTableColumn;

  const [searchFieldValue, setSearchFieldValue] = useState<any>("");
  const [isDeleteActive, SetIsDeleteActive] = useState<boolean>(false);
  const [triggered, setTriggered] = useState<boolean>(false);
  const [isOpen, SetIsEventOpen] = useState<boolean>(false);

  const [performerPaginationData, setperformerPaginationData] = useState({});
  const [performerKeyboardField, setPerformerKeyboardField] =
    useState<any>(performerFilters);
  const [ticketType, setTicketType] = useState([]);
  const [deliveryType, setDeliveryType] = useState([]);
  const [splitType, setSplitType] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [tempPerformer, setTempPerformer] = useState<any>([]);
  const [currencyRates, setCurrencyRates] = useState([]);
  const [events, setEvents] = useState(() => {
    // GET EVENT TABLE DATA FROM LOCAL STORATE
    const saved: any = localStorage.getItem("events");
    return JSON.parse(saved) || [];
  });
  const [performer, setPerformer] = useState([]);
  const [addAllEvents, setAddAllEvents] = useState(false);

  const [isTableEditable, setIsTableEditable] = useState<[]>([]);
  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [tableDataEditChanging, setTableDataEditChanging] = useState([]);
  const [countSelectedEdit, setCountSelectedEdit] = useState([]);
  const [eventOrPerformer, setEventOrPerformer] = useState("Event");
  const [getselectedCount, setGetSelectedCount] = useState<boolean>(false);
  const [errorShowInPublish, setErrorShowInPublish] = useState<boolean>(false);
  const [confirmRowDelete, setConfirmRowDelete] = useState<boolean>(false);
  const [singleCollapse, setSingleCollapse] = useState<boolean>(false);
  const [keyboardField, setKeyboardField] = useState<any>(initialKeyboardField);
  const [eTicketDataField, setETicketDataField] = useState<any>({
    btnEvent: {},
    isOpen: false,
  });
  const [tempEvent, setTempEvent] = useState([]);
  const [keyboardItems, setKeyboardItems] = useState(() => {
    // GET DRAGGABLE KEYBOARD ITEMS DATA FROM LOCAL STORAGE
    // const saved: any = localStorage.getItem("saveKeyboardItems");
    // return JSON.parse(saved) || ADD_INVENTORY_KEYBOARD_ITEMS;

    return getHeaderFilter(
      "saveSalesFilter",
      isFilterExistInDB,
      ADD_INVENTORY_KEYBOARD_ITEMS
    );
  });
  const [draggedItems, setDraggedItems] = useState(keyboardItems);
  const [virtuosoRecordIndex, setVirtuosoRecordIndex] = useState<any>({});
  const [mobileEditView, setMobileEditView] = useState<any>({
    show: false,
    eventIndex: undefined,
    recordIndex: undefined,
  });

  const [columnItems, setColumnItems] = useState<any>(() => {
    return getHeaderFilter(
      "",
      isColumnFilterExistInDB,
      ADD_INVENTRY_COLUMN_ITEMS
    );
  });

  const [draggedColumns, setDraggedColumns] = useState([]);
  const [proceedPriceToggle, setProceedPriceToggle] = useState<any>(false);

  const value: LayoutContextModel = React.useMemo(
    () => ({
      events,
      setEvents,
      performer,
      setPerformer,
      ticketType,
      setTicketType,
      deliveryType,
      setDeliveryType,
      splitType,
      setSplitType,
      benefits,
      setBenefits,
      restrictions,
      setRestrictions,
      currencyRates,
      setCurrencyRates,
      addAllEvents,
      setAddAllEvents,

      isTableEditable,
      setIsTableEditable,
      isPublish,
      setIsPublish,
      tableDataEditChanging,
      setTableDataEditChanging,
      countSelectedEdit,
      setCountSelectedEdit,
      eventOrPerformer,
      setEventOrPerformer,
      searchFieldValue,
      setSearchFieldValue,
      performerKeyboardField,
      setPerformerKeyboardField,
      getselectedCount,
      setGetSelectedCount,
      errorShowInPublish,
      setErrorShowInPublish,

      confirmRowDelete,
      setConfirmRowDelete,

      singleCollapse,
      setSingleCollapse,

      eTicketDataField,
      setETicketDataField,

      performerPaginationData,
      setperformerPaginationData,

      tempEvent,
      setTempEvent,

      keyboardItems,
      setKeyboardItems,

      draggedItems,
      setDraggedItems,
      keyboardField,
      setKeyboardField,
      tempPerformer,
      setTempPerformer,
      isDeleteActive,
      SetIsDeleteActive,
      triggered,
      setTriggered,
      isOpen,
      SetIsEventOpen,
      virtuosoRecordIndex,
      setVirtuosoRecordIndex,
      mobileEditView,
      setMobileEditView,

      columnItems,
      setColumnItems,

      draggedColumns,
      setDraggedColumns,

      proceedPriceToggle,
      setProceedPriceToggle,
    }),
    [
      events,
      performer,
      ticketType,
      deliveryType,
      splitType,
      benefits,
      restrictions,
      currencyRates,
      addAllEvents,
      isTableEditable,
      isPublish,
      tableDataEditChanging,
      countSelectedEdit,
      eventOrPerformer,
      searchFieldValue,
      performerKeyboardField,
      getselectedCount,
      errorShowInPublish,
      confirmRowDelete,
      singleCollapse,
      eTicketDataField,
      performerPaginationData,
      tempEvent,
      keyboardItems,
      draggedItems,
      keyboardField,
      tempPerformer,
      isDeleteActive,
      triggered,
      isOpen,
      virtuosoRecordIndex,
      mobileEditView,
      columnItems,
      setColumnItems,
      proceedPriceToggle,
      setProceedPriceToggle,
    ]
  );

  return (
    <AddInventoryContext.Provider value={value}>
      {children}
    </AddInventoryContext.Provider>
  );
};

export { AddInventoryContext, AddInventoryProvider };
