import { AddIcon } from "assets/media/icons/other_icons/AddIcon";
import { StyledButton } from "components/ui/buttons/StyledButton/StyledButton";
import { IKTSVG } from "components/ui/IKTSVG";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import PlusIcon from "../../../../../assets/media/icons/other_icons/plus.svg";
import CrossIcon from "../../../../../assets/media/icons/standard_icons/cross.svg";
import {
  addNestedCategory,
  handleCategoryToggle,
  updateCategory,
} from "../util";
import { AlgoliaWithDeleteButton } from "./AlgoliaWithDeleteButton";
import { SubCategoryForm } from "./SubcategoryForm/SubCategoryForm";

export const EditRecordForm = ({ categories, setCategories }: any) => {
  const [openModalIndex, setOpenModalIndex] = useState(null);

  return (
    <div className="right_body border-r lg2:w-1/2 w-full xl:h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5">
      <div className="fieldset flex flex-wrap -mx-2.5">
        {categories.map(
          (item: any, index: any) =>
            !item?.is_deleted && (
              <div className="w-full mb-4 px-2.5" key={index}>
                <div className="flex gap-4">
                  <AlgoliaWithDeleteButton
                    id={`menu-${index}`}
                    item={item}
                    handleHitSelect={(data: any) =>
                      updateCategory(setCategories, index, data)
                    }
                    toggleEdit={() =>
                      updateCategory(setCategories, index, {
                        isEditable: !item?.isEditable,
                      })
                    }
                    onChange={(e: any) =>
                      updateCategory(setCategories, index, {
                        name: e.target.value,
                      })
                    }
                    deleteItem={() =>
                      updateCategory(setCategories, index, {
                        is_deleted: true,
                      })
                    }
                  />
                  <StyledButton
                    data-te-collapse-init
                    aria-expanded={openModalIndex === index ? "true" : "false"}
                    isDisabled={!item?.menuId}
                    onClick={handleCategoryToggle(index, setOpenModalIndex)}
                    className="min-w-min"
                    id={`record-plus-${index}`}
                  >
                    <Tooltip
                      anchorId={`record-plus-${index}`}
                      content={
                        openModalIndex === index ? "Close menu" : "Add menu"
                      }
                      place={`top`}
                      variant="light"
                      className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 mt-1.5`}
                    />
                    <IKTSVG
                      className={`${
                        !item?.menuId ? "fill-gray-400" : "fill-violet-500"
                      } flex items-center justify-center group-hover:fill-white transition`}
                      svgClassName="w-3 h-3"
                      path={openModalIndex === index ? CrossIcon : PlusIcon}
                    />
                  </StyledButton>
                </div>

                <div
                  id={`sub_menu${index}`}
                  className={`ba_dd absolute top-0 lg2:top-10 bottom-[3.0625rem] left-0 w-full lg2:w-1/2 z-20 ${
                    openModalIndex === index ? "block" : "hidden"
                  }`}
                  aria-labelledby={`sub_menu${index}`}
                >
                  <div className="bg-white border p-5 h-full flex-1 overflow-scroll">
                    <div className="flex justify-between mb-5">
                      <h3 className="text-sm">
                        {(item?.componentType?.name || item?.type) && (
                          <strong>
                            {item?.componentType?.name || item?.type} :
                          </strong>
                        )}{" "}
                        {item?.name}
                      </h3>
                      <button
                        className={`group my-button p-1 md:p-1 w-8 `}
                        type="button"
                        id={`record-close--tooltip`}
                        onClick={handleCategoryToggle(index, setOpenModalIndex)}
                      >
                        <Tooltip
                          anchorId={`record-close--tooltip`}
                          content={`Close`}
                          place={`left`}
                          variant="light"
                          className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  `}
                        />
                        <IKTSVG
                          className={`flex items-center justify-center  transition`}
                          svgClassName="w-3 h-3 fill-violet-500 group-hover:fill-indigo-500"
                          path={CrossIcon}
                        />
                      </button>
                    </div>
                    {item?.sub_menu &&
                      item?.sub_menu.map(
                        (subCategory: any, subCategoryIndex: any) =>
                          !subCategory?.is_deleted && (
                            <SubCategoryForm
                              key={subCategoryIndex}
                              subCategory={subCategory}
                              index={index}
                              setCategories={setCategories}
                              subCategoryIndex={subCategoryIndex}
                            />
                          )
                      )}

                    <button
                      type="button"
                      className={` group inline-flex group items-center transition font-medium rounded text-sm13 px-2.5 py-0.5 text-white hover:text-white bg-violet-500 hover:bg-indigo-500 `}
                      onClick={addNestedCategory(setCategories, index)}
                    >
                      <div
                        className={`flex items-center justify-center  transition mr-2 fill-white `}
                      >
                        <AddIcon className="w-3.5 h-3" />
                      </div>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            )
        )}
        <div className="w-full mb-5 px-2.5 relative">
          <button
            type="button"
            className={` group inline-flex group items-center transition font-medium rounded text-sm13 px-2.5 py-0.5 text-white hover:text-white bg-violet-500 hover:bg-indigo-500 `}
            onClick={() =>
              setCategories((prev: any) =>
                prev.concat({
                  id: "",
                  name: "",
                  type: "",
                  type_id: "",
                  sub_menu: [],
                })
              )
            }
          >
            <div
              className={`flex items-center justify-center  transition mr-2 fill-white `}
            >
              <AddIcon className="w-3.5 h-3" />
            </div>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};
