const Substraction = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="9.501"
      viewBox="0 0 12 9.501"
      {...props}
    >
      <path
        id="Subtraction_8"
        data-name="Subtraction 8"
        d="M12.5-8170.75H2.5a1,1,0,0,1-1-1V-8174a1.5,1.5,0,0,0,1.5-1.5,1.5,1.5,0,0,0-1.5-1.5v-2.248a1,1,0,0,1,1-1h10a1,1,0,0,1,1,1V-8177a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5v2.252A1,1,0,0,1,12.5-8170.75Z"
        transform="translate(-1.501 8180.251)"
      />
    </svg>
  );
};

export default Substraction;
