import _, { find, isArray } from "lodash";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import Upload from "../../../assets/media/icons/other_icons/Upload.svg";
import processSVG from "../../../assets/media/icons/other_icons/process.svg";
import CalenderSVG from "../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutlineSVG from "../../../assets/media/icons/standard_icons/clock-outline.svg";
import CrossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import GeoSVG from "../../../assets/media/icons/standard_icons/geo_icon.svg";
import Warning1 from "../../../assets/media/icons/standard_icons/warning1.svg";
import Checkbox from "../../../components/formComponent/Checkbox";
import DropDownField from "../../../components/formComponent/DropDownField";
import FormDatePickerField from "../../../components/formComponent/FormDatePickerField";
import MultiDropDownField from "../../../components/formComponent/MultiDropDownField";
import PriceField from "../../../components/formComponent/PriceField";
import Textfield from "../../../components/formComponent/Textfield";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { decimalNumber } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import { display } from "../../tx_trade/helper/Assist";
import { InventoryContext } from "../core/InventoryProvider";
import { setEventRecordPayload } from "../core/_functions";
import { UpdateInventory, handleBulk } from "../core/_request";

const EditTableFieldSideBar = ({
  data,
  closeHandle,
  tableRefSide,
  updateVisibility,
  handleRecord,
  setIsOpenTicketPopUp,
  setList,
  list,
}: any) => {
  const [state, setState] = useState<any>();
  const layout = useContext(InventoryContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [btnDiseabled, setBtnDiseabled] = useState<boolean>(true);
  const [confirmDelte, setConfirmDelete] = useState<boolean>(false);
  const [flagData, setFlagData] = useState<any>();
  const [pushserErros, setPusherErros] = useState<any>();

  let ref = tableRefSide?.current?.style;

  // Handle Close

  const handleCancel = () => {
    display(false, ref);
    const allEvents = [...layout.events];
    const eventIndex = allEvents?.findIndex(
      (event) => event?.id === state?.event.id
    );

    if (eventIndex !== -1) {
      const event = { ...allEvents[eventIndex] };
      const recordIndex = event?.eventRecords?.findIndex(
        (record: any) => record?.id === state?.record.id
      );

      if (recordIndex !== -1) {
        event.eventRecords[recordIndex].selected = false;
        allEvents[eventIndex] = event;
        setList(allEvents);
      }
    }

    layout.setIsEditPopUpActive(false);
    closeHandle();
  };

  // Check Local Validation
  const setLocalValidationToEventRecords = async (data: any) => {
    let success = true;
    let fieldErrors: any = {};
    if (data) {
      const faceValueCheck =
        Number(data?.record?.face_value) === 0 ||
        (typeof data?.record?.face_value === "object" &&
          Number(data?.record?.face_value?.value) === 0);

      const processValueCheck =
        Number(data?.record?.sell_price) === 0 ||
        (typeof data?.record?.sell_price === "object" &&
          Number(data?.record?.sell_price?.value) === 0 &&
          !data?.record?.ppe);

      const subtickitypeCheck =
        (!data?.record?.sub_ticket_type?.id &&
          data?.record?.ticketType?.id === "eticket") ||
        (data?.record?.ticket_type === "eticket" &&
          !data?.record?.sub_ticket_type);

      !data?.record?.category &&
        data?.event?.categories &&
        Object.keys(data?.event?.categories).length > 0 &&
        (fieldErrors = {
          ...fieldErrors,
          category: "Category value field is required",
        });

      !data?.record?.split_type &&
        (fieldErrors = {
          ...fieldErrors,
          splitType: "Split Type value field is required",
        });
      !data?.record?.MULTIPLES &&
        data?.record?.splitType?.id === "MULTIPLES" &&
        (fieldErrors = {
          ...fieldErrors,
          MULTIPLES: "Sell in Multiples Field is required",
        });
      (!data?.record?.face_value ||
        (typeof data?.record?.face_value === "object" &&
          data?.record?.face_value?.value === "")) &&
        (fieldErrors = {
          ...fieldErrors,
          face_value: "field is required",
        });

      //  new
      faceValueCheck &&
        (fieldErrors = {
          ...fieldErrors,
          face_value: "field is required more than zero",
        });

      !data?.record?.quantity_available &&
        (fieldErrors = {
          ...fieldErrors,
          quantity_available: "field is required",
        });

      (!data?.record?.sell_price ||
        (typeof data?.record?.sell_price === "object" &&
          data?.record?.sell_price?.value === "")) &&
        !data?.record?.ppe &&
        (fieldErrors = {
          ...fieldErrors,
          sell_price: "Proceed value field is required",
        });

      // new
      processValueCheck &&
        (fieldErrors = {
          ...fieldErrors,
          sell_price: "Proceed value field is required more than zero",
        });

      !data?.record?.ticket_type &&
        (fieldErrors = {
          ...fieldErrors,
          ticket_type: "Ticket type field is required",
        });

      subtickitypeCheck &&
        (fieldErrors = {
          ...fieldErrors,
          sub_ticket_type: "Sub Ticket type Field is required",
        });

      (data?.record?.ticket_type === "paper" ||
        data?.record?.ticket_type?.id === "paper") &&
        data?.record?.delivery_options?.length > 0 &&
        data?.record?.delivery_options?.map((singleDelivery: any) => {
          let price =
            data?.record?.[singleDelivery?.id] != undefined
              ? Number(data?.record?.[singleDelivery?.id])
              : Number(singleDelivery?.price);
          (!price || price <= 0) &&
            (fieldErrors = {
              ...fieldErrors,
              [singleDelivery?.id]:
                layout.deliveryType?.find(
                  (item: any) => singleDelivery?.id === item?.id
                )?.type + " field is required more than zero",
            });
        });

      Object.keys(fieldErrors).length > 0
        ? (success = false)
        : (success = true);
    }

    setState((prevState: any) => ({
      ...prevState,
      record: {
        ...prevState.record,
        fieldErrors: fieldErrors,
      },
    }));

    return success;
  };

  // On Change On Record
  const handleOnRecordChange = (data: any) => {
    const newState = { ...state };
    if (data.target.value?.id)
      newState.record[data.target.name] = data.target.value.id;
    else if (data.target.hasOwnProperty("checked")) {
      newState.record[data.target.name] = data.target.checked;
      if (data.target.checked === true) {
        newState.record["date_to_ship"] = new Date();
      }
    } else newState.record[data.target.name] = data.target.value;
    setState(newState);
  };

  // Handle On Save Changes and Update Api Call
  const handleOnSaveChanges = async () => {
    if (!(await setLocalValidationToEventRecords(state))) {
      return true;
    } else {
      setTimeout(() => {
        setLocalValidationToEventRecords(state);
        // state?.record?.status = onlyUpdate
        //   ? state?.record?.status
        //   : eventRecord.status === "no"
        //   ? "yes"
        //   : "no";
        setIsLoading(true);
        UpdateInventory(
          setEventRecordPayload(
            state?.event,
            { ...state?.record, price_per_exchange: 0 } //REMOVE PPE CHANGE
          )
        ).then((response: any) => {
          let responseData = response?.data?.payload?.post_data;
          responseData
            ? showAlert(response.message, response?.data?.status ? false : true)
            : showAlert(Object.values(response?.errors)?.toString(), true);

          // UPDATE VISBILITY STATE
          updateVisibility(state?.record);
          setTimeout(() => {
            setIsLoading(false);
          }, 100);

          const allEvents = [...layout.events];
          const eventIndex = allEvents?.findIndex(
            (event) => event.id === state.event.id
          );

          if (eventIndex !== -1) {
            const event = { ...allEvents[eventIndex] };
            const recordIndex = event?.eventRecords?.findIndex(
              (record: any) => record.id === state.record.id
            );

            if (recordIndex !== -1) {
              const face_value = {
                name: state?.record?.face_value?.name,
                symbol: state?.record?.face_value?.symbol,
                value: decimalNumber(state?.record?.face_value?.value),
              };
              const sell_price = {
                name: state?.record?.sell_price?.name,
                symbol: state?.record?.sell_price?.symbol,
                value: decimalNumber(state?.record?.sell_price?.value),
              };

              event.eventRecords[recordIndex] = {
                ...state.record,
                face_value: face_value?.name
                  ? face_value
                  : state?.record?.face_value,
                sell_price: sell_price?.name
                  ? sell_price
                  : state?.record?.sell_price,
                fieldErrors: {},
              };
              allEvents[eventIndex] = event;
              setFlagData({
                eventId: allEvents[eventIndex]?.id,
                record: event.eventRecords[recordIndex],
              });
              // setList(allEvents);
              // handleCancel();
            }
          }
        });
      }, 600);
    }
  };

  // after api call and pushe reponse get set events perform
  useEffect(() => {
    if (_.size(flagData) > 0 && layout.events?.length > 0) {
      setList((current: any) => {
        return current?.map((event: any) => {
          if (event?.id === flagData?.eventId) {
            return {
              ...event,
              eventRecords: event?.eventRecords?.map((records: any) => {
                if (flagData?.record?.id === records?.id) {
                  if (records?.errors?.length > 0) {
                    return {
                      ...flagData?.record,
                      errors: records?.errors,
                    };
                  } else {
                    return {
                      ...flagData?.record,
                    };
                  }
                } else {
                  return records;
                }
              }),
            };
          } else {
            return event;
          }
        });
      });
    }
  }, [flagData]);

  // Handle On confirm Delete Record
  const handleOnConfirmDelete = () => {
    // const allEvents = [...layout.events];
    // const eventIndex = allEvents.findIndex(
    //   (event) => event?.id === state?.event.id
    // );

    // if (eventIndex !== -1) {
    //   const updatedEvent = { ...allEvents[eventIndex] };
    //   updatedEvent.eventRecords = updatedEvent.eventRecords.filter(
    //     (record: any) => record?.id !== state?.record.id
    //   );

    //   allEvents[eventIndex] = updatedEvent;
    //   setList(allEvents);
    // }
    setDeleteLoader(true);
    handleBulk({ checked_ids: state?.record?.id, action: "delete" }).then(
      (response: any) => {
        if (Object.keys(response?.data?.errors).length === 0) {
          showAlert(response?.message, false);
        } else {
          Object.values(response?.data?.errors).forEach((error: any) => {
            showAlert(error, true);
          });
        }

        setDeleteLoader(false);
      }
    );
    handleCancel();
  };

  const subTicketType = layout.ticketType?.filter(
    (item: any) => item?.id === "eticket"
  )?.[0]?.subtypes;

  const paperTypeExist =
    state?.record.ticket_type?.id === "paper" ||
    state?.record.ticket_type === "paper";

  const selectedDeliveryTypes: any =
    state?.record?.delivery_options?.map(
      (dT: any) => _.find(layout.deliveryType, { id: dT.id })?.type
    ) || [];

  useEffect(() => {
    if (data && data?.status) {
      setState(data);
      setPusherErros([]);
      setFlagData({});
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      display(data, ref);
    }
  }, [ref, data]);

  const categoryValue = state?.record?.category?.id
    ? state?.record?.category
    : state?.event?.categories &&
      state?.event?.categories.find(
        (category: any) =>
          Number(category.id) === Number(state?.record?.category)
      );

  // print pusher errors after update api call
  useEffect(() => {
    if (layout.events) {
      const allEvents = [...list];
      const eventIndex = allEvents?.findIndex(
        (event) => event?.id === state?.event.id
      );

      if (eventIndex !== -1) {
        const event = { ...allEvents[eventIndex] };
        const record = event?.eventRecords?.filter(
          (record: any) => record?.id === state?.record?.id
        );

        if (record) {
          setPusherErros(record?.[0]?.errors);
        }
      }
    }
  }, [layout.events]);

  const closeButtonRef = useRef<any>(null);

  // one pusher reponse get then after handlclose will perform
  useEffect(() => {
    if (_.size(flagData) > 0) {
      setTimeout(() => {
        if (pushserErros?.length === 0) {
          closeButtonRef?.current?.click();
        }
      }, 1000);
    }
  }, [flagData, pushserErros]);

  return (
    <>
      <div
        className={`${
          state?.status ? "show" : ""
        } upload-ticket-popup buy_canvas offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium z-50 md:max-w-[43.125rem] max-w-[calc(100%--3.75rem)] w-full`}
      >
        <div
          className={` ${
            isLoading && "pointer-events-none"
          }  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col`}
        >
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5 className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5">
              {state?.event?.name}
            </h5>
            <div className="buttons flex flex-wrap">
              <Tooltip
                anchorId={`upload-tickit`}
                content="Upload ticket"
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
              />
              <button
                type="button"
                id={"upload-tickit"}
                className={`flex items-center justify-center w-9 border-l box-content group ${
                  ((typeof state?.record?.ticket_type === "string" &&
                    state?.record?.ticket_type !== "eticket") ||
                    (state?.record?.ticket_type?.id &&
                      state?.record?.ticket_type?.id !== "eticket")) &&
                  `bg-gray-300/40  pointer-events-none cursor-not-allowed`
                }}`}
                onClick={() => {
                  handleRecord(state?.event, {
                    ...state?.record,
                    selected: true,
                  });
                  // new
                  const allEvents = [...layout.events];
                  const eventIndex = allEvents?.findIndex(
                    (event) => event?.id === state?.event.id
                  );

                  if (eventIndex !== -1) {
                    const event = { ...allEvents[eventIndex] };
                    const recordIndex = event.eventRecords?.findIndex(
                      (record: any) => record?.id === state?.record.id
                    );

                    if (recordIndex !== -1) {
                      event.eventRecords[recordIndex].selected = true;
                      allEvents[eventIndex] = event;
                      setList(allEvents);
                    }
                  }

                  setIsOpenTicketPopUp(true);
                  display(false, ref);

                  closeHandle();
                }}
              >
                <IKTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                  path={Upload}
                />
              </button>
              <Tooltip
                anchorId={`close`}
                content="Close"
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
              />
              <button
                type="button"
                className="flex items-center justify-center w-9 border-l box-content group"
                onClick={() => handleCancel()}
                ref={closeButtonRef}
              >
                <IKTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                  path={CrossSVG}
                />
              </button>
            </div>
          </div>
          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            <div className="event-info flex flex-wrap pl-1.5 border-b sm:gap-y-0 gap-y-2.5">
              <div className="date flex pl-3.5 pr-6 sm:py-[.6875rem] pt-2.5 sm:border-r text-sm12 items-center sm:w-auto w-full">
                <IKTSVG
                  path={CalenderSVG}
                  className="flex items-center justify-center w-3 fill-violet-800 mr-2"
                  svgClassName="w-[.6875rem] h-3"
                />
                {moment(state?.event?.date).format("ddd, DD MMM YYYY")}
              </div>
              <div className="date flex px-[.9375rem] sm:py-[.6875rem] sm:border-r text-sm12 items-center sm:w-auto w-full">
                <IKTSVG
                  path={ClockOutlineSVG}
                  className="flex items-center justify-center w-3 fill-violet-800 mr-2"
                  svgClassName="w-3 h-3"
                />
                {moment(state?.event?.time, ["h:mm A"]).format("HH:mm")}
              </div>
              <div className="date flex px-[.9375rem] sm:py-[.6875rem] pb-2.5 text-sm12 items-center sm:w-auto w-full">
                <IKTSVG
                  path={GeoSVG}
                  className="flex items-center justify-center w-3 fill-violet-800 mr-2"
                  svgClassName="w-3 h-3"
                />
                {state?.event?.address}
              </div>
            </div>
            <div className="fields px-5 py-5 pt-[1.875rem]">
              <div className="border rounded min-h-[100px] relative px-5 py-5 pt-[1.875rem]">
                {pushserErros?.length > 0 && (
                  <div className="row-error mb-[1.25rem]">
                    {pushserErros?.map((error: any) => {
                      return (
                        <div className="p-1.5 ">
                          <div>
                            {error.exchange && (
                              <span className="text-rose-500 text-xs inline-block font-semibold">
                                {error.exchange}: &nbsp;
                              </span>
                            )}

                            <p className="text-gray-600 inline font-normal text-xs">
                              {error?.errors && Array.isArray(error?.errors)
                                ? error?.errors?.join(",")
                                : ""}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <h5 className="absolute px-[.3125rem] bg-white -top-[.5625rem] leading-4 text-sm13 left-4 font-semibold">
                  Edit listing
                </h5>
                <div className="flex flex-wrap -mx-2.5 gap-y-5">
                  <div className="sm:w-1/2 w-full px-2.5 ">
                    <DropDownField
                      isCapital={true}
                      isValueCapital={true}
                      options={layout?.ticketType}
                      value={
                        Array.isArray(layout.ticketType) &&
                        layout.ticketType.find(
                          (ticket: any) =>
                            ticket.id === state?.record?.ticket_type
                        )
                      }
                      placeholder={"Ticket type"}
                      isClearable={false}
                      name="ticket_type"
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      errorClassName={
                        state?.record?.fieldErrors?.ticket_type && "error-field"
                      }
                      errorEventIndex={
                        state?.record?.fieldErrors?.ticket_type &&
                        `ticket_type-${state?.record?.id}`
                      }
                      errorMessage={
                        state?.record?.fieldErrors?.ticket_type
                          ? state?.record?.fieldErrors?.ticket_type
                          : ""
                      }
                    />
                  </div>

                  {paperTypeExist && (
                    <div className="sm:w-1/2 w-full px-2.5">
                      <MultiDropDownField
                        name="delivery_options"
                        isCapital={true}
                        options={layout.deliveryType}
                        value={state?.record?.delivery_options}
                        classNamePrefix={"form_multiDropDown"}
                        placeholder={"Delivery type"}
                        handleOnChange={(data: any) => {
                          handleOnRecordChange(data);
                          setBtnDiseabled(false);
                        }}
                        getOptionLabel="type"
                        isDisabled={
                          typeof state?.record?.ticket_type === "object" &&
                          state?.record?.ticket_type?.id !== "paper"
                            ? true
                            : typeof state?.record?.ticket_type === "string" &&
                              state?.record?.ticket_type !== "paper"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {paperTypeExist &&
                    isArray(selectedDeliveryTypes) &&
                    selectedDeliveryTypes.length > 0 &&
                    _.orderBy(selectedDeliveryTypes, ["type"], ["asc"]).map(
                      (selected: any) => {
                        let typeTemp = _.find(layout.deliveryType, {
                          type: selected,
                        });
                        let delivery = find(state?.record?.delivery_options, {
                          id: typeTemp?.id,
                        });
                        return delivery ? (
                          <div className="sm:w-1/2 w-full px-2.5 p-1.5 font-medium">
                            <div className="relative">
                              <Textfield
                                type="number"
                                name={delivery?.id}
                                passFocus={() => {}}
                                label={selected}
                                className={`
                                truncate  text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 
                               ${
                                 state?.record?.errors?.[delivery?.id] &&
                                 "border-rose-500 focus:border-rose-500 #{!important} placeholder:text-rose-500"
                               }
                                `}
                                value={
                                  state?.record?.[delivery?.id] !== undefined
                                    ? state?.records?.[delivery?.id]
                                    : Number(delivery?.price) || ""
                                }
                                placeholder={selected}
                                handleOnChange={(data: any) => {
                                  handleOnRecordChange(data);
                                  setBtnDiseabled(false);
                                }}
                              />
                              {state?.record?.[delivery?.id]
                                ? ""
                                : state?.record?.errors?.[delivery?.id] && (
                                    <>
                                      <Tooltip
                                        anchorId={`delivery-type-${state?.record?.delivery.id}-error`}
                                        content={
                                          state?.record?.errors?.[delivery?.id]
                                        }
                                        place="top"
                                        variant="light"
                                        className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
                                      />
                                      <div
                                        id={`delivery-type-${state?.record?.delivery.id}-error`}
                                        className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                                      >
                                        <IKTSVG
                                          className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                          path={Warning1}
                                        />
                                      </div>
                                    </>
                                  )}
                            </div>
                          </div>
                        ) : (
                          <td className="p-1.5 font-medium">
                            <div className="relative">
                              <input
                                type="number"
                                className={`bg-gray-100 placeholder:truncate pointer-events-none truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50`}
                                placeholder={selected}
                                disabled={true}
                                value={``}
                              />
                            </div>
                          </td>
                        );
                      }
                    )}

                  {state?.record?.ticket_type === "eticket" && (
                    <div className="sm:w-1/2 w-full px-2.5 relative">
                      <DropDownField
                        isCapital={true}
                        isValueCapital={true}
                        value={
                          typeof state?.record?.sub_ticket_type === "object"
                            ? state?.record?.sub_ticket_type
                            : layout.ticketType
                                .find((ticket: any) => ticket.id === "eticket")
                                ?.subtypes?.filter(
                                  (item: any) =>
                                    item?.id === state?.record?.sub_ticket_type
                                )?.[0]
                        }
                        isDisabled={
                          typeof state?.record?.ticket_type === "object" &&
                          state?.record?.ticket_type?.id !== "eticket"
                            ? true
                            : typeof state?.record?.ticket_type === "string" &&
                              state?.record?.ticket_type !== "eticket"
                            ? true
                            : false
                        }
                        isClearable={false}
                        options={subTicketType}
                        placeholder={"Sub Ticket.."}
                        name="sub_ticket_type"
                        handleOnChange={(data: any) => {
                          handleOnRecordChange(data);
                          setBtnDiseabled(false);
                        }}
                        className={
                          !state?.record?.sub_ticket_type &&
                          state?.record?.fieldErrors?.sub_ticket_type &&
                          "all-dropdown-errorField"
                        }
                        errorEventIndex={
                          state?.record?.fieldErrors?.ticket_type &&
                          `ticket_type-${state?.record?.id}`
                        }
                        errorMessage={
                          state?.record?.fieldErrors?.ticket_type
                            ? state?.record?.fieldErrors?.ticket_type
                            : ""
                        }
                      >
                        {!state?.record?.sub_ticket_type &&
                          state?.record?.fieldErrors?.sub_ticket_type && (
                            <div className="text-xs text-rose-500 italic error-msg">
                              {
                                state?.record?.fieldErrors
                                  ?.sub_ticket_type as string
                              }
                            </div>
                          )}
                      </DropDownField>
                    </div>
                  )}

                  <div className="sm:w-1/2 w-full flex flex-wrap px-2.5 gap-y-5">
                    <div className="quantity sm:w-1/2 w-full sm:pr-[.4688rem] relative">
                      <Textfield
                        name="quantity_available"
                        type="number"
                        label="Quantity"
                        id="quantity"
                        passFocus={() => {}}
                        value={state?.record?.quantity_available}
                        errorClass={
                          !state?.record?.quantity_available &&
                          state?.record?.fieldErrors?.quantity_available &&
                          "border-rose-500"
                        }
                        handleOnChange={(data: any) => {
                          handleOnRecordChange(data);
                          setBtnDiseabled(false);
                        }}
                      />
                      {!state?.record?.quantity_available &&
                        state?.record?.fieldErrors?.quantity_available && (
                          <div className="text-xs text-rose-500 italic error-msg">
                            {
                              state?.record?.fieldErrors
                                ?.quantity_available as string
                            }
                          </div>
                        )}
                    </div>
                    <div className="sold sm:w-1/2 w-full sm:pl-[.4688rem]">
                      <div className="relative">
                        <input
                          type="number"
                          className={`bg-gray-100 peer
                                             truncate h-[1.875rem] max-w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none  border-none text-gray-400 placeholder:text-gray-400 disable w-full`}
                          value={state?.record?.quantity_sold}
                          placeholder="Sold"
                          disabled={true}
                        />
                        <label className="false text-label !leading-[1.5] absolute text-xxs text-gray-400 duration-300 transform -translate-y-4 font-medium top-[.5625rem]  left-1 z-10 origin-[0] bg-white px-[.1875rem] peer-focus:px-1 peer-focus:text-indigo-500 peer-placeholder-shown:-translate-y-1/2  peer-placeholder-shown:top-1/2 peer-focus:top-[.5625rem] peer-placeholder-shown:text-xs peer-focus:text-xxs peer-placeholder-shown:text-gray-500 peer-focus:-translate-y-4 peer-hover:text-indigo-500 peer-focus:bg-transparent before:hidden peer-focus:before:block">
                          {" "}
                          Sold
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <DropDownField
                      isCapital={true}
                      isValueCapital={true}
                      value={
                        Array.isArray(layout?.splitType) &&
                        layout?.splitType.find(
                          (split: any) => split.id === state?.record?.split_type
                        )
                      }
                      isClearable={false}
                      options={layout?.splitType}
                      placeholder={"Split type"}
                      name="split_type"
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      errorClassName={
                        state?.record?.fieldErrors?.ticket_type && "error-field"
                      }
                      errorEventIndex={
                        state?.record?.fieldErrors?.ticket_type &&
                        `ticket_type-${state?.record?.id}`
                      }
                      errorMessage={
                        state?.record?.fieldErrors?.ticket_type
                          ? state?.record?.fieldErrors?.ticket_type
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5 editPopup-label">
                    <Textfield
                      type="number"
                      textFielClassName={`${
                        state?.record?.split_type !== "MULTIPLES" &&
                        `!bg-gray-100 pointer-events-none border-none `
                      } truncate  text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                      value={state?.record?.split_quantity || ""}
                      whiteLabel={
                        state?.record?.split_type !== "MULTIPLES" ? true : false
                      }
                      placeholder="Sell In Multiples"
                      name="split_quantity"
                      label="Sell In Multiples"
                      passFocus={() => {}}
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      // disabled={isEventRecordHoldTickets}
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <Textfield
                      name="quantity_display"
                      type="number"
                      label="Max display quantity"
                      id="quantity_display"
                      value={state?.record?.quantity_display}
                      passFocus={() => {}}
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      // disabled={isEventRecordHoldTickets}
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <DropDownField
                      value={
                        state?.record?.category?.id
                          ? state?.record?.category
                          : state?.event?.categories &&
                            state?.event?.categories.find(
                              (category: any) =>
                                Number(category.id) ===
                                Number(state?.record?.category)
                            )
                      }
                      options={state?.event?.categories}
                      placeholder={"Category"}
                      isCapital={true}
                      isValueCapital={true}
                      name="category"
                      className={` ${
                        !categoryValue &&
                        state?.record?.fieldErrors?.category &&
                        "all-dropdown-errorField"
                      }`}
                      divClassName={"relative"}
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      errorClassName={
                        state?.record?.fieldErrors?.category && "error-field"
                      }
                      errorEventIndex={
                        state?.record?.fieldErrors?.category &&
                        `category-${state?.record?.id}`
                      }
                      errorMessage={
                        state?.record?.fieldErrors?.category
                          ? state?.record?.fieldErrors?.category
                          : ""
                      }
                    >
                      {!categoryValue &&
                        state?.record?.fieldErrors?.category && (
                          <div
                            tabIndex={-1}
                            className="text-xxs text-rose-500 error-msg left-3"
                          >
                            {state?.record?.fieldErrors?.category}
                          </div>
                        )}
                    </DropDownField>
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <DropDownField
                      value={
                        state?.record?.section && state?.record?.section?.id
                          ? state?.record?.section
                          : state?.record?.section &&
                            state?.event?.categories &&
                            Array.isArray(state?.event?.categories) &&
                            state?.event?.categories
                              .find(
                                (category: any) =>
                                  Number(category.id) ===
                                  Number(state?.record?.category)
                              )
                              ?.sections?.find(
                                (item: any) =>
                                  Number(item?.id) ===
                                  Number(state?.record?.section)
                              )
                      }
                      options={
                        state?.record?.category?.sections
                          ? state?.record?.category?.sections
                          : state?.event?.categories &&
                            Array.isArray(state?.event?.categories) &&
                            state?.event?.categories.find(
                              (category: any) =>
                                Number(category.id) ===
                                Number(state?.record?.category)
                            )?.sections
                      }
                      placeholder={"Section"}
                      isCapital={true}
                      isValueCapital={true}
                      name="section"
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      // isDisabled={isEventRecordHoldTickets}
                      errorClassName={
                        state?.record?.fieldErrors?.section && "error-field"
                      }
                      errorEventIndex={
                        state?.record?.fieldErrors?.section &&
                        `section-${state?.record?.id}`
                      }
                      errorMessage={
                        state?.record?.fieldErrors?.section
                          ? state?.record?.fieldErrors?.section
                          : ""
                      }
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <Textfield
                      // value={editRecordData?.row && editRecordData?.row}
                      label="Row"
                      name="row"
                      type="text"
                      id="row"
                      passFocus={() => {}}
                      value={state?.record?.row}
                      // height="h-10"
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      // disabled={isEventRecordHoldTickets}
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <Textfield
                      value={
                        state?.record?.first_seat && state?.record?.first_seat
                      }
                      label="First seat"
                      name="first_seat"
                      type="number"
                      id="first_seat"
                      passFocus={() => {}}
                      // height="h-10"
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      // disabled={isEventRecordHoldTickets}
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5 relative">
                    <PriceField
                      value={
                        typeof state?.record?.face_value === "object"
                          ? state?.record?.face_value?.value
                          : decimalNumber(state?.record?.face_value)
                      }
                      currenncySymbol={
                        state?.record?.face_value_currency
                          ? state?.record?.face_value_currency
                          : state?.record?.face_value?.symbol
                      }
                      placeholder={"Face Value"}
                      textFielClassName={`truncate`}
                      label="Face Value"
                      name="face_value"
                      // handleOnBlur={(data: any) => {
                      //   const value = decimalNumber(data.target.value);
                      //   let event = {
                      //     target: {
                      //       name: data.target.name,
                      //       value: value,
                      //     },
                      //   };
                      //   // handleTableField(event, eIndex, rIndex);
                      // }}
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      // isDisabled={isEventRecordHoldTickets}
                      errorClass={`${
                        state?.record?.fieldErrors?.face_value
                          ? state?.record?.fieldErrors?.face_value
                          : ""
                      }`}
                      errorMessage={`${
                        state?.record?.fieldErrors?.face_value
                          ? state?.record?.fieldErrors?.face_value
                          : ""
                      }`}
                      errorEventIndex={
                        state?.record?.fieldErrors?.face_value &&
                        `face_value-${state?.record?.id}`
                      }
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5 proceed-price">
                    <PriceField
                      value={
                        typeof state?.record?.sell_price === "object"
                          ? state?.record?.sell_price?.value
                          : decimalNumber(state?.record?.sell_price)
                      }
                      currenncySymbol={
                        state?.record?.sell_price_currency
                          ? state?.record?.sell_price_currency
                          : state?.record?.sell_price?.symbol
                      }
                      placeholder={"Proceed Price"}
                      label="Proceed Price"
                      // className={`${
                      //   editRecordData?.errors?.sell_price &&
                      //   "focus:border-rose-500 border-rose-500 text-rose-500"
                      // }`}
                      // textFielClassName={`${
                      //   editRecordData?.errors?.sell_price &&
                      //   "focus:border-rose-500 border-rose-500 text-rose-500"
                      // } truncate`}
                      name="sell_price"
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      // handleOnBlur={(data: any) => {
                      //   const value = decimalNumber(data.target.value);
                      //   let event = {
                      //     target: {
                      //       name: data.target.name,
                      //       value: value,
                      //     },
                      //   };
                      //   // handleTableField(event, eIndex, rIndex);
                      // }}
                      // isDisabled={
                      //   eventRecord?.price_per_exchange
                      // }
                      errorClass={`${
                        state?.record?.fieldErrors?.sell_price
                          ? state?.record?.fieldErrors?.sell_price
                          : ""
                      }`}
                      errorMessage={`${
                        state?.record?.fieldErrors?.sell_price
                          ? state?.record?.fieldErrors?.sell_price
                          : ""
                      }`}
                      errorEventIndex={
                        state?.record?.fieldErrors?.sell_price &&
                        `sell_price-${state?.record?.id}`
                      }
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <Checkbox
                      label="Tickets in hand"
                      name="ticketsInHands"
                      id="ticketInHands"
                      checked={state?.record?.ticketsInHands}
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <FormDatePickerField
                      name="date_to_ship"
                      placeholder={`Date to ship`}
                      value={state?.record?.date_to_ship}
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                      disabledKeyboardNavigation={false}
                      isDisabled={state?.record?.ticketsInHands === true}
                      //   error={
                      //     editRecordData?.fieldErrors?.date_to_ship ? true : false
                      //   }
                    />
                    {/* {editRecordData?.fieldErrors?.date_to_ship && (
                      <>
                        <Tooltip
                          anchorId={`dateship-error-${editRecordData?.id}`}
                          content={editRecordData?.fieldErrors?.date_to_ship}
                          place="top"
                          variant="light"
                          className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-10 max-w-[10rem] !text-rose-500"
                        />
                        <div
                          id={`dateship-error-${editRecordData?.id}`}
                          className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                        >
                          <IKTSVG
                            className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                            path={Warning1}
                          />
                        </div>
                      </>
                    )} */}
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <MultiDropDownField
                      name="benefits"
                      classNamePrefix={"form_multiDropDown"}
                      options={layout.benefits}
                      value={
                        state?.record?.benefits?.[0]?.id
                          ? state?.record?.benefits
                          : layout?.benefits.filter((benefit: any) =>
                              state?.record?.benefits?.includes(benefit.id)
                            )
                      }
                      placeholder="Benefits"
                      isSearchable={true}
                      handleOnChange={(data: any) => {
                        handleOnRecordChange(data);
                        setBtnDiseabled(false);
                      }}
                    />
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <MultiDropDownField
                      name="restrictions"
                      classNamePrefix={"form_multiDropDown"}
                      options={layout.restrictions}
                      value={
                        state?.record &&
                        state?.record?.restrictions &&
                        state?.record?.restrictions[0]?.id
                          ? state?.record?.restrictions
                          : layout?.restrictions.filter((restriction: any) =>
                              state?.record?.restrictions?.includes(
                                restriction.id
                              )
                            )
                      }
                      placeholder={"Restrictions"}
                      handleOnChange={(data: any) => {
                        setBtnDiseabled(false);
                        handleOnRecordChange(data);
                      }}

                      // isDisabled={isEventRecordHoldTickets}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" offcanvas-footer bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            {confirmDelte ? (
              <div
                className="flex flex-wrap gap-2.5 gap-x-5 justify-end items-center w-full flex-row-reverse w-full
        "
              >
                <div>
                  <span className="text-sm13 font-medium mr-2 block">
                    Are you sure you want to delete ?
                  </span>
                </div>

                <div className="flex items-center ">
                  <button
                    className="inline-flex group items-center text-violet-800 border transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 hover:border-indigo-500 hover:text-white"
                    type="button"
                    onClick={(e: any) => setConfirmDelete(false)}
                  >
                    {" "}
                    Cancel
                  </button>
                  <button
                    className="ml-2.5 bg-rose-500  inline-flex group items-center text-white   
              transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 "
                    type="button"
                    onClick={(e: any) => {
                      handleOnConfirmDelete();
                      setConfirmDelete(false);
                    }}
                    id={`delete`}
                  >
                    <Tooltip
                      anchorId="delete"
                      content={"Confirm"}
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium !text-xxs z-10 mt-1.5"
                    />
                    <i className="fas fa-solid fa-trash mr-1.5"></i>
                    Delete
                  </button>
                </div>
              </div>
            ) : (
              <div className="l_btn gap-2.5 flex flex-wrap items-center">
                <button
                  type="button"
                  //   onClick={() => {
                  //     setEditRecordData({});
                  //     setEditData(null);
                  //   }}
                  onClick={() => handleCancel()}
                  className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                  // data-bs-dismiss="offcanvas"
                  // aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`${
                    deleteLoader && "pointer-events-none"
                  } text-rose-500 flex hover:text-white bg-white hover:bg-rose-500 border hover:border-rose-500 transition rounded text-sm13 px-2.5 py-0.5 `}
                  onClick={() => setConfirmDelete(true)}
                >
                  {deleteLoader && (
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition animate-spin mr-2"
                      path={processSVG}
                    />
                  )}
                  Delete
                </button>

                <button
                  type="button"
                  className={`
                  ${isLoading && "pointer-events-none"}
                   relative z-10 flex border border-green-600 hover:text-green-600 active:text-white text-white hover:bg-white bg-green-600 active:bg-green-600 transition rounded text-sm13 px-2.5 py-0.5 `}
                  onClick={() => handleOnSaveChanges()}
                >
                  {isLoading && (
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition animate-spin mr-2"
                      path={processSVG}
                    />
                  )}
                  Save changes
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTableFieldSideBar;
