import { LayoutContext } from "components/core/LayoutProvider";
import ProgressBardLoader from "components/ui/ProgressBardLoader";

import DataCell from "pages/tx_account/Table/component/DataCell";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";
import Footer from "./component/Footer";
import TableEditAction from "./component/TableEditAction";
import TableHeaderRow from "./component/TableHeaderRow";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left last-auto"
    style={{
      ...style,
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 last-auto"
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTable = ({ refetch }: any) => {
  const globalLayout = useContext(LayoutContext);
  const layout: any = useContext(SellerLevelsProviderContex);
  const [height, setHeight] = useState<any | undefined>(undefined);
  const tableData = layout.sellerLevelData?.tableData;
  const [deleteLevelLoader, setDeleteLevelLoader] = useState<any>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<any>(false);

  let timeout: any;

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#sales-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight + 1;

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    renderCall();
  }, [globalLayout?.htmlFontSize]);

  return (
    <>
      <div
        className={`accordion font-medium transition-all relative md:px-5 max-lg:!mr-0
          ${deleteConfirmation?.status ? "mb-16" : "mb-4"}
          `}
        id={`EventAccordion`}
      >
        <div
          id="sticky-head"
          className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none top-[14.625rem]`}
          // ref={stickyTableRef}
          // onScroll={handleScrollHead}
        >
          <table className="w-full text-xs text-left  absolute left-0 top-0 last-auto">
            <thead className="text-xs text-gray-400 ">
              <TableHeaderRow
              //   stickyTableRef={stickyTableRef}
              //   tableRef={tableRef}
              />
            </thead>
          </table>
        </div>
        <span id="sticky-shadow" className="z-10 hidden"></span>
        <div className="accordion-item bg-white  !rounded overflow-hidden">
          <div
            id="EventCollapseBody"
            className="accordion-collapse collapse show"
            aria-labelledby="EventCollapseBody"
            data-te-collapse-show
          >
            <div className="accordion-body relative">
              <div
                className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
                //   ref={tableRef}
                id={`tableId1`}
                //   onScroll={handleScrollVirtuoso}
                style={{ height: height > 41 ? `${height}px` : "auto" }}
              >
                <TableVirtuoso
                  data={tableData}
                  // endReached={() => {}}
                  useWindowScroll
                  overscan={200}
                  totalCount={tableData?.length}
                  atBottomThreshold={100}
                  id={"sales-lazyload"}
                  components={{
                    Table: Table,
                    TableHead: TableHead,
                    TableRow: (props) => {
                      return <TableRow {...props} />;
                    },
                  }}
                  fixedHeaderContent={() => (
                    <TableHeaderRow
                    // stickyTableRef={stickyTableRef}
                    // tableRef={tableRef}
                    />
                  )}
                  itemContent={(sIndex: any, list: any) => {
                    return (
                      <React.Fragment key={sIndex}>
                        <DataCell
                          value={list?.title}
                          classname="block"
                          padding="pl-5"
                          width="w-[6.25rem] min-w-[6.25rem]"
                        />
                        <DataCell
                          value={list?.team_count}
                          classname="block"
                          padding="pl-5"
                          width="w-[6.25rem] min-w-[6.25rem]"
                        />
                        <DataCell
                          value={list?.weightage}
                          classname="block"
                          padding="pl-5"
                          width="w-[6.25rem] min-w-[6.25rem]"
                        />

                        <TableEditAction
                          sIndex={sIndex}
                          list={list}
                          refetch={refetch}
                          setDeleteLevelLoader={setDeleteLevelLoader}
                          setDeleteConfirmation={setDeleteConfirmation}
                        />
                      </React.Fragment>
                    );
                  }}
                  // ref={virtuosoRef}
                />
              </div>
            </div>
          </div>
          {deleteLevelLoader && <ProgressBardLoader secondCounter={2} />}
        </div>
      </div>
      {deleteConfirmation?.status ? (
        <Footer
          setDeleteLevelLoader={setDeleteLevelLoader}
          refetch={refetch}
          deleteConfirmation={deleteConfirmation}
          setDeleteConfirmation={setDeleteConfirmation}
        />
      ) : null}
    </>
  );
};

export default DataTable;
