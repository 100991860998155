/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import axios from "../../../axois/SetupAxios";
import {
  REACT_APP_ALGOLIA_API_KEY,
  REACT_APP_ALGOLIA_APPLICATION_ID,
} from "../../../helpers/const";
const algoliasearch = require("algoliasearch");

const inventorySearch = (
  id: any,
  paginateData: any,
  searchFieldValue: any,
  performerKeyboardField: any,
  action: string
): Promise<any> => {
  let per_page = paginateData?.per_page;
  let start_date = performerKeyboardField?.start_date?.startDate
    ? moment(performerKeyboardField?.start_date?.startDate)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .format("YYYY-MM-DD")
    : "";
  let end_date = performerKeyboardField?.start_date?.endDate
    ? moment(performerKeyboardField?.start_date?.endDate)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        })
        .format("YYYY-MM-DD")
    : "";
  let params: any = {};

  switch (action) {
    case "performer":
      params = {
        search_by: "venue_performer",
        query: searchFieldValue,
      };
      break;

    case "event":
      params = {
        search_by: "event_name",
        query: searchFieldValue,
      };
      break;
    case "searchByPerformerId":
      params = {
        performer_id: id,
      };
      break;
    case "performerKeyboard":
      if (per_page) {
        params = {
          performer_id: id,
          timeframe: performerKeyboardField?.timeframe?.id,
          per_page: per_page,
          start_date: start_date,
          end_date: end_date,
        };
      } else {
        params = {
          performer_id: id,
          timeframe: performerKeyboardField?.timeframe?.id,
          start_date: start_date,
          end_date: end_date,
        };
      }
      break;
    case "paginatePerformer":
      if (Object.keys(performerKeyboardField?.timeframe).length > 0) {
        params = {
          performer_id: id,
          timeframe: performerKeyboardField?.timeframe?.id,
          start_date: start_date,
          end_date: end_date,
          per_page: per_page,
          page: paginateData.page,
        };
      } else {
        params = {
          performer_id: id,
          per_page: per_page,
          page: paginateData.page,
        };
      }

      break;

    default:
      break;
  }

  return axios
    .post(`inventory/search`, null, {
      params: params,
    })
    .then((response: any) => response.data);
};

const getEventDetails = (id: any, per_page: any): Promise<any> => {
  return axios.post(`events/details`, null, {
    params: {
      event_id: Array.isArray(id) ? id : [id],
      ...(per_page && { per_page: per_page }),
    },
  });
};

const getInventoryOptions = (): Promise<any> => {
  return axios.get(`inventory/options`);
};

// ALGOLIA SEARCH
const inventoryAlgoliaSearch = (
  checkExchange: any,
  eventOrPerformer: any,
  id: any,
  paginateData: any,
  searchFieldValue: any,
  performerKeyboardField: any,
  action: string,
  searchValue: any
): Promise<any> => {
  let start_date: any = moment(performerKeyboardField?.start_date?.startDate)
    .startOf("day")
    .unix();
  let end_date = performerKeyboardField?.start_date?.endDate
    ? moment(performerKeyboardField?.start_date?.endDate).endOf("day").unix()
    : "";
  var priorDate: any;
  if (Object.keys(performerKeyboardField?.timeframe).length > 0) {
    priorDate = Math.floor(
      new Date().setDate(
        new Date().getDate() + performerKeyboardField?.timeframe.id
      ) / 1000
    );
  }

  const currentDate = moment();
  const currentTimestamp = currentDate.unix();

  const getResults = () => {
    let str = "";
    let exchangeCondition = "";
    let isSourceTixstock: any = "";

    // IF LOGGEDIN USERD IS NOT CONNECTED WITH TIXSTOCK THEN PASS CONNECTED MARKETPLACE IDS
    checkExchange &&
      checkExchange.map((ex: number) => (str += "exchanges=" + ex + " OR "));
    if (checkExchange) {
      exchangeCondition = `AND ${str.slice(0, -3)}`;
    }

    // IF LOGGED USER IS CONNECTED WITH TIXSTOCK THEN PASS BELOW FILTER
    if (!checkExchange) {
      isSourceTixstock = ` AND is_source_tixstock=1 `;
    }

    // Create Algolia filters to retrieve data only for today with future time of today and future dates
    const dateFilters = `event_date_local >= ${new Date().getTime() / 1000}`;

    switch (action) {
      case "searchByPerformerId":
        return [
          {
            indexName: "events",
            params: {
              filters: `${id.indexId === 1 ? "performer_names" : "venue_id"}:${
                id.indexId === 1 ? '"' + searchValue?.name + '"' : id.id
              } ${exchangeCondition}  ${isSourceTixstock} AND ${dateFilters}`,
              // hitsPerPage: 10,
            },
          },
        ];
      case "performerKeyboard":
        return [
          {
            indexName: "events",
            params: {
              filters: `${id.indexId === 1 ? "performers" : "venue_id"}:${
                id.id
              } ${exchangeCondition}  ${isSourceTixstock}  ${
                priorDate
                  ? `AND event_date_local >= ${currentTimestamp} AND event_date_local <= ${priorDate}`
                  : ""
              } ${
                end_date
                  ? `AND event_date_local:${start_date} TO ${end_date}`
                  : ""
              } ${!priorDate && !end_date ? `AND ${dateFilters}` : ""}`,
              // hitsPerPage: 10,
            },
          },
        ];
      // break;
      case "paginatePerformer":
        return [
          {
            indexName: "events",
            params: {
              filters: `${id.indexId === 1 ? "performers" : "venue_id"}:${
                id.id
              } ${exchangeCondition}  ${isSourceTixstock} AND ${dateFilters}`,
              hitsPerPage: paginateData.per_page ? paginateData.per_page : 10,
              page: paginateData.page,
            },
          },
        ];
      // break;
      default:
        return [
          {
            indexName: "events",
            ...(searchFieldValue.value && { query: searchFieldValue.value }),
            params: {
              // hitsPerPage: searchFieldValue.id === 'bottom' ? 20 : 10,
              hitsPerPage: searchFieldValue.value ? 1000 : 20,
              ...(checkExchange && { filters: str.slice(0, -3) }),
              filters: `${dateFilters} ${exchangeCondition} ${isSourceTixstock}`,
              page:
                searchFieldValue.id === "bottom"
                  ? searchFieldValue.per_page
                  : 0,
            },
          },
          {
            indexName: "performers",
            ...(searchFieldValue.value && { query: searchFieldValue.value }),
            params: {
              hitsPerPage: 5,
              // filters:dateFilters
              filters: `events_exists=1`,
            },
          },
          {
            indexName: "venues",
            ...(searchFieldValue.value && { query: searchFieldValue.value }),
            params: {
              hitsPerPage: 5,
              filters: `events_exists=1`,
            },
          },
        ];
      // break;
    }
  };
  // Connect and authenticate with your Algolia app
  const client = algoliasearch(
    REACT_APP_ALGOLIA_APPLICATION_ID,
    REACT_APP_ALGOLIA_API_KEY
  );
  return client.multipleQueries(getResults()).then(({ results }: any) => {
    return results;
  });
};

const inventoryBlukAdd = (data: any): Promise<any> => {
  return axios.post(`inventory/bulk-add`, data);
};

const UpdateInventory = (data: any) => {
  return axios.post(`inventory/update`, data).then((response) => response.data);
};

const fetchMarketplacePrice = async (data: any) => {
  try {
    const params = {
      eventname: encodeURIComponent(data?.name),
      eventdate: moment(data?.date).format("DD-MM-YYYY"),
    };

    let queryString = new URLSearchParams(params).toString();

    // Split the query string by `&` to handle each key-value pair separately
    queryString = queryString
      .split("&")
      .map((pair) => {
        // Split each pair by `=`
        let [key, value] = pair.split("=");
        // Only remove `25` from the value if the key is 'eventname'
        if (key === "eventname") {
          value = value.replace(/25/g, "");
        }
        // Rejoin the key-value pair
        return `${key}=${value}`;
      })
      .join("&");

    // Replace `=` and `&` with `/`
    queryString = queryString.replace(/=/g, "/").replace(/&/g, "/");

    const url = `https://mi-ox.com/apiv2.0.php/matchdetails/${queryString}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Failed to fetch match details");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching match details:", error);
    throw error;
  }
};

const fetchMarketplacePriceWithSection = async (data: any) => {
  try {
    const params = {
      eventname: encodeURIComponent(data?.name),
      eventdate: moment(data?.date).format("YYYY-MM-DD"),
    };

    // const queryString = new URLSearchParams(params)
    //   .toString()
    //   .replace(/=/g, "/")
    //   .replace(/&/g, "/")
    //   .replace(/25/g, "");

    let queryString = new URLSearchParams(params).toString();

    // Split the query string by `&` to handle each key-value pair separately
    queryString = queryString
      .split("&")
      .map((pair) => {
        // Split each pair by `=`
        let [key, value] = pair.split("=");
        // Only remove `25` from the value if the key is 'eventname'
        if (key === "eventname") {
          value = value.replace(/25/g, "");
        }
        // Rejoin the key-value pair
        return `${key}=${value}`;
      })
      .join("&");

    // Replace `=` and `&` with `/`
    queryString = queryString.replace(/=/g, "/").replace(/&/g, "/");

    const url = `https://mi-ox.com/apiv2.0.php/sellers/${queryString}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error("Failed to fetch match details");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching match details:", error);
    throw error;
  }
};

const requestEvent = (data: any): Promise<any> => {
  return axios
    .post(`inventory/requset_event`, data)
    .then((response) => response.data);
};

export {
  fetchMarketplacePrice,
  fetchMarketplacePriceWithSection,
  getEventDetails,
  getInventoryOptions,
  inventoryAlgoliaSearch,
  inventoryBlukAdd,
  inventorySearch,
  requestEvent,
  UpdateInventory,
};
