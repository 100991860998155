import Warning from "../../assets/media/icons/standard_icons/warning1.svg";
import { IKTSVG } from "../ui/IKTSVG";
import { KTSVG } from "../ui/KTSVG";

const ErrorBoundary = ({ error }: any) => {
  return (
    <div className="contact_popup active fixed top-0 left-0 w-full h-full md:min-h-screen  overflow-auto opacity-0 invisible [&.active]:visible [&.active]:opacity-100 transition-all z-30">
      <div className="popup_header md:py-6 py-[15px] text-white fixed top-0 left-0 w-full z-20 bg-violet-600">
        <div className="container_login">
          <div className="flex flex-wrap items-center justify-between">
            <div className="tix_logo">
              <KTSVG
                className="max-w-[12.75rem] flex items-center justify-center fill-white transition cursor-pointer"
                path="tix_logo.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="popup_content md:pt-32 pt-24 md:pb-12 pb-5 min-h-screen flex items-center justify-center">
        <div className="container_main max-w-[76.25rem] m-auto">
          <div className="flex flex-wrap items-center justify-center">
            <IKTSVG
              path={Warning}
              className="w-[6.25rem] fill-violet-500 mb-[2rem]"
              svgClassName="w-[6.25rem] h-[6.25rem]"
            />
            <div className="contact_text text-center w-full px-5">
              <h5 className="md:text-[2rem] text-[1.875rem] font-artex_reg mb-5">
                Something went wrong, please refresh and try again.
              </h5>
              <pre style={{ color: "white", display: "none" }}>{error?.message}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;
