import { Link } from "react-router-dom";
import { KTSVG } from "../../../components/ui/KTSVG";

const FAQCategory = ({
  title,
  description,
  path,
  slug,
  width,
  height,
}: any) => {
  return (
    <>
      <div className="single-item lg:w-1/3 px-2.5 mb-5 w-full group cursor-pointer">
        <Link to={`/faq/${slug}`}>
          <div className="lg:py-14 lg:pt-[3.125rem] py-6 xl:px-[3.75rem] px-5 h-full bg-white rounded shadow-op1 text-center">
            <div className="mx-auto mb-5 item_icon w-[4.375rem] h-[4.375rem] group-hover:bg-indigo-500 transition-all bg-violet-500 flex items-center justify-center rounded-xl">
              <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                <KTSVG
                  svgClassName={`fill-white center ${width} ${height}`}
                  path={`menu_icons/${path}.svg`}
                />
              </span>
            </div>
            <h3 className="md:text-2xl text-xl mb-5 font-semibold !leading-[1.8125rem]">
              {title}
            </h3>
            <p className="text-[.8125rem] text-gray-600 leading-5">
              {description}
            </p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default FAQCategory;
