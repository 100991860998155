import _, { size } from "lodash";
import moment from "moment";
import { MAX_FILES } from "../../../helpers/const";
import { checkTicketFilesEmptyOrNot } from "../../../helpers/Functions";

const getPercentageValue = (number: Number, percent: Number) => {
  return (Number(percent) / 100) * Number(number);
};

const getPercentage = (number1: Number, number2: Number) => {
  if (number2 === 0) return 0;
  return Number((Number(number1) / Number(number2)) * 100);
};

const calculateDisplayPrice = (
  currnecyRates: any,
  currentMarketPlace: any,
  value: any
) => {
  let displayPrice: any = "";

  if (currnecyRates && !isNaN(Number(value))) {
    let priceWithRate: any =
      value *
      currnecyRates[currentMarketPlace ? currentMarketPlace.currency : "GBP"];
    let marketFees = currentMarketPlace
      ? getPercentageValue(
          priceWithRate,
          currentMarketPlace?.commission_percentage_fee
        )
      : 0;
    displayPrice = parseFloat(priceWithRate + marketFees).toFixed(2);
  } else {
    displayPrice = "";
  }

  return displayPrice;
};

const convertDate = (date: any) => {
  return moment(new Date(date * 1000).toString())
    .utc()
    .format("ddd, DD MMM YYYY");
};

const convertTime = (date: any) => {
  return moment(new Date(date * 1000).toString())
    .utc()
    .format("HH:mm");
};

const generateUniqueId = () => {
  return new Date().valueOf() + Math.floor(Math.random() * 100000);
};

const getTimeOnly = (date: any) => {
  return new Date(date).toLocaleTimeString([], {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
};

const getDateOnly = (date: any) => {
  return new Date(date).toLocaleDateString();
};

// Make Copy
function copy(text: string) {
  if (text.includes(",")) {
    let finalText = text
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/\s+/g, " ")
      .trim();
    navigator.clipboard.writeText(finalText);
    return finalText;
  } else {
    navigator.clipboard.writeText(text);
    return text;
  }
}

const visibilityChecker = (data: any, eventType: string) => {
  if (
    data?.ticket_type &&
    data?.quantity_available &&
    data?.split_type &&
    data?.quantity_display &&
    data?.category &&
    data?.section &&
    data?.row &&
    data?.first_seat &&
    eventType === "initial"
      ? data?.restrictions?.length > 0
      : data?.benefits?.length > 0 &&
        data?.ticketsInHands !== undefined &&
        data?.listing_ticket_files?.length > 0
  ) {
    return 5;
  } else if (
    data?.ticket_type &&
    data?.quantity_available &&
    data?.split_type &&
    data?.quantity_display &&
    data?.category &&
    data?.section &&
    data?.row &&
    data?.first_seat &&
    eventType === "initial"
      ? data?.restrictions?.length > 0
      : data?.benefits?.length > 0 && data?.ticketsInHands !== undefined
  ) {
    return 4;
  } else if (
    data?.ticket_type &&
    data?.quantity_available &&
    data?.split_type &&
    data?.quantity_display &&
    data?.category &&
    data?.section &&
    data?.row &&
    data?.first_seat &&
    eventType === "initial"
      ? data?.restrictions?.length > 0
      : data?.benefits?.length > 0
  ) {
    return 3;
  } else if (
    data?.ticket_type &&
    data?.quantity_available &&
    data?.split_type &&
    data?.quantity_display &&
    data?.category &&
    data?.section
  ) {
    return 2;
  } else if (
    data?.ticket_type &&
    data?.quantity_available &&
    data?.split_type &&
    data?.quantity_display &&
    data?.category
  ) {
    return 1;
  } else {
    return 0;
  }
};

// GET DATE OF EVENT 2 DAYS BEFORE
const get2DaysBeforeDateOfEvent = (data: any) => {
  const eventDate = moment(data);
  const today = moment().startOf("day");
  const tomorrow = moment().add(1, "day").startOf("day");

  if (eventDate.isSame(today, "day") || eventDate.isSame(tomorrow, "day")) {
    return today.toDate(); // Event is today or tomorrow, return today's date
  } else {
    // Event is neither today nor tomorrow, calculate 2 days before
    return eventDate.subtract(2, "days").toDate();
  }

  // return moment(data)
  //   .subtract(2, "days")
  //   .toDate()
};

const reArrangeDeliveryArray = (data: any, type: any) => {
  const groupedTemp = _.groupBy(data, type);
  return _.flatten([
    groupedTemp["Local"] || [],
    groupedTemp["National"] || [],
    groupedTemp["International"] || [],
  ]);
};

const checkIfClonetoNewActive = (data: any) => {
  // CHECK IF NEW TO CLONE FIELD ACTIVE
  return data && data.length > 0 && data?.some((event: any) => event?.id == "");
};
//

const TicketError: any =
  "Listing quantity must match with the number of tickets uploaded to proceed";

const checkAccordionCollapse = (data: any) => {
  let allAccordion = document.getElementsByClassName(data);
  let isAnyAccordionExpanded = Array.from(allAccordion).some(
    (accordion) => accordion.getAttribute("aria-expanded") === "true"
  );
  return isAnyAccordionExpanded;
};

const countExpandedAccordions = (data: any) => {
  let allAccordion = document.getElementsByClassName(data);
  let expandedAccordionCount = Array.from(allAccordion).filter(
    (accordion) => accordion.getAttribute("aria-expanded") === "true"
  ).length;
  return expandedAccordionCount;
};

function visibilityMeterCalculationForKeyBoard(
  event: any,
  item: any,
  list?: any
) {
  // let final = event?.eventRecords?.map((item: any) => {

  const calculateVisibility = () => {
    let visibility = 0;

    // const isStep1Filled =
    //   event &&
    //   _.size(item?.ticketType) > 0 &&
    //   item?.quantity &&
    //   _.size(item?.faceValue) > 0 &&
    //   _.size(item?.proceedValue) > 0 &&
    //   item?.faceValue?.value !== "" &&
    //   item?.faceValue?.value !== "0.00" &&
    //   item?.proceedValue?.value !== "" &&
    //   item?.proceedValue?.value !== "0.00";

    const isStep1Filled =
      (_.size(item?.ticketType) > 0 ? 1 : 0) +
        (item?.quantity ? 1 : 0) +
        (_.size(item?.faceValue) > 0 &&
        item?.faceValue?.value !== "" &&
        item?.faceValue?.value !== "0.00"
          ? 1
          : 0) +
        (_.size(item?.proceedValue) > 0 &&
        item?.proceedValue?.value !== "" &&
        item?.proceedValue?.value !== "0.00"
          ? 1
          : 0) >=
      2;

    const isCategoriesEmpty =
      event &&
      list?.length === 1 &&
      _.size(item?.ticketType) > 0 &&
      item?.quantity &&
      item?.faceValue?.value !== "" &&
      item?.faceValue?.value !== "0.00" &&
      item?.proceedValue?.value !== "" &&
      item?.proceedValue?.value !== "0.00" &&
      event?.categories?.length === 0;

    const isStep2Filled =
      _.size(item?.category) > 0 &&
      _.size(item?.section) > 0 &&
      item?.category?.name === item?.section?.name;

    const isStep2Inner =
      _.size(item?.category) > 0 &&
      _.size(item?.section) > 0 &&
      item?.category?.sections?.length === 1;

    const isStep3Filled =
      _.size(item?.category) > 0 &&
      _.size(item?.section) > 0 &&
      item?.section?.name &&
      item?.category?.name !== item?.section?.name;

    // const isStep4Filled =
    //   item?.row || (item?.row && item?.firstSeat) || item?.ticketsInHands;

    const allTicketUploaded =
      item?.quantity &&
      item?.ticketFiles?.filter((obj: any) => JSON.stringify(obj) !== "{}")
        ?.length > 0 &&
      Number(item?.quantity) ===
        item?.ticketFiles?.filter((obj: any) => JSON.stringify(obj) !== "{}")
          ?.length;
    const isStep4Filled = allTicketUploaded ? true : item?.ticketsInHands;

    const isStep5Filled =
      item?.ticketType?.id !== "" &&
      (item?.ticketType?.id === "mobile-qr" ||
      item?.ticketType?.id === "pdf" ||
      item?.ticketType?.id === "eticket"
        ? item?.quantity &&
          item?.ticketFiles?.filter((obj: any) => JSON.stringify(obj) !== "{}")
            ?.length > 0 &&
          Number(item?.quantity) ===
            item?.ticketFiles?.filter(
              (obj: any) => JSON.stringify(obj) !== "{}"
            )?.length
        : true);

    // FOR INTIAL LOW FILL SOME PERCENT WHEN VISIBILITY IS 1
    if (event) {
      visibility += 1;
    }
    //FOR LOW FILL AND LEVEL 1 AND LEVEL 2 COMPLETE WHEN VISIBILITY IS 5 AND CATEGORY AND SECTION EMPTY
    if (isCategoriesEmpty && isStep4Filled) {
      visibility += 4;
    }

    // FOR LOW FILL COMPLETE WHEN VISIBILITY IS 2
    if (isStep1Filled) {
      visibility += 1;
    }
    // FOR LEVEL 1 FILL COMPLETE WHEN VISIBILITY IS 3
    if (isStep1Filled && isStep2Filled) {
      visibility += isStep2Inner ? 2 : 1;
    }
    // FOR LEVEL 2 FILL COMPLETE WHEN VISIBILITY IS 4
    if (isStep1Filled && isStep3Filled) {
      visibility += 2;
    }

    // FOR LEVEL 3 FILL COMPLETE WHEN VISIBILITY IS 5
    if (isStep1Filled && (isStep2Filled || isStep3Filled) && isStep4Filled) {
      visibility += isStep3Filled ? 1 : isStep2Inner ? 1 : 2;
    }
    // FOR LEVEL 4 FILL COMPLETE WHEN VISIBILITY IS 6
    if (
      isStep1Filled &&
      (isStep2Filled || isStep3Filled) &&
      isStep4Filled &&
      isStep5Filled
    ) {
      visibility += 1;
    }

    return visibility;
  };

  const visibility = calculateVisibility();

  return visibility;
}

function visiblityMeterForBelowListing(Oldvisiblity: any, visiblity: any) {
  if (visiblity === 3) {
    return 1;
  }
  if (visiblity === 4) {
    return 2;
  }
  if (visiblity === 5) {
    return 3;
  }
  if (visiblity === 6) {
    return 4;
  }
  return Oldvisiblity > 2 && 0;
}

function visiblityMeter(
  Oldvisiblity: any,
  visiblity: any,
  searchValue: any,
  record: any
) {
  let level1 =
    visiblity === 1 &&
    (_.size(record?.ticketType) > 0 ||
      record?.quantity ||
      (_.size(record?.faceValue) > 0 &&
        record?.faceValue?.value !== "" &&
        record?.faceValue?.value !== "0.00") ||
      (_.size(record?.proceedValue) > 0 &&
        record?.proceedValue?.value !== "" &&
        record?.proceedValue?.value !== "0.00"));
  // return searchValue && Oldvisiblity === 0 && visiblity === 0
  //   ? "visibility-case0-13"
  //   : Oldvisiblity === 0 && visiblity === 1
  //   ? "visibility-case0-13"
  //   : `visibility-case${Number(Oldvisiblity) - 1}-${Number(visiblity - 1)}`;
  return level1
    ? "visibility-case0-13"
    : `visibility-case${Number(Oldvisiblity) - 1}-${Number(visiblity - 1)}`;
}

function keyBoardVisiblityDynamicMessage1(
  visibility: any,
  record: any,
  searchedValue: any,
  errorCase: any,
  priceFiledFocused: any,
  event: any,
  loader: any
) {
  const level1 =
    record &&
    (_.size(record?.ticketType) > 0 ||
      record?.quantity ||
      (_.size(record?.faceValue) > 0 &&
        record?.faceValue?.value !== "" &&
        record?.faceValue?.value !== "0.00") ||
      (_.size(record?.proceedValue) > 0 &&
        record?.proceedValue?.value !== "" &&
        record?.proceedValue?.value !== "0.00"));
  const level2 =
    priceFiledFocused &&
    event?.markeInsightsForAvgPrice &&
    (_.size(record?.section) === 0 || _.size(record?.category) === 0);

  if (loader) {
    return `anim-fade8`;
  }
  if (
    priceFiledFocused &&
    (record?.proceedValue?.value === "" ||
      record?.proceedValue?.value === "0.00" ||
      !record?.proceedValue?.value)
  ) {
    return `anim-fade9`;
  }
  if (level2) {
    return `anim-fade10`;
  }
  if (errorCase === "poor" && priceFiledFocused) {
    return `anim-fade11`;
  }
  if (errorCase === "Average" && priceFiledFocused) {
    return `12`;
  }
  if (
    errorCase === "Good" &&
    !record?.ticketsInHands &&
    visibility !== 5 &&
    priceFiledFocused
  ) {
    return `anim-fade13`;
  }
  if (errorCase === "Low" && priceFiledFocused) {
    return `anim-fade14`;
  }

  if (searchedValue && visibility === 0) {
    return `anim-fade1`;
  }
  if (visibility === 1 && level1) {
    // return `Optimise your listings and enhance your chances of selling quickly on the Tixstock Network`;
    return `anim-fade2`;
  }
  if (visibility === 2) {
    return `anim-fade3`;
  }
  if (visibility === 3) {
    // return `Upload tickets for immediate download and achieve maximum listing quality`;
    return `anim-fade4`;
  }
  if (visibility === 4) {
    return `anim-fade5`;
  }
  if (record?.ticketsInHands && visibility === 5) {
    return `anim-fade6`;
  }
  if (!record?.ticketsInHands && visibility === 5) {
    return "anim-fade7";
  }
  // return `Listing quality`;
}

function keyBoardVisiblityDynamicMessage(
  visibility: any,
  record: any,
  searchedValue: any,
  errorCase: any,
  priceFiledFocused: any,
  event: any,
  loader: any
) {
  const level1 =
    record &&
    (_.size(record?.ticketType) > 0 ||
      record?.quantity ||
      (_.size(record?.faceValue) > 0 &&
        record?.faceValue?.value !== "" &&
        record?.faceValue?.value !== "0.00") ||
      (_.size(record?.proceedValue) > 0 &&
        record?.proceedValue?.value !== "" &&
        record?.proceedValue?.value !== "0.00"));

  const level2 =
    priceFiledFocused &&
    event?.markeInsightsForAvgPrice &&
    (_.size(record?.section) === 0 || _.size(record?.category) === 0);

  if (loader) {
    return `Optimise your listings to sell on the Tixstock Network`;
  }
  if (
    priceFiledFocused &&
    (record?.proceedValue?.value === "" ||
      record?.proceedValue?.value === "0.00" ||
      !record?.proceedValue?.value)
  ) {
    return `Price details required before a listing can be published`;
  }
  if (level2) {
    return `Include specific Section/block to improve listing quality`;
  }
  if (errorCase === "poor" && priceFiledFocused) {
    return `Not likely to sell at this price`;
  }
  if (errorCase === "Average" && priceFiledFocused) {
    return `More likely to sell at a lower price`;
  }
  if (
    errorCase === "Good" &&
    !record?.ticketsInHands &&
    visibility !== 5 &&
    priceFiledFocused
  ) {
    return `Competitively priced and likely to sell`;
  }
  if (errorCase === "Low" && priceFiledFocused) {
    return `This price looks to low`;
  }
  if (searchedValue && visibility === 0) {
    return `Listing Quality`;
  }
  if (visibility === 1 && level1) {
    // return `Optimise your listings and enhance your chances of selling quickly on the Tixstock Network`;
    return `Optimise your listings to sell on the Tixstock Network`;
  }
  if (visibility === 2) {
    return `Include specific section/block information to improve listing quality`;
  }
  if (visibility === 3) {
    // return `Upload tickets for immediate download and achieve maximum listing quality`;
    return `Upload tickets to achieve maximum listing quality`;
  }
  if (visibility === 4) {
    return `Optimise your listings to sell on the Tixstock Network`;
  }
  if (record?.ticketsInHands && visibility === 5) {
    return `In-hand tickets must be delivered within 24 hours of a confirmed sale`;
  }
  if (!record?.ticketsInHands && visibility === 5) {
    return "Optimise your listings to sell on the Tixstock Network";
  }
  return `Listing Quality`;
}

function visiblityCountForPayload(visibility: any) {
  if (visibility === 0 || visibility === 1 || visibility === 2) {
    return 0;
  }
  if (visibility === 3) {
    return 1;
  }
  if (visibility === 4) {
    return 2;
  }
  if (visibility === 5) {
    return 3;
  }
  if (visibility === 6) {
    return 4;
  }
}

function tooltipContentForTicketType(value: any) {
  if (value?.id === "pdf") {
    return "PDF ticket uploaded to Tixstock";
  }
  if (value?.id === "mobile-qr") {
    return "Screenshot of the ticket QR code uploaded to Tixstock (PDF).";
  }
  if (value?.id === "mobile") {
    return "External transfer of ticket or app login - proof of delivery to be uploaded to Tixstock.";
  }
  if (value?.id === "mobile-link") {
    return "Link for digital wallet ticket (NFC / PKPASS) uploaded to Tixstock - option to also provide an instruction letter.";
  }
  if (value?.id === "season-cards") {
    return "Physical membership cards delivered via hand delivery. Proof of Delivery to be uploaded to Tixstock.";
  }
  if (value?.id === "paper") {
    return "Physical tickets sent via tracked delivery or hand delivery. Proof of delivery must be uploaded to Tixstock for relevant delivery method used.";
  }
  if (value?.id === "local-delivery") {
    return "In-person delivery";
  }
}

const footer_dynamic = () => {
  const footer_elem = document.getElementsByClassName(
    "page_footer"
  )[0] as HTMLElement;
  if (footer_elem) {
    const footer_height = footer_elem.clientHeight;
    const bot_spacing = document.getElementsByClassName(
      "bot_spacing"
    )[0] as HTMLElement;
    // console.log(footer_height);
  }
};

const maxFileCondition = (quantity: any) => {
  return Math.min(quantity, MAX_FILES);
};

// const updatePPEPartners = (ppePartners: any = [], id: any, value: any) => {
//   const index = ppePartners.findIndex((partner: any) => partner.id === id);

//   if (index !== -1) {
//     // Update the existing partner using object spread
//     return ppePartners.map((partner: any, i: any) =>
//       i === index ? { ...partner, value } : partner
//     );
//   } else {
//     // Add a new partner if it doesn't exist
//     return [...ppePartners, { id, value }];
//   }
// };

// TICKETS IN HAND VALUE ASSIGNED DEPEND ON TICKET FILES
const ticketsInHandValueAssigned = (
  quantity: number | string,
  ticketFiles: any,
  ticketsInHands: boolean | string
) => {
  let result =
    quantity &&
    ticketFiles &&
    size(ticketFiles) > 0 &&
    checkTicketFilesEmptyOrNot(ticketFiles) > 0 &&
    size(ticketFiles) === Number(quantity)
      ? true
      : ticketsInHands;

  return result;
};

export {
  calculateDisplayPrice,
  checkAccordionCollapse,
  checkIfClonetoNewActive,
  convertDate,
  convertTime,
  copy,
  countExpandedAccordions,
  footer_dynamic,
  generateUniqueId,
  get2DaysBeforeDateOfEvent,
  getDateOnly,
  getPercentage,
  getPercentageValue,
  getTimeOnly,
  keyBoardVisiblityDynamicMessage,
  keyBoardVisiblityDynamicMessage1,
  maxFileCondition,
  reArrangeDeliveryArray,
  TicketError,
  ticketsInHandValueAssigned,
  tooltipContentForTicketType,
  // updatePPEPartners,
  visibilityChecker,
  visibilityMeterCalculationForKeyBoard,
  visiblityCountForPayload,
  visiblityMeter,
  visiblityMeterForBelowListing,
};
