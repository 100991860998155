import { useState } from "react";
import CloneSVG from "../../../../../assets/media/icons/other_icons/Clone.svg";
import TextInput from "../../../../../components/formComponent/TextInput";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import { copy } from "../../../../add_inventory/core/_functions";

const CopytoChipboard = ({
  headerName,
  value,
  defaultValue,
  id,
  padding = "p-2",
  margin = "mb-1",
  isLock,
  placeholder,
  inputFieldName,
  setState,
}: any) => {
  const [isCopied, setIsCopied] = useState<any>({
    id: "",
    value: "",
  });
  // HANDLE CLEAR THE FIELD
  const handleClear = (fieldData: string) => {
    setState((current: any) => {
      let final = {
        fund: {
          ...current?.fund,
          ...(current?.fund?.status
            ? {
                newData: {
                  ...current?.fund?.newData,
                  [fieldData]: "",
                },
              }
            : {}),
        },
        withdraw: {
          ...current?.withdraw,
          ...(current?.withdraw?.status
            ? {
                newData: {
                  ...current?.withdraw?.newData,
                  [fieldData]: "",
                },
              }
            : {}),
        },
      };
      return final;
    });
  };

  // HANDLE CHANGE
  const handleOnChange = (data: any) => {
    setState((current: any) => {
      let tabActive = current?.fund?.status;
      return {
        ...current,
        ...(tabActive
          ? {
              fund: {
                ...current?.fund,
                data: {
                  ...current?.fund?.data,
                  [data.target.name]: data?.target?.value,
                },
              },
            }
          : {
              withdraw: {
                ...current?.withdraw,
                data: {
                  ...current?.withdraw?.data,
                  [data.target.name]: data?.target?.value,
                },
              },
            }),
      };
    });
  };

  // HANDLE COPY
  const handleClick = (data: any) => {
    setIsCopied({
      id: data?.id,
      defaultValue: copy(data?.defaultValue),
    });
    setTimeout(() => {
      setIsCopied({
        id: "",
        defaultValue: "",
      });
    }, 1500);
  };

  return (
    <>
      <div className={`md:w-1/2 ${padding} w-full`}>
        <div
          className={`text-gray-400 text-sm12  ${margin}`}
          title={headerName}
        >
          {headerName}
        </div>
        {isLock ? (
          <div className="relative">
            <input
              type="text"
              value={defaultValue}
              className="text-sm12 rounded bg-gray-100 w-full border-none px-2 py-0 block text-violet-800 text-ellipsis overflow-hidden pr-6"
              disabled
            />
            {defaultValue !== "" && defaultValue !== null && defaultValue !== undefined && (
              <button
                className="flex items-center justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
                type="button"
                onClick={(e: any) => {
                  handleClick({ id, defaultValue });
                }}
              >
                <IKTSVG
                  className={`flex items-center justify-center transition
             ${
               isCopied.defaultValue === defaultValue && isCopied.id === id
                 ? "fill-green-600  hover:fill-green-600"
                 : "fill-violet-500 hover:fill-indigo-500"
             }
             `}
                  path={CloneSVG}
                  svgClassName="w-[.7813rem] h-[.6563rem]"
                />
              </button>
            )}
          </div>
        ) : (
          <div className="flex-1 relative">
            <TextInput
              type="text"
              value={value}
              name={inputFieldName}
              required={false}
              disabled={false}
              placeholder={placeholder}
              handleClearValue={() => handleClear(inputFieldName)}
              handleOnChange={(data: any) => handleOnChange(data)}
              inputHeight={"!h-6"}
              isIcon={false}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default CopytoChipboard;
