export const PopUpShimmer = () => {
  return (
    <div className="p-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
      <div className="border rounded">
        {Array.from({ length: 11 }, (item, i) => (
          <div className="border-b" key={i}>
            <div className="flex flex-wrap px-3 py-3  -mx-2">
              <div className="p-2 w-full  h-1.875rem ">
                <div className="shimmer-effect w-full h-[30px]"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
