import _ from "lodash";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import DropDownField from "../../../../components/formComponent/DropDownField";
import PriceField from "../../../../components/formComponent/PriceField";
import Textfield from "../../../../components/formComponent/Textfield";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import {
  decimalNumber,
  groupTeamMemberCommon,
} from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import {
  ORDER_ASSIGN_TO_NEW_TEAM,
  QUERIES,
  SYMBOLS,
} from "../../../../helpers/const";
import { getEventDetails } from "../../../add_inventory/core/_requsets";
import { SalesContext } from "../../core/SalesProvider";
import {
  getOrderReplacementTeamListingPrice,
  orderReplacement,
} from "../../core/_requests";
import BackToOrder from "./components/BackToOrder";
import EventDetails from "./components/EventDetails";
import Header from "./components/Header";
import HighligtedFilter from "./components/HighligtedFilter";
import Label from "./components/Label";
import SellarPenalityDetails from "./components/SellarPenalityDetails";

const OrderNewTeam = () => {
  const layout = useContext(SalesContext);

  let orderAssignNewTeam = layout?.orderAssignNewTeam?.data;

  const [categoryOptions, setCategoryOptions] = useState<any>();
  const [customNewOrderLoader, setCustomNewOrderLoader] =
    useState<boolean>(false);
  // STATE
  const [state, setState] = useState<any>({
    team_id: null,
    team_member: null,

    ticketDetails: {
      category: null,
      section: null,
      row: "",
      first_seat: "",
      ticketPrice: "",
    },
    penalty: {
      penaltyType: "absolute",
      penaltyValue: "",
      total: 0,
      notes: "",
    },
    orderAdmistration: {
      orderStatus: null,
      internalNote: "",
    },
    deduct_from_wallet: false,
  });

  // VARIABLES
  let isDisabled: boolean = false;

  let isActive: any = {
    reviewStatus: false,
    fieldStatus: false,
  };

  if (state?.team_id) {
    isActive.fieldStatus = true;
    if (
      state?.team_id &&
      state?.team_member &&
      (categoryOptions?.categories?.length === 0 ||
        state?.ticketDetails?.category) &&
      // state?.ticketDetails?.section &&
      // state?.ticketDetails?.row &&
      // state?.ticketDetails?.first_seat &&
      state?.ticketDetails?.ticketPrice?.value &&
      state?.penalty?.penaltyValue &&
      state?.penalty?.total &&
      state?.penalty?.notes &&
      state?.orderAdmistration?.orderStatus &&
      state?.orderAdmistration?.internalNote
    ) {
      isActive.reviewStatus = true;
    } else {
      isActive.reviewStatus = false;
    }
  } else {
    isActive.fieldStatus = false;
  }

  // IS CONFIRMED OR NOT
  layout?.orderAssignNewTeam?.confirmed
    ? (isDisabled = true)
    : (isDisabled = false);

  // LISTING API
  const { data } = useQuery(
    [`${QUERIES.GET_CATEGORY_LISTING}`],
    () => getEventDetails(orderAssignNewTeam?.event_id, ""),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled(data, error) {
        if (data?.status || data?.status === 200) {
          setCategoryOptions(data?.data?.data?.[0]);
          let categoryOptions = data?.data?.data?.[0]?.categories
            ? data?.data?.data?.[0]?.categories
            : [];

          let category = categoryOptions?.find(
            (item: any) => item?.name === orderAssignNewTeam?.category
          );

          setState((current: any) => {
            return {
              ...current,
              ticketDetails: {
                category: category,
                section: category?.sections?.find(
                  (item: any) =>
                    item?.name === orderAssignNewTeam?.seating_location
                ),
                row: orderAssignNewTeam?.row,
                first_seat: orderAssignNewTeam?.first_seat,
                ticketPrice: {
                  name: "ticketPrice",
                  value: orderAssignNewTeam?.ticket_price,
                  symbol: orderAssignNewTeam?.ticket_currency,
                },
              },
            };
          });
        }
      },
    }
  );
  let transaction_value: any =
    orderAssignNewTeam?.ticket_quantity *
    state?.ticketDetails?.ticketPrice?.value;

  // REPLACEMENT BLOCK - DATA
  let replacementData = {
    category: state?.ticketDetails?.category?.name,
    seating_location: state?.ticketDetails?.section?.name,
    first_seat: state?.ticketDetails?.first_seat,
    row: state?.ticketDetails?.row,
    ticket_currency: state?.ticketDetails?.ticketPrice?.symbol,
    ticket_price: state?.ticketDetails?.ticketPrice?.value,
    ticket_quantity: orderAssignNewTeam?.ticket_quantity,
    ticket_type: orderAssignNewTeam?.ticket_type,
    sub_ticket_type: orderAssignNewTeam?.sub_ticket_type,
    transaction_value: parseFloat(transaction_value).toFixed(2),
    broker_name: `${state?.team_id?.name} - ${state?.team_member?.name}`,
  };

  // PAYLOAD
  let payload = {
    fine_type:
      state?.penalty?.penaltyType === "absolute"
        ? "fixed_amount"
        : "percentage",
    fine_amount: state?.penalty?.total,
    assign_to: "team",
    team_id: state?.team_id?.id,
    user_id: state?.team_member?.id,
    order_id: orderAssignNewTeam?.order_id,
    order_status: state?.orderAdmistration?.orderStatus?.id,
    note_internal: state?.orderAdmistration?.internalNote,
    note_penalty: state?.penalty?.notes,
    row: replacementData?.row,
    first_seat: replacementData?.first_seat,
    ticket_price: replacementData?.ticket_price,
    ticket_section: replacementData?.seating_location,
    wallet_deduction: state?.deduct_from_wallet,
  };

  // REPLACEMENT API
  const { data: responseData, refetch } = useQuery(
    [`${QUERIES.REPLACEMENT_LIST_TEAM}`],
    () => orderReplacement(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onSettled(data: any, error: any) {
        setCustomNewOrderLoader(false);

        if (data?.data?.status) {
          showAlert(data?.message, false, 2000);

          setTimeout(() => {
            layout.setOrderAssignNewTeam(ORDER_ASSIGN_TO_NEW_TEAM);
            layout.setTabValue("cancelled");
            layout?.setQuery(orderAssignNewTeam?.order_id);
          }, 2000);
        } else if (data) {
          if (data?.data?.errors?.title) {
            showAlert(data?.data?.errors?.title, true);
          } else if (data?.data?.errors && _.size(data?.data?.errors) > 0) {
            showAlert(data?.data?.errors?.[0], true);
          } else {
            showAlert("Something went wrong.", true);
          }
          layout.setOrderAssignNewTeam((currentValue: any) => {
            return {
              ...currentValue,
              disabledButton: false,
            };
          });
        }
      },
    }
  );

  const handleTeamChange = (data: any) => {
    setState((currentValue: any) => {
      return {
        ...currentValue,
        [data?.target?.name]: data?.target?.value,
        team_member: null,
      };
    });
  };

  // LISTING API
  const { data: newTeamListingPrice, isLoading: teamListingPriceLoading } =
    useQuery(
      [`${QUERIES.ORDER_REPLACEMENT_TEAM_LISTING_PRICE}`, state?.team_id],
      () =>
        getOrderReplacementTeamListingPrice({
          order_id: orderAssignNewTeam?.order_id,
          team_id: state?.team_id?.id,
        }),
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        enabled: state?.team_id?.id ? true : false,
        onSettled(data) {
          if (data?.data?.status) {
            setState((currentValue: any) => {
              return {
                ...currentValue,
                available_amout: data?.data?.wallet_balance,
                ticketDetails: {
                  ...currentValue?.ticketDetails,
                  ticketPrice: {
                    ...currentValue?.ticketDetails?.ticketPrice,
                    value: data?.data?.target_team?.ticket_price,
                    symbol: data?.data?.target_team?.team_currency,
                  },
                },
              };
            });
          }
        },
      }
    );

  return (
    <React.Fragment>
      <div
        className={`show !scrollbar-thin  !scrollbar-thumb-violet-800 !scrollbar-thumb-rounded-full scrollbar-track-gray-650 !h-full overflow-y-auto overflow-x-hidden outline-none upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding  transition duration-300 ease-in-out font-medium w-full md:max-w-[calc(100%_-_3.75rem)] z-[50]  max-h-[calc(100%-3.75rem)] min-h-[calc(100%-3.75rem)] bg-gray-650 `}
      >
        <div className="relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600 h-full">
          <div className=" main-content-wrap  min-h-full bg-white flex flex-col">
            {/* BACK TO ORDER LISTING   */}
            <BackToOrder assign="team" confirmed={isDisabled} />

            <div className="inner-main-content p-5 overflow-auto h-full 123 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <div className="top-filterbar rounded border mb-5">
                {/* EVENT DETAILS */}
                <EventDetails data={orderAssignNewTeam} />

                {/* FILTER HIGHLIGHTED  */}
                <HighligtedFilter
                  data={orderAssignNewTeam}
                  NoBorderBottom={true}
                  confirmedScreen={true}
                />
              </div>

              {/* ASSIGN NEW TEAM */}
              <div className="replacement-listing rounded border mb-5">
                <Header
                  title={`Assign order to new team`}
                  className="ellips-text text-sm14 font-medium md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                />

                {/* DROPDOWN FIELDS  */}
                {!isDisabled ? (
                  <div className="panelty-status md:p-5 px-2.5 py-3.5 flex flex-wrap gap-y-5 -mx-2.5">
                    <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-2.5">
                      <DropDownField
                        value={state?.team_id}
                        name="team_id"
                        placeholder="Team"
                        // isDisabled={isDisabled}
                        isClearable={true}
                        options={layout?.teamsOptions?.filter(
                          (option: any) =>
                            option?.id !== orderAssignNewTeam?.team_id
                        )}
                        handleOnChange={(data: any) => handleTeamChange(data)}
                      />
                    </div>
                    {isActive?.fieldStatus && (
                      <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-2.5">
                        <DropDownField
                          value={state?.team_member}
                          name="team_member"
                          placeholder="Team member"
                          // isDisabled={isDisabled}
                          isClearable={true}
                          options={groupTeamMemberCommon([state?.team_id])}
                          handleOnChange={(data: any) =>
                            setState((currentValue: any) => {
                              return {
                                ...currentValue,
                                [data?.target?.name]: data?.target?.value,
                              };
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {/* FILTER HIGHLIGHTED  */}
                    <HighligtedFilter
                      data={replacementData}
                      bgColor="bg-white"
                      NoBorderBottom={true}
                      confirmedScreen={true}
                    />
                  </>
                )}
              </div>

              {isActive?.fieldStatus && (
                <>
                  {/* EDITABLE - BLOCK */}
                  {!isDisabled && (
                    <div className="order-administration rounded border mb-5 anim-fade z-[11] relative">
                      <Header
                        title={`Amend ticket details`}
                        className="ellips-text text-sm14 font-medium max-w-[18.75rem] md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                      />

                      {/* INPUT FIELDS  */}
                      <div className="panelty-status md:p-5 px-2.5 py-3.5 flex flex-wrap gap-y-5 -mx-2.5 ">
                        {/* CATEGORY */}
                        <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-2.5">
                          <DropDownField
                            value={state?.ticketDetails?.category}
                            name="category"
                            placeholder="Category"
                            // isDisabled={isDisabled}
                            isClearable={true}
                            options={categoryOptions?.categories}
                            handleOnChange={(data: any) =>
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  ticketDetails: {
                                    ...currentValue?.ticketDetails,
                                    [data?.target?.name]: data?.target?.value,
                                    section: null,
                                  },
                                };
                              })
                            }
                          />
                        </div>

                        {/* SECTION */}
                        <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-2.5">
                          <DropDownField
                            value={state?.ticketDetails?.section}
                            name="section"
                            placeholder="Section/block"
                            // isDisabled={isDisabled}
                            isClearable={true}
                            options={state?.ticketDetails?.category?.sections}
                            handleOnChange={(data: any) =>
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  ticketDetails: {
                                    ...currentValue?.ticketDetails,
                                    [data?.target?.name]: data?.target?.value,
                                  },
                                };
                              })
                            }
                          />
                        </div>

                        {/* ROW */}

                        <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  flex flex-wrap gap-y-5">
                          <div className="panelty-input md:w-1/2 w-full px-2.5">
                            <Textfield
                              name="row"
                              id={"row-new-order"}
                              value={state?.ticketDetails?.row}
                              label={"Row"}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  return {
                                    ...currentValue,
                                    ticketDetails: {
                                      ...currentValue?.ticketDetails,
                                      [data?.target?.name]: data?.target?.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="panelty-input md:w-1/2 w-full px-2.5">
                            <Textfield
                              name="first_seat"
                              value={state?.ticketDetails?.first_seat}
                              id={"first-seat-new-order"}
                              label={"First seat"}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  return {
                                    ...currentValue,
                                    ticketDetails: {
                                      ...currentValue?.ticketDetails,
                                      [data?.target?.name]: data?.target?.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>

                        {/* SEAT */}

                        {/* TICKET PRICE */}
                        <div
                          className={`panelty-input lg:w-1/4 md:w-1/2 px-2.5 w-full ${
                            teamListingPriceLoading ? `shimmer-effect` : ``
                          }`}
                        >
                          <PriceField
                            value={state?.ticketDetails?.ticketPrice?.value}
                            currenncySymbol={
                              state?.ticketDetails?.ticketPrice?.symbol
                            }
                            name="ticketPrice"
                            label={"Ticket price"}
                            textFielClassName={`truncate`}
                            id={`ticketPrice`}
                            handleOnChange={(data: any) => {
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  ticketDetails: {
                                    ...currentValue?.ticketDetails,
                                    [data?.target?.name]: data?.target?.value,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="order-panelty rounded border mb-5 anim-fade">
                    {/* HEADER */}
                    <Header
                      title="Order penalty"
                      className="ellips-text text-sm14 font-medium md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                    />
                    <SellarPenalityDetails
                      currenncy={SYMBOLS?.[orderAssignNewTeam?.refund_currency]}
                      inputValue={state?.penalty?.total}
                      amount={state?.available_amout}
                      checkedValue={state?.deduct_from_wallet}
                      onChange={(e: any) =>
                        setState((currentValue: any) => {
                          return {
                            ...currentValue,
                            deduct_from_wallet: e.target.checked,
                          };
                        })
                      }
                      confirmation={layout?.orderAssignNewTeam?.confirmed}
                      sellarName={orderAssignNewTeam?.team_name}
                    />

                    {/* PENALITY INPUT FIELDS - BLOCK */}
                    <div
                      className={`${
                        !isDisabled ? "bg-indigo-500" : "bg-white"
                      }  panelty-block md:px-5 md:py-4 p-2.5 flex flex-wrap items-center bg-opacity-[7%] md:gap-x-5 gap-2.5 border-b`}
                    >
                      {!isDisabled && (
                        <>
                          <div className="panelty-radio flex items-center gap-[.9375rem] font-medium md:w-auto w-full">
                            <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                              <input
                                type="radio"
                                name="penaltyType"
                                value="absolute"
                                checked={
                                  state?.penalty?.penaltyType === "absolute"
                                    ? true
                                    : false
                                }
                                id="absolutetype"
                                onChange={(e: any) => {
                                  setState((current: any) => {
                                    return {
                                      ...current,
                                      penalty: {
                                        ...current?.penalty,
                                        penaltyType: "absolute",
                                        penaltyValue: "",
                                        total: 0,
                                      },
                                    };
                                  });
                                }}
                              />
                              <label htmlFor="absolutetype" className="">
                                ({SYMBOLS[orderAssignNewTeam?.ticket_currency]})
                                Amount
                              </label>
                            </div>
                            <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                              <input
                                type="radio"
                                name="penaltyType"
                                value="relative"
                                checked={
                                  state?.penalty?.penaltyType === "relative"
                                    ? true
                                    : false
                                }
                                id="relativetype"
                                onChange={(e: any) => {
                                  setState((current: any) => {
                                    return {
                                      ...current,
                                      penalty: {
                                        ...current?.penalty,
                                        penaltyType: "relative",
                                        penaltyValue: "",
                                        total: 0,
                                      },
                                    };
                                  });
                                }}
                              />
                              <label htmlFor="relativetype" className="">
                                (%) Percentage
                              </label>
                            </div>
                          </div>
                          <div className="panelty-input max-w-[9.375rem]">
                            {/* {JSON.stringify(orderAssignNewTeam?.ticket_currency)} */}
                            <PriceField
                              value={state?.penalty?.penaltyValue}
                              currenncySymbol={
                                state?.penalty?.penaltyType === "absolute"
                                  ? orderAssignNewTeam?.ticket_currency
                                  : "PER"
                              }
                              name="penaltyValue"
                              label="Penalty value"
                              textFielClassName={`truncate`}
                              id={`penaltyValue`}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  const penaltyType =
                                    currentValue?.penalty?.penaltyType;
                                  const orderValue =
                                    orderAssignNewTeam?.transaction_value;
                                  const penaltyValue =
                                    data?.target?.value?.value;

                                  let total: any = 0;

                                  if (penaltyType === "relative") {
                                    total = (
                                      (penaltyValue / 100) *
                                      orderValue
                                    ).toFixed(2);
                                  } else if (penaltyType === "absolute") {
                                    total = penaltyValue;
                                  }

                                  return {
                                    ...currentValue,
                                    deduct_from_wallet:
                                      Number(total) >
                                      Number(state?.available_amout)
                                        ? false
                                        : currentValue?.deduct_from_wallet,
                                    penalty: {
                                      ...currentValue?.penalty,
                                      penaltyValue,
                                      total,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </>
                      )}
                      <h5 className="text-sm14 font-semibold">
                        Penalty total:{" "}
                        {`${
                          SYMBOLS[orderAssignNewTeam?.ticket_currency]
                        }${decimalNumber(state?.penalty?.total)}`}
                      </h5>
                    </div>

                    {/* PENALITY NOTES - BLOCK */}
                    <div className="panelty-note md:px-5 md:py-5 px-2.5 py-3.5 -mx-2.5">
                      <div className="md:w-1/2 w-full px-2.5">
                        <Label data="Penalty notes" required={true} />
                        {!isDisabled ? (
                          <input
                            className={`bg-opacity-50 border 
                          font-medium transition bg-gray-100 focus:border-indigo-500 focus:bg-white outline-none peer
                                 border-gray-300 whitespace-normal text-sm12 rounded  px-2 block text-violet-800 resize-none py-1.5 leading-4 w-full`}
                            name="notes"
                            value={state?.penalty?.notes}
                            disabled={isDisabled}
                            onChange={(e: any) => {
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  penalty: {
                                    ...currentValue?.penalty,
                                    notes: e?.target?.value,
                                  },
                                };
                              });
                            }}
                          />
                        ) : (
                          <div className="bg-opacity-100  !border-gray-100 text-violet-800 font-medium transition bg-gray-100 focus:border-indigo-500 focus:bg-white outline-none peer whitespace-normal text-sm12 rounded  px-2 block  resize-none py-1.5 leading-4 w-full min-h-[1.875rem]">
                            {state?.penalty?.notes}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* ORDER ADMINISTRATION - BLOCK */}
                  <div className="order-administration rounded border ">
                    <Header
                      title="Order administration (internal)"
                      className="ellips-text text-sm14 font-medium max-w-[18.75rem] md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                    />

                    {/* INPUT FIELDS  */}
                    <div className="panelty-status md:p-5 px-2.5 py-3.5 flex flex-wrap gap-y-5 -mx-2.5">
                      <div className="panelty-note lg:w-1/4 md:w-1/2 w-full px-2.5">
                        <Label data="Internal order status" required={true} />
                        <DropDownField
                          value={state?.orderAdmistration?.orderStatus}
                          name="orderStatus"
                          placeholder={`${
                            state?.orderAdmistration?.orderStatus
                              ? ""
                              : "Internal order status"
                          }`}
                          isDisabled={isDisabled}
                          isClearable={true}
                          options={
                            layout?.salesOptions?.internal_order_statuses
                          }
                          handleOnChange={(data: any) =>
                            setState((currentValue: any) => {
                              return {
                                ...currentValue,
                                orderAdmistration: {
                                  ...currentValue?.orderAdmistration,
                                  [data?.target?.name]: data?.target?.value,
                                },
                              };
                            })
                          }
                        />
                      </div>
                      <div className="panelty-note md:w-1/2 w-full px-2.5">
                        <Label data="Internal notes" required={true} />

                        {!isDisabled ? (
                          <input
                            className={`bg-opacity-50 border font-medium transition bg-gray-100  focus:border-indigo-500 focus:bg-white outline-none peer
                                 border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1.5 leading-4`}
                            name="internalNote"
                            value={state?.orderAdmistration?.internalNote}
                            disabled={isDisabled}
                            onChange={(e: any) => {
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  orderAdmistration: {
                                    ...currentValue?.orderAdmistration,
                                    internalNote: e?.target?.value,
                                  },
                                };
                              });
                            }}
                          />
                        ) : (
                          <div
                            className="bg-opacity-50 border text-violet-800 font-medium transition bg-gray-100  focus:border-indigo-500 focus:bg-white outline-none peer
                                 border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block resize-none py-1.5 leading-4 min-h-[1.875rem]"
                          >
                            {state?.orderAdmistration?.internalNote}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* ACTION BUTTONS */}
            <div className=" offcanvas-footer bottom-0 right-0 w-full flex flex-wrap justify-between px-5 py-3 bg-white z-10 border-t">
              {!layout?.orderAssignNewTeam?.confirmed &&
              !layout?.orderAssignNewTeam?.disabledButton ? (
                <div className="l_btn gap-2.5 flex flex-wrap items-center justify-end w-full">
                  <button
                    type="button"
                    className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                    onClick={() => {
                      layout.setOrderAssignNewTeam(ORDER_ASSIGN_TO_NEW_TEAM);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`${
                      isActive?.reviewStatus
                        ? "hover:text-white active: bg-violet-500 text-white hover:bg-indigo-500 active:bg-indigo-500 anim-fade"
                        : "bg-gray-100 text-gray-500 pointer-events-none"
                    } relative z-10    transition  rounded text-sm13 px-2.5 py-0.5`}
                    onClick={() => {
                      layout.setOrderAssignNewTeam((currentValue: any) => {
                        return {
                          ...currentValue,
                          confirmed: true,
                        };
                      });
                    }}
                  >
                    Review
                  </button>
                </div>
              ) : (
                layout?.orderAssignNewTeam?.confirmed &&
                !layout?.orderAssignNewTeam?.disabledButton && (
                  <div className="l_btn gap-2.5 flex flex-nowrap items-center justify-end w-full overflow-auto whitespace-nowrap max-md:justify-start">
                    <span className="text-sm13">
                      Do you confirm all details are correct?
                    </span>
                    <button
                      type="button"
                      className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                      onClick={() => {
                        layout.setOrderAssignNewTeam((currentValue: any) => {
                          return {
                            ...currentValue,
                            confirmed: false,
                          };
                        });
                      }}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className={` relative z-10   hover:text-white active: bg-violet-500 text-white hover:bg-indigo-500 active:bg-indigo-500 transition  rounded text-sm13 px-2.5 py-0.5`}
                      onClick={() => {
                        layout.setOrderAssignNewTeam((currentValue: any) => {
                          return {
                            ...currentValue,
                            confirmed: false,
                            disabledButton: true,
                          };
                        });
                        setCustomNewOrderLoader(true);
                        refetch();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {customNewOrderLoader && (
        <ProgressBardLoader LoadingText="" secondCounter={1.5} />
      )}
    </React.Fragment>
  );
};

export default OrderNewTeam;
