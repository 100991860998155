const OrderDetailBox = ({
  children,
  title,
  className,
  headerClass = "md:w-1/2 w-full mb-5 px-2.5",
  lockIcon,
}: any) => {
  return (
    <div className={headerClass}>
      <div className="border rounded h-full bg-white">
        <div
          className={`${
            lockIcon && "flex gap-2"
          }  title border-b  justify-between`}
        >
          <span className="md:text-sm15 text-sm12 block font-semibold md:px-5 px-3 py-[9px] flex-1">
            {" "}
            {title}
          </span>
          {lockIcon && lockIcon}
        </div>
        <div className={className}>{children}</div>
      </div>
    </div>
  );
};

export default OrderDetailBox;
