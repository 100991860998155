import React from "react";
import PageTitle from "../../components/core/PageTitle";
import Reports from "./reports";
import { ReportsProvider } from "./core/ReportsProvider";



const ReportsWrapper = () => {
  return (
    <>
      <ReportsProvider>
          <PageTitle title="Reports" />
          <Reports/>
      </ReportsProvider>
    </>
  );
};

export default ReportsWrapper;
