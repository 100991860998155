const TableHeader = ({
  width,
  title,
  className,
  cellWidth,
  isArrow = false,
}: any) => {
  return (
    <>
      <th className={`px-2.5 py-3 font-medium whitespace-nowrap ${cellWidth}`}>
        {isArrow ? (
          <div className="w-full pr-3 table-head-select">{title}</div>
        ) : (
          title
        )}
      </th>
    </>
  );
};

export default TableHeader;
