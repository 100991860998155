import { useContext } from "react";
import { ConnectorContext } from "../../core/ConnectorProvider";

const BodyContent = () => {
  const layout = useContext(ConnectorContext);
  const state: any = layout?.state;

  // HANDLE ONCHANGE
  const handleOnChange = (data: any) => {
    layout.setState((current: any) => ({
      ...current,
      body: data.target.value,
    }));
  };

  const formatJSON = (jsonString: any) => {
    try {
      const jsonObject = JSON.parse(jsonString);
      return JSON.stringify(jsonObject, null, 2); // Pretty print JSON with 2 spaces indentation
    } catch (error) {
      // If JSON.parse fails, return the original string
      return jsonString;
    }
  };

  return (
    <div className="flex flex-col mt-4">
      <textarea
        name="body"
        id="body-auth"
        value={formatJSON(state?.body)}
        onChange={(e: any) => handleOnChange(e)}
        className="whitespace-pre-line scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full text-sm12 rounded bg-gray-100 w-full border-none px-2 block text-violet-800 resize-none min-h-[12rem] py-1"
        style={{ whiteSpace: "pre" }}
      ></textarea>
    </div>
  );
};

export default BodyContent;
