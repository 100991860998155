import React, { useContext } from "react";
import Checkbox from "../../../../components/formComponent/Checkbox";
import DatePickerField from "../../../../components/formComponent/DatePickerField";
import DropDownField from "../../../../components/formComponent/DropDownField";
import MultiDropDownField from "../../../../components/formComponent/MultiDropDownField";
import { handleSelectDeselectAll } from "../../../../helpers/Functions";
import { WalletAndBankDetailsContext } from "../core/WalletAndBankDetailsProvider";

const CommonFilter = ({ handleOnChange, options, value, loading }: any) => {
  const layout = useContext(WalletAndBankDetailsContext);

  // HEADER FILTER
  const getFilterItems = () =>
    layout.filterItems?.filter((obj: any) => obj?.checked === true);

  // HANDLE SELECT-DESELECT FOT TEAMS
  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any,
    searchName: string = "name"
  ) => {
    let isNameAvailable = options?.some((item: any) => item?.[`${searchName}`]);

    // MAP WHICH IS STATE
    const filterSetterMap: any = {
      "Seller wallets": layout.setSellerWalletsFilter,
      "Bank accounts": layout.setBankAccountFilter,
      "Withdraw requests": layout.setWithdrawRequestFilter,
    };

    const filterSetter = filterSetterMap[layout.isTabActive];

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      filterSetter,
      false,
      searchName
    );

    // IF ALL SELECTED
    // if (data?.target?.id === "select-all") {
    //   filterSetter((currentValue: any) => {
    //     let final = {
    //       ...currentValue,
    //       [fieldName]: isNameAvailable
    //         ? options?.filter((option: any) => option?.[`${searchName}`])
    //         : extractOptions(options),
    //       page: 1,
    //     };
    //     delete final?.id;
    //     return final;
    //   });
    // } else if (data?.target?.id === "deselect-all") {
    //   filterSetter((currentValue: any) => {
    //     let final = {
    //       ...currentValue,
    //       [fieldName]: [],
    //       page: 1,
    //     };
    //     delete final?.id;
    //     return final;
    //   });
    // }
  };

  return (
    <React.Fragment>
      {getFilterItems()?.map((columnItem: any, index: number) => {
        switch (columnItem.id) {
          case 1:
            return (
              <React.Fragment key={index}>
                {/* Team */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <MultiDropDownField
                      classNamePrefix={"form_multiDropDown"}
                      placeholder="Teams"
                      options={options?.options_teams}
                      name="teams"
                      value={value?.teams}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      handleOnSelection={(data: any, visibleOptions: any) => {
                        handleOnSelection(
                          data,
                          "teams",
                          options?.options_teams,
                          visibleOptions
                        );
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );

          case 14:
            return (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
              >
                <div className={` rounded  ${loading && "shimmer-effect"} `}>
                  <MultiDropDownField
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Wallet status"
                    options={options?.options_walletstatus}
                    name="statuses"
                    value={value?.statuses}
                    handleOnChange={(data: any) => {
                      handleOnChange(data);
                    }}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "statuses",
                        options?.options_walletstatus,
                        visibleOptions
                      )
                    }
                  />
                </div>
              </div>
            );
          case 15:
            return (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
              >
                <div className={` rounded  ${loading && "shimmer-effect"} `}>
                  <MultiDropDownField
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Withdraw request status"
                    options={options?.options_withdraw_request_statuses}
                    name="statuses"
                    value={value?.statuses}
                    handleOnChange={(data: any) => {
                      handleOnChange(data);
                    }}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "statuses",
                        options?.options_withdraw_request_statuses,
                        visibleOptions
                      )
                    }
                  />
                </div>
              </div>
            );
          case 16:
            return (
              <React.Fragment key={index}>
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DatePickerField
                      name="requestedDate"
                      placeholder="Requested date"
                      disabledKeyboardNavigation={false}
                      isDateRange={true}
                      value={value?.requestedDate}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 19:
            return (
              <React.Fragment key={index}>
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <Checkbox
                      label="Change requested"
                      id="txpay_change_request"
                      name="txpay_change_request"
                      labelclass="text-gray-500"
                      checked={value?.txpay_change_request}
                      tooltip={false}
                      handleOnChange={(data: any, e: any) => {
                        handleOnChange(data);
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          case 20:
            return (
              <React.Fragment key={index}>
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] min-w-[16.25rem] px-[.3125rem]  `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DropDownField
                      options={[
                        { id: 1, name: "Yes", value: 1 },
                        { id: 0, name: "No", value: 0 },
                      ]}
                      placeholder="Withdrawal bank account configured"
                      isClearable={true}
                      value={value?.is_withdrawal_acc_configured}
                      name="is_withdrawal_acc_configured"
                      // value={formik.values.country_id}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
        }
      })}
    </React.Fragment>
  );
};

export default CommonFilter;
