const TXNetwork = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="92.196"
      height={26}
      viewBox="0 0 92.196 26"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_30415"
            data-name="Rectangle 30415"
            width="92.196"
            height="25.999"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_4465" data-name="Group 4465" transform="translate(0 0)">
        <path
          id="Path_48633"
          data-name="Path 48633"
          d="M11.255,0,0,6.5v13L11.255,26,22.51,19.5V6.5Z"
          transform="translate(0 0)"
          fill="#41007f"
        />
        <path
          id="Path_48634"
          data-name="Path 48634"
          d="M6.28,24.573V23.292h7.606v1.281H10.8V30.8H9.368V24.573ZM23.3,23.509l-3.031,3.534L23.3,30.578V30.8H21.611l-2.368-2.8-2.379,2.8H15.171v-.217L18.2,27.043l-3.031-3.534v-.217h1.693l2.379,2.8,2.379-2.8H23.3Z"
          transform="translate(-3.786 -14.044)"
          fill="#00e500"
        />
        <path
          id="Path_48635"
          data-name="Path 48635"
          d="M72.258,23.292v7.5H70.851l-4.621-5.5v5.5h-1.4v-7.5h1.407l4.621,5.49v-5.49Z"
          transform="translate(-39.092 -14.044)"
          fill="#41007f"
        />
        <path
          id="Path_48636"
          data-name="Path 48636"
          d="M95.143,23.292v1.281h-5.1v1.819h4.872v1.281H90.042v1.841h5.1V30.8H88.612v-7.5Z"
          transform="translate(-53.428 -14.044)"
          fill="#41007f"
        />
        <path
          id="Path_48637"
          data-name="Path 48637"
          d="M107.811,24.574V23.293h7.606v1.281h-3.088V30.8H110.9V24.574Z"
          transform="translate(-65.003 -14.044)"
          fill="#41007f"
        />
        <path
          id="Path_48638"
          data-name="Path 48638"
          d="M142.11,23.292v.218L139.422,30.8H137.7l-2.116-5.719-2.1,5.719h-1.739l-2.7-7.285v-.218h1.452l2.127,5.936,2.185-5.936h1.555l2.2,5.913,2.1-5.913Z"
          transform="translate(-77.802 -14.044)"
          fill="#41007f"
        />
        <g id="Group_4466" data-name="Group 4466" transform="translate(0 0)">
          <g
            id="Group_4465-2"
            data-name="Group 4465"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_48639"
              data-name="Path 48639"
              d="M165.469,30.255a3.526,3.526,0,0,1-1.527-1.333A3.738,3.738,0,0,1,163.4,26.9v-.125a3.768,3.768,0,0,1,.538-2.025,3.51,3.51,0,0,1,1.533-1.338,5.911,5.911,0,0,1,4.615,0,3.516,3.516,0,0,1,1.528,1.338,3.775,3.775,0,0,1,.538,2.025V26.9a3.746,3.746,0,0,1-.538,2.019,3.535,3.535,0,0,1-1.521,1.333,5.94,5.94,0,0,1-4.626,0m4.472-1.5a2.263,2.263,0,0,0,.76-1.824v-.16a2.3,2.3,0,0,0-.76-1.836,3.873,3.873,0,0,0-4.318,0,2.289,2.289,0,0,0-.766,1.836v.16a2.254,2.254,0,0,0,.766,1.824,3.928,3.928,0,0,0,4.318,0"
              transform="translate(-98.523 -13.836)"
              fill="#41007f"
            />
            <path
              id="Path_48640"
              data-name="Path 48640"
              d="M196.47,27.312a2.723,2.723,0,0,1-1.692.715l2.264,2.551v.217H195.4l-2.447-2.745h-1.921v2.745H189.6v-7.5h4.689a3.052,3.052,0,0,1,2.047.635,2.159,2.159,0,0,1,.744,1.733V25.8a2.042,2.042,0,0,1-.607,1.515m-.846-1.71a.9.9,0,0,0-.355-.761,1.663,1.663,0,0,0-1.018-.268h-3.225v2.207h3.225a1.66,1.66,0,0,0,1.018-.269.91.91,0,0,0,.355-.772Z"
              transform="translate(-114.316 -14.044)"
              fill="#41007f"
            />
            <path
              id="Path_48641"
              data-name="Path 48641"
              d="M220.491,23.509l-3.66,3.1,3.523,3.969V30.8h-1.738l-2.882-3.248L214.212,28.8V30.8h-1.43v-7.5h1.43V27l4.369-3.706h1.91Z"
              transform="translate(-128.295 -14.044)"
              fill="#41007f"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { TXNetwork };
