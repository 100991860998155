import ReactDOM from "react-dom";
import { Tooltip } from "react-tooltip";

interface TooltipPortalInterface {
  id: any;
  content: any;
  place?: string;
  variant?: string;
  positionStrategy?: string;
  className: string;
  style?: any;
  isOpen?: any;
  text13?: any;
}

const TooltipPortal: React.FC<TooltipPortalInterface> = ({
  id,
  content,
  place = "top",
  variant = "light",
  positionStrategy = "fixed",
  className,
  style,
  isOpen = undefined,
  text13 = false,
}: any) => {
  const portalRoot = document.body;
  if (!portalRoot) return null;

  return ReactDOM.createPortal(
    <Tooltip
      id={id}
      isOpen={isOpen}
      html={content}
      place={place}
      variant={variant}
      positionStrategy={positionStrategy}
      className={`${className} ${text13 === true ? "!text-sm13" : "!text-xs"}`}
      style={style}
    />,
    document.body
  );
};

export default TooltipPortal;
