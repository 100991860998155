import NoDataComponent from "components/tableComponent/NoDataComponent";
import { QUERIES } from "helpers/const";
import { useContext } from "react";
import { useQuery } from "react-query";
import CommonFilters from "./component/CommonFilters";
import LevelConfigurationPopup from "./component/LevelConfigurationPopup";
import {
  sellerLevelConfiguration,
  sellerLevelOptionsWithConfig,
} from "./core/request";
import { SellerLevelsProviderContex } from "./core/SellerLevelsProvider";
import DataTable from "./table/DataTable";

const SellerLevels = () => {
  const layout = useContext(SellerLevelsProviderContex);
  const tableData: any = layout.sellerLevelData?.tableData || [];

  // GET LISTINGS
  const { isLoading: loading, refetch: refetchLevelAPi } = useQuery(
    [QUERIES.SELLER_LEVEL_LIST],
    () => sellerLevelConfiguration(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      // enabled: true,
      onSettled(data, error) {
        const newData = {
          count: data?.data?.count,
          tableData: data?.data?.lists || [],
          options: data?.data?.options,
        };
        layout.setSellerLevelData(newData);
      },
    }
  );

  // GET options
  const { data: levelConfigData } = useQuery(
    [QUERIES.SELLER_LEVEL_OPTION],
    () => sellerLevelOptionsWithConfig(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
    }
  );

  return (
    <div className="main-content-wrap">
      <div
        className={`topFilters sticky-element top-[60px] z-[11] pb-5 bg-gray-200 max-md:!static`}
        id="topFilters"
      >
        <CommonFilters loading={loading} />
      </div>

      {loading ? (
        Array.from({ length: 3 }, (v, i) => (
          <div
            className={`accordion font-medium  mb-2.5 mx-5 ${
              i === 0 && "mt-0"
            } `}
          >
            <div
              key={i}
              className={`accordion-item bg-white !rounded overflow-hidden ${
                loading && "shimmer-effect"
              } `}
              style={{ height: "40px" }}
            ></div>
          </div>
        ))
      ) : (
        <DataTable refetch={refetchLevelAPi} />
      )}

      {!loading && tableData?.length === 0 ? <NoDataComponent /> : null}

      <LevelConfigurationPopup
        configData={levelConfigData}
        refetch={refetchLevelAPi}
      />
    </div>
  );
};

export default SellerLevels;
