import React from "react";
import { IKTSVG } from "../../../components/ui/IKTSVG";

interface InputFieldInterface {
  imgPath?: any;
  placeholder?: any;
  id?: any;
  name?: string;
  value?: any;
  handleOnChange?: any;
  autoComplete?: string;
  className?: string;
  onKeyPress?: any;
}

const InputField: React.FC<InputFieldInterface> = ({
  imgPath,
  placeholder,
  id,
  name,
  value,
  handleOnChange,
  className,
  autoComplete,
  onKeyPress,
}: any) => {
  return (
    <div className="input-field w-full">
      <div className="field-block relative">
        <IKTSVG
          path={imgPath}
          className="fill-violet-500 transition absolute top-1/2 -translate-y-1/2 left-2"
          svgClassName="w-[15px] h-3.5"
        />
        <input
          className={
            className ||
            "w-full pl-[30px] py-0.5 text-sm15 font-medium rounded border-violet-100/20 bg-violet-100 bg-opacity-[7%]"
          }
          type="text"
          autoComplete={autoComplete}
          placeholder={placeholder}
          id={id}
          name={name}
          value={value ? value : ""}
          onChange={(data) => {
            data?.preventDefault();
            let event = {
              target: { name: data.target.name, value: data.target.value },
            };
            handleOnChange(event);
          }}
          onKeyPress={onKeyPress}
        />
      </div>
    </div>
  );
};

export default InputField;
