const DataCell = ({
  value,
  subValue,
  classname = "",
  width = "",
  title = "",
  padding,
  noCapitalize,
  children,
}: any) => {
  return (
    <>
      <td
        className={`p-1.5 font-medium h-[2.8125rem] text-ellipsis overflow-hidden  ${padding} max-w-[${width}] w-[${width}] `}
      >
        <span className=" ">
          <p
            className={`${width} ${classname} ${
              noCapitalize === "yes" ? "" : "capitalize"
            } ${value === "-" && "text-center"} `}
            title={value ? value : ""}
          >
            {children ? children : value}
          </p>
          {/* {subValue && (
            <small className="text-xxs text-gray-500">{subValue}</small>
          )} */}
        </span>
      </td>
    </>
  );
};

export default DataCell;
