import React from "react";

interface TableHeaderInterface {
  title: string;
  width: string;
}

const TableHeader: React.FC<TableHeaderInterface> = ({ title, width }: any) => {
  return (
    <th className={`md:px-4 px-3 py-3 font-medium whitespace-nowrap ${width}`}>
      <div className="w-full pr-4">{title}</div>
    </th>
  );
};

export default TableHeader;
