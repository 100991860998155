const Astrick = ({ error }: any) => {
  return (
    <span
      className={`${error ? "text-red-500" : "text-gray-400"} ml-1 asterisk`}
    >
      *
    </span>
  );
};

export default Astrick;
