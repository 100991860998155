const Plus = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10}
      height={10}
      viewBox="0 0 10 10"
      {...props}
    >
      <g id="Group_140" data-name="Group 140" transform="translate(-140 -130)">
        <rect
          id="Rectangle_15"
          data-name="Rectangle 15"
          width={10}
          height="1.5"
          transform="translate(140 134)"
        />
        <rect
          id="Rectangle_16"
          data-name="Rectangle 16"
          width={10}
          height="1.5"
          transform="translate(146 130) rotate(90)"
        />
      </g>
    </svg>
  );
};

export { Plus };
