import PageTitle from "../../components/core/PageTitle";
import TXPay from "./TXPay";
import { TXProvider } from "./core/TXProvider";
import Footer from "./layout/Footer";

const TXWrapper = () => {
  return (
    <>
      <TXProvider>
        <PageTitle title="TX Pay" />
        <TXPay />
        <Footer />
      </TXProvider>
    </>
  );
};

export default TXWrapper;
