export type SortState = {
  sort?: string;
  order?: "asc" | "desc";
};

export const initialOptions: any = [
  { id: "top_trending", name: "Top trending" },
  // { id: "trending_tab", name: "Trending tab" },
  // { id: "trending_tab_banner", name: "Trending tab banner" },
  // { id: "concert_tab", name: "Concert tab" },
  // { id: "concert_tab_banner", name: "Concert tab banner" },
  // { id: "sports_tab", name: "Sports tab" },
  // { id: "sports_tab_banner", name: "Sports tab banner" },
  // { id: "trending_clubs", name: "Trending clubs" },
  // { id: "popular_events", name: "Popular events" },
  // { id: "popular_events_banner", name: "Popular events banner" },
  { id: "popular_cities", name: "Popular cities" },
  { id: "popular_events", name: "Popular events" },
  { id: "upcoming_events", name: "Upcoming events" },
  { id: "new_events", name: "New events" },
];

export const initialStateForHomeDesign = {
  types: { id: "", name: "" },
  type_components: [
    {
      compoType: "",
      compoId: "",
      compoName: "",
    },
  ],
};

export const initialContentState = {
  eventName: [],
  per_page: 20,
  page: 1,
  title: "",
};

export const initialRecordFilters = { keyword: "" };

export const initialIsPopupActiveState = {
  tabActive: "",
  status: false,
  data: undefined,
};

export const algoliaIndexes = [
  "Event",
  "Performer",
  "Venue",
  "Category",
  "City",
];

export type QueryState = SortState;
