/* eslint-disable array-callback-return */

import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TableVirtuoso } from "react-virtuoso";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  addCommaInNumber,
  getCurrencySymbol,
} from "../../../helpers/Functions";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { TxAccountContext } from "../core/TxAccountProvider";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  md:min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTablePayouts = ({ paginateData, loading, setIsLoading }: any) => {
  const layout = useContext(TxAccountContext);
  const payOutData = layout.payoutList;

  const tableRef: any = useRef<any>();
  const stickyTableRef: any = useRef<any>();
  const [height, setHeight] = useState<any | undefined>(undefined);

  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  let timeout: any;
  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#payout-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      // setTimeout(() => {
      //   nomoreListingHeight = nomoreListing?.clientHeight;
      // }, 2000);

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight - 10;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
      // if (topValue.includes("-")) {
      // Check if the value contains a negative sign
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive
      topfilterTop && (topfilterTop.style.top = `${newValue}px`); // Apply the new positive value back as a string
      // }
      const windScroll: number = window.scrollY;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;
      // const totleScroll = headingOne + inventoryaccordion + 16;
      // const currentTop = parseInt(topfilterTop.style.top || "0");
      const topFiltersHeight = topfilterTop.clientHeight;
      let totleScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totleScroll = topFiltersHeight;
      } else {
        totleScroll = headingOne + inventoryaccordion + 16;
      }

      const stickyHead: any = document.getElementById("sticky-head");
      const stickyShadow: any = document.getElementById("sticky-shadow");

      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  // HANDLE CHECK
  const handleCheck = (e: any, id: string) => {
    layout.setPayoutList((prev: any) => {
      return prev.map((current: any) => {
        if (current.id === id) {
          return {
            ...current,
            selected: e === true ? true : e?.target?.checked,
          };
        } else {
          return current;
        }
      });
    });
  };

  // HANDLE - SELECT ALL
  const handleSelectAll = (data: any) => {
    layout.setPayoutList((prev: any) => {
      return prev.map((current: any) => {
        return {
          ...current,
          selected: data?.target?.checked,
        };
      });
    });
  };

  const getColumnItems = () => {
    return layout.columnItems.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  return (
    <div
      className="accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0 lg2:min-h-[8.75rem]"
      id={`EventAccordion`}
    >
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none last-auto`}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left  min-w-[87.5rem] absolute left-0 top-0">
          <thead className="text-xs text-gray-400 ">
            <tr className="border-b bg-white">
              {/* <th
                className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] items-center justify-center text-center sticky left-0 p-0 z-10 no-scroll checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                id="checkbox_shad"
              >
                <div
                  className={`h-full flex items-center justify-center border-r w-10`}
                >
                  <input
                    type="checkbox"
                    checked={
                      size(layout.payoutList) > 0 &&
                      layout.payoutList?.every((item: any) => item?.selected)
                    }
                    readOnly
                    className={` w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 `}
                    onChange={(data) => handleSelectAll(data)}
                  />
                </div>
              </th> */}
              {getColumnItems().map((columnItem: any, index: number) => {
                switch (columnItem.id) {
                  case 25:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Team name"
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={true}
                          postKey="team_name"
                          padding="pl-5"
                        />
                      </React.Fragment>
                    );
                  case 42:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Payout reference"
                          arrow={false}
                          postKey="payout_reference"
                          width="min-w-[10.625rem] w-[10.625rem]"
                        />
                      </React.Fragment>
                    );
                  case 26:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Payout date"
                          width="min-w-[7.5rem] w-[7.5rem]"
                          arrow={true}
                          postKey="payout_date"
                          // padding="pl-5"
                        />
                      </React.Fragment>
                    );
                  case 27:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Payout value"
                          arrow={false}
                          postKey="payout_value"
                          width="min-w-[7.5rem] w-[7.5rem]"
                        />
                      </React.Fragment>
                    );
                }
              })}

              <th className="md:sticky relative right-0 ml-auto  p-0 w-[5.0625rem] min-w-[5rem]">
                <div
                  className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white hidden"
                  id="left-shad-list"
                >
                  <div className="arrow_buttons flex shadow-op1 rounded ">
                    <button
                      className="group rounded-l  transition px-[.5625rem] py-2"
                      type="button"
                      onClick={(e: any) => scroll(-120)}
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={angleLeftSVG}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                    <button
                      className="group rounded-r  transition px-[.5625rem] py-2"
                      type="button"
                      onClick={(e: any) => scroll(120)}
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={angleRightSVG}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        {/* <EventFields /> */}
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2 last-auto"
              ref={tableRef}
              id={`tableId1`}
              onScroll={handleScrollVirtuoso}
              // style={{
              //   height: `${
              //     virtuosoHeight() > height ? virtuosoHeight() : height
              //   }px`,
              // }}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              <TableVirtuoso
                data={payOutData}
                endReached={() => {
                  if (
                    paginateData?.current_page < paginateData?.last_page &&
                    !loading
                  ) {
                    setIsLoading(true);
                    layout.setPayOutFilter((current: any) => {
                      let nextPage: number = paginateData?.current_page + 1;
                      return {
                        ...current,
                        page: nextPage,
                        per_page: 20,
                      };
                    });
                  }
                }}
                useWindowScroll
                overscan={200}
                totalCount={payOutData?.length}
                atBottomThreshold={100}
                id={"payout-lazyload"}
                components={{
                  Table: Table,
                  TableHead: TableHead,
                  TableRow: (props) => {
                    return (
                      <TableRow
                        {...props}
                        // hoveredId={hoveredId}
                        // setHoveredId={setHoveredId}
                        // showOrder={showOrder}
                      />
                    );
                  },
                  TableFoot: (props) => {
                    if (
                      payOutData?.length === paginateData?.total &&
                      paginateData?.current_page > 1
                    ) {
                      return (
                        <tfoot
                          className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                          id={`NoMoreListing`}
                        >
                          <tr className="border-b bg-white">
                            <td
                              colSpan={11}
                              align="center"
                              className="h-[2.8125rem]"
                            >
                              No more items to show
                            </td>
                          </tr>
                        </tfoot>
                      );
                    } else {
                      return null;
                    }
                  },
                }}
                fixedFooterContent={() => {
                  return (
                    <tr className="border-b bg-white">
                      <td colSpan={0}>Hi</td>
                    </tr>
                  );
                }}
                fixedHeaderContent={() => (
                  <tr className="border-b bg-white">
                    {/* <th
                      className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] items-center justify-center text-center sticky left-0 p-0 z-10 no-scroll checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                      id="checkbox_shad"
                    >
                      <div
                        className={`h-full flex items-center justify-center border-r w-10`}
                      >
                        <input
                          type="checkbox"
                          checked={
                            size(layout.payoutList) > 0 &&
                            layout.payoutList?.every(
                              (item: any) => item?.selected
                            )
                          }
                          readOnly
                          className={` w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 `}
                          onChange={(data) => handleSelectAll(data)}
                        />
                      </div>
                    </th> */}
                    {getColumnItems().map((columnItem: any, index: number) => {
                      switch (columnItem.id) {
                        case 25:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Team name"
                                width="min-w-[9.875rem] w-[9.875rem]"
                                arrow={true}
                                postKey="team_name"
                                padding="pl-5"
                              />
                            </React.Fragment>
                          );
                        case 42:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Payout reference"
                                arrow={false}
                                postKey="payout_reference"
                                width="min-w-[10.625rem] w-[10.625rem]"
                              />
                            </React.Fragment>
                          );
                        case 26:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Payout date"
                                width="min-w-[7.5rem] w-[7.5rem]"
                                arrow={true}
                                postKey="payout_date"
                                // padding="pl-5"
                              />
                            </React.Fragment>
                          );
                        case 27:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Payout value"
                                arrow={false}
                                postKey="payout_value"
                                width="min-w-[7.5rem] w-[7.5rem]"
                              />
                            </React.Fragment>
                          );
                      }
                    })}
                    <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:w-[5.0625rem] md:min-w-[5rem]">
                      <div
                        className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white hidden"
                        id="left-shad-list"
                      >
                        <div className="arrow_buttons flex shadow-op1 rounded ">
                          <button
                            className="group rounded-l  transition px-[.5625rem] py-2"
                            type="button"
                            onClick={(e: any) => scroll(-120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={angleLeftSVG}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                          <button
                            className="group rounded-r  transition px-[.5625rem] py-2"
                            type="button"
                            onClick={(e: any) => scroll(120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={angleRightSVG}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                        </div>
                      </div>
                    </th>
                  </tr>
                )}
                itemContent={(sIndex: any, list: any) => {
                  const matchedView = false;
                  // showOrder?.show &&
                  // showOrder?.data &&
                  // showOrder?.data?.order_id === list.order_id;
                  return (
                    <React.Fragment key={sIndex}>
                      {/* <td
                        className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.8125rem] text-center sticky left-0 no-scroll p-0 checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                        id="checkbox_shad"
                      >
                        <div
                          className={`h-full flex items-center justify-center bg-inherit border-r w-10 `}
                        >
                          <input
                            id="checkbox-all"
                            type="checkbox"
                            className={`cursor-pointer w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 relative`}
                            checked={list?.selected}
                            onChange={(e: any) => handleCheck(e, list?.id)}
                            onClick={(e) =>
                              handleShift(e, sIndex, layout?.payoutList)
                            }
                          />
                        </div>
                      </td> */}
                      {getColumnItems().map(
                        (columnItem: any, index: number) => {
                          switch (columnItem.id) {
                            case 25:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.team_name}
                                    classname="block"
                                    padding="pl-5"
                                    width="min-w-[9.875rem] max-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 42:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.payout_reference}
                                    classname="block"
                                    title={list?.payout_reference}
                                    // padding="pl-5"
                                    width="w-full max-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 26:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    // value={moment(list?.payout_date).format("DD/MM/YYYY")}
                                    value={list?.payout_date}
                                    classname="block"
                                    width="min-w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 27:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={`${getCurrencySymbol(
                                      list?.currency
                                    )}${addCommaInNumber(list?.payout_value)}`}
                                    classname="block"
                                    // padding="pl-5"
                                    width="w-full"
                                  />
                                </React.Fragment>
                              );
                          }
                        }
                      )}

                      <td
                        className={`sticky  right-0 ml-auto p-0 icon_td  border-b `}
                      >
                        <React.Fragment key={sIndex}>
                          <div
                            className={`${
                              matchedView ? "bg-indigo-500 bg-opacity-[7%]" : ""
                            } `}
                          >
                            <div
                              className="flex relative w-full h-full border-l"
                              id="left-shad-list"
                            >
                              {/* DOWNLOAD BUTTON */}
                              {/* <button
                                className={`group flex items-center justify-center p-1 md:p-1 w-full
                               `}
                                type="button"
                                id={`record-view-tooltip${sIndex}`}
                                data-tooltip-id={`download-invoice-${sIndex}`}
                                // onClick={() => handleDownloadPDF(list)}
                              >
                                <TooltipPortal
                                  id={`download-invoice-${sIndex}`}
                                  content={"Download PDF"}
                                  className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                                />
                                <IKTSVG
                                  className={`flex items-center justify-center transition fill-violet-500 group-hover:fill-indigo-500 `}
                                  path={downloadIcon}
                                  svgClassName="w-[.9375rem] h-3.5"
                                />
                              </button> */}

                              {/* VIEW BUTTON */}
                              <button
                                className={`group flex items-center justify-center p-1 md:p-1 w-full
                               `}
                                type="button"
                                id={`record-view-tooltip${sIndex}`}
                                onClick={() => {
                                  layout.setPayoutViewData((pre: any) => {
                                    return {
                                      ...pre,
                                      view_status: true,
                                      id: list?.id,
                                    };
                                  });
                                }}
                                data-tooltip-id={`eye-invoice-${sIndex}`}
                              >
                                <TooltipPortal
                                  id={`eye-invoice-${sIndex}`}
                                  content={"View"}
                                  className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default mt-2"
                                />
                                <IKTSVG
                                  className={`flex items-center justify-center transition group-hover:fill-indigo-500 fill-violet-500`}
                                  path={eyeSVG}
                                  svgClassName="w-4 h-[.6875rem]"
                                />
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      </td>
                      {/* </tr> */}
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </div>
          <div
            id="custom-loader"
            className={`accordion bg-white font-medium pt-2.5 border-t `}
          >
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            {/* <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataTablePayouts;
