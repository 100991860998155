const FilterCountBox = ({
  value,
  label,
  isCheckbox = false,
  handleOnchange,
  postKey,
  loading,
  isChecked = false,
  baseCurrency = undefined,
}: any) => {
  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 mb-5 px-2.5 first:pl-5   ">
      <label
        className={`border form-check items-center rounded group block min-w-[9.875rem] active-style ${
          isChecked && value !== 0
            ? "border-indigo-500 text-indigo-500 bg-indigo-500/5"
            : ""
        }
       ${
         isCheckbox && value !== 0
           ? "hover:bg-indigo-500/5 hover:border-indigo-500 hover:text-indigo-500"
           : ""
       } `}
      >
        <div className={` px-4 py-2.5 h-ful ${loading && "shimmer-effect"} `}>
          <div className="flex justify-between">
            <div>
              <h3
                className={`text-2xl md:text-boxtitle md:leading-9 [&.active-style]:text-indigo-500`}
              >
                {baseCurrency}
                {value}
              </h3>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <p
              className={`text-xs text-gray-500 py-1 ${
                isCheckbox && value !== 0 ? "group-hover:text-indigo-500" : ""
              } 
              ${isChecked && value !== 0 ? "text-indigo-500" : ""}
              `}
            >
              {label}
            </p>

            {isCheckbox && (
              <div className="flex">
                <input
                  className="form-check-input h-3 w-3 rounded-sm bg-gray-100 border-gray-300 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 hover:border-indigo-500 group-hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
                  type="checkbox"
                  name={label}
                  checked={isChecked}
                  onChange={(e: any) => {
                    let event = {
                      target: {
                        name: e.target.name,
                        checked: e.target.checked,
                      },
                    };
                    handleOnchange(event);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </label>
    </div>
  );
};

export default FilterCountBox;
