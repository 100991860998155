import { Fragment, memo, useMemo } from "react";

import { DownAngleIcon } from "assets/media/icons/other_icons/DownAngleIcon";
import { AngleLeftIcon } from "assets/media/icons/standard_icons/AngleLeftIcon";
import { AngleRightIcon } from "assets/media/icons/standard_icons/AngleRightIcon";
import { Tooltip } from "react-tooltip";

export const DataCell = ({
  value,
  classname = "",
  width = "",
  padding,
  noCapitalize,
  isSticky,
  isTootTip = true,
}: any) => {
  return (
    <>
      {isSticky ? (
        <td
          className={`group sticky right-0 ml-auto p-0 icon_td  border-b w-[${width}] min-w-[${width}] max-w-[${width}] ${padding}`}
        >
          <div
            className="flex left-shad relative w-full h-full border-l"
            id="left-shad-list"
          >
            {value}
          </div>
        </td>
      ) : (
        <td
          className={`p-1.5 font-medium h-[2.8125rem] text-ellipsis overflow-hidden w-[${width}] min-w-[${width}] max-w-[${width}] `}
        >
          <span className="ellips-text ">
            <p
              className={`${width} ${padding} ${classname} ${
                noCapitalize === "yes" ? "" : "capitalize"
              } ${value === "-" && "text-center"} `}
              title={value && isTootTip ? value : ""}
            >
              {value}
            </p>
          </span>
        </td>
      )}
    </>
  );
};

export const StyledTable = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left "
    style={{
      ...style,
    }}
  />
);

export const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

export const TableRow = memo((props: any) => {
  const { showOrder, hoveredId, setHoveredId, reportsLoading, ...rest } = props;

  const list = rest?.item;
  const matchedView =
    showOrder?.show &&
    showOrder?.data &&
    showOrder?.data?.order_id === list.order_id;

  return (
    <Fragment>
      {false ? (
        <tr {...rest} className="shimmer-effect highlight" />
      ) : (
        <tr
          {...rest}
          className={`border-b bg-white ${matchedView ? "matchView" : ""}
            ${list?.is_legacy_order === "true" && "legacy_block"}
  
            `}
        />
      )}
    </Fragment>
  );
});

const handleSort = (sortedKey: any, setOrderState: any) => () => {
  if (!sortedKey || !setOrderState) return;
  setOrderState((preValue: any) => {
    return {
      order_by: sortedKey,
      sort_order:
        preValue &&
        Object.keys(preValue).length > 0 &&
        preValue.order_by !== sortedKey
          ? "desc"
          : preValue.sort_order === "desc"
          ? "asc"
          : preValue.sort_order === "asc"
          ? undefined
          : "desc",
    };
  });
};

export const HeaderCell = ({
  title,
  width,
  arrow,
  postKey,
  padding,
  uniqueKey,
  orderState,
  setOrderState,
  isSticky,
  scroll,
}: any) => {
  const getIconColor = useMemo(
    () =>
      orderState?.order_by === postKey && orderState?.sort_order === "desc"
        ? "rotate-180 !fill-violet-500"
        : orderState?.order_by === postKey && orderState?.sort_order === "asc"
        ? " !fill-violet-500"
        : "fill-gray-400",
    [orderState?.order_by, orderState?.sort_order, postKey]
  );

  return isSticky ? (
    <th
      className={`sticky right-0 ml-auto p-0 max-w-[${width}] min-w-[${width}] w-[${width}]`}
    >
      <div
        className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
        id="left-shad-list"
      >
        <button
          className="group rounded-l  hover: transition px-[.5625rem] py-2"
          id="leftArrow"
          type="button"
          onClick={() => scroll(-120)}
        >
          <span className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500">
            <AngleLeftIcon className="w-1.5 h-[.5625rem]" />
          </span>
        </button>
        <button
          className="group rounded-r  transition px-[.5625rem] py-2"
          id="rightArrow"
          type="button"
          onClick={() => scroll(120)}
        >
          <span className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500">
            <AngleRightIcon className="w-1.5 h-[.5625rem]" />
          </span>
        </button>
      </div>
    </th>
  ) : (
    <th
      className={`px-1.5 py-3 font-medium whitespace-nowrap w-[${width}] min-w-[${width}] max-w-[${width}] ${padding}`}
      key={uniqueKey}
    >
      {arrow ? (
        <div className="w-full pr-3 relative">
          {title}
          <button type="button" onClick={handleSort(postKey, setOrderState)}>
            <div
              className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4 ${getIconColor}`}
            >
              <DownAngleIcon />
            </div>
          </button>
        </div>
      ) : (
        title
      )}
      <Tooltip
        anchorId={`pricePerExchange-tooltip${title}`}
        content="Price per exchange"
        place="top"
        variant="light"
        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
      />
    </th>
  );
};

export const HeaderList = (props: any) => {
  const { columns = [], scroll, orderState, setOrderState } = props;

  if (columns.length === 0) return null;

  return (
    <Fragment>
      {columns.map((column: any, index: number) => (
        <HeaderCell
          key={index}
          title={column?.title}
          width={column?.width}
          arrow={column?.isArrow}
          postKey={column?.postKey}
          padding={column?.postKey}
          orderState={orderState}
          setOrderState={setOrderState}
          isSticky={column?.isSticky}
          scroll={scroll}
        />
      ))}
    </Fragment>
  );
};
