import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Collapse from "../../../../assets/media/icons/other_icons/Collapse.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { storeFields } from "../../../../components/core/requests";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import {
  columnsOutSideClick,
  outSideClick,
} from "../../../../helpers/AssetHelpers";
import { existInLocalstorage } from "../../../../helpers/Functions";
import { PAYOUT_TAB, QUERIES } from "../../../../helpers/const";
import { getAccountInfo } from "../../../settings/core/_request";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import DraggableColumns from "../../component/DraggableColumns";
import DraggableFilters from "../../component/DraggableFilters";
import { TxAccountContext } from "../../core/TxAccountProvider";

const HeaderFilterContainer = ({ heightRef1, setIsToggle }: any) => {
  const layout = useContext(TxAccountContext);
  const globalLayout = useContext(LayoutContext);

  const [isCollapseAccordion, setIsCollapseAccordion] =
    useState<boolean>(false);

  let headerFilter = `tx_account_filter_${
    layout?.isTabActive === PAYOUT_TAB
      ? "Pay_outs"
      : layout?.isTabActive === "Seller wallets"
      ? "Seller_wallets"
      : layout?.isTabActive === "Bank accounts"
      ? "Bank_accounts"
      : layout?.isTabActive === "Withdraw requests"
      ? "Withdraw_requests"
      : layout?.isTabActive
  }`;

  let headerColumn = `tx_account_column_${
    layout?.isTabActive === PAYOUT_TAB
      ? "Pay_outs"
      : layout?.isTabActive === "Seller wallets"
      ? "Seller_wallets"
      : layout?.isTabActive === "Bank accounts"
      ? "Bank_accounts"
      : layout?.isTabActive === "Withdraw requests"
      ? "Withdraw_requests"
      : layout?.isTabActive
  }`;

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, headerFilter)
    );
    document.addEventListener("mousedown", (e: any) => {
      columnsOutSideClick(e, headerColumn);
    });
    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, headerFilter)
      );
      document.removeEventListener("mousedown", (e: any) => {
        columnsOutSideClick(e, headerColumn);
      });
    };
  }, [headerColumn, headerColumn]);

  let payload: any = [
    {
      key: headerFilter,
      value: layout.filterItems,
    },
    {
      key: headerColumn,
      value: layout.columnItems,
    },
  ];

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[headerFilter];

  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[headerColumn];

  //   STORE FILTER ARRAY
  const { refetch: getInfo } = useQuery(
    [`${QUERIES}-txaccount-newone`, layout?.isTabActive],
    () => getAccountInfo(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      // enabled: false,
      onError: (err) => {},
      onSettled(data, error) {
        globalLayout.SetAllowedAccess(data?.data);
      },
    }
  );

  //   STORE FILTER ARRAY
  const { refetch } = useQuery(
    [`${QUERIES}-txaccount`, layout?.isTabActive],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onError: (err) => {},
      onSettled(data, error) {
        // console.log(data);
      },
    }
  );

  useEffect(() => {
    if (layout.filterItems && existInLocalstorage(headerFilter)) {
      layout.setFilterItems(existInLocalstorage(headerFilter));
      setTimeout(() => {
        refetch();
        localStorage.removeItem(headerFilter);
      }, 3000);
    }

    if (layout.columnItems && existInLocalstorage(headerColumn)) {
      layout.setColumnItems(existInLocalstorage(headerColumn));
      setTimeout(() => {
        refetch();
        localStorage.removeItem(headerColumn);
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (isFilterExistInDB) {
      setTimeout(() => {
        layout.setFilterItems(isFilterExistInDB);
        layout.setDraggedItems(isFilterExistInDB);
      }, 1000);
    }
    if (isColumnFilterExistInDB) {
      setTimeout(() => {
        layout.setColumnItems(isColumnFilterExistInDB);
        layout.setDraggedColumns(isColumnFilterExistInDB);
      }, 1000);
    }
  }, [isFilterExistInDB, isColumnFilterExistInDB]);

  return (
    <div className="accordion font-medium" ref={heightRef1}>
      <div className=" bg-white accordion-item !rounded-none">
        <div
          className="flex  justify-between accordion-header mb-0"
          id="headingOne"
        >
          <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2">
            <div className="flex flex-wrap" id="filters">
              <div className="ba_item relative max-md:hidden">
                <span
                  className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
                  data-te-collapse-init
                  data-te-collapse-collapsed
                  data-te-target={`#${headerFilter}`}
                  aria-expanded="false"
                  aria-controls={headerFilter}
                >
                  Filters
                </span>
                <div
                  id={headerFilter}
                  className="!visible ba_dd absolute top-full z-10 bg-white shadow-lt left-0 min-w-[15rem] w-full hidden"
                  data-te-collapse-item
                  aria-labelledby={headerFilter}
                  data-te-parent="#filters"
                >
                  <DraggableFilters
                    isFilterExistInDB={isFilterExistInDB}
                    headerFilter={headerFilter}
                    refetch={refetch}
                  />
                </div>
              </div>

              <div className="ba_item relative">
                <span
                  className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
                  data-te-collapse-init
                  data-te-collapse-collapsed
                  data-te-target={`#${headerColumn}`}
                  aria-expanded="false"
                  aria-controls={headerColumn}
                >
                  Columns
                </span>
                <div
                  id={headerColumn}
                  className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden cursor-pointer max-h-[28.125rem] overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
                  data-te-collapse-item
                  aria-labelledby={headerColumn}
                  data-te-parent="#filters"
                >
                  <DraggableColumns
                    isColumnFilterExistInDB={isColumnFilterExistInDB}
                    headerFilter={headerColumn}
                    refetch={refetch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              className={`${
                false ? "pointer-events-none" : ""
              } group relative flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group h-full md:h-auto [&[aria-expanded='false'] > .icon ]:rotate-180`}
              type="button"
              data-te-collapse-init
              data-te-toggle="collapse"
              data-te-target="#inventoryaccordion"
              aria-expanded="true"
              aria-controls="inventoryaccordion"
              data-tooltip-id="tx-account-accordion"
              id="tx-account-accordion"
              onClick={() => {
                setIsCollapseAccordion((current: boolean) => {
                  let isCollapse: any = document
                    .getElementById("tx-account-accordion")
                    ?.getAttribute("aria-expanded");
                  return isCollapse === "false" ? true : false;
                });
                setIsToggle();
              }}
            >
              <TooltipPortal
                id={`tx-account-accordion`}
                content={`${isCollapseAccordion ? "Expand" : "Collapse"}`}
                positionStrategy="fixed"
                place="left"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
              />
              <IKTSVG
                className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                path={Collapse}
                svgClassName="w-[.5625rem] h-[.5625rem]"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderFilterContainer;
