import { useRef, useState } from "react";
import AddInventory from "./AddInventory";
import { AddInventoryProvider } from "./core/AddInventoryProvider";
import Footer from "./layout/Footer";

const AddInventoryWrapper = () => {
  const [list, setList] = useState([]);
  const virtuosoRef = useRef(null);

  const asdasd = document.getElementsByClassName("page_footer")?.[0];
  const [udpatedFooterHeight, setUdpatedFooterHeight] = useState(0);
  setTimeout(() => {
    setUdpatedFooterHeight(asdasd?.clientHeight);
  }, 1000);

  return (
    <AddInventoryProvider>
      <div
        className="main-content-wrap bot_spacing"
        style={{ marginBottom: udpatedFooterHeight + "px" }}
      >
        <AddInventory list={list} setList={setList} virtuosoRef={virtuosoRef} />
        <Footer list={list} setList={setList} virtuosoRef={virtuosoRef} />
      </div>
    </AddInventoryProvider>
  );
};

export default AddInventoryWrapper;
