import { useContext, useEffect } from "react";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";

const MarketplaceEditable = ({ fillData, data, setData = () => {} }: any) => {
  const layout = useContext(PersonalDetailsContext);
  const allMarketplaceArray = layout.filterOptions?.marketplace_lists;

  useEffect(() => {
    if (fillData?.length > 0) {
      const newData = allMarketplaceArray?.map((obj: any) => {
        const hasMatch = fillData?.some(
          (innerObj: any) => obj?.id === innerObj?.id
        );
        return {
          ...obj,
          value: hasMatch,
        };
      });
      setData(newData);
    }
  }, [fillData, allMarketplaceArray]);

  const handleOnChange = (data: any) => {
    const { checked, name } = data.target;

    setData((prevData: any) =>
      prevData?.map((obj: any) =>
        obj.id === name ? { ...obj, value: checked } : obj
      )
    );
  };

  return (
    <>
      {data?.map((obj: any, index: number) => (
        <div className="inline-flex items-center text-violet-800transition font-medium rounded md:w-1/2 w-full p-2.5">
          <input
            name={obj.id}
            id={`role-check${obj.id}`}
            type="checkbox"
            checked={obj.value}
            onChange={(data: any) => {
              handleOnChange(data);
            }}
            className="w-4 h-4 text-violet-500 bg-white border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor={`role-check${obj.id}`}
            className="cursor-pointer ml-2 text-sm12 font-medium text-violet-800 dark:text-gray-300 flex flex-wrap items-center"
          >
            {" "}
            {obj.name}
            <button
              className="group flex items-center justify-center transition-all ml-1.5"
              type="button"
              id={`roles-${obj.id}`}
            ></button>
          </label>
        </div>
      ))}
    </>
  );
};

export default MarketplaceEditable;
