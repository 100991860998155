import { IKTSVG } from "components/ui/IKTSVG";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import downAngleSVG from "../../../../../assets/media/icons/other_icons/down-angle.svg";

const HeaderCell = ({ title, width, arrow, postKey, padding }: any) => {
  const { isTabActive, contentFilter } = useCustomize();

  const tab = isTabActive;
  const order = tab === "design_menu" ? contentFilter : contentFilter;

  const handleSort = (sortedKey: any) => {
    const setFilter = (filterSetter: Function) => {
      filterSetter((currentVal: any) => ({
        ...currentVal,
        order_by: sortedKey,
        sort_order:
          currentVal &&
          Object.keys(currentVal).length > 0 &&
          currentVal.order_by !== sortedKey
            ? "desc"
            : currentVal.sort_order === "desc"
            ? "asc"
            : currentVal.sort_order === "asc"
            ? undefined
            : "desc",
        page: 1,
        per_page: 20,
      }));
    };

    switch (tab) {
      case "design_menu":
        setFilter(contentFilter);
        break;
      case "content_page":
        setFilter(contentFilter);
        break;

      default:
        break;
    }
  };

  return (
    <th
      className={`px-1.5 py-3 font-medium whitespace-nowrap ${width} ${padding}`}
    >
      {arrow ? (
        <div className="w-full pr-3 relative">
          {title}
          <button type="button" onClick={() => handleSort(postKey)}>
            <IKTSVG
              path={downAngleSVG}
              // className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4
              //     `}
              className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4 ${
                // @ts-ignore
                order?.order_by === postKey && order?.sort_order === "desc"
                  ? "rotate-180 !fill-violet-500"
                  : order?.order_by === postKey && order?.sort_order === "asc"
                  ? " !fill-violet-500"
                  : "fill-gray-400"
              }
                  `}
            />
          </button>
        </div>
      ) : (
        title
      )}
    </th>
  );
};

export default HeaderCell;
