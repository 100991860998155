/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DropDownField from "../../components/formComponent/DropDownField";
import Textfield from "../../components/formComponent/Textfield";
import db from "../../components/layout/auth/sign_up/component/dexie/dexie";
import { KTSVG } from "../../components/ui/KTSVG";

const ThankYou = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowPass, setIsShowPass] = useState<boolean>(false);
  const navigate = useNavigate();

  let options = [
    {
      value: "asd",
      label: "Paper",
      date: "Mon, 30 2023",
      time: "15:00",
      place: "Ibrox Statium, Glasgow",
    },
  ];
  let publish_status = [
    {
      id: 1,
      name: "active",
    },
    {
      id: 2,
      name: "Inactive",
    },
  ];
  const visible = () => {
    // const trigger = document.getElementsByClassName('contact_trigger');
    const trigger = document.querySelector(".contact_trigger");
    const trigger_elem = document.querySelector(".contact_popup");
    // @ts-ignore
    trigger_elem.classList.toggle("active");
  };
  const handleClick = () => {
    navigate("../", { replace: true });
  };
  return (
    <>
      <div className="detail_login">
        <div className="login_header bg-violet-600 md:py-6 py-[.9375rem] text-white">
          <div className="container_login">
            <div className="flex items-center justify-between">
              <div 
              onClick={handleClick}
              >
                <KTSVG
                  className="max-w-[12.75rem] flex items-center justify-center fill-white transition cursor-pointer"
                  path="tix_logo.svg"
                />
              </div>
              <div
                onClick={async () => {
                  // layout.setFormdata(undefined)
                  // @ts-ignore
                  await db.myNewStore.clear();
                  navigate("/login");
                }}
                // href="#"
                className="flex items-center text-sm18 group hover:text-indigo-500 transition text-white font-artex_reg"
              >
                <KTSVG
                  className="flex items-center justify-center mr-2 fill-white group-hover:fill-indigo-500 transition"
                  path="menu_icons/Logout.svg"
                  svgClassName="w-5"
                />
                Login
              </div>
            </div>
          </div>
        </div>
        <div className="content_block py-14">
          <div className="container_main max-w-[76.25rem] m-auto">
            <div className="flex flex-wrap justify-center">
              <div className="left_panel md:max-w-[60.6%] px-5 w-full min-h-[calc(100vh-12.25rem)] md:min-h-fit">
                <div className="inner_left h-full flex items-center justify-center">
                  <div className="thankyou_content text-center">
                    <h1 className="md:text-[1.875rem] text-[1.5rem] font-artex_reg leading-[1.2] mb-5">
                      Thank you for creating your Tixstock account
                    </h1>
                    <h5 className=" text-sm15 font-basier_med leading-5 mb-7">
                      One of our experts will be in touch soon
                    </h5>
                    <div
                      onClick={async () => {
                        // layout.setFormdata(undefined)
                        // @ts-ignore
                        await db.myNewStore.clear();
                        navigate("/login");
                      }}
                      // href="#"
                      className="text-sm18 font-artex_reg leading-5 py-3.5 px-5 border border-violet-800 rounded hover:bg-indigo-500 hover:border-indigo-500 hover:text-white transition-all inline-block"
                    >
                      Go to our website
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="right_panel md:max-w-[calc(100%-60.6%)] px-5 w-full">
                <div className="inner_right lg2:pl-10 md:sticky md:top-[6.875rem]">
                  <div className="content_block  shadow-op2 md:min-h-[calc(100vh-12.5rem)] pb-16">
                    <div className="content_part p-7">
                      <p className="font-basier_med text-sm-15 mb-5">Tixstock is the fastest, most reliable way to put your inventory infront of an engaged, sales-ready audience.</p>
                      <p className="font-basier text-sm-15 mb-5">We’ve provided guidance (   ) to help you enter the right information. <a href="javascript:void(0);" className="contact_trigger font-basier_med text-indigo-500 underline hover:text-violet-800 transition" onClick={() => visible()}>Contact us</a> if you require assistance.</p>
                      <div className="process_bar">
                        <p className="font-basier_med text-sm-15 mb-3">10% complete</p>
                        <div className="process w-full h-3.5 rounded-md bg-gray-100 relative">
                          <span className="w-[10%] rounded-md bg-green-500 h-full absolute top-0 left-0"></span>
                        </div>
                      </div>
                    </div>
                    <div className="detail_info">
                      <ul>
                        <li className="flex items-center flex-wrap px-[1.875rem] py-[1.125rem] bg-gray-100 border-b border-gray-200 font-artex_reg text-sm18 leading-5">
                          <span className="bg-indigo-500 w-[1.375rem] h-[1.375rem] flex items-center justify-center rounded text-white mr-2.5 text-sm15">1</span>
                          Your details
                        </li>
                        <li className="flex items-center flex-wrap px-[1.875rem] py-[1.125rem] border-b border-gray-200 text-gray-400 font-artex_reg text-sm18 leading-5">
                          <span className="bg-gray-300 w-[1.375rem] h-[1.375rem] flex items-center justify-center rounded text-white mr-2.5 text-sm15">2</span>
                          Payments
                        </li>
                        <li className="flex items-center flex-wrap px-[1.875rem] py-[1.125rem] border-b border-gray-200 text-green-600 font-artex_reg text-sm18 leading-5">
                          <span className="bg-green-600 w-[1.375rem] h-[1.375rem] flex items-center justify-center rounded text-white mr-2.5 text-sm15">
                            <KTSVG
                              className="flex items-center justify-center fill-white transition"
                              path="other_icons/check.svg"
                              svgClassName="w-[1.125rem]"
                            />
                          </span>
                          Channels
                        </li>
                        <li className="flex items-center flex-wrap px-[1.875rem] py-[1.125rem] border-b border-gray-200 text-gray-400 font-artex_reg text-sm18 leading-5">
                          <span className="bg-gray-300 w-[1.375rem] h-[1.375rem] flex items-center justify-center rounded text-white mr-2.5 text-sm15">4</span>
                          Packages
                        </li>
                      </ul>
                    </div>
                    <span className="font-artex_semi text-sm15 text-green-500 px-5 text-center w-full block absolute bottom-7">One connection. A world of events.</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="contact_popup  fixed top-0 left-0 w-full h-full md:min-h-screen bg-violet-600 overflow-auto opacity-0 invisible [&.active]:visible [&.active]:opacity-100 transition-all z-20">
        <div className="popup_header md:py-6 py-[.9375rem] text-white fixed top-0 left-0 w-full z-20 bg-violet-600">
          <div className="container_login">
            <div className="flex flex-wrap items-center justify-between">
              <div className="tix_logo"
              onClick={handleClick}
              >
                <KTSVG
                  className="max-w-[12.75rem] flex items-center justify-center fill-white transition cursor-pointer"
                  path="tix_logo.svg"
                />
              </div>
              <div className="cross_close">
                <button
                  type="button"
                  className="group flex mx-1 items-center justify-center h-7 w-7 border border-white hover:border-indigo-500 text-white  hover:bg-indigo-500 transition rounded"
                  onClick={() => visible()}
                >
                  <KTSVG
                    className="fill-white  transition"
                    path="standard_icons/cross.svg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="popup_content md:pt-32 pt-24 md:pb-12 pb-5 min-h-screen flex items-center justify-center">
          <div className="container_main max-w-[76.25rem] m-auto">
            <div className="flex flex-wrap items-center">
              <div className="contact_text md:w-1/2 w-full px-5 text-white leading-[1.2] mb-7 md:md-0">
                <h2 className="md:text-[2.8125rem] text-[1.875rem] font-artex_reg md:mb-7 mb-5">
                  Talk to an expert
                </h2>
                <h5 className="md:text-[1.25rem] text-[1.125rem] font-artex_reg mb-5">
                  Call
                  <a
                    href="callto:+4108000306740"
                    className="text-green-600 hover:text-indigo-500 ml-1 transition-all"
                  >
                    +41 (0)800 030 6740
                  </a>
                </h5>
                <p className="font-basier_med md:text-sm15 text-sm14 leading-[1.5]">
                  Alternatively, please fill out the form to connect with one of
                  our experts and begin your Tixstock journey.
                </p>
              </div>
              <div className="contact_form md:w-1/2 w-full px-5">
                <div className="form_wrap bg-white md:p-10 p-7 rounded">
                  <div className="form_fields">
                    <div className="w-full md:mb-5 mb-3.5">
                      <Textfield
                        name="yourname"
                        type="text"
                        label="Your name"
                        id="yourname"
                        isFocus={(data: any) => {}}
                        handleOnChange={(data: any) => data}
                      />
                    </div>
                    <div className="w-full md:mb-5 mb-3.5">
                      <Textfield
                        name="companyname"
                        type="text"
                        label="Company name"
                        id="companyname"
                        isFocus={(data: any) => {}}
                        handleOnChange={(data: any) => data}
                      />
                    </div>
                    <div className="w-full md:mb-5 mb-3.5">
                      <DropDownField
                        options={publish_status}
                        placeholder="Your location"
                        className=""
                        name="yourlocation"
                        handleOnChange={(data: any) => {}}
                      />
                    </div>
                    <div className="w-full md:mb-5 mb-3.5">
                      <Textfield
                        name="phonenumber"
                        type="text"
                        label="Phone number"
                        id="phonenumber"
                        isFocus={(data: any) => {}}
                        handleOnChange={(data: any) => data}
                      />
                    </div>
                    <div className="w-full md:mb-5 mb-3.5">
                      <Textfield
                        name="emailaddress"
                        type="email"
                        label="Email address"
                        id="emailaddress"
                        isFocus={(data: any) => {}}
                        handleOnChange={(data: any) => data}
                      />
                    </div>
                    <div className="w-full md:mb-7 mb-5">
                      <textarea
                        placeholder="Message"
                        className=" whitespace-normal text-sm12 rounded w-full border border-gray-300 focus:bg-violet-300/50 px-2 block text-violet-800 resize-none min-h-[7.5rem] py-1"
                      ></textarea>
                    </div>
                    <div className="submit_btn">
                      <button
                        type="submit"
                        className="false text-sm18 font-artex_reg px-3 md:py-2.5 py-1.5 w-full bg-indigo-500 rounded hover:bg-green-600 text-white transition"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
