import { IKTSVG } from "components/ui/IKTSVG";
import CrossSVG from "../../../assets/media/icons/standard_icons/cross-purple.svg";
import SearchSVG from "../../../assets/media/icons/standard_icons/search-violet.svg";

const APIConnectorSearchField = ({
  loading,
  setFilters,
  name,
  placeholder,
  filters,
  value,
  setValue,
}: any) => {
  //   const [value, setValue] = useState("");

  const setValueInFilters = (event: any) => {
    setFilters((current: any) => {
      return {
        ...current,
        [event.target.name]: event.target.value,
        page: 1,
      };
    });
  };

  return (
    <div
      className={`w-full sm:w-1/2 lg2:w-[20%] lg:w-1/4 xl:w-[20%] px-[.313rem] relative `}
    >
      <div className={`${loading && "shimmer-effect"}`}>
        <>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            {/* <button type="button">
            <IKTSVG
              className="fill-violet-500"
              svgClassName="h-3.5 w-3.5"
              path={SearchSVG}
            />
          </button> */}

            <button
              type="button"
              onClick={() => {
                let eventObj = {
                  target: {
                    name: name,
                    value: "",
                  },
                };
                setValueInFilters(eventObj);
                setValue("");
                //   clearSearchField();
              }}
            >
              <IKTSVG
                className="fill-violet-500"
                svgClassName="h-3.5 w-3.5"
                path={filters?.[name] && value ? CrossSVG : SearchSVG}
              />
            </button>
          </div>
          <input
            type="text"
            name={name}
            id="simple-search"
            placeholder={placeholder}
            className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
            onKeyDown={(event: any) => {
              if (event?.target?.value && event.key === "Enter") {
                event.preventDefault();
                // layout.setEventsFilters((current: any) => {
                //   return {
                //     ...current,
                //     venue: event.target.value,
                //     page: 1,
                //   };
                // });
                setValueInFilters(event);
              }
            }}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
        </>
      </div>
    </div>
  );
};

export default APIConnectorSearchField;
