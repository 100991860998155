export const PolicyContent = () => {
  return (
    <div className="text-sm13">
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Ticket Delivery</h2>
        <p className="mb-2 last:mb-0">
          We collaborate closely with our suppliers to ensure timely delivery of
          all tickets. Each listing includes an 'in-hand date,' indicating the
          earliest date you can expect to receive your tickets. Typically,
          tickets are delivered within 48 hours of this date. For tickets
          labeled as 'Tickets in hand,' you can expect delivery within 48 hours
          of your purchase.
        </p>
        <p className="mb-2 last:mb-0">
          Please note that if you purchase tickets within 48 hours of the event
          start time, tickets may be delivered up to 2 hours before the event
          begins.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Seat Allocation</h2>
        <p className="mb-2 last:mb-0">
          All tickets within a listing will be provided by the supplier as
          consecutive seat numbers, unless otherwise specified.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Attendee Details</h2>
        <p className="mb-2 last:mb-0">
          For some events, organizers or venues require attendee information to
          facilitate ticket transfers. We request this information only when
          necessary to ensure a seamless experience for the attendee.
        </p>
        <p className="mb-2 last:mb-0">
          IMPORTANT: Events requiring this detail will be highlighted before
          purchase. Please note that failure to provide this information may
          result in your order being Cancelled without a refund.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">
          Accessing Purchased Tickets
        </h2>
        <p className="mb-2 last:mb-0">
          All of your purchases are visible in the 'Purchases' section of TX
          Trade. There you can download e-tickets, find mobile links, and view
          proof of delivery.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Ticket Cancellations</h2>
        <p className="mb-2 last:mb-0">
          Once a purchase is confirmed, it is considered final, and tickets
          cannot be Cancelled or exchanged. If you no longer wish to use your
          tickets and there is sufficient time before the event, we recommend
          relisting them via Tixstock if you have an active seller account. If
          you do not have an active seller account, please contact our support
          team for assistance.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Event Cancellations</h2>
        <p className="mb-2 last:mb-0">
          In the unlikely event that an event is Cancelled, your purchase will
          be refunded.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Event Rescheduling</h2>
        <p className="mb-2 last:mb-0">
          If an event is rescheduled, your tickets will remain valid. The
          original date may still be printed on the ticket, but it will be valid
          for entry on the new date.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Neutral Stadium Events</h2>
        <p className="mb-2 last:mb-0">
          In instances where events are held at neutral stadiums, tickets for
          these events may be available on the TX Trade platform before the
          participants are confirmed or announced, such as for the final of a
          major sporting event. Listings will indicate if the tickets are for
          'Neutral,' meaning they will be in the official neutral area of the
          stadium, or 'Any side,' meaning the tickets could be located in any
          area of the stadium, provided they meet the listing criteria (e.g.,
          Category, Block, and/or Row). Once the event participants are
          confirmed, tickets for specific 'fan sections' will become available
          and will be clearly stated on each listing.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Benefits and Restrictions</h2>
        <p className="mb-2 last:mb-0">
          Some tickets include additional benefits, such as 'Early access to the
          venue,' or restrictions, such as 'Under 18 only.' These details will
          be displayed on the listing, so please review them before confirming
          your purchase.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Ticket Personalization</h2>
        <p className="mb-2 last:mb-0">
          Personalization of tickets is not required for all events. If attendee
          details are necessary for entry, 'Attendee details required' will be
          indicated at the time of purchase.
        </p>
      </div>
      <div className="mb-6 last:mb-0">
        <h2 className="text-sm15 font-bold mb-3">Reporting Ticket Issues</h2>
        <p className="mb-2 last:mb-0">
          All issues must be reported immediately. If you have received an
          instruction letter with the seller's contact details, please attempt
          to reach them directly. If not, report any issues by calling{" "}
          <a
            href="tel:+35794058833"
            className="text-violet-500 hover:text-indigo-500"
          >
            +357 94 058833
          </a>
          . Top Seller program members can also raise issues in their dedicated
          WhatsApp group.
        </p>
      </div>
    </div>
  );
};
