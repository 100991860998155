import { size } from "lodash";
import { useContext, useState } from "react";
import { StyledButton } from "../../../../../components/ui/buttons/StyledButton/StyledButton";
import { showAlert } from "../../../../../helpers/ShowAlert";
import { TxAccountContext } from "../../../core/TxAccountProvider";
import { getTXAccountOrdersListings } from "../../../core/_request";
import { downloadFile } from "../../../core/functions";

const SubBtnList = [
  { title: "General", addonParams: {} },
  { title: "Marketplace", addonParams: { export_type: "market_place" } },
];

export const MultiExportToCsv = () => {
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  const layout = useContext(TxAccountContext);
  let invoiceView = layout.invoicesViewData;
  const { data, id } = invoiceView;

  const handleExportCSV = (id: any, exportType: any) => () => {
    if (exportLoader) return;
    setExportLoader(true);
    getTXAccountOrdersListings({
      orderFilters: {
        ...layout.orderFilters,
        export_to_csv: 1,
        invoice_id: id,
        ...exportType,
      },
    }).then((response: any) => {
      setExportLoader(false);
      if (response?.data && size(response?.data) > 0) {
        downloadFile({
          data: response?.data.replace(/\\n/g, "\n"),
          fileName: `${data?.invoice_number}.csv`,
          fileType: "text/csv",
        });
      } else if (response?.errors && size(response?.errors) > 0) {
        showAlert(response?.errors?.title, true);
      } else {
        showAlert("Something went wrong!", true);
      }
    });
  };

  return (
    <div className="flex gap-2">
      {SubBtnList.map((btn) => (
        <StyledButton
          isDisabled={exportLoader || data?.orders?.length === 0}
          onClick={handleExportCSV(id, btn.addonParams)}
        >
          {btn.title} Export
        </StyledButton>
      ))}
    </div>
  );
};
