import React from "react";
import SVG from "react-inlinesvg";
import ProcessSVG from "../../assets/media/icons/other_icons/process.svg";

type Props = {
  className?: string;
  path: string;
  svgClassName?: string;
  fill?: string;
  id?: string;
  svgRef?: any;
  spanId?: any;
  dataTooltipID?: string;
};

const IKTSVG: React.FC<Props> = ({
  className = "",
  path,
  svgClassName = "",
  fill,
  id = "",
  spanId = "",
  svgRef,
  dataTooltipID = "",
}: any) => {
  return (
    <span
      className={`${className}`}
      tabIndex={-1}
      id={spanId}
      data-tooltip-id={dataTooltipID}
    >
      <SVG
        cacheRequests={true}
        src={path}
        className={svgClassName}
        fill={fill}
        id={id}
        ref={svgRef}
        loader={
          id === "tx-trade-svg" && (
            <>
              <span className="flex items-center justify-center fill-violet-500 transition animate-spin">
                <SVG src={ProcessSVG} />
              </span>
            </>
          )
        }
        onError={(data: any) => {
          // data && console.log(data, "data error of " + path);
        }}
      />
    </span>
  );
};

export { IKTSVG };
