import _ from "lodash";
import { useContext } from "react";
import InfoCircle from "../../../../../assets/media/icons/other_icons/info.svg";
import { LayoutContext } from "../../../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import { INVENTORY_VALIDATE_COLUMN } from "../../../../../helpers/const";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";
import { getFieldErrorColumns } from "../../../core/_functions";
import { Messages } from "../../../core/_messages";
import InventoryNoJSX from "../../../core/InventoryNoJSX";
import { InventoryContext } from "../../../core/InventoryProvider";

type TEventRecordStatus = {
  event: Record<string, any>;
  eventRecord: Record<string, any>;
  eIndex: string | number;
  rIndex: string | number;
};

const EventRecordStatus = ({
  event,
  eventRecord,
  eIndex,
  rIndex,
}: TEventRecordStatus) => {
  const globalLayout = useContext(LayoutContext);
  const layout = useContext(InventoryContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  const {
    isEventRecordError,
    isEventRecordBEError,
    isEventRecordHoldTickets,
    isALLTicketUploadedWithPublish,
  } = InventoryNoJSX({
    event,
    eventRecord,
  });

  const totalHold = eventRecord?.hold_tickets
    ? Object.values(eventRecord.hold_tickets).reduce(
        (acc: number, holdData: any) => acc + (holdData?.hold_quantity || 0),
        0
      )
    : 0;

  return (
    <div className={`second_row flex !p-l-[2.5rem] w-full bg-gray-100`}>
      <div
        className={`border-b min-h-[1.9375rem] sticky left-0 ${
          isGodAdmin ? "w-[5rem]" : "w-[2.5rem]"
        }`}
      ></div>
      <div className="p-0 equal-td-height border-b flex items-start sticky left-[2.5rem]">
        <div
          className={`error-box border-b border-l border-r ml-1.5 -mt-[1px] rounded-b mb-2 
                  ${
                    isEventRecordHoldTickets
                      ? "row-hold-new group-hover:bg-sky-blue-300"
                      : ""
                  }
                  ${
                    isEventRecordError || isEventRecordBEError
                      ? "row-error-new group-hover:bg-rose-550"
                      : ""
                  }
                  ${
                    isGodAdmin && eventRecord?.isLock === true
                      ? "bg-gray-300/40"
                      : "bg-white"
                  }
                `}
        >
          {isEventRecordBEError &&
            eventRecord?.errors?.map((error: any, errorIndex: any) => {
              // Filter out errors that are included in INVENTORY_VALIDATE_COLUMN
              const filteredErrors = error?.errors?.filter(
                (e: string) =>
                  ![
                    ...INVENTORY_VALIDATE_COLUMN,
                    ...layout.deliveryType?.map((item: any) => item.id),
                    ...(eventRecord?.ppePartners &&
                    _.size(eventRecord?.ppePartners) > 0
                      ? eventRecord?.ppePartners?.map(
                          (partner: any) => partner?.name
                        )
                      : []),
                  ]?.includes(e)
              );
              const uniqueKey = `${event?.id}-${eIndex}-${rIndex}-${eventRecord?.id}-${errorIndex}`;

              // Render only if there are non-excluded errors
              if (filteredErrors && filteredErrors.length > 0) {
                return (
                  <div
                    className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500"
                    key={uniqueKey}
                  >
                    Error&nbsp;
                    <div>
                      {error?.exchange && error?.exchange !== undefined && (
                        <span className="text-rose-500 text-xs inline-block font-semibold">
                          {error?.exchange}: &nbsp;
                        </span>
                      )}
                      <p className="!text-gray-600 inline font-normal 23">
                        {filteredErrors.join(",")}
                      </p>
                    </div>
                  </div>
                );
              }

              // If no errors to render, return null or an empty fragment
              return null;
            })}

          {isEventRecordError &&
            _.size(getFieldErrorColumns(eventRecord, layout.deliveryType)) >
              0 && (
              <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500">
                Error:&nbsp;
                <div>
                  <p className="text-gray-600 inline font-normal">
                    There are some errors in{" "}
                    {getFieldErrorColumns(
                      eventRecord,
                      layout.deliveryType
                    )?.join(", ")}{" "}
                    {_.size(
                      getFieldErrorColumns(eventRecord, layout.deliveryType)
                    ) > 1
                      ? " fields"
                      : " field"}
                  </p>
                </div>
              </div>
            )}

          {isALLTicketUploadedWithPublish && (
            <div className="px-2.5 pb-[.3125rem] pt-0 flex text-green-500">
              Tickets uploaded:
              <div>
                {"   "}
                <div className="text-gray-600 text-xs inline-block font-medium flex pl-1">
                  {"   "} Editing restricted{" "}
                  <div className="flex" data-tooltip-id="editing">
                    <IKTSVG
                      className="cursor-pointer fill-gray-400 hover:fill-indigo-500 pl-1 inline-block align-middle "
                      svgClassName="w-[.875rem] h-[.875rem]"
                      path={InfoCircle}
                    />
                    <TooltipPortal
                      id={`editing`}
                      content={Messages?.ticketRestricted}
                      className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-20 max-w-[19rem] cursor-default "
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {isEventRecordHoldTickets && (
            <div className="px-2.5 pb-[.3125rem] pt-0">
              <div className="">
                <p className="text-sky-blue-500 text-xs flex flex-inline font-semibold">
                  {Messages?.ticketHoldLine1_new}
                  <span className="text-gray-600 font-normal flex flex-inline">
                    &nbsp;{totalHold} of{" "}
                    {Number(eventRecord?.quantity_available) === 0
                      ? totalHold
                      : Number(totalHold) +
                        Number(eventRecord?.quantity_available)}{" "}
                    tickets on hold for 30 mins. Editing is restricted
                    <span
                      id={`${eventRecord?.id}-OnHoldMessageIcon`}
                      data-tooltip-id={`${eventRecord?.id}-OnHoldMessageIcon`}
                    >
                      <TooltipPortal
                        id={`${eventRecord?.id}-OnHoldMessageIcon`}
                        content={`${
                          isEventRecordHoldTickets &&
                          eventRecord?.quantity_available <
                            eventRecord?.listing_ticket_files?.length
                            ? Messages?.ticketHoldLine3_new
                            : Messages?.ticketHoldLine2_new
                        }`}
                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-20 mt-1.5 max-w-xs text-center"
                      />
                      <IKTSVG
                        path={InfoCircle}
                        className="ml-2 cursor-pointer fill-gray-400 hover:fill-indigo-500 inline-block align-middle "
                        svgClassName="fill-gray-400 hover:fill-indigo-500 w-[.875rem] h-[.875rem]"
                      />
                    </span>
                  </span>
                </p>
              </div>
            </div>
          )}

          {eventRecord?.processing ? (
            <div className="p-1 h-[100%]">
              <p className="text-gray-600 inline font-normal">
                {Messages?.ticketProcessing}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default EventRecordStatus;
