import { addCommaInNumber, decimalNumber } from "../../../helpers/Functions";
import { SYMBOLS } from "../../../helpers/const";
import FilterCountBox from "./FilterCountBox";

const OrdersFilterCountBoxsWrapper = ({
  ordersOverViewDetails,
  loading,
  baseCurrency,
}: any) => {
  return (
    <>
      <FilterCountBox
        value={
          ordersOverViewDetails?.total_orders
            ? addCommaInNumber(ordersOverViewDetails?.total_orders)
            : "0"
        }
        label="Orders"
        isCheckbox={false}
        loading={loading}
      />

      <FilterCountBox
        value={
          ordersOverViewDetails?.tixstock_revenue
            ? decimalNumber(ordersOverViewDetails?.tixstock_revenue)
            : "0"
        }
        label="Tixstock revenue"
        isCheckbox={false}
        loading={loading}
        baseCurrency={SYMBOLS[baseCurrency]}
      />
      <FilterCountBox
        value={
          ordersOverViewDetails?.total_revenue
            ? decimalNumber(ordersOverViewDetails?.total_revenue)
            : "0"
        }
        label="Gross transaction value"
        isCheckbox={false}
        loading={loading}
        baseCurrency={SYMBOLS[baseCurrency]}
      />
      <FilterCountBox
        value={
          ordersOverViewDetails?.settlement_net_payout
            ? decimalNumber(ordersOverViewDetails?.settlement_net_payout)
            : "0"
        }
        label="Total seller payouts"
        isCheckbox={false}
        loading={loading}
        baseCurrency={SYMBOLS[baseCurrency]}
      />
    </>
  );
};

export default OrdersFilterCountBoxsWrapper;
