import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import downloadIcon from "../../../assets/media/icons/other_icons/download-icon.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";

interface PDFViewInterface {
  isLoading?: boolean;
  isDownloadBtn?: boolean;
  fileName: string;
  draftKey: any;
  downloadPDF: any;
  title?: string;
}

const PDFView: React.FC<PDFViewInterface> = ({
  isLoading = false,
  fileName = "tixstock-invoice-000000000-draft.pdf",
  draftKey,
  isDownloadBtn = false,
  downloadPDF,
  title = "View invoice",
}: any) => {
  return (
    <div className="invoice_list p-5 pt-3.5 bg-gray-100/50 mt-[1.875rem] rounded">
      <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
        {title}
      </h5>

      {/* LOAD  */}
      {isLoading ? (
        <div className="loader bg-white flex items-center justify-center pt-6 pb-4 invoice-loader anim-fade">
          <div className="max-w-[14rem] inner_loader w-full text-center">
            <div className="loader-stripe h-2 rounded mb-3.5"></div>
            <span className="block text-sm14 leading-4">
              Saving draft invoice
            </span>
          </div>
        </div>
      ) : (
        <div className="list_wrap flex flex-wrap gap-2.5 anim-fade">
          <div className="single-invoice border rounded flex flex-wrap justify-between px-2.5 py-1.5 text-sm12 bg-white w-full">
            {/* FILE NAME */}
            <span className="flex-1 flex leading-4">{fileName}</span>

            {isDownloadBtn && (
              // DOWNLOAD BTN
              <button
                type="button"
                data-tooltip-id={`pdf-download-account`}
                onClick={() => downloadPDF(draftKey, "download", fileName)}
              >
                <TooltipPortal
                  id={`pdf-download-account`}
                  content={"Download PDF"}
                  className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                />
                <IKTSVG
                  className="fill-violet-500 hover:fill-indigo-500 transition"
                  path={downloadIcon}
                  svgClassName="w-[.9375rem]"
                />
              </button>
            )}

            {/* VIEW BUTTON */}
            <button
              className="ml-2"
              type="button"
              data-tooltip-id={`pdf-view-account`}
              onClick={() => downloadPDF(draftKey, "view")}
            >
              <TooltipPortal
                id={`pdf-view-account`}
                content={"View"}
                className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
              />
              <IKTSVG
                className="fill-violet-500 hover:fill-indigo-500 transition"
                path={eyeSVG}
                svgClassName="w-[.9375rem] h-2.5"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFView;
