import { size } from "lodash";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TableVirtuoso } from "react-virtuoso";
import eyeSVG from "../../../../assets/media/icons/other_icons/Eye.svg";
import angleLeftSVG from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import TableRow from "../../../tx_account/Table/component/TableRow";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import { WalletAndBankDetailsContext } from "../core/WalletAndBankDetailsProvider";
import { getTXAccountBankAccountListings } from "../core/_request";
import { display } from "../core/functions";
import BankAccountsViewForm from "./component/BankAccountsViewForm";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  md:min-w-[87.5rem] last-auto"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTableBankAccount = ({
  loading,
  setIsLoading,
  bankAccountRefetch,
}: any) => {
  const layout = useContext(WalletAndBankDetailsContext);
  const [loader, setLoader] = useState(false);
  const [bankView, setBankView] = useState<any>({
    show: false,
    data: undefined,
    index: undefined,
  });

  useEffect(() => {
    if (layout.porterState && size(layout.bankAccountList) === 1) {
      setBankView({
        show: true,
        data: layout.porterState,
        index: 0,
      });
    }
  }, [layout.bankAccountList, layout.porterState]);
  const bankAccountList = layout.bankAccountList;
  let paginateData = layout.bankAccountMeta;

  const tableRef: any = useRef<any>();
  const stickyTableRef: any = useRef<any>();
  const [height, setHeight] = useState<any | undefined>(undefined);

  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  let timeout: any;
  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#payout-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
      // if (topValue.includes("-")) {
      // Check if the value contains a negative sign
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive
      topfilterTop && (topfilterTop.style.top = `${newValue}px`); // Apply the new positive value back as a string
      // }
      const windScroll: number = window.scrollY;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;
      const salesFilter =
        document.getElementById("salesFilter")?.offsetHeight || 0;
      // const totleScroll = headingOne + inventoryaccordion + 16;
      // const currentTop = parseInt(topfilterTop.style.top || "0");
      let totleScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totleScroll = headingOne + inventoryaccordion + salesFilter + 20;
      } else {
        totleScroll = headingOne + inventoryaccordion + 16;
      }

      const stickyHead: any = document.getElementById("sticky-head");
      const stickyShadow: any = document.getElementById("sticky-shadow");
      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  const getColumnItems = () => {
    return layout.columnItems.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  // HANDLE VIEW
  const handleView = (list: any, sIndex: any) => {
    setLoader(true);
    getTXAccountBankAccountListings({
      bankAccountFilter: {
        id: list?.team_id,
      },
    }).then((response: any) => {
      setLoader(false);

      if (response?.data && size(response?.data) > 0) {
        let data = response?.data?.[0];
        setBankView({
          show: true,
          data: data,
          index: sIndex,
          fetched: true,
        });
        setTimeout(() => {
          setBankView((current: any) => {
            return {
              ...current,
              fetched: false,
            };
          });
        }, 200);
        setTimeout(() => {
          display(true);
        }, 10);
      }
    });
  };
  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };
  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  return (
    <div
      className={`accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0 ${
        bankView?.show && "pr-0"
      }`}
      id={`EventAccordion`}
    >
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none last-auto`}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left  min-w-[87.5rem] absolute left-0 top-0 last-auto">
          <thead className="text-xs text-gray-400 ">
            <tr className="border-b bg-white">
              {getColumnItems().map((columnItem: any, index: number) => {
                switch (columnItem.id) {
                  case 0:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Team name"
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={true}
                          postKey="team_name"
                          padding="pl-5"
                        />
                      </React.Fragment>
                    );
                  case 30:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="TX Pay ID"
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={false}
                          postKey="team_name"
                          padding="pl-5"
                        />
                      </React.Fragment>
                    );
                  case 38:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title={columnItem?.name}
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={false}
                          postKey="withdraw_bank_name"
                          padding="pl-5"
                        />
                      </React.Fragment>
                    );
                  case 39:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title={columnItem?.name}
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={false}
                          postKey="funding_bank_name"
                          padding="pl-5"
                        />
                      </React.Fragment>
                    );
                  case 41:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title={columnItem?.name}
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={false}
                          postKey="funding_bank_name"
                          padding="pl-5"
                        />
                      </React.Fragment>
                    );
                }
              })}

              <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:w-[5.0625rem] md:min-w-[5rem]">
                <div
                  className={`py-[.4375rem] px-4 justify-end h-[2.5rem] left-shad border-l bg-white ${
                    bankView?.show ? "flex" : "hidden"
                  }`}
                  id="left-shad-list"
                >
                  <button
                    className="group rounded-l  transition px-[.5625rem] py-2"
                    type="button"
                    onClick={(e: any) => scroll(-120)}
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                      path={angleLeftSVG}
                      svgClassName="w-1.5 h-[.5625rem]"
                    />
                  </button>
                  <button
                    className="group rounded-r  transition px-[.5625rem] py-2"
                    type="button"
                    onClick={(e: any) => scroll(120)}
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                      path={angleRightSVG}
                      svgClassName="w-1.5 h-[.5625rem]"
                    />
                  </button>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        {/* <EventFields /> */}
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2 last-auto"
              ref={tableRef}
              id={`tableId1`}
              onScroll={handleScrollVirtuoso}
              // style={{
              //   height: `${
              //     virtuosoHeight() > height ? virtuosoHeight() : height
              //   }px`,
              // }}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              <TableVirtuoso
                data={bankAccountList}
                endReached={() => {
                  if (
                    paginateData?.current_page < paginateData?.last_page &&
                    !loading
                  ) {
                    setIsLoading(true);
                    layout?.setBankAccountFilter((current: any) => {
                      let nextPage: number = paginateData?.current_page + 1;
                      return {
                        ...current,
                        page: nextPage,
                        per_page: 20,
                      };
                    });
                  }
                }}
                useWindowScroll
                overscan={200}
                totalCount={bankAccountList?.length}
                atBottomThreshold={100}
                id={"payout-lazyload"}
                components={{
                  Table: Table,
                  TableHead: TableHead,
                  TableRow: (props) => {
                    return (
                      <TableRow
                        {...props}
                        // hoveredId={hoveredId}
                        // setHoveredId={setHoveredId}
                        showOrder={bankView}
                        showOrderId="team_id"
                      />
                    );
                  },
                  TableFoot: (props) => {
                    if (
                      bankAccountList?.length === paginateData?.total &&
                      paginateData?.current_page > 1
                    ) {
                      return (
                        <tfoot
                          className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                          id={`NoMoreListing`}
                        >
                          <tr className="border-b bg-white">
                            <td
                              colSpan={11}
                              align="center"
                              className="h-[2.8125rem]"
                            >
                              No more items to show
                            </td>
                          </tr>
                        </tfoot>
                      );
                    } else {
                      return null;
                    }
                  },
                }}
                fixedHeaderContent={() => (
                  <tr className="border-b bg-white">
                    {getColumnItems().map((columnItem: any, index: number) => {
                      switch (columnItem.id) {
                        case 0:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Team name"
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={true}
                                postKey="team_name"
                                padding="pl-5"
                              />
                            </React.Fragment>
                          );
                        case 30:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="TX Pay ID"
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={false}
                                postKey="team_name"
                                padding="pl-5"
                              />
                            </React.Fragment>
                          );
                        case 38:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title={columnItem?.name}
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={false}
                                postKey="withdraw_bank_name"
                                padding="pl-5"
                              />
                            </React.Fragment>
                          );
                        case 39:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title={columnItem?.name}
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={false}
                                postKey="funding_bank_name"
                                padding="pl-5"
                              />
                            </React.Fragment>
                          );
                        case 41:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title={columnItem?.name}
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={false}
                                postKey="funding_bank_name"
                                padding="pl-5"
                              />
                            </React.Fragment>
                          );
                      }
                    })}
                    <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:w-[5.0625rem] md:min-w-[5rem]">
                      <div
                        className={`py-[.4375rem] px-4 justify-end h-[2.5rem] left-shad border-l bg-white ${
                          bankView?.show ? "flex" : "hidden"
                        }`}
                        id="left-shad-list"
                      >
                        <button
                          className="group rounded-l  transition px-[.5625rem] py-2"
                          type="button"
                          onClick={(e: any) => scroll(-120)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                            path={angleLeftSVG}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                        <button
                          className="group rounded-r  transition px-[.5625rem] py-2"
                          type="button"
                          onClick={(e: any) => scroll(120)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                            path={angleRightSVG}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                      </div>
                    </th>
                  </tr>
                )}
                fixedFooterContent={() => {
                  return (
                    <tr className="border-b bg-white">
                      <td colSpan={0}>Hi</td>
                    </tr>
                  );
                }}
                itemContent={(sIndex: any, list: any) => {
                  const matchedView = false;
                  return (
                    <React.Fragment key={sIndex}>
                      {getColumnItems().map(
                        (columnItem: any, index: number) => {
                          switch (columnItem.id) {
                            case 0:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.team_name}
                                    classname="block"
                                    padding="pl-5"
                                    width="min-w-[9.875rem] max-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 30:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.tx_pay_id}
                                    classname="block"
                                    padding="pl-5"
                                    width="min-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 38:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={
                                      list?.withdrawal_bank_account?.bank_name
                                    }
                                    classname="block"
                                    padding="pl-5"
                                    width="min-w-[9.875rem] max-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 39:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={
                                      list?.deposit_bank_account?.bank_name
                                    }
                                    classname="block"
                                    padding="pl-5"
                                    width="min-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 41:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={
                                      list?.is_txpay_change_request
                                        ? "Change requested"
                                        : "N/A"
                                    }
                                    classname="block"
                                    padding="pl-5"
                                    width="min-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                          }
                        }
                      )}

                      <td
                        className={`sticky right-0 ml-auto p-0 icon_td  border-b last-of-type:border-b-0`}
                      >
                        <React.Fragment key={sIndex}>
                          <div
                            className={`${
                              matchedView ? "bg-indigo-500 bg-opacity-[7%]" : ""
                            } `}
                          >
                            <div
                              className="flex relative w-full h-full border-l"
                              id="left-shad-list"
                            >
                              {/* VIEW BUTTON */}
                              <button
                                className={`group flex items-center justify-center p-1 md:p-1 w-full
                                 `}
                                type="button"
                                id={`record-view-tooltip${sIndex}`}
                                data-tooltip-id={`add-bank-${sIndex}`}
                                onClick={() => {
                                  handleView(list, sIndex);
                                }}
                              >
                                <TooltipPortal
                                  id={`add-bank-${sIndex}`}
                                  content={"View"}
                                  className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default mt-2"
                                />

                                <IKTSVG
                                  className={`flex items-center justify-center transition group-hover:fill-indigo-500 fill-violet-500`}
                                  path={eyeSVG}
                                  svgClassName="w-4 h-[.6875rem]"
                                />
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      </td>
                      {/* </tr> */}
                    </React.Fragment>
                  );
                }}
              />
              {bankView?.show && (
                <BankAccountsViewForm
                  data={bankView}
                  setBankView={setBankView}
                  bankAccountRefetch={bankAccountRefetch}
                  handleClose={() =>
                    setBankView({
                      show: false,
                      data: undefined,
                      index: undefined,
                    })
                  }
                  fetchViewAPI={handleView}
                />
              )}
            </div>
          </div>
          <div
            id="custom-loader"
            className={`accordion bg-white font-medium pt-2.5 border-t mx-2`}
          >
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
          </div>
        </div>
      </div>
      {loader && <ProgressBardLoader secondCounter={1.5} />}
    </div>
  );
};

export default DataTableBankAccount;
