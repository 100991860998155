import _, { size } from "lodash";
import moment from "moment";
import { useContext } from "react";
import filter from "../../../../assets/media/icons/other_icons/filter.svg";
import refresh from "../../../../assets/media/icons/other_icons/refresh.svg";
import crossIcon from "../../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { addCommaInNumber } from "../../../../helpers/Functions";
import {
  TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
  TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
  TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
} from "../../../../helpers/const";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import { WalletAndBankDetailsContext } from "../core/WalletAndBankDetailsProvider";

const CommonFilterChips = ({
  filterValue,
  filterName,
  onRemove,
  loading,
}: any) => {
  return (
    <div
      className={`clr_filter   lg2:max-w-[25%] flex-1 w-auto px-[.3125rem]  `}
    >
      <div
        className={`px-[.9375rem] pr-[1.875rem] py-[.5625rem] bg-indigo-500 bg-opacity-[7%] border border-indigo-500 rounded-xl md:min-w-[15.625rem] min-w-[11.25rem] h-full relative ${
          loading && "shimmer-effect"
        } `}
      >
        <button
          type="button"
          className="group crossIcon w-[1.125rem] h-[1.125rem] rounded-full bg-white absolute top-2.5 right-2.5 flex items-center justify-center"
          onClick={onRemove}
          data-tooltip-id={`close-filter-${filterName}`}
        >
          <TooltipPortal
            id={`close-filter-${filterName}`}
            content={"Clear"}
            place="top"
            className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
          />
          <IKTSVG
            path={crossIcon}
            svgClassName="w-2 h-2 fill-violet-800 group-hover:fill-indigo-500"
          />
        </button>
        <h3 className="text-sm15 leading-5 mb-1">{filterValue}</h3>
        <span className="text-sm12 text-gray-500 leading-4 flex items-center">
          {filterName}
        </span>
      </div>
    </div>
  );
};

const FiltersChips = ({ totalList, loading, ToggleElemOpen }: any) => {
  const layout = useContext(WalletAndBankDetailsContext);
  const tab = layout.isTabActive;
  const sellerWalletsFilter = layout.sellerWalletsFilter;
  const bankAccountFilter = layout.bankAccountFilter;
  const withdrawRequestFilter = layout.withdrawRequestFilter;

  // CONDITION
  const filterVisibile =
    // SELLER WALLET
    size(sellerWalletsFilter?.teams) > 0 ||
    size(sellerWalletsFilter?.statuses) > 0 ||
    // BANK ACCOUNT FILTER
    size(bankAccountFilter?.teams) > 0 ||
    bankAccountFilter?.txpay_change_request ||
    bankAccountFilter?.is_withdrawal_acc_configured?.name ||
    // WITHDRAW REQUEST
    size(withdrawRequestFilter?.teams) > 0 ||
    (tab === "Withdraw requests" && size(withdrawRequestFilter?.statuses) > 0
      ? true
      : false) ||
    withdrawRequestFilter?.txpay_change_request ||
    (withdrawRequestFilter?.requestedDate?.startDate !== undefined &&
      withdrawRequestFilter?.requestedDate?.endDate !== undefined);

  // CLEAR ALL FILTER
  const handleOnclear = () => {
    if (tab === "Seller wallets") {
      layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
    }
    if (tab === "Bank accounts") {
      layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
    }
    if (tab === "Withdraw requests") {
      layout.setIsSelectAll({ status: "" });
      layout.setWithdrawRequestFilter((current: any) => {
        return {
          ...TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
          statuses: null,
        };
      });
    }
  };

  const checkFilterTypeTab = (filterType: any, tab: string) => {
    let filter: any;
    if (tab === "Seller wallets") {
      filter = sellerWalletsFilter;
    } else if (tab === "Bank accounts") {
      filter = bankAccountFilter;
    } else if (tab === "Withdraw requests") {
      filter = withdrawRequestFilter;
    }
    switch (filterType) {
      case "teams":
        return filter?.teams && size(filter?.teams) > 0 && filter?.teams;
      case "order_status":
        return (
          filter?.order_status &&
          size(filter?.order_status) > 0 &&
          filter?.order_status
        );
      case "statuses":
        return (
          filter?.statuses && size(filter?.statuses) > 0 && filter?.statuses
        );
      case "txpay_change_request":
        return filter?.txpay_change_request;
      case "is_withdrawal_acc_configured":
        return filter?.is_withdrawal_acc_configured;

      default:
        break;
    }
  };

  // HANDLE REMOVE FILTER
  const handleFilterRemove = (tab: string, data: any, dataValue: any) => {
    const setFilter = (filterSetter: Function) => {
      layout.setIsSelectAll({ status: "" });
      filterSetter((currentVal: any) => {
        let final = {
          ...currentVal,
          [`${data}`]: dataValue,
          page: 1,
        };
        delete final?.id;
        return final;
      });
    };

    switch (tab) {
      case "Seller wallets":
        setFilter(layout.setSellerWalletsFilter);
        break;
      case "Bank accounts":
        setFilter(layout.setBankAccountFilter);
        break;
      case "Withdraw requests":
        setFilter(layout.setWithdrawRequestFilter);
        break;
      default:
        break;
    }
  };
  // GET TOTAL FUNCTION
  const getTotal = () => {
    return tab === "Seller wallets" ||
      tab === "Withdraw requests" ||
      tab === "Bank accounts"
      ? `${totalList ? addCommaInNumber(totalList) : "0"} results`
      : "";
  };

  // function ToggleElemClose() {
  //   const submenuWrapper: any = document.querySelector("#filtersMob");
  //   submenuWrapper.classList.remove("activeFIlter");
  //   document.body.classList.remove("overflow-hidden");
  // }
  // function ToggleElemOpen() {
  //   console.log("object");
  //   const submenuWrapper: any = document.querySelector("#filtersMob");
  //   submenuWrapper.classList.add("activeFIlter");
  //   document.body.classList.add("overflow-hidden");
  // }

  return (
    <>
      {filterVisibile ? (
        <>
          <div className="search-filter-result px-2.5 py-3.5 flex flex-nowrap w-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full flex-1">
            <div className="clr_filter   lg2:max-w-[25%] flex-1 w-auto px-[.3125rem]">
              <div
                className={`px-[.9375rem] py-[.5625rem] bg-gray-100/50 border rounded-xl md:min-w-[15.625rem] min-w-[11.25rem] h-full ${
                  loading && "shimmer-effect rounded"
                }`}
              >
                <h3 className={`text-sm15 leading-5 mb-1 font-semibold  `}>
                  {/* <span className={`${loading && "shimmer-effect"}`}> */}
                  <span className={`${loading && "opacity-0"}`}>
                    {getTotal()}
                  </span>
                  {/* </span> */}
                </h3>
                <span
                  className="text-sm12 leading-4 flex items-center cursor-pointer w-[5.625rem]"
                  onClick={handleOnclear}
                >
                  <IKTSVG
                    path={refresh}
                    className=" mr-1.5"
                    svgClassName="w-[.6875rem] h-[.6875rem] fill-violet-500 hover:fill-indigo-500"
                  />
                  Clear filters
                </span>
              </div>
            </div>

            {/* ORDER STATUS */}
            {size(checkFilterTypeTab("order_status", tab)) > 0 &&
              checkFilterTypeTab("order_status", tab)?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(checkFilterTypeTab("order_status", tab)) > 1
                      ? size(checkFilterTypeTab("order_status", tab)) +
                        " selected"
                      : checkFilterTypeTab("order_status", tab)?.[0]
                          ?.description
                  }
                  filterName={"Order status"}
                  onRemove={() => handleFilterRemove(tab, "order_status", [])}
                  loading={loading}
                />
              )}

            {/* TEAMS - CHIP */}
            {size(checkFilterTypeTab("teams", tab)) > 0 &&
              checkFilterTypeTab("teams", tab)?.[0] && (
                <CommonFilterChips
                  filterValue={
                    size(checkFilterTypeTab("teams", tab)) > 1
                      ? size(checkFilterTypeTab("teams", tab)) + " selected"
                      : checkFilterTypeTab("teams", tab)?.[0]?.name
                  }
                  filterName={"Teams"}
                  onRemove={() => handleFilterRemove(tab, "teams", [])}
                  loading={loading}
                />
              )}

            {/* WALLET STATUS */}
            {_.size(checkFilterTypeTab("statuses", tab)) > 0 && (
              <CommonFilterChips
                filterValue={
                  size(checkFilterTypeTab("statuses", tab)) > 1
                    ? size(checkFilterTypeTab("statuses", tab)) + " selected"
                    : checkFilterTypeTab("statuses", tab)?.[0]?.name
                }
                filterName={
                  tab === "Seller wallets"
                    ? "Wallet status"
                    : "Withdraw request status"
                }
                onRemove={() => handleFilterRemove(tab, "statuses", null)}
                loading={loading}
              />
            )}

            {checkFilterTypeTab("is_withdrawal_acc_configured", tab) && (
              <CommonFilterChips
                filterValue={`${
                  checkFilterTypeTab("is_withdrawal_acc_configured", tab)?.name
                }`}
                filterName={"Withdrawal bank account configured"}
                onRemove={() =>
                  handleFilterRemove(tab, "is_withdrawal_acc_configured", null)
                }
                loading={loading}
              />
            )}

            {/* REQUESTED DATE */}
            {withdrawRequestFilter?.requestedDate &&
              withdrawRequestFilter?.requestedDate?.startDate !== undefined &&
              withdrawRequestFilter?.requestedDate?.endDate !== undefined && (
                <CommonFilterChips
                  filterValue={`${moment(
                    withdrawRequestFilter?.requestedDate?.startDate
                  ).format("DD/MM/YYYY")} - ${moment(
                    withdrawRequestFilter?.requestedDate?.endDate
                  ).format("DD/MM/YYYY")} `}
                  filterName={"Requested date"}
                  onRemove={() => {
                    layout.setWithdrawRequestFilter((currentVal: any) => {
                      return {
                        ...currentVal,
                        requestedDate: null,
                        page: 1,
                        per_page: 20,
                      };
                    });
                  }}
                  loading={loading}
                />
              )}

            {checkFilterTypeTab("txpay_change_request", tab) && (
              <CommonFilterChips
                filterValue={"Selected"}
                filterName={"Change requested"}
                onRemove={() =>
                  handleFilterRemove(tab, "txpay_change_request", false)
                }
                loading={loading}
              />
            )}
          </div>
          <div
            className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
            id="filterBtn"
            onClick={ToggleElemOpen}
          >
            <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
              <IKTSVG
                path={filter}
                className="fill-white mr-1.5"
                svgClassName="w-4 h-4"
              />
              Filter results
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
            <span className={`${loading && "shimmer-effect"}`}>
              <span className={`${loading && "opacity-0"}`}>{getTotal()}</span>
            </span>
          </div>
          <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArequestedDatea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start "></div>
          <div
            className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
            id="filterBtn"
            onClick={ToggleElemOpen}
          >
            <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
              <IKTSVG
                path={filter}
                className="fill-white mr-1.5"
                svgClassName="w-4 h-4"
              />
              Filter results
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default FiltersChips;
