import FilterChips from "./FilterChips";
import Filters from "./Filters";

const FilterWrapper = ({ loading }: any) => {
  return (
    <>
      {/* ALL FILTERS */}
      <Filters loading={loading} />

      {/* FILTER CHIPS */}
      <FilterChips loading={loading} />
    </>
  );
};

export default FilterWrapper;
