import { storeFields } from "components/core/requests";
import { IKTSVG } from "components/ui/IKTSVG";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { existInLocalstorage } from "helpers/Functions";
import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import close from "../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { TXContext } from "../core/TXProvider";
import { TX_TAB } from "../core/const";

export const VirtualCardPolicy = ({ setIsVirtualCardpolicyOpen }: any) => {
  const layout = useContext(LayoutContext);

  const { setActiveTab } = useContext(TXContext);

  const [isScrolled80, setIsScrolled80] = useState<any>(false);
  const contentRef = useRef<any>(null);

  const payload = [
    {
      key: "saveTxPayVirtualCardTerms",
      value: 1,
    },
  ];

  let checkLocalStorage = existInLocalstorage("access");

  const { refetch, isLoading } = useQuery(
    [`tx-pay-virtualCardTerms`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onSettled: (data) => {
        if (data?.data?.status) {
          setIsVirtualCardpolicyOpen(false);
          if (checkLocalStorage) {
            const newData = {
              ...checkLocalStorage,
              ui_field_settings: {
                ...checkLocalStorage?.ui_field_settings,
                saveTxPayVirtualCardTerms: 1,
              },
            };
            localStorage.setItem("access", JSON.stringify(newData));
          }
        }
      },
    }
  );

  const handleScroll = () => {
    const content: any = contentRef?.current;
    if (content) {
      const scrollTop = content?.scrollTop;
      const scrollHeight = content?.scrollHeight;
      const clientHeight = content?.clientHeight;

      const scrollPercentage: any =
        (scrollTop / (scrollHeight - clientHeight)) * 100;

      if (scrollPercentage >= 80 || scrollHeight <= clientHeight) {
        setIsScrolled80(true);
      }
    }
  };

  useEffect(() => {
    const content: any = contentRef?.current;
    if (content) {
      content.addEventListener("scroll", handleScroll);
      // Check if content fits without scrolling on mount
      if (content.scrollHeight <= content.clientHeight) {
        setIsScrolled80(true);
      }
      window.addEventListener("resize", handleScroll);
    }

    return () => {
      if (content) {
        content.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();
  }, [layout.htmlFontSize]);

  return (
    <div className="policyPopup fixed top-0 md:left-[3.75rem] right-0 bottom-0 z-[9999] px-5 py-[3.75rem] bg-black bg-opacity-[70%] flex items-center justify-center transition-all opacity-100 visible">
      <div className="flex flex-col bg-white rounded-xl w-full max-w-2xl h-full">
        <div className="flex justify-between border-b">
          <h1 className="text-sm15 leading-6 font-semibold px-5 py-2">
            TX Pay Virtual Cards Terms & Conditions
          </h1>
          <button
            className="flex-none flex items-center justify-center w-10 h-full min-h-10 border-l"
            onClick={() => setActiveTab(TX_TAB.wallet)}
          >
            <IKTSVG path={close} svgClassName="w-2.5 h-2.5 fill-violet-500" />
          </button>
        </div>
        <div
          ref={contentRef}
          className="p-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
        ></div>
        <div className="px-5 py-3 border-t flex gap-5 items-center justify-end">
          <button
            type="button"
            className={`relative z-10 border inline-flex items-center transition font-semibold rounded text-sm13 px-2.5 py-0.5 ${
              !isScrolled80 || isLoading
                ? "pointer-events-none text-gray-400 bg-gray-100 border-gray-100"
                : "text-white bg-violet-500  hover:bg-indigo-500 hover:border-indigo-500"
            } `}
            onClick={() => refetch()}
          >
            I accept
          </button>
          {isLoading && <ProgressBardLoader secondCounter={1} />}
        </div>
      </div>
    </div>
  );
};
