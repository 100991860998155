/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../helpers/Loader";
import { showAlert } from "../../../helpers/ShowAlert";
import { QUERIES } from "../../../helpers/const";
import ThankYou from "../../../pages/login/thank-you";
import { LayoutContext } from "../../core/LayoutProvider";
import { getListingData, signUp } from "./core/_request";
import db from "./sign_up/component/dexie/dexie";

const LoadingPage = () => {
  const prevRoute = useLocation();
  const layout = useContext(LayoutContext);
  const navigate = useNavigate();
  const [step, setStep] = useState<any>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const stripeToken = (prevRoute?.search + "&").match(
    /(?:setup_intent\=).+?(?=\&)/g
  );

  const { data } = useQuery(QUERIES.SIGN_UP, () => getListingData(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    onError: (err) => {},
    enabled: true,
  });

  const removeQueryParams = () => {
    const param = searchParams.get("setup_intent_client_secret");
    const param2 = searchParams.get("setup_intent");
    if (param || param2) {
      // 👇️ delete each query param
      searchParams.delete("setup_intent_client_secret");
      searchParams.delete("setup_intent");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  };
  // UPDATE RECORD IN INDEX DB
  const handleUpdateRecord = async (data: any) => {
    const objects = [];
    const businessObjects = [];

    // Iterate over each file in the array
    for (let i = 0; i < data?.identity_proof?.length; i++) {
      const file = data?.identity_proof[i];
      // Create a new normal object with desired properties
      const object = {
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
        type: file.type,
        base64: file?.base64,
        // Add any other desired properties
      };
      objects.push(object); // Add the object to the array
    }
    // Iterate over each file in the array
    for (let i = 0; i < data?.business_proof?.length; i++) {
      const file = data?.business_proof[i];
      // Create a new normal object with desired properties
      const object = {
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
        type: file.type,
        base64: file?.base64,
        // Add any other desired properties
      };
      businessObjects.push(object); // Add the object to the array
    }
    // @ts-ignore
    await db.myNewStore.put({
      ...data,
      identity_proof: objects,
      business_proof: businessObjects,
      setConversationRate: [],
    });
  };

  useEffect(() => {
    // @ts-ignore
    db.myNewStore?.toArray().then((result: any) => {
      var lastItem = result?.pop();
      handleUpdateRecord({
        ...lastItem,
        ...(stripeToken && {
          card_token: Array.isArray(stripeToken) ? stripeToken[0] : stripeToken,
        }),
      });
    });

    // @ts-ignore
    db.myNewStore?.toArray().then((result) => {
      var lastItem = result?.pop();
      if (lastItem) {
        layout.setUserDetail((current: any) => {
          return {
            ...current,
            ...lastItem,
            card_token: lastItem?.card_token
              ? lastItem?.card_token
              : Array.isArray(stripeToken)
              ? stripeToken[0]
              : stripeToken,
            exchanges:
              lastItem.exchanges?.length > 0
                ? lastItem?.exchanges
                : data?.data?.exchanges,
          };
        });
      } else {
        navigate("/login");
      }
    });
  }, [data]);

  //   useEffect(() => {
  //     if (layout?.userDetail?.card_token !== "") {
  //       const signUpData = async () => {
  //         let response = await signUp(layout?.userDetail).then(
  //           (response: any) => response
  //         );
  //         if (response?.data?.status === true) {
  //           // showAlert(response?.message, false);
  //           setStep(true);
  //         } else {
  //           let errors: any;
  //           if (response?.errors?.title) {
  //             errors = response?.errors?.title;
  //           } else if (Array.isArray(response?.errors)) {
  //             errors = response?.errors;
  //           }
  //           showAlert(
  //             Array.isArray(errors) ? errors.join("<br/>") : errors,
  //             true
  //           );
  //           navigate("/payments");
  //         }
  //       };
  //       signUpData();
  //     }
  //   }, [layout?.userDetail]);

  useEffect(() => {
    signUpData();
  }, [layout.userDetail?.card_token !== ""]);

  const signUpData = async () => {
    if (layout?.userDetail?.firstname) {
      delete layout?.userDetail?.confirm_password;

      let response = await signUp(layout?.userDetail).then(
        (response: any) => response
      );
      if (response?.data?.status === true) {
        // showAlert(response?.message, false);
        setStep(true);
      } else {
        let errors: any;
        if (response?.errors?.title) {
          errors = response?.errors?.title;
        } else if (Array.isArray(response?.errors)) {
          errors = response?.errors;
        }

        showAlert(Array.isArray(errors) ? errors.join("<br/>") : errors, true);
        navigate("/payments");
      }
    }
    removeQueryParams();
  };

  return <div>{step ? <ThankYou /> : <Loader />}</div>;
};

export default LoadingPage;
