import Card from "../../../components/ui/Card";

const DashBoardShimmerLoader = ({
  mainLoading,
  blockShimmerCount = 2,
  bodyShimmerCount = 4,
  bodyShimmerCountSix,
  headerShimmer = true,
  width = "",
  isLoading,
}: any) => {
  return (
    <div
      className={`xl:w-1/2 lg2:w-1/2 ${
        width ? "!w-full" : "w-full md:px-2 mb-4"
      }   dashboard-shimmer`}
    >
      <Card isLoading={isLoading}>
        {/* HEADER - SHIMMER */}
        {headerShimmer && (
          <div
            className={`relative w-full flex justify-between bg-white rounded-t px-5 pt-[1.375rem]   gap-2 false false  ${
              blockShimmerCount < 1 && "mb-[1.625rem]"
            } `}
          >
            <div
              className={`ul 1 md:w-1/2   rounded-lg shimmer-effect ${
                mainLoading && "shimmer-effect"
              } `}
              style={{ height: "1rem" }}
            >
              {" "}
              <span className={` ${mainLoading && "opacity-0"} `}>
                second div
              </span>
            </div>
          </div>
        )}

        {/* BLOCK SHIMMER */}
        {blockShimmerCount > 0 && (
          <div
            className={`flex flex-wrap justify-between p-5 -mx-2.5 divide-gray-200 ${
              !width && "pt-[1.625rem]"
            } `}
          >
            {Array.from({ length: blockShimmerCount }, (v, i) => (
              <div
                key={i}
                className={`px-2.5 md:w-1/2 w-full mb-2.5 md:mb-0 ${
                  mainLoading && ""
                } `}
              >
                <div
                  className={` rounded px-4 py-2.5 h-[5rem] shimmer-effect ${
                    mainLoading && " shimmer-effect"
                  } `}
                >
                  <span className={` ${mainLoading && "opacity-0"} `}>
                    second div
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* BODY SHIMMER */}
        <div
          className={`relative flex flex-col	 justify-between bg-white rounded px-5  pb-5  gap-5 ${
            blockShimmerCount < 1 && "mt-1.5"
          }`}
        >
          {bodyShimmerCount > 0 &&
            Array.from(
              {
                length: bodyShimmerCountSix ? 6 : bodyShimmerCount,
              },
              (v, i) => (
                <div
                  key={i}
                  className={`ul 1 rounded shimmer-effect h-7 ${
                    mainLoading && "shimmer-effect"
                  } `}
                >
                  {" "}
                  <span className={` ${mainLoading && "opacity-0"} `}>
                    second div
                  </span>
                </div>
              )
            )}
        </div>
      </Card>
    </div>
  );
};

export default DashBoardShimmerLoader;
