export const CollapseIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="8.997"
    viewBox="0 0 9 8.997"
    {...props}
  >
    <path
      id="Union_14"
      data-name="Union 14"
      d="M4.5,5.8.9,9,0,7.99,4.5,4,9,7.99,8.1,9Zm0-4L.9,5,0,3.992,4.5,0,9,3.992,8.1,5Z"
    />
  </svg>
);
