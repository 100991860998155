import { useContext } from "react";
import { Link } from "react-router-dom";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { decimalNumber } from "../../../helpers/Functions";
import { TX_TAB } from "../core/const";

const TxPayTab = ({
  tab,
  onClick,
  labelText,
  currency,
  amount,
  shimmer,
  route,
}: any) => {
  const globalLayout: any = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  return (
    <Link
      to={`/tx-pay${route}`}
      className={`single-tab lg:px-4 px-2.5 py-[.5625rem]  lg:whitespace-normal whitespace-nowrap] flex  items-center bg-white rounded-t-lg cursor-pointer mr-[1px] w-full gap-2 group hover:bg-opacity-100 lg2:max-w-[16.4375rem] min-w-[10.625rem] justify-between ${
        tab === labelText ? "  bg-opacity-100 z-10" : "bg-opacity-60"
      }`}
      onClick={() => onClick(labelText)}
    >
      <h4
        className={`lg:text-sm15 text-sm14 font-semibold group-hover:text-indigo-500 `}
      >
        {labelText}
      </h4>

      {!(isGodAdmin && labelText === TX_TAB.virtualCard) && (
        <>
          {shimmer && tab === labelText ? (
            <span
              className={`shimmer-effect flex items-center justify-center bg-violet-500 text-xxs text-white px-1.5 py-[.1563rem] rounded group-hover:bg-indigo-500 transition-all min-h-[1.25rem] min-w-[1.25rem]`}
            ></span>
          ) : (
            <span
              className={`flex items-center justify-center bg-violet-500 text-xxs text-white px-1.5 py-[.1563rem] rounded group-hover:bg-indigo-500 transition-all min-h-[1.25rem] min-w-[1.25rem] `}
            >
              {currency}
              {/* {decimalNumber(amount)} */}
              {currency
                ? amount
                  ? decimalNumber(amount)
                  : "0"
                : amount
                ? amount
                : "0"}
            </span>
          )}
        </>
      )}
    </Link>
  );
};

export default TxPayTab;
