import { LayoutContext } from "components/core/LayoutProvider";
import HeaderCell from "pages/subscribe/personal_details/table/component/HeaderCell";
import { useContext } from "react";

const TableHeaderRow = () => {
  const globalLayout = useContext(LayoutContext);

  return (
    <tr className="border-b  bg-white ">
      <HeaderCell
        title={"Levels name"}
        width="w-[6.25rem] min-w-[6.25rem]"
        arrow={false}
        postKey="name"
        padding="pl-5"
      />

      <HeaderCell
        title={"Team count"}
        width="w-[6.25rem] min-w-[6.25rem]"
        arrow={false}
        postKey="name"
        padding="pl-5"
      />
      <HeaderCell
        title={"Weightage"}
        width="w-[6.25rem] min-w-[6.25rem]"
        arrow={false}
        postKey="name"
        padding="pl-5"
      />

      <th className="md:sticky relative right-0 ml-auto  p-0 md:w-[13.0625rem] md:max-w-[13.0625rem] md:min-w-[13.0625rem] w-[9.375rem] max-w-[9.375rem] min-w-[9.375rem]"></th>
      {globalLayout?.isMobile && (
        <th className="sticky right-0 ml-auto  p-0 w-10 min-w-[2.5rem]">
          <div
            className="py-[.4375rem] px-4 flex justify-end h-[2.5rem]  bg-white max-md:pointer-events-none"
            id=""
          ></div>
        </th>
      )}
    </tr>
  );
};

export default TableHeaderRow;
