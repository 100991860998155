import { useContext } from "react";
import { Tooltip } from "react-tooltip";
import EDITSVG from "../../../../../assets/media/icons/other_icons/edit-square.svg";
import { LayoutContext } from "../../../../../components/core/LayoutProvider";
import DropDownField from "../../../../../components/formComponent/DropDownField";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";

const TableActionButtons = ({
  list,
  sIndex,
  matchedView,
  handleViewForm,
  handleOnChangeStatus,
}: any) => {
  const subscription_option = [
    {
      id: "1",
      name: "Active",
      label: "Active",
    },
    {
      id: "3",
      name: "Declined",
      label: "Declined",
    },
  ];
  const findOption = (options: any, value: any) => {
    return options.find((option: any) => option?.name === value) || null;
  };

  const globalLayout = useContext(LayoutContext);

  const currentStatusValue = list?.status?.name;
  const selectedOption = findOption(subscription_option, currentStatusValue);

  // useEffect(() => {
  //   function hideArrowScroll1() {
  //     const filter_scroll: any = document.getElementById("tableId1 ");
  //     const maxScrollLeft: any =
  //       filter_scroll?.scrollWidth - filter_scroll?.clientWidth;
  //     console.log(filter_scroll?.scrollWidth, filter_scroll?.clientWidth);

  //     if (Math.round(filter_scroll.scrollLeft) >= maxScrollLeft) {
  //       console.log("object");
  //     } else {
  //       console.log("object11");
  //     }
  //   }
  //   const filter_scroll = document.getElementById("filter_scroll"); // Make sure filter_scroll is defined and references the correct element
  //   filter_scroll?.addEventListener("scroll", hideArrowScroll1);
  //   hideArrowScroll1();
  //   return () => {
  //     filter_scroll?.removeEventListener("scroll", hideArrowScroll1); // Cleanup: Remove event listener when component unmounts
  //   };
  // }, []);
  return (
    <>
      <td
        className={`md:sticky relative right-0 ml-auto p-0 icon_td md:w-[13.0625rem] md:max-w-[13.0625rem] md:min-w-[13.0625rem] w-[8rem] max-w-[8rem] min-w-[8rem]`}
      >
        <div
          className={` bg-opacity-[7%] ${
            matchedView && "bg-indigo-500 bg-opacity-[7%]"
          }`}
        >
          <div
            className="flex left-shad relative w-full h-full border-l"
            id="left-shad-list"
          >
            <div
              id={sIndex}
              className="w-[8rem] max-w-[8rem] min-w-[8rem] border-r subscription_drop p-[.625rem] pt-[.4rem] "
            >
              <DropDownField
                options={subscription_option}
                placeholder={selectedOption ? "" : "Action"}
                name={`Status`}
                getOptionLabel="label"
                value={selectedOption}
                isMenuPortalTarget={true}
                isClearable={false}
                getOptionValue="name"
                handleOnChange={(data: any) => {
                  handleOnChangeStatus(data, list, sIndex);
                }}
                isSearchable={false}
              />
            </div>
            <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1 max-md:hidden">
              <button
                className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                type="button"
                id={`record-view-tooltip${sIndex}`}
                onClick={() => {
                  handleViewForm(list, sIndex);
                }}
              >
                <Tooltip
                  anchorId={`record-view-tooltip${sIndex}`}
                  content={`${"Edit"}`}
                  place={`${sIndex === 0 ? "left" : "top"}`}
                  variant="light"
                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                    sIndex === 0 ? "mt-0" : "mt-1.5"
                  }`}
                />
                <IKTSVG
                  className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
                  svgClassName="w-4 h-[.6875rem]"
                  path={EDITSVG}
                />
              </button>
            </div>
          </div>
        </div>
      </td>
      {globalLayout?.isMobile && (
        <td
          className={`sticky right-0 ml-auto p-0 icon_td w-[2.5rem] max-w-[2.5rem] min-w-[2.5rem]`}
        >
          <div
            className={` bg-opacity-[7%] ${
              matchedView && "bg-indigo-500 bg-opacity-[7%]"
            }`}
          >
            <div
              className="flex left-shad relative w-full h-full border-l"
              id="left-shad-list"
            >
              <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1">
                <button
                  className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                  type="button"
                  id={`record-view-tooltip${sIndex}`}
                  onClick={() => {
                    handleViewForm(list, sIndex);
                  }}
                >
                  <Tooltip
                    anchorId={`record-view-tooltip${sIndex}`}
                    content={`${"Edit"}`}
                    place={`${sIndex === 0 ? "left" : "top"}`}
                    variant="light"
                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                      sIndex === 0 ? "mt-0" : "mt-1.5"
                    }`}
                  />
                  <IKTSVG
                    className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
                    svgClassName="w-4 h-[.6875rem]"
                    path={EDITSVG}
                  />
                </button>
              </div>
            </div>
          </div>
        </td>
      )}
    </>
  );
};

export default TableActionButtons;
