type TButton = {
  id?: string;
  dataTooltipID?: string;
  className: string;
  children?: React.ReactNode;
  btnType?: "button" | "submit" | "reset";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button = ({
  id,
  dataTooltipID,
  className,
  children,
  btnType = "button",
  onClick,
}: TButton) => {
  return (
    <button
      id={id}
      className={className}
      type={btnType}
      data-tooltip-id={dataTooltipID}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
