import _, { size } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { showAlert } from "../../../helpers/ShowAlert";
import { cancelWithdrawlRequest } from "../../tx_pay/core/requests";
import { TxAccountContext } from "../core/TxAccountProvider";
import { approveWithdrawalRequest } from "../core/_request";

const WithdrawFooter = () => {
  const layout = useContext(TxAccountContext);
  const setIsSelectAll = layout.setIsSelectAll;
  const isSelectAll = layout.isSelectAll;
  const [loader, setLoader] = useState<boolean>(false);
  let invoiceTotalRecords = layout?.withdrawRequestMeta?.total;

  let isSelectAllForFooter = layout.withdrawRequestList?.filter(
    (item: any) => item?.status === "Active"
  );

  let approvedRecords =
    size(
      _.filter(layout.withdrawRequestList, {
        status: "Active",
        is_valid_amount: true,
        is_wallet_and_bank_acc_set: true,
      })
    ) > 0;

  // LOAD ALL RECORDS FROM DB
  useEffect(() => {
    if (
      invoiceTotalRecords === size(layout.withdrawRequestList) &&
      isSelectAll?.status === "load"
    ) {
      layout.setWithdrawRequestList((prev: any) => {
        return prev.map((current: any) => {
          return {
            ...current,
            selected: current?.status === "Active" ? true : false,
          };
        });
      });
      setIsSelectAll({ status: "bulk" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceTotalRecords, layout.withdrawRequestList, isSelectAll]);

  isSelectAllForFooter =
    size(isSelectAllForFooter) > 0
      ? isSelectAllForFooter?.every((item: any) => item?.selected)
      : false;

  let isAnyStatucActive: any = useMemo(() => {
    let isActive = layout?.withdrawRequestFilter?.statuses?.find(
      (item: any) => item?.name === "Active"
    );
    return size(layout?.withdrawRequestFilter?.statuses) === 0 &&
      size(
        layout.withdrawRequestList?.filter(
          (item: any) => item?.status === "Active"
        )
      ) > 0
      ? true
      : isActive &&
        size(
          layout.withdrawRequestList?.filter(
            (item: any) => item?.status === "Active"
          )
        ) > 0
      ? isActive
      : false;
  }, [layout?.withdrawRequestFilter?.statuses, layout.withdrawRequestList]);

  // HANDLE BULK ACTION
  const bulkAction = (action: string) => {
    setLoader(true);
    let approvedRecords = _.filter(layout.withdrawRequestList, {
      status: "Active",
      is_valid_amount: true,
      is_wallet_and_bank_acc_set: true,
    });
    if (action === "approve" && size(approvedRecords) > 0) {
      let withdrawalIds = _.map(approvedRecords, "id");

      // APPROVE API
      approveWithdrawalRequest(withdrawalIds)
        .then((response: any) => {
          if (response?.data?.status === false && size(response?.errors) > 0) {
            showAlert(response?.errors?.[0], true);
            setLoader(false);
          } else if (response?.data?.status) {
            showAlert(response?.message, false);
            layout.setWithdrawRequestList((prev: any) => {
              return prev.map((current: any) => {
                return {
                  ...current,
                  selected: false,
                };
              });
            });
            setIsSelectAll({ status: "" });
            setLoader(false);

            setTimeout(() => {
              layout.setWithdrawRequestFilter((current: any) => {
                return {
                  ...current,
                  page: 1,
                  asd: "",
                };
              });
            }, 200);
          }
        })
        .catch((err: any) => {
          showAlert("Something went wrong", true);
          setLoader(false);
        });
    } else if (action === "reject") {
      // REJECT ALL API
      let withdrawalIds = _.map(
        _.filter(layout.withdrawRequestList, {
          status: "Active",
        }),
        "id"
      );
      size(withdrawalIds) > 0 &&
        cancelWithdrawlRequest(withdrawalIds)
          .then((response: any) => {
            if (
              response?.data?.status === false &&
              size(response?.errors) > 0
            ) {
              showAlert(response?.errors?.[0], true);
              setLoader(false);
            } else if (response?.data?.status) {
              showAlert(response?.message, false);
              layout.setWithdrawRequestList((prev: any) => {
                return prev.map((current: any) => {
                  return {
                    ...current,
                    selected: false,
                  };
                });
              });
              setLoader(false);
              setIsSelectAll({ status: "" });
              setTimeout(() => {
                layout.setWithdrawRequestFilter((current: any) => {
                  return {
                    ...current,
                    page: 1,
                    asd: "",
                  };
                });
              }, 200);
            }
          })
          .catch((err: any) => {
            showAlert("Something went wrong", true);
          });
    }
  };

  return (
    <div className="flex flex-wrap gap-2.5 my-2 w-full ">
      {isSelectAll?.status === "load" ? (
        <div className="flex flex-wrap items-center gap-2.5  text-sm13 anim-fade">
          <button
            className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
     bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
            type="button"
            onClick={() => setIsSelectAll({ status: "" })}
          >
            Cancel
          </button>
          <button
            type="button"
            className="group inline-flex group items-center transition font-medium rounded px-2.5 py-0.5 leading-5
    bg-violet-500 hover:bg-indigo-500 text-white hover:text-white
    "
            onClick={(data: any) => {
              layout.setWithdrawRequestFilter((current: any) => {
                return {
                  ...current,
                  page: 1,
                  per_page: invoiceTotalRecords,
                };
              });
            }}
          >
            Confirm
          </button>
          <span>Do you want to select all records?</span>
        </div>
      ) : isSelectAll?.status === "bulk" ? (
        <div
          className={`flex flex-wrap items-center gap-2.5  text-sm13 anim-fade ${
            loader ? "pointer-events-none" : ""
          }`}
        >
          <button
            className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
     bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
            type="button"
            onClick={() => {
              layout.setWithdrawRequestList((prev: any) => {
                return prev.map((current: any) => {
                  return {
                    ...current,
                    selected: false,
                  };
                });
              });
              setIsSelectAll({ status: "" });
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className="group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5
    bg-violet-500 hover:bg-indigo-500 text-white hover:text-white
    "
            onClick={(data: any) => bulkAction("reject")}
          >
            Reject all
          </button>
          <button
            type="button"
            className={`group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5
            ${
              approvedRecords
                ? "bg-violet-500 hover:bg-indigo-500 text-white hover:text-white"
                : "pointer-events-none bg-gray-100 text-gray-400"
            }
            `}
            onClick={(data: any) => bulkAction("approve")}
          >
            Approve all
          </button>
        </div>
      ) : (
        <div
          className={`${!isAnyStatucActive ? "pointer-events-none" : ""}
            anim-fade inline-flex items-center text-violet-800 transition font-medium rounded px-2.5 py-0.5`}
        >
          <input
            id="default-checkbox"
            type="checkbox"
            readOnly
            checked={isSelectAllForFooter}
            // disabled={!isSelectAllForFooter ? true : false}
            className={`w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600`}
            onChange={(data: any) => {
              if (
                data?.target?.checked &&
                invoiceTotalRecords !==
                  (size(layout.withdrawRequestList) > 0 &&
                    size(layout.withdrawRequestList))
              ) {
                setIsSelectAll({ status: "load" });
              } else if (
                data?.target?.checked &&
                invoiceTotalRecords ===
                  (size(layout.withdrawRequestList) > 0 &&
                    size(layout.withdrawRequestList)) &&
                layout.withdrawRequestList?.some(
                  (item: any) => item?.status === "Active"
                )
              ) {
                layout.setWithdrawRequestList((prev: any) => {
                  return prev.map((current: any) => {
                    return {
                      ...current,
                      selected: current?.status === "Active" ? true : false,
                    };
                  });
                });
                setIsSelectAll({ status: "bulk" });
              } else if (!data?.target?.checked) {
                layout.setWithdrawRequestList((prev: any) => {
                  return prev.map((current: any) => {
                    return {
                      ...current,
                      selected: false,
                    };
                  });
                });
              }
            }}
          />
          <label
            htmlFor="default-checkbox"
            className={` ml-2 text-sm13 font-medium  dark:text-gray-300 text-gray-900`}
          >
            Select all
          </label>
        </div>
      )}

      {/* LOADER */}
      {loader && <ProgressBardLoader LoadingText={``} secondCounter={"2"} />}
    </div>
  );
};

export default WithdrawFooter;
