import React from 'react'

const DetailComponent = ({title, detail} : any) => {
  return (
    <div className="md:w-1/2 p-2 w-full">
      <div className="text-gray-400 text-sm12">
        {title}
      </div>
      <div className="text-sm12 break-words">
        {detail}
      </div>
    </div>
  )
}

export default DetailComponent