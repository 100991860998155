import _, { size } from "lodash";
import React, { useContext, useState } from "react";
import Plus from "../../../assets/media/icons/other_icons/plus.svg";
import cross from "../../../assets/media/icons/standard_icons/cross.svg";
import TextInput from "../../../components/formComponent/TextInput";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { showAlert } from "../../../helpers/ShowAlert";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { TxAccountContext } from "../core/TxAccountProvider";
import {
  downloadPDF,
  getTXAccountOrdersListings,
  saveInvoiceCommission,
} from "../core/_request";
import CommissionPDFView from "./CommissionPDFView";
import PDFView from "./PDFView";

const ExcludeContainer = ({
  draftInvoiceList,
  excludeOrderValue,
  setExcludeOrderValue,
  // confirmedExclude,
  isDisabled,
  setDraftInvoiceList,
  refetch,
}: any) => {
  const layout = useContext(TxAccountContext);
  const commonConfirmation = layout?.commonConfirmation;
  const [isCommissionLoading, setIsCommissionLoading] =
    useState<boolean>(false);
  const [xeroCommissionId, setXeroCommissionId] = useState<string | null>(null);

  // ON ENTER -  EXCLUDE ORDER
  const handleExcludeOrder = () => {
    let enteredIds: any = excludeOrderValue?.excludeOrder
      .split(/\s*,\s*|\s+/)
      .map((id: any) => id.trim());
    enteredIds = _.uniq(enteredIds);
    let existingIds: any =
      [
        ...(layout.commonConfirmation?.listing_data?.filterData
          ?.excludesOrderId || []),
        ...excludeOrderValue?.excludeOrderFilterChip,
      ] || [];

    // Filter out IDs that are already in existingIds
    let uniqueIds = enteredIds.filter((id: any) => !existingIds.includes(id));

    if (uniqueIds?.length === 0) {
      showAlert("Please enter unique order ID", true);
    }

    uniqueIds.forEach((id: any) => {
      if (id.toString().length >= 4 && id.toString().length <= 36) {
        setExcludeOrderValue((current: any) => {
          let excludeOrderFilterChip = [...current.excludeOrderFilterChip, id];
          return {
            ...current,
            excludeOrder: "",
            excludeOrderFilterChip: excludeOrderFilterChip, // Use current.excludeOrderFilterChip to get the latest state
          };
        });
      }
    });
  };

  // CONFIRMED EXCLUDE
  const confirmedExclude = (excludeOrdersList: any, existingOrders: any) => {
    layout?.setCustomLoader(true);

    // EXCLUDE ORDERS API -
    getTXAccountOrdersListings({
      orderFilters: layout?.orderFilters,
      get_teams: true,
      exclude_order_ids: [
        ...excludeOrdersList,
        ...(existingOrders ? existingOrders : []),
      ],
    })
      .then((response: any) => {
        if (response?.data) {
          layout.setCommonConfirmation((current: any) => {
            return {
              ...current,
              listing_data: {
                ...current?.listing_data,
                filterData: {
                  ...current?.listing_data?.filterData,
                  excludesOrderId:
                    current?.listing_data?.filterData?.excludesOrderId &&
                    size(current?.listing_data?.filterData?.excludesOrderId) > 0
                      ? [
                          ...current?.listing_data?.filterData?.excludesOrderId,
                          ...excludeOrderValue?.excludeOrderFilterChip,
                        ]
                      : excludeOrderValue?.excludeOrderFilterChip,
                  orders: {
                    value: response?.data?.orders_count_total,
                    excludeOrders: response?.data?.orders_count_excluded,
                  },
                  teamsOption: {
                    options: response?.data?.teams,
                    value: response?.data?.teams_count_total,
                    excludeValue: response?.data?.teams_count_excluded,
                  },
                  invoiceValue: {
                    ...current?.listing_data?.filterData?.invoiceValue,
                    value: response?.data?.invoice_value_total,
                    excludeInvoice: response?.data?.invoice_value_excluded,
                  },
                },
              },
            };
          });

          setTimeout(() => {
            refetch();
          }, 500);
        }

        // RESET EXCLUDE STATE
        setExcludeOrderValue((current: any) => {
          return {
            ...current,
            excludeOrder: "",
            excludeOrderFilterChip: [],
          };
        });
      })
      .finally(() => {
        layout?.setCustomLoader(false);
      });
  };

  // DOWNLOAD PDF
  const handleDownloadPDF = (data: any) => {
    layout.setCustomLoader(true);
    downloadPDF(data)
      .then((response: any) => {
        const blob = new Blob([response], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
        layout.setCustomLoader(false);
      })
      .catch((err) => {})
      .finally(() => {
        layout.setCustomLoader(false);
      });
  };

  const createCommissionInvoice = (e: any) => {
    e.preventDefault();
    setIsCommissionLoading(true);
    saveInvoiceCommission(
      commonConfirmation?.draftData?.invoice_primary_id
    ).then((response: any) => {
      if (response?.data?.status) {
        setXeroCommissionId(response?.data?.invoice_id);
        setIsCommissionLoading(false);
      } else if (size(response?.errors) > 0) {
        showAlert(response?.errors?.[0], true);
        setIsCommissionLoading(false);
      } else {
        showAlert("Something went wrong", true);
        setIsCommissionLoading(false);
      }
    });
  };

  return (
    <React.Fragment>
      {!commonConfirmation?.finish && (
        <>
          <div className="exclude_order p-5 pt-3.5 bg-gray-100/50 mt-[1.875rem] rounded">
            {/* INVOICE No & REFERENCE */}
            <div className="flex flex-wrap gap-y-5  -mx-2.5 mb-6">
              <div className="block_wrap w-1/2 px-2.5">
                <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
                  Enter invoice number
                </h5>
                <TextInput
                  inputClassName="placeholder:truncate block px-2.5 border-gray-200 font-medium w-full text-xs transition  border appearance-none rounded focus:outline-none  focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 peer peer-focus:bg-white autofill:bg-indigo-500 form"
                  type="text"
                  value={excludeOrderValue?.invoice_number}
                  name={"invoice_number"}
                  required={false}
                  disabled={isDisabled}
                  placeholder={"Invoice number"}
                  handleClearValue={(data: any) =>
                    setExcludeOrderValue((current: any) => {
                      return {
                        ...current,
                        invoice_number: "",
                      };
                    })
                  }
                  handleOnChange={(data: any) =>
                    setExcludeOrderValue((current: any) => {
                      return {
                        ...current,
                        invoice_number: data?.target.value,
                      };
                    })
                  }
                  inputHeight={"!h-7"}
                  isIcon={false}
                />
              </div>

              {/* REFERENCE  */}
              <div className="block_wrap w-1/2 px-2.5">
                <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
                  Enter reference
                </h5>
                <TextInput
                  inputClassName="placeholder:truncate block px-2.5 border-gray-200 font-medium w-full text-xs transition border appearance-none rounded focus:outline-none  focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 peer peer-focus:bg-white autofill:bg-indigo-500 form"
                  type="text"
                  value={excludeOrderValue?.reference}
                  name={"reference"}
                  required={false}
                  disabled={isDisabled}
                  placeholder={"Reference"}
                  handleClearValue={(data: any) =>
                    setExcludeOrderValue((current: any) => {
                      return {
                        ...current,
                        reference: "",
                      };
                    })
                  }
                  handleOnChange={(data: any) =>
                    setExcludeOrderValue((current: any) => {
                      return {
                        ...current,
                        reference: data?.target.value,
                      };
                    })
                  }
                  inputHeight={"!h-7"}
                  isIcon={false}
                />
              </div>
            </div>

            <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
              Exclude orders from Invoice
            </h5>
            <div className="exclude_input relative">
              <form className="flex flex-wrap flex-col">
                <input
                  type="text"
                  className={`w-full border rounded   text-sm14 leading-5 px-2.5 py-1 placeholder:text-gray-400 ${
                    isDisabled
                      ? "bg-gray-100 text-gray-400 border-gray-100"
                      : "bg-white border-gray-200"
                  }`}
                  placeholder="Enter order ID or marketplace ID"
                  name="excludeOrder"
                  disabled={isDisabled}
                  value={excludeOrderValue?.excludeOrder}
                  onChange={(data: any) =>
                    setExcludeOrderValue((current: any) => {
                      return {
                        ...current,
                        excludeOrder: data.target.value,
                      };
                    })
                  }
                  onKeyDown={(data: any) => {
                    if (data?.key === "Enter") {
                      data?.preventDefault();
                      handleExcludeOrder();
                    }
                  }}
                />
                <button
                  className={`submit_exclude absolute top-[1px] right-[1px] w-6 bottom-[1px]  pl-1 ${
                    isDisabled ? "pointer-events-none " : "bg-white"
                  }`}
                  type="button"
                  disabled={isDisabled}
                  data-tooltip-id={`add-exclude`}
                  onClick={(data: any) => {
                    data?.preventDefault();
                    handleExcludeOrder();
                  }}
                >
                  <TooltipPortal
                    id={`add-exclude`}
                    content={"Add"}
                    className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                  />
                  <IKTSVG
                    path={Plus}
                    className="fill-gray-400 hover:fill-indigo-500"
                    svgClassName="w-3 h-3"
                  />
                </button>
              </form>
            </div>

            {/* FILTER CHIP */}
            {size(excludeOrderValue?.excludeOrderFilterChip) > 0 && (
              <div className="exclude_list flex flex-wrap gap-2.5 mt-5">
                {excludeOrderValue?.excludeOrderFilterChip?.map(
                  (item: any, index: number) => {
                    return (
                      <div className="anim-fade single_list flex items-center text-sm12 rounded-xl px-2.5 py-[.1875rem] border bg-white">
                        <span>{item}</span>

                        <button
                          className="inline-flex items-center"
                          type="button"
                          data-tooltip-id={`item-${index}`}
                          onClick={(data: any) => {
                            setExcludeOrderValue((current: any) => {
                              return {
                                ...current,
                                excludeOrderFilterChip:
                                  current?.excludeOrderFilterChip?.filter(
                                    (excludeItem: any) => excludeItem !== item
                                  ),
                              };
                            });
                          }}
                        >
                          <TooltipPortal
                            id={`item-${index}`}
                            content={"Clear"}
                            className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                          />
                          <IKTSVG
                            path={cross}
                            className="fill-violet-500 hover:fill-indigo-500 ml-2 cursor-pointer"
                            svgClassName="w-2 h-2"
                          />
                        </button>
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        </>
      )}

      {/* CONFIRMED EXCLUDE - BUTTONS */}
      {size(excludeOrderValue?.excludeOrderFilterChip) > 0 && (
        <div className="anim-fade confirm_exclude px-5 py-3.5 flex items-center flex-wrap gap-2.5 text-sm13 bg-gray-100/50 border-t rounded-b">
          <span>
            Exclude{" "}
            {size(excludeOrderValue?.excludeOrderFilterChip) === 1
              ? `${size(excludeOrderValue?.excludeOrderFilterChip)} order?`
              : `${size(
                  excludeOrderValue?.excludeOrderFilterChip
                )} orders?`}{" "}
          </span>
          <div className="buttons gap-2.5   flex flex-wrap">
            <button
              className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
         bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
              type="button"
              onClick={(data: any) =>
                setExcludeOrderValue((current: any) => {
                  return {
                    ...current,
                    excludeOrderFilterChip: [],
                  };
                })
              }
            >
              Cancel
            </button>
            <button
              type="button"
              className="group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5
                      bg-violet-500 hover:bg-indigo-500 text-white hover:text-white
                      "
              onClick={(data: any) =>
                confirmedExclude(
                  excludeOrderValue?.excludeOrderFilterChip,
                  layout.commonConfirmation?.listing_data?.filterData
                    ?.excludesOrderId
                )
              }
            >
              Confirm
            </button>
          </div>
        </div>
      )}

      {/** CONFIRMED EXCLUDED ORDERS */}
      {_.size(
        layout?.commonConfirmation?.listing_data?.filterData?.excludesOrderId
      ) > 0 && (
        <div className="exclude_order p-5 pt-3.5 bg-gray-100/50 mt-[1.875rem] rounded">
          <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
            Excluded orders
          </h5>
          <div className="exclude_input relative"></div>
          <div className="exclude_list flex flex-wrap gap-2.5 mt-5">
            {layout?.commonConfirmation?.listing_data?.filterData?.excludesOrderId?.map(
              (id: any, key: number) => {
                return (
                  <div
                    className="anim-fade single_list flex items-center text-sm12 rounded-xl px-2.5 py-[.1875rem] border bg-white"
                    key={`confired-excluded-order-${key}`}
                  >
                    <span>{id}</span>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}

      {/* PDF COMPONENT */}
      {commonConfirmation?.generate_draft_invoice_status &&
        commonConfirmation?.generate_draft_invoice_next_step &&
        (commonConfirmation?.generate_draft_invoice_next_step_disabled ||
          commonConfirmation?.finish) && (
          <>
            <PDFView
              fileName={`tixstock-invoice-${
                commonConfirmation?.draftData?.invoice_number
              }-${commonConfirmation?.draftData?.status
                ?.toLowerCase()
                .replace(/ /g, "-")}.pdf`}
              isLoading={
                commonConfirmation?.generate_draft_invoice_next_step_disabled &&
                commonConfirmation?.finish
                  ? false
                  : commonConfirmation?.generate_draft_invoice_next_step_disabled &&
                    !commonConfirmation?.finish
                  ? true
                  : false
              }
              draftKey={commonConfirmation?.draftData?.invoice_id}
              downloadPDF={(data: any, type: string, name: string) =>
                type === "view" && handleDownloadPDF(data)
              }
            />

            <CommissionPDFView
              fileName={
                xeroCommissionId
                  ? `tixstock-commission-invoice-${
                      commonConfirmation?.draftData?.invoice_number
                    }-${commonConfirmation?.draftData?.status
                      ?.toLowerCase()
                      .replace(/ /g, "-")}.pdf`
                  : "Save commission invoice"
              }
              isLoading={
                (commonConfirmation?.generate_draft_invoice_next_step_disabled &&
                  !commonConfirmation?.finish) ||
                isCommissionLoading
              }
              draftKey={xeroCommissionId}
              isDownloadBtn={false}
              downloadPDF={(data: any, type: string, name: string) =>
                type === "view" && handleDownloadPDF(data)
              }
              saveCommissionInvoice={createCommissionInvoice}
              title={
                xeroCommissionId
                  ? "View commission invoice"
                  : "Commission invoice"
              }
              isGenerate={!xeroCommissionId}
            />
          </>
        )}

      {/* LOADER */}
      {layout?.customLoader && <ProgressBardLoader secondCounter={1} />}
    </React.Fragment>
  );
};

export default ExcludeContainer;
