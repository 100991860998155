import { Fragment, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  LOG_TAB_KEYS,
  LOG_TABS,
} from "../../../../components/ExpandableList/constants/constants";
import { ExpandableList } from "../../../../components/ExpandableList/ExpandableList";
import { KTSVG } from "../../../../components/ui/KTSVG";
import { Tab } from "../../../../components/ui/Tab/Tab";
import { QUERIES } from "../../../../helpers/const";
import { display } from "../../../Sales/core/_functions";
import NoDataAvailable from "../../../tx_account/component/NoDataAvailable";
import { PopUpShimmer } from "./Shimmer";

function convertIntoStringValue(data: any) {
  let value = data?.value ?? data;

  // Convert null values to an empty string
  if (value == null) return "";

  // Convert the value to a string
  value = String(value);

  // Check if the value is a valid number and, if so, convert it to a number and back to string (ex- 2000.00 -> 2000)
  if (!isNaN(parseFloat(value)) && isFinite(Number(value)))
    value = String(Number(value));

  return value;
}

const updateList = (listData = []) => {
  if (listData.length > 1) {
    let result: any = [];

    for (let index = listData.length - 1; index >= 0; index--) {
      const currentElement: any = listData[index];
      const prevElement: any = listData[index - 1];

      if (listData.length - 1 === index) result.push(currentElement);
      if (currentElement?.data && prevElement?.data) {
        let currentElementKeys = Object.keys(currentElement?.data);
        let prevElementKeys = Object.keys(prevElement?.data);

        prevElementKeys.forEach((key) => {
          const currentValue = convertIntoStringValue(currentElement.data[key]);
          const prevValue = convertIntoStringValue(prevElement.data[key]);

          if (
            (currentElementKeys.includes(key) && prevValue !== currentValue) ||
            (!currentElementKeys.includes(key) && prevValue)
          ) {
            prevElement.updatedKeys = [...(prevElement.updatedKeys ?? []), key];
          }
        });

        result.push(prevElement);
      }
    }
  }
  return listData;
};

export const LogDetailsPopUp = (props: any) => {
  const {
    logsData,
    setLogsData,
    request,
    isDisplay = true,
    activeTab = LOG_TAB_KEYS.ORDER,
    setActiveTab = () => {},
  } = props;
  const [listData, setListData] = useState([]);

  const { isLoading, isFetching } = useQuery(
    [QUERIES.INVENTORY_LOG_LISTING, logsData, activeTab],
    () => request(activeTab),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: () => {},
      onSettled: (res: any) => {
        if (res?.data && Array.isArray(res?.data)) {
          let updatedData = updateList(res.data);
          setListData(updatedData);
        } else setListData([]);
      },
    }
  );

  const isDataFetched = useMemo(
    () => Boolean(listData?.length === 0 && !isLoading && !isFetching),
    [isFetching, isLoading, listData?.length]
  );

  useEffect(() => {
    if (isDisplay) display(true);
  }, [isDisplay, props]);

  // close pop up when we have
  useEffect(() => {
    if (!isDisplay && logsData) {
      window.addEventListener("click", function (e: any) {
        const logDetails = document.getElementById("logDetails") as HTMLElement;
        const icon = document.getElementById(
          `logs-tooltip${logsData.eventId}-${logsData?.id}`
        ) as HTMLElement;

        if (
          !logDetails?.contains(e?.target) &&
          icon &&
          !icon?.contains(e?.target)
        )
          setLogsData(null);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsData, isDisplay]);

  return (
    <div
      className={`show offcanvas fill_canvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  w-full upload-ticket-popup z-40 ${
        isDisplay ? "md:max-w-[43.125rem]" : ""
      }`}
    >
      <div
        className={`h-full    ${
          isDisplay ? "" : "md:max-w-[43.125rem]"
        }  ml-auto  `}
      >
        <div
          className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col s_ticket"
          id="logDetails"
        >
          <div className="offcanvas-header flex items-center justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal font-semibold py-2 px-5 text-sm15"
              id="offcanvasRightLabel"
            >
              Log details {logsData?.order_id && `#${logsData?.order_id}`}
            </h5>

            <button
              type="button"
              className="flex items-center justify-center h-10 w-10 border-l box-content group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                if (isDisplay) display(false);
                setLogsData(null);
              }}
            >
              <KTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path="standard_icons/cross.svg"
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>

          {isLoading || isFetching ? (
            <PopUpShimmer />
          ) : (
            <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:p-[1rem] p-5 mb-1 text-gray-600">
              {isDataFetched && !isDisplay ? (
                <NoDataAvailable />
              ) : (
                <Fragment>
                  {isDisplay && (
                    <div className="flex justify-around tab-panel bg-no-repeat bg-tradepattern bg-cover bg-indigo-500 bg-opacity-[15%] p-5 pb-0  after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
                      {LOG_TABS.map((log) => (
                        <Tab
                          key={log.key}
                          title={log.title}
                          isActiveTab={activeTab === log.key}
                          tabKey={log.key}
                          handleTab={(key: any) => {
                            if (activeTab !== key) {
                              setListData([]); // reset data first
                              setActiveTab(log.key);
                            }
                          }}
                        />
                      ))}
                    </div>
                  )}

                  <div className={`${isDisplay ? "mt-5" : ""}`}>
                    {isDataFetched && isDisplay ? (
                      <NoDataAvailable />
                    ) : (
                      Array.isArray(listData) &&
                      listData?.map((listItem: any, index: number) => (
                        <ExpandableList
                          key={index}
                          listItem={listItem}
                          index={index}
                        />
                      ))
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
