import React from "react";
import question from "../../../../../assets/media/icons/other_icons/que_color.svg";
import TooltipPortal from "../../../../../pages/tx_trade/table/component/TooltipPortal";
import { IKTSVG } from "../../../../ui/IKTSVG";
import Astrick from "./Astrick";

interface FormSubHeaderInterface {
  title: string;
  error?: any;
  tooltipRequire?: boolean;
  tooltipID?: any;
  tooltipContent?: string;
}

const FormSubHeader: React.FC<FormSubHeaderInterface> = ({
  title,
  error,
  tooltipRequire = true,
  tooltipID,
  tooltipContent,
}: any) => {
  return (
    <div className="title flex flex-wrap justify-between items-center mb-2.5 md:mb-4">
      <h4 className="text-sm15 font-basier_med leading-5">
        {title}
        <Astrick error={error} />
      </h4>
      {tooltipRequire && tooltipContent && (
        <div className={` `} data-tooltip-id={tooltipID}>
          <TooltipPortal
            id={tooltipID}
            place={"right"}
            content={tooltipContent}
            text13={true}
            className=" !bg-white !opacity-100 shadow !px-3 !py-3 font-basier !text-sm13 z-10 max-w-[18.75rem] md:max-w-[27.5rem] cursor-default"
          />
          <IKTSVG
            path={question}
            className="w-5 h-5 flex items-center justify-center transition-all fill-gray-400 hover:fill-indigo-500"
            svgClassName="w-5 h-5"
          />
        </div>
      )}
    </div>
  );
};

export default FormSubHeader;
