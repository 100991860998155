import { createContext, PropsWithChildren, useState } from "react";
import { ICustomizeContext } from "./Interface";
import {
  initialContentState,
  initialIsPopupActiveState,
  initialRecordFilters,
  initialStateForHomeDesign,
  QueryState,
} from "./_.model";
import { CUSTOMIZE_TAB_KEYS } from "./constants/constants";

export const CustomizeContext = createContext<ICustomizeContext>({
  // GENERAL
  isTabActive: {},
  setTabActive: (data: any) => {},

  isLoading: false,
  setIsLoading: (data: any) => {},

  orderState: {},
  setOrderState: (data: any) => {},

  isPopupActive: {},
  setIsPopupActive: (data: any) => {},

  isDelete: {},
  setIsDelete: (data: any) => {},

  // design menu data
  records: [],
  setRecords: (data: any) => {},

  recordFilter: {},
  setRecordFilter: (data: any) => {},

  isRecordEdit: null,
  setIsRecordEdit: (data: any) => {},

  typeComponent: null,
  setTypeComponent: (data: any) => {},

  contentFilter: {},
  setContentFilter: (data: any) => {},

  contentList: [],
  setContentList: (data: any) => {},

  state: [],
  setState: (data: any) => {},

  contentCount: {},
  setContentCount: (data: any) => {},

  // inputChange: "",
  // setInputChange: (data: any) => {},
});

export const CustomizeProvider = ({ children }: PropsWithChildren) => {
  // GENERAL
  const [isTabActive, setTabActive] = useState<string>(
    CUSTOMIZE_TAB_KEYS.DESIGN_MENU
  );

  const [contentCount, setContentCount] = useState<any>({ total: 0 });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [orderState, setOrderState] = useState<QueryState>({});

  const [isPopupActive, setIsPopupActive] = useState<any>(
    initialIsPopupActiveState
  );

  const [isDelete, setIsDelete] = useState<any>({
    status: false,
    data: undefined,
  });

  const [typeComponent, setTypeComponent] = useState({ name: "Event" });

  const [records, setRecords] = useState([]);
  const [isRecordEdit, setIsRecordEdit] = useState(null);

  // Design Menu STATE
  const [recordFilter, setRecordFilter] = useState(initialRecordFilters);

  // CONTENT STATE
  const [contentFilter, setContentFilter] = useState(initialContentState);
  const [contentList, setContentList] = useState([]);

  // HOME DESIGN
  const [state, setState] = useState<any>([initialStateForHomeDesign]);

  // const [inputChange, setInputChange] = useState("");

  // CONTEXT VALUE
  const value: ICustomizeContext = {
    isTabActive,
    setTabActive,
    isLoading,
    setIsLoading,
    orderState,
    setOrderState,
    isPopupActive,
    setIsPopupActive,
    isDelete,
    setIsDelete,

    typeComponent,
    setTypeComponent,

    // design menu data
    records,
    setRecords,
    recordFilter,
    setRecordFilter,
    isRecordEdit,
    setIsRecordEdit,

    // CONTENT
    contentFilter,
    setContentFilter,
    contentList,
    setContentList,

    // HOME DESIGN
    state,
    setState,

    contentCount,
    setContentCount,
    // inputChange,
    // setInputChange,
  };

  return (
    <CustomizeContext.Provider value={value}>
      {children}
    </CustomizeContext.Provider>
  );
};
