import { LayoutContext } from "components/core/LayoutProvider";
import HeaderCell from "pages/subscribe/personal_details/table/component/HeaderCell";
import { useContext } from "react";

const TableHeaderRow = () => {
  const globalLayout = useContext(LayoutContext);

  return (
    <tr className="border-b  bg-white ">
      <HeaderCell
        title={"Name"}
        width="w-[12rem] min-w-[12rem]"
        arrow={false}
        postKey="name"
        padding="!pl-0"
      />

      <HeaderCell
        title={"Email"}
        width="w-[15rem] min-w-[15rem]"
        arrow={false}
        postKey="name"
        padding="!pl-0"
      />

      <th className="md:sticky relative right-0 ml-auto  p-0 md:w-[4rem] md:max-w-[4rem] md:min-w-[4rem] w-[4rem] max-w-[4rem] min-w-[4rem]"></th>
      {globalLayout?.isMobile && (
        <th className="sticky right-0 ml-auto  p-0 w-10 min-w-[2.5rem]">
          <div
            className="py-[.4375rem] px-4 flex justify-end h-[2.5rem]  bg-white max-md:pointer-events-none"
            id=""
          ></div>
        </th>
      )}
    </tr>
  );
};

export default TableHeaderRow;
