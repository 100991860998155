/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import DropDownField from "../../components/formComponent/DropDownField";
import { KTSVG } from "../../components/ui/KTSVG";
import { AddInventoryContext } from "../add_inventory/core/AddInventoryProvider";
import { AddInventorySearchingValues } from "../add_inventory/core/_models";

let marketPlaces = [
  {
    label: "Old Trafford, Manchester",
  },
  {
    value: "Emirates Old Trafford Cricket Ground, Manchester",
  },
];
let ticket_type = [
  {
    value: 1,
    label: "Paper",
  },
  {
    value: 2,
    label: "E-Ticket",
  },
  {
    value: 3,
    label: "Mobile Transfer",
  },
];
let split_type = [
  {
    value: 1,
    label: "No Preferences",
  },
  {
    value: 2,
    label: "All Together",
  },
  {
    value: 3,
    label: "Avoid Leaving One Ticket",
  },
];
let category = [
  {
    value: "balcony",
    label: "Balcony",
  },
  {
    value: "floor",
    label: "Floor",
  },
  {
    value: "longside-upper",
    label: "Longside Upper",
  },
  {
    value: "longside-lower",
    label: "Longside Lower",
  },
  {
    value: "seating",
    label: "Seating",
  },
];
let section = [
  {
    value: "bs-b1",
    label: "Balcony Seating - Block 1",
  },
  {
    value: "bs-b2",
    label: "Balcony Seating - Block 2",
  },
  {
    value: "fs-b1",
    label: "Floor Seating - Block 1",
  },
  {
    value: "fs-b2",
    label: "Floor Seating - Block 2",
  },
  {
    value: "ts-b1",
    label: "Tier Seating - Block 1",
  },
  {
    value: "ts-b2",
    label: "Tier Seating - Block 2",
  },
];
let benefits = [
  {
    value: "Aisle",
    label: "Aisle",
  },
  {
    value: "Centre Aisle",
    label: "Centre Aisle",
  },
  {
    value: "VIP Parking Pass",
    label: "VIP Parking Pass",
  },
  {
    value: "Full Suite (Not Shared)",
    label: "Full Suite (Not Shared)",
  },
  {
    value: "12-person suite",
    label: "12-person suite",
  },
  {
    value: "14-person suite",
    label: "14-person suite",
  },
  {
    value: "16-person suite",
    label: "16-person suite",
  },
  {
    value: "18-person suite",
    label: "18-person suite",
  },
  {
    value: "20-person suite",
    label: "20-person suite",
  },
  {
    value: "24-person suite",
    label: "24-person suite",
  },
  {
    value: "Full Suite - Includes 8 fixed seats and 4 bar stools",
    label: "Full Suite - Includes 8 fixed seats and 4 bar stools",
  },
  {
    value: "Includes unlimited food and soft drinks",
    label: "Includes unlimited food and soft drinks",
  },
  {
    value: "Includes limited alcoholic beverages - beer, wine and liquor",
    label: "Includes limited alcoholic beverages - beer, wine and liquor",
  },
  {
    value: "Includes unlimited food and drinks (beer and soft drinks)",
    label: "Includes unlimited food and drinks (beer and soft drinks)",
  },
  {
    value: "Actual Third Row Over Visitor Dugout",
    label: "Actual Third Row Over Visitor Dugout",
  },
  {
    value: "Actual Third Row Over Visitor Dugout",
    label: "Actual Third Row Over Visitor Dugout",
  },
  {
    value: "Tunnel Club access - Includes pregame food and beverage",
    label: "Tunnel Club access - Includes pregame food and beverage",
  },
  {
    value: "Under Overhang",
    label: "Under Overhang",
  },
  {
    value: "Actual Second Row Over Home Bullpen",
    label: "Actual Second Row Over Home Bullpen",
  },
  {
    value: "In-Seat Wait Service",
    label: "In-Seat Wait Service",
  },
  {
    value: "Includes unlimited food and drinks (beer, wine and liquor)",
    label: "Includes unlimited food and drinks (beer, wine and liquor)",
  },
  {
    value: "Actual Third Row Over Home Dugout",
    label: "Actual Third Row Over Home Dugout",
  },
  {
    value: "Food and drink voucher included",
    label: "Food and drink voucher included",
  },
  {
    value: "Actual 4th row of section",
    label: "Actual 4th row of section",
  },
];
let restrictions = [
  {
    value: "Aisle",
    label: "Aisle",
  },
  {
    value: "Centre Aisle",
    label: "Centre Aisle",
  },
  {
    value: "VIP Parking Pass",
    label: "VIP Parking Pass",
  },
  {
    value: "Full Suite (Not Shared)",
    label: "Full Suite (Not Shared)",
  },
  {
    value: "12-person suite",
    label: "12-person suite",
  },
  {
    value: "14-person suite",
    label: "14-person suite",
  },
  {
    value: "16-person suite",
    label: "16-person suite",
  },
  {
    value: "18-person suite",
    label: "18-person suite",
  },
  {
    value: "20-person suite",
    label: "20-person suite",
  },
  {
    value: "24-person suite",
    label: "24-person suite",
  },
  {
    value: "Full Suite - Includes 8 fixed seats and 4 bar stools",
    label: "Full Suite - Includes 8 fixed seats and 4 bar stools",
  },
  {
    value: "Includes unlimited food and soft drinks",
    label: "Includes unlimited food and soft drinks",
  },
  {
    value: "Includes limited alcoholic beverages - beer, wine and liquor",
    label: "Includes limited alcoholic beverages - beer, wine and liquor",
  },
  {
    value: "Includes unlimited food and drinks (beer and soft drinks)",
    label: "Includes unlimited food and drinks (beer and soft drinks)",
  },
  {
    value: "Actual Third Row Over Visitor Dugout",
    label: "Actual Third Row Over Visitor Dugout",
  },
  {
    value: "Actual Third Row Over Visitor Dugout",
    label: "Actual Third Row Over Visitor Dugout",
  },
  {
    value: "Tunnel Club access - Includes pregame food and beverage",
    label: "Tunnel Club access - Includes pregame food and beverage",
  },
  {
    value: "Under Overhang",
    label: "Under Overhang",
  },
  {
    value: "Actual Second Row Over Home Bullpen",
    label: "Actual Second Row Over Home Bullpen",
  },
  {
    value: "In-Seat Wait Service",
    label: "In-Seat Wait Service",
  },
  {
    value: "Includes unlimited food and drinks (beer, wine and liquor)",
    label: "Includes unlimited food and drinks (beer, wine and liquor)",
  },
  {
    value: "Actual Third Row Over Home Dugout",
    label: "Actual Third Row Over Home Dugout",
  },
  {
    value: "Food and drink voucher included",
    label: "Food and drink voucher included",
  },
  {
    value: "Actual 4th row of section",
    label: "Actual 4th row of section",
  },
];

const PerformerVenue = () => {
  const [triggered, setTriggered] = useState<boolean>(false);
  const layout = useContext(AddInventoryContext);
  const events = layout.events;
  const [PPE, setPPE] = useState(Boolean);
  const [tableAlert, setTableAlert] = useState(false);

  const [searchedValue, setSearchedValue] =
    useState<AddInventorySearchingValues>();

  const [keyboardField, setKeyboardField] = useState<any>({
    value: "",
    label: "",
    date: "",
    time: "",
    place: "",
    eventRecords: [
      {
        marketplaces: {},
        ticketType: {},
        selected: false,
        splitType: {},
        category: {},
        section: {},
        row: "",
        firstRow: "",
        firstSeat: {},
        faceValue: {},
        proceedValue: {},
        ppe: "",
        ticketsInHands: {},
        dateField: {},
        benefits: {},
        restrictions: {},
      },
    ],
  });

  useEffect(() => {
    // layout.setMarketPlaces(marketPlaces);
    // layout.setTicket_type(ticket_type);
    // layout.setSplit_type(split_type);
    // layout.setCategory(category);
    // layout.setSection(section);
    layout.setBenefits(benefits);
    layout.setRestrictions(restrictions);
  }, []);

  // Events Onclick SearchField
  const handleSearchClick = (e: any, ref: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (ref.current !== undefined) {
      if (e.target.id === "Rectangle_15") {
        ref.current.clearValue();
      }
    }
  };

  const handleEventSelect = (data: any) => {
    setKeyboardField({
      ...data,
      eventRecords: [...keyboardField["eventRecords"]],
    });
  };

  const handleKeyboardChange = (data: any) => {
    setKeyboardField({
      ...keyboardField,
      eventRecords: [
        {
          ...keyboardField["eventRecords"][0],
          [data.target.name]:
            data.target.value !== undefined && Array.isArray(data.target.value)
              ? [data.target.value]
              : typeof data.target.value === "object"
              ? data.target.value
              : typeof data.target.value === "string"
              ? data.target.value
              : data.target.checked,
        },
      ],
    });
  };

  const handleTableField = (data: any, eIndex: number, rIndex: number) => {
    let tempEvents = [...events];
    tempEvents[eIndex]["eventRecords"][rIndex][data.target.name] =
      data.target.value !== undefined && Array.isArray(data.target.value)
        ? [data.target.value]
        : typeof data.target.value === "object"
        ? data.target.value
        : typeof data.target.value === "string" &&
          data.target.type !== "checkbox"
        ? data.target.value
        : data.target.type === "checkbox"
        ? data.target.checked
        : data.target.value;

    layout.setEvents(tempEvents);
  };

  return (
    <div className="px-5">
      <div className="accordion mt-4 font-medium" id="accordionExample">
        <div className="bg-white border rounded-lg border-gray-200 shadow-md">
          <div className="flex accordion-header mb-0" id="headingOne">
            <div className="pl-2 pr-5 py-2">
              <select
                name=""
                id=""
                className="sm:mr-2 !pr-4 py-0 text-sm13 font-medium border-none focus:ring-0"
              >
                <option value="">Keyboard</option>
                <option value="">Keyboard1</option>
                <option value="">Keyboard2</option>
                <option value="">Keyboard3</option>
              </select>
              <select
                name=""
                id=""
                className="!pr-4 py-0 text-sm13 font-medium border-none focus:ring-0"
              >
                <option value="">Columns</option>
                <option value="">Columns1</option>
                <option value="">Columns2</option>
              </select>
            </div>
            <button
              className="relative flex items-center py-4 px-5 text-base text-gray-800 text-left bg-white border-l rounded-tr-lg transition focus:outline-none group  ml-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <KTSVG
                className="flex items-center justify-center group-[.collapsed] :rotate-180  fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/Collapse.svg"
              />
            </button>
          </div>
          <div>
            <div className="relative">
              <div
                className={`px-5 py-2 border-t flex flex-wrap items-center -mx-[0.313rem]`}
              >
                <div
                  className={`w-full md:w-3/4 lg:w-1/2 xl:w-1/3 px-[0.313rem] my-2`}
                >
                  {/* <SearchField
                    placeholder="Search performer or venue"
                    name="upcomingEvents"
                    classNamePrefix="inventory_select"
                    handleOnChange={(data: any) => {
                      setTriggered(true);
                      handleEventSelect(data);
                    }}
                    onClick={(data: any, ref: any) =>
                      handleSearchClick(data, ref)
                    }
                  /> */}
                </div>
                {triggered && (
                  <div className="w-full sm:w-1/2 lg:w-1/4 xl:w-[12.5%] px-[0.313rem] my-2">
                    <DropDownField
                      options={ticket_type}
                      placeholder="Timeframe"
                      name="ticketType"
                      handleOnChange={(data: any) => handleKeyboardChange(data)}
                    />
                  </div>
                )}
              </div>
              {/* Keyboard Fields */}
              {triggered && (
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body border-t">
                    <div className="flex flex-wrap">
                      <div className="p-5 font-semibold text-sm13 border-r border-b text-center w-full sm:w-auto">
                        9 results
                      </div>
                      <div className="flex items-center px-5 py-3 border-r gap-x-5 flex-auto border-b">
                        <div className="flex py-1 px-2.5 text-xs border rounded-full">
                          Next 90 days
                          <button className="ml-2">
                            <KTSVG
                              path="standard_icons/cross.svg"
                              svgClassName="h-2 w-2"
                            />
                          </button>
                        </div>
                        <button
                          type="button"
                          className="group border text-center bg-white rounded-sm w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition"
                        >
                          <KTSVG
                            className="fill-violet-500 group-hover:fill-white transition"
                            path="other_icons/refresh.svg"
                            svgClassName="ml-[.313rem]"
                          />
                        </button>
                      </div>
                      <div className="flex items-center px-5 py-3 border-b gap-x-5 w-full lg:w-auto">
                        <div className="flex items-center text-xs gap-x-2">
                          <span>View</span>
                          <select
                            name=""
                            id=""
                            className="border-gray-300 focus:border-indigo-500 rounded py-1 pl-2 text-xs"
                          >
                            <option value="">20</option>
                            <option value="">40</option>
                          </select>
                        </div>
                        <div className="flex items-center text-xs gap-x-2">
                          <span>Page</span>
                          <span className="w-6 h-6 text-center border border-gray-300 rounded p-1 text-xs">
                            1
                          </span>
                          <span>of 1</span>
                        </div>
                      </div>
                    </div>
                    <div className="text-xs overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                      <table className="w-full text-left min-w-[70rem]">
                        <tbody>
                          <tr>
                            <td className="flex items-center justify-center border-r border-b  h-[2.5rem] p-1 text-center">
                              <input
                                type="checkbox"
                                className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0"
                              />
                            </td>
                            <td
                              className="py-2.5 px-3.5 w-full border-b"
                              colSpan={4}
                            >
                              9 events selected
                            </td>
                          </tr>
                          <tr>
                            <td className="border-r border-b h-[2.5rem] p-3">
                              <input
                                type="checkbox"
                                className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0"
                              />
                            </td>
                            <td className="py-2.5 px-3.5 border-r border-b w-[19.5rem]">
                              Manchester United vs Liverpool
                            </td>
                            <td className="py-2.5 px-3.5 border-r border-b w-[10rem]">
                              <div className=" inline-flex items-center whitespace-nowrap">
                                <KTSVG
                                  className=" fill-violet-800 hover:fill-indigo-800 transition"
                                  path="standard_icons/calendar.svg"
                                />
                                <span className="ml-2">Wed, 1 Jun 2022</span>
                              </div>
                            </td>
                            <td className="py-2.5 px-3.5 border-r border-b w-[5.3rem] whitespace-nowrap">
                              <div className=" inline-flex items-center text-center">
                                <KTSVG
                                  className=" fill-violet-800 hover:fill-indigo-800 transition"
                                  path="standard_icons/clock-outline.svg"
                                />
                                <span className="ml-2">12:00</span>
                              </div>
                            </td>
                            <td className="py-2.5 px-3.5  border-b w-[37.5rem]">
                              <div className=" inline-flex items-center">
                                <KTSVG
                                  className=" fill-violet-800 hover:fill-indigo-800 transition"
                                  path="standard_icons/geo_icon.svg"
                                />
                                <span className="ml-2">
                                  Old Trafford, Manchester
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="border-r border-b h-[2.5rem] p-3">
                              <input
                                type="checkbox"
                                className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0"
                              />
                            </td>
                            <td className="py-2.5 px-3.5 border-r border-b w-[19.5rem]">
                              Manchester United vs Liverpool
                            </td>
                            <td className="py-2.5 px-3.5 border-r border-b w-[10rem]">
                              <div className=" inline-flex items-center whitespace-nowrap">
                                <KTSVG
                                  className=" fill-violet-800 hover:fill-indigo-800 transition"
                                  path="standard_icons/calendar.svg"
                                />
                                <span className="ml-2">Wed, 1 Jun 2022</span>
                              </div>
                            </td>
                            <td className="py-2.5 px-3.5 border-r border-b w-[5.3rem] whitespace-nowrap">
                              <div className=" inline-flex items-center text-center">
                                <KTSVG
                                  className=" fill-violet-800 hover:fill-indigo-800 transition"
                                  path="standard_icons/clock-outline.svg"
                                />
                                <span className="ml-2">12:00</span>
                              </div>
                            </td>
                            <td className="py-2.5 px-3.5  border-b w-[37.5rem]">
                              <div className=" inline-flex items-center">
                                <KTSVG
                                  className=" fill-violet-800 hover:fill-indigo-800 transition"
                                  path="standard_icons/geo_icon.svg"
                                />
                                <span className="ml-2">
                                  Old Trafford, Manchester
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="flex flex-wrap items-center justify-end p-4">
                    <div className="ml-6">
                      <button
                        type="button"
                        className="inline-flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase"
                      >
                        <KTSVG
                          className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-white"
                          path="other_icons/plus.svg"
                        />
                        ADD EVENTS
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformerVenue;
