import ComponentSVG from "../../../../components/ui/typography/ComponentSVG";
import { addCommaInNumber } from "../../../../helpers/Functions";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";

type TEventCount = {
  dataTooltipID: string;
  tooltipContent: string;
  SVGParentClassName: string;
  SVGClassName: string;
  SVGImageComponent: React.ElementType;
  data: string | number;
};

const EventCount = ({
  dataTooltipID,
  tooltipContent,
  SVGParentClassName,
  SVGClassName,
  SVGImageComponent,
  data,
}: TEventCount) => {
  return (
    <div
      data-tooltip-id={dataTooltipID}
      className="flex items-center md:mr-3 text-xs text-white"
    >
      <TooltipPortal
        id={dataTooltipID}
        content={tooltipContent}
        place="top"
        variant="light"
        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[12] mt-2.5"
      />
      {/* SVG COMPONENT */}
      <ComponentSVG className={SVGParentClassName}>
        <SVGImageComponent className={SVGClassName} />
      </ComponentSVG>{" "}
      {/* COUNT */}
      {addCommaInNumber(data)}
    </div>
  );
};

export default EventCount;
