import _, { isArray } from "lodash";
import moment from "moment";
import axios from "../../../axois/SetupAxios";

// NOTIFICATIONS LISTING
const getNotificationListing = (data: any): Promise<any> => {
  let activities: any = [];
  let teammembers = data?.filters?.specific
    ? [data?.filters?.specific]
    : data?.activityFilters?.specific
    ? [data?.activityFilters?.specific]
    : data?.tabValue === "notification"
    ? _.map(data?.filters?.teammembers, "id")
    : _.map(data?.activityFilters?.teammembers, "id");
  // let teammembers = (data?.tabValue === 'notification' ? (_.map(data?.filters?.teammembers, 'id')) : (_.map(data?.activityFilters?.teammembers, 'id')))
  let page =
    data?.tabValue === "notification"
      ? data?.filters?.page
      : data?.tabValue === "activity"
      ? data?.activityFilters?.page
      : data?.page;
  let per_page =
    data?.tabValue === "notification"
      ? data?.filters?.per_page
      : data?.activityFilters?.per_page;
  let start_date;
  let end_date;
  if (
    data?.dateRange &&
    data?.dateRange?.target &&
    data?.dateRange?.target?.value
  ) {
    start_date = data?.dateRange?.target?.value?.startDate;
    end_date = data?.dateRange?.target?.value?.endDate;
  }
  let notification_type = _.keys(_.pickBy(data?.filters?.notification_type));
  let activity_type = _.keys(_.pickBy(data?.activityFilters?.activity_type));
  if (_.map(data?.filters?.order_exchanges, "id")) {
    activities = _.map(data?.activityFilters?.activities, "id");
  }

  const teamValue = data?.team_id
    ? _.map(data?.team_id, "id")
    : data?.filters?.team_id
    ? _.map(data?.filters?.team_id, "id")
    : _.map(data?.activityFilters?.team_id, "id");

  let payload = {
    start_date,
    end_date,
    ...(data?.team_member && { team_member: data?.team_member }),
    ...(teammembers && teammembers.length > 0 && { team_member: teammembers }),
    ...(start_date && { start_date: moment(start_date).format("YYYY-MM-DD") }),
    ...(end_date && { end_date: moment(end_date).format("YYYY-MM-DD") }),
    ...(data?.tabValue
      ? { tab_name: data?.tabValue }
      : { tab_name: "notification" }),
    ...(notification_type &&
    notification_type.length > 0 &&
    data?.tabValue === "notification"
      ? { new_notifications: 1 }
      : Array.isArray(activity_type) && activity_type.length > 0
      ? {
          activities: activity_type,
          new_activities:
            data?.activityFilters?.new_activities === 1 ? 1 : undefined,
        }
      : {
          new_activities:
            data?.activityFilters?.new_activities === 1 ? 1 : undefined,
        }),
    ...(activities && activities?.length > 0 && { activities: activities }),
    ...data.pagination,
    ...(page && { page }),
    ...(data?.page && { page: data?.page }),
    ...(per_page && { per_page }),
    ...(data?.debouncedSearchNotifications && {
      keywords: data?.debouncedSearchNotifications,
    }),
    ...(data?.debouncedSearchActivity && {
      keywords: data?.debouncedSearchActivity,
    }),
    ...(_.size(teamValue) > 0 && {
      team_id: teamValue,
    }),
  };
  return axios.post(`notifications/listing`, payload).then((response) => {
    return response.data;
  });
};

// NOTIFICATIONS OPTIONS
const getNotificationOptions = (): Promise<any> => {
  return axios.get(`notifications/listing`).then((response) => response.data);
};

// MARK NOTIFICATIONS AS READ
const getMarkAsRead = (data: any): Promise<any> => {
  let payload = {
    action: "is_new",
    // id: [7,8]
    ...(isArray(data) ? { id: data } : { is_new_all: data }),
  };
  return axios
    .post(`notifications/mark-as-read`, payload)
    .then((response) => response.data);
};

// MARK NOTIFICATIONS AS PINNED
const getMarkAsPinned = (data: any): Promise<any> => {
  let payload = {
    action: "is_pinned",
    is_pinned: 1,
    id: data,
  };
  return axios
    .post(`notifications/mark-as-read`, payload)
    .then((response) => response.data);
};

// MARK NOTIFICATIONS AS PINNED
const getMarkAsUnpinned = (data: any): Promise<any> => {
  let payload = {
    action: "is_pinned",
    is_pinned: 0,
    id: data,
  };
  return axios
    .post(`notifications/mark-as-read`, payload)
    .then((response) => response.data);
};

export {
  getMarkAsPinned,
  getMarkAsRead,
  getMarkAsUnpinned,
  getNotificationListing,
  getNotificationOptions,
};
