import { CrossIcon } from "assets/media/icons/other_icons/CrossIcon";
import { EditSquareIcon } from "assets/media/icons/other_icons/EditSquareIcon";
import Textfield from "components/formComponent/Textfield";
import { DeleteIconButton } from "components/ui/buttons/DeleteIconButton/DeleteIconButton";
import AlgoliaAll from "pages/customize/component/UI/AlgoliaAll";
import { INITIAL_TYPES } from "pages/customize/core/constants/constants";
import React from "react";

export const AlgoliaWithDeleteButton = (props: any) => {
  const {
    id,
    item,
    handleHitSelect,
    deleteItem,
    toggleEdit = null,
    onChange = () => {},
    children,
  } = props;

  return (
    <React.Fragment>
      {item?.isEditable ? (
        <Textfield
          type="text"
          value={item?.name ?? ""}
          handleOnChange={onChange}
        />
      ) : (
        <AlgoliaAll
          index={id}
          inputValue={item?.name}
          value={item}
          handleHitSelect={handleHitSelect}
          type={INITIAL_TYPES[item?.type] ?? item?.type}
          width={"full"}
        />
      )}

      <button
        className="inline-flex group items-center text-violet-800"
        onClick={(e) => {
          e.preventDefault();
          toggleEdit && toggleEdit();
        }}
      >
        <span className="min-w-4 max-w-4 flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500  transition">
          {item?.isEditable ? (
            <CrossIcon width="14" height="14" />
          ) : (
            <EditSquareIcon width="14" height="14" />
          )}
        </span>
      </button>

      <DeleteIconButton id={id} onClick={deleteItem} />

      {children}
    </React.Fragment>
  );
};
