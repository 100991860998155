import { createContext, PropsWithChildren, useContext, useState } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import {
  REPORT_COLUMN_ITEMS,
  REPORT_FILTER_ITEMS,
} from "../../../helpers/const";
import { QueryState } from "../../inventory/core/_models";

export interface LayoutContextModel {
  orders: Array<any>;
  setOrders: (data: any) => void;

  events: Array<any>;
  setEvents: (data: any) => void;

  orderState: any;
  setOrderState: (data: any) => void;

  reportsFilterHandle: any;
  setReportsFilterHandle: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  draggedColumns: Array<any>;
  setDraggedColumns: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  orderDateRange: any;
  setOrderDateRange: (data: any) => void;

  eventDateRange: any;
  setEventDateRange: (data: any) => void;
}

const ReportsContext = createContext<LayoutContextModel>({
  orders: [],
  setOrders: (data: any) => {},

  events: [],
  setEvents: (data: any) => {},

  orderState: [],
  setOrderState: (data: any) => {},

  reportsFilterHandle: {},
  setReportsFilterHandle: (data: any) => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  draggedColumns: [],
  setDraggedColumns: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  orderDateRange: [],
  setOrderDateRange: (data: any) => {},

  eventDateRange: [],
  setEventDateRange: (data: any) => {},
});

const ReportsProvider = ({ children }: PropsWithChildren) => {
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  const access = globalLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveDraggableFilterData;

  let items = getHeaderFilter(
    "saveDraggableFilterData",
    isFilterExistInDB,
    REPORT_FILTER_ITEMS
  );
  if (isGodAdmin) {
  } else if (userSpecific) {
    items = items?.filter(
      (item: any) =>
        item.name !== "Team members" &&
        item.name !== "All teams" &&
        item.name !== "Internal replacement status" &&
        item.name !== "Stores" &&
        item?.name !== "Internal order status" &&
        item?.name !== "Include webstores"
    );
  } else {
    items = items?.filter(
      (item: any) =>
        item.name !== "All teams" &&
        item.name !== "Marketplaces" &&
        item.name !== "Internal replacement status" &&
        item.name !== "Stores" &&
        item?.name !== "Internal order status" &&
        item?.name !== "Include webstores"
    );
  }

  // COLUMN FILTER - CONDITION
  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.reportTableColumn;

  let columnsItem = getHeaderFilter(
    "reportTableColumn",
    isColumnFilterExistInDB,
    REPORT_COLUMN_ITEMS
  );

  if (isGodAdmin) {
  } else {
    columnsItem = columnsItem
      ?.filter(
        (obj: any) =>
          obj?.name !== "Team name" &&
          obj?.name !== "Team member" &&
          obj.name !== "Marketplace value" &&
          obj.name !== "Settlement/net payout value" &&
          obj?.name !== "Marketplace" &&
          obj?.name !== "Marketplace ID" &&
          obj?.name !== "Tixstock revenue" &&
          obj?.name !== "Internal replacement status" &&
          obj?.name !== "Internal order status" &&
          obj?.name !== "Include webstores" &&
          obj?.name !== "Buyer fees"
      )
      ?.map((item: any) => ({
        ...item,
        name: item?.name === "Listing value" ? "Order value" : item?.name,
      }));
  }

  const [orders, setOrders] = useState([]);
  const [events, setEvents] = useState([]);
  const [orderState, setOrderState] = useState<QueryState>({});
  const [reportsFilterHandle, setReportsFilterHandle] = useState<any>({
    order_exchanges: [],
    category_ids: null,
    per_page: 20,
  });
  const [draggedColumns, setDraggedColumns] = useState([]);
  const [filterItems, setFilterItems] = useState(() => {
    return items;
  });
  const [draggedItems, setDraggedItems] = useState(filterItems);

  const [columnItems, setColumnItems] = useState(() => {
    return columnsItem;
  });

  const [orderDateRange, setOrderDateRange] = useState<any>({});
  const [eventDateRange, setEventDateRange] = useState<any>({});

  const value: LayoutContextModel = {
    orders,
    setOrders,

    events,
    setEvents,

    orderState,
    setOrderState,

    reportsFilterHandle,
    setReportsFilterHandle,

    filterItems,
    setFilterItems,

    draggedItems,
    setDraggedItems,

    columnItems,
    setColumnItems,

    draggedColumns,
    setDraggedColumns,

    orderDateRange,
    setOrderDateRange,

    eventDateRange,
    setEventDateRange,
  };

  return (
    <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>
  );
};

export { ReportsContext, ReportsProvider };
