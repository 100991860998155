import { IKTSVG } from "../IKTSVG";

interface TabInterface {
  imgPath?: any;
  title?: string;
  handleTab?: any;
  isActiveTab?: Boolean;
  tabKey?: any;
  className?: any;
}

export const Tab: React.FC<TabInterface> = ({
  imgPath,
  title,
  handleTab,
  isActiveTab = false,
  tabKey,
  className = "",
}) => {
  return (
    <>
      <div
        className={`${
          isActiveTab ? "bg-opacity-100 z-[1]" : "bg-opacity-60"
        } single-tab lg:px-4 px-2.5 py-[.5625rem]  min-w-[10.625rem lg:whitespace-normal whitespace-nowrap
        ] flex justify-between items-center bg-white rounded-t cursor-pointer mr-[1px] w-full gap-2 ${className}`}
        onClick={() => handleTab(tabKey)}
        key={tabKey}
      >
        <h4
          className={`lg:text-sm15 text-sm14 font-semibold ${
            isActiveTab && "text-indigo-500"
          }`}
        >
          {title}
        </h4>
        {imgPath && (
          <IKTSVG
            className={`123 flex items-center justify-center fill-${
              isActiveTab ? "indigo" : "violet"
            }-500 transition`}
            path={imgPath}
            svgClassName={"w-4 h-4"}
          />
        )}
      </div>
    </>
  );
};
