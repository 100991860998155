/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import imgpath from "../../../assets/media/icons/standard_icons/angle_left.svg";
import { KTSVG } from "../../../components/ui/KTSVG";
import { displayFAQ } from "../../tx_trade/helper/Assist";
import NavigationButtons from "../components/NavigationButtons";

const SideDetail = ({
  showFAQ,
  tableRef,
  handleClose,
  faqList,
  setShowFAQ,
  filteredFaq,
}: any) => {
  let ref = tableRef?.current?.style;
  const canvasRef: any = useRef<any>();
  let mainFaq = faqList?.[0]?.list || filteredFaq;

  useEffect(() => {
    if (showFAQ) {
      displayFAQ(showFAQ, ref);
    }
  }, [ref, showFAQ]);

  // HANDLE CANCEL BUTTON
  const handleCancel = () => {
    displayFAQ(false, ref);
    handleClose();
  };

  const [currentIndex, setCurrentIndex] = useState<any>();
  const currentData = mainFaq[currentIndex];

  const handleNavigationBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex: any) => prevIndex - 1);
    }
  };

  const handleNavigationNext = () => {
    if (currentIndex < mainFaq.length - 1) {
      setCurrentIndex((prevIndex: any) => prevIndex + 1);
    }
  };

  useEffect(() => {
    if (showFAQ?.index || showFAQ?.index === 0) {
      setCurrentIndex(showFAQ?.index);
    }
  }, [showFAQ?.index]);

  useEffect(() => {
    if (currentIndex || currentIndex === 0) {
      const newData = {
        show: true,
        data: currentData,
        index: currentIndex === 0 ? 0 : currentIndex,
      };

      setShowFAQ(newData);
    }
  }, [currentIndex]);

  return (
    <div
      className={`show faq_canvas offcanvas buy_canvas offcanvas-end  fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium lg:max-w-[43.125rem] md:max-w-[calc(100%-3.75rem)] w-full z-40 `}
      tabIndex={-1}
      id="FaqVideo"
      aria-labelledby="cc"
      data-bs-backdrop="false"
      ref={canvasRef}
    >
      <div className="relative flex flex-col h-full bg-white ml-auto">
        <div className="offcanvas-header flex justify-between border-t border-b">
          <h5
            className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold p-5 flex-1"
            id="offcanvasRightLabel"
          >
            {showFAQ?.data?.que}
          </h5>
          <button
            type="button"
            className="flex items-center justify-center w-9 border-l box-content group border-r lg:border-r-0"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => handleCancel()}
            //   onClick={() => display()}
          >
            <KTSVG
              className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
              path="standard_icons/cross.svg"
              svgClassName="w-2.5 h-2.5"
            />
          </button>
        </div>
        <div className="offcanvas-body max-h-full h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:p-[1.875rem] p-5">
          <p
            dangerouslySetInnerHTML={{
              __html: showFAQ?.data?.ans,
            }}
            className="text-[.8125rem] text-gray-600 [&>a:hover]:text-indigo-500"
          >
            {/* {showFAQ?.data?.ans} */}
          </p>
          <div className="md:flex-row flex flex-col justify-between md:items-center items-start max-w-[31.625rem] my-[1.875rem] -mx-2">
            {showFAQ?.data?.video && (
              <a
                href={showFAQ?.data?.video}
                className="flex items-center group hover:text-indigo-500 px-2 md:my-0 my-1 text-violet-500"
                target="_blank"
              >
                <KTSVG
                  className="fill-violet-800 group-hover:fill-indigo-500 h-4 w-5 mr-2.5 transition"
                  path="other_icons/youtube.svg"
                />
                Watch the video guide
              </a>
            )}
            {showFAQ?.data?.pdf && (
              <a
                href={showFAQ?.data?.pdf}
                className="flex items-center group hover:text-indigo-500 px-2 md:my-0 my-1 text-violet-500 text-sm14"
                target="_blank"
              >
                <KTSVG
                  className="fill-violet-800 group-hover:fill-indigo-500 h-4 w-5 mr-2.5 transition"
                  path="other_icons/pdf.svg"
                />
                Download PDF guide
              </a>
            )}
          </div>
          {showFAQ?.data?.video && (
            <div className="relative pt-[calc(360_/_630_*_100%)]">
              <div className="absolute top-0 left-0 w-full h-full bg-gray-200 rounded">
                <video controls>
                  <source src={showFAQ?.data?.video} />
                </video>
              </div>
            </div>
          )}
        </div>

        {/* NAVIGATION BUTTONS */}
        <div className=" w-full flex justify-end px-5 py-3 bg-white z-10 border-t min-h-[3.1875rem]">
          <div className="arrow_buttons flex shadow-op1 rounded ">
            {/* {/ BACK BUTTON /} */}
            <NavigationButtons
              id="back"
              imgPath={imgpath}
              handleNavigation={(data: any) => handleNavigationBack()}
              disabled={currentIndex === 0}
            />

            {/* {/ NEXT BUTTON /} */}
            <NavigationButtons
              id="next"
              imgPath={imgpath}
              handleNavigation={(data: any) => handleNavigationNext()}
              disabled={currentIndex === mainFaq?.length - 1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideDetail;
