import check from "../../../../../assets/media/icons/other_icons/check.svg";
import info from "../../../../../assets/media/icons/other_icons/info.svg";
import { IKTSVG } from "../../../../ui/IKTSVG";

const InfoList = ({
  item,
  index,
  isPopup = false,
  className = "px-2.5 flex flex-wrap w-full mb-2.5",
}: any) => {
  return (
    <div className={className}>
      <IKTSVG
        className={`${
          isPopup ? "w-4 h-4 fill-violet-800" : "w-5 h-5 fill-gray-500"
        } mr-2.5 flex items-center justify-center  bg-white rounded-full`}
        path={check}
        svgClassName="w-2"
      />
      <div className="flex-1 leading-tight">
        <h6
          className={`font-basier_med ${
            isPopup ? "text-sm13" : "text-sm14 md:text-sm15"
          }`}
        >
          {item?.title}
        </h6>
        {item?.subTitle && (
          <p
            className={`font-basier ${
              isPopup ? "text-sm12" : "text-sm12 md:text-sm14"
            } my-[.4375rem] last:mb-0`}
          >
            {item?.subTitle}
          </p>
        )}
        {item?.content && (
          <div className="flex flex-wrap w-full mb-[.4375rem]">
            <IKTSVG
              className="w-4 h-4 mr-1.5 flex items-center justify-center fill-gray-200 bg-white rounded-full [&_text]:fill-violet-800"
              path={info}
              svgClassName="w-4 h-4"
            />
            <div className="flex-1">
              <p
                className={`font-basier italic ${
                  isPopup ? "text-sm12" : "text-sm12 md:text-sm14"
                }`}
              >
                {item?.content}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoList;
