import { CUSTOMIZE_TAB_KEYS } from "pages/customize/core/constants/constants";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import { Fragment, useMemo, useState } from "react";
import ContentPage from "../ContentPage/ContentPage";
import { DesignMenuFilters } from "../DesignMenu/DesignMenuFilters/DesignMenuFilters";
import FilterChips from "./FilterChips";

const FilterPage = () => {
  const { isTabActive } = useCustomize();
  const [searchValue, setSearchValue] = useState("");

  const contentPage = useMemo(
    () => isTabActive === CUSTOMIZE_TAB_KEYS.CONTENT_PAGE,
    [isTabActive]
  );

  return (
    <Fragment>
      <div id="filterWrap">
        <div
          className={`${
            contentPage && "filtersMob"
          } max-md:flex max-md:flex-col`}
        >
          <div
            id="filters_block"
            className="flex flex-wrap border-b border-t gap-y-2.5 py-4 px-5 bg-white max-md:flex-1 max-md:overflow-auto max-md:content-start"
          >
            {contentPage ? (
              <ContentPage />
            ) : (
              <DesignMenuFilters
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            )}
          </div>
        </div>
        <FilterChips setSearchValue={setSearchValue} />
      </div>
    </Fragment>
  );
};

export default FilterPage;
