import Cookies from "js-cookie";
import { useEffect, useState } from "react";

export const LoadImages = (pathname: string) => {
  const [images, setImages] = useState<string>("");
  useEffect(() => {
    import(`../assets/media/icons/${pathname}`).then((image: any) => {
      setImages(image.default);
    });
  }, [pathname]);

  if (images && images !== undefined) {
    return images;
  }
};

export const base64TOURL = (file: any, fileType = "application/pdf") => {
  const bytes = new Uint8Array(
    atob(file)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  const blob = new Blob([bytes], { type: fileType });
  return window.URL.createObjectURL(blob);
};

export const base64ToPDF = (file: any, fileName: string) => {
  const bytes = new Uint8Array(
    atob(file)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  const blob = new Blob([bytes], { type: "application/pdf" });
  const a = document.createElement("a");
  a.download = fileName; // Set the file name here
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const getCookies = (data: any) => {
  let cookieData = Cookies.get(data) as any;
  if (cookieData) {
    cookieData = JSON.parse(cookieData);
  }
  return cookieData;
};

export const percentage = (partialValue: any, totalValue: any) => {
  return ((100 * partialValue) / totalValue).toFixed();
};

export const fnBrowserDetect = () => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
};
export function createObjectArrayWithKeys(values: any, skipKeys: any) {
  function formatEventDate(inputString: any) {
    let stringWithSpaces = inputString.replace(/_/g, " ");
    if (
      inputString.toLowerCase().includes("swift/bic") ||
      inputString.toLowerCase().includes("iban")
    ) {
      return stringWithSpaces.toUpperCase();
    }

    return stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
  }

  const result = Object.entries(values)
    ?.map((item) => {
      const key = item[0];
      if (skipKeys.includes(key)) {
        return null; // Skip the object with the specified key
      }

      // let value: any = item[1];
      // if (value instanceof Date) {
      //   value = moment(value).format("DD/MM/YYYY");
      // } else {
      //   value = item[1]; // Convert non-date values to strings
      // }

      return {
        key: item[0],
        label: formatEventDate(item[0]),
        value: item[1],
      };
    })
    .filter((item) => item !== null);

  return result;
}

// SCROLL TO TOP IF ERROR OCCURs
export const scrollToError = (formik: any) => {
  const inputFields: any = document.querySelectorAll(".form");
  const firstErrorField = [...inputFields].find((inputItem) => {
    return formik.errors.hasOwnProperty(
      inputItem.name ? inputItem.name : inputItem?.getAttribute("data-name")
    );
  });
  if (firstErrorField) {
    firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

export const splitTypeOptions = (data: any) => {
  return data?.map((item: any) => ({
    ...item,
    name:
      item?.id === "ANY"
        ? "None"
        : item?.id === "NOSINGLES"
        ? "Avoid Leaving One"
        : item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase(),
  }));
};

// Memoization helper function
// export const memoize = (fn: any) => {
//   const cache = new Map();
//   return (...args: any) => {
//     const key = JSON.stringify(args);
//     if (cache?.has(key)) {
//       return cache?.get(key);
//     }
//     const result = fn(...args);
//     cache.set(key, result);
//     return result;
//   };
// };

// Function to get the ticket type based on eventRecord and layout
export const getTicketType = (eventRecord: any, layout: any) => {
  const { ticket_type, sub_ticket_type } = eventRecord || {};
  const { ticketType } = layout;

  if (typeof ticket_type === "object") {
    if (ticket_type === "eticket") {
      if (sub_ticket_type === "pdf") {
        return { id: "pdf", name: "PDF E-Ticket" };
      } else if (sub_ticket_type === "mobile-qr") {
        return { id: "mobile-qr", name: "Mobile QR" };
      }
    } else {
      return ticket_type;
    }
    return null;
  } else if (Array.isArray(ticketType)) {
    let subTicket = !sub_ticket_type?.trim() ? "pdf" : sub_ticket_type;

    let value: any = ticketType.find((ticket) => {
      return ticket_type === "eticket"
        ? ticket.id === subTicket
        : ticket.id === ticket_type;
    });
    return value;
  }

  return null;
};

// Function to get the category value based on eventRecord and event
export const getCategoryValue = (eventRecord: any, event: any) => {
  const categoryId = eventRecord?.category?.id;
  const recordCategoryId = eventRecord?.category;

  if (categoryId) {
    return eventRecord?.category;
  } else if (event?.categories) {
    const foundCategory = event.categories.find(
      (category: any) => Number(category.id) === Number(recordCategoryId)
    );
    return foundCategory || null;
  }

  return null;
};

// Function to get the section value based on eventRecord and event
export const getSectionValue = (eventRecord: any, event: any) => {
  const sectionId = eventRecord?.section?.id;
  const recordSectionId = eventRecord?.section;
  const recordCategoryId = eventRecord?.category;

  if (sectionId) {
    return eventRecord?.section;
  } else if (event?.categories && Array.isArray(event.categories)) {
    const foundCategory = event.categories.find(
      (category: any) => Number(category.id) === Number(recordCategoryId)
    );

    if (foundCategory && foundCategory.sections) {
      const foundSection = foundCategory.sections.find(
        (section: any) => Number(section?.id) === Number(recordSectionId)
      );
      return foundSection || null;
    }
  }

  return null;
};

// Function to get benefits value based on eventRecord and layout
export const getBenefitsValue = (eventRecord: any, layout: any) => {
  const benefitsId = eventRecord?.benefits?.[0]?.id;

  if (benefitsId) {
    return eventRecord?.benefits;
  } else if (layout?.benefits) {
    return layout.benefits.filter((benefit: any) =>
      eventRecord?.benefits?.includes(benefit.id)
    );
  }

  return null;
};

// Function to get restriction value based on eventRecord and layout
export const getRestrictionValue = (eventRecord: any, layout: any) => {
  const restrictionsId = eventRecord?.restrictions?.[0]?.id;

  if (restrictionsId) {
    return eventRecord?.restrictions;
  } else if (layout?.restrictions) {
    return layout.restrictions.filter((restriction: any) =>
      eventRecord?.restrictions?.includes(restriction.id)
    );
  }

  return null;
};

export const extractOptions = (inputArray: any) =>
  inputArray &&
  inputArray
    .map((obj: any) => obj.options)
    .filter((options: any) => options)
    .flat();

// export const outSideClick = (e: any, bulkActionRef: any) => {
//   if (!bulkActionRef?.current?.contains(e.target)) {
//     let bulkAction: any = document.querySelectorAll(
//       'span[data-te-target="#bulkaction"]:not([data-te-collapse-collapsed])'
//     )?.[0];
//     if (bulkAction) {
//       bulkAction.click();
//     }
//   }
// };

// export const columnsOutSideClick = (e: any, bulkAction1Ref: any) => {
//   if (!bulkAction1Ref?.current?.contains(e.target)) {
//     let bulkAction: any = document.querySelectorAll(
//       'span[data-te-target="#bulkaction1"]:not([data-te-collapse-collapsed])'
//     )?.[0];
//     if (bulkAction) {
//       bulkAction.click();
//     }
//   }
// };
export const outSideClick = (e: any, dropdownId: any) => {
  let filterDiv = document.getElementById(dropdownId);
  if (!filterDiv?.contains(e.target)) {
    let bulkAction: any = document.querySelectorAll(
      `span[data-te-target="#${dropdownId}"]:not([data-te-collapse-collapsed])`
    )?.[0];
    if (bulkAction) {
      bulkAction.click();
    }
  }
};

export const columnsOutSideClick = (e: any, dropdownId: any) => {
  let columnDiv = document.getElementById(dropdownId);
  if (!columnDiv?.contains(e.target)) {
    let bulkAction: any = document.querySelectorAll(
      `span[data-te-target="#${dropdownId}"]:not([data-te-collapse-collapsed])`
    )?.[0];
    if (bulkAction) {
      bulkAction.click();
    }
  }
};
// export const
export const quantityOptions = () => {
  let quantityOptions = Array.from({ length: 5 }, (_, index) => ({
    id: (index + 1).toString(),
    name: (index + 1).toString(),
    postData: (index + 1).toString(),
  }));
  quantityOptions.unshift({ id: "6", name: "Any", postData: "1" });
  return quantityOptions;
};

export const getOptionForDropdown = (value: any, name: any, icon: any) => {
  if (value && name) {
    return {
      id: value,
      name: name,
      lable: name,
      icon: icon,
      value: name,
    };
  }
};

export const getHeaderFilter = (
  globalStateKey: string,
  globalState: any,
  constArray: any
) => {
  let isFilterExistInDB = globalState;
  const saved: any = localStorage.getItem(globalStateKey);
  return isFilterExistInDB || JSON.parse(saved) || constArray;
};

export function colorVirtualCard(color: any) {
  if (color === "Dark purple") {
    return "#5020A6";
  }
  if (color === "Dark green") {
    return "#269926";
  }
  if (color === "Dark blue") {
    return "#4F6DF0";
  }
  if (color === "Dark parrot green") {
    return "#1FA683";
  }
  if (color === "Dark purple blue") {
    return "#8F60E6";
  }
}

export function HexColorCodeForVirtualCard(color: any) {
  if (color === "#5020A6") {
    return "Dark purple";
  }
  if (color === "#269926") {
    return "Dark green";
  }
  if (color === "#4F6DF0") {
    return "Dark blue";
  }
  if (color === "#1FA683") {
    return "Dark parrot green";
  }
  if (color === "#8F60E6") {
    return "Dark purple blue";
  }
  return "Dark purple";
}

export function colorVirtualCardForDropDown(color: any) {
  if (color === "Dark purple") {
    return "card-100";
  }
  if (color === "Dark green") {
    return "card-200";
  }
  if (color === "Dark blue") {
    return "card-300";
  }
  if (color === "Dark parrot green") {
    return "card-400";
  }
  if (color === "Dark purple blue") {
    return "card-500";
  }
}

export function isValidUrl(url: any) {
  const urlRegex: any = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlRegex.test(url);
}

export function getExtension(filename: any) {
  return filename?.split(".").pop();
}

export function capitalizeFirstLetter(string: any) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
}

export const allFieldsFilled = (
  arr: any,
  values: any,
  errors: any = {},
  limit: number = 0
) => {
  for (const key of arr) {
    if (errors[key]) {
      return false;
    }
    if (
      values[key] === undefined ||
      values[key] === null ||
      values[key] === "" ||
      values[key]?.length < limit
    ) {
      return false;
    }
  }
  return true;
};

// HANDLE FILE CONVERSION
export const FileToObjectConversion = (data: any) => {
  const objects = [];

  for (let i = 0; i < data.length; i++) {
    const file = data[i];
    const object = {
      name: file.name,
      lastModified: file.lastModified,
      size: file.size,
      type: file.type,
      base64: file?.base64,
    };
    objects.push(object);
  }
  return objects;
};
export function checkingValidUrlForBoth(data: any) {
  return isValidUrl(data?.android) && isValidUrl(data?.ios);
}

// HANDLE ON FOCUS
export const handleOnFocus = (
  data: any,
  isFocused: boolean | string,
  formik: any
) => {
  let name = data?.target?.name;
  if ((!isFocused || isFocused === "blur") && formik?.errors?.[name]) {
    formik.setFieldTouched(name, true);
  }
};

export const handleInputChange = (newValue: any, ID: any) => {
  let id: any = document.getElementById(ID);
  if (newValue && id) {
    id?.classList?.add("input-typing");
  } else {
    id?.classList?.remove("input-typing");
  }
  return newValue;
};

export const MobileInsturction = [
  "Mobile Link URLs can be entered directly into each ticket field on the right-hand side",
  "If your tickets have separate URLs for Android/iOS - select the switch to enable multiple fields for each ticket",
  "If you have .pkpass files to convert to links - use this window to upload them",
  "Select the ‘PKPASS’ switch on the listing, then drag and drop each .pkpass file - ensuring any designated seat matches the ticket - to generate a Mobile Link URL",
  "Confirm all tickets are uploaded by clicking the green ‘Confirm’ button",
];

export function checkPPEExist(list: any, isGodAdmin: any) {
  const selectedEventRecords: any = list
    ?.flatMap((item: any) =>
      item?.eventRecords?.filter((record: any) => record?.selected)
    )
    .filter(Boolean);

  let godAdminNotSameTeamError = false;
  if (isGodAdmin) {
    const allSameTeamName = selectedEventRecords?.map(
      (record: any) => record?.team_name
    );
    godAdminNotSameTeamError = allSameTeamName.every(
      (name: any) => name === allSameTeamName?.[0]
    );
  }

  const hasPricePerExchangeOne = selectedEventRecords?.some(
    (obj: any) =>
      obj?.price_per_exchange === 1 ||
      obj?.price_per_exchange === true ||
      obj?.ppe === true
  );

  const findAllexhange = selectedEventRecords?.every(
    (obj: any) =>
      obj?.price_per_exchange === 1 ||
      obj?.price_per_exchange === true ||
      obj?.ppe === true
  );

  let isError = false;

  if (isGodAdmin) {
    if (godAdminNotSameTeamError) {
      isError = hasPricePerExchangeOne || false;
    } else {
      isError = true;
    }
  } else {
    isError = hasPricePerExchangeOne || false;
  }

  return { isError: isError, teamNameError: !godAdminNotSameTeamError };
}

export function checkPPEExistForTableLevelNEw(list: any, isGodAdmin: any) {
  let allPPE: any = false;
  let somePPE: any = false;
  let godAdminSameTeam: any = false;

  const filteredEvents = list?.eventRecords?.filter(
    (eventGroup: any) => eventGroup?.selected
  );

  allPPE =
    filteredEvents?.length > 0 &&
    filteredEvents?.every(
      (obj: any) =>
        obj?.price_per_exchange === 1 ||
        // (obj.price_per_exchange === false &&
        //   (obj.replacible.price_per_exchange === 1 ||
        //     obj.replacible.price_per_exchange === true) &&
        //   obj?.isInputChange === true) ||
        obj.ppe === true
    );

  somePPE =
    filteredEvents?.length === 1
      ? false
      : filteredEvents?.length > 0 &&
        filteredEvents?.some(
          (obj: any) =>
            obj?.price_per_exchange === 1 ||
            // (obj.price_per_exchange === false &&
            //   (obj.replacible.price_per_exchange === 1 ||
            //     obj.replacible.price_per_exchange === true) &&
            //   obj?.isInputChange === true) ||
            obj.ppe === true
        );

  if (isGodAdmin) {
    const allSameTeamName = filteredEvents?.map(
      (record: any) => record?.team_name
    );
    godAdminSameTeam = allSameTeamName.every(
      (name: any) => name === allSameTeamName?.[0]
    );
  }

  return {
    allPPE,
    somePPE,
    allNonPPE: !allPPE && !somePPE,
    godAdminError: isGodAdmin ? !godAdminSameTeam : false,
  };
}

export function getSellerLevelNumberWithColor(data: any) {
  let levelName: any;
  const levelString = data?.title?.toLowerCase();
  switch (levelString) {
    case "level 1":
      levelName = 1;
      break;
    case "level 2":
      levelName = 2;
      break;
    case "level 3":
      levelName = 3;
      break;
    case "super level":
      levelName = "S";
      break;
    case "super seller":
      levelName = "S";
      break;
    default:
  }

  return levelName;
}
