/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";

const AddInventoryButton = ({
  handleOnClick,
  classNames,
  eventLength,
  isActive = false,
}: any) => {
  // let eventLength = layout && layout.events.length > 0;
  const [addInventory, setAddInventory] = useState(true);

  return (
    <button
      type="button"
      className={`relative inline-flex items-center z-10    hover:text-violet-500 transition font-medium rounded text-sm13 px-2.5 py-0.5  ${
        eventLength
          ? "pointer-events-none !text-gray-400 bg-gray-100 "
          : " bg-violet-500 border border-violet-500  after:h-0 after:w-full after:bg-violet-600 after:absolute after:top-0 after:left-0 after:rounded after:transition hover:after:opacity-0 after:-z-10"
      } ${
        isActive
          ? "!bg-white !text-violet-500 border !border-violet-500 hover:!text-white hover:!bg-indigo-500 hover:!border-indigo-500"
          : "text-white hover:bg-white"
      } ${classNames}`}
      onClick={(data: any) => {
        setAddInventory((pre: any) => !pre);
        handleOnClick(addInventory);
      }}
    >
      Add to Inventory
    </button>
  );
};

export default AddInventoryButton;
