import _, { size } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import Checkbox from "../../../components/formComponent/Checkbox";
import DropDownField from "../../../components/formComponent/DropDownField";
import HeaderSection from "../../../components/fullPopup/HeaderSection";
import { errorReflect } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import {
  API_CONNECTOR_ACCEPTED_STATUS_CODE,
  HTTP_METHODS,
} from "../../../helpers/const";
import { ConnectorContext } from "../core/ConnectorProvider";
import { handleError, mapVariables, replacePatterns } from "../core/functions";
import { fetchThroughMethod, getTriggerListing } from "../core/requests";
import APISearchField from "./component/APISearchField";
import APITab from "./component/APITab";
import AuthenticationBodyWrapper from "./component/AuthenticationBodyWrapper";

const Triggers = ({ data, sellerData, handleClose }: any) => {
  const layout = useContext(ConnectorContext);
  const state: any = layout?.state;
  const headers: any = layout?.headers;
  const variableData: any = layout?.variableData;
  const { names: nameOptions } = layout?.clientsOptionsData;

  const title = useMemo(() => {
    return layout.clientsOptionsData?.names?.find((option: any) => {
      return option?.id === data?.name;
    })?.name;
  }, [data?.name]);

  // HANDLE TAB ACTIVE
  const handleActiveTab = (tab: string) => {
    layout.setState((current: any) => {
      return {
        ...current,
        activeTab: tab,
      };
    });
  };

  // EXECUTE API
  const executeAPIAndLoadState = (payload: any) => {
    payload.url = replacePatterns(variableData, payload.url);
    payload.headers = payload.headers?.map((item: any) => {
      return {
        ...item,
        value: replacePatterns(variableData, item?.value),
      };
    });

    payload.body = replacePatterns(
      variableData,
      typeof payload?.body === "object"
        ? JSON.stringify(payload?.body)
        : payload?.body
    );

    layout.setLoader(true);
    fetchThroughMethod(payload)
      .then((response: any) => {
        layout.setLoader(false);
        if (!response.ok || (response?.errors && size(response?.errors) > 0)) {
          errorReflect(response);
          layout.setState((current: any) => {
            return {
              ...current,
              response: {
                ...current?.response,
                data: response,
                error: API_CONNECTOR_ACCEPTED_STATUS_CODE?.includes(
                  response?.statusCode
                )
                  ? false
                  : true,
                message: response?.statusCode,
              },
            };
          });
        } else if (
          API_CONNECTOR_ACCEPTED_STATUS_CODE?.includes(response?.statusCode)
        ) {
          layout.setState((current: any) => {
            return {
              ...current,
              response: {
                data: response,
                error: false,
                message: "Success",
              },
            };
          });
        }
      })
      .catch((err) => {
        layout.setLoader(false);
        handleError(err);
      });
  };

  const executeAuthAPI = () => {
    // AUTH API CALLED
    layout.setLoader(true);
    getTriggerListing({
      id: sellerData?.id,
      name: "auth",
    })
      .then((response: any) => {
        const authData = response?.data?.[0];
        if (authData) {
          let headers = Object.entries(authData?.headers)?.map(
            ([key, value]) => {
              return {
                name: key,
                value: value,
              };
            }
          );
          let payload = {
            ...authData,
            headers,
            httpMethod: HTTP_METHODS?.find(
              (method: any) => method?.name === authData?.request_type
            ),
            url: authData?.endpoint_url,
          };

          payload.url = replacePatterns(variableData, payload.url);
          payload.headers = payload.headers?.map((item: any) => {
            return {
              ...item,
              value: replacePatterns(variableData, item?.value),
            };
          });

          payload.body = replacePatterns(
            variableData,
            typeof payload?.body === "object"
              ? JSON.stringify(payload?.body)
              : payload?.body
          );
          // PROXY API CALLED
          fetchThroughMethod(payload)
            .then((fethResponse: any) => {
              layout.setLoader(false);
              if (
                fethResponse.ok ||
                API_CONNECTOR_ACCEPTED_STATUS_CODE?.includes(
                  fethResponse?.statusCode
                )
              ) {
                let newMappedHeaders = mapVariables(
                  authData?.response_mapped_variables,
                  fethResponse
                );

                let newHeaders = _.cloneDeep(layout.headers);
                newHeaders.forEach((header: any) => {
                  for (const key in newMappedHeaders) {
                    const placeholder = `{{${key}}}`;
                    const value = newMappedHeaders[key];
                    header.value = replacePatterns(
                      variableData,
                      header.value.replace(placeholder, value)
                    );
                  }
                });

                executeAPIAndLoadState({ ...state, headers: newHeaders });
              }
            })
            .catch(() => layout.setLoader(false));
        }
      })
      .catch(() => layout.setLoader(false));
  };

  // HANDLE SUBMIT
  const handleSend = () => {
    const { body } = state;

    try {
      if (body) {
        JSON.parse(body);
      }

      if (state?.authRequired && data) {
        executeAuthAPI();
      } else {
        executeAPIAndLoadState({ ...state, headers: headers });
      }
    } catch (error) {
      layout.setLoader(false);
      showAlert("Please check the content in the body", true);
    }
  };

  // HANDLE CHANGE
  const handleOnChange = (data: any) => {
    layout.setState((current: any) => {
      return {
        ...current,
        [data?.target?.name]: data?.target?.value
          ? data?.target?.value
          : data?.target?.checked,
      };
    });
  };

  useEffect(() => {
    layout.setMapping(data?.response_mapped_variables);
  }, [data]);

  return (
    <div
      className={`inventory-list w-full px-5 pt-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full${
        layout.canvasToggle ? "px-5" : "pl-10 px-5"
      }`}
    >
      {/* CROSS BUTTON */}
      <HeaderSection
        className="border mb-2 flex justify-between  items-center sticky top-0 bg-white"
        titleClassName="px-4"
        title={title ? title : "Add Trigger"}
        handleClosePopup={handleClose}
      />

      {/* SEARCH FIELD */}
      <div className="trade-filters py-[.4375rem] flex flex-wrap border-b xxl:flex-nowrap gap-2">
        <APISearchField handleSend={handleSend} />

        {/* AUTH REQUIRED */}
        <div className="w-full h-auto sm:w-auto sm:max-w-[11.4375rem] md:w-[11.4375rem] xxl:max-[11.4375rem]  lg3:max-w-[20%]">
          <Checkbox
            label="Auth required"
            id="authRequired"
            extraClass="h-full"
            name="authRequired"
            checked={state?.authRequired}
            tooltip={false}
            handleOnChange={(data: any, e: any) => {
              handleOnChange(data);
            }}
          />
        </div>

        {/* DROPDOWN */}
        {!data && (
          <div className="w-full sm:w-auto ">
            <DropDownField
              options={nameOptions?.filter((item: any) => item?.id !== "auth")}
              placeholder="Name"
              name="name"
              value={state?.name}
              isClearable={true}
              handleOnChange={(data: any, e: any) => {
                handleOnChange(data);
              }}
            />
          </div>
        )}
      </div>

      <div className="mt-2 flex justify-around tab-panel bg-no-repeat bg-cover bg-indigo-500 bg-opacity-[15%] p-5 pb-0  after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
        <APITab
          title="Headers"
          status={state?.activeTab}
          handleActive={handleActiveTab}
        />
        <APITab
          title="Body"
          status={state?.activeTab}
          handleActive={handleActiveTab}
        />
      </div>

      <div
        className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
        role="tabpanel"
        data-te-tab-active
      >
        <AuthenticationBodyWrapper />
      </div>
    </div>
  );
};

export default Triggers;
