import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import DropDownField from "../../../../../components/formComponent/DropDownField";
import PriceField from "../../../../../components/formComponent/PriceField";
import ProgressBardLoader from "../../../../../components/ui/ProgressBardLoader";
import { decimalNumber } from "../../../../../helpers/Functions";
import { showAlert } from "../../../../../helpers/ShowAlert";
import {
  ORDER_ASSIGN_TO_NEW_LISTING,
  QUERIES,
  SYMBOLS,
} from "../../../../../helpers/const";
import { SalesContext } from "../../../core/SalesProvider";
import { orderReplacement } from "../../../core/_requests";
import BackToOrder from "./BackToOrder";
import EventDetails from "./EventDetails";
import Header from "./Header";
import HighligtedFilter from "./HighligtedFilter";
import Label from "./Label";
import SellarPenalityDetails from "./SellarPenalityDetails";

interface OrderNewReplacementInterface {
  setListing?: any;
  commonWallet?: any;
}

const OrderNewReplacement: React.FC<OrderNewReplacementInterface> = ({
  setListing,
  backToOrderListingStatus,
  commonWallet,
}: any) => {
  const layout = useContext(SalesContext);
  const [customNewOrderLoader, setCustomNewOrderLoader] =
    useState<boolean>(false);

  const [state, setState] = useState<any>({
    penalty: {
      penaltyType: "absolute",
      penaltyValue: undefined,
      penaltyCurrency: layout?.orderAssign?.ticket_currency,
      total: 0,
      orderValue: 0,
      notes: "",
    },
    orderAdmistration: {
      orderStatus: null,
      internalNote: "",
    },
    deduct_from_wallet: false,
  });

  // ORIGINAL - ORDER OBJECT
  let orderAssign = layout?.orderAssign?.data;
  let orderAssignValues = layout?.orderAssign;

  // REPLACEMENT - ORDER OBJECT
  let displayReplacementList = {
    ...layout?.orderAssign?.replacementList,
    ticket_quantity: orderAssign?.ticket_quantity,
    category: layout?.orderAssign?.replacementList?.zone_label,
    seating_location: layout?.orderAssign?.replacementList?.section_label,
    ticket_currency: layout?.orderAssign?.replacementList?.sell_price_currency,
    ticket_price: layout?.orderAssign?.replacementList?.sell_price,
    transaction_value: (
      parseFloat(layout?.orderAssign?.replacementList?.sell_price) *
      orderAssign?.ticket_quantity
    ).toFixed(2),
    broker_name: `${layout?.orderAssign?.replacementList?.team_name} - ${layout?.orderAssign?.replacementList?.broker_name}`,
  };

  let isDisabled: boolean = false;
  let isActive: boolean = false;

  if (
    orderAssignValues?.reviewReplacement &&
    !orderAssignValues?.confirmedReplacement
  ) {
    isDisabled = false;
  } else if (orderAssignValues?.confirmedReplacement) {
    isDisabled = true;
  }

  if (
    state?.penalty?.penaltyValue &&
    state?.penalty?.total &&
    state?.penalty?.notes &&
    state?.orderAdmistration?.internalNote &&
    state?.orderAdmistration?.orderStatus
  ) {
    isActive = true;
  } else {
    isActive = false;
  }

  useEffect(() => {
    setState((current: any) => {
      return {
        ...current,
        penalty: {
          ...current?.penalty,
          penaltyCurrency: orderAssignValues?.data?.ticket_currency,
          orderValue: orderAssignValues?.data?.transaction_value,
        },
      };
    });
  }, [orderAssignValues]);

  // PAYLOAD
  let payload = {
    replacement_listing_id: displayReplacementList?.id,
    fine_type:
      state?.penalty?.penaltyType === "absolute"
        ? "fixed_amount"
        : "percentage",
    fine_amount: state?.penalty?.total,
    assign_to: "broker",
    order_id: orderAssignValues?.data?.order_id,
    order_status: state?.orderAdmistration?.orderStatus?.id,
    note_internal: state?.orderAdmistration?.internalNote,
    note_penalty: state?.penalty?.notes,
    row: displayReplacementList?.row,
    first_seat: displayReplacementList?.first_seat,
    ticket_price: displayReplacementList?.ticket_price,
    ticket_section: displayReplacementList?.section_label,
    wallet_deduction: state?.deduct_from_wallet,
  };

  // REPLACEMENT API
  const { data, refetch } = useQuery(
    [`${QUERIES.REPLACEMENT_LIST}`],
    () => orderReplacement(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onSettled(data: any, error: any) {
        setCustomNewOrderLoader(false);
        if (data?.data?.status) {
          showAlert(data?.message, false, 2000);
          setTimeout(() => {
            layout.setOrderAssign(ORDER_ASSIGN_TO_NEW_LISTING);
            layout.setTabValue("cancelled");
            layout?.setQuery(orderAssign?.order_id);
          }, 2000);
        } else if (data) {
          if (data?.data?.errors?.title) {
            showAlert(data?.data?.errors?.title, true);
          } else if (data?.data?.errors && _.size(data?.data?.errors) > 0) {
            showAlert(data?.data?.errors?.[0], true);
          } else {
            showAlert("Something went wrong.", true);
          }
        }
      },
    }
  );
  return (
    <React.Fragment>
      <div
        className={`show !scrollbar-thin  !scrollbar-thumb-violet-800 !scrollbar-thumb-rounded-full scrollbar-track-gray-650  overflow-y-auto overflow-x-hidden outline-none upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding  transition duration-300 ease-in-out font-medium w-full md:max-w-[calc(100%_-_3.75rem)] z-[50] bg-gray-650 `}
      >
        <div className="relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600 h-full">
          <div className=" main-content-wrap  min-h-full bg-white flex flex-col">
            {/* BACK TO ORDER LISTING   */}
            <BackToOrder
              backToOrderListingStatus={false}
              confirmed={isDisabled}
            />

            <div className="inner-main-content p-5 overflow-auto h-[calc(100vh-6.875rem)] flex flex-col scrollbar-thin  scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <div className="top-filterbar rounded border mb-5">
                {/* EVENT DETAILS */}
                <EventDetails data={layout?.orderAssign?.data} />

                {/* FILTER HIGHLIGHTED  */}
                <HighligtedFilter
                  data={orderAssign}
                  NoBorderBottom={true}
                  confirmedScreen={true}
                />
              </div>
              <div className="replacement-listing rounded border mb-5">
                {/* HEADER */}
                <Header
                  title="Replacement listing"
                  className="text-sm14 font-medium md:pl-5 md:p-[.9375rem] p-2.5 leading-5 w-full"
                />
                {/* REPLACEMENT */}
                <HighligtedFilter
                  data={displayReplacementList}
                  bgColor="bg-white"
                  NoBorderBottom={true}
                  confirmedScreen={true}
                />
              </div>

              <div className="order-panelty rounded border mb-5">
                {/* HEADER */}
                <Header
                  title="Order penalty"
                  className="ellips-text text-sm14 font-medium md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                />
                <SellarPenalityDetails
                  currenncy={SYMBOLS?.[orderAssign?.refund_currency]}
                  inputValue={state?.penalty?.total}
                  amount={commonWallet}
                  checkedValue={state?.deduct_from_wallet}
                  onChange={(e: any) =>
                    setState((currentValue: any) => {
                      return {
                        ...currentValue,
                        deduct_from_wallet: e.target.checked,
                      };
                    })
                  }
                  confirmation={orderAssignValues?.confirmedReplacement}
                  sellarName={orderAssign?.team_name}
                />
                {/* PENALITY - BLOCK */}
                <div
                  className={`${
                    orderAssignValues?.reviewReplacement &&
                    !orderAssignValues?.confirmedReplacement
                      ? "bg-indigo-500"
                      : "bg-white"
                  }  panelty-block md:px-5 md:py-4 p-2.5 flex flex-wrap items-center bg-opacity-[7%] md:gap-x-5 gap-2.5 border-b`}
                >
                  {orderAssignValues?.reviewReplacement &&
                    !orderAssignValues?.confirmedReplacement && (
                      <>
                        <div className="panelty-radio flex items-center gap-[.9375rem] font-medium md:w-auto w-full">
                          <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                            <input
                              type="radio"
                              name="penaltyType"
                              value="absolute"
                              checked={
                                state?.penalty?.penaltyType === "absolute"
                                  ? true
                                  : false
                              }
                              id="absolutetype"
                              onChange={(e: any) => {
                                setState((current: any) => {
                                  return {
                                    ...current,
                                    penalty: {
                                      ...current?.penalty,
                                      penaltyType: "absolute",
                                      penaltyValue: "",
                                      total: 0,
                                    },
                                  };
                                });
                              }}
                            />
                            <label htmlFor="absolutetype" className="">
                              (
                              {
                                SYMBOLS[
                                  orderAssignValues?.data?.ticket_currency
                                ]
                              }
                              ) Amount
                            </label>
                          </div>
                          <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                            <input
                              type="radio"
                              name="penaltyType"
                              value="relative"
                              checked={
                                state?.penalty?.penaltyType === "relative"
                                  ? true
                                  : false
                              }
                              id="relativetype"
                              onChange={(e: any) => {
                                setState((current: any) => {
                                  return {
                                    ...current,
                                    penalty: {
                                      ...current?.penalty,
                                      penaltyType: "relative",
                                      penaltyValue: "",
                                      total: 0,
                                    },
                                  };
                                });
                              }}
                            />
                            <label htmlFor="relativetype" className="">
                              (%) Percentage
                            </label>
                          </div>
                        </div>
                        <div className="panelty-input max-w-[9.375rem]">
                          <PriceField
                            value={state?.penalty?.penaltyValue}
                            currenncySymbol={
                              state?.penalty?.penaltyType === "absolute"
                                ? state?.penalty?.penaltyCurrency
                                : "PER"
                            }
                            name="penaltyValue"
                            label="Penalty value"
                            textFielClassName={`truncate`}
                            id={`penaltyValue`}
                            handleOnChange={(data: any) => {
                              setState((currentValue: any) => {
                                const penaltyType =
                                  currentValue?.penalty?.penaltyType;
                                const orderValue =
                                  currentValue?.penalty?.orderValue;
                                const penaltyValue = data?.target?.value?.value;

                                let total: any = 0;

                                if (penaltyType === "relative") {
                                  total = (
                                    (penaltyValue / 100) *
                                    orderValue
                                  ).toFixed(2);
                                } else if (penaltyType === "absolute") {
                                  total = penaltyValue;
                                }

                                return {
                                  ...currentValue,
                                  deduct_from_wallet:
                                    Number(total) > Number(commonWallet)
                                      ? false
                                      : currentValue?.deduct_from_wallet,
                                  penalty: {
                                    ...currentValue?.penalty,
                                    penaltyValue,
                                    total,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      </>
                    )}
                  <h5 className="text-sm14 font-semibold">
                    Penalty total:{" "}
                    {`${
                      SYMBOLS[state?.penalty?.penaltyCurrency]
                    }${decimalNumber(state?.penalty?.total)}`}
                  </h5>
                </div>

                {/* PENALITY NOTES - BLOCK */}
                <div className="panelty-note md:px-5 md:py-5 px-2.5 py-3.5">
                  <div className="md:w-1/2 w-full">
                    <Label data="Penalty notes" required={true} />
                    {!isDisabled ? (
                      <input
                        className={`bg-opacity-50 border font-medium transition   focus:border-indigo-500 focus:bg-white outline-none peer
                       border-gray-300 bg-gray-100 whitespace-normal text-sm12 rounded  px-2 block text-violet-800 resize-none py-1.5 leading-4 w-full`}
                        name="notes"
                        value={state?.penalty?.notes}
                        disabled={isDisabled}
                        onChange={(e: any) => {
                          setState((currentValue: any) => {
                            return {
                              ...currentValue,
                              penalty: {
                                ...currentValue?.penalty,
                                notes: e?.target?.value,
                              },
                            };
                          });
                        }}
                      />
                    ) : (
                      <div
                        className=" font-medium transition   focus:border-indigo-500 focus:bg-white outline-none peer
                       border-gray-300 bg-gray-100 whitespace-normal text-sm12 rounded  px-2 block text-violet-800 resize-none py-1.5 leading-4 w-full min-h-[1.875rem]"
                      >
                        {state?.penalty?.notes}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* ORDER ADMINISTRATION - BLOCK */}
              <div className="order-administration rounded border ">
                <Header
                  title="Order administration (internal)"
                  className="ellips-text text-sm14 font-medium max-w-[18.75rem] md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                />

                {/* INPUT FIELDS  */}
                <div className="panelty-status md:p-5 px-2.5 py-3.5 flex flex-wrap md:gap-5 gap-2.5">
                  <div className="panelty-note md:w-1/4 w-full">
                    <Label data="Internal order status" required={true} />
                    <DropDownField
                      value={state?.orderAdmistration?.orderStatus}
                      name="orderStatus"
                      placeholder={`${
                        state?.orderAdmistration?.orderStatus
                          ? ""
                          : "Internal order status"
                      }`}
                      isDisabled={isDisabled}
                      isClearable={true}
                      options={
                        orderAssignValues?.data?.internalOrderOptions?.data
                          ?.internal_order_statuses
                      }
                      handleOnChange={(data: any) =>
                        setState((currentValue: any) => {
                          return {
                            ...currentValue,
                            orderAdmistration: {
                              ...currentValue?.orderAdmistration,
                              [data?.target?.name]: data?.target?.value,
                            },
                          };
                        })
                      }
                    />
                  </div>
                  <div className="panelty-note md:w-1/2 w-full">
                    <Label data="Internal notes" required={true} />
                    {!isDisabled ? (
                      <input
                        className={`bg-opacity-50 border font-medium transition bg-gray-100  focus:border-indigo-500 focus:bg-white outline-none peer
                                 border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1.5 leading-4`}
                        name="internalNote"
                        value={state?.orderAdmistration?.internalNote}
                        disabled={isDisabled}
                        onChange={(e: any) => {
                          setState((currentValue: any) => {
                            return {
                              ...currentValue,
                              orderAdmistration: {
                                ...currentValue?.orderAdmistration,
                                internalNote: e?.target?.value,
                              },
                            };
                          });
                        }}
                      />
                    ) : (
                      <div
                        className="font-medium transition bg-gray-100  focus:border-indigo-500 focus:bg-white outline-none peer
                                 border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1.5 leading-4 min-h-[1.875rem]"
                      >
                        {state?.orderAdmistration?.internalNote}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* ACTION BUTTONS */}
            <div className=" offcanvas-footer bottom-0 right-0 w-full flex flex-wrap justify-between px-5 py-3 bg-white z-10 border-t">
              {orderAssignValues?.reviewReplacement &&
              !orderAssignValues?.confirmedReplacement ? (
                <div className="l_btn gap-2.5 flex flex-wrap items-center justify-end w-full">
                  <button
                    type="button"
                    className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                    onClick={() => {
                      layout.setOrderAssign(ORDER_ASSIGN_TO_NEW_LISTING);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`${
                      isActive
                        ? "hover:text-white active: bg-violet-500 text-white hover:bg-indigo-500 active:bg-indigo-500 anim-fade"
                        : "bg-gray-100 text-gray-500 pointer-events-none"
                    } relative z-10    transition  rounded text-sm13 px-2.5 py-0.5`}
                    onClick={() => {
                      layout.setOrderAssign((currentValue: any) => {
                        return {
                          ...currentValue,
                          confirmedReplacement: true,
                        };
                      });
                    }}
                  >
                    Review
                  </button>
                </div>
              ) : (
                orderAssignValues?.confirmedReplacement && (
                  <div className="l_btn gap-2.5 flex flex-wrap items-center justify-end w-full">
                    <span className="text-sm13">
                      Do you confirm all details are correct?
                    </span>
                    <button
                      type="button"
                      className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                      onClick={() => {
                        layout.setOrderAssign((currentValue: any) => {
                          return {
                            ...currentValue,
                            reviewReplacement: true,
                            confirmedReplacement: false,
                          };
                        });
                      }}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className={` relative z-10   hover:text-white active: bg-violet-500 text-white hover:bg-indigo-500 active:bg-indigo-500 transition  rounded text-sm13 px-2.5 py-0.5`}
                      onClick={() => {
                        layout.setOrderAssign((currentValue: any) => {
                          return {
                            ...currentValue,
                            reviewReplacement: true,
                            confirmedReplacement: false,
                          };
                        });
                        setCustomNewOrderLoader(true);

                        refetch();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      {customNewOrderLoader && (
        <ProgressBardLoader LoadingText="" secondCounter={1.5} />
      )}
    </React.Fragment>
  );
};

export default OrderNewReplacement;
