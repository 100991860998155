export const AngleRightIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="9"
    viewBox="0 0 6 9"
    {...props}
  >
    <path
      id="Path_360"
      data-name="Path 360"
      d="M24,136h9l-4.5,6Z"
      transform="translate(-136 33) rotate(-90)"
    />
  </svg>
);
