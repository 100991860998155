import { createContext, PropsWithChildren, useState } from "react";

export interface LayoutContextModel {
  sellerLevelData: any;
  setSellerLevelData: (data: any) => void;

  sellerLevelEditData: any;
  setSellerLevelEditData: (data: any) => void;
}

const SellerLevelsProviderContex = createContext<LayoutContextModel>({
  sellerLevelData: [],
  setSellerLevelData: (data: any) => {},

  sellerLevelEditData: {},
  setSellerLevelEditData: (data: any) => {},
});

const SellerLevelsProvider = ({ children }: PropsWithChildren) => {
  const [sellerLevelData, setSellerLevelData] = useState<any>();
  const [sellerLevelEditData, setSellerLevelEditData] = useState<any>();

  const value: LayoutContextModel = {
    sellerLevelData,
    setSellerLevelData,

    sellerLevelEditData,
    setSellerLevelEditData,
  };

  return (
    <SellerLevelsProviderContex.Provider value={value}>
      {children}
    </SellerLevelsProviderContex.Provider>
  );
};

export { SellerLevelsProvider, SellerLevelsProviderContex };
