export const TX_TAB = {
  wallet: "Wallet",
  virtualCard: "Virtual cards",
};

export const TX_PAY_TAB_ROUTES = {
  wallet: "/wallet",
  virtualCard: "/virtual-cards",
};

export const COLOR_NAME_ID: any = {
  "Dark purple": "dark-purple",
  "Dark green": "dark-green",
  "Dark blue": "dark-blue",
  "Dark parrot green": "dark-parrot-green",
  "Dark purple blue": "dark-purple-blue",
};
