const HandSVG = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.178"
      height="14.5"
      viewBox="0 0 11.178 14.5"
      {...props}
    >
      <path
        id="Path_48597"
        data-name="Path 48597"
        d="M8.843,14.5a4.816,4.816,0,0,1-4.471-3.033l-1.831-4.6a.6.6,0,0,1,.749-.8l.477.157a1.212,1.212,0,0,1,.749.7l.852,2.133h.453v-7.1a.755.755,0,0,1,1.51,0V7.25h.6V.755a.755.755,0,1,1,1.51,0V7.25h.6V1.661a.755.755,0,0,1,1.51,0V7.25h.6V3.474a.755.755,0,0,1,1.51,0V9.667A4.832,4.832,0,0,1,8.843,14.5Z"
        transform="translate(-2.498)"
        fill=""
      />
    </svg>
  );
};

export default HandSVG;
