import { useContext } from "react";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { ConnectorContext } from "../core/ConnectorProvider";

interface TabInterface {
  imgPath?: any;
  title?: string;
  handleTab?: any;
  home?: any;
  countValue?: any;
  requiredFlag?: any;
  loading?: any;
  withIcon?: any;
  stroke?: any;
}

const Tab: React.FC<TabInterface> = ({
  imgPath,
  title,
  handleTab,
  home = false,
  countValue,
  requiredFlag,
  loading,
  withIcon = false,
  stroke = false,
}) => {
  const layout = useContext(ConnectorContext);
  const isTabActive = layout.isTabActive;
  const isTitle = isTabActive === title;

  return (
    <>
      <div
        className={`${
          isTitle ? "bg-opacity-100 !z-[11]" : "bg-opacity-60"
        } single-tab lg:px-4 px-2.5 py-[.5625rem]  lg:whitespace-normal whitespace-nowrap
        ] flex  items-center bg-white rounded-t cursor-pointer mr-[1px] w-full gap-2 group hover:bg-opacity-100 ${
          home
            ? "min-w-[6.25rem] max-w-[6.25rem] justify-center"
            : "lg2:max-w-[16.4375rem] min-w-[10.625rem] justify-between"
        }`}
        onClick={() => handleTab()}
      >
        <h4
          className={`lg:text-sm15 text-sm14 font-semibold group-hover:text-indigo-500 ${
            requiredFlag && isTitle
              ? "text-rose-500"
              : isTitle && "text-indigo-500"
          } ${home && "hidden"}`}
        >
          {title}
        </h4>
        {countValue ? (
          <span
            className={`flex items-center ${
              requiredFlag
                ? "bg-rose-500"
                : isTitle
                ? "bg-indigo-500"
                : "bg-violet-500 "
            } text-xxs text-white font-semibold px-2 py-0.5 rounded group-hover:bg-indigo-500 transition-all ${
              loading && "shimmer-effect"
            }  `}
          >
            {countValue}
          </span>
        ) : (
          <IKTSVG
            className={`flex items-center justify-center fill-${
              isTitle ? "indigo" : "violet"
            }-500 ${
              withIcon && "group-hover:stroke-indigo-500"
            }  group-hover:fill-indigo-500  transition ${
              stroke && "stroke-violet-500"
            }`}
            path={imgPath}
            svgClassName={
              title === "Inventory" ? "w-[.9375rem] h-[.9375rem]" : " w-4 h-4"
            }
          />
        )}
      </div>
    </>
  );
};

export default Tab;
