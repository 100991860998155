/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import Select, { ControlProps, NoticeProps, components } from "react-select";
import { Tooltip } from "react-tooltip";
import SpinnerSVG from "../../../../assets/media/icons/other_icons/spinner.svg";
import crossIcon from "../../../../assets/media/icons/standard_icons/evt_cross.svg";
import searchIcon from "../../../../assets/media/icons/standard_icons/evt_s_icon.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { isMobileView } from "../../../../helpers/Functions";
import { AddInventoryContext } from "../../core/AddInventoryProvider";
import { checkIfClonetoNewActive } from "../../core/_functions";
import Options from "./Options";

const customStyle = {
  control: (base: any, state: any) => {
    return {
      ...base,
      display: "flex",
      flexDirection: "row-reverse",
      border: state.isFocused
        ? "2px solid #6f6cff"
        : state.isDisabled
        ? "none"
        : "2px solid lightgrey",
      minHeight: "1px",
      backgroundColor: state.isFocused
        ? "#E9E9FF"
        : state.isDisabled
        ? "#F2F2F2"
        : "white",
      height: "2.5rem",
      borderWidth: "2px",
      borderRadius: "0.32rem",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid",
        borderColor: "#6f6cff ",
        borderWidth: "2px",
      },
    };
  },
  container: (provided: any, state: any) => {
    return {
      ...provided,

      // marginTop: 50,
      // width:
      //   state.selectProps.name === "upcomingEvents" && state.isFocused
      //     ? "100%"
      //     : state.selectProps.name === "tableSearchField"
      //     ? "100%"
      //     : "70%",
      // transition: "all 0.3s ease",
    };
  },
  option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      // ...base,
      // padding:'6px',
      // paddingLeft:'8px',
      // paddingRight:'8px'
      // backgroundColor: isFocused ? "#E9E9FF" : "white",
      // color: isFocused ? "black" : "black",
      // "&:hover": {
      //   color: "black",
      // },
    };
  },
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      overflow: "visible",
    };
  },
  placeholder: (base: any) => {
    if (isMobileView) {
      return {
        ...base,
        pointerEvents: "none",
        userSelect: "none",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
      };
    } else {
      return base;
    }
  },
  input: (base: any) => {
    if (isMobileView) {
      return {
        ...base,
        gridTemplateColumns: "0 minmax(min-content, 1fr)",
      };
    } else {
      return base;
    }
  },
};

const Control = ({ children, ...props }: ControlProps<any, false>) => {
  // @ts-ignore
  const { onButtonClick } = props.selectProps;
  const layout = useContext(AddInventoryContext);
  const isForTable: any = undefined;
  return (
    <components.Control {...props} className="pl-2">
      {/* Search icon button */}
      {!props.isDisabled && (
        <>
          <Tooltip
            anchorId={`${
              props.selectProps.inputValue ||
              _.size(props.selectProps?.value) > 0
                ? "clear_btn"
                : "search_btn"
            }`}
            content={`${
              props.selectProps.inputValue ||
              _.size(props.selectProps?.value) > 0
                ? "Clear"
                : "Search"
            }`}
            place="top"
            variant="light"
            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
          />
          <button
            className={`search_btn transition-all ${
              props.selectProps.inputValue ||
              _.size(props.selectProps?.value) > 0
                ? "opacity-0 invisible"
                : "active opacity-100 "
            }`}
            type="button"
            onClick={(e: any) =>
              props.selectProps.inputValue ? onButtonClick(e) : null
            }
            onMouseDown={(e: any) =>
              !props.selectProps.inputValue && onButtonClick(e)
            }
            onTouchStart={(e: any) =>
              !props.selectProps.inputValue && onButtonClick(e)
            }
            id={"search_btn"}
          >
            <SVG
              className="w-3.5 fill-violet-500"
              src={searchIcon}
              id={"search_btn"}
            />
          </button>

          <button
            className={`group search_btn cross_btn transition-all absolute top-0 left-0 w-[1.875rem] h-full z-10 ${
              props.selectProps.inputValue ||
              _.size(props.selectProps?.value) > 0
                ? "active opacity-100 transition-all"
                : "opacity-0 invisible"
            }`}
            type="button"
            onClick={(e: any) =>
              props.selectProps.inputValue ? onButtonClick(e) : null
            }
            onMouseDown={(e: any) =>
              !props.selectProps.inputValue && onButtonClick(e)
            }
            onTouchStart={(e: any) =>
              !props.selectProps.inputValue && onButtonClick(e)
            }
            id={"clear_btn"}
          >
            <SVG
              className="w-2.5 fill-violet-500 stroke-violet-500 group-hover:stroke-indigo-500 m-auto"
              src={crossIcon}
              id={"clear_btn"}
            />
          </button>
        </>
      )}

      {/* Search icon button End */}
      {children}
      {/* Start Toggle button */}
      {props.selectProps.name === "upcomingEvents" && (
        <div
          className={`w-auto inline-flex flex-row-reverse p-0.5 mr-0.5 mb-3 !my-0 border border-indigo-500/20 rounded bg-white ${
            props.selectProps.name === "upcomingEvents" && props.isFocused
              ? ""
              : "visually-hidden"
          } ${isForTable && "visually-hidden"}`}
          onMouseDown={onButtonClick}
          onTouchStart={onButtonClick}
        >
          <div className="relative flex items-center sm:flex-0 flex-auto">
            <input
              id="search-radio-2"
              type="radio"
              name="search-radio"
              className="absolute hidden peer"
              checked={
                layout.eventOrPerformer === "Performer/venue" ? true : false
              }
              readOnly
            />
            <label
              htmlFor="search-radio-2"
              className="py-0.5 px-1 w-full text-center rounded text-indigo-500 peer-checked:text-white peer-checked:bg-indigo-500 peer-checked:shadow transition text-xxs cursor-pointer"
            >
              {" "}
              Performer/venue
            </label>
          </div>
          <div className="relative flex items-center sm:flex-0 flex-auto">
            <input
              id="search-radio-1"
              type="radio"
              name="search-radio"
              className="absolute hidden peer"
              checked={layout.eventOrPerformer === "Event" ? true : false}
              readOnly
            />
            <label
              htmlFor="search-radio-1"
              className="py-0.5 px-1 w-full text-center rounded text-indigo-500 peer-checked:text-white peer-checked:bg-indigo-500  peer-checked:shadow transition text-xxs cursor-pointer"
            >
              {" "}
              Event
            </label>
          </div>
        </div>
      )}

      {/* ---------------------------- End Toggle code */}
    </components.Control>
  );
};

// GROUPING HEAD
const GroupHeading = (props: any) => {
  return (
    <div className="text-xs pl-1 font-semibold mb-2">{props.data.label}</div>
  );
};

// LOADING MESSAGE COMPONENT
const LoadingMessage = (props: NoticeProps<any, false>) => {
  return (
    <IKTSVG svgClassName="fill-indigo-500 h-4 w-4 center" path={SpinnerSVG} />
  );
};

// NO OPTIPON MESSAGE COMPONENT
const NoOptionsMessage = (props: any) => {
  return (
    props?.inputValue?.length >= 0 && (
      <>
        <div className="bg-violet-300 h-20 flex justify-center flex-col">
          <div className="text-black text-sm15">No result</div>
          <div>
            <button
              type="button"
              className="focus:outline-none text-white bg-violet-500 hover:bg-violet-900 focus:ring-violet-700 
              font-medium  text-sm13 px-2 py-0.5 rounded"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              Request an event
            </button>
          </div>
        </div>
      </>
    )
  );
};
const SearchField = ({
  inputRef,
  name,
  placeholder,
  classNamePrefix,
  handleOnChange,
  onClick,
  isForTable = undefined,
  className,
  value,
  options,
  onInputChange,
  inputValue,
  onFocus,
  onBlur,
  isLoading = false,
  isDisabled = false,
  onMenuScrollToBottom,
  list,
}: any) => {
  const ref: any = useRef();
  const layout = useContext(AddInventoryContext);
  const [algoliaOption, setAlgoliaOption] = useState<any>();
  const [loadMoreOptions, setLoadMoreOptions] = useState<any>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const [inputValueAsProp, setInputValueAsProp] = useState();

  // MENU LIST COMPONENT
  const CustomMenuList = ({ innerRef, ...props }: any) => {
    const { onButtonClick } = props.selectProps;
    if (Array.isArray(props.children) && layout.eventOrPerformer === "Event") {
      return (
        <components.MenuList
          {...props}
          className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
        >
          <div className="rounded-b-sm">
            {/* {name === "upcomingEvents" && (
              <span className="text-xs pl-1 font-semibold block mb-3">
                Upcoming events
              </span>
            )} */}
            {props.children}
            {layout.eventOrPerformer === "Event" &&
              props?.options?.length > 0 &&
              props?.options?.length < 20 &&
              inputValue &&
              options?.[0]?.nbPages > 2 && (
                <button
                  type="button"
                  className=" pl-1 hover:underline hover:decoration-indigo-500 font-semibold"
                  onClick={(e) => {
                    onButtonClick(e);
                  }}
                >
                  <span className="text-xs text-indigo-500" id="bottom">
                    {`View all results for ${inputValue}`}
                  </span>
                </button>
              )}
          </div>
        </components.MenuList>
      );
    } else {
      return (
        <components.MenuList
          {...props}
          className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
        >
          {props.children}
        </components.MenuList>
      );
    }
  };
  // ALGOLIA API DATA
  useEffect(() => {
    if (options && options.length > 0) {
      if (name === "tableSearchField") {
        setInputValueAsProp(inputValue);
        setAlgoliaOption((current: any) => {
          if (inputValue === inputValueAsProp) {
            return options[0]?.hits;
            // if (current && current?.length > 0) {
            //   return [...current, ...options[0]?.hits];
            // } else {
            //   return options[0]?.hits;
            // }
          } else {
            setLoadMoreOptions("");
            setAlgoliaOption((current: any) => {
              return options[0]?.hits;
            });
          }
        });
      } else {
        if (layout.eventOrPerformer === "Event") {
          setInputValueAsProp(inputValue);
          setAlgoliaOption((current: any) => {
            if (inputValue === inputValueAsProp) {
              if (current && current?.length > 0) {
                // return _.uniqBy([...current, ...options[0]?.hits], "id");
                return options[0]?.hits;
              } else {
                return options[0]?.hits;
              }
            } else {
              setLoadMoreOptions("");
              setAlgoliaOption((current: any) => {
                return options[0]?.hits;
              });
            }
          });
        } else if (
          layout.eventOrPerformer === "Performer/venue" &&
          options?.[1]?.hits
        ) {
          setAlgoliaOption([
            {
              label: "Performers",
              options:
                layout.eventOrPerformer === "Performer/venue" &&
                options?.[1]?.hits,
            },
            {
              label: "Venues",
              options:
                layout.eventOrPerformer === "Performer/venue" &&
                options?.[2]?.hits?.map((hit: any) => {
                  return {
                    ...hit,
                    name: `${hit?.name}${
                      hit?.city != "" ? `, ${hit.city}` : ""
                    }`,
                  };
                }),
            },
          ]);
        }
      }
    }
  }, [layout.eventOrPerformer, options, name, inputValue, inputValueAsProp]);

  const handleInputChange = (data: any, event: any) => {
    onInputChange(data, event);
  };

  const onButtonClick = (e: any, ref: any) => {
    onClick(e, ref);
    if (e?.target?.id !== "search_btn" && e?.target?.id !== "") {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default behavior of selecting the option
    }
  };

  return (
    <>
      <Select
        // @ts-ignore
        ref={ref}
        // @ts-ignore
        onButtonClick={(e: any) => onButtonClick(e, ref)}
        components={{
          Control,
          Option: Options,
          //NoOptionsMessage,
          MenuList: CustomMenuList,
          GroupHeading,
          LoadingMessage,
        }}
        isSearchable
        onChange={(data: any, event: any) => {
          let target = { ...data };
          data && setIsFocused(false);
          handleOnChange(target, event);
        }}
        onKeyDown={handleKeyDown}
        onInputChange={handleInputChange}
        // inputValue={inputValue}
        getOptionLabel={(option: any) => option.name}
        getOptionValue={(option: any) => option.id}
        value={value ? value : null}
        placeholder={placeholder}
        className={`searchbar-wrap ${isFocused && "menu_open"} rounded
        ${
          name === "upcomingEvents" && checkIfClonetoNewActive(list)
            ? "cloneDisabled"
            : ""
        }
        `}
        onMenuClose={() => {
          setIsFocused(false);
          setLoadMoreOptions("");
        }}
        onMenuOpen={() => setIsFocused(true)}
        onMenuScrollToBottom={(event: any) => {
          onMenuScrollToBottom(true);
        }}
        noOptionsMessage={NoOptionsMessage}
        menuPortalTarget={document.body}
        name={name}
        isDisabled={isDisabled}
        // onFocus={onFocus}
        onBlur={onBlur}
        onFocus={() => ref.current.onInputChange()}
        // onBlur={() => setIsFocused(false)}
        // options={getOptions() !== false && getOptions()}
        options={algoliaOption}
        styles={customStyle}
        isClearable={true}
        classNamePrefix={classNamePrefix}
        isLoading={isLoading}
        filterOption={null}
        // menuIsOpen={true}
        menuShouldScrollIntoView={false}
        menuPlacement="auto"
        openMenuOnClick={true}
      />
    </>
  );
};

export default SearchField;
