/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import backToTOp from "../../../assets/media/icons/other_icons/backtotop.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { scrollToTop } from "../../../helpers/Functions";
import { TxAccountContext } from "../core/TxAccountProvider";
import CommanFooter from "./CommanFooter";
import OrderFooter from "./OrderFooter";
import WithdrawFooter from "./WithdrawFooter";

const Footer = () => {
  const layout = useContext(TxAccountContext);
  let commonConfirmation = layout.commonConfirmation;
  const tab = layout.isTabActive;
  const [isVisible, setIsVisible] = useState(false);

  // Function to show/hide the button based on scroll position
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <footer className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white shadow-3xl z-20 min-h-[3.125rem]">
        {/* BACK TO TOP */}
        <button
          type="button"
          className={`bg-violet-550 hover:bg-indigo-500 rounded-l w-[1.875rem] h-[1.875rem] items-center justify-center flex fixed bottom-[4.0625rem] right-0 transition duration-300 ${
            isVisible ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
          id="backtotop"
          onClick={scrollToTop}
        >
          <IKTSVG path={backToTOp} svgClassName="w-[.9375rem] h-[.9375rem]" />
        </button>

        <div className={`flex w-full overflow-auto`}>
          {tab === "Orders" ? (
            <OrderFooter
              commonConfirmation={commonConfirmation}
              setCommonConfirmation={layout?.setCommonConfirmation}
            />
          ) : tab === "Withdraw requests" ? (
            <WithdrawFooter />
          ) : (
            <CommanFooter
              commonConfirmation={commonConfirmation}
              setCommonConfirmation={layout?.setCommonConfirmation}
            />
          )}
        </div>
      </footer>
    </>
  );
};

export default Footer;
