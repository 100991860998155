import _, { size } from "lodash";
import moment from "moment";
import { showAlert } from "../../../helpers/ShowAlert";

// HANDLE ERROR
export const handleError = (response: any) => {
  showAlert(`${response.status}`, true);
};

export const getHeaders = (headers: any) => {
  return headers?.reduce((acc: any, header: any) => {
    if (header.value !== "") {
      acc[header.name] = header.value;
    }
    return acc;
  }, {});
};

export function removeTrailingSlash(url: string) {
  if (url.endsWith("/")) {
    return url.slice(0, -1);
  }
  return url;
}

export function getValueByName(data: any, name: string) {
  const item = data?.find((el: any) => el.name === name);
  return item ? item.value : "";
}

export const replacePatterns = (dataArray: any, inputValue: string) => {
  const regex = /\{\{(.+?)\}\}/g;

  return inputValue.replace(regex, (_: any, name: string) => {
    const value = getValueByName(dataArray, name);
    console.log(name, value);
    return value ? value : "''";
  });
};

export function mapVariables(response_mapped_variables: any, obj: any) {
  let result: any = {};
  function getValue(obj: any, keys: string): any {
    try {
      return keys.split(".").reduce((acc, key) => acc[key], obj);
    } catch (error) {
      return undefined;
    }
  }

  for (let key in response_mapped_variables) {
    let keyToMatch = response_mapped_variables[key];
    if (keyToMatch?.includes(".")) {
      let value = getValue(obj, keyToMatch);
      if (value !== undefined) {
        result[key] = value;
      }
    } else {
      if (keyToMatch in obj) {
        result[key] = obj[keyToMatch];
      }
    }
  }
  return result;
}

export const convertIntoFormatDate = (date: any, format = "DD/MM/YYYY") => {
  if (!date) return date;
  return moment(date).format(format);
};

export const formatStartEndDate = (date: any) => {
  if (!date?.startDate || !date?.endDate) return date;

  return {
    event_start_date: convertIntoFormatDate(date?.startDate, "YYYY-MM-DD"),
    event_end_date: convertIntoFormatDate(date?.endDate, "YYYY-MM-DD"),
  };
};

export const mapOrderDataToFormData = (params: any) => {
  let { order_by = null, sort_order = null } = params?.sorting;

  let event_id: any = params?.filters?.event_id?.id
    ? [params?.filters?.event_id?.id]
    : _.map(params?.filters?.event_id, "id");

  let eventDateRange = formatStartEndDate(params?.filters?.eventDateRange);
  let orderDateRange = formatStartEndDate(params?.filters?.orderDateRange);

  let { team_member, keywords } = params?.filters;

  const payload = {
    reseller_id: params?.id,
    page: params?.filters.page,
    per_page: params?.filters.per_page,
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && { sort_order }),
    ...(size(event_id) > 0 && { event_id }),
    ...(eventDateRange?.event_start_date && {
      event_start_date: eventDateRange?.event_start_date,
    }),
    ...(eventDateRange?.event_end_date && {
      event_end_date: eventDateRange?.event_end_date,
    }),
    ...(orderDateRange?.event_start_date && {
      order_start_date: eventDateRange?.event_start_date,
    }),
    ...(orderDateRange?.event_end_date && {
      order_end_date: eventDateRange?.event_end_date,
    }),

    ...(team_member && size(team_member) > 0
      ? { team_member: _.map(team_member, "id") }
      : { team_member: "all" }),

    ...(keywords && { keywords }),
  };

  return payload;
};

export const getConnectedExchange = (allowedAccess: any = {}) => {
  if (
    allowedAccess?.is_connected_to_preferred_partners === 0 &&
    Array.isArray(allowedAccess?.connected_exchanges) &&
    size(allowedAccess?.connected_exchanges) > 0
  ) {
    return allowedAccess?.connected_exchanges;
  }

  return "";
};
