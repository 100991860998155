import React, { useContext } from "react";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";

const FilterChips = ({ loading }: any) => {
  const layout: any = useContext(SellerLevelsProviderContex);
  const count = layout.sellerLevelData?.count;

  return (
    <div
      className="flex flex-wrap customStyle relative bg-white  border-t max-sm:justify-between"
      id={`salesFilter`}
    >
      <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
        {/* TOTAL RESULT */}
        <div className={`inline-block rounded`}>
          <span className={`${loading && "shimmer-effect"} block`}>
            <span className={`${loading && "opacity-0"}`}>
              {count ? count : 0} Levels
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FilterChips;
