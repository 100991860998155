import stubhub from "../../../assets/media/icons/other_icons/Stubhub.svg";
import uploadIcon from "../../../assets/media/icons/other_icons/Upload.svg";
import checkIcon from "../../../assets/media/icons/other_icons/check.svg";
import handIcon from "../../../assets/media/icons/other_icons/hand-icon.svg";
import ticketped from "../../../assets/media/icons/other_icons/ticketped.svg";
import ticombo from "../../../assets/media/icons/other_icons/ticombo.svg";
import txnetwork from "../../../assets/media/icons/other_icons/tx-network.svg";
import Cross from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
const ListingQualityPopup = ({ isOpen, onClose }: any) => {
  return (
    <div
      className={`${
        isOpen ? "show" : ""
      } offcanvas fill_canvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  w-full upload-ticket-popup z-40`}
    >
      <div className="h-full md:max-w-[43.125rem]  ml-auto">
        <div className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
          <div className="offcanvas-header flex items-center justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal font-semibold py-2 px-5 text-sm15"
              id="offcanvasRightLabel"
            >
              Listing Quality
            </h5>
            <button
              type="button"
              className="flex items-center justify-center h-10 w-10 border-l box-content group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                onClose(false);
              }}
            >
              {/* <span className="btn-close w-3 h-3 text-black hover:text-indigo-500"></span> */}
              <KTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path="standard_icons/cross.svg"
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>

          {/* MAIN FORM */}
          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:p-[1.875rem] p-5 mb-1 text-gray-600">
            <div className="top-content text-sm13 text-gray-600">
              <p className="mb-5">
                Tixstock partners with a vast global network, granting access to
                our complete events inventory. Each partner maintains control of
                the listings they display to their customers, including factors
                such as event type, seller fulfillment levels and immediate
                download of tickets. This prompts questions from Tixstock
                sellers like, “Are my listings visible across all channels?” and
                “Which listings need attention?”.
              </p>
              <div className="logos flex items-center justify-center gap-2.5 my-[1.875rem] flex-wrap">
                <img src={stubhub} className="w-[3.625rem]" />
                <img src={ticombo} className="w-[3.75rem]" />
                <img src={ticketped} className="w-[5.625rem]" />
                <img src={txnetwork} className="w-[6.875rem]" />
                <span className="text-sm13 text-violet-500">+20 more</span>
              </div>
              <p className="mb-5">
                To ensure maximum visibility on the Tixstock Network, we’ve
                developed a system to determine Listing Quality. This system
                assesses the quality of a listing based on event type and ticket
                details presented to consumers. Refer to the information below
                and tooltips on the Add Inventory keyboard to optimise your
                listings and enhance your chances of selling quickly on the
                Tixstock Network.
              </p>
            </div>
            <div className="meter_info mt-[1.875rem] rounded-xl">
              <h2 className="text-sm15 font-semibold leading-5 mb-5">
                How it works
              </h2>
              <div className="info_block gap-x-1.5 flex flex-nowrap border border-gray-400 rounded-lg p-1.5 bg-white text-center text-white md:text-sm13 text-sm11 mb-5">
                <span className="rounded-l bg-violet-500 text-white w-full md:min-w-[50%] min-w-[23%]  p-0.5">
                  Low
                </span>
                <span className="w-full bg-green-600 p-0.5">Level 1</span>
                <span className="w-full bg-green-600 p-0.5">Level 2</span>
                <span className="w-full bg-green-600 p-0.5">Level 3</span>
                <span className="w-full bg-green-600 p-0.5 rounded-r">
                  Level 4
                </span>
              </div>
              <div className="require_field text-sm13 md:p-5 p-3.5 md:pt-4 bg-gray-100/50 rounded-lg">
                <h2 className="text-sm15 font-semibold leading-5 mb-3.5">
                  Required fields
                </h2>
                <p className="font-semibold mb-1.5">
                  Ticket type + Quantity + Category + Section/block + Face value
                  + Proceed price
                </p>
                <p>
                  Listing Quality will increase as you complete the fields
                  required to list on the Tixstock Network.
                </p>
              </div>
              <div className="levels_info mt-5">
                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4">
                  <div className="title-level flex flex-wrap items-center mb-3">
                    <h2 className="leading-5 text-sm15 font-semibold">
                      Level 1
                    </h2>
                    <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white ml-5">
                      <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem] rounded-r-sm"></span>
                    </div>
                  </div>
                  <div className="level_content text-sm13">
                    <p className="mb-3">All required fields:</p>
                    <p className="font-semibold mb-3">Section/block</p>
                    <p className="mb-4">
                      ▪ Same as Category where block-specific options available
                    </p>
                    <div className="blocks -mx-2.5 flex flex-wrap">
                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full max-sm:mb-4">
                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                            Category
                          </label>
                          Longside Lower
                        </div>
                      </div>
                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full">
                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                            Section/block
                          </label>
                          Longside Lower
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5">
                  <div className="title-level flex flex-wrap items-center mb-3">
                    <h2 className="leading-5 text-sm15 font-semibold">
                      Level 2
                    </h2>
                    <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white ml-5">
                      <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem] rounded-r-sm"></span>
                    </div>
                  </div>
                  <div className="level_content text-sm13">
                    <p className="mb-3">All required fields:</p>
                    <p className="font-semibold mb-3">Section/block</p>
                    <p className="mb-4">
                      ▪ Different to Category where block-specific options
                      available
                    </p>
                    <div className="blocks -mx-2.5 flex flex-wrap">
                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full max-sm:mb-4">
                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                            Category
                          </label>
                          Longside Lower
                        </div>
                      </div>
                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full">
                        <div className="input_inner bg-white  border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                            Section/block
                          </label>
                          Block 111
                        </div>
                      </div>
                    </div>
                    <p className="mb-4 mt-3">
                      ▪ Same as Category where block-specific options
                      unavailable
                    </p>
                    <div className="blocks -mx-2.5 flex flex-wrap">
                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full max-sm:mb-4">
                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                            Category
                          </label>
                          Floor Standing
                        </div>
                      </div>
                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full">
                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                            Section/block
                          </label>
                          Floor Standing
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5">
                  <div className="title-level flex flex-wrap items-center mb-3">
                    <h2 className="leading-5 text-sm15 font-semibold">
                      Level 3
                    </h2>
                    <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white ml-5">
                      <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem] rounded-r-sm"></span>
                    </div>
                  </div>
                  <div className="level_content text-sm13">
                    <p className="mb-3">All Level 2 requirements plus:</p>
                    <p className="font-semibold mb-3 mt-4">Tickets in hand</p>

                    <div className="blocks -mx-2.5 flex flex-wrap">
                      <div className="input_block px-2.5 sm:w-1/2 w-full ">
                        <div className="input_inner bg-white border border-green-600 rounded relative text-sm12 text-green-600 p-1 min-h-[1.875rem] pl-[2.375rem] leading-4 flex items-center overflow-hidden justify-between">
                          <div className="icon_block bg-white absolute left-0 top-0 w-[1.875rem] h-full flex items-center justify-center border-r border-green-600">
                            <IKTSVG
                              path={handIcon}
                              svgClassName="fill-green-600 w-[.6875rem] h-3.5"
                            />
                          </div>
                          Tickets in hand
                          <div className="checkbox bg-green-500 w-[.8125rem] h-[.8125rem] flex items-center justify-center rounded-sm mr-1">
                            <IKTSVG
                              path={checkIcon}
                              svgClassName="fill-white w-[.4375rem]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3">
                      In-hand tickets must be delivered within 24 hours of a
                      confirmed sale - failure to meet this deadline can result
                      in cancellation and a penalty on your account.
                    </p>
                  </div>
                </div>

                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5">
                  <div className="title-level flex flex-wrap items-center mb-3">
                    <h2 className="leading-5 text-sm15 font-semibold">
                      Level 4
                    </h2>
                    <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white ml-5">
                      <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem] rounded-r-sm"></span>
                    </div>
                  </div>

                  <div className="level_content text-sm13">
                    <p className="mb-3">All required fields plus:</p>
                    <p className="font-semibold mb-3 ">Upload tickets</p>

                    <div className="blocks -mx-2.5 flex flex-wrap">
                      <div className="input_block px-2.5 sm:w-1/2 w-full">
                        <div className="input_inner bg-white border border-green-600 rounded relative text-sm12 text-green-600 p-1 min-h-[1.875rem] pl-[2.375rem] leading-4 flex items-center overflow-hidden justify-between">
                          <div className="icon_block bg-white absolute left-0 top-0 w-[1.875rem] h-full flex items-center justify-center border-r border-green-600">
                            <IKTSVG
                              path={uploadIcon}
                              svgClassName="fill-green-600 w-[.8125rem]"
                            />
                          </div>
                          Tickets uploaded
                          <div className="checkbox w-[.8125rem] h-[.8125rem] flex items-center justify-center rounded-sm mr-1">
                            <IKTSVG
                              path={Cross}
                              svgClassName="fill-green-600 w-2 h-2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="mt-3">
                      Make tickets available for immediate download to reach
                      maximum Listing Quality.
                    </p>
                  </div>
                </div>

                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5">
                  <div className="title-level flex flex-wrap items-center mb-3">
                    <h2 className="leading-5 text-sm15 font-semibold">
                      Price suggestions
                    </h2>
                  </div>

                  <div className="level_content text-sm13">
                    <p className="mb-3">
                      Suggestions are provided on the Add Inventory keyboard and
                      within the Proceed price field.
                    </p>
                    <div className="meter_info flex flex-wrap flex-col mb-[.9375rem]">
                      <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white max-w-[8.75rem] mb-2.5">
                        <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-green-600 p-[.1875rem] rounded-r-sm"></span>
                      </div>
                      <p className="leading-4">
                        Green - Competitively priced and likely to sell
                      </p>
                    </div>
                    <div className="meter_info flex flex-wrap flex-col mb-[.9375rem]">
                      <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white max-w-[8.75rem] mb-2.5">
                        <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-orange-500 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-orange-500 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-orange-500 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-orange-500 p-[.1875rem] rounded-r-sm"></span>
                      </div>
                      <p className="leading-4">
                        Orange - More likely to sell at a lower price
                      </p>
                    </div>
                    <div className="meter_info flex flex-wrap flex-col mb-[.9375rem]">
                      <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white max-w-[8.75rem] mb-2.5">
                        <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-rose-500 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-rose-500 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-rose-500 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-rose-500 p-[.1875rem] rounded-r-sm"></span>
                      </div>
                      <p className="leading-4">
                        Red - Not likely to sell at this price
                      </p>
                    </div>
                    <div className="meter_info flex flex-wrap flex-col">
                      <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white max-w-[8.75rem] mb-2.5">
                        <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-sky-blue-500 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-sky-blue-500 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-sky-blue-500 p-[.1875rem]"></span>
                        <span className="w-[.9375rem] bg-sky-blue-500 p-[.1875rem] rounded-r-sm"></span>
                      </div>
                      <p className="leading-4">Blue - Price too low</p>
                    </div>
                  </div>
                </div>

                <div className="not_affect bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5 ">
                  <h2 className="leading-5 text-sm15 font-semibold">
                    The following fields do not affect Listing Quality levels
                  </h2>
                  <p className="mt-3 text-sm13">
                    Split type, Max display quantity, Row, First seat, Benefits,
                    Restrictions*
                  </p>
                  <p className="mt-2 text-sm13">
                    *Failure to disclose Restrictions can result in cancellation
                    and a penalty on your account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingQualityPopup;
