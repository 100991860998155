import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <div>
      <div className="main-container">
        <Outlet />
      </div>
      {/* <Footer />} */}
    </div>
  );
};

export default PublicLayout;
