import { useContext, useEffect, useState } from "react";
import JsonTreeViewer from "../../component/JsonTreeViewer";
import SubmitButton from "../../component/SubmitButton";
import { ConnectorContext } from "../../core/ConnectorProvider";
import ViewMapping from "./ViewMapping";

const ResponseScreen = ({ handleSubmit }: any) => {
  const layout = useContext(ConnectorContext);
  const state: any = layout?.state;
  const sideOption: any = layout?.sideOption;
  const { error, data } = state?.response;

  const [showMapping, setShowMapping] = useState<any>({
    show: false,
    data: "",
  });

  // RESIZING
  useEffect(() => {
    const responseviewdiv = document.getElementById(
      "responseviewdiv"
    ) as HTMLDivElement;
    const resizer = document.querySelector(".resizer") as HTMLDivElement;

    let y = 0;
    let height = 0;

    const onMouseDown = (e: MouseEvent) => {
      y = e.clientY;
      height = parseInt(window.getComputedStyle(responseviewdiv).height, 10);
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
      const targetElement = e.target as HTMLElement;

      if (targetElement?.classList.contains("resizer")) {
        document.body.style.userSelect = "none";
        responseviewdiv.style.userSelect = "none";
      }
    };

    const onMouseMove = (e: MouseEvent) => {
      const dy = e.clientY - y;
      responseviewdiv.style.height = `${height - dy}px`;
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      document.body.style.userSelect = ""; // Enable text selection
      responseviewdiv.style.userSelect = ""; // Enable text selection for responseviewdiv
    };

    // Add event listeners to both the resizer and responseviewdiv
    responseviewdiv.addEventListener("mousedown", onMouseDown);
    resizer.addEventListener("mousedown", onMouseDown);

    return () => {
      responseviewdiv.removeEventListener("mousedown", onMouseDown);
      resizer.removeEventListener("mousedown", onMouseDown);
    };
  }, []);

  return (
    <>
      <div
        className={`overflow-auto min-h-[56px] left-1 w-[99.5%] inventory-list  p-5 absolute bottom-0 z-[9999999] bg-white border `}
        id="responseviewdiv"
        style={{ height: data ? "200px" : "" }}
      >
        <div className="resizer"></div>

        <div
          className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
          role="tabpanel"
          data-te-tab-active
        >
          {/* {data && ( */}
          <div className="sticky top-0 right-0 flex justify-end p-2 gap-2">
            <button
              type="button"
              className="items-center z-10 text-white bg-violet-500 hover:bg-indigo-500 transition  rounded text-sm13 px-2.5 py-0.5 "
              onClick={(e: any) => {
                e.preventDefault();
                setShowMapping((showMapping: any) => {
                  return { ...showMapping, show: true };
                });
              }}
            >
              View Mapping
            </button>

            {/* SUBMIT BUTTON */}
            <SubmitButton
              handleSubmit={handleSubmit}
              disabled={
                sideOption === "Triggers" && !state?.name ? true : false
              }
            />
          </div>
          {/* )} */}
          <JsonTreeViewer data={state?.response?.data} error={error} />
        </div>
      </div>
      {showMapping?.show && <ViewMapping handleShow={setShowMapping} />}
    </>
  );
};

export default ResponseScreen;
