import { useContext } from "react";
import { LayoutContext } from "../core/LayoutProvider";

const NoJSXComponent = () => {
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  const conversation_rate = globalLayout?.conversationRate;
  const price_suggestion_factor =
    globalLayout?.allowedAccess?.price_suggestion_factor;
  let isMobile = globalLayout?.isMobile;
  const listingIds = globalLayout?.editListings;

  const priceFactor = Number(price_suggestion_factor);

  return {
    isGodAdmin,
    conversation_rate,
    price_suggestion_factor,
    isMobile,
    listingIds,
    priceFactor,
  };
};

export default NoJSXComponent;
