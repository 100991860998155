import React from "react";

interface ProgressBarLoader {
  LoadingText?: string;
  secondCounter?: any;
}

const ProgressBardLoader: React.FC<ProgressBarLoader> = ({
  LoadingText,
  secondCounter,
}: any) => {
  return (
    <div className="page-loader fixed top-0 left-[60px] max-md:left-0 max-md:w-full w-[calc(100%-3.75rem)] h-screen flex items-center justify-center z-[99999]">
      <div className="loader_wrap max-w-[15.875rem] w-full bg-white p-[.9375rem] pt-[1.5625rem] text-center shadow-3xl rounded">
        <div className="loader-strip bg-gray-200 rounded w-full h-2 mb-[.9375rem]">
          <div
            className="load-line"
            style={{
              animation: ` loaderAnim ${secondCounter}s linear forwards`,
            }}
          ></div>
        </div>
        {LoadingText && (
          <div className="loading-text text-sm14 leading-4 ">{LoadingText}</div>
        )}
      </div>
    </div>
  );
};

export default ProgressBardLoader;
