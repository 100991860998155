/* eslint-disable react/jsx-pascal-case */
import _, { find, isArray, size } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import close from "../../../../assets/media/icons/other_icons/Clear.svg";
import PPPIcon from "../../../../assets/media/icons/other_icons/PPPIcon.svg";
import handIcon from "../../../../assets/media/icons/other_icons/hand-icon.svg";
import FormDatePickerField from "../../../../components/formComponent/FormDatePickerField";

import Warning1 from "../../../../assets/media/icons/standard_icons/warning1.svg";
import Checkbox from "../../../../components/formComponent/Checkbox";
import { default as PriceField } from "../../../../components/formComponent/PriceField";

import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { deliveryTypeOrders } from "../../../../helpers/const";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";

import Messages from "helpers/Messages";
import { Tooltip } from "react-tooltip";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import DropDownField from "../../../../components/formComponent/DropDownField";
import MultiDropDownField from "../../../../components/formComponent/MultiDropDownField";
import PPEComponent from "../../../../components/formComponent/PPEComponent";
import Textfield from "../../../../components/formComponent/Textfield";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import {
  decimalNumber,
  handleKeyDown,
  removeCommaFromNumber,
  removeSelectAll,
  resetPPEFields,
  uniqueByNameWithPriority,
  updatePPEPartners,
} from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import { TicketError } from "../../../add_inventory/core/_functions";
import { InventoryContext } from "../../core/InventoryProvider";
import {
  setEventRecordPayload,
  visibilityMeterCalculationForInventory,
} from "../../core/_functions";
import { UpdateInventory } from "../../core/_request";

const EditInventoryMobileView = ({ setEditView, editView, setList }: any) => {
  const layout = useContext(InventoryContext);
  const globalLayout = useContext(LayoutContext);
  let isMobile = globalLayout?.isMobile;
  const [editViewData, setEditViewData] = useState<any>();
  const [loader, setLoader] = useState<any>(false);
  let eventRecordData: any = editViewData?.eventRecordData || {};
  let benefitValue = eventRecordData?.benefits?.[0]?.id
    ? eventRecordData?.benefits
    : layout?.benefits.filter((benefit: any) =>
        eventRecordData?.benefits?.includes(benefit.id)
      );
  let restrictionValue =
    eventRecordData &&
    eventRecordData?.restrictions &&
    eventRecordData?.restrictions[0]?.id
      ? eventRecordData?.restrictions
      : layout?.restrictions.filter((restriction: any) =>
          eventRecordData?.restrictions?.includes(restriction.id)
        );
  let event: any = editViewData?.event || {};
  const closePopupView = () => {
    const element = document.getElementById("filtersMob1");
    document.body.classList.remove("overflow-hidden-custom");
    if (element) {
      element.classList.remove("activeFIlter");
    }
    setEditView({
      show: false,
    });
  };

  let ticketTypeValue: any;

  if (typeof eventRecordData?.ticket_type === "object") {
    if (
      eventRecordData?.ticket_type === "eticket" &&
      eventRecordData?.sub_ticket_type === "pdf"
    ) {
      ticketTypeValue = {
        id: "pdf",
        name: "PDF E-Ticket",
      };
    } else if (
      eventRecordData?.ticket_type === "eticket" &&
      eventRecordData?.sub_ticket_type === "mobile-qr"
    ) {
      ticketTypeValue = {
        id: "mobile-qr",
        name: "Mobile QR",
      };
    } else {
      ticketTypeValue = eventRecordData?.ticket_type;
    }
  } else if (Array.isArray(layout.ticketType)) {
    let subTicket = !eventRecordData?.sub_ticket_type?.trim()
      ? "pdf"
      : eventRecordData?.sub_ticket_type;
    const foundTicket = layout.ticketType.find((ticket: any) => {
      if (eventRecordData?.ticket_type === "eticket") {
        return ticket?.id === subTicket;
      } else {
        return ticket?.id === eventRecordData?.ticket_type;
      }
    });

    ticketTypeValue = foundTicket;
  }

  let paperTypeExist: any;

  (eventRecordData?.ticket_type?.id === "paper" ||
    eventRecordData?.ticket_type == "paper") &&
    (paperTypeExist = true);

  let seletedDeliveryTypes: any = [];

  seletedDeliveryTypes =
    eventRecordData?.delivery_options?.length > 0
      ? [
          ...seletedDeliveryTypes,
          ...eventRecordData?.delivery_options?.map(
            (dT: any) => _.find(layout.deliveryType, { id: dT.id })?.type
          ),
        ]
      : [];

  seletedDeliveryTypes = _.uniq(seletedDeliveryTypes);

  let splitTypeValue: any =
    typeof eventRecordData?.split_type === "object"
      ? eventRecordData?.split_type
      : Array.isArray(layout.splitType) &&
        layout.splitType.find(
          (split: any) => split.id === eventRecordData?.split_type
        );

  let splitCondition: any =
    (typeof eventRecordData?.split_type === "string" &&
      eventRecordData?.split_type !== "MULTIPLES") ||
    (typeof eventRecordData?.split_type === "object" &&
      eventRecordData?.split_type?.id !== "MULTIPLES");

  // const updatePPEPartners = (
  //   ppePartners: any[],
  //   id: any,
  //   value: any,
  //   type: any
  // ) => {
  //   let partnerExists = false;

  //   const updatedPartners: any = ppePartners
  //     ? ppePartners.map((partner) => {
  //         if (partner.id === id) {
  //           partnerExists = true;
  //           return { ...partner, value: value };
  //         }
  //         return partner;
  //       })
  //     : [];

  //   if (!partnerExists) {
  //     updatedPartners.push({ id: id, value: value });
  //   }

  //   let minValue = Number.MAX_VALUE;
  //   let minValueSymbol = "";

  //   updatedPartners.forEach((partner: any) => {
  //     const partnerValue = Number(partner?.value?.value);

  //     if (!partner.is_hidden && !isNaN(partnerValue)) {
  //       if (partnerValue < minValue) {
  //         minValue = partnerValue;
  //         minValueSymbol = partner?.value?.symbol;
  //       }
  //     }
  //   });

  //   const arrayWithTxtradePrice = updatedPartners.map((partner: any) => {
  //     if (partner.is_hidden === true) {
  //       return {
  //         ...partner,
  //         value: {
  //           ...partner.value,
  //           value: minValue,
  //           symbol: minValueSymbol,
  //         },
  //       };
  //     }
  //     return partner;
  //   });

  //   if (type === "ppePartners") {
  //     return arrayWithTxtradePrice;
  //   } else {
  //     return arrayWithTxtradePrice?.map((partner: any) => {
  //       return {
  //         exchange_id: partner.exchange_id,
  //         sell_price_currency: partner?.value?.symbol,
  //         sell_price: partner?.value?.value,
  //         reseller_id: partner?.id,
  //         reseller_name: partner?.name,
  //       };
  //     });
  //   }
  // };

  // HANDLE ON CHANGE
  const handleOnChange = (data: any) => {
    const { target, parentName, id } = data;
    const { name, value, checked } = target;

    // UPDATE STATE
    setEditViewData((prevFormData: any) => {
      const newValue =
        parentName === "ppePartners"
          ? updatePPEPartners(
              prevFormData.eventRecordData["ppePartners"],
              id,
              value,
              "ppePartners",
              prevFormData?.sell_price
            )
          : name === "ticketsInHands" || name === "price_per_exchange"
          ? checked
          : value;

      // PREPARE THE KEY TO UPDATE
      const keyToUpdate = parentName === "ppePartners" ? "ppePartners" : name;

      const updatedEventRecordData = {
        ...prevFormData.eventRecordData,
        [keyToUpdate]: newValue,
        ...(name === "sell_price" &&
          (prevFormData.eventRecordData.price_per_exchange === 1 ||
            prevFormData.eventRecordData.price_per_exchange === true) && {
            ppePartners: updatePPEPartners(
              prevFormData.eventRecordData["ppePartners"],
              null,
              null,
              "ppePartners",
              value
            ),
            ticket_exchange_prices: updatePPEPartners(
              prevFormData.eventRecordData["ppePartners"],
              null,
              null,
              "ticket_exchange_prices",
              value
            ),
          }),
        ...(name === "sell_price" &&
          (prevFormData.eventRecordData.price_per_exchange === 0 ||
            prevFormData.eventRecordData.price_per_exchange === false) && {
            ppePartners: resetPPEFields(
              prevFormData.eventRecordData["ppePartners"],
              value,
              "ppePartners"
            ),

            ticket_exchange_prices: resetPPEFields(
              prevFormData.eventRecordData["ppePartners"],
              value,
              "ticket_exchange_prices"
            ),
          }),
      };

      if (parentName === "ppePartners") {
        updatedEventRecordData.ticket_exchange_prices = updatePPEPartners(
          updatedEventRecordData["ppePartners"],
          id,
          value,
          "ticket_exchange_prices",
          prevFormData?.sell_price
        );
      }

      return {
        ...prevFormData,
        eventRecordData: updatedEventRecordData,
      };
    });
  };

  const localValidationCheck = async (eventRecord: any) => {
    let fieldErrors: any = {};
    let success = true;

    const faceValueCheck =
      Number(eventRecord?.face_value) <= 0 ||
      (typeof eventRecord?.face_value === "object" &&
        Number(eventRecord?.face_value?.value) <= 0);

    const processValueCheck =
      Number(eventRecord?.sell_price) <= 0 ||
      (typeof eventRecord?.sell_price === "object" &&
        Number(eventRecord?.sell_price?.value) <= 0 &&
        !eventRecord?.ppe);

    const isHoldTicket =
      eventRecord?.hold_tickets?.length > 0 &&
      (eventRecord?.listing_ticket_files?.length > 0 ||
        eventRecord?.mobile_links?.length > 0) &&
      Number(eventRecord?.quantity_available) !==
        (Number(eventRecord?.listing_ticket_files?.length) ||
          Number(eventRecord?.mobile_links?.length));

    const isUploadedTicketLessWithQuantity =
      eventRecord?.quantity_available &&
      eventRecord?.quantity_available <
        (eventRecord?.listing_ticket_files?.length ||
          eventRecord?.mobile_links?.length);

    !eventRecord?.ticket_type &&
      (fieldErrors = {
        ...fieldErrors,
        ticket_type: "Ticket type field is required",
      });

    (eventRecord?.ticket_type === "paper" ||
      eventRecord?.ticket_type?.id === "paper") &&
      eventRecord?.delivery_options?.length > 0 &&
      eventRecord?.delivery_options?.map((singleDelivery: any) => {
        let price =
          eventRecord?.[singleDelivery?.id] != undefined
            ? Number(eventRecord?.[singleDelivery?.id])
            : Number(singleDelivery?.price);
        (!price || price <= 0) &&
          (fieldErrors = {
            ...fieldErrors,
            [singleDelivery?.id]:
              layout.deliveryType?.find(
                (item: any) => singleDelivery?.id === item?.id
              )?.type + " field is required more than zero",
          });
      });

    (!eventRecord?.quantity_available ||
      isUploadedTicketLessWithQuantity ||
      isHoldTicket) &&
      (fieldErrors = {
        ...fieldErrors,
        quantity_available:
          isUploadedTicketLessWithQuantity || isHoldTicket
            ? TicketError
            : "Quantity field is required",
      });

    !eventRecord?.category &&
      event?.categories &&
      Object.keys(event?.categories).length > 0 &&
      (fieldErrors = {
        ...fieldErrors,
        category: "Category value field is required",
      });

    (!eventRecord?.face_value ||
      (typeof eventRecord?.face_value === "object" &&
        eventRecord?.face_value?.value === "")) &&
      (fieldErrors = {
        ...fieldErrors,
        face_value: "Face value field is required",
      });

    //  new
    faceValueCheck &&
      (fieldErrors = {
        ...fieldErrors,
        face_value: "Face value field is required more than zero",
      });

    (!eventRecord?.sell_price ||
      (typeof eventRecord?.sell_price === "object" &&
        eventRecord?.sell_price?.value === "")) &&
      !eventRecord?.ppe &&
      (fieldErrors = {
        ...fieldErrors,
        sell_price: "Proceed value field is required",
      });

    // new

    processValueCheck &&
      (fieldErrors = {
        ...fieldErrors,
        sell_price: "Proceed value field is required more than zero",
      });

    !eventRecord?.split_type &&
      (fieldErrors = {
        ...fieldErrors,
        splitType: "Split Type value field is required",
      });
    !eventRecord?.MULTIPLES &&
      eventRecord?.splitType?.id === "MULTIPLES" &&
      (fieldErrors = {
        ...fieldErrors,
        MULTIPLES: "Sell in Multiples Field is required",
      });

    !eventRecord?.sub_ticket_type?.id &&
      eventRecord?.ticketType?.id === "eticket" &&
      (fieldErrors = {
        ...fieldErrors,
        sub_ticket_type: "Sub Ticket type Field is required",
      });

    if (eventRecord?.price_per_exchange) {
      if (Array.isArray(eventRecord?.ppePartners)) {
        eventRecord?.ppePartners?.forEach((partner: any) => {
          if (partner.value.value === "0.00" || partner.value.value === "0") {
            fieldErrors["PPP"] = `PPP fields are required more than zero`;
            fieldErrors[
              partner?.name
            ] = `${partner?.name} value field required more than zero`;
          }
        });
      }
    }

    Object.keys(fieldErrors).length > 0 && (success = false);

    let errors: any = [
      {
        errors: [
          ...(eventRecord?.errors && _.size(eventRecord?.errors) > 0
            ? eventRecord?.errors?.[0]?.errors
            : []),
          ...(fieldErrors && _.size(fieldErrors) > 0
            ? Object.keys(fieldErrors)
            : []),
        ],
        exchange: eventRecord?.errors?.[0]?.exchange,
      },
    ];
    setEditViewData((pre: any) => {
      return {
        ...pre,
        eventRecordData: {
          ...pre?.eventRecordData,
          fieldErrors: fieldErrors,
          ...(_.size(errors?.[0]?.errors) > 0 && {
            errors: errors,
          }),
        },
      };
    });
    return success;
  };

  const updateRecord = async (eventRecord: any, onlyUpdate: any) => {
    //CHECK LOCAL VALIDATION
    if (!(await localValidationCheck(eventRecord))) {
      return true;
    } else {
      setLoader(true);
      setTimeout(() => {
        eventRecord.status = onlyUpdate
          ? eventRecord?.status
          : eventRecord?.status === "no"
          ? "yes"
          : "no";

        UpdateInventory(setEventRecordPayload(event, { ...eventRecord })).then(
          (response: any) => {
            setLoader(false);
            let responseData = response?.data?.payload?.post_data;
            responseData && responseData.status
              ? showAlert(response.message, false)
              : showAlert(Object.values(response?.errors)?.toString(), true);
            if (response?.data?.status || size(response?.errors) > 0) {
              setList((prevList: any) => {
                return prevList?.map((obj: any) => {
                  if (obj?.id === event?.id) {
                    return {
                      ...obj,
                      eventRecords: obj.eventRecords?.map((innerObj: any) => {
                        if (innerObj?.id === eventRecordData?.id) {
                          delete eventRecordData?.fieldErrors;
                          let visibility: any =
                            visibilityMeterCalculationForInventory(
                              event,
                              { ...eventRecordData, isInputChange: true },
                              layout.ticketType
                            );
                          return {
                            ...eventRecordData,
                            errors: [],
                            visibility: visibility,
                            isInputChange: false,
                            listing_ticket_files:
                              response?.data?.payload?.is_ticket_type_change ===
                              1
                                ? {
                                    listing_ticket_files: [],
                                    ticketFiles: [],
                                    mobile_links: [],
                                  }
                                : {},
                          };
                        } else {
                          return innerObj;
                        }
                      }),
                    };
                  } else {
                    return obj;
                  }
                });
              });
              closePopupView();
            }
          }
        );
      }, 600);
    }
  };

  useEffect(() => {
    if (editView) {
      setEditViewData({
        eventRecordData: editView?.record,
        event: editView?.event,
      });
    }
  }, [editView]);

  const handleOnSelection = (
    data: any,
    option: any,
    filedName: any,
    visibleOptions: any,
    currentOptionsSelected: any
  ) => {
    let updatedOptions =
      visibleOptions && size(visibleOptions) > 0
        ? visibleOptions
        : data?.target?.id === "select-all"
        ? option
        : null;
    if (data?.target?.id === "select-all") {
      let target = {
        name: filedName,
        value: updatedOptions,
      };
      handleOnChange({ target });
    } else if (data?.target?.id === "deselect-all") {
      let target = {
        name: filedName,
        value: removeSelectAll(currentOptionsSelected, updatedOptions),
      };
      handleOnChange({ target });
    }
  };

  const isEventRecordHoldTickets =
    eventRecordData?.hold_tickets &&
    Object.keys(eventRecordData?.hold_tickets).length > 0;

  const isALLTicketUploadedWithPublish =
    (Number(eventRecordData?.listing_ticket_files?.length) > 0 ||
      Number(eventRecordData?.mobile_links?.length) > 0) &&
    Number(eventRecordData?.quantity_available) > 0 &&
    Number(eventRecordData?.quantity_available) ===
      (Number(eventRecordData?.listing_ticket_files?.length) ||
        Number(eventRecordData?.mobile_links?.length)) &&
    eventRecordData?.status === "yes";

  const disableMobilefield =
    (isEventRecordHoldTickets &&
      eventRecordData?.quantity_available <
        eventRecordData?.listing_ticket_files?.length) ||
    eventRecordData?.price_per_exchange;

  return (
    <div
      id="collapseOne"
      className={`accordion-collapse collapse ${isMobile && "show"} `}
      data-te-collapse-item
      data-te-collapse-show
      aria-labelledby="headingOne1"
    >
      {/* Keyboard Components */}
      <div
        className="filtersMob md:hidden max-md:flex max-md:flex-col editRow"
        id={"filtersMob1"}
      >
        <div
          className="closePopup hidden max-md:flex"
          id="closePopup"
          onClick={() => {
            closePopupView();
          }}
        >
          <IKTSVG
            path={close}
            className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
            svgClassName="w-4 h-4"
          />
        </div>
        <div
          className={`accordion-body -mx-[.3125rem] pt-4 pb-1 px-5 border-y max-md:pt-14 max-md:pb-16  max-md:flex-1 max-md:overflow-auto max-md:content-start  h-full`}
        >
          <div className="flex flex-wrap">
            <>
              <div
                className={`table-mobileview-drop   font-medium w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5  `}
              >
                <div>
                  <DropDownField
                    isCapital={true}
                    isValueCapital={true}
                    value={ticketTypeValue}
                    isClearable={false}
                    options={layout.ticketType}
                    placeholder={"Ticket type"}
                    name="ticket_type"
                    handleOnChange={(data: any) => {
                      handleOnChange(data);
                    }}
                    isDisabled={
                      isEventRecordHoldTickets || isALLTicketUploadedWithPublish
                    }
                    className={
                      eventRecordData?.fieldErrors?.ticket_type && "error-field"
                    }
                    errorEventIndex={eventRecordData?.fieldErrors?.ticket_type}
                    errorMessage={
                      eventRecordData?.fieldErrors?.ticket_type
                        ? eventRecordData?.fieldErrors?.ticket_type
                        : ""
                    }
                    dropDownWidth={"min-w-[unset] max-w-[unset] w-full"}
                  />
                </div>
              </div>

              {/* Deliverty Type */}
              {paperTypeExist && (
                <div
                  className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
                >
                  <>
                    <div>
                      <MultiDropDownField
                        name="delivery_options"
                        isCapital={true}
                        options={layout.deliveryType}
                        value={eventRecordData?.delivery_options}
                        classNamePrefix={"form_multiDropDown"}
                        placeholder={"Delivery type"}
                        handleOnChange={(data: any) => handleOnChange(data)}
                        getOptionLabel="type"
                        isDisabled={isEventRecordHoldTickets}
                      />{" "}
                    </div>
                  </>
                </div>
              )}

              {paperTypeExist &&
                isArray(seletedDeliveryTypes) &&
                seletedDeliveryTypes.length > 0 &&
                _.orderBy(seletedDeliveryTypes, deliveryTypeOrders, [
                  "asc",
                ]).map((selected: any) => {
                  let typeTemp = _.find(layout.deliveryType, {
                    type: selected,
                  });
                  let delivery = find(eventRecordData?.delivery_options, {
                    id: typeTemp?.id,
                  });
                  return delivery &&
                    eventRecordData?.hold_tickets.length === 0 ? (
                    <div
                      className={`w-full relative sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5`}
                    >
                      <div>
                        <div className="relative">
                          <Textfield
                            name={delivery?.id}
                            type="text"
                            label={selected}
                            isFocus={(data: any) => {}}
                            passFocus={(data: any) => {}}
                            handleOnChange={(e: any) => handleOnChange(e)}
                            value={
                              eventRecordData?.[delivery?.id] != undefined
                                ? eventRecordData?.[delivery?.id]
                                : Number(delivery?.price)
                                ? Number(delivery?.price)
                                : ""
                            }
                            textFielClassName={`truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5 ${
                              eventRecordData?.fieldErrors?.[delivery?.id] &&
                              "border-rose-500 focus:!border-rose-500  placeholder:text-rose-500"
                            }`}
                            // placeholder={selected}
                          />
                          {/* <input
                            type="text"
                            name={delivery?.id}
                            className={`truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5 ${
                              eventRecordData?.fieldErrors?.[delivery?.id] &&
                              "border-rose-500 focus:!border-rose-500  placeholder:text-rose-500"
                            }`}
                            value={
                              eventRecordData?.[delivery?.id] != undefined
                                ? eventRecordData?.[delivery?.id]
                                : Number(delivery?.price)
                                ? Number(delivery?.price)
                                : ""
                            }
                            placeholder={selected}
                            id={`${[delivery?.id]}`}
                            onChange={(event: any) => handleOnChange(event)}
                            onKeyDown={(e: any) => {}}
                          /> */}

                          {eventRecordData?.[delivery?.id]
                            ? ""
                            : eventRecordData?.fieldErrors?.[delivery?.id] && (
                                <>
                                  <TooltipPortal
                                    id={"delivery-type"}
                                    content={
                                      eventRecordData?.fieldErrors?.[
                                        delivery?.id
                                      ]
                                    }
                                    place="top"
                                    variant="light"
                                    className="text-center z-[99999] !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-20 max-w-[9rem] !text-rose-500"
                                  />
                                  <div
                                    data-tooltip-id={`delivery-type`}
                                    className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                                  >
                                    <IKTSVG
                                      className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                      path={Warning1}
                                    />
                                  </div>
                                </>
                              )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="p-1.5 font-medium">
                      <div className="relative">
                        <input
                          type="number"
                          className={`bg-gray-100 text-gray-400 placeholder:text-gray-400 placeholder:truncate pointer-events-none truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-none rounded focus:border-indigo-500 focus:bg-violet-300/50 px-2.5 123 `}
                          placeholder={selected}
                          disabled={true}
                          value={``}
                        />
                      </div>
                    </div>
                  );
                })}
            </>

            <div
              className={` w-full relative sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5`}
            >
              <Textfield
                name="quantity_available"
                type="number"
                label="Quantity"
                isFocus={(data: any) => {}}
                passFocus={(data: any) => {}}
                handleOnChange={(e: any) => handleOnChange(e)}
                value={eventRecordData?.quantity_available}
                textFielClassName={`truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5 ${
                  eventRecordData?.fieldErrors?.quantity_available &&
                  "border-rose-500 focus:border-rose-500 #{!important} placeholder:text-rose-500"
                }  `}
                onKeyDown={handleKeyDown}
                isDisabled={
                  isEventRecordHoldTickets || isALLTicketUploadedWithPublish
                }
              />

              {eventRecordData?.fieldErrors?.quantity_available && (
                <>
                  <TooltipPortal
                    id={`quantity-error`}
                    content={eventRecordData?.fieldErrors?.quantity_available}
                    className="text-center z-[99999] !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-20 max-w-[13.75rem] !text-rose-500"
                  />

                  <div
                    data-tooltip-id={`quantity-error`}
                    className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                  >
                    <IKTSVG
                      className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                      path={Warning1}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 ">
              <div>
                <Textfield
                  name="Sold"
                  type="number"
                  label="Sold"
                  isFocus={(data: any) => {}}
                  passFocus={(data: any) => {}}
                  handleOnChange={(e: any) => handleOnChange(e)}
                  value={eventRecordData?.quantity_sold}
                  textFielClassName={`bg-gray-100 truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                  isDisabled={true}
                />
                {/* <input
                  type="number"
                  className={`bg-gray-100 truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                  value={eventRecordData?.quantity_sold}
                  placeholder="Sold"
                  disabled={true}
                /> */}
              </div>
            </div>

            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 }`}
            >
              <div>
                <DropDownField
                  value={splitTypeValue}
                  isClearable={false}
                  options={layout.splitType}
                  placeholder={"Split type"}
                  name="split_type"
                  handleOnChange={(data: any) => handleOnChange(data)}
                  className={
                    eventRecordData?.fieldErrors?.splitType && "error-field"
                  }
                  isDisabled={
                    isEventRecordHoldTickets || isALLTicketUploadedWithPublish
                  }
                  errorEventIndex={eventRecordData?.fieldErrors?.splitType}
                  errorMessage={
                    eventRecordData?.fieldErrors?.splitType &&
                    eventRecordData?.fieldErrors?.splitType
                  }
                  dropDownWidth={"min-w-[unset] max-w-[unset] w-full"}
                />
              </div>
            </div>

            {!splitCondition && (
              <div
                className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 }`}
              >
                <div>
                  {isEventRecordHoldTickets ||
                  isALLTicketUploadedWithPublish ? (
                    <>
                      <input
                        type="text"
                        className={`bg-gray-100 truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                        value={eventRecordData?.split_quantity}
                        placeholder="Sell In Multiples"
                        disabled={true}
                      />{" "}
                    </>
                  ) : (
                    <Textfield
                      name="split_quantity"
                      type="text"
                      label="Sell In Multiples"
                      isFocus={(data: any) => {}}
                      passFocus={(data: any) => {}}
                      handleOnChange={(e: any) => handleOnChange(e)}
                      value={eventRecordData?.split_quantity}
                      textFielClassName={`${splitCondition}  truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                    />
                    // <input
                    //   type="text"
                    //   className={`${splitCondition}  truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                    //   value={eventRecordData?.split_quantity}
                    //   placeholder="Sell In Multiples"
                    //   name="split_quantity"
                    //   onKeyDown={(e: any) => {}}
                    //   onChange={(e: any) => handleOnChange(e)}
                    // />
                  )}
                </div>
              </div>
            )}

            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] min-w-[10.0625rem] px-[.313rem] mb-3.5 `}
            >
              <div>
                {isEventRecordHoldTickets || isALLTicketUploadedWithPublish ? (
                  <>
                    {" "}
                    <input
                      type="text"
                      className={`bg-gray-100 truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                      value={eventRecordData?.quantity_display}
                      placeholder="Max display quantity"
                      disabled={true}
                    />{" "}
                  </>
                ) : (
                  <Textfield
                    name="quantity_display"
                    type="text"
                    label="Max display quantity"
                    isFocus={(data: any) => {}}
                    passFocus={(data: any) => {}}
                    handleOnChange={(e: any) => handleOnChange(e)}
                    value={eventRecordData?.quantity_display}
                    textFielClassName={`truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                  />

                  // <input
                  //   type="text"
                  //   className={`    truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                  //   value={eventRecordData?.quantity_display}
                  //   placeholder="Max display quantity"
                  //   name="quantity_display"
                  //   onKeyDown={(e: any) => {}}
                  //   onChange={(e: any) => handleOnChange(e)}
                  //   // disabled={isEventRecordHoldTickets || isALLTicketUploadedWithPublish}
                  // />
                )}
              </div>
            </div>

            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
            >
              <div>
                <DropDownField
                  value={
                    eventRecordData?.category?.id
                      ? eventRecordData?.category
                      : event?.categories &&
                        event?.categories.find(
                          (category: any) =>
                            Number(category.id) ===
                            Number(eventRecordData?.category)
                        )
                  }
                  options={uniqueByNameWithPriority(
                    event?.categories,
                    eventRecordData?.category?.id
                      ? eventRecordData?.category?.id
                      : eventRecordData?.category
                  )}
                  placeholder={"Category"}
                  isCapital={true}
                  isValueCapital={true}
                  name="category"
                  handleOnChange={(data: any) => handleOnChange(data)}
                  isDisabled={
                    isEventRecordHoldTickets || isALLTicketUploadedWithPublish
                  }
                  className={
                    eventRecordData?.fieldErrors?.category && "error-field"
                  }
                  errorEventIndex={
                    eventRecordData?.fieldErrors?.category &&
                    `category-${eventRecordData?.id}`
                  }
                  errorMessage={
                    eventRecordData?.fieldErrors?.category
                      ? eventRecordData?.fieldErrors?.category
                      : ""
                  }
                  dropDownWidth={"min-w-[unset] max-w-[unset] w-full"}
                />
              </div>
            </div>

            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 proceed-price  `}
            >
              <div>
                <DropDownField
                  value={
                    eventRecordData?.section && eventRecordData?.section?.id
                      ? eventRecordData?.section
                      : eventRecordData?.section &&
                        event?.categories &&
                        Array.isArray(event?.categories) &&
                        event?.categories
                          .find(
                            (category: any) =>
                              Number(category.id) ===
                              Number(eventRecordData?.category)
                          )
                          ?.sections?.find(
                            (item: any) =>
                              Number(item?.id) ===
                              Number(eventRecordData?.section)
                          )
                  }
                  options={
                    eventRecordData?.category?.sections
                      ? uniqueByNameWithPriority(
                          eventRecordData?.category?.sections,
                          eventRecordData?.section?.id
                            ? eventRecordData?.section?.id
                            : eventRecordData?.section
                        )
                      : event?.categories &&
                        Array.isArray(event?.categories) &&
                        uniqueByNameWithPriority(
                          event?.categories.find(
                            (category: any) =>
                              Number(category.id) ===
                              Number(eventRecordData?.category)
                          )?.sections,
                          eventRecordData?.section?.id
                            ? eventRecordData?.section?.id
                            : eventRecordData?.section
                        )
                  }
                  placeholder={"Section/block"}
                  isCapital={true}
                  isValueCapital={true}
                  name="section"
                  handleOnChange={(data: any) => handleOnChange(data)}
                  isDisabled={
                    isEventRecordHoldTickets || isALLTicketUploadedWithPublish
                  }
                  className={
                    eventRecordData?.fieldErrors?.section && "error-field"
                  }
                  errorEventIndex={eventRecordData?.fieldErrors?.section}
                  errorMessage={
                    eventRecordData?.fieldErrors?.section
                      ? eventRecordData?.fieldErrors?.section
                      : ""
                  }
                  dropDownWidth={"min-w-[unset] max-w-[unset] w-full"}
                />
              </div>
            </div>

            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
            >
              <div>
                {isEventRecordHoldTickets || isALLTicketUploadedWithPublish ? (
                  <input
                    type="text"
                    className={`bg-gray-100 truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                    value={eventRecordData?.row && eventRecordData?.row}
                    placeholder="Row"
                    disabled={true}
                  />
                ) : (
                  <Textfield
                    name="row"
                    type="text"
                    label="Row"
                    isFocus={(data: any) => {}}
                    passFocus={(data: any) => {}}
                    handleOnChange={(e: any) => handleOnChange(e)}
                    value={eventRecordData?.row}
                    textFielClassName={`   h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                  />
                  // <input
                  //   type="text"
                  //   className={`   h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                  //   value={eventRecordData?.row && eventRecordData?.row}
                  //   placeholder="Row"
                  //   name="row"
                  //   onChange={(e: any) => handleOnChange(e)}
                  //   // disabled={isEventRecordHoldTickets || isALLTicketUploadedWithPublish}
                  // />
                )}
              </div>
            </div>

            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
            >
              <div>
                {isEventRecordHoldTickets || isALLTicketUploadedWithPublish ? (
                  <>
                    <input
                      type="text"
                      className={`bg-gray-100 truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                      value={eventRecordData?.first_seat}
                      placeholder="First seat"
                      disabled={true}
                    />
                  </>
                ) : (
                  <Textfield
                    name="first_seat"
                    type="text"
                    label="First seat"
                    isFocus={(data: any) => {}}
                    passFocus={(data: any) => {}}
                    handleOnChange={(e: any) => handleOnChange(e)}
                    value={eventRecordData?.first_seat}
                    textFielClassName={`   h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                  />
                  // <input
                  //   type="text"
                  //   className={`   h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                  //   value={eventRecordData?.first_seat}
                  //   placeholder="First seat"
                  //   name="first_seat"
                  //   onKeyDown={(e: any) => {}}
                  //   onChange={(e: any) => handleOnChange(e)}
                  //   // disabled={isEventRecordHoldTickets || isALLTicketUploadedWithPublish}
                  // />
                )}
              </div>
            </div>

            <div
              className={`w-full  sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 text-xs `}
            >
              <div>
                <PriceField
                  value={
                    typeof eventRecordData?.face_value === "object"
                      ? eventRecordData?.face_value?.value
                      : removeCommaFromNumber(eventRecordData?.face_value)
                  }
                  currenncySymbol={
                    eventRecordData?.face_value_currency
                      ? eventRecordData?.face_value_currency
                      : eventRecordData?.face_value?.symbol
                  }
                  textFielClassName={`truncate`}
                  name="face_value"
                  handleOnChange={(data: any) => handleOnChange(data)}
                  isDisabled={
                    isEventRecordHoldTickets || isALLTicketUploadedWithPublish
                  }
                  // tabTiming={saveDataInStateTimer}
                  errorClass={`${
                    eventRecordData?.fieldErrors?.face_value
                      ? eventRecordData?.fieldErrors?.face_value
                      : ""
                  }`}
                  errorMessage={`${
                    eventRecordData?.fieldErrors?.face_value
                      ? eventRecordData?.fieldErrors?.face_value
                      : ""
                  }`}
                  errorEventIndex={
                    eventRecordData?.fieldErrors?.face_value && 1
                  }
                  toolTipZindex={`z-[99999]`}
                  label={"Face Value"}
                  textfieldType={"number"}
                />
              </div>
            </div>

            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] proceed-price mb-3.5 ${
                disableMobilefield && "disableField"
              }`}
            >
              <div>
                <PriceField
                  value={
                    typeof eventRecordData?.sell_price === "object"
                      ? eventRecordData?.sell_price?.value
                      : removeCommaFromNumber(eventRecordData?.sell_price)
                  }
                  currenncySymbol={
                    eventRecordData?.sell_price_currency
                      ? eventRecordData?.sell_price_currency
                      : eventRecordData?.sell_price?.symbol
                  }
                  className={`${
                    eventRecordData?.errors?.sell_price &&
                    "focus:border-rose-500 border-rose-500 text-rose-500"
                  }`}
                  textFielClassName={`${
                    eventRecordData?.errors?.sell_price &&
                    "focus:border-rose-500 border-rose-500 text-rose-500"
                  } truncate`}
                  name="sell_price"
                  handleOnChange={(data: any) => handleOnChange(data)}
                  errorClass={`${
                    eventRecordData?.fieldErrors?.sell_price
                      ? eventRecordData?.fieldErrors?.sell_price
                      : ""
                  }`}
                  errorMessage={`${
                    eventRecordData?.fieldErrors?.sell_price
                      ? eventRecordData?.fieldErrors?.sell_price
                      : ""
                  }`}
                  errorEventIndex={
                    eventRecordData?.fieldErrors?.sell_price && 1
                  }
                  // isDisabled={isALLTicketUploadedWithPublish}
                  // handleOnFocus={(data: any) => {
                  //   focusOnPriceField(data, eIndex, rIndex);
                  // }}
                  // tabTiming={saveDataInStateTimer}
                  isPriceField={false}
                  fieldMessage={false}
                  isValidationPopupVisible={false}
                  isPriceTable={false}
                  toolTipZindex={`z-[99999]`}
                  label="Proceed Price"
                  textfieldType={"number"}
                  isDisabled={
                    (isEventRecordHoldTickets &&
                      eventRecordData?.quantity_available <
                        eventRecordData?.listing_ticket_files?.length) ||
                    eventRecordData?.price_per_exchange
                  }
                />
              </div>
            </div>

            {/* PPP BUTTON */}
            <div
              className={` ${
                isEventRecordHoldTickets && "disable_field pointer-events-none"
              }  inhand_keyboard w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 min-w-[11.5625rem]  `}
            >
              <Checkbox
                checked={eventRecordData?.price_per_exchange}
                label="Price per partner"
                name="price_per_exchange"
                id="price_per_exchange"
                WithIcon={true}
                Icon={PPPIcon}
                IconSize={"w-[.6875rem] h-[.9063rem]"}
                handleOnChange={(data: any) => handleOnChange(data)}
                AddinventoryHand={true}
                activeViolet={true}
              />
            </div>

            <div
              className={`font-medium w-full  sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5  dd_large`}
            >
              <div>
                <MultiDropDownField
                  name="benefits"
                  options={layout.benefits}
                  value={benefitValue}
                  classNamePrefix={"form_multiDropDown"}
                  placeholder={"Benefits"}
                  handleOnChange={(data: any) => handleOnChange(data)}
                  isDisabled={
                    isEventRecordHoldTickets || isALLTicketUploadedWithPublish
                  }
                  handleOnSelection={(data: any, visibleOptions: any) => {
                    handleOnSelection(
                      data,
                      layout.benefits,
                      "benefits",
                      visibleOptions,
                      benefitValue
                    );
                  }}
                  // multiple={true}
                />
              </div>
            </div>

            <div
              className={`font-medium w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5  dd_large`}
            >
              <div>
                <MultiDropDownField
                  name="restrictions"
                  options={layout.restrictions}
                  value={restrictionValue}
                  classNamePrefix={"form_multiDropDown"}
                  placeholder={"Restrictions"}
                  handleOnChange={(data: any) => handleOnChange(data)}
                  handleOnSelection={(data: any, visibleOptions: any) => {
                    handleOnSelection(
                      data,
                      layout.restrictions,
                      "restrictions",
                      visibleOptions,
                      restrictionValue
                    );
                  }}
                  isDisabled={
                    isEventRecordHoldTickets || isALLTicketUploadedWithPublish
                  }
                />
              </div>
            </div>

            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
            >
              <div>
                <div className="w-full ">
                  <FormDatePickerField
                    name="date_to_ship"
                    maxDate={event?.date ? new Date(event?.date) : null}
                    placeholder={`Date to ship`}
                    value={eventRecordData?.date_to_ship}
                    handleOnChange={(data: any) => {
                      if (data.target.value === "") {
                        data.target.value = new Date();
                      }
                      handleOnChange(data);
                    }}
                    disabledKeyboardNavigation={false}
                    isDisabled={
                      eventRecordData?.ticketsInHands ||
                      isEventRecordHoldTickets ||
                      isALLTicketUploadedWithPublish
                    }
                    error={
                      eventRecordData?.fieldErrors?.date_to_ship ? true : false
                    }
                    intializeField={eventRecordData?.intializeField}
                    portalId=""
                    placeholderMoveTop={true}
                  />
                </div>
                {eventRecordData?.fieldErrors?.date_to_ship && (
                  <>
                    <Tooltip
                      anchorId={`dateship-error`}
                      content={eventRecordData?.fieldErrors?.date_to_ship}
                      place="top"
                      variant="light"
                      className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-10 max-w-[10rem] !text-rose-500"
                    />
                    <div
                      id={`dateship-error`}
                      className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                    >
                      <IKTSVG
                        className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                        path={Warning1}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* )} */}
            <div
              className={` ${
                (isEventRecordHoldTickets || isALLTicketUploadedWithPublish) &&
                "disable_field pointer-events-none"
              }  inhand_keyboard w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 min-w-[11.5625rem] 
                 `}
            >
              <Checkbox
                checked={eventRecordData?.ticketsInHands}
                label="Tickets in hand"
                name="ticketsInHands"
                id="ticketInHands"
                WithIcon={true}
                Icon={handIcon}
                IconSize={"w-[.6875rem] h-[.9063rem]"}
                TooltipContent={
                  "Tickets marked in hand must be delivered within 24h of sale - failure to do so may result in a penalty applied to your account."
                }
                TooltipID={"Tickets-hand"}
                handleOnChange={(data: any) => handleOnChange(data)}
                AddinventoryHand={true}
              />
            </div>

            <div
              className={`bg-indigo-500 bg-opacity-[7%] px-5 py-[.9375rem] flex items-center gap-2.5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full ${
                eventRecordData?.price_per_exchange ? "flex" : "hidden"
              }`}
            >
              <PPEComponent
                id={`tx-network-add-inventory`}
                label="TX Network"
                // name={`sell_price`}
                value={
                  typeof eventRecordData?.sell_price === "object"
                    ? eventRecordData?.sell_price?.value
                    : removeCommaFromNumber(eventRecordData?.sell_price)
                }
                currencySymbol={eventRecordData?.sell_price_currency}
                isTooltip={true}
                tooltipContent={Messages.txNetworkTooltipMessage}
                // className="bg-opacity-100 disable border-gray-500"
                // isPPEActive={true}
                // disabled={true}
                txNetwork={true}
                name="sell_price"
                handleOnChange={(data: any) => handleOnChange(data)}
              />
              {eventRecordData?.ppePartners?.map((partner: any) => {
                if (partner?.name && !partner?.is_hidden) {
                  return (
                    <React.Fragment key={partner.id}>
                      <PPEComponent
                        id={partner.id}
                        label={partner.name}
                        name={partner.id}
                        value={removeCommaFromNumber(partner?.value?.value)}
                        errors={eventRecordData?.fieldErrors?.[partner?.name]}
                        currencySymbol={partner?.value?.symbol}
                        isMatchedValue={
                          removeCommaFromNumber(
                            decimalNumber(
                              typeof eventRecordData?.sell_price === "object"
                                ? eventRecordData?.sell_price?.value
                                : removeCommaFromNumber(
                                    eventRecordData?.sell_price
                                  )
                            )
                          ) !==
                          removeCommaFromNumber(
                            decimalNumber(partner?.value?.value)
                          )
                        }
                        tooltipContent={`Your default proceed price across 10+ partners in the TX Network`}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                      />
                    </React.Fragment>
                  );
                } else return <></>;
              })}
            </div>

            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5
        max-md:hidden
        `}
            ></div>
          </div>
          {loader && <ProgressBardLoader secondCounter={2} />}
        </div>
        <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl bg-white z-20">
          <button
            type="button"
            className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
            onClick={() => closePopupView()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
            onClick={() => updateRecord(eventRecordData, true)}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditInventoryMobileView;
