import React, { memo, useState } from "react";
import Select, { NoticeProps } from "react-select";
// import "./styles.css";
import { components } from "react-select";
import Warning1 from "../../assets/media/icons/standard_icons/warning1.svg";
import { capitalizedWords } from "../../helpers/Functions";
import { MULTI_SELECTION_ALLOWED_TABLE } from "../../helpers/const";
import TooltipPortal from "../../pages/tx_trade/table/component/TooltipPortal";
import { IKTSVG } from "../ui/IKTSVG";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,

  ...rest
}: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "transparent";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    justifyContent: "space-between",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      {capitalizedWords(children)}
      {/* @ts-ignore */}
      <input type="checkbox" checked={isSelected} readOnly />
    </components.Option>
  );
};

const customStyle = {
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  control: (base: any, state: any) => {
    return {
      ...base,
      boxShadow: "none",
      "&:hover": {
        border: "1px solid",
        borderColor: "#6f6cff ",
        borderWidth: "1px",
        // boxShadow: "#ff8b67"
      },
    };
  },
};

const MenuList = (props: any, notice: NoticeProps) => {
  return (
    <components.MenuList
      {...props}
      className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-x-hidden"
    >
      {props.children}
    </components.MenuList>
  );
};

const CustomMenuList = (props: any, notice: NoticeProps) => {
  const { onButtonClick } = props.selectProps;
  const visibleOptions = React.Children.map(
    props.children,
    (child) => child.props.data
  );

  return (
    <components.MenuList
      {...props}
      className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full !pt-0"
    >
      {MULTI_SELECTION_ALLOWED_TABLE?.includes(props.selectProps?.name) &&
        props?.children?.props?.children !== "No options" && (
          <div className="flex flex-inline min-h-[2rem] w-full border-b -mt-1 sticky top-0">
            <button
              className={`bg-white !opacity-100 border-gray-300 border-r hover:border-indigo-500 text-violet-800 hover:bg-indigo-500 hover:text-white w-1/2 items-center text-sm13 p-1 rounded-tl 
        transition font-medium text-center`}
              type="button"
              id="select-all"
              onClick={(e: any) => onButtonClick(e, visibleOptions)}
            >
              Select all
            </button>
            <button
              className={`bg-white !opacity-100 border-gray-300 hover:border-indigo-500 text-violet-800 hover:bg-indigo-500 hover:text-white w-1/2 items-center text-sm13 p-1 rounded-tr 
transition font-medium text-center`}
              type="button"
              id="deselect-all"
              onClick={(e: any) => onButtonClick(e, visibleOptions)}
            >
              Deselect all
            </button>
          </div>
        )}
      {props.children}
    </components.MenuList>
  );
};

const TableMultiDropdown = memo(
  ({
    name,
    handleOnChange,
    classNamePrefix,
    options,
    value,
    placeholder,
    getOptionLabel = "name",
    getOptionValue = "id",
    isDisabled = false,
    handleOnSelection,
    errorClassName = "",
    errorMessage = "",
    errorEventIndex = "",
    isNone = true,
  }: any) => {
    const { MultiValueContainer, Placeholder } = components;

    const MultiValue = (props: any) => {
      // Only interested in rendering one/first multiValue
      // if (props.index > 0) return null;
      // const { length } = props.getValue();
      // return length > 0 ? (
      //   <div className="option-label">{`${length} selected`}</div>
      // ) : (
      //   <components.MultiValue {...props} />
      // );
      if (props.index > 0) return null;
      const { length } = props.getValue();
      let isAllNone = props
        .getValue()
        ?.every((item: any) => item?.name === "None");

      let displayPlaceholder = isNone ? true : !isAllNone ? true : false;

      return length > 0 && !props.isFocused ? (
        <div className="option-label">
          {displayPlaceholder && (
            <Placeholder {...props}>{placeholder}</Placeholder>
          )}
          {props?.selectProps?.menuIsOpen
            ? ""
            : isAllNone && !props?.selectProps?.menuIsOpen
            ? isNone
              ? "None"
              : placeholder
            : `${length} selected`}
        </div>
      ) : (
        <MultiValueContainer {...props} />
      );
    };

    const [inputValue, setInputValue] = useState("");
    const [menuOpenCloseStatus, setMenuOpenCloseStatus] =
      useState<boolean>(false);

    const handleInputChange = (value: any, { action }: any) => {
      if (action === "input-change") {
        // handleInputChangeForParent(value);
        setInputValue(value);
      }
    };

    return (
      <div className="App multi-select-dropdown min-w-[6.875rem] relative">
        <Select
          // @ts-ignore
          onButtonClick={(data: any, visibleArray: any) => {
            handleOnSelection(data, visibleArray);
            if (data?.target?.id !== "select-all") {
              setInputValue("");
            }
          }}
          value={Array.isArray(value) && value}
          inputValue={menuOpenCloseStatus ? inputValue : ""}
          onInputChange={handleInputChange}
          menuIsOpen={menuOpenCloseStatus}
          onMenuClose={() => setMenuOpenCloseStatus(false)}
          onMenuOpen={() => setMenuOpenCloseStatus(true)}
          isMulti
          classNamePrefix={classNamePrefix}
          closeMenuOnSelect={false}
          tabSelectsValue={false}
          hideSelectedOptions={false}
          isClearable={true}
          onChange={(selectedOption: any, actionMeta: any) => {
            let event = { target: { name: name, value: selectedOption } };
            if (actionMeta?.action !== "pop-value") {
              handleOnChange(event);
            }
          }}
          name={name}
          isSearchable={true}
          components={{
            MultiValue: MultiValue,
            Option: InputOption,
            MenuList: CustomMenuList,
          }}
          menuPortalTarget={document.body}
          styles={customStyle}
          className={`${errorClassName}`}
          // menuIsOpen={true}
          //@ts-ignore
          options={options}
          placeholder={placeholder}
          getOptionLabel={(option) => option[getOptionLabel]}
          getOptionValue={(option) => option[getOptionValue]}
          menuPlacement="auto"
          isDisabled={isDisabled}
        />
        {value?.length > 0
          ? ""
          : errorMessage &&
            errorEventIndex && (
              <>
                <TooltipPortal
                  id={`error-${errorEventIndex}`}
                  content={errorMessage}
                  className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
                />
                <div
                  id={`error-${errorEventIndex}`}
                  data-tooltip-id={`error-${errorEventIndex}`}
                  className="absolute top-1/2 -translate-y-1/2 right-3 "
                >
                  <IKTSVG
                    className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                    path={Warning1}
                  />
                </div>
              </>
            )}
      </div>
    );
  }
);

export default TableMultiDropdown;
