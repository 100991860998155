import { createContext, PropsWithChildren, useContext, useState } from "react";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../../helpers/AssetHelpers";
import {
  INITIAL_PERSONAL_DETAILS,
  PEROSNAL_DETAILS_COLUMNS_ITEMS,
  PEROSNAL_DETAILS_FILTER_ITEMS,
} from "../../../../helpers/const";

export interface LayoutContextModel {
  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  draggedColumns: Array<any>;
  setDraggedColumns: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  filterData: any;
  setFilterData: (data: any) => void;

  meta: any;
  setMeta: (data: any) => void;

  filterOptions: any;
  setFilterOptions: (data: any) => void;

  listings: any;
  setListings: (data: any) => void;

  personalDetailsTabs: any;
  setPersonalDetailsTabs: (data: any) => void;

  levelLists: any;
  setLevelLists: (data: any) => void;
}

const PersonalDetailsContext = createContext<LayoutContextModel>({
  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  draggedColumns: [],
  setDraggedColumns: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  filterData: [],
  setFilterData: (data: any) => {},

  meta: [],
  setMeta: (data: any) => {},

  filterOptions: [],
  setFilterOptions: (data: any) => {},

  listings: [],
  setListings: (data: any) => {},

  personalDetailsTabs: {},
  setPersonalDetailsTabs: (data: any) => {},

  levelLists: [],
  setLevelLists: (data: any) => {},
});

const PersonalDetailsProvider = ({ children }: PropsWithChildren) => {
  const globalLayout = useContext(LayoutContext);

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.savePersonalDetailsFilter;

  let items = getHeaderFilter(
    "savePersonalDetailsFilter",
    isFilterExistInDB,
    PEROSNAL_DETAILS_FILTER_ITEMS
  );

  // COLUMN FILTER - CONDITION
  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.PersonalDetailsTableColumn;

  let columnsItems = getHeaderFilter(
    "PersonalDetailsTableColumn",
    isColumnFilterExistInDB,
    PEROSNAL_DETAILS_COLUMNS_ITEMS
  );

  const [filterItems, setFilterItems] = useState(items);
  const [draggedItems, setDraggedItems] = useState(filterItems);
  const [draggedColumns, setDraggedColumns] = useState([]);
  const [columnItems, setColumnItems] = useState(columnsItems);
  const [filterData, setFilterData] = useState<any>(INITIAL_PERSONAL_DETAILS);
  const [meta, setMeta] = useState<any>();
  const [filterOptions, setFilterOptions] = useState<any>([]);
  const [listings, setListings] = useState<any>([]);
  const [personalDetailsTabs, setPersonalDetailsTabs] = useState<any>({
    activeTab: "Personal Details",
  });

  const [levelLists, setLevelLists] = useState<any>([]);

  const value: LayoutContextModel = {
    filterItems,
    setFilterItems,

    draggedItems,
    setDraggedItems,

    draggedColumns,
    setDraggedColumns,

    columnItems,
    setColumnItems,

    filterData,
    setFilterData,

    meta,
    setMeta,

    filterOptions,
    setFilterOptions,

    listings,
    setListings,

    personalDetailsTabs,
    setPersonalDetailsTabs,

    levelLists,
    setLevelLists,
  };

  return (
    <PersonalDetailsContext.Provider value={value}>
      {children}
    </PersonalDetailsContext.Provider>
  );
};

export { PersonalDetailsContext, PersonalDetailsProvider };
