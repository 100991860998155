import _ from "lodash";
import { useContext, useRef } from "react";
import Checkbox from "../../../../../components/formComponent/Checkbox";
import DropDownField from "../../../../../components/formComponent/DropDownField";
import MultiDropDownField from "../../../../../components/formComponent/MultiDropDownField";
import { handleSelectDeselectAll } from "../../../../../helpers/Functions";
import { SalesContext } from "../../../core/SalesProvider";

interface OrderNewListingFiltersgInterface {
  categoryOptions?: any;
  data?: any;
}

const OrderNewListingFilters: React.FC<OrderNewListingFiltersgInterface> = ({
  categoryOptions,
  data,
}: any) => {
  let quantityOptionsArray = Array.from({ length: 5 }, (_, index) => ({
    id: (index + data?.ticket_quantity).toString(),
    name: (index + data?.ticket_quantity).toString(),
    postData: (index + data?.ticket_quantity).toString(),
  }));

  quantityOptionsArray = [
    { id: "", name: "Any", postData: "" },
    ...quantityOptionsArray,
    {
      id: "more",
      name: "more than " + (data?.ticket_quantity + 4),
      postData: "more",
    },
  ];

  const layout = useContext(SalesContext);
  const teamSelectedRef: any = useRef<any>();
  const categorySelectedRef: any = useRef<any>();
  const ticketTypeSelectedRef: any = useRef<any>();

  // HANDLE SELECT-DESELECT FOT TEAMS
  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any
  ) => {
    if (teamSelectedRef && teamSelectedRef?.current) {
      teamSelectedRef.current.blur();
    }
    if (categorySelectedRef && categorySelectedRef?.current) {
      categorySelectedRef.current.blur();
    }
    if (ticketTypeSelectedRef && ticketTypeSelectedRef?.current) {
      ticketTypeSelectedRef.current.blur();
    }

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      layout.setOrderNewFilterHandle
    );
  };

  // HANDLE ONCHANGE
  const handleOnChange = (data: any) => {
    layout.setOrderNewFilterHandle((currentValue: any) => {
      const newFilter = {
        ...currentValue,
        [data?.target?.name]: data?.target?.checked
          ? data?.target?.checked
          : data?.target?.value,
        page: 1,
        per_page: 10,
      };

      // Check if the new filter is different from the current one
      const hasChanged =
        JSON.stringify(currentValue) !== JSON.stringify(newFilter);

      if (hasChanged) {
        layout.setCustomNewOrderLoader(true);
        return newFilter;
      }

      return currentValue;
    });
  };

  return (
    <div className="filter flex flex-wrap border-b gap-y-2.5 py-4 px-[.9375rem] bg-white">
      {/* Team */}
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] px-[.3125rem] ${
          layout.customNewOrderLoader ? "shimmer-effect" : ""
        }`}
      >
        <MultiDropDownField
          isCapital={true}
          selectedRef={teamSelectedRef}
          isValueCapital={true}
          options={layout?.teamsOptions?.filter(
            (option: any) => option?.id !== data?.team_id
          )}
          classNamePrefix={"form_multiDropDown"}
          placeholder={"All teams"}
          isClearable={true}
          isMultiSelection={true}
          name="team_id"
          handleOnChange={(data: any, actionMeta: any) => {
            layout.setCustomNewOrderLoader(true);
            layout.setOrderNewFilterHandle((currentValue: any) => {
              return {
                ...currentValue,
                [data?.target?.name]: data?.target?.value,
                page: 1,
                per_page: 10,
              };
            });

            if (
              _.size(data?.target?.value) === 0 &&
              _.size(layout.salesFilterHandle?.team_member) > 0
            ) {
              layout.setOrderNewFilterHandle((currentValue: any) => {
                return {
                  ...currentValue,
                  team_member: "",
                };
              });
            }
          }}
          handleOnSelection={(data: any, visibleOptions: any) =>
            handleOnSelection(
              data,
              "team_id",
              layout?.teamsOptions,
              visibleOptions
            )
          }
          value={layout.orderNewFilterHandle?.team_id}
          label="Teams"
          isMenuPortalTarget={true}
        />
      </div>

      {/* CATEGORY */}
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] ${
          layout.customNewOrderLoader ? "shimmer-effect" : ""
        }`}
      >
        <div className={` rounded`}>
          <MultiDropDownField
            name="category_id"
            classNamePrefix={"form_multiDropDown"}
            placeholder="Category"
            options={categoryOptions}
            selectedRef={categorySelectedRef}
            isMultiSelection={true}
            value={layout.orderNewFilterHandle?.category_id}
            handleOnSelection={(data: any, visibleOptions: any) => {
              handleOnSelection(
                data,
                "category_id",
                categoryOptions,
                visibleOptions
              );
            }}
            handleOnChange={(data: any, e: any) => handleOnChange(data)}
            isMenuPortalTarget={true}
          />
        </div>
      </div>

      {/* QUANTITY */}
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] ${
          layout.customNewOrderLoader ? "shimmer-effect" : ""
        }`}
      >
        <div className={`rounded`}>
          <DropDownField
            options={quantityOptionsArray}
            placeholder="Quantity"
            name="quantity"
            value={layout?.orderNewFilterHandle?.quantity}
            isClearable={false}
            handleOnChange={(data: any, e: any) => handleOnChange(data)}
          />
        </div>
      </div>

      {/* TICKET IN HAND */}
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] ${
          layout.customNewOrderLoader ? "shimmer-effect" : ""
        }`}
      >
        <div className={`rounded`}>
          <Checkbox
            label="Tickets in hand"
            id="ticket_in_handd"
            name="ticket_in_hand"
            labelclass="text-gray-500"
            checked={layout.orderNewFilterHandle?.ticket_in_hand}
            tooltip={false}
            handleOnChange={(data: any, e: any) => handleOnChange(data)}
          />
        </div>
      </div>

      {/* TICEKTS UPLOADED */}
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] ${
          layout.customNewOrderLoader ? "shimmer-effect" : ""
        }`}
      >
        <div className={`rounded`}>
          <Checkbox
            label="Tickets uploaded"
            id="ticket_uploaded"
            name="ticket_uploaded"
            labelclass="text-gray-500"
            checked={layout.orderNewFilterHandle?.ticket_uploaded}
            tooltip={false}
            handleOnChange={(data: any, e: any) => handleOnChange(data)}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderNewListingFilters;
