import { CrossIcon } from "assets/media/icons/other_icons/CrossIcon";
import { SearchIcon } from "assets/media/icons/standard_icons/SearchIcon";
import { initialRecordFilters } from "pages/customize/core/_.model";
import { useCustomize } from "pages/customize/hooks/useCustomize";

export const DesignMenuFilters = ({ searchValue, setSearchValue }: any) => {
  const { recordFilter, setRecordFilter } = useCustomize();

  return (
    <div className="flex justify-between w-full">
      {/* SEARCH WITH NAME */}
      <div className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%]  lg:px-[.3125rem]`}>
        <div
          className={`relative rounded flex-1 max-w-full md:mb-0 ${
            false && "shimmer-effect"
          } `}
        >
          <div className="w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <button
                type="button"
                onClick={() => {
                  if (recordFilter?.keyword) {
                    setRecordFilter(initialRecordFilters);
                    setSearchValue("");
                  }
                }}
              >
                <div className="fill-violet-500">
                  {recordFilter?.keyword ? (
                    <CrossIcon className="w-3.5" />
                  ) : (
                    <SearchIcon className="h-3.5 w-3.5" />
                  )}
                </div>
              </button>
            </div>
            <input
              type="text"
              name="keyword"
              id="simple-search-personal"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search title"
              className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
              onKeyDown={(e: any) => {
                if (e?.target?.value && e.key === "Enter") {
                  e.preventDefault();
                  setRecordFilter((prev: any) => ({
                    ...prev,
                    [e.target.name]: e.target.value,
                  }));
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
