import { Tooltip } from "react-tooltip";
import CrossSVG from "../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../ui/IKTSVG";

const HeaderSection = ({
  title,
  handleClosePopup,
  className = "border-t flex justify-between md:pl-8 pl-5 items-center border-b sticky top-0 bg-white z-[60]",
  titleClassName,
  id,
}: any) => {
  return (
    <div className={className} id={id}>
      <div className={`flex-1 ${titleClassName}`}>
        {title && (
          <span className="md:text-sm15 text-sm12 block font-semibold">
            {title}
          </span>
        )}
      </div>
      <div className="flex flex-wrap">
        <button
          className={`group relative sadsadsad mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l`}
          type="button"
          id={`Close-header-${title}`}
          onClick={handleClosePopup}
        >
          <IKTSVG
            className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition`}
            path={CrossSVG}
            svgClassName="w-[.5966rem] h-[.5966rem]"
          />

          <Tooltip
            anchorId={`Close-header-${title}`}
            content={`Close`}
            place={"left"}
            variant="light"
            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
          />
        </button>
      </div>
    </div>
  );
};

export default HeaderSection;
