import DropDownField from "components/formComponent/DropDownField";
import TextInput from "components/formComponent/TextInput";
import { IKTSVG } from "components/ui/IKTSVG";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import infoIcon from "../../../../assets/media/icons/other_icons/info.svg";

const CommonDynamicComponent = ({
  index,
  obj,
  OptionData,
  formik,
  ClassName = false,
  fullWidthTitle = false,
  handleOnUpdate = () => {},
  formikErrorClass = false,
}: any) => {
  return (
    <div
      key={index}
      className={` ${
        ClassName ? ClassName : "w-full"
      } bg-white mb-5 px-2.5 relative flex items-center`}
    >
      <div className={` ${fullWidthTitle ? "w-full mb-2" : "w-full md:w-1/3"}`}>
        <p className="text-sm13">{obj?.label} :</p>
      </div>

      <div className="w-full seller_level_drop_down relative">
        {obj?.component === "dropdown" ? (
          <DropDownField
            options={OptionData?.[obj?.key]}
            value={formik.values?.[obj?.key]}
            placeholder={obj?.label}
            name={obj?.key}
            getOptionLabel="name"
            getOptionValue="id"
            height=""
            className={`h-fourty ${
              obj?.originalValue ? "have_original_value" : ""
            }  ${
              formik.touched?.[obj?.key] &&
              formik.errors?.[obj?.key] &&
              "all-dropdown-errorField"
            } `}
            handleOnChange={(data: any) => {
              formik?.setFieldValue(obj?.key, data?.target?.value);
              handleOnUpdate(obj?.key, "update");
            }}
          />
        ) : (
          <>
            <TextInput
              name={obj?.key}
              type={obj?.type === "numeric" ? "number" : "text"}
              placeholder={obj?.label}
              value={formik.values?.[obj?.key]}
              id={obj?.key}
              required={false}
              className={`${
                obj?.originalValue ? "!bg-indigo-50" : "!bg-white"
              }  `}
              handleOnChange={(e: any) => {
                formik.handleChange(e);
                handleOnUpdate(obj?.key, "update");
              }}
              errorClass={
                formik.touched?.[obj?.key] &&
                formik.errors?.[obj?.key] &&
                "border-rose-500"
              }
              handleClearValue={() => formik.setFieldValue([obj?.key], "")}
            />
          </>
        )}

        {formik.touched?.[obj?.key] && formik.errors?.[obj?.key] ? (
          <div
            className={`text-xs text-rose-500 error-msg  ${
              formikErrorClass ? "left-[1.375rem]" : "left-[1.375rem]"
            }  `}
          >
            {formik.errors?.[obj?.key]}
          </div>
        ) : null}
      </div>

      {obj?.originalValue ? (
        <div
          className="flex items-center justify-center absolute right-12 top-[2.5rem]"
          data-tooltip-id={`infoTooltip-${index}`}
        >
          <TooltipPortal
            id={`infoTooltip-${index}`}
            content={`This value has been updated and orignal value is ${obj?.originalValue} `}
            className="text-center !bg-white !opacity-100 shadow !px-[.3125rem] !py-0.5 font-medium  !text-xxs z-[9999999999999] max-w-[10.625rem] cursor-default !text-violet-800"
          />
          <IKTSVG
            path={infoIcon}
            className="ml-1 fill-indigo-500 block"
            svgClassName="fill-gray-400 hover:fill-indigo-500 w-3.5 h-3.5"
          />
        </div>
      ) : null}
    </div>
  );
};

export default CommonDynamicComponent;
