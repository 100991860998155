import { IKTSVG } from "components/ui/IKTSVG";
import check from "../../../assets/media/icons/other_icons/check.svg";

const SellerLevelBenefitsSlider = ({ data, key }: any) => {
  return (
    <div key={key} className="p-[.9375rem]">
      <h4 className="title text-sm15 font-medium leading-[1.125rem] mb-[.4375rem]">
        Seller {data?.title}
      </h4>
      <span className="text-sm12 font-medium text-gray-500 leading-[.875rem] block mb-2.5">
        Quarterly GTV £0 - £4,999.99
      </span>
      {/* <button className="bg-violet-500 text-xxs font-medium leading-3 px-1.5 py-[.1875rem] rounded text-white hover:bg-indigo-500 mb-[.9375rem]">
        Seller Levels explained
      </button> */}

      <div className="benifitList gap-y-[.5625rem] flex flex-wrap">
        {data?.benefits?.map((obj: any, index: any) => {
          return (
            <div key={index} className="singleList w-full">
              <div className="iconContent flex">
                <IKTSVG
                  path={check}
                  // bg-yellow-100 for last level
                  className="w-3 min-w-3 h-3 rounded-sm bg-green-600 flex items-center justify-center mt-[.0625rem] mr-1.5"
                  svgClassName="fill-white w-[.4375rem] h-1.5"
                />
                <span className="text-sm12 leading-[.9375rem]">{obj}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SellerLevelBenefitsSlider;
