import { Tooltip } from "react-tooltip";
import CloneSVG from "../../../assets/media/icons/other_icons/Clone.svg";
import checkSVG from "../../../assets/media/icons/other_icons/check.svg";
import { default as crossSVG } from "../../../assets/media/icons/standard_icons/cross.svg";
import Warning1 from "../../../assets/media/icons/standard_icons/warning1.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";

const SalesMobilelinkUrl = ({
  ticketFileIndex,
  ticketFile,
  item,
  eventIndex,
  eventRecordIndex,
  handleMobileLinkChange,
  copy,
  setIsCopied,
  isCopied,
}: any) => {
  const androidLink = item?.separate_links
    ? ticketFile?.link?.android
    : ticketFile?.link;

  const copyBtnVisible = item?.separate_links
    ? ticketFile?.link?.android && ticketFile?.isValidAndroid
    : ticketFile?.link && ticketFile?.isValid;

  const errorExistInFileAndroid = item?.separate_links
    ? !ticketFile?.isValidAndroid
    : !ticketFile?.isValid;
  return (
    <div
      className={`con_transform w-full  text-sm12  border-violet-900 flex flex-wrap direction-row items-center `}
    >
      <span className="mr-2 text-sm12">Ticket {ticketFileIndex + 1}</span>
      <div className="flex-1 w-full flex gap-2">
        <div
          className={`flex items-center relative ${
            item?.separate_links ? "w-1/2" : "w-full"
          } `}
        >
          <div className="relative flex-1 w-full">
            {/* INPUT BOX */}
            <input
              type="text"
              //   value={ticketFile?.link}
              value={
                item?.separate_links
                  ? ticketFile?.link?.android
                    ? ticketFile?.link?.android
                    : ""
                  : ticketFile?.link
                  ? ticketFile?.link
                  : ""
              }
              placeholder={`Enter ${
                item?.separate_links ? "android " : ""
              }URL link here`}
              className={`truncate h-6 ${
                ticketFile?.isValid ? "pr-[3rem]" : "pr-[1.8rem]"
              } w-full text-sm13 font-medium py-0 border-gray-300 rounded  focus:bg-violet-300/50 focus:border-violet-400  mr-1 ${
                item?.errors?.length > 0 ||
                (ticketFile.hasOwnProperty("isValid") &&
                  errorExistInFileAndroid)
                  ? "border-rose-500"
                  : (ticketFile?.isValid || item?.isFull) && "border-green-500"
              }`}
              title={
                ticketFile.hasOwnProperty("isValid") && errorExistInFileAndroid
                  ? "Invalid URL"
                  : ""
              }
              onChange={(e: any) =>
                handleMobileLinkChange(
                  eventIndex,
                  eventRecordIndex,
                  e,
                  false,
                  false,
                  ticketFileIndex,
                  "android"
                )
              }
            />

            {ticketFile.hasOwnProperty("isValid") &&
              errorExistInFileAndroid && (
                <>
                  <Tooltip
                    anchorId={`mobile-link-${ticketFileIndex}-error`}
                    content={`${item?.errors?.[0] ? item?.errors?.[0] : ""}`}
                    place="top"
                    variant="light"
                    className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
                  />
                  <div
                    id={`mobile-link-${ticketFileIndex}-error`}
                    className="absolute top-1/2 -translate-y-1/2 right-6 cursor-default"
                  >
                    <IKTSVG
                      className="flex items-center justify-center h-4 w-4 fill-rose-500 hover:fill-rose-500 transition"
                      svgClassName="h-4 w-4"
                      path={Warning1}
                    />
                  </div>
                </>
              )}
          </div>

          {/* COPY BUTTON */}
          {copyBtnVisible && (
            <span
              className={`flex items-center gap-x-2 absolute ${
                ticketFile?.alreadySave === 1 ? "right-2" : "right-6"
              } `}
            >
              <button
                type="button"
                id={`mobile-link-text-copy-${eventIndex}-${eventRecordIndex}`}
                onClick={(e: any) => {
                  //   let copiedValues = copy(ticketFile?.link);

                  let copiedValues = copy(
                    item?.separate_links
                      ? ticketFile?.link?.android
                      : ticketFile?.link
                      ? ticketFile?.link
                      : ""
                  );
                  setIsCopied({
                    id: `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}-android`,
                    value: copiedValues,
                  });
                  setTimeout(() => {
                    setIsCopied({
                      id: "",
                      value: "",
                    });
                  }, 200);
                }}
              >
                <Tooltip
                  anchorId={`mobile-link-text-copy-${eventIndex}-${eventRecordIndex}`}
                  content="Copy URL"
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                />
                <IKTSVG
                  className={`flex items-center justify-center transition ${
                    isCopied.value ===
                      (ticketFile?.link?.android || ticketFile?.link) &&
                    isCopied.id ===
                      `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}-android`
                      ? "fill-green-600  hover:fill-green-600"
                      : "fill-violet-500 hover:fill-indigo-500"
                  }`}
                  svgClassName="w-3"
                  path={CloneSVG}
                />
              </button>
            </span>
          )}

          {/* CLEAR BUTTON INSIDE INPUT BOX*/}
          {androidLink && ticketFile?.alreadySave !== 1 && (
            <span
              className={`flex items-center gap-x-2 absolute right-2 top-1/2 -translate-y-1/2`}
              key={ticketFileIndex}
            >
              <button
                type="button"
                id={`mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}clear`}
                onClick={(e: any) =>
                  handleMobileLinkChange(
                    eventIndex,
                    eventRecordIndex,
                    e,
                    false,
                    true,
                    ticketFileIndex,
                    "android"
                  )
                }
              >
                <Tooltip
                  anchorId={`mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}clear`}
                  content="Clear"
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                />
                <IKTSVG
                  className="fill-violet-500 hover:fill-indigo-500 transition"
                  path={crossSVG}
                  svgClassName="w-2.5"
                />
              </button>
            </span>
          )}
        </div>
        {item?.separate_links && (
          <div className="flex items-center relative w-1/2">
            <div className="relative flex-1 w-full">
              {/* INPUT BOX */}
              <input
                type="text"
                value={ticketFile?.link?.ios ? ticketFile?.link?.ios : ""}
                placeholder={`Enter iOS URL link here`}
                className={`truncate h-6 ${
                  ticketFile?.isValid ? "pr-[3rem]" : "pr-[1.8rem]"
                } w-full text-sm13 font-medium py-0 border-gray-300 rounded  focus:bg-violet-300/50 focus:border-violet-400  mr-1 ${
                  item?.errors?.length > 0 ||
                  (ticketFile.hasOwnProperty("isValid") &&
                    !ticketFile?.isValidIos)
                    ? "border-rose-500"
                    : (ticketFile?.isValid || item?.isFull) &&
                      "border-green-500"
                }`}
                title={
                  ticketFile.hasOwnProperty("isValid") &&
                  !ticketFile?.isValidIos
                    ? "Invalid URL"
                    : ""
                }
                onChange={(e: any) =>
                  handleMobileLinkChange(
                    eventIndex,
                    eventRecordIndex,
                    e,
                    false,
                    false,
                    ticketFileIndex,
                    "ios"
                  )
                }
              />

              {ticketFile.hasOwnProperty("isValid") &&
                !ticketFile?.isValidIos && (
                  <>
                    <Tooltip
                      anchorId={`mobile-link-${ticketFileIndex}-error-saparate`}
                      content={`${item?.errors?.[0] ? item?.errors?.[0] : ""}`}
                      place="top"
                      variant="light"
                      className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
                    />
                    <div
                      id={`mobile-link-${ticketFileIndex}-error-saparate`}
                      className="absolute top-1/2 -translate-y-1/2 right-6 cursor-default"
                    >
                      <IKTSVG
                        className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                        svgClassName="h-4 w-4"
                        path={Warning1}
                      />
                    </div>
                  </>
                )}
            </div>

            {/* COPY BUTTON */}
            {ticketFile?.link && ticketFile?.isValid && (
              <span
                className={`flex items-center gap-x-2 absolute ${
                  ticketFile?.alreadySave === 1 ? "right-2" : "right-6"
                } `}
              >
                <button
                  type="button"
                  id={`mobile-link-text-copy-${eventIndex}-${eventRecordIndex}-saparate`}
                  onClick={(e: any) => {
                    // let copiedValues = copy(ticketFile?.link);
                    let copiedValues = copy(
                      item?.separate_links
                        ? ticketFile?.link?.ios
                        : ticketFile?.link
                        ? ticketFile?.link
                        : ""
                    );
                    setIsCopied({
                      id: `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`,
                      value: copiedValues,
                    });
                    setTimeout(() => {
                      setIsCopied({
                        id: "",
                        value: "",
                      });
                    }, 200);
                  }}
                >
                  <Tooltip
                    anchorId={`mobile-link-text-copy-${eventIndex}-${eventRecordIndex}-saparate`}
                    content="Copy URL"
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                  <IKTSVG
                    className={`flex items-center justify-center transition ${
                      isCopied.value === ticketFile?.link?.ios &&
                      isCopied.id ===
                        `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`
                        ? "fill-green-600  hover:fill-green-600"
                        : "fill-violet-500 hover:fill-indigo-500"
                    }`}
                    svgClassName="w-3"
                    path={CloneSVG}
                  />
                </button>
              </span>
            )}

            {/* CLEAR BUTTON INSIDE INPUT BOX*/}
            {ticketFile?.link?.ios && ticketFile?.alreadySave !== 1 && (
              <span
                className={`flex items-center gap-x-2 absolute   right-2 `}
                key={ticketFileIndex}
              >
                <button
                  type="button"
                  id={`mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}clear-saparate`}
                  onClick={(e: any) =>
                    handleMobileLinkChange(
                      eventIndex,
                      eventRecordIndex,
                      e,
                      false,
                      true,
                      ticketFileIndex,
                      "ios"
                    )
                  }
                >
                  <Tooltip
                    anchorId={`mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}clear-saparate`}
                    content="Clear"
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                  <IKTSVG
                    className="fill-violet-500 hover:fill-indigo-500 transition"
                    path={crossSVG}
                    svgClassName="w-2.5"
                  />
                </button>
              </span>
            )}
          </div>
        )}
      </div>
      {/*CONFIRM  BUTTON */}
      {!ticketFile?.isValid && (
        <>
          <button
            type="button"
            id={`mobile-link-text-confirm-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
            className={`${
              (item?.separate_links
                ? !ticketFile?.link?.ios || !ticketFile?.link?.android
                : !ticketFile?.link) &&
              "pointer-events-none !bg-gray-100 border-none"
            } ${
              ticketFile?.link
                ? "bg-violet-500  hover:bg-indigo-500 border border-violet-500 "
                : "bg-white border border-gray-300"
            } group flex mx-1 items-center justify-center h-6 w-6  hover:border-indigo-500 text-white hover:bg-indigo-500 transition rounded mr-0`}
            onClick={(e: any) =>
              handleMobileLinkChange(
                eventIndex,
                eventRecordIndex,
                e,
                true,
                false,
                ticketFileIndex
              )
            }
          >
            <IKTSVG
              path={checkSVG}
              className={` group-hover:fill-white transition ${
                (
                  item?.separate_links
                    ? !ticketFile?.link?.ios || !ticketFile?.link?.android
                    : !ticketFile?.link
                )
                  ? "fill-gray-400"
                  : "fill-violet-500 fill-white"
              }`}
              svgClassName="w-3"
            />
            <Tooltip
              anchorId={`mobile-link-text-confirm-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
              content="Confirm"
              place="top"
              variant="light"
              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
            />
          </button>
        </>
      )}
    </div>
  );
};

export default SalesMobilelinkUrl;
