import { DeleteIcon } from "assets/media/icons/other_icons/DeleteIcon";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";

export const DeleteIconButton = ({ id, ...rest }: any) => (
  <button
    type="button"
    data-tooltip-id={`delete-${id}`}
    className={`border group inline-flex group items-center font-medium rounded text-sm13 px-2.5 py-0.5 max-h-[1.875rem]`}
    {...rest}
  >
    <TooltipPortal
      id={`delete-${id}`}
      content="Delete"
      place="top"
      variant="light"
      className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium text-xs z-[9999]"
    />
    <div className="w-2.5 h-3.5 flex items-center justify-center fill-violet-500 hover:fill-indigo-500 rounded-full cursor-pointer">
      <DeleteIcon className="w-2.5 h-3.5" />
    </div>
  </button>
);
