import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";
import { TableVirtuoso } from "react-virtuoso";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import AddSVG from "../../../assets/media/icons/other_icons/add_icon.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import NoDataComponent from "../../../components/tableComponent/NoDataComponent";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { QUERIES } from "../../../helpers/const";
import { useScrollLeftEffect } from "../../../hooks/useScrollLeftEffect";
import { ConnectorContext } from "../core/ConnectorProvider";
import { getTriggerListing } from "../core/requests";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const TriggersDataTable = ({ data, setTriggerState }: any) => {
  // GET triggerslist
  const [customTriggersLoading, setCustomTriggersLoading] = useState(true);

  const {
    isLoading: triggersloading,
    data: triggersData,
    refetch: refetchClients,
  } = useQuery(
    [`${QUERIES.API_CONNECTOR_TRIGGERS}`, data?.id],
    () =>
      getTriggerListing({
        id: data?.id,
        exclude_names: ["auth"],
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      // enabled: !optionsLoading && startListing ? true : false,
      onError: (err) => {},
      onSettled: (err) => {
        // setReportTableLoading(false)
        // setCustomClientLoading(false);
        setCustomTriggersLoading(false);
      },
    }
  );

  const paginateData = triggersData?.meta;

  const globalLayout = useContext(LayoutContext);
  const layout = useContext(ConnectorContext);

  const tableRef: any = useRef();
  const stickyTableRef: any = useRef();
  const virtuosoRef: any = useRef();

  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  const { setIsSettled }: any = useScrollLeftEffect({
    elementId: "tableId1",
    headerId: "sticky-head",
  });

  const scroll = (scrollOffset: any) => {
    setIsSettled(true);
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  // CALCULATE THE HEIGHT OF TABLE VIRTOUSO
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [virtHeight, setVirtHeight] = useState<any>();
  const [hoveredId, setHoveredId] = useState(null);

  // HANDLE ORDER VIEW PAGE
  const handleTriggerView = (list: any, index: number) => {
    if (list?.id) {
      setTriggerState({
        status: true,
        data: list,
      });
    } else {
      // display(false);
      setTriggerState({
        status: false,
        data: "",
      });
    }
  };

  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#triggers-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      if (divElement?.offsetHeight > 1) {
        let elemHeight = divElement?.offsetHeight + theadHeight;
        const height = virtHeight > elemHeight ? virtHeight : elemHeight;

        if (nomoreListingHeight) {
          elemHeight =
            divElement?.offsetHeight + theadHeight + nomoreListingHeight + 1;
        }
        setHeight(`${elemHeight}`);
      }
    }, 800);
  };
  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader-triggers");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      (paginateData?.current_page !== paginateData?.last_page &&
        paginateData?.current_page < paginateData?.last_page) ||
      triggersloading
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData, triggersloading]);

  return (
    <>
      <div>
        <div
          className={`inline-flex m-4 gap-2 
      ${layout.canvasToggle ? "px-0" : "pl-[1.875rem]"}`}
        >
          <button
            type="button"
            className={`text-white hover:text-white bg-violet-500 hover:bg-indigo-500 group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
            onClick={() => {
              setTriggerState({ status: true, data: "" });
            }}
          >
            <IKTSVG
              className={`flex items-center justify-center  transition mr-2 fill-white`}
              path={AddSVG}
              svgClassName="w-3.5 h-3"
            />
            Add
          </button>
        </div>

        {/* DEFINE YOUR TABLE HERE */}
        <div
          className={`trade-table w-full flex-1 basis-0 overflow-auto h-full min-h-[23.4375rem] th_last ${
            layout.canvasToggle ? "px-0" : "pl-[1.875rem]"
          }`}
        >
          <div
            className="accordion font-medium mb-0 mt-auto h-full"
            id={`EventAccordion`}
          >
            <div
              id="sticky-head"
              className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none`}
              ref={stickyTableRef}
              onScroll={handleScrollHead}
            >
              <table className="w-full text-xs text-left  min-w-[87.5rem] absolute left-0 top-0">
                <thead className="text-xs text-gray-400 ">
                  <tr className="border-b bg-white">
                    <HeaderCell
                      title="ID"
                      width="w-[12rem]"
                      arrow={false}
                      postKey="id"
                      padding="pl-5"
                    />
                    <HeaderCell
                      title="Name"
                      width="w-[12rem]"
                      arrow={false}
                      postKey="name"
                      padding="pl-5"
                    />
                    <HeaderCell
                      title="Request Type"
                      width="w-[12rem]"
                      arrow={false}
                      postKey="request_type"
                      padding="pl-5"
                    />
                    <HeaderCell
                      title="Endpoint URL"
                      // width="min-w-[6.875rem]"
                      arrow={false}
                      postKey="endpoint_url"
                      padding="pl-5"
                    />

                    <th className="sticky right-0 ml-auto  p-0 w-[5.0625rem]">
                      <div
                        className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
                        id="left-shad-list"
                      >
                        <button
                          className="group rounded-l  hover: transition px-[.5625rem] py-2"
                          id="leftArrow"
                          type="button"
                          onClick={(e: any) => scroll(-120)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                            path={angleLeftSVG}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                        <button
                          className="group rounded-r  transition px-[.5625rem] py-2"
                          id="rightArrow"
                          type="button"
                          onClick={(e: any) => scroll(120)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                            path={angleRightSVG}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
            <div className="accordion-item bg-white  !rounded overflow-hidden">
              {/* <EventFields /> */}
              <div
                id="EventCollapseBody"
                className="accordion-collapse collapse show"
                aria-labelledby="EventCollapseBody"
                data-te-collapse-show
              >
                <div className="accordion-body relative">
                  <div
                    className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
                    ref={tableRef}
                    id={`tableId1`}
                    onScroll={handleScrollVirtuoso}
                    // style={{
                    //   height: `${
                    //     virtuosoHeight() > height ? virtuosoHeight() : height
                    //   }px`,
                    // }}
                    style={{ height: height > 41 ? `${height}px` : "auto" }}
                  >
                    {customTriggersLoading ? (
                      Array.from({ length: 10 }, (v, i) => (
                        <div
                          className={`accordion font-medium  mb-2.5 mx-5 ${
                            i === 0 && "mt-5"
                          } `}
                          key={i}
                        >
                          <div
                            key={i}
                            className={`accordion-item bg-white !rounded overflow-hidden ${"shimmer-effect"} `}
                            style={{ height: "40px" }}
                          ></div>
                        </div>
                      ))
                    ) : (
                      <>
                        {" "}
                        {triggersData?.data?.length > 0 ? (
                          <TableVirtuoso
                            data={triggersData?.data}
                            endReached={() => {
                              if (
                                paginateData?.current_page <
                                paginateData?.last_page
                              ) {
                                layout.setConnectorFilter((current: any) => {
                                  let nextPage: number =
                                    paginateData?.current_page + 1;
                                  return {
                                    ...current,
                                    page: nextPage,
                                    per_page: 20,
                                  };
                                });
                              }
                            }}
                            useWindowScroll
                            overscan={200}
                            totalCount={triggersData?.data?.length}
                            atBottomThreshold={100}
                            id={"triggers-lazyload"}
                            components={{
                              Table: Table,
                              TableHead: TableHead,
                              TableRow: (props) => {
                                return (
                                  <TableRow
                                    {...props}
                                    hoveredId={hoveredId}
                                    setHoveredId={setHoveredId}
                                    //   showOrder={connectorViewState}
                                  />
                                );
                              },
                              TableFoot: (props) => {
                                if (
                                  triggersData?.data?.length ===
                                    paginateData?.total &&
                                  paginateData?.current_page > 1
                                ) {
                                  return (
                                    <tfoot
                                      className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                                      id={`NoMoreListing`}
                                    >
                                      <tr className="border-b bg-white">
                                        <td
                                          colSpan={11}
                                          align="center"
                                          className="h-[2.8125rem]"
                                        >
                                          No more items to show
                                        </td>
                                      </tr>
                                    </tfoot>
                                  );
                                } else {
                                  return null;
                                }
                              },
                              // ScrollSeekPlaceholder,
                            }}
                            fixedFooterContent={() => {
                              return (
                                <tr className="border-b bg-white">
                                  <td colSpan={0}>Hi</td>
                                </tr>
                              );
                            }}
                            fixedHeaderContent={() => (
                              <tr className="border-b bg-white">
                                <HeaderCell
                                  title="ID"
                                  width="w-[12rem]"
                                  arrow={false}
                                  postKey="id"
                                  padding="pl-5"
                                />
                                <HeaderCell
                                  title="Name"
                                  width="w-[12rem]"
                                  arrow={false}
                                  postKey="name"
                                  padding="pl-5"
                                />
                                <HeaderCell
                                  title="Request Type"
                                  width="w-[12rem]"
                                  arrow={false}
                                  postKey="request_type"
                                  padding="pl-5"
                                />
                                <HeaderCell
                                  title="Endpoint URL"
                                  // width="min-w-[6.875rem]"
                                  arrow={false}
                                  postKey="endpoint_url"
                                  padding="pl-5"
                                />

                                <th className="sticky right-0 ml-auto  p-0 w-[5.0625rem]">
                                  <div
                                    className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
                                    id="left-shad-list"
                                  >
                                    <button
                                      className="group rounded-l  hover: transition px-[.5625rem] py-2"
                                      id="leftArrow"
                                      type="button"
                                      onClick={(e: any) => scroll(-120)}
                                    >
                                      <IKTSVG
                                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                        path={angleLeftSVG}
                                        svgClassName="w-1.5 h-[.5625rem]"
                                      />
                                    </button>
                                    <button
                                      className="group rounded-r  transition px-[.5625rem] py-2"
                                      id="rightArrow"
                                      type="button"
                                      onClick={(e: any) => scroll(120)}
                                    >
                                      <IKTSVG
                                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                        path={angleRightSVG}
                                        svgClassName="w-1.5 h-[.5625rem]"
                                      />
                                    </button>
                                  </div>
                                </th>
                              </tr>
                            )}
                            itemContent={(sIndex: any, list: any) => {
                              return (
                                <React.Fragment key={sIndex}>
                                  {/* CHECKBOX */}
                                  <DataCell
                                    value={list?.id}
                                    classname="block"
                                    padding="pl-5"
                                    width="w-[12rem]"
                                  />
                                  <DataCell
                                    value={list?.name}
                                    classname="block"
                                    padding="pl-5"
                                    width="w-[12rem]"
                                  />

                                  <DataCell
                                    value={list?.request_type}
                                    classname="block"
                                    padding="pl-5"
                                    width="w-[12rem]"
                                  />
                                  <DataCell
                                    value={list?.endpoint_url}
                                    classname="block"
                                    padding="pl-5"
                                    // width="w-[7.75rem]"
                                  />
                                  <td className="group sticky right-0 ml-auto p-0 icon_td  border-b">
                                    <React.Fragment key={sIndex}>
                                      <div
                                      //   className={`${
                                      //     matchedView
                                      //       ? "bg-indigo-500 bg-opacity-[7%]"
                                      //       : ""
                                      //   } `}
                                      >
                                        <div
                                          className="flex left-shad relative w-full h-full border-l"
                                          id="left-shad-list"
                                        >
                                          <button
                                            className={`group flex items-center justify-center p-1 md:p-1 w-full`}
                                            type="button"
                                            id={`record-view-tooltip${sIndex}`}
                                            onClick={() => {
                                              handleTriggerView(list, sIndex);
                                            }}
                                          >
                                            <Tooltip
                                              anchorId={`record-view-tooltip${sIndex}`}
                                              content="View"
                                              place="top"
                                              variant="light"
                                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                            />
                                            <IKTSVG
                                              className="fill-violet-500 group-hover:fill-indigo-500 transition"
                                              path={eyeSVG}
                                              svgClassName="w-4 h-[.6875rem]"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </td>
                                  {/* </tr> */}
                                </React.Fragment>
                              );
                            }}
                            ref={virtuosoRef}
                            // height={data?.length * 40.5}
                          />
                        ) : (
                          <NoDataComponent />
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div
                  id="custom-loader-triggers"
                  className={`accordion bg-white font-medium pt-2.5 border-t `}
                >
                  <div
                    className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                    style={{ height: "30px" }}
                  ></div>
                  <div
                    className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                    style={{ height: "30px" }}
                  ></div>
                  {/* <div
            className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
            style={{ height: "30px" }}
          ></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TriggersDataTable;
