const CrosHandIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.688"
      height="14.5"
      viewBox="0 0 12.688 14.5"
      {...props}
    >
      <path
        id="hand-back-right-off"
        d="M13.03,13.733l-1.009-1.009a4.811,4.811,0,0,1-8.2-1.245L1.992,6.869a.6.6,0,0,1,.749-.8l.477.157a1.229,1.229,0,0,1,.749.7L4.82,9.063h.453V5.975L1.11,1.813l.773-.767L13.8,12.965l-.767.767M8.9.755a.755.755,0,0,0-1.51,0V5.015L8.9,6.525V.755m4.229,8.911V3.474a.755.755,0,1,0-1.51,0V7.25h-.6V1.661a.755.755,0,0,0-1.51,0V7.129l3.522,3.522a4.775,4.775,0,0,0,.1-.985m-6.344-7.7a.755.755,0,1,0-1.51,0V2.9l1.51,1.51Z"
        transform="translate(-1.11)"
        fill=""
      />
    </svg>
  );
};

export default CrosHandIcon;
