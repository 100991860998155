import moment from "moment";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TableVirtuoso } from "react-virtuoso";
import MinusOutline from "../../../../assets/media/icons/other_icons/minus-outline.svg";
import PlusOutLine from "../../../../assets/media/icons/other_icons/plus-outline.svg";
import UploadOutOutline from "../../../../assets/media/icons/other_icons/upload-out-outline.svg";
import UploadOutline from "../../../../assets/media/icons/other_icons/upload-outline.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { SYMBOLS } from "../../../../helpers/const";
import { TXContext } from "../../core/TXProvider";
import TableHeader from "./TableHeader";
import VirtualTableRow from "./VirtualTableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left"
    style={{
      ...style,
    }}
  />
);
const TableHead = ({ style, ...props }: any) => {
  return (
    <thead
      {...props}
      className="text-xs text-gray-400 "
      style={{
        ...style,
        position: "static",
      }}
    />
  );
};

const VirtualCardTransactionTable = ({
  isGodAdmin,
  data,
  paginateData,
  perPage,
}: any) => {
  const [height, setHeight] = useState<any | undefined>(undefined);

  const layout = useContext(TXContext);
  const globalLayout = useContext(LayoutContext);
  const loaderRef = useRef<any>(null);

  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector(
        "#virtual-card-transcation"
      );
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      if (divElement?.offsetHeight > 1) {
        let elemHeight = divElement.offsetHeight + theadHeight + 1;

        if (nomoreListingHeight) {
          elemHeight =
            divElement?.offsetHeight + theadHeight + nomoreListingHeight + 1;
        }
        setHeight(`${elemHeight}`);
      }
    }, 800);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    if (data?.length === paginateData?.total && paginateData?.current_page > 1)
      renderCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalLayout?.htmlFontSize,
    data,
    paginateData?.total,
    paginateData?.current_page,
    nomoreListing,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (globalLayout?.htmlFontSize) {
        renderCall();
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLayout?.htmlFontSize]);

  useEffect(() => {
    const handleScrollTable = () => {
      // Select all elements with class 'loadHead'
      const loadHeads: any = document.getElementById("sticky-head");
      const HeaderHeight: any = document.querySelector("header")?.clientHeight;
      const filters_block: any =
        document.getElementById("filters_block")?.clientHeight;
      const txFilter: any = document.getElementById("txFilter")?.clientHeight;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;
      const stickyElement = document.getElementById(
        "topFilters"
      ) as HTMLElement;
      var computedStyle = window.getComputedStyle(stickyElement);
      var paddingBottom = computedStyle.paddingBottom;
      var paddingBottomValue = parseInt(paddingBottom, 10);
      // const totleScroll = headingOne + inventoryaccordion;
      const totleScroll =
        window.innerWidth > 767
          ? headingOne + inventoryaccordion
          : headingOne + inventoryaccordion + txFilter;
      console.log(totleScroll, "totleScroll");
      // const topPosition: any =
      //   HeaderHeight + filters_block + txFilter + paddingBottomValue + -1;
      const topPosition: any =
        window.innerWidth > 767
          ? HeaderHeight + filters_block + txFilter + paddingBottomValue + -1
          : HeaderHeight;

      // Loop through each loadHead element
      // loadHeads.forEach((loadHead) => {

      // Check if loadHead is 300px from the top of the window
      const windScroll: number = window.scrollY;

      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;
      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        loadHeads?.classList.remove("opacity-0");
        loadHeads?.classList.remove("h-0");
        loadHeads?.classList.add("h-[2.5625rem]");
        loadHeads.style.top = topPosition + "px";
        // stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        loadHeads?.classList.add("opacity-0");
        loadHeads?.classList.add("h-0");
        loadHeads?.classList.remove("h-[2.5625rem]");
        loadHeads.style.top = 0;
      }
      // });
      // }
    };

    // Add scroll event listener
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("scroll", handleScrollTable);
      document.removeEventListener("resize", handleScrollTable);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (loaderRef.current) {
        loaderRef.current.classList.add("hidden");
      }
    }

    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (loaderRef.current) {
        loaderRef.current.classList.add("visible");
        loaderRef.current.classList.remove("hidden");
      }
    }
  }, [paginateData?.current_page, paginateData?.last_page]);

  const tableRef: any = useRef();
  const stickyTableRef: any = useRef();
  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };
  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };
  return (
    <div
      className="accordion mb-4 font-medium transition-all relative px-5 max-md:pb-14"
      id={`EventAccordion`}
    >
      {" "}
      <div
        id="sticky-head"
        className={`tableHead sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none xl:top-[12.375rem] top-[14.625rem] `}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left  lg:min-w-[87.5rem] absolute left-0 top-0">
          <thead className="text-xs text-gray-400 ">
            <tr className="border-b bg-white">
              {/* Team name */}
              {isGodAdmin && (
                <>
                  <TableHeader
                    title="Team name"
                    cellWidth="min-w-[11.25rem]"
                    isArrow={false}
                  />
                  <TableHeader
                    title="Card number"
                    cellWidth="min-w-[11.25rem]"
                    isArrow={false}
                  />
                </>
              )}

              {/* Date */}
              <TableHeader
                title="Date"
                cellWidth="min-w-[7.5rem]"
                isArrow={false}
              />

              {/* Type */}
              <TableHeader
                title="Type"
                cellWidth="min-w-[6.25rem]"
                isArrow={false}
              />

              {/* In */}
              <TableHeader
                title="In"
                cellWidth="min-w-[9.375rem]"
                isArrow={false}
              />

              {/* Out */}
              <TableHeader title="Out" cellWidth="min-w-[9.375rem]" />

              {/* Description */}
              <TableHeader
                title="Description"
                cellWidth="lg:w-full w-[20rem] max-lg:max-w-[20rem] max-lg:min-w-[20rem]"
              />
            </tr>
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
              //   ref={tableRef}
              id={`tableId1`}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
              ref={tableRef}
              onScroll={handleScrollVirtuoso}
            >
              <TableVirtuoso
                data={data}
                useWindowScroll
                overscan={200}
                totalCount={data?.length}
                id={`virtual-card-transcation`}
                endReached={() => {
                  if (paginateData?.current_page < paginateData?.last_page) {
                    layout.setVirtualFilterHandle((current: any) => {
                      let nextPage = paginateData?.current_page + 1;

                      return {
                        ...current,
                        page: nextPage,
                        per_page: perPage,
                      };
                    });
                  }
                }}
                fixedHeaderContent={() => (
                  <tr className="border-b bg-white">
                    {/* Team name */}
                    {isGodAdmin && (
                      <>
                        <TableHeader
                          title="Team name"
                          cellWidth="min-w-[11.25rem]"
                          isArrow={false}
                        />
                        <TableHeader
                          title="Card number"
                          cellWidth="min-w-[11.25rem]"
                          isArrow={false}
                        />
                      </>
                    )}

                    {/* Date */}
                    <TableHeader
                      title="Date"
                      cellWidth="min-w-[7.5rem]"
                      isArrow={false}
                    />

                    {/* Type */}
                    <TableHeader
                      title="Type"
                      cellWidth="min-w-[6.25rem]"
                      isArrow={false}
                    />

                    {/* In */}
                    <TableHeader
                      title="In"
                      cellWidth="min-w-[9.375rem]"
                      isArrow={false}
                    />

                    {/* Out */}
                    <TableHeader title="Out" cellWidth="min-w-[9.375rem]" />

                    {/* Description */}
                    <TableHeader
                      title="Description"
                      cellWidth="lg:w-full w-[20rem] max-lg:max-w-[20rem] max-lg:min-w-[20rem]"
                    />
                  </tr>
                )}
                fixedFooterContent={() => null}
                components={{
                  Table: Table,
                  TableHead: TableHead,
                  TableRow: (props) => {
                    return <VirtualTableRow {...props} />;
                  },
                  TableFoot: (props) => {
                    if (
                      data?.length === paginateData?.total &&
                      paginateData?.current_page > 1
                    ) {
                      return (
                        <tfoot
                          className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                          id="NoMoreListing"
                        >
                          <tr className="border-b bg-white">
                            <td
                              colSpan={11}
                              align="center"
                              className="h-[2.8125rem]"
                            >
                              No more items to show
                            </td>
                          </tr>
                        </tfoot>
                      );
                    } else {
                      return null;
                    }
                  },
                }}
                itemContent={(tIndex: any, list: any) => {
                  const date = moment(list?.date, "DD/MM/YYYY");

                  // Format the date as DD MMM YY
                  const formatedDate = date.format("DD MMM YY");
                  const matchedView = false;

                  return (
                    <>
                      <React.Fragment key={tIndex}>
                        {isGodAdmin && (
                          <>
                            <td
                              style={{ height: "2.5625rem" }}
                              className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                            >
                              <p className="w-[9.0625rem]">
                                {list?.team_name ? `${list?.team_name}` : "-"}
                              </p>
                            </td>
                            <td
                              style={{ height: "2.5625rem" }}
                              className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                            >
                              <p className="w-[9.0625rem]">
                                {list?.card_number
                                  ? `${list?.card_number}`
                                  : "-"}
                              </p>
                            </td>
                          </>
                        )}
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                        >
                          <p className="w-[6.25rem]">{formatedDate}</p>
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                        >
                          <div className="credit flex items-center">
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
                              path={
                                list?.type === "Credit"
                                  ? PlusOutLine
                                  : list?.type === "Debit"
                                  ? MinusOutline
                                  : list?.in
                                  ? UploadOutline
                                  : UploadOutOutline
                              }
                              svgClassName="w-3 h-3"
                            />
                            {list?.type}
                          </div>
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                        >
                          {list.in
                            ? `${SYMBOLS?.[list?.currency]}${list.in}`
                            : "-"}
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                        >
                          {list.out
                            ? `${SYMBOLS?.[list?.currency]}${list.out}`
                            : "-"}
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium ellips-text"
                        >
                          <p
                            className=" whitespace-nowrap text-ellipsis md:max-w-[31.25rem] max-w-[18.75rem]"
                            title={list.description}
                          >
                            {list.description ? `${list.description}` : "-"}
                          </p>
                        </td>
                      </React.Fragment>
                    </>
                  );
                }}
              />
            </div>
          </div>

          <div
            id="custom-loader"
            ref={loaderRef}
            className={`accordion bg-white font-medium pt-2.5 border-t `}
          >
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualCardTransactionTable;
