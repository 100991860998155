import _ from "lodash";
import { useContext } from "react";
import { InventoryContext } from "./InventoryProvider";

type TInventoryNoJSX = {
  event?: Record<string, any>;
  eventRecord?: Record<string, any>;
};

const InventoryNoJSX = ({ event, eventRecord }: TInventoryNoJSX) => {
  const layout = useContext(InventoryContext);
  const isTableEditable: [] = layout?.isTableEditable;

  // IS DISABLED ALL
  let disabledAll =
    _.size(layout?.isTableEditable) > 0 ||
    layout.isDeleteActive ||
    layout.isEditPopUpActive ||
    layout.isBulkActionLoader ||
    _.size(layout?.tableAlert?.confirm);

  // ROW DISABLED
  let isDisabled;
  let forRowDisabled: any;

  if (
    (eventRecord?.selected === false || eventRecord?.selected === undefined) &&
    isTableEditable.length > 0
  ) {
    isDisabled = "row-disabled";
    forRowDisabled = "";
  } else if (
    (eventRecord?.selected === false || eventRecord?.selected === undefined) &&
    isTableEditable.length === 0
  ) {
    isDisabled = "";
    forRowDisabled = "";
  } else if (eventRecord?.selected === true && isTableEditable.length === 0) {
    isDisabled = "";
    forRowDisabled = "";
  } else if (eventRecord?.selected === true && isTableEditable.length > 0) {
    isDisabled = "row-indigo-light row-indigo-light -selected";
    forRowDisabled = "pointer-events-none !border-gray-300";
  }

  // FE ERRORS
  const isEventRecordError =
    eventRecord?.fieldErrors &&
    Object.keys(eventRecord?.fieldErrors).length > 0;

  // BE ERRORS
  const isEventRecordBEError =
    eventRecord?.errors && Object.keys(eventRecord?.errors)?.length > 0;

  // ON HOLD ERROR
  const isEventRecordHoldTickets =
    eventRecord?.hold_tickets &&
    Object.keys(eventRecord?.hold_tickets).length > 0;

  // WHILE PUBLISHING ERROR
  const isALLTicketUploadedWithPublish =
    Number(eventRecord?.quantity_available) > 0 &&
    Number(eventRecord?.quantity_available) ===
      (Number(eventRecord?.listing_ticket_files?.length) ||
        Number(eventRecord?.mobile_links?.length)) &&
    eventRecord?.status === "yes";

  // HOLD TICKET
  let totalHold = 0;
  eventRecord?.hold_tickets &&
    Object.keys(eventRecord?.hold_tickets).length > 0 &&
    Object.values(eventRecord?.hold_tickets)?.map((holdData: any) => {
      totalHold = totalHold + holdData?.hold_quantity;
    });

  // TICKE TYPE VALUE
  let ticketTypeValue: any;
  if (typeof eventRecord?.ticket_type === "object") {
    if (
      eventRecord?.ticket_type === "eticket" &&
      eventRecord?.sub_ticket_type === "pdf"
    ) {
      ticketTypeValue = {
        id: "pdf",
        name: "PDF E-Ticket",
      };
    } else if (
      eventRecord?.ticket_type === "eticket" &&
      eventRecord?.sub_ticket_type === "mobile-qr"
    ) {
      ticketTypeValue = {
        id: "mobile-qr",
        name: "Mobile QR",
      };
    } else {
      ticketTypeValue = eventRecord?.ticket_type;
    }
  } else if (Array.isArray(layout.ticketType)) {
    let subTicket = !eventRecord?.sub_ticket_type?.trim()
      ? "pdf"
      : eventRecord?.sub_ticket_type;
    const foundTicket = layout.ticketType.find((ticket: any) => {
      if (eventRecord?.ticket_type === "eticket") {
        return ticket?.id === subTicket;
      } else {
        return ticket?.id === eventRecord?.ticket_type;
      }
    });

    ticketTypeValue = foundTicket;
  }

  // CATEGORY VALUE
  const categoryValue = eventRecord?.category?.id
    ? eventRecord?.category
    : event?.categories &&
      event?.categories.find(
        (category: any) => Number(category.id) === Number(eventRecord?.category)
      );

  // SECTION VALUE
  const sectionValue =
    eventRecord?.section && eventRecord?.section?.id
      ? eventRecord?.section
      : eventRecord?.section &&
        event?.categories &&
        Array.isArray(event?.categories) &&
        event?.categories
          .find(
            (category: any) =>
              Number(category.id) === Number(eventRecord?.category)
          )
          ?.sections?.find(
            (item: any) => Number(item?.id) === Number(eventRecord?.section)
          );

  // SPLIT TYPE
  let splitTypeValue: any =
    typeof eventRecord?.split_type === "object"
      ? eventRecord?.split_type
      : Array.isArray(layout.splitType) &&
        layout.splitType.find(
          (split: any) => split.id === eventRecord?.split_type
        );

  // BENEFITS VALUE
  const benefitsValue = eventRecord?.benefits?.[0]?.id
    ? eventRecord?.benefits
    : layout?.benefits.filter((benefit: any) =>
        eventRecord?.benefits?.includes(benefit.id)
      );

  // RESTRICTION VALUE
  const restrictionValue =
    eventRecord && eventRecord?.restrictions && eventRecord?.restrictions[0]?.id
      ? eventRecord?.restrictions
      : layout?.restrictions.filter((restriction: any) =>
          eventRecord?.restrictions?.includes(restriction.id)
        );

  // SPLIT CONDITION - FOR MULTIPLE
  let splitCondition: string = "";
  if (
    (typeof eventRecord?.split_type === "string" &&
      eventRecord?.split_type !== "MULTIPLES") ||
    (typeof eventRecord?.split_type === "object" &&
      eventRecord?.split_type?.id !== "MULTIPLES")
  ) {
    splitCondition =
      "pointer-events-none bg-gray-100 !border-gray-100 text-gray-400 placeholder:text-gray-400 disable";
  } else {
    splitCondition = "";
  }

  return {
    disabledAll,
    isDisabled,
    forRowDisabled,
    isEventRecordError,
    isEventRecordBEError,
    isEventRecordHoldTickets,
    isALLTicketUploadedWithPublish,
    ticketTypeValue,
    categoryValue,
    sectionValue,
    splitTypeValue,
    benefitsValue,
    restrictionValue,
    splitCondition,
    totalHold,
  };
};

export default InventoryNoJSX;
