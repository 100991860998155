import React from "react";
import { useNavigate } from "react-router-dom";
import { KTSVG } from "./KTSVG";

type Props = {
  title: string;
  disabled?: boolean;
};

const AddInventoryButton: React.FC<Props> = ({
  title = "Add Inventory",
  disabled = false,
}: any) => {


  const navigate = useNavigate();

  return (
    <button
      type="button"
      className={`relative inline-flex items-center z-10 text-white bg-violet-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase after:content-[''] after:h-2/4 after:w-full after:bg-violet-400 after:absolute after:top-0 after:left-0 after:rounded after:transition hover:after:opacity-0 after:-z-10 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`} // Add disabled styles if `disabled` prop is true
      onClick={() => !disabled && navigate("../add-inventory", { replace: true })}
    >
      <KTSVG className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-white" path="other_icons/plus.svg" />
      {title}
    </button>
    //     <button type="button" className="text-white bg-violet-500 hover:bg-indigo-500 transition
    //     font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase">
    //     <i className="text-xs fas fa-solid fa-plus pr-2"></i>Add Listing
    // </button>
  );
};

export default AddInventoryButton;
