import React from "react";
import CountBoxFilter from "./CountBoxFilter";
import FilterChips from "./FilterChips";
import HeaderFilter from "./HeaderFilter";

const CommonFilters = ({ loading }: any) => {
  return (
    <div>
      <HeaderFilter />
      <CountBoxFilter loading={loading} />
      <FilterChips loading={loading} />
    </div>
  );
};

export default CommonFilters;
