const ListingLines = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10" {...props}>
      <g
        id="Group_1786"
        data-name="Group 1786"
        transform="translate(-1485 -406.5)"
      >
        <rect
          id="Rectangle_28668"
          data-name="Rectangle 28668"
          width="100%"
          height="1.5"
          rx="0.75"
          transform="translate(1485 406.5)"
        />
        <rect
          id="Rectangle_28670"
          data-name="Rectangle 28670"
          width="100%"
          height="1.5"
          rx="0.75"
          transform="translate(1485 410.24)"
        />
        <rect
          id="Rectangle_28671"
          data-name="Rectangle 28671"
          width="100%"
          height="1.5"
          rx="0.75"
          transform="translate(1485 414)"
        />
      </g>
    </svg>
  );
};

export default ListingLines;
