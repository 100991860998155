import _ from "lodash";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import AlgoliaAll from "../UI/AlgoliaAll";

const ContentPage = () => {
  const { contentFilter, setContentFilter } = useCustomize();

  const handleHitSelect = (data: any) => {
    const { id, name, componentType } = data;

    if (id) {
      setContentFilter((currentValue: any) => {
        let updatedRecord = { name: name, id: id, type: componentType?.name };

        const existingIndex = _.findIndex(
          currentValue?.eventName,
          (event: any) => event.type === componentType?.name
        );

        let updatedEventName;

        if (existingIndex !== -1) {
          updatedEventName = [...currentValue?.eventName];
          updatedEventName[existingIndex] = updatedRecord;
        } else {
          updatedEventName = [
            ...(currentValue?.eventName || []),
            updatedRecord,
          ];
        }

        return {
          ...currentValue,
          eventName: updatedEventName,
          page: 1,
        };
      });
    }
  };

  return (
    <>
      <AlgoliaAll
        index={1}
        value={null}
        isValuesNeeded={false}
        handleHitSelect={handleHitSelect}
        // inputValue={inputValue ? inputValue : }
      />
    </>
  );
};

export default ContentPage;
