import { Tooltip } from "react-tooltip";
import mobileSVG from "../../../assets/media/icons/other_icons/mobile.svg";
import pinSVG from "../../../assets/media/icons/other_icons/pin.svg";
import ticketSVG from "../../../assets/media/icons/standard_icons/ticket-1.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";

const UploadInstruction = ({ data, children }: any) => {
  return (
    <div className="border rounded h-full bg-white">
      <div className="title border-b">
        <div className="flex flex-wrap">
          <div className="flex border-r px-3 py-2">
            <IKTSVG
              className={`min-w-4 max-w-4 flex items-center justify-center transition`}
              svgClassName={`${
                data?.type === "Paper"
                  ? `w-[.8125rem] h-[.6875rem]`
                  : data?.type === "E-Ticket"
                  ? `w-[.6875rem] h-[.875rem]`
                  : `w-[.625rem] h-[.9375rem]`
              }`}
              path={
                data?.type === "Paper"
                  ? ticketSVG
                  : data?.type === "E-Ticket"
                  ? pinSVG
                  : mobileSVG
              }
            />
            <Tooltip
              anchorId={`assigned-ticket-${data?.type}`}
              content={data?.type}
              place="top"
              variant="light"
              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
            />
          </div>
          <div className="flex px-3 py-2">
            <span className="md:text-sm15 text-sm12 block font-semibold">
              {data?.heading}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap px-5 py-3 font-medium">
        {children ? (
          children
        ) : (
          <ol className="list-decimal px-2.5 ">
            {data?.listing?.map((item: any) => {
              return <li className="text-sm12 break-words">{item}</li>;
            })}
          </ol>
        )}
      </div>
    </div>
  );
};

export default UploadInstruction;
