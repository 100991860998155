import { LayoutContext } from "components/core/LayoutProvider";
import PriceField from "components/formComponent/PriceField";
import { useFormik } from "formik";
import { SYMBOLS } from "helpers/const";
import { decimalNumber } from "helpers/Functions";
import { isArray } from "lodash";
import { useContext, useMemo, useState } from "react";
import * as Yup from "yup";
import circle from "../../../assets/media/icons/other_icons/circle.svg";
import info from "../../../assets/media/icons/other_icons/info.svg";
import visalogo from "../../../assets/media/icons/other_icons/visaLogo.svg";
import Cross from "../../../assets/media/icons/standard_icons/cross.svg";
import logo from "../../../assets/media/icons/tix_logo.svg";
import DropDownField from "../../../components/formComponent/DropDownField";
import Textfield from "../../../components/formComponent/Textfield";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { colorVirtualCard } from "../../../helpers/AssetHelpers";
import { showAlert } from "../../../helpers/ShowAlert";
import { addVirtualCard } from "../core/requests";

const customAmountError =
  ({
    headerOptions,
    cardAmountLimit,
    formattedUserBalance,
    formattedCardAmountLimit,
  }: any) =>
  (value: any, { createError, path }: any) => {
    const amount = Number(value) || 0;
    if (amount < cardAmountLimit)
      return createError({
        path,
        message: `A minimum amount of ${formattedCardAmountLimit} is required.`,
      });
    else if (
      cardAmountLimit > headerOptions?.available_amount &&
      amount === cardAmountLimit
    )
      return createError({
        path,
        message: `Please ensure your balance is at least ${formattedCardAmountLimit}.`,
      });
    else if (amount > headerOptions?.available_amount)
      return createError({
        path,
        message: `Insufficient balance. Your current balance is ${formattedUserBalance}.`,
      });

    return true;
  };

const AddVirtualCardPopup = ({
  setVirtualCardList,
  virtualCardListRefech,
  options,
  headerOptions,
  isCardLimitOver,
  remainingCardsNumber,
  refetch,
}: any) => {
  const [addCardLoader, setAddCardLoader] = useState<any>(false);
  const color_code = [
    {
      id: "dark-purple",
      name: "Dark purple",
      label: "Dark purple",
      value: "Dark purple",
      icon: circle,
    },
    {
      id: "dark-green",
      name: "Dark green",
      label: "Dark green",
      value: "Dark green",
      icon: circle,
    },
    {
      id: "dark-blue",
      name: "Dark blue",
      label: "Dark blue",
      value: "Dark blue",
      icon: circle,
    },
    {
      id: "dark-parrot-green",
      name: "Dark parrot green",
      label: "Dark parrot green",
      value: "Dark parrot green",
      icon: circle,
    },
    {
      id: "dark-purple-blue",
      name: "Dark purple blue",
      label: "Dark purple blue",
      value: "Dark purple blue",
      icon: circle,
    },
  ];

  const { sellerLevelData, currencyLabel } = useContext(LayoutContext);

  const cardAmountLimit = useMemo(() => {
    const tx_cards_money_limit = isArray(sellerLevelData?.config)
      ? sellerLevelData?.config.find(
          (card: any) => card.key === "tx_cards_money_limit"
        )?.value
      : sellerLevelData?.config?.tx_cards_limit;

    return Number(tx_cards_money_limit) || 0;
  }, [sellerLevelData?.config]);

  const formattedUserBalance = useMemo(
    () =>
      headerOptions?.available_amount
        ? `${SYMBOLS[currencyLabel]}${decimalNumber(
            headerOptions?.available_amount
          )}`
        : "0",
    [currencyLabel, headerOptions?.available_amount]
  );

  const formattedCardAmountLimit = useMemo(
    () =>
      cardAmountLimit
        ? `${SYMBOLS[currencyLabel]}${decimalNumber(cardAmountLimit)}`
        : "0",
    [cardAmountLimit, currencyLabel]
  );

  // Regular expression for phone number validation
  const phoneRegExp = /^\+?\d+(\s?\d+)?$/;

  const addCardSchema = Yup.object().shape({
    cardName: Yup.string().required("Card alias is required"),
    first_name: Yup.string().required("First name is required"),
    amount: Yup.object().shape({
      value: Yup.string().required("Amount is required").test(
        "validator-custom-name",
        customAmountError({
          headerOptions,
          cardAmountLimit,
          formattedUserBalance,
          formattedCardAmountLimit,
        })
      ),
    }),
    last_name: Yup.string().required("Last name is required"),
    phone: Yup.string()
      // .required("Phone number is required")
      .matches(phoneRegExp, "That doesn't look like a phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long"),
    // address_line_1: Yup.string().required("Address line 1 is required"),
    // city: Yup.string().required("City is required"),
    // postcode: Yup.string().required("Postcode is required"),
    // country: Yup.object().nullable().required("Country is required"),
  });

  const initialValues = {
    cardName: "",
    color: {
      id: "dark-purple",
      name: "Dark purple",
      label: "Dark purple",
      value: "Dark purple",
      icon: circle,
    },
    first_name: "",
    last_name: "",
    phone: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    postcode: "",
    amount: { value: cardAmountLimit ? cardAmountLimit : "" },
    country: null,
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema: addCardSchema,
    onSubmit: (values: any) => {
      setAddCardLoader(true);

      const data = {
        ...(values?.cardName && { card_alias: values?.cardName }),
        ...(values?.first_name && { first_name: values?.first_name }),
        ...(values?.last_name && { last_name: values?.last_name }),
        ...(values?.phone && { phone: values?.phone }),

        ...(values?.address_line_1 && { address1: values?.address_line_1 }),

        ...(values?.address_line_2 && { address2: values?.address_line_2 }),
        ...(values?.city && { city: values?.city }),
        ...(values?.postcode && { postcode: values?.postcode }),
        ...(values?.amount?.value && { amount: values?.amount?.value }),
        ...(values?.country?.iso_code && {
          country: values?.country?.iso_code,
        }),
        ...(colorVirtualCard(
          typeof values?.color === "object"
            ? values?.color?.name
            : values?.color
        ) && {
          card_color: colorVirtualCard(
            typeof values?.color === "object"
              ? values?.color?.name
              : values?.color
          ),
        }),

        // state: "MI",
      };

      addVirtualCard(data)
        .then((res) => {
          showAlert(
            res?.data?.message || res?.errors?.title || res?.errors[0],
            !res.data.status
          );
          if (res.data.status) {
            setVirtualCardList((pre: any) => [
              ...pre,
              { ...values, loader: true },
            ]);
            virtualCardListRefech();
            refetch();

            const closeBtn = document.getElementById("add-card-close");
            if (closeBtn) {
              formik.resetForm();
              closeBtn.click();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setAddCardLoader(false);
        });
    },
  });

  if (isCardLimitOver) return null;

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <div
        className={` offcanvas upload-ticket-popup  offcanvas-end  fixed bottom-0 top-[3.875rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[43.125rem] max-w-full w-full `}
        tabIndex={-1}
        id="offcanvasAddcard"
        aria-labelledby="offcanvasAddcardLabel"
        // data-bs-backdrop="false"
      >
        <div className="relative   flex flex-col h-full bg-white ml-auto">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5 className="offcanvas-title mb-0 leading-normal font-semibold py-2 px-5 text-sm15">
              Add a virtual card {`(${remainingCardsNumber} remaining)`}
            </h5>
            <button
              type="button"
              id="close-setting"
              className="flex items-center justify-center h-[2.375rem] w-10 border-l box-content group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                formik.resetForm();
              }}
            >
              <IKTSVG
                className="fill-violet-500 group-hover:fill-indigo-500 transition"
                path={Cross}
                svgClassName="w-[.6875rem] h-[.6875rem]"
              />
            </button>
          </div>

          <div className="offcanvas-body max-h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 h-full">
            <div className="card_info bg-gray-100/50 p-[1.875rem] rounded-lg">
              <div className="cardInfoWrap max-w-[22.375rem] m-auto">
                <div
                  className="card_detail_top min-h-[13.75rem] bg-vir_card_bg bg-no-repeat bg-left-bottom relative z-0 rounded-xl -m-[.0625rem] shadow-op21"
                  style={{
                    backgroundColor: colorVirtualCard(
                      typeof formik.values?.color === "object"
                        ? formik.values?.color?.name
                        : formik.values?.color
                    ),
                  }}
                  // style={{ backgroundColor: "#5020A6" }}
                >
                  <span className="bg-gradient-to-br from-white/0 to-white/0 via-white absolute left-0 top-0 w-full h-full opacity-[7%] -z-[1]"></span>
                  <div className="card-detail p-5 absolute top-0 left-0 w-full h-full flex justify-between flex-col">
                    <div className="top-card flex items-center justify-between">
                      <div className="logo card-svg-logo">
                        <IKTSVG
                          path={logo}
                          svgClassName="w-[9.9375rem] h-6 fill-white"
                        />
                      </div>
                      <span className="text-sm12 text-white ellips-text max-w-[8.125rem]">
                        {formik.values.cardName}
                      </span>
                    </div>
                    <div className="bottom-card flex items-center justify-between">
                      <span className="text-sm15 text-white">
                        •••• •••• •••• ••••
                      </span>
                      <div className="logo">
                        <IKTSVG
                          path={visalogo}
                          svgClassName="w-[3.875rem] h-5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="details mt-5">
                  <div className="detail-row flex items-center justify-between text-sm13 leading-4 mb-2.5">
                    <span className="font-semibold">Status</span>
                    <span className="text-gray-500">
                      {/* {typeof data?.status === "object"
                      ? data?.status?.id
                      : data?.status} */}
                      Pending request
                    </span>
                  </div>
                  <div className="detail-row flex items-center justify-between text-sm13 leading-4 mb-2.5">
                    <span className="font-semibold">Card number</span>
                    <span className="text-gray-500">
                      — &nbsp;— &nbsp;— &nbsp;—
                    </span>
                  </div>
                  <div className="detail-row flex items-center justify-between text-sm13 leading-4 mb-2.5">
                    <span className="font-semibold">Expiry date</span>
                    <span className="text-gray-500">—</span>
                  </div>
                  <div className="detail-row flex items-center justify-between text-sm13 leading-4">
                    <span className="font-semibold">CVV</span>
                    <span className="text-gray-500">—</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card_options mt-5">
              <div className="p-[.9375rem] border rounded border-gray-200">
                <h4 className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
                  Card personalisation
                </h4>
                <div className="formField whiteFormFields mb-5 flex flex-wrap flex-col last:mb-0">
                  <label className="text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                    Card alias*
                  </label>
                  <div className="relative">
                    <Textfield
                      height={`!h-[1.875rem] !text-sm13`}
                      name="cardName"
                      value={formik.values.cardName}
                      handleOnChange={formik.handleChange}
                      errorClass={
                        formik.errors.cardName &&
                        formik.touched.cardName &&
                        "border-rose-500"
                      }
                    />
                    {formik.errors.cardName && formik.touched.cardName && (
                      <div className="text-xxs text-rose-500 text-center error-msg left-3">
                        {formik.errors.cardName as string}
                      </div>
                    )}
                  </div>
                </div>

                <div className="formField whiteFormFields smallDropdown mb-5 flex flex-wrap flex-col last:mb-0">
                  <label className="text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                    Card colour
                  </label>
                  <DropDownField
                    placeholder={``}
                    options={color_code}
                    value={formik.values.color}
                    name="color"
                    handleOnChange={formik.handleChange}
                    menuPortalTarget={true}
                    menuPosition={"top"}
                    menuPlacement={"top"}
                    isClearable={false}
                  />
                </div>
              </div>
            </div>

            {/* Transfer from your TX Pay Wallet */}
            <div className="card_options mt-5">
              <div className="p-[.9375rem] border rounded border-gray-200">
                <h4 className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
                  Transfer from your TX Pay Wallet
                </h4>

                <div className="formField whiteFormFields mb-5 flex flex-wrap flex-col last:mb-0 max-w-[20rem]">
                  <label className="text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                    Amount*
                  </label>
                  <div className="relative">
                    <PriceField
                      textfieldType="number"
                      name="amount"
                      value={
                        formik.values?.amount
                          ? typeof formik.values?.amount === "object"
                            ? formik.values?.amount?.value
                            : formik.values?.amount
                          : ""
                      }
                      handleOnChange={(data: any) => {
                        let event = {
                          target: {
                            name: data.target.name,
                            value: {
                              name: data.target.name,
                              value: data.target?.value?.value,
                              symbol: data?.target?.value?.symbol,
                            },
                          },
                        };
                        formik.handleChange(event);
                      }}
                      currenncySymbol={currencyLabel}
                    />
                    {formik.errors.amount?.value &&
                      formik.touched.amount?.value && (
                        <div className="text-xxs text-rose-500  error-msg left-11">
                          {formik.errors.amount.value as string}
                        </div>
                      )}
                  </div>
                  <span className="text-sm13 text-gray-500 font-medium mt-1.5">
                    Balance {formattedUserBalance}
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <div className="p-[.9375rem] border rounded border-gray-200">
                {/* Success msg */}
                <h4 className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
                  Account holder details
                </h4>
                <div className="flex items-center font-medium px-2.5 py-2 bg-gray-100/50 bg-opacity-[70%] rounded-lg text-sm13 leading-4 w-full mb-[.9375rem]">
                  <IKTSVG
                    path={info}
                    svgClassName="w-3.5 h-3.5 fill-indigo-500 mr-2"
                  />
                  Virtual cards can only be created using your main Tixstock
                  account holder details
                </div>
                {/* End Success msg */}
                <div className="flex flex-wrap -mx-2 [&_input]:bg-gray-100 [&_input]:border-transparent">
                  <div className="formField px-2 mb-[.9375rem] last:mb-0 w-full md:w-1/2">
                    <label className="block text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                      First name*
                    </label>
                    <div className="relative">
                      <Textfield
                        height={`!h-[1.875rem]  !text-sm13`}
                        name="first_name"
                        value={formik.values.first_name}
                        handleOnChange={formik.handleChange}
                        errorClass={
                          formik.errors.first_name &&
                          formik.touched.first_name &&
                          "!border-rose-500"
                        }
                      />
                      {formik.errors.first_name &&
                        formik.touched.first_name && (
                          <div className="text-xxs text-rose-500 text-center error-msg left-3">
                            {formik.errors.first_name as string}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="formField px-2 mb-[.9375rem] last:mb-0 w-full md:w-1/2">
                    <label className="block text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                      Last name*
                    </label>
                    <div className="relative">
                      <Textfield
                        height={`!h-[1.875rem]  !text-sm13`}
                        name="last_name"
                        value={formik.values.last_name}
                        handleOnChange={formik.handleChange}
                        errorClass={
                          formik.errors.last_name &&
                          formik.touched.last_name &&
                          "!border-rose-500"
                        }
                      />
                      {formik.errors.last_name && formik.touched.last_name && (
                        <div className="text-xxs text-rose-500 text-center error-msg left-3">
                          {formik.errors.last_name as string}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="formField px-2 mb-[.9375rem] last:mb-0 w-full">
                    <label className="block text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                      Phone
                    </label>
                    <div className="relative">
                      <Textfield
                        height={`!h-[1.875rem] !text-sm13`}
                        name="phone"
                        value={formik.values.phone}
                        handleOnChange={formik.handleChange}
                        errorClass={
                          formik.errors.phone &&
                          formik.touched.phone &&
                          "!border-rose-500"
                        }
                      />
                      {formik.errors.phone && formik.touched.phone && (
                        <div className="text-xxs text-rose-500 text-center error-msg left-3">
                          {formik.errors.phone as string}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="formField px-2 mb-[.9375rem] last:mb-0 w-full">
                    <label className="block text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                      Address line 1
                    </label>
                    <div className="relative">
                      <Textfield
                        height={`!h-[1.875rem] !text-sm13`}
                        name="address_line_1"
                        value={formik.values.address_line_1}
                        handleOnChange={formik.handleChange}
                        errorClass={
                          formik.errors.address_line_1 &&
                          formik.touched.address_line_1 &&
                          "!border-rose-500"
                        }
                      />
                      {formik.errors.address_line_1 &&
                        formik.touched.address_line_1 && (
                          <div className="text-xxs text-rose-500 text-center error-msg left-3">
                            {formik.errors.address_line_1 as string}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="formField px-2 mb-[.9375rem] last:mb-0 w-full">
                    <label className="block text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                      Address line 2
                    </label>
                    <div className="relative">
                      <Textfield
                        height={`!h-[1.875rem] !text-sm13`}
                        name="address_line_2"
                        value={formik.values.address_line_2}
                        handleOnChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="formField px-2 mb-[.9375rem] last:mb-0 w-full md:w-1/2">
                    <label className="block text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                      City
                    </label>
                    <div className="relative">
                      <Textfield
                        height={`!h-[1.875rem] !text-sm13`}
                        name="city"
                        value={formik.values.city}
                        handleOnChange={formik.handleChange}
                        errorClass={
                          formik.errors.city &&
                          formik.touched.city &&
                          "!border-rose-500"
                        }
                      />
                      {formik.errors.city && formik.touched.city && (
                        <div className="text-xxs text-rose-500 text-center error-msg left-3">
                          {formik.errors.city as string}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="formField px-2 mb-[.9375rem] last:mb-0 w-full md:w-1/2">
                    <label className="block text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                      Postcode
                    </label>
                    <div className="relative">
                      <Textfield
                        height={`!h-[1.875rem] !text-sm13`}
                        name="postcode"
                        value={formik.values.postcode}
                        handleOnChange={formik.handleChange}
                        errorClass={
                          formik.errors.postcode &&
                          formik.touched.postcode &&
                          "!border-rose-500"
                        }
                      />
                      {formik.errors.postcode && formik.touched.postcode && (
                        <div className="text-xxs text-rose-500 text-center error-msg left-3">
                          {formik.errors.postcode as string}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="smallDropdown formField px-2 mb-[.9375rem] last:mb-0 w-full ">
                    <label className="block text-gray-500 text-sm12 font-medium leading-[.8125rem] mb-[.3125rem]">
                      Country
                    </label>
                    <div className="relative inner-inp">
                      {/* <Textfield
                      height={`!h-10  `}
                      name="country"
                      value={formik.values.country}
                      handleOnChange={formik.handleChange}
                      errorClass={
                        formik.errors.country &&
                        formik.touched.country &&
                        "!border-rose-500"
                      }
                    />
                    {formik.errors.country && formik.touched.country && (
                      <div className="text-xxs text-rose-500 text-center error-msg left-3">
                        {formik.errors.country as string}
                      </div>
                    )} */}
                      <DropDownField
                        isCapital={true}
                        isValueCapital={true}
                        options={options?.country_list}
                        placeholder=""
                        isClearable={true}
                        divClassName={`md:mb-5 mb-[.9375rem] relative add-card-country-drop`}
                        className={` ${
                          formik.touched.country &&
                          formik.errors?.country &&
                          "all-dropdown-errorField"
                        }`}
                        name="country"
                        value={formik.values.country}
                        handleOnChange={(data: any) => {
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          );
                        }}
                      >
                        {formik.touched.country && formik.errors.country && (
                          <div
                            tabIndex={-1}
                            className="text-xxs text-rose-500 error-msg left-3"
                          >
                            {formik.errors.country}
                          </div>
                        )}
                      </DropDownField>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="offcanvas-footer border-t py-3 px-5 false text-right">
            <button
              type="button"
              id={"add-card-close"}
              className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
        bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13  py-[.1875rem] leading-[1rem] px-2.5 mr-2"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => formik.resetForm()}
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={addCardLoader}
              className={` transition py-[.1875rem] leading-[1rem] px-2.5 text-sm13  border border-violet-500 bg-violet-500 text-white hover:bg-indigo-500 hover:border-indigo-500 rounded disabled:pointer-events-none disabled:bg-gra`}
              // onClick={handleOnConfirm}
            >
              Request card
            </button>
          </div>
          {addCardLoader && <ProgressBardLoader secondCounter={2} />}
        </div>
      </div>
    </form>
  );
};

export default AddVirtualCardPopup;
