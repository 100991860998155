import Dexie from "dexie";

const db = new Dexie("myDatabase");

db.version(3).stores({
  myNewStore: "++id,age",
});

export default db;

// UPDATE RECORD IN INDEX DB
export const handleUpdateRecord = async (data: any) => {
  await (db as any).myNewStore.put({
    ...data,
    setConversationRate: [],
  });
};

// CLEAR FROM DB
export const ClearIndexDB = async () => {
  await (db as any).myNewStore.clear();
};
