/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
// import { Footer, Select } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";
import SearchVioletSVG from "../../assets/media/icons/other_icons/search-violet.svg";
import { LayoutContext } from "../../components/core/LayoutProvider";
import PageTitle from "../../components/core/PageTitle";
import { KTSVG } from "../../components/ui/KTSVG";
import faqs from "../../local-json/faqs.json";
import { commonFaq } from "../../local-json/utis";
import FAQCategory from "./components/FAQCategory";
import FAQSidebarList from "./components/FAQSidebarList";
import InputField from "./components/InputField";

const Faq = () => {
  const layout = useContext(LayoutContext);
  const access = layout.allowedAccess?.allowed_access;
  const txTrade = access?.some(
    (item: any) => (item.id === "txtrade" && item.status === true) || false
  );

  const navigate = useNavigate();
  const [classAdd, setClassAdd] = useState<any>();

  const [searchFAQ, setSearchFAQ] = useState<any>();
  const handleOnChange = (data: any) => {
    setSearchFAQ(data.target.value);
  };
  let filteredFaq = commonFaq.filter(
    (obj) =>
      obj?.que.toLowerCase().includes(searchFAQ?.toLowerCase()) ||
      obj?.ans.toLowerCase().includes(searchFAQ?.toLowerCase())
  );

  const handleEnterKeyPress = (event: any) => {
    if (searchFAQ && event.key === "Enter") {
      navigate({
        pathname: "/faq/search",
        // search: `?${createSearchParams({ q: searchFAQ })}`,
        search: searchFAQ,
      });
    }
  };

  const ref: any = useRef();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current !== null && !ref.current?.contains(event.target)) {
        setClassAdd(!classAdd);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  function removeKey(obj: any, keyToRemove: any) {
    if (obj.hasOwnProperty(keyToRemove)) {
      delete obj[keyToRemove];
    }
    return obj;
  }

  let filteredFaqs = faqs;
  if (!txTrade) {
    filteredFaqs = removeKey(filteredFaqs, "tx-trade");
    filteredFaq = filteredFaq?.filter((obj: any) => obj?.slug !== "tx-trade");
  }

  // IF TX TRADE USER THEN DISABLE LISTING AND SALES
  if (layout?.isTxTradeUser === 1) {
    filteredFaqs = removeKey(filteredFaqs, "listings");
    filteredFaqs = removeKey(filteredFaqs, "sale");
    filteredFaq = filteredFaq?.filter(
      (obj: any) => obj?.slug !== "listing" && obj?.slug !== "sales"
    );
  }

  return (
    <>
      <PageTitle title="FAQ Centre" />

      <div className="main-content-wrap">
        <div className="flex flex-wrap">
          <div
            ref={ref}
            className="sidebar lg2:max-w-[15.0625rem] border-r p-5 w-full bg-white lg2:min-h-[calc(100vh-3.9375rem)] lg2:relative lg2:top-0 sticky top-[3.75rem] z-20"
          >
            <div
              className="group lg2:hidden block mob_sb_menu relative  [&[aria-expanded='true'] .dd_arrow]:rotate-180"
              data-te-collapse-init
              data-te-collapse-collapsed
              data-te-target="#collapseOne5"
              aria-expanded="false"
              aria-controls="collapseOne5"
            >
              <span className="block w-full border rounded px-[.9375rem] py-2.5 text-sm13 font-medium">
                Overview
              </span>
              <span className="dd_arrow absolute right-3 top-1/2 -translate-y-1/2 group-[[data-te-collapse-collapsed]]:rotate-0 rotate-180 transition">
                <span className="w-2.5 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-violet-500">
                  <KTSVG
                    className="w-2.5 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-violet-500"
                    path="other_icons/down-angle.svg"
                    svgClassName="w-2.5 h-2.5"
                  />
                </span>
              </span>
            </div>
            <div
              className={`${
                classAdd ? "hidden" : ""
              } sb_menus lg2:!h-auto lg2:sticky lg2:top-20 lg2:overflow-auto lg2:block hidden absolute left-0 w-full top-full lg2:p-0 px-5 z-20 bg-white`}
              id="collapseOne5"
              data-te-collapse-item
              data-te-collapse-collapsed
              aria-labelledby="headingOne5"
            >
              {filteredFaqs &&
                Object.values(filteredFaqs).map((item: any) => {
                  return (
                    item?.slug === "tx-trade-policy" && (
                      <FAQSidebarList
                        title={item.title}
                        path={item.icon}
                        slug={item.slug}
                        currentMenu=""
                        setClassAdd={setClassAdd}
                        classAdd={classAdd}
                      />
                    )
                  );
                })}
            </div>
          </div>

          <div className="sb_content lg2:max-w-[calc(100%-15.0625rem)] w-full bg-no-repeat bg-overviewpattern bg-cover bg-center bg-violet-300">
            <div className="searchBox-width flex justify-center py-4 px-5 bg-white/40">
              {/* <div className="relative flex-1 max-w-[27rem] md:mb-0 mb-2">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <button>
                    <KTSVG
                      className="fill-violet-500"
                      svgClassName="h-3.5 w-3.5"
                      path="other_icons/search-violet.svg"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="border font-medium bg-white border-indigo-500/20 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-8 pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                  placeholder="Search FAQs"
                  required
                />
              </div> */}
              <InputField
                placeholder="Search FAQs"
                autoComplete="off"
                id="searchfaqs"
                className="border font-medium bg-white border-indigo-500/20 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-8 pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                name="searchfaqs"
                imgPath={SearchVioletSVG}
                value={searchFAQ}
                handleOnChange={(data: any) => handleOnChange(data)}
                onKeyPress={handleEnterKeyPress}
              />

              {searchFAQ && (
                <div className="suggestion rounded shadow-lt scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full bg-white w-full md:max-w-[27rem] max-w-[calc(100%-2.5rem)]">
                  <div className="sb_content  w-full bg-no-repeat bg-cover bg-center bg-white">
                    <div className="p-5 ">
                      <div id="table-shrink" className="">
                        {filteredFaq?.length > 0 ? (
                          filteredFaq?.map((list: any, fIndex: any) => {
                            // const matchedView = showFAQ?.index === fIndex;
                            return (
                              <React.Fragment key={fIndex}>
                                <div
                                  className={`single-item bg-gray-100/50 hover:bg-indigo-500/10 transition-all rounded mb-2.5 cursor-pointer hover:bg-indigo-500 hover:bg-opacity-[7%]`}
                                >
                                  <Link
                                    to={`/faq/${list.slug}`}
                                    state={{ data: list }}
                                  >
                                    <div className="flex justify-between faq-list">
                                      <div className="accordion_wrap px-5 py-[1.125rem] flex-1">
                                        <h4 className="text-[15px] font-semibold mb-3">
                                          {list.que}
                                        </h4>
                                        <p
                                          className="text-[13px] text-gray-600 line-clamp-1 [&>a:hover]:text-indigo-500"
                                          dangerouslySetInnerHTML={{
                                            __html: list.ans,
                                          }}
                                        >
                                          {/* {list.ans} */}
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <p className="text-[13px] text-gray-600 line-clamp-1">
                            No Results
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="p-5 ">
              <div className="flex flex-wrap -mx-2.5">
                {filteredFaqs &&
                  Object.values(filteredFaqs).map(
                    (item: any) =>
                      item.title !== "Overview" &&
                      item?.slug === "tx-trade-policy" && (
                        <FAQCategory
                          title={item.title}
                          description={item.desc}
                          // path={item.icon}
                          slug={item.slug}
                          path={item.boxIcon}
                          width={item.boxIconwidth}
                          height={item.boxIconheight}
                        />
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};
export default Faq;
