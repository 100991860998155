import PageTitle from "components/core/PageTitle";
import { SellerLevelsProvider } from "./core/SellerLevelsProvider";
import SellerLevels from "./SellerLevels";

const SellerLevelsWrapper = () => {
  return (
    <SellerLevelsProvider>
      <PageTitle title="Seller levels" />
      <SellerLevels />
    </SellerLevelsProvider>
  );
};

export default SellerLevelsWrapper;
