import { decimalNumber } from "../../../../../helpers/Functions";

const SellarPenalityDetails = ({
  amount,
  currenncy,
  inputValue,
  checkedValue,
  onChange,
  confirmation,
  sellarName,
}: any) => {
  let DisabledRadioBtn: any =
    confirmation || Number(inputValue) > Number(amount);

  return (
    <div
      className={`seller-details md:pl-5 md:p-[.9375rem] p-2.5 ${
        confirmation && "!pb-0"
      } `}
    >
      <div className="flex gap-5 items-center">
        <p className="text-sm13 font-medium">
          Seller name: <span>{sellarName}</span>{" "}
        </p>
        <p className="text-sm13 font-medium">
          Wallet balance:{" "}
          <span>
            {currenncy}
            {decimalNumber(amount)}
          </span>
        </p>
        <div className="flex gap-3 items-center">
          <p className="text-sm13 font-medium">
            Do you want to deduct from the wallet?
          </p>
          <div>
            <label
              className={`relative inline-flex items-center cursor-pointer ${
                DisabledRadioBtn && `disabled`
              } [&.disabled>div:after]:bg-gray-400 [&.disabled]:pointer-events-none ${
                DisabledRadioBtn && "pointer-events-none"
              }`}
            >
              <input
                type="checkbox"
                name="deduct_from_wallet"
                value=""
                className="sr-only peer"
                checked={checkedValue}
                onChange={(e: any) => onChange(e)}
              />
              <div
                className={`${
                  DisabledRadioBtn
                    ? "pointer-events-none bg-gray-100 peer-checked:after:bg-gray-400 after:bg-gray-400"
                    : "bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500"
                } w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8`}
              ></div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellarPenalityDetails;
