import PageTitle from "../../components/core/PageTitle";
import Notifications from "./notifications";
import { NotificationsProvider } from "./core/NotificationsProvider";
import Footer from "./layout/footer";


const NotificationsWrapper = () => {
  return (
    <>
      <NotificationsProvider>
          <PageTitle title="Notifications"/>
          <Notifications/>
          <Footer/>
      </NotificationsProvider>
    </>
  );
};

export default NotificationsWrapper;
