/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import MinusOutline from "../../../assets/media/icons/other_icons/minus-outline.svg";
import PlusOutLine from "../../../assets/media/icons/other_icons/plus-outline.svg";
import { default as Process } from "../../../assets/media/icons/other_icons/process.svg";
import UploadOutOutline from "../../../assets/media/icons/other_icons/upload-out-outline.svg";
import UploadOutline from "../../../assets/media/icons/other_icons/upload-outline.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import CrossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import { getExtension } from "../../../helpers/AssetHelpers";
import { decimalNumber } from "../../../helpers/Functions";
import Loader from "../../../helpers/Loader";
import { showAlert } from "../../../helpers/ShowAlert";
import { PDFFailedLoad, SYMBOLS } from "../../../helpers/const";
import { TXContext } from "../core/TXProvider";
import { display } from "../core/_functions";
import { getTxPayListing, txPayDetailsView } from "../core/requests";
import DetailComponent from "./DetailComponent";

const EventDetailView = ({
  show,
  handleShow,
  handleClose,
  paginateData,
}: any) => {
  const layout = useContext(TXContext);
  const [loading, setLoading] = useState(false);
  const [pdfView, setPdfView] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [numPages, setNumPages] = useState<any>(null);

  const [filePreview, setFilePreview] = useState<any>();
  const tempItems =
    layout.items?.[show?.ledgerIndex]?.transactions?.[show?.index] || [];
  const [items, setItems] = useState(tempItems);
  const canvasRef: any = useRef();
  const dropdownRef: any = useRef(
    [...new Array(items?.items?.length && items?.items?.length + 10)].map(() =>
      React.createRef()
    )
  );
  const [showItemDetail, setShowItemDetail] = useState<any>({});
  const showRightPopup = Object.keys(showItemDetail)?.length > 0 ? true : false;
  const globalLayout: any = useContext(LayoutContext);
  let currencySymbol = SYMBOLS?.[items?.currency]
    ? SYMBOLS?.[items?.currency]
    : items?.currency;

  const { isLoading: isDetailsLoading, data: detailsListingData } = useQuery(
    [`tx-pay-details`, tempItems?.id],
    () => txPayDetailsView({ id: tempItems?.id }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: tempItems?.id ? true : false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (detailsListingData) {
      if (detailsListingData?.data?.status) {
        setItems({ ...tempItems, items: detailsListingData?.data?.["items"] });
      } else {
        showAlert("Something went wrong", true);
      }
    }
  }, [detailsListingData]);

  /**
   * HANDLE DRODOWN CLICK OF LEFT SIDE POPUP
   * @param event
   * @param item
   * @param index
   */
  const handleDropdownclick = (event: any, item: any, index: number) => {
    let target: any = event.target;
    let parentNode = target?.parentNode?.getAttribute("aria-expanded")
      ? target?.parentNode?.getAttribute("aria-expanded")
      : target?.parentNode?.parentNode?.getAttribute("aria-expanded")
      ? target?.parentNode?.parentNode?.getAttribute("aria-expanded")
      : target?.parentNode?.parentNode?.parentNode?.getAttribute(
          "aria-expanded"
        );
    target?.getAttribute("title") != "Dropdown" &&
      setShowItemDetail(
        //parentNode === "true" ? { item: item?.item_details, index } : {}
        { item: item?.item_details, index }
      );
  };
  /**
   * Download File
   * @param param0
   */
  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  // ADJUST TABLE STYLING
  useEffect(() => {
    display(true);
    canvasRef?.current.classList.add("show");
  }, [show]);

  // CLOSE POPUP
  const closePopup = () => {
    canvasRef?.current.classList.remove("show");
    display(false);
    handleClose();
    setFilePreview(null);
    setPdfView(false);
  };

  const pdfViewer = (file: any) => {
    setPageNumber(1);

    setFilePreview({ preview: file?.key, name: file?.name });
    setPdfView(true);
  };

  /**
   * HANDLE RIGHT SIDE POPUP CLOSE
   */
  const handleRightPopupClose = () => {
    {
      setShowItemDetail({});
    }
  };

  // NAVIGATION BUTTON COMPONENT
  const NavigationButtons = ({
    handleNavigation,
    imgPath,
    className,
    id,
    disabled,
  }: any) => {
    return (
      <button
        className={`group relative p-1 md:p-1 w-6 h-6 rounded-l ${className} ${
          id === "next" && "rotate-180"
        } ${disabled ? "pointer-events-none " : ""}`}
        type="button"
        id={id}
        onClick={() => handleNavigation(id)}
      >
        <KTSVG
          className={`flex items-center justify-center ${
            disabled ? `fill-gray-400` : `fill-violet-500`
          } transition group-hover:fill-indigo-500`}
          path={imgPath}
          svgClassName="w-1.5 h-[.5625rem]"
        />
      </button>
    );
  };

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  const handleNavigation = (nav: string) => {
    let newIndex = show?.index;
    let newLedgerIndex = show?.ledgerIndex;

    if (nav === "back") {
      if (newIndex === 0) {
        if (newLedgerIndex === 0) newLedgerIndex = layout.items.length - 1;
        else newLedgerIndex = newLedgerIndex - 1;
        newIndex = layout.items[newLedgerIndex]?.transactions.length - 1;
      } else {
        newIndex = newIndex - 1;
      }
    } else {
      if (
        newIndex ===
        layout.items[show?.ledgerIndex]?.transactions.length - 1
      ) {
        newIndex = 0;
        if (layout.items.length - 1 === newLedgerIndex) newLedgerIndex = 0;
        else newLedgerIndex = newLedgerIndex + 1;
      } else {
        newIndex = newIndex + 1;
      }
    }
    const data =
      layout.items?.[newLedgerIndex]?.transactions?.[newIndex]?.[
        "transfer_file"
      ] || [];

    handleRightPopupClose();
    handleShow({
      show: true,
      index: newIndex,
      ledgerIndex: newLedgerIndex,
      files: data,
    });

    setItems(layout.items?.[newLedgerIndex]?.transactions?.[newIndex] || []);
    setFilePreview(null);
    setPdfView(false);
  };
  /**
   * EXPORT TO CSV FILE
   * @param e
   * @param transactionId
   */
  const exportToCsv = (e: any, transactionId: any) => {
    e.preventDefault();
    setLoading(true);
    const data: any = getTxPayListing({
      filters: layout?.TXPayFilterHandle,
      export_to_csv: 1,
      id: transactionId,
    }).then((response: any) => {
      downloadFile({
        data: response?.data.replace(/\\n/g, "\n"),
        fileName: "TX Pay.csv",
        fileType: "text/csv",
      });
      if (response) {
        setLoading(false);
      }
    });
  };
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <>
      <div
        data-te-modal-init
        className={`offcanvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0  bg-clip-padding outline-none transition duration-300 ease-in-out font-medium upload-ticket-popup w-full md:pointer-events-none md:w-[calc(100%-3.75rem)] max-w-[86.25rem]`}
        tabIndex={-1}
        id="payDetail"
        ref={canvasRef}
        aria-labelledby="payDetailLabel"
      >
        <div
          className={`${
            pdfView && "shadow-3xl"
          }  pop_wrap flex flex-wrap h-full md:overflow-hidden overflow-auto pointer-events-none`}
        >
          {pdfView && (
            <div
              className={`  bg-white w-full lg:h-full h-auto ml-auto relative flex flex-col lg:w-1/2 pointer-events-auto 
                max-md:fixed max-md:top-[3.75rem] max-md:left-0 max-md:w-full max-md:h-[calc(100dvh-3.75rem)] max-md:overflow-auto max-md:z-[1000] max-md:bg-white
                `}
            >
              <div className="offcanvas-header flex justify-between border-t border-b min-h-[2.5313rem]">
                <h5
                  className={`offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 max-md:max-w-[calc(100%-2.5rem)] max-md:whitespace-nowrap max-md:overflow-hidden max-md:text-ellipsis ${
                    items?.description ? "px-5" : "px-0"
                  }`}
                  id="offcanvasRightLabel"
                >
                  {filePreview?.name}
                </h5>
                <button
                  type="button"
                  className="flex items-center justify-center w-9 border-l box-content group"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={(data: any) => {
                    setPdfView(false);
                  }}
                >
                  <IKTSVG
                    className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                    path={CrossSVG}
                    svgClassName="w-2.5 h-2.5"
                  />
                </button>
              </div>
              <div
                className={`transition lg2:border-r w-full   p-4 md:p-5 flex items-center !justify-start flex-col lg2:scrollbar-thin lg2:scrollbar-thumb-violet-800 lg2:scrollbar-thumb-rounded-full`}
              >
                <div className="border rounded w-full">
                  <div className="flex w-full border-b min-h-[2.5rem]">
                    <div className="flex items-center justify-between w-full px-4 py-1.5">
                      <div className="text-xxs md:text-xs"></div>
                      {numPages > 1 && (
                        <div className="flex">
                          <button
                            className={`group rounded-l  hover: transition px-[.5625rem] py-2 ${
                              pageNumber === 1 && "pointer-events-none"
                            }`}
                            type="button"
                            onClick={(data: any) =>
                              setPageNumber(
                                (count: any) => count !== 1 && count - 1
                              )
                            }
                          >
                            <IKTSVG
                              className={`flex items-center justify-center  transition group-hover:fill-indigo-500 ${
                                pageNumber === 1
                                  ? "fill-gray-400"
                                  : "fill-violet-500"
                              }`}
                              path={angleLeftSVG}
                            />
                          </button>
                          <button
                            className={`group rounded-r  transition px-[.5625rem] py-2 ${
                              numPages === pageNumber && "pointer-events-none"
                            }`}
                            type="button"
                            onClick={(data: any) =>
                              setPageNumber(
                                (count: any) => count !== numPages && count + 1
                              )
                            }
                          >
                            <IKTSVG
                              className={`flex items-center justify-center  transition group-hover:fill-indigo-500 ${
                                numPages === pageNumber
                                  ? "fill-gray-400"
                                  : "fill-violet-500"
                              }`}
                              path={angleRightSVG}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="uploaded-file-preview overflow-hidden">
                    <Document
                      file={filePreview?.preview}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={
                        <IKTSVG
                          className="flex items-center justify-center mr-2 mt-5 fill-violet-500 transition animate-spin"
                          path={Process}
                        />
                      }
                      error={
                        filePreview?.path?.split(".").pop() === "pdf" ? (
                          PDFFailedLoad
                        ) : (
                          <img src={filePreview?.preview} />
                        )
                      }
                    >
                      <Page pageNumber={pageNumber} pageIndex={pageNumber} />
                    </Document>
                  </div>
                  {filePreview?.path?.split(".").pop() === "pdf" && (
                    <div className="ml-auto p-2 w-8 text-xs text-gray-400">
                      {pageNumber}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            className={`pay-detail bg-white w-full lg:h-full h-auto ml-auto relative flex flex-col lg:w-1/2 pointer-events-auto shadow-3xl`}
          >
            <div className="offcanvas-header flex justify-between border-t border-b ">
              <h5
                className={`offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 ${
                  items?.description ? "px-5" : "px-0"
                }`}
                id="offcanvasRightLabel"
              >
                {items?.type === "Transfer" ? "Transfer" : items?.description}
              </h5>
              {!showRightPopup && (
                <div className="buttons flex flex-wrap min-h-[2.5313rem]">
                  <button
                    type="button"
                    className="flex items-center justify-center w-9 border-l box-content group"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    onClick={() => closePopup()}
                  >
                    <IKTSVG
                      className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                      path={CrossSVG}
                      svgClassName="w-2.5 h-2.5"
                    />
                  </button>
                </div>
              )}
            </div>
            <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-5 mb-1">
              <div className="body_wrap">
                <div className="credit_block mb-5 p-4 border rounded">
                  <div className="credit flex items-center text-sm15 leading-5 mb-3">
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
                      path={
                        items?.type === "Credit"
                          ? PlusOutLine
                          : items?.type === "Debit"
                          ? MinusOutline
                          : items?.in
                          ? UploadOutline
                          : UploadOutOutline
                      }
                      svgClassName="w-3 h-3"
                    />
                    {items?.type}
                  </div>
                  <h2 className="text-2xl  md:text-boxtitle md:leading-9 mb-3">
                    {items?.in
                      ? `${currencySymbol}${items?.in}`
                      : `${currencySymbol}${items?.out}`}
                  </h2>
                  <p className="text-sm12 font-medium text-gray-500 block leading-4">
                    {items?.created_at}
                  </p>

                  {items?.payout_reference && (
                    <p className="text-sm12 font-medium text-gray-500 block leading-4">
                      Payout reference : {items?.payout_reference}
                    </p>
                  )}
                </div>

                {isDetailsLoading ? (
                  <div className="shimmer-effect w-full h-[2.5rem] min-w-[8.125rem] rounded"></div>
                ) : (
                  _.size(items?.items) > 0 && (
                    <div className="acc_wrap border rounded">
                      <div className="single_accordion ">
                        <div
                          className="acc_title inventory-table sales-table overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full "
                          id="dropdownItems"
                        >
                          {items?.items?.map((item: any, index: any) => (
                            <table
                              className="w-full text-xs text-left border-b last-of-type:border-b-0"
                              key={`dropdowns${index}`}
                            >
                              <thead
                                id={`single_accordion${index}`}
                                className="group [&:not([data-te-collapse-collapsed])]:bg-indigo-500/5"
                                data-te-collapse-init
                                data-te-collapse-collapsed
                                data-te-target={`#collapseItem${index}`}
                                aria-expanded="false"
                                aria-controls={`collapseItem${index}`}
                              >
                                <tr
                                  ref={dropdownRef.current[index]}
                                  className="group cursor-pointer"
                                  data-te-collapse-init
                                  data-te-collapse-collapsed
                                  data-te-target={`#collapseItem${index}`}
                                  aria-expanded={false}
                                  aria-controls={`collapseItem${index}`}
                                  onClick={(event) =>
                                    handleDropdownclick(event, item, index)
                                  }
                                >
                                  <th
                                    style={{ width: "8.125rem" }}
                                    className="py-3 pr-1.5 pl-4 whitespace-nowrap font-medium min-w-[8.125rem]"
                                  >
                                    {item?.date}
                                  </th>
                                  <th
                                    style={{ width: "7.5rem" }}
                                    className="py-3 px-1.5 font-medium min-w-[7.5rem]"
                                  >
                                    <span
                                      className="whitespace-nowrap text-ellipsis overflow-hidden w-[6.625rem] block underline"
                                      title={item?.item_id}
                                    >
                                      {item?.item_id}
                                    </span>
                                  </th>
                                  <th
                                    style={{ width: "7.5rem" }}
                                    className="py-3 px-1.5 whitespace-nowrap font-medium min-w-[7.5rem]"
                                  >
                                    {currencySymbol}
                                    {decimalNumber(item.amount)}
                                  </th>
                                  <th className="py-3 px-1.5 font-medium">
                                    <span
                                      className="whitespace-nowrap text-ellipsis overflow-hidden w-[12.5rem] block"
                                      title={item?.title}
                                    >
                                      {item?.title}
                                    </span>
                                  </th>
                                  <th
                                    style={{ width: "2.5rem" }}
                                    className="ml-auto border-l font-medium group px-4 py-2.5 min-w-[2.5rem]"
                                    title={"Dropdown"}
                                  >
                                    <KTSVG
                                      className="flex items-center justify-center  fill-violet-500 hover:fill-indigo-500 transition
                                shrink-0 rotate-[-180deg] duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 motion-reduce:transition-none pointer-events-none"
                                      path="standard_icons/arrowDown.svg"
                                    />
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                id={`collapseItem${index}`}
                                className="!visible hidden accordion-collapse bg-gray-100/50 text-gray-500 border-t group"
                                data-te-collapse-item
                                aria-labelledby={`collapseItem${index}`}
                                data-te-parent="#dropdownItems"
                              >
                                {item?.item_statement.map(
                                  (order: any, oIndex: any) => (
                                    <tr
                                      className="border-t"
                                      id={`collapseItem${index}${oIndex}`}
                                    >
                                      <td className="py-3 pr-1.5 pl-4"></td>
                                      <td className="py-3 px-1.5"></td>
                                      <td className="py-3 px-1.5">
                                        {currencySymbol}
                                        {order.amount}
                                      </td>
                                      <td className="py-3 px-1.5">
                                        {order.title}
                                      </td>
                                      <td></td>
                                    </tr>
                                  )
                                )}

                                {item.item_statement.length === 0 && (
                                  <tr className="border-t" id={`collapseItem`}>
                                    <td
                                      className="py-3 px-1.5 text-center"
                                      colSpan={4}
                                    >
                                      No Result
                                    </td>
                                    <td></td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>

              {items?.type === "Transfer" && (
                <div className="body_wrap">
                  <span className="text-sm13 font-semibold text-violet-800 ">
                    Description
                  </span>
                  <div className="mt-2">
                    <p className="text-sm12 font-medium  block leading-4">
                      {items?.description}
                    </p>
                  </div>
                </div>
              )}
              {show?.files?.length > 0 && (
                <div className="body_wrap mt-4">
                  <span className="text-sm13 font-semibold text-violet-800 ">
                    Transfer files
                  </span>
                  <div className="mt-2 flex flex-col gap-y-2.5">
                    {show?.files?.map((obj: any, index: any) => {
                      return (
                        <div
                          key={index}
                          className="flex items-center !border-solid  hover:!border-indigo-500 hover:bg-violet-50 transition  drag_here border border-dashed rounded bg-white px-2 py-[3px] flex-1"
                        >
                          <span className="!text-black !inline-flex items-center justify-between w-full text-sm12 font-medium text-gray-400 block ">
                            {obj?.name}
                            <span className="flex s-center gap-x-2 ">
                              <button
                                id={`view-${index}`}
                                onClick={() => {
                                  if (
                                    getExtension(obj?.name) === "PDF" ||
                                    getExtension(obj?.name) === "pdf"
                                  ) {
                                    pdfViewer(obj);
                                  } else {
                                    window.open(obj?.key, "_blank");
                                  }
                                }}
                              >
                                <IKTSVG
                                  className="fill-violet-500 hover:fill-indigo-500 w-4 h-4 transition"
                                  svgClassName="w-4 h-4"
                                  path={eyeSVG}
                                />
                                <Tooltip
                                  anchorId={`view-${index}`}
                                  content="View"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                />
                              </button>
                            </span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className=" offcanvas-footer bottom-0 right-0 w-full flex flex-wrap justify-between px-5 py-1.5 bg-white z-10 border-t min-h-[3.125rem]">
              <div className="l_btn gap-2.5 flex flex-wrap items-center my-1.5">
                <button
                  type="button"
                  className="btn gray-btn group"
                  onClick={(event) => exportToCsv(event, items?.id)}
                >
                  <KTSVG
                    className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
                    path="other_icons/file.svg"
                  />
                  Export to CSV
                </button>
              </div>
              {!showRightPopup && (
                <div className="r_btn gap-2.5 flex flex-wrap items-center">
                  <div className="pop_nav flex shadow-op1 rounded">
                    {/* BACK BUTTON */}
                    <NavigationButtons
                      id="back"
                      imgPath="standard_icons/angle_left.svg"
                      handleNavigation={(data: any) => handleNavigation(data)}
                      disabled={paginateData?.total === 1 || isDetailsLoading}
                    />
                    {/* NEXT BUTTON */}
                    <NavigationButtons
                      id="next"
                      imgPath="standard_icons/angle_left.svg"
                      handleNavigation={(data: any) => handleNavigation(data)}
                      disabled={paginateData?.total === 1 || isDetailsLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {showRightPopup && (
            <div
              className={`bg-white shadow-3xl w-full lg:h-full h-auto ml-auto fixed max-lg:h-full md:w-[calc(100%_-_3.75rem)] lg:relative flex flex-col z-10 transition lg:w-1/2 pointer-events-auto`}
            >
              <div className="offcanvas-header flex justify-between border-t border-b">
                <h5
                  className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5 12121"
                  id="offcanvasRightLabel"
                >
                  Tixstock ID: {showItemDetail?.item?.id}
                </h5>
                <div className="buttons flex flex-wrap">
                  <button
                    className="group relative mail flex flex-auto items-center justify-center p-1 md:p-1 w-10 h-full border-l"
                    aria-label="Close"
                    onClick={() => handleRightPopupClose()}
                  >
                    <KTSVG
                      className="flex items-center justify-center  group-hover:hidden transition"
                      path="other_icons/arrow-r.svg"
                      svgClassName="w-[.9375rem] h-[.6875rem]"
                    />
                    <KTSVG
                      className="flex items-center justify-center hidden group-hover:block transition"
                      path="other_icons/arrow-r-indigo.svg"
                      svgClassName="w-[.9375rem] h-[.6875rem]"
                    />
                  </button>
                </div>
              </div>

              <div className="offcanvas-body max-lg:pb-20 md:h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-5 mb-1">
                <div className="body_wrap">
                  <div className="border rounded">
                    <div className="flex flex-wrap px-3 py-3 border-b">
                      <DetailComponent
                        detail={showItemDetail?.item?.id}
                        title="Tixstock ID"
                      />
                      <DetailComponent
                        detail={showItemDetail?.item?.date}
                        title="Order date"
                      />
                      {/* <DetailComponent detail={showItemDetail?.item?.marketplace} title="Marketplace" /> */}
                      {/* <DetailComponent detail={showItemDetail?.item?.marletplace_id} title="Marketplace ID" /> */}

                      <div className="md:w-1/2 p-2 w-full">
                        <div className="text-gray-400 text-sm12 mb-1">
                          Order status
                        </div>
                        <div
                          className={`inline-flex px-2.5 py-[3px] ${
                            showItemDetail?.item?.status === "Paid"
                              ? "bg-green-600"
                              : "bg-rose-500"
                          } text-sm12 text-white rounded`}
                        >
                          {showItemDetail?.item?.status}
                        </div>
                      </div>
                      <div className="md:w-1/2 p-2 w-full">
                        <div className="text-gray-400 text-sm12 mb-1">
                          Deliver by
                        </div>
                        <div className="inline-flex px-2.5 py-[3px] bg-rose-500 bg-opacity-[7%] text-sm12 rounded">
                          {moment(
                            showItemDetail?.item?.delivery_by,
                            "DD-MM-YYYY"
                          ).format("DD/MM/YYYY")}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap px-3 py-3">
                      <div className="md:w-1/2 p-2 w-full">
                        <div className="text-gray-400 text-sm12">Event</div>
                        <div className="text-sm12 overflow-hidden text-ellipsis whitespace-nowrap">
                          {showItemDetail?.item?.event}
                        </div>
                      </div>
                      <div className="md:w-1/2 p-2 w-full">
                        <div className="text-gray-400 text-sm12">Venue</div>
                        <div className="text-sm12">
                          {showItemDetail?.item?.venue}
                        </div>
                      </div>
                      <div className="md:w-1/2 p-2 w-full">
                        <div className="text-gray-400 text-sm12">
                          Event date
                        </div>
                        <div className="text-sm12">
                          {showItemDetail?.item?.event_date}
                        </div>
                      </div>
                      <div className="md:w-1/2 p-2 w-full">
                        <div className="text-gray-400 text-sm12">
                          Ticket details
                        </div>
                        <div className="text-sm12">
                          {showItemDetail?.item?.ticket_details}
                        </div>
                      </div>
                      <div className="md:w-1/2 p-2 w-full flex">
                        <div className="w-1/2">
                          <div className="text-gray-400 text-sm12">
                            Ticket quantity
                          </div>
                          <div className="text-sm12">
                            {showItemDetail?.item?.ticket_quantity}
                          </div>
                        </div>
                        <div className="w-1/2">
                          <div className="text-gray-400 text-sm12">
                            Ticket price
                          </div>
                          <div className="text-sm12">
                            {currencySymbol}
                            {showItemDetail?.item?.ticket_price}
                          </div>
                        </div>
                      </div>
                      <div className="md:w-1/2 p-2 w-full">
                        <div className="text-gray-400 text-sm12">
                          Order value
                        </div>
                        <div className="text-sm12">
                          {currencySymbol}
                          {showItemDetail?.item?.transaction_value}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="max-lg:mt-auto sticky bottom-0 right-0 w-full flex justify-end px-5 py-3 bg-white z-10 border-t min-h-[3.125rem]">
                {/* BACK BUTTON */}
                <div className="arrow_buttons flex shadow-op1 rounded ">
                  <NavigationButtons
                    id="back"
                    imgPath="standard_icons/angle_left.svg"
                    handleNavigation={(data: any) => handleNavigation(data)}
                    disabled={paginateData?.total === 1}
                  />
                  {/* NEXT BUTTON */}
                  <NavigationButtons
                    id="next"
                    imgPath="standard_icons/angle_left.svg"
                    handleNavigation={(data: any) => handleNavigation(data)}
                    disabled={paginateData?.total === 1}
                  />
                </div>
              </div>
              {/* NAVIGATION BUTTONS */}

              {/* <div className=" offcanvas-footer bottom-0 right-0 w-full flex justify-between px-5 py-3 bg-white border-t">
            <div className="r_btn gap-2.5 flex flex-wrap items-center ml-auto">
              <div className="pop_nav flex">
                <button className="group relative p-1 md:p-1 w-6 h-[1.4375rem] rounded-l bg-gray-100 hover:bg-indigo-500">
                  <KTSVG
                    className="flex items-center justify-center fill-violet-500 transition group-hover:fill-white"
                    path="standard_icons/angle_left.svg"
                  />
                </button>
                <button className="group relative  p-1 md:p-1 w-6 h-[1.4375rem] rounded-r bg-gray-100 hover:bg-indigo-500">
                  <KTSVG
                    className="flex items-center justify-center fill-violet-500 group-hover:fill-white"
                    path="standard_icons/angle_right.svg"
                  />
                </button>
              </div>
            </div>
          </div> */}
            </div>
          )}
        </div>
      </div>
      {loading && <Loader />}
      {showRightPopup && <div className="offcanvas-backdrop fade show"></div>}
    </>
  );
};

export default EventDetailView;
