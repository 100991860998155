import PageTitle from "../../components/core/PageTitle";
import ContentWrapper from "./ContentWrapper";
import { TxAccountProvider } from "./core/TxAccountProvider";

const TxAccountWrapper = () => {
  return (
    <>
      <TxAccountProvider>
        <PageTitle title="TX Accounts" />
        <ContentWrapper />
        {/* <DraftInvoice /> */}
      </TxAccountProvider>
    </>
  );
};

export default TxAccountWrapper;
