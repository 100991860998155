/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import { NotificationsContext } from "../core/NotificationsProvider";
import { addCommaInNumber } from "../../../helpers/Functions";

const TabHeader = ({
  tabId,
  name,
  count,
  countColor,
  handleTabClick,
  postKey,
}: any) => {
  const layout = useContext(NotificationsContext);
  return (
    <>
      <a
        // href={void 0}
        className={`  mx-[0.5px] ${
          postKey === layout.tabValue && "text-indigo-500 z-10"
        } max-w-[12.5rem] min-w-[10.375rem] w-full`}
        // data-te-toggle="pill"
        data-te-target={`#${tabId}`}
        // role="tab"
        aria-controls={tabId}
        // aria-selected="true"
        onClick={() => {
          handleTabClick(postKey);
          layout.setDateRange("");
        }}
      >
        <div
          className={`single-tab h-full sm:px-4 px-3 py-[10px] bg-white rounded-t cursor-pointer mr-[1px] w-full z-10 ${
            postKey === layout.tabValue ? "bg-opacity-100" : "bg-opacity-60"
          }`}
        >
          <div className="text flex justify-between items-center flex-wrap">
            <h4 className="sm:text-sm15 text-sm14 leading-5 font-semibold">
              {name}
            </h4>
            <span
              className={`flex items-center ${countColor} text-xxs text-white font-semibold px-2 py-0.5 rounded`}
            >
              {count ? addCommaInNumber(count) : "0"}
            </span>
          </div>
        </div>
      </a>
    </>
  );
};

export default TabHeader;
