const TixLogo = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="31.303"
      viewBox="0 0 34 31.303"
      {...props}
    >
      <path
        id="Path_461"
        data-name="Path 461"
        d="M13.478,13.706,17,11.672l3.521,2.034L17,15.739ZM3.521,15.739V7.958L10.26,4.066l13.479,7.782L27.26,9.815,10.26,0,0,5.924v7.782ZM10.26,7.782,6.739,9.815V25.378L17,31.3l10.26-5.924V21.311L17,27.236l-6.74-3.89v-11.5l3.522-2.034Zm3.217,9.639v4.068L17,23.521l17-9.815V5.924L23.739,0,20.217,2.033,30.478,7.958v3.714L17,19.454Z"
        fill="#00e500"
      />
    </svg>
  );
};

export default TixLogo;
