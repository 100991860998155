import { useContext, useEffect, useState } from "react";
import { NotificationsContext } from "../core/NotificationsProvider";

const NotificationCountBoxes = ({
  name,
  countValue,
  notificationCount,
  isCheckbox,
  extraProp = false,
  postKey,
  loading,
}: any) => {
  const layout = useContext(NotificationsContext);

  const [newLoading, setNewLoading] = useState(true);

  useEffect(() => {
    // if (loading === false) {
    setTimeout(() => {
      setNewLoading(false);
    }, 500);

    // }
  }, []);

  return (
    <>
      <div
        className={`w-full sm:w-1/2 lg:w-1/3 mb-5 px-2.5
        ${loading && "shimmer-effect"}
        ${newLoading && "shimmer-effect"} `}
      >
        <label
          htmlFor={postKey}
          className={`px-4 py-2.5 h-full min-w-[9.8125rem] border form-check items-center group rounded block ${
            layout.notificationsFilterHandle?.notification_type?.[postKey] &&
            "active-style"
          } [&.active-style]:bg-indigo-500/5 [&.active-style]:border-indigo-500
              ${
                isCheckbox &&
                "hover:bg-indigo-500/5 hover:border-indigo-500 hover:text-indigo-500"
              } `}
        >
          <div className="flex justify-between">
            <div>
              <h3
                className={`text-2xl md:text-boxtitle md:leading-9 ${
                  layout.notificationsFilterHandle?.notification_type?.[
                    postKey
                  ] && "active-style"
                } [&.active-style]:text-indigo-500`}
              >
                {extraProp}
                {countValue ? countValue : "0"}
              </h3>
            </div>
            {notificationCount && (
              <h5>
                <span
                  className={`flex items-center bg-gray-100 text-xxs font-semibold px-2 py-0.5 rounded ${
                    layout.notificationsFilterHandle?.notification_type?.[
                      postKey
                    ] && "active-style"
                  } [&.active-style]:text-indigo-500 [&.active-style]:bg-indigo-500/5 `}
                >
                  {notificationCount ? notificationCount : "0"} notifications
                </span>
              </h5>
            )}
          </div>
          <div className="flex justify-between items-center">
            <p
              className={`text-xs text-gray-500 py-1 ${
                isCheckbox && "group-hover:text-indigo-500"
              } ${
                layout.notificationsFilterHandle?.notification_type?.[
                  postKey
                ] && "active-style"
              } [&.active-style]:text-indigo-500`}
            >
              {name ? name : "0"}
            </p>

            {isCheckbox && countValue !== "0" && (
              <div className="flex">
                <input
                  className="form-check-input h-3 w-3 rounded-sm bg-gray-100 border-gray-300 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 hover:border-indigo-500 group-hover:border-indigo-500 focus:ring-0 focus:ring-offset-0`}"
                  type="checkbox"
                  name={postKey}
                  id={postKey}
                  checked={
                    layout.notificationsFilterHandle?.notification_type?.[
                      postKey
                    ]
                  }
                  onChange={(e: any) => {
                    layout.setNotificationsFilterHandle((current: any) => {
                      return {
                        ...current,
                        notification_type: {
                          ...current.notification_type,
                          [e.target.name]: e.target.checked,
                        },
                      };
                    });
                  }}
                />
              </div>
            )}
          </div>
        </label>
      </div>
    </>
  );
};

export default NotificationCountBoxes;
