import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { storeFields } from "../../../components/core/requests";
import { outSideClick } from "../../../helpers/AssetHelpers";
import { QUERIES } from "../../../helpers/const";
import { NotificationsContext } from "../core/NotificationsProvider";
import DraggableFilters from "./DraggableFilters";

const HeaderfilterContainer = () => {
  const layout = useContext(NotificationsContext);
  const globalLayout = useContext(LayoutContext);

  let headerFilter = `tx_notification_filter_${
    layout?.tabValue === "notification" ? "notification" : "activities"
  }`;
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[headerFilter];

  let payload: any = [
    {
      key: headerFilter,
      value: layout.filterItems,
    },
  ];

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, headerFilter)
    );

    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, headerFilter)
      );
    };
  }, []);

  //   STORE FILTER ARRAY
  const { refetch } = useQuery(
    [`${QUERIES.STORE_FIELD}`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onError: (err) => {},
      onSettled(data, error) {},
    }
  );
  // useEffect(() => {
  //   if (layout.filterItems && existInLocalstorage(headerFilter)) {
  //     layout.setFilterItems(existInLocalstorage(headerFilter));
  //     setTimeout(() => {
  //       refetch();
  //       localStorage.removeItem(headerFilter);
  //     }, 3000);
  //   }
  // }, []);

  useEffect(() => {
    if (isFilterExistInDB) {
      setTimeout(() => {
        layout.setFilterItems(isFilterExistInDB);
        layout.setDraggedItems(isFilterExistInDB);
      }, 1000);
    }
  }, [isFilterExistInDB]);

  return (
    <div className="flex flex-wrap" id={`#filters`}>
      <div className="ba_item relative max-md:hidden">
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target={`#${headerFilter}`}
          aria-expanded="false"
          aria-controls={`${headerFilter}`}
        >
          Filters
        </span>
        <div
          id={`${headerFilter}`}
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden"
          data-te-collapse-item
          aria-labelledby={`${headerFilter}`}
          data-te-parent={`#filters`}
        >
          <DraggableFilters refetch={refetch} />
        </div>
      </div>
    </div>
  );
};

export default HeaderfilterContainer;
