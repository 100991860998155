/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  getSellerLevelNumberWithColor,
  LoadImages,
} from "helpers/AssetHelpers";
import { upperCase } from "lodash";
import moment from "moment";
import {
  Fragment,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SVG from "react-inlinesvg";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import AddListingsOutline from "../../../assets/media/icons/add-listings-outline.svg";
import DashboardOutline from "../../../assets/media/icons/dashboard-outline.svg";
import CircleQuestion from "../../../assets/media/icons/menu_icons/Circle-question.svg";
import TixLogo from "../../../assets/media/icons/menu_icons/TixLogo";
import Plug1 from "../../../assets/media/icons/menu_icons/api-connector.svg";
import AccountGear from "../../../assets/media/icons/menu_icons/gear.svg";
import LogoutSVG2 from "../../../assets/media/icons/menu_icons/logout2.svg";
import TXAccountSVG from "../../../assets/media/icons/menu_icons/txAccount1.svg";
import wallet from "../../../assets/media/icons/menu_icons/walletnBanking.svg";
import MyListingsOutline from "../../../assets/media/icons/my-listings-outline.svg";
import NotificationOutlineWithout from "../../../assets/media/icons/notification-outline-without.svg";
import NotificationOutline from "../../../assets/media/icons/notification-outline.svg";
import SubscribeIcon from "../../../assets/media/icons/other_icons/user1.svg";
import ReportsOutline from "../../../assets/media/icons/reports-outline.svg";
import SalesOutline from "../../../assets/media/icons/sales-outline.svg";
import AngleRight from "../../../assets/media/icons/standard_icons/angle_right.svg";
import arrowDown from "../../../assets/media/icons/standard_icons/chevron-down.svg";
import TixStockLogoFull from "../../../assets/media/icons/tixstock-logo-light.svg";
import TxPayOutline from "../../../assets/media/icons/tx-pay-outline.svg";
import TxTradeOutline from "../../../assets/media/icons/tx-trade-outline.svg";

import { addCommaInNumber, handleLogout } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import {
  getMarkAsPinned,
  getMarkAsUnpinned,
} from "../../../pages/notifications/core/requests";
import { LayoutContext } from "../../core/LayoutProvider";
import PusherSetup from "../../core/PusherSetup";
import { getNotificationListing } from "../../core/requests";
import { KTSVG } from "../../ui/KTSVG";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper/modules";

// Import Swiper styles
import { IKTSVG } from "components/ui/IKTSVG";

// import required modules
import { TX_PAY_TAB_ROUTES, TX_TAB } from "pages/tx_pay/core/const";
import AsideMenuItem from "./AsideMenuItem";
import SellerLevelBenefitsSlider from "./SellerLevelBenefitsSlider";
import { ShowSubmenu } from "./ShowSubmenu";

// import required modules
const AsideDefault = ({ setUserInfo, userInfo }: any) => {
  const [open, setOpen] = useState(false);
  const [menuopen, setmenuopen] = useState(false); // only for mobile
  const [showMenu, setShowMenu] = useState(false); // show all menu instead of load after another
  const layout = useContext(LayoutContext);
  let tabValue = layout.tabValue;
  const catMenu = useRef<any>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const authUser = layout.authUser;
  const access = layout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );

  // const [userInfo, setUserInfo] = useState(false);

  const notificationStatuts =
    layout.allowedAccess?.account_info?.is_read_new_notifications;
  const isGodAdmin = layout.allowedAccess?.account_info?.is_god_admin;

  const closeOpenMenus = (e: any) => {
    if (catMenu.current && open && !catMenu.current.contains(e.target)) {
      setOpen(false);
      setUserInfo(false);
    }

    if (layout?.notificationId && !catMenu?.current?.contains(e.target)) {
      layout?.setNotificationId(undefined);
      setUserInfo(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  useEffect(() => {
    setTimeout(() => {
      setShowMenu(true);
    }, 1000);
  }, []);

  useEffect(() => {
    setOpen(false); // Close the navigation panel
  }, [pathname]);

  // GET NOTIFICATIONS LISTING
  const {
    isLoading: notificationsLoading,
    data: listingData,
    refetch,
  } = useQuery(
    [
      `${QUERIES.NOTIFICATIONS_LISTING}`,
      tabValue,
      layout.newNotification,
      layout.notificationId,
    ],
    () =>
      getNotificationListing({
        tab_name: tabValue,
        ...(userSpecific && { team_member: [authUser?.id] }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled:
        layout.newNotification !== undefined ||
        layout.notificationId !== undefined,
      onError: (err) => {},
    }
  );

  useEffect(() => {
    if (listingData?.data?.activities) {
      layout?.setActivities(
        listingData?.data?.activities?.map((activity: any) => {
          return {
            ...activity,
          };
        })
      );
    }
  }, [listingData, tabValue, listingData?.data?.activities]);

  useEffect(() => {
    if (listingData?.data?.notifications) {
      layout?.setNotifications(
        listingData?.data?.notifications?.map((notification: any) => {
          return {
            ...notification,
          };
        })
      );
    }
  }, [listingData, tabValue]);

  const handleUnpin = (e: any, id: any) => {
    getMarkAsUnpinned([id])
      .then((response) => {
        if (response.data.status === true) {
          layout.setNotificationId(layout.notificationId + 1);
          // layout.setNotifications(layout.notifications.map((notification: any) => {
          //   if (notification.selected) {
          //     notification.selected = false
          //     return {
          //       ...notification,
          //       is_pinned: false
          //     }
          //   }
          //   else {
          //     return notification
          //   }
          // }))
        }
      })
      .catch((error) => {});
  };

  const handlePin = (e: any, id: any) => {
    getMarkAsPinned([id])
      .then((response) => {
        if (response.data.status === true) {
          layout.setNotificationId(id);
          layout.setNotifications(
            layout.notifications.map((notification: any) => {
              if (notification.selected) {
                notification.selected = false;
                return {
                  ...notification,
                  is_pinned: true,
                };
              } else {
                return notification;
              }
            })
          );
        }
      })
      .catch((error) => {});
  };

  // MEMOIZED ACCESS
  const memoizedAccess = useMemo(() => access, [access]);

  // NAVIGATE TO DASHBOARD MODULE
  const handleClick = () => {
    navigate("../", { replace: true });
  };

  const closeOpenMainMenu = (e: any) => {
    if (catMenu.current && menuopen && !catMenu.current.contains(e.target)) {
      setmenuopen(!menuopen);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMainMenu);
    return () => {
      document.removeEventListener("mousedown", closeOpenMainMenu);
    };
  }, [menuopen]);

  // Submenu  design fixed
  useEffect(() => {
    const menuItems = document.querySelectorAll(
      "[data-menu-id]"
    ) as NodeListOf<HTMLElement>;
    const menuUl = document.querySelector(".menu-ul") as HTMLElement;
    const MenuWrap = document.querySelector(
      ".notification_class"
    ) as HTMLElement;
    let currentSubmenu: HTMLElement | null = null;
    let activeMenuItem: HTMLElement | null = null;

    const updateSubmenuPosition = () => {
      if (currentSubmenu && activeMenuItem) {
        const { offsetTop } = activeMenuItem;
        const scrollTop = menuUl.scrollTop;
        const scrollLeft = MenuWrap.offsetWidth;

        currentSubmenu.style.top = `${offsetTop - scrollTop}px`;
        currentSubmenu.style.left = `${scrollLeft}px`;
      }
    };

    const handleClick = (event: Event) => {
      event.stopPropagation();
      const menuItem = event.currentTarget as HTMLElement;
      const menuId = menuItem.getAttribute("data-menu-id");

      if (currentSubmenu) {
        currentSubmenu.style.display = "none";
        if (currentSubmenu.getAttribute("data-submenu-id") === menuId) {
          currentSubmenu = null;
          activeMenuItem = null;
          return;
        }
      }

      if (menuId) {
        const submenuToShow = document.querySelector(
          `[data-submenu-id="${menuId}"]`
        ) as HTMLElement;
        if (submenuToShow) {
          const { offsetTop } = menuItem;
          const scrollTop = menuUl.scrollTop;
          const scrollLeft = MenuWrap.offsetWidth;

          submenuToShow.style.top = `${offsetTop - scrollTop}px`;
          submenuToShow.style.left = `${scrollLeft}px`;

          submenuToShow.style.display = "block";
          currentSubmenu = submenuToShow;
          activeMenuItem = menuItem;

          updateSubmenuPosition();
        }
      }
    };

    const handleBodyClick = () => {
      if (currentSubmenu) {
        currentSubmenu.style.display = "none";
        currentSubmenu = null;
        activeMenuItem = null;
      }
    };

    menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", handleClick);
    });

    if (menuUl) {
      menuUl.addEventListener("scroll", updateSubmenuPosition);
    }

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      menuItems.forEach((menuItem) => {
        menuItem.removeEventListener("click", handleClick);
      });
      if (menuUl) {
        menuUl.removeEventListener("scroll", updateSubmenuPosition);
      }
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [layout.allowedAccess?.allowed_access]);

  return (
    <>
      <PusherSetup refetch={() => layout.setIsPusherCall(true)} />
      <div
        className={`flex fixed z-[99999999] notification_class md:pointer-events-auto pointer-events-none ${
          open && "menuActive"
        } ${
          !(layout?.notificationId || userInfo) &&
          "after:bg-menuCorner after:absolute after:left-full after:top-0 after:w-4 after:h-4 after:transition after:duration-200 before:bg-menuCorner before:absolute before:left-full before:bottom-0 before:w-4 before:h-4 before:transition before:duration-200 before:-scale-y-100"
        } ${menuopen ? "before:block" : "before:hidden md:before:block"}`}
        ref={catMenu}
      >
        <div
          className={`${
            open
              ? "w-[11.6875rem] md:w-[13.875rem] open-menu"
              : "w-[3.75rem] close-menu"
          } flex flex-col menu-wrap h-screen pt-5 relative duration-200 bg-violet-600 overflow-x-auto scrollbar-none scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full`}
        >
          <div
            className="flex md:hidden gap-x-4 items-center cursor-pointer logo pointer-events-auto px-2.5"
            // onClick={handleClick}
            onClick={() => {
              setOpen(false);
              setmenuopen(menuopen ? false : true);
              layout.setNotificationId(undefined);
              setUserInfo(false);
            }}
          >
            <TixLogo className={`cursor-pointer duration-500`} />
          </div>
          <div
            className="md:flex gap-x-4 items-center justify-center cursor-pointer logo hidden p-2.5 pl-3"
            onClick={handleClick}
          >
            <div className="relative w-full min-h-7 h-7">
              <SVG
                src={TixStockLogoFull}
                className={`h-7 min-h-7 absolute left-0 top-0 duration-0 ${
                  open ? "opacity-100" : "opacity-0"
                } `}
              />
              <TixLogo
                className={`cursor-pointer h-7 absolute  duration-0 left-0 top-0 ${
                  open ? "opacity-0" : "opacity-100"
                }`}
              />
            </div>
          </div>

          <ul
            className={`menu-ul pt-1 ${
              menuopen ? "active-menu" : ""
            } pointer-events-auto ${
              !showMenu ? `hidden` : ``
            } bg-violet-600 px-2.5 overflow-auto scrollbar-none scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full !pb-16 md:pb-2.5`}
          >
            <>
              <div
                className="group user_logo  flex items-center relative mb-2.5 w-full gap-x-2.5"
                data-tip
                id="par_id"
                onClick={() => {
                  setUserInfo(!userInfo);
                  layout.setNotificationId(undefined);
                }}
              >
                {open ? (
                  <div
                    className="flex flex-row justify-between items-center w-full min-h-[2.8125rem] hover:bg-violet-400 px-2.5 transition-all
              duration-300 rounded cursor-pointer"
                  >
                    <div className="flex flex-col gap-y-[0.1875rem]">
                      <span
                        className={`origin-left capitalize text-white font-medium leading-[1.0625rem] text-truncate transition-all`}
                      >
                        {/* Scott Welch */}
                        {layout.allowedAccess?.account_info?.firstname}{" "}
                        {layout.allowedAccess?.account_info?.surname}
                      </span>
                      <p className="text-xxs text-green-500 leading-[0.875rem] ">
                        {/* Seller{" "}
                        {layout.allowedAccess?.team_data?.seller_level?.title} */}
                        {layout.allowedAccess?.team_data?.seller_level?.title?.toLowerCase() ===
                        "super level"
                          ? "Super Seller"
                          : `Seller ${layout.allowedAccess?.team_data?.seller_level?.title}`}
                      </p>
                    </div>
                    <div>
                      {/* <SVG src={RightArrow} className="h-[0.5625rem] w-[0.2813rem]" /> */}
                      <IKTSVG
                        className={`stroke-white`}
                        path={arrowDown}
                        svgClassName={`w-[.7813rem] h-[.6563rem] -rotate-90 `}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="relative h-12">
                    <div
                      className={`bg-sellerNotification absolute top-0 right-[0.1875rem] w-[0.8125rem] h-[0.9131rem] flex items-center justify-center text-violet-800 bg-cover font-medium text-xxs leading-[0.875rem]   `}
                    >
                      {/* {layout.allowedAccess?.team_data?.seller_level?.id}
                       */}
                      {getSellerLevelNumberWithColor(
                        layout.allowedAccess?.team_data?.seller_level
                      )}
                    </div>
                    <div className="w-10 h-[2.8125rem] flex items-center justify-center text-white bg-avatarShape bg-cover text-sm15 font-semibold min-w-[2.5rem]">
                      {upperCase(
                        layout.allowedAccess?.account_info?.firstname?.charAt(0)
                      )}
                      {upperCase(
                        layout.allowedAccess?.account_info?.surname?.charAt(0)
                      )}
                    </div>
                  </div>
                )}
              </div>
              <Tooltip
                anchorId={"par_id"}
                html={`<div><h2 class="name">${`${layout.allowedAccess?.account_info?.firstname} ${layout.allowedAccess?.account_info?.surname}`}</h2> 
                  <!-- <span class="type">Accounts</span> -->
                  </div>`}
                place="right"
                variant="light"
                className="   !bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
                positionStrategy="fixed"
              />

              {/* Minimize */}
              <li
                className="flex rounded p-2 mb-[0.0625rem] cursor-pointer overflow-hidden hover:bg-light-white text-gray-300 text-sm15 items-center gap-x-3 hover:bg-violet-400/50 transition"
                onClick={() => setOpen((prev: any) => !prev)}
              >
                <img
                  src={LoadImages(`menu_icons/minimize-outline.svg`)}
                  alt=""
                  className={`w-6 h-6 ${open === false ? "scale-x-[-1]" : ""}`}
                />
                <span
                  className={`${
                    !open ? "invisible" : ""
                  } origin-left duration-200 text-white font-semibold text-sm13 leading-[1.0625rem]`}
                >
                  {open ? "Minimize" : <span className="invisible">&nbsp</span>}
                </span>
              </li>
              {/* Dashboard */}
              <Suspense fallback={<div>Loading...</div>}>
                <AsideMenuItem
                  state={open}
                  title="Dashboard"
                  svgName={DashboardOutline}
                  path={layout.isTxTradeUser === 1 ? "/dashboard" : "/"}
                  width={"w-[1.5rem]"}
                  height={"h-[1.5rem]"}
                />
              </Suspense>
              {/* Notifications */}
              <Suspense fallback={<div>Loading...</div>}>
                <AsideMenuItem
                  state={open}
                  title="Notifications"
                  svgName={
                    notificationStatuts
                      ? NotificationOutline
                      : NotificationOutlineWithout
                  }
                  path={"#"}
                  width={"w-[1.5rem]"}
                  height={"h-[1.5rem]"}
                  setUserInfo={setUserInfo}
                  separator={true}
                />
              </Suspense>
              {/* Add Inventory */}

              {!isGodAdmin && (
                <Suspense fallback={<div>Loading...</div>}>
                  {memoizedAccess &&
                    memoizedAccess?.find(
                      (item: any) => item.id === "inventory" && item.status
                    ) && (
                      <Fragment key={`inventory`}>
                        <AsideMenuItem
                          state={open}
                          title="Add Listings"
                          svgName={AddListingsOutline}
                          path="/add-inventory"
                          width={"w-[1.5rem]"}
                          height={"h-[1.5rem]"}
                        />
                      </Fragment>
                    )}
                </Suspense>
              )}

              {/* Inventory */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "inventory" && item.status
                  ) && (
                    <Fragment key={`inventory`}>
                      <AsideMenuItem
                        state={open}
                        title="My Listings"
                        svgName={MyListingsOutline}
                        path="/inventory"
                        width={"w-[1.5rem]"}
                        height={"h-[1.5rem]"}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* Sales */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "shipping" && item.status
                  ) && (
                    <Fragment key={`shipping`}>
                      {isGodAdmin &&
                      memoizedAccess?.find(
                        (item: any) =>
                          item.id === "instruction_letters" && item.status
                      ) ? (
                        <AsideMenuItem
                          state={open}
                          title="Sales"
                          svgName={SalesOutline}
                          // svgName={SalesOutlineWithout}
                          path={"/sales"}
                          showSubmenu={[
                            {
                              name: "Pending",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount?.orders_count
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount?.orders_count
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Awaiting Delivery",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount
                                  ?.to_be_fulfilled_tab
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount
                                        ?.to_be_fulfilled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Delivered",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount?.fulfilled_tab
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount?.fulfilled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Completed",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount?.completed_tab
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount?.completed_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Cancelled",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount?.cancelled_tab
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount?.cancelled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                          ]}
                          width={"w-[1.5rem]"}
                          height={"h-[1.5rem]"}
                        />
                      ) : (
                        <AsideMenuItem
                          state={open}
                          title="Sales"
                          svgName={SalesOutline}
                          path={"/sales"}
                          showSubmenu={[
                            {
                              name: "Pending",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount?.orders_count
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount?.orders_count
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Awaiting Delivery",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount
                                  ?.to_be_fulfilled_tab
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount
                                        ?.to_be_fulfilled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Delivered",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount?.fulfilled_tab
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount?.fulfilled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Completed",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount?.completed_tab
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount?.completed_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                            {
                              name: "Cancelled",
                              path: "/sales",
                              count: {
                                value: layout?.salesGlobalCount?.cancelled_tab
                                  ? addCommaInNumber(
                                      layout?.salesGlobalCount?.cancelled_tab
                                    )
                                  : 0,
                                show: true,
                              },
                            },
                          ]}
                          width={"w-[1.5rem]"}
                          height={"h-[1.5rem]"}
                        />
                      )}
                    </Fragment>
                  )}
              </Suspense>

              {/* Reports */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "reports" && item.status
                  ) && (
                    <Fragment key="reports">
                      <AsideMenuItem
                        state={open}
                        title="Reports"
                        svgName={ReportsOutline}
                        path="/reports"
                        width={"w-[1.5rem]"}
                        height={"h-[1.5rem]"}
                        separator={true}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* TX Pay */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "txpay" && item.status
                  ) && (
                    <AsideMenuItem
                      state={open}
                      title="TX Pay"
                      svgName={TxPayOutline}
                      path={undefined}
                      width={"w-[1.5rem]"}
                      height={"h-[1.5rem]"}
                      showSubmenu={[
                        {
                          name: TX_TAB.wallet,
                          path: `/tx-pay${TX_PAY_TAB_ROUTES.wallet}`,
                          count: {
                            value: undefined,
                            show: false,
                          },
                        },
                        {
                          name: TX_TAB.virtualCard,
                          path: `/tx-pay${TX_PAY_TAB_ROUTES.virtualCard}`,
                          count: {
                            value: undefined,
                            show: false,
                          },
                        },
                      ]}
                    />
                  )}
              </Suspense>

              {/* TX-TRADE */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "txtrade" && item.status
                  ) && (
                    <AsideMenuItem
                      state={open}
                      title="TX Trade"
                      svgName={TxTradeOutline}
                      path={layout.isTxTradeUser === 1 ? "/" : "/tx-trade"}
                      width={"w-[1.5rem]"}
                      height={"h-[1.5rem]"}
                      separator={true}
                      showSubmenu={
                        memoizedAccess &&
                        memoizedAccess?.find(
                          (item: any) => item.id === "txtrade" && item.status
                        ) &&
                        isGodAdmin &&
                        layout?.isTxTradeCustomize
                          ? [
                              {
                                name: "TX Trade",
                                path: "/tx-trade",
                                count: {
                                  value: undefined,
                                  show: false,
                                },
                              },
                              {
                                name: "Customise",
                                path: "/tx-trade-customise",
                                count: {
                                  value: undefined,
                                  show: false,
                                },
                              },
                            ]
                          : undefined
                      }
                    />
                  )}
              </Suspense>

              {/* API Connector */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "api-connector" && item.status
                  ) && (
                    <Fragment key="API-connector">
                      <AsideMenuItem
                        state={open}
                        title="API-connector"
                        svgName={Plug1}
                        path="/API-connector"
                        width={"w-[1.5rem]"}
                        height={"h-[1.5rem]"}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "settings" && item.status
                  ) && (
                    <AsideMenuItem
                      state={open}
                      title="Settings"
                      svgName={SettingSVG}
                      path={["/settings", "/instruction-letter"]}
                      width={"w-[1.375rem]"}
                      height={"h-[1.125rem]"}
                    />
                  )}
              </Suspense> */}
              {/* <Suspense fallback={<div>Loading...</div>}>
                <AsideMenuItem
                  state={open}
                  title="FAQ"
                  svgName={faqSVG}
                  path={""}
                  width={"w-[1.375rem]"}
                  height={"h-[1.3125rem]"}
                  showSubmenu={[
                    {
                      name: "Help center",
                      path: "https://help.tixstock.com",
                      externalLink: true,
                      count: {
                        value: undefined,
                        show: false,
                      },
                    },
                    {
                      name: "TX Trade policy",
                      path: "/faq/tx-trade-policy",
                      count: {
                        value: undefined,
                        show: false,
                      },
                    },
                  ]}
                />
              </Suspense> */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "subscribers" && item.status
                  ) && (
                    <AsideMenuItem
                      state={open}
                      title="Subscribers"
                      svgName={SubscribeIcon}
                      path={""}
                      showSubmenu={[
                        {
                          name: "Subscriber Details",
                          path: "/personal-details",
                          count: {
                            value: undefined,
                            show: false,
                          },
                        },
                        // {
                        //   name: "Wallets & Bank accounts",
                        //   path: "/wallet-&-bank-details",
                        //   count: {
                        //     value: undefined,
                        //     show: false,
                        //   },
                        // },
                        {
                          name: "Seller levels",
                          path: "/seller-levels",
                          count: {
                            value: undefined,
                            show: false,
                          },
                        },
                      ]}
                      width={"w-[1.375rem]"}
                      height={"h-[1.375rem]"}
                    />
                  )}
              </Suspense>

              {/* Wallets and Banking */}
              <Suspense fallback={<div>Loading...</div>}>
                {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "subscribers" && item.status
                  ) && (
                    <Fragment key="Wallets & Banking">
                      <AsideMenuItem
                        state={open}
                        title="Wallets & Banking"
                        svgName={wallet}
                        path="/wallet-&-bank-details"
                        width={"w-[1.5rem]"}
                        height={"h-[1.5rem]"}
                      />
                    </Fragment>
                  )}
              </Suspense>

              {/* TX Accounts */}
              {isGodAdmin &&
                memoizedAccess &&
                memoizedAccess?.find(
                  (item: any) => item.id === "txaccounts" && item.status
                ) && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <AsideMenuItem
                      state={open}
                      title="TX Accounts"
                      svgName={TXAccountSVG}
                      path="/tx-account"
                      width={"w-[1.5rem]"}
                      height={"h-[1.5rem]"}
                    />
                  </Suspense>
                )}
            </>
          </ul>

          {/** Sub menu */}
          <div className="submenu-main">
            <ShowSubmenu
              title="FAQ"
              showSubmenu={[
                {
                  name: "Help center",
                  path: "https://help.tixstock.com",
                  externalLink: true,
                  count: {
                    value: undefined,
                    show: false,
                  },
                },
                {
                  name: "TX Trade policy",
                  path: "/faq/tx-trade-policy",
                  count: {
                    value: undefined,
                    show: false,
                  },
                },
              ]}
            />

            <ShowSubmenu
              title="Subscribers"
              showSubmenu={[
                {
                  name: "Personal/company details",
                  path: "/personal-details",
                  count: {
                    value: undefined,
                    show: false,
                  },
                },
                // {
                //   name: "Wallets & Bank accounts",
                //   path: "/wallet-&-bank-details",
                //   count: {
                //     value: undefined,
                //     show: false,
                //   },
                // },
                {
                  name: "Seller levels",
                  path: "/seller-levels",
                  count: {
                    value: undefined,
                    show: false,
                  },
                },
              ]}
            />
          </div>
          {/* Help Chat  */}
          {/* <div
            className={`absolute bottom-0 ${
              menuopen ? "left-0" : "-left-[3.75rem]"
            }  w-full md:static help-chat-module bg-violet-600 mt-auto p-2.5 z-50 transition-all duration-200`}
          >
            <div className="relative flex items-center p-2 bg-green-600 text-sm13 font-medium text-white rounded">
              <IKTSVG
                path={HelpIcon}
                className="w-6 min-w-6 h-6 flex items-center justify-center mr-2"
                svgClassName="w-6 h-6"
              />
              {open && <span>Help</span>}
              <span className="absolute right-[calc(100%_-_2.875rem)] -top-2 min-w-4 h-4 p-0.5 leading-none text-center text-xxs rounded-full bg-rose-500 border-[.0938rem] border-violet-600">
                2
              </span>
            </div>
          </div> */}
        </div>

        {userInfo && (
          <>
            <div className=" relative">
              <div className="absolute arrow top-0 left-0 w-5 h-5 bg-violet-600"></div>
              <div className="absolute arrow bottom-0 left-0 w-5 h-5 bg-violet-600"></div>
              <div className="w-[20.8125rem] h-screen flex flex-col relative duration-400 bg-white border-l pointer-events-auto rounded-l-2xl overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                <div className="w-full py-[1.1875rem] px-5">
                  <h2 className="w-full text-sm18 leading-[1.3125rem] font-medium mb-1.5 capitalize">
                    {/* Scott Welch */}
                    {layout.allowedAccess?.account_info?.firstname}
                    {"  "}
                    {layout.allowedAccess?.account_info?.surname}
                  </h2>
                  <p className="flex items-baseline w-full text-sm12 leading-[0.9375rem] text-gray-500">
                    {layout.allowedAccess?.team_data?.seller_level?.title?.toLowerCase() ===
                    "super level"
                      ? "Super Seller"
                      : `Seller ${layout.allowedAccess?.team_data?.seller_level?.title}`}
                    <span
                      className={`ml-2 w-[0.5625rem] h-[0.5625rem] rounded-sm  bg-green-600 border-green-600`}
                    ></span>
                    <span
                      className={`ml-1 w-[0.5625rem] h-[0.5625rem] rounded-sm  ${
                        getSellerLevelNumberWithColor(
                          layout.allowedAccess?.team_data?.seller_level
                        ) !== 3 && "!bg-green-600 !border-green-600"
                      }   bg-green-600/25 border-green-600/25`}
                    ></span>
                    <span
                      className={`ml-1 w-[0.5625rem] h-[0.5625rem] rounded-sm ${
                        [1, "S"]?.includes(
                          getSellerLevelNumberWithColor(
                            layout.allowedAccess?.team_data?.seller_level
                          )
                        ) && "!bg-green-600 !border-green-600"
                      } bg-green-600/25 border-green-600/25`}
                    ></span>
                    <span
                      className={`ml-1 w-[0.5625rem] h-[0.5625rem] rounded-sm ${
                        getSellerLevelNumberWithColor(
                          layout.allowedAccess?.team_data?.seller_level
                        ) === "S" && "!bg-yellow-100 !border-yellow-100"
                      } bg-yellow-100/25 border-yellow-100/25`}
                    ></span>
                  </p>
                </div>
                <div className="w-full pb-2.5 border-b border-gray-200">
                  {memoizedAccess &&
                  memoizedAccess?.find(
                    (item: any) => item.id === "settings" && item.status
                  ) ? (
                    // <a className="w-full hover:bg-gray-100/50 inline-flex items-center text-sm13 leading-4 font-medium px-5 h-[2.125rem]">
                    <a
                      href="/settings"
                      target="_self"
                      className="w-full hover:bg-gray-100/50 inline-flex items-center text-sm13 leading-4 font-medium px-5 h-[2.125rem]"
                      rel="noreferrer"
                    >
                      <SVG
                        src={AccountGear}
                        className={`w-[1.5rem] h-[1.5rem] fill-violet-500 mr-2.5`}
                      />
                      Account Settings
                    </a>
                  ) : null}

                  <a
                    href="https://help.tixstock.com"
                    target="_blank"
                    className="w-full hover:bg-gray-100/50 inline-flex items-center text-sm13 leading-4 font-medium px-5 h-[2.125rem]"
                    rel="noreferrer"
                  >
                    <SVG
                      src={CircleQuestion}
                      className={`w-[1.5rem] h-[1.5rem] fill-violet-500 mr-2.5`}
                    />
                    Help Centre
                  </a>
                  <button
                    className="w-full hover:bg-gray-100/50 inline-flex items-center text-sm13 leading-4 font-medium px-5 h-[2.125rem]"
                    onClick={() => {
                      handleLogout();
                    }}
                  >
                    <SVG
                      src={LogoutSVG2}
                      className={`w-[1.5rem] h-[1.5rem] fill-violet-500 mr-2.5`}
                    />
                    Logout
                  </button>
                </div>
                <div className="w-full p-5">
                  <h4 className="w-full text-sm15 leading-[1.375rem] font-medium mb-[0.875rem]">
                    You are on track to maintain Level 3 status for next quarter
                  </h4>
                  <p className="w-full text-sm12 leading-[1.0625rem] font-normal text-gray-500">
                    Your GTV activity during the current calendar quarter will
                    determine your Seller Level in the next calendar quarter.{" "}
                    <span className="font-medium text-violet-800 cursor-pointer">
                      Learn more
                    </span>
                  </p>
                  <div className="w-full border border-gray-200 rounded my-5">
                    <div className="flex gap-x-[0.3125rem] w-full border-b border-gray-200 p-[0.6875rem] flex-shrink-0">
                      <div className="w-full rounded h-[1.125rem] bg-gray-200 relative before:absolute before:content-[''] before:h-full before:w-[58%] before:left-0 before:top-0 before:bg-green-600 before:rounded-s">
                        {/* bg-green-600/30 */}
                        <span
                          className="flex items-center justify-center text-white w-full h-full text-xxs leading-3 font-medium relative z-10"
                          id="seller-current-level-gtv"
                        >
                          <Tooltip
                            anchorId={`seller-current-level-gtv`}
                            content={`${"Target GTV £12,500"}`}
                            place={`${"top"}`}
                            variant="light"
                            className={`!bg-white max-w-[4rem] !opacity-100 !py-0.5 !px-[0.3125rem] !text-xxs font-medium z-10 mt-1.5`}
                          />
                          3
                        </span>
                      </div>
                      <div className="w-full rounded h-[1.125rem] bg-gray-200 relative">
                        {/* bg-green-600/30 */}
                        <span
                          className="flex items-center justify-center text-white w-full h-full text-xxs leading-3 font-medium relative z-10"
                          id="seller-current-level-gtv2"
                        >
                          2
                        </span>
                      </div>
                      <div className="w-full rounded h-[1.125rem] bg-gray-200 relative">
                        {/* bg-green-600/30 */}
                        <span
                          className="flex items-center justify-center text-white w-full h-full text-xxs leading-3 font-medium relative z-10"
                          id="seller-current-level-gtv1"
                        >
                          1
                        </span>
                      </div>
                      <div className="w-full rounded h-[1.125rem] max-w-[1.125rem] bg-gray-200 relative ">
                        {/* bg-yellow-100/30 */}
                        <span
                          className="flex items-center justify-center text-white w-full h-full text-xxs leading-3 font-medium relative z-10"
                          id="seller-current-level-gtvS"
                        >
                          S
                        </span>
                      </div>
                    </div>
                    <div className="px-[0.9375rem] py-3">
                      <div className="flex w-full">
                        <div className="w-full">
                          <p className="text-sm12 leading-[0.9375rem] w-full mb-1.5">
                            GTV Oct - Dec
                          </p>
                          <span className="text-sm12 leading-[0.9375rem] text-gray-500 w-full">
                            30 days left
                          </span>
                        </div>
                        <p className="text-end w-full text-sm15 leading-[1.1875rem]">
                          £8,000.00
                        </p>
                      </div>
                      <p className="w-full text-sm12 leading-[0.9375rem] font-medium text-gray-500 mt-1.5">
                        GTV £15,000+ to unlock Level 2
                      </p>
                    </div>
                  </div>
                  <div className="levelSLider w-full">
                    <Swiper
                      className="w-full border rounded"
                      modules={[Navigation]}
                      navigation={{
                        prevEl: ".swiper-button-prev-new",
                        nextEl: ".swiper-button-next-new",
                      }}
                    >
                      {layout?.allowedAccess?.seller_levels?.map(
                        (obj: any, index: any) => {
                          return (
                            <SwiperSlide>
                              <SellerLevelBenefitsSlider
                                data={obj}
                                key={index}
                              />
                            </SwiperSlide>
                          );
                        }
                      )}
                    </Swiper>
                    <div className="mt-[0.9375rem] flex">
                      <button className="flex items-center justify-center w-6 h-6 rounded-s bg-gray-100 group hover:bg-indigo-500 disabled:hover:bg-gray-100 swiper-button-prev-new">
                        <SVG
                          src={AngleRight}
                          className="h-[0.5625rem] w-1.5 rotate-180 group-hover:fill-white group-disabled:fill-gray-400 fill-violet-500"
                        />
                      </button>
                      <button className="flex items-center justify-center w-6 h-6 rounded-e bg-gray-100 group hover:bg-indigo-500 disabled:hover:bg-gray-100 swiper-button-next-new">
                        <SVG
                          src={AngleRight}
                          className="h-[0.5625rem] w-1.5 group-hover:fill-white group-disabled:fill-gray-400 fill-violet-500"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {layout?.notificationId ? (
          <div className=" relative">
            <div className="absolute arrow top-0 left-0 w-5 h-5 bg-violet-600"></div>
            <div className="absolute arrow bottom-0 left-0 w-5 h-5 bg-violet-600"></div>
            <div className="w-[20.8125rem] h-screen flex flex-col relative duration-400 bg-white border-l pointer-events-auto rounded-l-2xl">
              <div className="">
                <div className="flex items-start justify-between p-5 border-b border-gray-200">
                  <h2 className="text-sm18 font-semibold">Notifications</h2>
                  <button
                    className={`text-sm12 font-medium leading-[0.9375rem] px-2 py-1 text-white bg-violet-500 hover:bg-indigo-500 rounded`}
                    onClick={() => {
                      navigate("../notifications", { replace: true });
                      layout?.setNotificationId(undefined);
                      let logo: any = document.querySelector(".logo");
                      logo && logo.click();
                    }}
                  >
                    View all
                  </button>
                </div>
                {/* <div className="flex px-5 py-5 border-b border-white/10">
                  <button
                    type="button"
                    className="flex items-center text-sm13 font-medium hover:underline text-white"
                    onClick={() => {
                      navigate("../notifications", { replace: true });
                      layout?.setNotificationId(undefined);
                      let logo: any = document.querySelector(".logo");
                      logo && logo.click();
                    }}
                  >
                    <SVG className="w-3.5 mr-2.5" src={Alerts} />
                    View all
                  </button>
                </div> */}
                <div className="p-5 pb-3 border-b border-gray-200">
                  <button
                    className="w-full py-3 px-[0.9375rem] text-xs font-medium bg-green-600/[0.07] border border-green-600/20 rounded transition flex items-center justify-between mb-2.5"
                    onClick={() => layout.setTabValue("notification")}
                  >
                    {listingData?.data?.new_notification > 1
                      ? "New notifications"
                      : "New notification"}
                    <span className="text-sm15 font-medium leading-[1.1875rem]">
                      {listingData?.data?.new_notification
                        ? addCommaInNumber(listingData?.data?.new_notification)
                        : ""}
                    </span>
                  </button>
                  <button
                    className="w-full flex items-center justify-between py-3 px-[0.9375rem] text-xs font-medium  border border-gray-200 rounded transition mb-5"
                    onClick={() => layout.setTabValue("activity")}
                  >
                    {layout?.tabValue === "activity" &&
                    layout?.activities?.length > 1
                      ? "Activity logs"
                      : "Activity log"}
                    <span className="text-sm15 font-medium leading-[1.1875rem]">
                      9,999
                    </span>
                  </button>
                  <span className="notification-count text-sm12 font-medium leading-[0.9375rem] text-gray-500">
                    You have 81 notifications
                  </span>
                </div>
              </div>

              {notificationsLoading ? (
                <div className="text-center py-10 opacity-40">
                  {/* Loading... */}
                  <KTSVG
                    className=" flex items-center justify-center"
                    svgClassName="w-4 h-4 animate-spin"
                    path="other_icons/white_process_fill.svg"
                  />
                </div>
              ) : (
                <div className="notification-list overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                  <ul>
                    {layout?.tabValue === "notification" ||
                    listingData?.data?.notifications?.length > 0 ? (
                      listingData?.data?.notifications.map(
                        (notification: any, index: any) => (
                          <li
                            className={`pt-2 px-5 transition hover:bg-violet-100/10 ${
                              notification.is_pinned ? "pinned" : ""
                            } ${!notification.is_pinned ? "unpinned" : ""}`}
                          >
                            {notification.is_pinned ? (
                              <>
                                <div className="pb-2 border-b border-white/10 text-xxs font-medium cursor-pointer inner-pin">
                                  <Tooltip
                                    anchorId={`record-unpin-tooltip${index}`}
                                    content="Mark as Unpinned"
                                    place="top"
                                    variant="light"
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                  />
                                  <div
                                    className="text-sky-blue-500 mb-1"
                                    id={`record-unpin-tooltip${index}`}
                                    onClick={(e: any) =>
                                      handleUnpin(e, notification.id)
                                    }
                                  >
                                    <KTSVG
                                      className="inline-flex fill-sky-blue-500 text-center mr-1"
                                      svgClassName="w-[.4375rem] h-2.5"
                                      path="standard_icons/pin-icon.svg"
                                    />
                                    <span className="px-1">Pinned</span>
                                    {moment(notification.created_at).format(
                                      "DD MMM"
                                    )}
                                  </div>
                                  <div
                                    className="text-gray-600"
                                    dangerouslySetInnerHTML={{
                                      __html: notification.text,
                                    }}
                                  ></div>
                                </div>
                              </>
                            ) : notification.is_new ? (
                              <>
                                <div className="pb-2 border-b text-xxs font-medium border-white/10 cursor-pointer inner-pin">
                                  <Tooltip
                                    anchorId={`record-pin-tooltip${index}`}
                                    content="Mark as Pinned"
                                    place="top"
                                    variant="light"
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                  />
                                  <div className="group">
                                    <div
                                      className="group-hover:opacity-100 group-hover:visible group-hover:inline-flex opacity-0 invisible hidden overflow-hidden items-center mb-1"
                                      id={`record-pin-tooltip${index}`}
                                      onClick={(e: any) =>
                                        handlePin(e, notification.id)
                                      }
                                    >
                                      <KTSVG
                                        className="inline-flex fill-sky-blue-500 text-center mr-1"
                                        svgClassName="w-[.5rem] h-[.625rem]"
                                        path="standard_icons/pin-icon.svg"
                                      />
                                      <span className="px-1 text-sky-blue-500">
                                        Pin To Top
                                      </span>
                                      <span className="text-gray-400">
                                        {moment(notification.created_at).format(
                                          "DD MMM"
                                        )}
                                      </span>
                                    </div>
                                    <div
                                      className="flex items-center mr-1 mb-1 text-green-600 group-hover:hidden"
                                      id={`record-pin-tooltip${index}`}
                                    >
                                      <span className="w-2 h-2 rounded-full bg-green-600 mr-1.5"></span>
                                      <span className="px-1 ">New</span>
                                      {moment(notification.created_at).format(
                                        "DD MMM"
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className="text-gray-600 pt-1"
                                    dangerouslySetInnerHTML={{
                                      __html: notification.text,
                                    }}
                                  ></div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="pb-2 border-b text-xxs font-medium border-white/10 cursor-pointer"
                                  onClick={(e: any) =>
                                    handlePin(e, notification.id)
                                  }
                                >
                                  <Tooltip
                                    anchorId={`record-pin-tooltip${index}`}
                                    content="Mark as Pinned"
                                    place="top"
                                    variant="light"
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                  />
                                  <div
                                    className="group-hover:opacity-100 group-hover:visible group-hover:inline-flex opacity-0 invisible hidden overflow-hidden items-center"
                                    id={`record-pin-tooltip${index}`}
                                  >
                                    <KTSVG
                                      className="inline-flex fill-sky-blue-500 text-center mr-1"
                                      svgClassName="w-1.5 h-2"
                                      path="standard_icons/pin-icon.svg"
                                    />
                                    <span className="pr-1 text-sky-blue-500">
                                      Pin To Top
                                    </span>
                                  </div>
                                  <span className="text-gray-500">
                                    {moment(notification.created_at).format(
                                      "DD MMM"
                                    )}
                                  </span>
                                  <div
                                    className="text-gray-600 pt-1"
                                    dangerouslySetInnerHTML={{
                                      __html: notification.text,
                                    }}
                                  ></div>
                                </div>
                              </>
                            )}
                          </li>
                        )
                      )
                    ) : layout?.tabValue === "activity" &&
                      layout?.activities?.length > 0 ? (
                      layout?.activities?.map(
                        (activity: any, aIndex: number) => (
                          <li className="pt-2 px-5 transition" key={aIndex}>
                            {activity.is_pinned ? (
                              <div className="pb-2 border-b text-xxs font-medium border-white/10">
                                <div className="text-sky-blue-500 mb-1">
                                  <KTSVG
                                    className="inline-flex fill-sky-blue-500 text-center mr-1"
                                    svgClassName="w-1.5 h-2"
                                    path="standard_icons/pin-icon.svg"
                                  />
                                  {moment(activity.created_at).format("DD MMM")}
                                </div>
                                <div
                                  className="text-gray-600"
                                  dangerouslySetInnerHTML={{
                                    __html: activity.text,
                                  }}
                                ></div>
                              </div>
                            ) : activity.is_new ? (
                              <div className="pb-2 border-b text-xxs font-medium border-gray-400">
                                <div className="flex items-center mr-1 text-green-600">
                                  <span className="w-2 h-2 rounded-full bg-green-600 mr-1.5"></span>
                                  <span className="px-1">New</span>
                                  {moment(activity.created_at).format("DD MMM")}
                                </div>
                                <div
                                  className="text-gray-600"
                                  dangerouslySetInnerHTML={{
                                    __html: activity.text,
                                  }}
                                ></div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </li>
                        )
                      )
                    ) : (
                      <p className="text-white text-center text-xs py-4">
                        No records found at the moment.
                      </p>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : (
          " "
        )}
      </div>
      {/* {userInfo || layout?.notificationId ? (
        <div className="w-[calc(100vw--3.75rem)] z-100 bg-black bg-opacity-70 fixed top-0 left-0 h-full"></div>
      ) : (
        ""
      )} */}
    </>
  );
};

export default AsideDefault;
