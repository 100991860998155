import { useEffect, useRef } from "react";

// import ListingInfo from "pages/inventory/components/ListingInfo";
import AngleLeft from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import ListingInfo from "./ListingInfo";
// import ListingInfo from "./ListingInfo";

const ListingHeader = ({
  countsData,
  publishStatus,
  setPublishStatus,
  disabled,
  isLoading,
}: any) => {
  const filter_scroll: any = useRef();
  const scroll_filter = (scrollOffset: any) => {
    filter_scroll.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    function hideArrowScroll() {
      const left_sc: any = document.getElementById("scroll_left");
      const right_sc: any = document.getElementById("scroll_right");
      const filter_scroll: any = document.getElementById("filter_scroll");
      const maxScrollLeft: any =
        filter_scroll?.scrollWidth - filter_scroll?.clientWidth;

      if (filter_scroll?.scrollLeft === 0) {
        left_sc.style.display = "none";
      } else {
        left_sc.style.display = "flex";
      }
      if (filter_scroll.scrollLeft >= maxScrollLeft) {
        right_sc.style.display = "none";
      } else {
        right_sc.style.display = "flex";
      }
    }
    const filter_scroll = document.getElementById("filter_scroll"); // Make sure filter_scroll is defined and references the correct element
    filter_scroll?.addEventListener("scroll", hideArrowScroll);
    hideArrowScroll();
    return () => {
      filter_scroll?.removeEventListener("scroll", hideArrowScroll); // Cleanup: Remove event listener when component unmounts
    };
  }, []);
  return (
    <>
      <div
        className="border-b "
        id="inventoryaccordion"
        data-te-collapse-item
        data-te-collapse-show
      >
        <div className="px-5 pt-3 pr-2.5 relative">
          <span className="text-sm15 font-semibold">Overview</span>
          <div
            className="divide-gray-200 divide-y-2 scroll_container"
            id={`scroll_container`}
          >
            <div
              ref={filter_scroll}
              id={`filter_scroll`}
              className="filter_scroll flex flex-nowrap pt-3 -mx-2.5 -ml-5 whitespace-nowrap overflow-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-violet-800 123"
            >
              {/** Commented due to hold suggestion API task  */}
              <div
                id={`scroll_right`}
                className="arrow_slide absolute top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center right-0 bg-gray-100 rounded-l group hover:bg-indigo-500 cursor-pointer mt-3"
                // onClick={() => scrollLeft()}
                onClick={() => scroll_filter(50)}
              >
                <IKTSVG
                  path={AngleRight}
                  svgClassName="w-1.5 h-[.5625rem]"
                  className=" group-hover:fill-white"
                />
              </div>
              <div
                id={`scroll_left`}
                className="arrow_slide absolute translate-y-1/2 w-6 h-6 flex items-center justify-center left-0 bg-gray-100 rounded-r group hover:bg-indigo-500 cursor-pointer mt-3"
                onClick={() => scroll_filter(-50)}
                // onClick={() => scroll_filter(50)}
              >
                <IKTSVG
                  path={AngleLeft}
                  svgClassName="w-1.5 h-[.5625rem]"
                  className=" group-hover:fill-white"
                />
              </div>

              <ListingInfo
                listName="Total"
                listCount={countsData?.total}
                disabled={disabled}
                isLoading={isLoading}
              />

              <ListingInfo
                listName="Published listings"
                listCount={countsData?.yes}
                isCheckbox={true}
                postKey="yes"
                isLoading={isLoading}
              />

              <ListingInfo
                listName="Unpublished listings"
                listCount={countsData?.no}
                isCheckbox={true}
                postKey="no"
                isLoading={isLoading}
              />

              <ListingInfo
                listName="Listings with errors"
                listCount={countsData?.errors}
                isCheckbox={true}
                postKey="errors"
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListingHeader;
