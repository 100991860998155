import { size } from "lodash";
import { useContext } from "react";
import { useQuery } from "react-query";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { errorReflect } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import {
  API_CONNECTOR_ACCEPTED_STATUS_CODE,
  INITIAL_HEADERS,
  QUERIES,
} from "../../../helpers/const";
import { ConnectorContext } from "../core/ConnectorProvider";
import { replacePatterns } from "../core/functions";
import { fetchThroughMethod, getTriggerListing } from "../core/requests";
import APISearchField from "./component/APISearchField";
import APITab from "./component/APITab";
import AuthenticationBodyWrapper from "./component/AuthenticationBodyWrapper";

const Authentication = ({ data }: any) => {
  const layout = useContext(ConnectorContext);
  const state: any = layout?.state;
  const headers: any = layout?.headers;
  const variableData: any = layout?.variableData;
  const { id } = data;

  // FETCH AUTHENTICATION DATA
  const { isLoading } = useQuery(
    [`${QUERIES.API_CONNECTOR_TRIGGERS}`],
    () =>
      getTriggerListing({
        id: id,
        name: "auth",
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled(data, error) {
        const authData = data?.data?.[0];

        if (size(authData) > 0) {
          let headersArray =
            authData?.headers && size(headers) > 0
              ? Object.entries(authData?.headers)?.map(([key, value]) => {
                  return {
                    name: key,
                    value: value,
                  };
                })
              : [INITIAL_HEADERS];

          // HEADERS & MAIN STATE UPDATE
          layout.setHeaders(headersArray);
          layout.setMapping(authData?.response_mapped_variables);
          layout.setState((current: any) => {
            return {
              ...current,
              url: authData?.endpoint_url,
              headers: headersArray ? headersArray : current?.headers,
              body:
                typeof authData?.body === "object"
                  ? JSON.stringify(authData?.body)
                  : authData?.body,
              triggerId: authData?.id,
              mapping: authData?.response_mapped_variables,
            };
          });
        }
      },
    }
  );

  // HANDLE TAB ACTIVE
  const handleActiveTab = (tab: string) => {
    layout.setState((current: any) => {
      return {
        ...current,
        activeTab: tab,
      };
    });
  };

  // HANDLE ERROR
  const handleError = (response: any) => {
    showAlert(`${response.status}`, true);
  };

  // EXECUTE API
  const executeAPIAndLoadState = () => {
    let payload = { ...state, headers: headers };
    payload.url = replacePatterns(variableData, payload.url);
    payload.headers = payload.headers?.map((item: any) => {
      return {
        ...item,
        value: replacePatterns(variableData, item?.value),
      };
    });

    payload.body = replacePatterns(variableData, payload?.body);
    layout.setLoader(true);
    fetchThroughMethod(payload)
      .then((response: any) => {
        layout.setLoader(false);
        if (!response.ok || (response?.error && size(response?.error) > 0)) {
          errorReflect(response);
          layout.setState((current: any) => {
            return {
              ...current,
              response: {
                ...current?.response,
                data: response,
                error: API_CONNECTOR_ACCEPTED_STATUS_CODE?.includes(
                  response?.statusCode || response?.status
                )
                  ? false
                  : true,
                message: response?.status,
              },
            };
          });
        } else if (
          API_CONNECTOR_ACCEPTED_STATUS_CODE?.includes(response?.status)
        ) {
          layout.setState((current: any) => {
            return {
              ...current,
              response: {
                data: response,
                error: false,
                message: "Success",
              },
            };
          });
        }
      })
      .catch((err) => {
        layout.setLoader(false);
        handleError(err);
      });
  };

  // HANDLE API SEND
  const handleSend = () => {
    let { body } = state;
    body = body?.trim();
    try {
      if (body) {
        JSON.parse(body);
        executeAPIAndLoadState();
      } else {
        executeAPIAndLoadState();
      }
    } catch (error) {
      layout.setLoader(false);
      showAlert("Please check the content in the body", true);
    }
  };

  return (
    <div
      className={`inventory-list w-full px-5 pt-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full ${
        layout.canvasToggle ? "px-5" : "pl-10 px-5"
      }`}
    >
      {/* URL FIELD */}
      <APISearchField handleSend={handleSend} />

      <div className="mt-4 flex justify-around tab-panel bg-no-repeat bg-cover bg-indigo-500 bg-opacity-[15%] p-5 pb-0  after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
        <APITab
          title="Headers"
          status={state?.activeTab}
          handleActive={handleActiveTab}
        />
        <APITab
          title="Body"
          status={state?.activeTab}
          handleActive={handleActiveTab}
        />
      </div>

      <div
        className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
        role="tabpanel"
        data-te-tab-active
      >
        <AuthenticationBodyWrapper />
      </div>

      {/* LOADER */}
      {isLoading && <ProgressBardLoader secondCounter={1.5} />}
    </div>
  );
};

export default Authentication;
