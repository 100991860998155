import PageTitle from "../../components/core/PageTitle";
import { FaqProvider } from "./core/FaqProvider";
import FAQ from "./faq";

// import Footer from "./layout/footer";

const FAQWrapper = () => {
  return (
    <>
      <FaqProvider>
        <PageTitle title="FAQ Centre" />
        <FAQ />
        {/* <Footer/> */}
      </FaqProvider>
    </>
  );
};

export default FAQWrapper;
