const ClockOutline = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        id="clock-outline"
        d="M8,12.8A4.8,4.8,0,1,0,3.2,8,4.8,4.8,0,0,0,8,12.8M8,2A6,6,0,1,1,2,8,6,6,0,0,1,8,2m.3,3V8.15l2.7,1.6-.45.738L7.4,8.6V5Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export default ClockOutline;
