import CheckboxFilter from "pages/tx_trade/component/filters/CheckboxFilter";
import React, { useContext } from "react";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";

const CountBoxFilter = ({ loading }: any) => {
  const layout: any = useContext(SellerLevelsProviderContex);
  const count = layout.sellerLevelData?.count;

  return (
    <div
      className="bg-white  accordion-collapse font-medium border-t collapse show"
      id="inventoryaccordion"
      data-te-collapse-item
      data-te-collapse-show
      aria-labelledby="headingOne"
    >
      <div
        className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
        role="tabpanel"
        data-te-tab-active
      >
        <div className="px-5 pt-2">
          <div className="divide-gray-200 divide-y-2">
            <div className="flex flex-nowrap pt-3 -mx-2.5 overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <CheckboxFilter
                title="Total levels"
                trackedLoading={loading}
                count={count}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountBoxFilter;
