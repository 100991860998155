import "@fortawesome/fontawesome-free/css/all.min.css";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-tooltip/dist/react-tooltip.css";
import "tw-elements";
// import { Modal, Ripple, initTE } from "tw-elements";
import "../src/assets/styles/form.scss";
import "../src/assets/styles/style.scss";
import "../src/assets/styles/visibilityanimation.scss";
import App from "./App";
import "./index.scss";

// initTE({ Modal, Ripple });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
