import { IKTSVG } from "components/ui/IKTSVG";
import DeleteSVG from "../../../../../assets/media/icons/other_icons/Delete.svg";
import EDITSVG from "../../../../../assets/media/icons/other_icons/edit-square.svg";

import { LayoutContext } from "components/core/LayoutProvider";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { SellerLevelsProviderContex } from "../../core/SellerLevelsProvider";

const TableEditAction = ({
  sIndex,
  list,
  refetch,
  matchedView,
  setDeleteLevelLoader = () => {},
  setDeleteConfirmation = () => {},
}: any) => {
  const layout: any = useContext(SellerLevelsProviderContex);
  const globalLayout: any = useContext(LayoutContext);
  const navigate = useNavigate();

  function convertToId(value: any) {
    return value
      ?.toLowerCase()
      ?.replace(/\s+/g, "_")
      ?.replace(/[^\w_]/g, "");
  }

  const handOnDeleteLevel = async (data: any) => {
    setDeleteConfirmation({
      status: true,
      id: data?.id,
    });
  };

  return (
    // <td
    //   className={`md:sticky relative right-0 ml-auto p-0 icon_td md:w-[13.0625rem] md:max-w-[13.0625rem] md:min-w-[13.0625rem] w-[8rem] max-w-[8rem] min-w-[8rem]`}
    // >
    //   <div className={` bg-opacity-[7%] `}>
    //     <div
    //       className="flex left-shad relative w-full h-full border-l"
    //       id="left-shad-list"
    //     >
    //       <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1 max-md:hidden">
    //         <button
    //           className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1  h-7  `}
    //           type="button"
    //           id={`delete-record-view-tooltip${sIndex}`}
    //           onClick={() => {
    //             globalLayout.setLevelGlobal([
    //               {
    //                 id: list?.id,
    //                 label: convertToId(list?.title),
    //                 name: list?.title,
    //               },
    //             ]);
    //             navigate("/personal-details");
    //           }}
    //         >
    //           View teams
    //         </button>
    //       </div>
    //     </div>
    //     <div
    //       className="flex left-shad relative w-[6.25rem] h-full border-l"
    //       id="left-shad-list"
    //     >
    //       <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1 max-md:hidden">
    //         <button
    //           className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
    //           type="button"
    //           id={`delete-record-view-tooltip${sIndex}`}
    //           onClick={() => {
    //             handOnDeleteLevel(list);
    //             // layout.setSellerLevelEditData(list);
    //           }}
    //         >
    //           <Tooltip
    //             anchorId={`delete-record-view-tooltip${sIndex}`}
    //             content={`${"Delete"}`}
    //             place={`${sIndex === 0 ? "left" : "top"}`}
    //             variant="light"
    //             className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
    //               sIndex === 0 ? "mt-0" : "mt-1.5"
    //             }`}
    //           />

    //           <IKTSVG
    //             className=" flex items-center justify-center fill-white group-hover:fill-white"
    //             path={DeleteSVG}
    //             svgClassName="w-2.5 h-[.8125rem]"
    //           />
    //         </button>
    //       </div>
    //     </div>
    //     <div
    //       className="flex left-shad relative w-[6.25rem] h-full border-l"
    //       id="left-shad-list"
    //     >
    //       <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1 max-md:hidden">
    //         <button
    //           className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
    //           type="button"
    //           id={`record-view-tooltip${sIndex}`}
    //           data-bs-toggle="offcanvas"
    //           data-bs-target="#level_confi_popup"
    //           aria-controls="level_confi_popup_label"
    //           onClick={() => {
    //             layout.setSellerLevelEditData(list);
    //           }}
    //         >
    //           <Tooltip
    //             anchorId={`record-view-tooltip${sIndex}`}
    //             content={`${"Edit"}`}
    //             place={`${sIndex === 0 ? "left" : "top"}`}
    //             variant="light"
    //             className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
    //               sIndex === 0 ? "mt-0" : "mt-1.5"
    //             }`}
    //           />
    //           <IKTSVG
    //             className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
    //             svgClassName="w-4 h-[.6875rem]"
    //             path={EDITSVG}
    //           />
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </td>
    <>
      <td
        className={`md:sticky relative right-0 ml-auto p-0 icon_td md:w-[13.0625rem] md:max-w-[13.0625rem] md:min-w-[13.0625rem] w-[9.375rem] max-w-[9.375rem] min-w-[9.375rem]`}
      >
        <div
          className={` bg-opacity-[7%] ${
            matchedView && "bg-indigo-500 bg-opacity-[7%]"
          }`}
        >
          <div
            className="flex left-shad relative w-full h-full border-l"
            id="left-shad-list"
          >
            <div
              id={sIndex}
              className="w-[6.5625rem] max-w-[6.5625rem] min-w-[6.5625rem] border-r subscription_drop p-[.625rem] pt-[.4rem] "
            >
              <button
                className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1  h-7  `}
                type="button"
                // id={`delete-record-view-tooltip${sIndex}`}
                onClick={() => {
                  globalLayout.setLevelGlobal([
                    {
                      id: list?.id,
                      label: convertToId(list?.title),
                      name: list?.title,
                    },
                  ]);
                  navigate("/personal-details");
                }}
              >
                View teams
              </button>
            </div>

            <div className="flex items-center justify-center p-1 md:p-1 w-8 md:border-r md:flex-auto flex-1 ">
              <button
                className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                type="button"
                id={`delete-record-view-tooltip${sIndex}`}
                onClick={() => {
                  handOnDeleteLevel(list);
                  // layout.setSellerLevelEditData(list);
                }}
              >
                <Tooltip
                  anchorId={`delete-record-view-tooltip${sIndex}`}
                  content={`${"Delete"}`}
                  place={`${sIndex === 0 ? "left" : "top"}`}
                  variant="light"
                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                    sIndex === 0 ? "mt-0" : "mt-1.5"
                  }`}
                />

                <IKTSVG
                  className=" flex items-center justify-center fill-white group-hover:fill-white"
                  path={DeleteSVG}
                  svgClassName="w-2.5 h-[.8125rem]"
                />
              </button>
            </div>
            <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1 max-md:hidden">
              <button
                className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                type="button"
                id={`record-view-tooltip${sIndex}`}
                data-bs-toggle="offcanvas"
                data-bs-target="#level_confi_popup"
                aria-controls="level_confi_popup_label"
                onClick={() => {
                  layout.setSellerLevelEditData(list);
                }}
              >
                <Tooltip
                  anchorId={`record-view-tooltip${sIndex}`}
                  content={`${"Edit"}`}
                  place={`${sIndex === 0 ? "left" : "top"}`}
                  variant="light"
                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                    sIndex === 0 ? "mt-0" : "mt-1.5"
                  }`}
                />
                <IKTSVG
                  className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
                  svgClassName="w-4 h-[.6875rem]"
                  path={EDITSVG}
                />
              </button>
            </div>
          </div>
        </div>
      </td>
      {globalLayout?.isMobile && (
        <td
          className={`sticky right-0 ml-auto p-0 icon_td w-[2.5rem] max-w-[2.5rem] min-w-[2.5rem]`}
        >
          <div
            className={` bg-opacity-[7%] ${
              matchedView && "bg-indigo-500 bg-opacity-[7%]"
            }`}
          >
            <div
              className="flex left-shad relative w-full h-full border-l"
              id="left-shad-list"
            >
              <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1">
                <button
                  className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                  type="button"
                  id={`record-view-tooltip${sIndex}`}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#level_confi_popup"
                  aria-controls="level_confi_popup_label"
                  onClick={() => {
                    layout.setSellerLevelEditData(list);
                  }}
                >
                  <Tooltip
                    anchorId={`record-view-tooltip${sIndex}`}
                    content={`${"Edit"}`}
                    place={`${sIndex === 0 ? "left" : "top"}`}
                    variant="light"
                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                      sIndex === 0 ? "mt-0" : "mt-1.5"
                    }`}
                  />
                  <IKTSVG
                    className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
                    svgClassName="w-4 h-[.6875rem]"
                    path={EDITSVG}
                  />
                </button>
              </div>
            </div>
          </div>
        </td>
      )}
    </>
  );
};

export default TableEditAction;
