import { size } from "lodash";
import { useEffect, useRef, useState } from "react";

const DropdownViewPopup = ({
  options,
  isDisabled,
  value,
  excludeValue,
}: any) => {
  const [toggleTeam, setToggleTeam] = useState<boolean>(false);
  const ref = useRef<any>();

  // CLOSE TEAM DROPDOWN
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setToggleTeam(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`block_wrap w-1/2 px-2.5 `} ref={ref}>
      <div className={`relative dropdown  ${toggleTeam ? "open" : "close"} `}>
        <div
          className={`inner_block_wrap border rounded px-5 py-3 h-full  ${
            isDisabled ? "bg-gray-100/50 border-transparent" : ""
          }
        ${toggleTeam ? "border-indigo-500 open" : "close"}
        `}
          onClick={() => {
            size(options) > 0 &&
              !isDisabled &&
              setToggleTeam((pre: any) => !pre);
          }}
        >
          <h3 className="text-sm18 leading-5 mb-2">{value}</h3>
          <span
            className={`text-sm12  block leading-4 ${
              toggleTeam ? "text-indigo-500" : "text-gray-500"
            }`}
          >
            Teams
          </span>

          {/* EXCLUDE TEAM */}
          {excludeValue && !isDisabled ? (
            <div className="except_count px-2 py-1 bg-rose-500 absolute top-[.8125rem] right-[.9375rem] text-xxs font-semibold rounded text-white leading-3">
              -{excludeValue}
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className={`${
            toggleTeam ? "" : "hidden"
          } list_block absolute top-full left-0 right-0 w-full px-2.5 py-1.5 rounded shadow-lt1 text-sm12 max-h-60 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full  bg-white z-10`}
        >
          <ul>
            {options &&
              size(options) > 0 &&
              options?.map((item: any, index: number) => {
                return (
                  <li className="mb-3.5 last-of-type:mb-0" key={index}>
                    {item?.name}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DropdownViewPopup;
