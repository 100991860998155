import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NotificationsContext } from "../core/NotificationsProvider";

const ActivityCountBoxes = ({
  name,
  countValue,
  activityCount,
  isCheckbox,
  extraProp = false,
  postKey,
  allOptions = [],
  loading,
}: any) => {
  const location = useLocation();
  const [shouldRemoveState, setShouldRemoveState] = useState(false);
  const layout = useContext(NotificationsContext);

  // GOT DATA FROM DASHBOARD
  useEffect(() => {
    if (location && location?.state === "activity") {
      layout.setActivitiesFilterHandle({ new_activities: 1 });
      setShouldRemoveState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // CLEAR THE STATE AFTER FETCHING THE DATA
  useEffect(() => {
    if (
      shouldRemoveState &&
      Object.keys(layout.activitiesFilterHandle).includes("new_activities")
    ) {
      location.state = "";
      window.history.replaceState({}, document.title);
      setShouldRemoveState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout.activitiesFilterHandle, shouldRemoveState]);

  const checked =
    postKey === "new_activities"
      ? layout.activitiesFilterHandle?.new_activities
      : layout?.activitiesFilterHandle?.activities
      ? layout?.activitiesFilterHandle?.activities?.some(
          (data: any) => data?.id === postKey
        )
      : false;

  return (
    <>
      <div className={`w-full sm:w-1/2 lg:w-1/3 mb-5 px-2.5 first:pl-5   `}>
        <label
          htmlFor={postKey}
          className={`px-4 py-2.5 h-full min-w-[9.8125rem]
          
          

            border form-check items-center group rounded block ${
              loading && "shimmer-effect"
            } ${
            checked && "active-style"
          } [&.active-style]:bg-indigo-500/5 [&.active-style]:border-indigo-500 ${
            isCheckbox &&
            "hover:bg-indigo-500/5 hover:border-indigo-500 hover:text-indigo-500"
          } `}
        >
          <div className="flex justify-between">
            <div>
              <h3
                className={`text-2xl md:text-boxtitle md:leading-9 ${
                  checked && "active-style"
                } [&.active-style]:text-indigo-500`}
              >
                {extraProp}
                {countValue ? countValue : "0"}
              </h3>
            </div>
            {activityCount && (
              <h5>
                <span
                  className={`flex items-center bg-gray-100 text-xxs font-semibold px-2 py-0.5 rounded ${
                    checked && "active-style"
                  } [&.active-style]:text-indigo-500 [&.active-style]:bg-indigo-500/5 `}
                >
                  {activityCount ? activityCount : "0"} activities
                </span>
              </h5>
            )}
          </div>
          <div className="flex justify-between items-center">
            <p
              className={`text-xs text-gray-500 py-1 ${
                isCheckbox && "group-hover:text-indigo-500"
              } ${checked && "active-style"} [&.active-style]:text-indigo-500`}
            >
              {name ? name : "0"}
            </p>
            {isCheckbox && (
              <div className="flex">
                {}
                <input
                  className="form-check-input h-3 w-3 rounded-sm bg-gray-100 border-gray-300 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 hover:border-indigo-500 group-hover:border-indigo-500 focus:ring-0 focus:ring-offset-0`}"
                  type="checkbox"
                  name={postKey}
                  id={postKey}
                  // value={postKey}
                  checked={checked}
                  onChange={(e: any) => {
                    layout.setActivitiesFilterHandle((current: any) => {
                      if (e.target.value !== "new_activities") {
                        if (current.activities === undefined) {
                          current.activities = [];
                        }
                        let updatedActivities = [];
                        if (e.target?.checked) {
                          updatedActivities =
                            Array.isArray(current?.activities) &&
                            !current?.activities?.some(
                              (option: any) => option.id === e.target?.name
                            )
                              ? [
                                  ...current?.activities,
                                  {
                                    id: parseInt(e.target?.name),
                                    name:
                                      e.target?.value.charAt(0).toUpperCase() +
                                      e.target?.value.slice(1),
                                  },
                                ]
                              : current?.activities;
                        } else {
                          updatedActivities = current?.activities?.filter(
                            (option: any) => option.id !== postKey
                          );
                        }
                        return {
                          ...current,
                          activities: updatedActivities,
                        };
                      } else {
                        return {
                          ...current,
                          new_activities: e.target.checked ? 1 : 0,
                        };
                      }
                    });
                  }}
                  value={
                    postKey === "new_activities"
                      ? postKey
                      : Array.isArray(allOptions) &&
                        allOptions?.find((option: any) => option.id === postKey)
                          ?.name
                  }
                />
              </div>
            )}
          </div>
        </label>
      </div>
    </>
  );
};

export default ActivityCountBoxes;
