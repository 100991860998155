import { KTSVG } from "../ui/KTSVG";

const TablePopup = ({
  cancelBtn,
  confirmBtn,
  selectedItem,
  message,
  isConfirm,
  btnRef,
}: any) => {
  return (
    <div className="fixed top-0 left-0 flex items-center justify-center bg-black/70 h-screen w-full z-50">
      <div className="flex max-w-[40.25rem]  w-full bg-white rounded px-3 py-2 mx-2.5">
        {/* <div className="flex max-w-[40.25rem]  w-full bg-white rounded  px-3 py-2	"> */}
        {isConfirm ? (
          <div className="flex flex-wrap gap-2.5 justify-between items-center w-full max-sm:justify-center ">
            <div>
              <span className="text-sm13 font-medium mr-2 block">
                {message}
              </span>
            </div>

            {/* <div className="flex items-center ml-auto"> */}
            <div className="flex items-center ">
              <button
                className="inline-flex group items-center text-violet-800 bg-gray-100 transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 hover:text-white"
                type="button"
                onClick={(e: any) => cancelBtn(false)}
              >
                {" "}
                Cancel
              </button>
              <button
                className="ml-2 bg-rose-500  inline-flex group items-center text-white   
              transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 "
                type="button"
                onClick={(e: any) => {
                  confirmBtn("confirm");
                  cancelBtn(false);
                }}
                ref={btnRef}
              >
                <i className="fas fa-solid fa-trash mr-1.5"></i>
                Delete
              </button>
            </div>
          </div>
        ) : (
          <>
            <KTSVG
              svgClassName="fill-rose-500 min-w-[1rem] mt-0.5 mr-2 w-4 h-4"
              path="standard_icons/warning1.svg"
            />
            <span className="text-sm font-medium mr-2 text-rose-500">
              {message}
            </span>
            <button
              className="ml-auto mt-0.5 p-1 h-full"
              type="button"
              onClick={(e: any) => {
                cancelBtn(false);

                let bulkAction: any = document.querySelectorAll(
                  `span[data-te-target="#bulk"]:not([data-te-collapse-collapsed])`
                )?.[0];
                if (bulkAction) {
                  bulkAction?.click();
                }
              }}
            >
              <KTSVG
                svgClassName="fill-violet-800 hover:fill-indigo-500 transition w-2.5 h-2.5"
                path="standard_icons/cross.svg"
              />
            </button>
          </>
        )}
        {/* </div> */}
      </div>
    </div>
  );
};

export default TablePopup;
