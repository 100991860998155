import axios from "axios";

const sellerLevelConfiguration = async (): Promise<any> => {
  const response = await axios.post(`seller-level/lists`);
  return response.data;
};

const editSellerLevel = async (data: any): Promise<any> => {
  const response = await axios.post(`seller-level/edit`, data);
  return response.data;
};

const sellerLevelOptionsWithConfig = async (): Promise<any> => {
  const response = await axios.post(`seller-level/options`);
  return response.data;
};

const addSellerLevel = async (data: any): Promise<any> => {
  const response = await axios.post(`seller-level/add`, data);
  return response.data;
};

const deleteSellerLevel = async (data: any): Promise<any> => {
  const response = await axios.post(`seller-level/delete`, data);
  return response.data;
};

export {
  sellerLevelConfiguration,
  editSellerLevel,
  sellerLevelOptionsWithConfig,
  addSellerLevel,
  deleteSellerLevel,
};
