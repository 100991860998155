export const ShowSubmenu = ({ title, showSubmenu }: any) => {
  const trimmedTitle = title.toLowerCase().replace(/\s+/g, "_");
  return (
    <div
      className={`submenu-wrapper fixed hidden lg:!hidden max-md:pt-[3.75rem] z-[99999] text-sm12 pointer-events-none`}
      data-submenu-id={trimmedTitle}
    >
      <ul
        id={title}
        className="submenu text-violet-800 bg-white  shadow-op3 pointer-events-auto"
      >
        {showSubmenu?.map((item: any, index: number) => {
          return (
            <li
              className="  cursor-pointer px-2.5 py-1 border-b leading-[1.3125rem] hover:text-indigo-500 hover:bg-indigo-500 hover:bg-opacity-[7%]"
              // onClick={() => window.open(item?.path, "_parent")}
              // key={index}
              key={`${index}-${title}`}
            >
              <span>
                <a
                  href={item?.path}
                  target={item?.externalLink ? "_blank" : "_self"}
                  rel="noreferrer"
                >
                  {item?.name}
                </a>
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
