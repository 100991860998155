import _, { debounce, find, includes, isArray } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import PPPIcon from "../../../../assets/media/icons/other_icons/PPPIcon.svg";
import Upload from "../../../../assets/media/icons/other_icons/Upload.svg";
import Check from "../../../../assets/media/icons/other_icons/check.svg";
import handCross from "../../../../assets/media/icons/other_icons/cross-hand-icon.svg";
import EditSquareSVG from "../../../../assets/media/icons/other_icons/edit-square.svg";
import handIcon from "../../../../assets/media/icons/other_icons/hand-icon.svg";
import Refresh from "../../../../assets/media/icons/other_icons/refresh.svg";
import Cross from "../../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import PriceField from "../../../../components/formComponent/PriceField";
import { IKTSVG } from "../../../../components/ui/IKTSVG";

import {
  getBenefitsValue,
  getRestrictionValue,
} from "../../../../helpers/AssetHelpers";
import {
  colorForMeter,
  decimalNumber,
  handleShift,
  priceCalculation,
  tooltipForListingMeter,
} from "../../../../helpers/Functions";
import { deliveryTypeOrders } from "../../../../helpers/const";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import { AddInventoryContext } from "../../core/AddInventoryProvider";
import {
  maxFileCondition,
  TicketError,
  visibilityMeterCalculationForKeyBoard,
  visiblityMeterForBelowListing,
} from "../../core/_functions";
import { Messages } from "../../core/_messages";
import useDebounce from "../../core/useDebounce";
import MobileViewListingBlock from "./MobileViewListingBlock";

const Viewmobile = React.memo(
  ({
    event,
    eIndex,
    rIndex,
    tempEventRecord,
    // isTableEditable,
    target_currency,
    forRowDisabled,
    seletedDeliveryTypes,
    deliveryTypeExist,
    paperTypeExist,
    focusedRow,
    setPPE,
    resetPPEValues,
    handleBlur,
    handleFocus,
    firstSelectedIndex,
    handleEticket,
    unSetPPE,
    getDisplayPriceCurrency,
    handlePPETableChange,
    triggered,
    setTriggered,
    list,
    setList,
    setIsOpenListingQualityPopup,
  }: any) => {
    const layout = useContext(AddInventoryContext);
    const coreLayout = useContext(LayoutContext);
    const [eventRecord, setEventRecord] = useState(tempEventRecord);
    const [updateChecker, setUpdateChecker] = useState<any>();
    const isTableEditable = layout.isTableEditable;
    const conversation_rate = coreLayout?.conversationRate;
    const priceFactor = Number(
      coreLayout?.allowedAccess?.price_suggestion_factor
    );
    // console.log(tempEventRecord);
    // console.log(eventRecord);

    useEffect(() => {
      // console.log(tempEventRecord);
      setEventRecord(tempEventRecord);
    }, [tempEventRecord]);

    const handleInputChangeDebounced = debounce((data) => {
      // Update the state with the new data here
      setList((events: any) => {
        return events.map((event: any, eventIndex: any) => {
          if (eventIndex === eIndex) {
            return {
              ...event,
              eventRecords: Array.isArray(event["eventRecords"])
                ? event["eventRecords"]?.map((eventRecord: any) => {
                    if (eventRecord.selected === true) {
                      layout.setTableDataEditChanging((pre: any) => {
                        const conditions: any = {
                          "01fg98zqgrtfe7avqvv575e970": "Local",
                          "01fnv9ja884j9empp7a4z663wj": "National",
                          "01fnv9gwfd8wmnn27aw196pj37": "International",
                          sub_ticket_type: "Sub ticket type",
                        };
                        const newName = conditions[data.target.name] || null;
                        if (newName && pre.indexOf(newName) === -1) {
                          return [...pre, newName];
                        } else if (
                          !newName &&
                          pre.indexOf(data.target.name) === -1
                        ) {
                          return [...pre, data.target.name];
                        }
                        return pre;
                      });

                      //for the category change
                      let section: any = {};
                      if (data.target.name === "category") {
                        section.section = "";
                      }
                      let final = {
                        ...eventRecord,
                        [data.target.name]:
                          data.target.value !== undefined &&
                          Array.isArray(data.target.value)
                            ? data.target.value
                            : typeof data.target.value === "object"
                            ? data.target.value
                            : typeof data.target.value === "string" &&
                              data.target.type !== "checkbox"
                            ? data.target.value
                            : data.target.type === "checkbox"
                            ? data.target.checked
                            : data.target.value,
                        ...section,
                      };

                      // VISIBILITY LOGIC
                      let visibility: number = 0;
                      let fieldCount: number = 8;

                      if (final?.ticketType && _.size(final?.ticketType) > 0) {
                        visibility += 5 / fieldCount;
                      }

                      // SUB TICKET TYPE
                      // if (
                      //   final?.ticketFiles &&
                      //   _.size(final?.ticketFiles) > 0
                      // ) {
                      //   visibility += 5 / fieldCount;
                      // } else if (
                      //   _.size(final?.ticketFiles) === 0 &&
                      //   final?.ticketType?.id === "eticket"
                      // ) {
                      //   fieldCount = 11;
                      // } else if (
                      //   _.size(final?.ticketFiles) === 0 &&
                      //   final?.ticketType?.id !== "eticket"
                      // ) {
                      //   fieldCount = 10;
                      //   visibility += 5 / fieldCount;
                      // }

                      // QUANTITY
                      if (final?.quantity) {
                        visibility += 5 / fieldCount;
                      }
                      if (final?.splitType && _.size(final?.splitType) > 0) {
                        visibility += 5 / fieldCount;
                      }

                      if (final?.category && _.size(final?.category) > 0) {
                        visibility += 5 / fieldCount;
                      }
                      if (final?.section && _.size(final?.section) > 0) {
                        visibility += 5 / fieldCount;
                      }
                      if (final?.row) {
                        visibility += 5 / fieldCount;
                      }
                      if (final?.proceedValue?.value) {
                        visibility += 5 / fieldCount;
                      }
                      if (final?.ticketsInHands) {
                        visibility += 5 / fieldCount;
                      }

                      visibility = Math.round(visibility);

                      return {
                        ...final,
                        visibility: visibility && visibility,
                        oldVisibility:
                          visibility && final?.visibility === visibility
                            ? final?.oldVisibility
                            : visibility && final?.visibility,
                      };
                    } else {
                      return {
                        ...eventRecord,
                      };
                    }
                  })
                : [],
            };
          } else {
            return event;
          }
        });
      });
    }, 400);

    function calculateAvgPriceForProceedValueNew(
      sectionname: any,
      markplaceArry: any
    ) {
      let sectionValue =
        sectionname?.originalName?.trim()?.toLowerCase().split(" ").join("") ||
        sectionname?.name?.trim()?.toLowerCase().split(" ").join("");
      let markeplaceValueWithAvg: any =
        markplaceArry &&
        Object.entries(markplaceArry)?.map((item: any) => {
          return {
            id: item?.[0],
            name: item?.[0]?.trim()?.toLowerCase().split(" ").join(""),
            avgPrice: item?.[1]?.total?.price_avg,
          };
        });

      let avgPrice = markeplaceValueWithAvg?.find(
        (obj: any) => obj.name === sectionValue
      )?.avgPrice;

      let finalAvgRate = avgPrice ? avgPrice * conversation_rate : undefined;
      return finalAvgRate;
    }

    const MemoizedHandleTableFieldOld = useMemo(() => {
      return (data: any) => {
        let tempEvents = list;
        // If table is not edit-able

        if (isTableEditable.length === 0) {
          tempEvents[eIndex]["eventRecords"][rIndex][data.target.name] =
            data.target.value !== undefined && Array.isArray(data.target.value)
              ? data.target.value
              : typeof data.target.value === "object"
              ? data.target.value
              : typeof data.target.value === "string" &&
                data.target.type !== "checkbox"
              ? data.target.value
              : data.target.type === "checkbox"
              ? data.target.checked
              : data.target.value;

          //for split type
          if (
            data.target.name === "splitType" &&
            data.target.value?.id !== "MULTIPLES"
          ) {
            tempEvents[eIndex]["eventRecords"][rIndex]["MULTIPLES"] = "";
          }

          //for the category change
          if (data.target.name === "category") {
            tempEvents[eIndex]["eventRecords"][rIndex]["section"] = null;
          }

          if (data.target.name === "section") {
            tempEvents[eIndex]["eventRecords"][rIndex]["avg_price"] =
              calculateAvgPriceForProceedValueNew(
                data?.target?.value,
                tempEvents[eIndex]["markeInsightsForAvgPrice"]
              );
          }

          // TICKETFILES EMPTY OBJECT ON QUNTITY CHANGE
          // if (data.target.name === "quantity") {
          //   const quantity =
          //     data?.target?.name === "quantity"
          //       ? parseInt(data.target.value, 10)
          //       : 0;
          //   tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
          //     quantity > 0 ? Array.from({ length: quantity }, () => ({})) : [];
          // }

          if (data.target.name === "quantity") {
            const quantity = parseInt(data.target.value, 10) || 0; // Ensure quantity is a number, default to 0 if NaN
            const existingTicketFiles = tempEvents[eIndex]["eventRecords"][
              rIndex
            ]["ticketFiles"]?.filter(
              (obj: any) => JSON.stringify(obj) !== "{}"
            );

            if (existingTicketFiles) {
              // If ticketFiles already exists, add or remove elements based on quantity
              const currentCount = existingTicketFiles.length;
              const diff = quantity - currentCount;

              if (diff > 0) {
                // Add elements
                tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
                  existingTicketFiles.concat(
                    Array.from({ length: maxFileCondition(diff) }, () => ({}))
                  );
              } else if (diff === 0) {
                tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
                  existingTicketFiles?.filter(
                    (obj: any) => JSON.stringify(obj) !== "{}"
                  );
              }
              // else if (diff < 0) {
              //   console.log("2");
              //   // Remove elements
              //   tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
              //     existingTicketFiles.slice(0, quantity);
              // }
              // If diff is 0, no action needed as the count is already correct
            } else {
              // If ticketFiles doesn't exist, create a new array with the specified length
              tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
                quantity > 0
                  ? Array.from(
                      { length: maxFileCondition(quantity) },
                      () => ({})
                    )
                  : [];
            }
          }

          //for the eticket change
          if (data.target.name === "ticketType") {
            tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] =
              data.target.value?.subtypes?.[0];
            //for delivery type field
            if (data.target.value?.id !== "paper") {
              tempEvents[eIndex]["eventRecords"][rIndex]["deliveryType"] = [];
            }
          }

          if (data.target.name === "ticketsInHands" && data.target?.checked) {
            tempEvents[eIndex]["eventRecords"][rIndex]["dateToShip"] =
              new Date();
          }

          // VISIBILITY LOGIC
          // let visibility: number = 0;
          // let fieldCount: number = 8;

          // NEW VISIBILITY LOGIC
          let visibility: any = visibilityMeterCalculationForKeyBoard(
            tempEvents[eIndex],
            tempEvents[eIndex]["eventRecords"][rIndex]
          );

          const currentVisibility =
            tempEvents[eIndex]["eventRecords"][rIndex]["visibility"];
          const oldVisibility =
            currentVisibility === visibility
              ? tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"]
              : currentVisibility;

          tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"] =
            oldVisibility;
          tempEvents[eIndex]["eventRecords"][rIndex]["visibility"] = visibility;

          // console.log(tempEvents[eIndex]["eventRecords"][rIndex]);
          // setEventRecord(tempEvents[eIndex]["eventRecords"][rIndex]);
          setList(tempEvents);
          // If table is edit-able
        } else {
          // handleInputChangeDebounced(data);
          setList((events: any) => {
            return events.map((event: any, eventIndex: any) => {
              if (eventIndex === eIndex) {
                let eventRecords = Array.isArray(event["eventRecords"])
                  ? event["eventRecords"]?.map((eventRecord: any) => {
                      if (eventRecord.selected === true) {
                        let newArrDelivery = eventRecord?.deliveryType?.reduce(
                          (count: any, item: any) => {
                            count[item.id] = item.type;
                            return count;
                          },
                          {}
                        );

                        layout.setTableDataEditChanging((pre: any[]) => {
                          const eventPre = pre[eIndex] || [];
                          const conditions: any = {
                            ...newArrDelivery,
                            sub_ticket_type: "Sub ticket type",
                          };
                          const newName = conditions[data.target.name] || null;

                          let updatedEventPre = [...eventPre];

                          if (
                            newName &&
                            updatedEventPre.indexOf(newName) === -1 &&
                            data.target.name
                          ) {
                            updatedEventPre = [
                              ...updatedEventPre,
                              newName,
                              data.target.name,
                            ];
                          } else if (
                            !newName &&
                            updatedEventPre.indexOf(data.target.name) === -1 &&
                            data.target.name
                          ) {
                            updatedEventPre = [
                              ...updatedEventPre,
                              data.target.name,
                            ];
                          }

                          const newPre = [...pre];
                          newPre[eIndex] = updatedEventPre;

                          return newPre;
                        });

                        // TICKETFILES EMPTY OBJECT ON EDITABLE BASED ON QUNTITY CHANGE
                        // if (data.target.name === "quantity") {
                        //   const quantity =
                        //     data?.target?.name === "quantity"
                        //       ? parseInt(data.target.value, 10)
                        //       : 0;
                        //   eventRecord["ticketFiles"] =
                        //     quantity > 0
                        //       ? Array.from({ length: quantity }, () => ({}))
                        //       : [];
                        // }

                        if (data.target.name === "quantity") {
                          const quantity = parseInt(data.target.value, 10) || 0; // Ensure quantity is a number, default to 0 if NaN
                          const existingTicketFiles = eventRecord[
                            "ticketFiles"
                          ].filter((obj: any) => JSON.stringify(obj) !== "{}");

                          if (existingTicketFiles) {
                            // If ticketFiles already exists, add or remove elements based on quantity
                            const currentCount = existingTicketFiles.length;
                            const diff = quantity - currentCount;

                            if (diff > 0) {
                              // Add elements
                              eventRecord["ticketFiles"] =
                                existingTicketFiles.concat(
                                  Array.from(
                                    { length: maxFileCondition(diff) },
                                    () => ({})
                                  )
                                );
                            } else if (diff === 0) {
                              eventRecord["ticketFiles"] =
                                existingTicketFiles?.filter(
                                  (obj: any) => JSON.stringify(obj) !== "{}"
                                );
                            }
                            //  else if (diff < 0) {
                            //   // Remove elements
                            //   eventRecord["ticketFiles"] =
                            //     existingTicketFiles.slice(0, quantity);
                            // }
                            // If diff is 0, no action needed as the count is already correct
                          } else {
                            // If ticketFiles doesn't exist, create a new array with the specified length
                            eventRecord["ticketFiles"] =
                              quantity > 0
                                ? Array.from(
                                    { length: maxFileCondition(quantity) },
                                    () => ({})
                                  )
                                : [];
                          }
                        }

                        //for the category change
                        let section: any = {};
                        if (data.target.name === "category") {
                          section.section = "";
                        }

                        //for split type
                        let multipleData = eventRecord?.MULTIPLES;
                        if (
                          data.target.name === "splitType" &&
                          data.target.value?.id !== "MULTIPLES"
                        ) {
                          multipleData = "";
                        }

                        let final = {
                          ...eventRecord,
                          MULTIPLES: multipleData,
                          [data.target.name]:
                            data.target.value !== undefined &&
                            Array.isArray(data.target.value)
                              ? data.target.value
                              : typeof data.target.value === "object"
                              ? data.target.value
                              : typeof data.target.value === "string" &&
                                data.target.type !== "checkbox"
                              ? data.target.value
                              : data.target.type === "checkbox"
                              ? data.target.checked
                              : data.target.value,
                          ...section,
                        };
                        let visibility: any =
                          visibilityMeterCalculationForKeyBoard(event, final);
                        const avg_price = calculateAvgPriceForProceedValueNew(
                          final?.section,
                          event?.markeInsightsForAvgPrice
                        );

                        return {
                          ...final,
                          ...(final?.ticketsInHands
                            ? { dateToShip: new Date() }
                            : { dateToShip: final?.dateToShip }),
                          visibility: visibility && visibility,
                          oldVisibility:
                            visibility && final?.visibility === visibility
                              ? final?.oldVisibility
                              : visibility && final?.visibility,
                          avg_price: avg_price,
                        };
                      } else {
                        return {
                          ...eventRecord,
                        };
                      }
                    })
                  : [];
                // setEventRecord(eventRecords);

                let firstSelectedIndex = -1;

                let final = eventRecords?.map((record: any, index: any) => {
                  let newArrDelivery = eventRecord?.deliveryType?.reduce(
                    (count: any, item: any) => {
                      count[item.id] = item.type;
                      return count;
                    },
                    {}
                  );
                  if (record?.selected) {
                    // hide if any impact on other code
                    if (record.section?.id) {
                      const matchingId = record?.section?.id;
                      const matchingSections =
                        record.category?.sections?.filter(
                          (innerObj: any) => innerObj?.id === matchingId
                        );

                      if (!matchingSections || matchingSections?.length === 0) {
                        record.section = {};
                      }
                    }

                    if (firstSelectedIndex === -1) {
                      // This is the first selected record, store its index
                      firstSelectedIndex = index;
                      return {
                        ...record,
                      };
                    } else {
                      // Check if ticketType is "paper"
                      if (record.ticketType?.id === "paper") {
                        return {
                          ...record,
                        };
                      } else {
                        // Create a copy of the record without the specific key
                        // const {
                        //   "01fg98zqgrtfe7avqvv575e970": _,
                        //   "01fnv9gwfd8wmnn27aw196pj37": __,
                        //   "01fnv9ja884j9empp7a4z663wj": ___,
                        //   ...rest
                        // } = record;
                        const keysToDelete = Object.keys(newArrDelivery);
                        let rest = record;

                        keysToDelete.forEach((key: any) => {
                          if (rest.hasOwnProperty(key)) {
                            delete rest[key];
                          }
                        });
                        return {
                          ...rest,
                          deliveryType: [],
                        };
                      }
                    }
                  } else {
                    return record;
                  }
                });

                return {
                  ...event,
                  eventRecords: final,
                };
              } else {
                return event;
              }
            });
          });
          // setList(tempEvents([...events]));
        }
      };
    }, [eIndex, isTableEditable, layout, list, rIndex, setList]);

    // console.log(list, "list");
    const updateSetList = useCallback(() => {
      setList((prev: any) => {
        return prev?.map((inEvent: any, inEIndex: number) => {
          if (inEIndex == eIndex) {
            return {
              ...inEvent,
              eventRecords: inEvent["eventRecords"]?.map(
                (inEventRecord: any, inRIndex: number) => {
                  if (inRIndex === rIndex) {
                    return {
                      ...eventRecord,
                    };
                  } else {
                    return inEventRecord;
                  }
                }
              ),
            };
          } else {
            return inEvent;
          }
        });
      });
    }, []);

    const MemoizedHandleTableField = useMemo(() => {
      return (data: any, eIndex: number, rIndex: number) => {
        setEventRecord((prev: any) => {
          return {
            ...prev,

            [data?.target?.name]:
              data.target.value !== undefined &&
              Array.isArray(data.target.value)
                ? data.target.value
                : typeof data.target.value === "object"
                ? data.target.value
                : typeof data.target.value === "string" &&
                  data.target.type !== "checkbox"
                ? data.target.value
                : data.target.type === "checkbox"
                ? data.target.checked
                : data.target.value,
          };
        });

        // setUpdateChecker(data);

        if (layout.isTableEditable.length === 0) {
          MemoizedHandleTableFieldOld(data);
          setUpdateChecker({
            eventId: eIndex,
            eventRecordId: rIndex,
          });
        } else {
          setUpdateChecker(data);
        }
      };
    }, [list, layout.isTableEditable]);

    useEffect(() => {
      if (layout.isTableEditable.length === 0) {
        setTimeout(() => {
          updateChecker?.eventId !== undefined &&
            setEventRecord(
              list?.[updateChecker?.eventId]?.eventRecords?.[
                updateChecker?.eventRecordId
              ]
            );
        }, 500);
      }
    }, [updateChecker]);

    // MULTI EDIT
    let eventRecordMemo: any = useMemo(() => {
      return [updateChecker];
    }, [updateChecker]);

    const debounceHandler = useDebounce(eventRecordMemo, 0);

    useEffect(() => {
      if (
        updateChecker &&
        updateChecker?.eventId === undefined &&
        layout.isTableEditable.length > 0
      ) {
        MemoizedHandleTableFieldOld(updateChecker);
      }
    }, [debounceHandler]);

    //send response to eventField component
    function refreshEventsWhileActionPerform() {
      //send response to eventField component
      const tempEvents = (events: any) => {
        return events.map((event: any) => {
          return {
            ...event,
            eventRecords:
              Array.isArray(event["eventRecords"]) &&
              event["eventRecords"].length > 0
                ? event["eventRecords"].map((eventRecord: any) => {
                    return {
                      ...eventRecord,
                    };
                  })
                : [],
          };
        });
      };
      setList(tempEvents([...list]));
    }

    // let eventRecordMemo: any = useMemo(() => {
    //   // return [updateChecker];
    //   console.log(updateChecker);
    //   console.log(eventRecord);
    //   setEventRecord({

    //   })
    // }, [updateChecker]);

    // const debounceHandler = useDebounce(eventRecordMemo, 0);

    // useEffect(() => {
    //   if (updateChecker) {
    //     //solve tab fast issue
    //     // updateSetList();
    //     MemoizedHandleTableFieldOld(updateChecker);
    //   }
    // }, [debounceHandler]);

    if (eventRecord?.newRow && triggered === false) {
      setTriggered(true);
      setTimeout(() => {
        eventRecord.newRow = false;
        setTriggered(false);
      }, 2000);
    }

    let isDisabled;

    if (eventRecord?.selected === false && isTableEditable.length > 0) {
      isDisabled = "row-disabled";
      forRowDisabled = "";
    } else if (
      eventRecord?.selected === false &&
      isTableEditable.length === 0
    ) {
      isDisabled = "";
      forRowDisabled = "";
    } else if (eventRecord?.selected === true && isTableEditable.length === 0) {
      isDisabled = "";
      forRowDisabled = "";
    } else if (eventRecord?.selected === true && isTableEditable.length > 0) {
      isDisabled = "row-indigo-light row-indigo-light -selected";
      forRowDisabled = "pointer-events-none";
    }

    const isAllFilesUploaded =
      eventRecord?.ticketFiles &&
      Object.keys(eventRecord?.ticketFiles).length &&
      Object.keys(
        eventRecord?.ticketFiles?.filter(
          (obj: any) => JSON.stringify(obj) !== "{}"
        )
      ).length >= (eventRecord?.quantity ? eventRecord?.quantity : 0) &&
      Object.keys(
        eventRecord?.ticketFiles?.filter(
          (obj: any) => JSON.stringify(obj) !== "{}"
        )
      ).length > 0
        ? true
        : false;

    const ticketInHandFromListings = (eIndex: any, rIndex: any) => {
      setList((prevList: any) => {
        return prevList?.map((obj: any, index: any) => {
          if (eIndex === index) {
            return {
              ...obj,
              eventRecords: obj?.eventRecords?.map(
                (innerObj: any, innerIndex: any) => {
                  if (rIndex === innerIndex) {
                    let visibility: any = visibilityMeterCalculationForKeyBoard(
                      obj,
                      {
                        ...innerObj,
                        ticketsInHands: !innerObj?.ticketsInHands,
                      }
                    );
                    return {
                      ...innerObj,
                      visibility: visibility,
                      oldVisibility:
                        visibility && innerObj?.visibility === visibility
                          ? innerObj?.oldVisibility
                          : visibility && innerObj?.visibility,
                      ticketsInHands: !innerObj?.ticketsInHands,
                    };
                  }
                  return innerObj;
                }
              ),
            };
          }
          return obj;
        });
      });
    };

    const handleOnSelection = (
      data: any,
      eIndex: any,
      rIndex: any,
      option: any,
      filedName: any
    ) => {
      if (data?.target?.id === "select-all") {
        let target = {
          name: filedName,
          value: option,
        };
        MemoizedHandleTableField({ target }, eIndex, rIndex);
      } else if (data?.target?.id === "deselect-all") {
        let target = {
          name: filedName,
          value: "",
        };
        MemoizedHandleTableField({ target }, eIndex, rIndex);
      }
    };

    const [priceFiledFocused, setPriceFieldFocused] = useState<any>();
    const [avgPrice, setAvgPrice] = useState<any>();
    const focusOnPriceField = (data: any, eIndex: any, rIndex: any) => {
      setPriceFieldFocused({ data: data, eIndex: eIndex, rIndex: rIndex });
    };

    function categoryExist(event: any, record: any, type: any) {
      const hasProceedValue =
        _.size(record?.proceedValue) > 0 &&
        record?.proceedValue?.value !== "0.00" &&
        record?.proceedValue?.value !== "";
      const hasNoCategory = _.size(record?.category) === 0;
      const hasNoSection = _.size(record?.section) === 0;
      if (type === "withoutcategory") {
        return (hasProceedValue && (hasNoCategory || hasNoSection)) || false;
      }
      if (type === "withcategory") {
        return (
          (hasProceedValue &&
            _.size(record?.category) > 0 &&
            _.size(record?.section) > 0) ||
          false
        );
      }
    }

    const rowClasses = [
      "view_row",
      "first_row",
      "flex",
      "columns-1",
      "h-[2.5625rem]",
      "border-b",
      "whitespace-nowrap",
      "column-view",
      "items-center",
    ];

    // CURRENCY
    const SYMBOLS: any = {
      GBP: "£",
      EUR: "€",
      USD: "$",
      PER: "%",
    };

    const faceValue =
      typeof eventRecord?.faceValue === "object"
        ? eventRecord?.faceValue?.value
        : decimalNumber(eventRecord?.faceValue);

    const proccedValue =
      typeof eventRecord?.proceedValue === "object"
        ? eventRecord?.proceedValue?.value
        : decimalNumber(eventRecord?.proceedValue);

    const benefitsValue =
      _.size(eventRecord) > 0 && getBenefitsValue(eventRecord, layout);
    const restrictionValue =
      _.size(eventRecord) > 0 && getRestrictionValue(eventRecord, layout);
    let splitCondition: string = "";
    if (
      (typeof eventRecord?.splitType === "string" &&
        eventRecord?.splitType !== "MULTIPLES") ||
      (typeof eventRecord?.splitType === "object" &&
        eventRecord?.splitType?.id !== "MULTIPLES")
    ) {
      splitCondition = "text-gray-400";
    } else {
      splitCondition = "123";
    }

    let splitTypeValue: any =
      typeof eventRecord?.split_type === "object"
        ? eventRecord?.split_type
        : Array.isArray(layout.splitType) &&
          layout.splitType.find(
            (split: any) => split.id === eventRecord?.split_type
          );

    const isPaper =
      eventRecord?.ticket_type === "paper" ||
      eventRecord?.ticket_type?.id === "paper";

    // HANDLE MOBILE POPUP
    const editViewPopOpen = (eIndex: any, rIndex: any, event: any) => {
      const element = document.getElementById("add-inventory-mobile-filter");
      if (element) {
        element.classList.add("activeFIlter");
        document.body.classList.add("overflow-hidden-custom");
        layout.setMobileEditView({
          show: true,
          eventIndex: eIndex,
          recordIndex: rIndex,
          event: event,
        });
      }
    };

    if (eventRecord?.status !== "published") {
      return (
        <React.Fragment key={`${event?.id}-${rIndex}`}>
          {/* {JSON.stringify(new Date())} */}
          <div
            className={`view_row addInventoryView mobileRow
            ${eventRecord?.status === "failed" && "row-error-new"}
            ${
              eventRecord?.status === "partPublished" && "row-processing-new"
            }  ${eventRecord?.bulkLoader && "pointer-events-none"}`}
          >
            <div
              className={`${rowClasses.join(" ")}`}
              key={`eventFieldTr${rIndex}`}
              id={`${event?.id}-${rIndex}`}
            >
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className=" flex 
              items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] text-center sticky left-0 no-scroll p-0 checkbox_td z-[2] td_block"
                id={`checkbox_shad`}
              >
                <label
                  className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] flex items-center absolute top-0  justify-center select-none p-1 border-r ${forRowDisabled} ${
                    layout.isPublish && "pointer-events-none !border-gray-300"
                  } ${layout.isDeleteActive && "pointer-events-none"}
                ${
                  ((eventRecord?.errors &&
                    Object.keys(eventRecord?.errors ? eventRecord?.errors : [])
                      .length > 0) ||
                    eventRecord?.status === "failed") &&
                  "error-row1"
                  // bg-rose-500/5 hover:bg-rose-500/5
                }
                ${eventRecord?.selected ? "bg-indigo-500 bg-opacity-[7%]" : ""}
                ${
                  eventRecord?.newRow &&
                  "row-indigo-light ease-out duration-500"
                }
                `}
                  htmlFor={`checkbox-all-${event?.id}-${rIndex}`}
                ></label>
                <input
                  name="selected"
                  id={`checkbox-all-${event?.id}-${rIndex}`}
                  key={`checkbox-all-${event?.id}-${rIndex}`}
                  type="checkbox"
                  // defaultChecked={eventRecord?.selected}
                  checked={eventRecord?.selected}
                  className={`${forRowDisabled} ${
                    layout.isDeleteActive && "pointer-events-none "
                  } w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative`}
                  onChange={(event: any) => {
                    MemoizedHandleTableField(event, eIndex, rIndex);
                  }}
                  onClick={(e) => {
                    handleShift(e, rIndex, event?.eventRecords);
                    setTimeout(() => {
                      refreshEventsWhileActionPerform();
                    }, 70);
                  }}
                />
              </div>

              {/* TICKET TYPE */}
              <MobileViewListingBlock
                id={`ticket_type-${eIndex}-${rIndex}`}
                className="third-col"
                value={eventRecord?.ticketType?.name}
                placeholder="Ticket type"
                isError={eventRecord?.errors?.ticket_type}
              />

              {paperTypeExist && (
                <div
                  className={`delivery-type disable four-col ${
                    !isPaper ? "text-gray-400" : "ena"
                  }`}
                >
                  {eventRecord?.deliveryType?.length > 0
                    ? ` ${eventRecord?.deliveryType?.length} selected `
                    : " Delivery type"}
                </div>
              )}

              {paperTypeExist &&
                isArray(seletedDeliveryTypes) &&
                seletedDeliveryTypes.length > 0 &&
                _.orderBy(seletedDeliveryTypes, deliveryTypeOrders, [
                  "asc",
                ]).map((selected: any) => {
                  let delivery = find(eventRecord?.deliveryType, {
                    type: selected,
                  });
                  return delivery ? (
                    <div className="p-1.5 min-w-[7.625rem] max-w-[7.625rem] five-col">
                      {eventRecord?.[delivery?.id] != undefined
                        ? eventRecord?.[delivery?.id]
                        : delivery?.price
                        ? Number(delivery?.price)
                        : selected}
                    </div>
                  ) : (
                    <div className="p-1.5 min-w-[7.625rem] max-w-[7.625rem] disable five-col">
                      {selected}
                    </div>
                  );
                })}

              {/* QUANTITY */}
              <MobileViewListingBlock
                id={`quantity_available-${eIndex}-${rIndex}`}
                className={`eight-col ${
                  layout.tableDataEditChanging?.includes(
                    "quantity_available"
                  ) && "edited"
                }`}
                value={eventRecord?.quantity}
                placeholder="Quantity"
                isError={eventRecord?.errors?.quantity_available}
              />

              {/* SPLIT TYP */}
              <MobileViewListingBlock
                className={`ellips-text ten-col inline`}
                value={eventRecord?.splitType?.name}
                placeholder="Split type"
              />

              {/* SELL IN MULTIPLE */}
              <MobileViewListingBlock
                className={`eleven-col ${
                  !eventRecord?.MULTIPLES && "text-gray-400"
                }`}
                value={eventRecord?.MULTIPLES}
                placeholder="Sell in multiple"
              />

              {/* MAX DISPLAY */}
              <MobileViewListingBlock
                className={`twelve-col`}
                value={eventRecord?.maxDisplayQuantity}
                placeholder="Max display"
              />

              {/* CATEGORY */}
              <MobileViewListingBlock
                id={`category-${eIndex}-${rIndex}`}
                className={`thirteen-col`}
                value={eventRecord?.category?.name}
                placeholder="Category"
                isError={eventRecord?.errors?.category}
              />

              {/* SECTION/BLOCK */}
              <MobileViewListingBlock
                className={`fourteen-col`}
                value={eventRecord?.section?.name}
                placeholder="Section/block"
              />

              {/* ROW */}
              <MobileViewListingBlock
                className={`fifteen-col`}
                value={eventRecord?.row}
                placeholder="Row"
              />

              {/* FIRST SEAT */}
              <MobileViewListingBlock
                className={`sixteen-col`}
                value={eventRecord?.first_seat}
                placeholder="First seat"
              />

              {/* FACE VALUE */}
              <MobileViewListingBlock
                id={`face_value-${eIndex}-${rIndex}`}
                className={`seventeen-col ${!faceValue && "nosymbol"}`}
                value={faceValue}
                placeholder="Face value"
                data-sybmol-attribute={
                  eventRecord?.faceValue?.symbol
                    ? SYMBOLS[eventRecord?.faceValue.symbol]
                    : SYMBOLS[eventRecord?.proceedValue?.symbol]
                }
                isError={eventRecord?.errors?.face_value}
              />

              {/* PROCEED PRICE */}
              <MobileViewListingBlock
                id={`sell_price-${eIndex}-${rIndex}`}
                className={`eighteen-col ${!proccedValue && "nosymbol"}`}
                value={
                  proccedValue ? decimalNumber(proccedValue) : proccedValue
                }
                placeholder="Proceed Price"
                data-sybmol-attribute={
                  eventRecord?.proceedValue?.symbol
                    ? SYMBOLS[eventRecord?.proceedValue?.symbol]
                    : SYMBOLS[eventRecord?.proceedValue?.symbol]
                }
                isError={eventRecord?.errors?.sell_price}
              />

              {/* BENEFITS */}
              <MobileViewListingBlock
                className={`twentyone-col ${
                  benefitsValue?.length ? "en" : "text-gray-500"
                }`}
                value={
                  benefitsValue?.length > 0
                    ? `${benefitsValue?.length} selected`
                    : ""
                }
                placeholder="Benefits"
              />

              {/* RESTRICTIONS */}
              <MobileViewListingBlock
                className={`twentytwo-col ${
                  restrictionValue?.length ? "en" : "text-gray-500"
                }`}
                value={
                  restrictionValue?.length > 0
                    ? `${restrictionValue?.length} selected`
                    : ""
                }
                placeholder="Restrictions"
              />

              {/* DATE TO SHIP */}
              <MobileViewListingBlock
                id={`date_to_ship-${eIndex}-${rIndex}`}
                className={`twenty-col ${
                  eventRecord?.ticketsInHands && "text-gray-400"
                }`}
                value={
                  eventRecord?.dateToShip
                    ? moment(eventRecord?.dateToShip).format("DD/MM/YYYY")
                    : ""
                }
                placeholder="Date to ship"
                isError={eventRecord?.errors?.date_to_ship}
              />

              <div className="p-0 z-[2] md:sticky right-0 min-w-[10.01rem] max-w-[10.01rem] bg-white accordion-right-sticky ml-auto">
                <React.Fragment key={`${event?.id}-${rIndex}`}>
                  <div
                    className={`
                    ${event.value === "" && "row-disabled"} 
                    ${eventRecord?.bulkLoader ? "row-disabled" : ""} 
                    ${isDisabled} 
                    bg-white border-l left-shad st_icons 
                    ${
                      eventRecord?.selected
                        ? "!bg-indigo-500 !bg-opacity-[7%]"
                        : ""
                    }
                    ${
                      ((eventRecord?.errors &&
                        Object.keys(
                          eventRecord?.errors ? eventRecord?.errors : []
                        ).length > 0) ||
                        eventRecord?.status === "failed") &&
                      " error-row1 row-error-new"
                      // !bg-rose-500/5 hover:bg-rose-500/5
                    }
                    ${
                      rIndex === firstSelectedIndex && eventRecord?.selected
                        ? " first "
                        : ""
                    }
                    ${
                      eventRecord?.newRow &&
                      "row-indigo-light ease-out duration-500"
                    }`}
                    id={`left-shad-list`}
                  >
                    <div
                      className={`flex 
                    ${
                      eventRecord?.status === "failed" && layout.isPublish && ""
                      // bg-rose-500/5
                    }
                    ${
                      eventRecord?.status === "partPublished" &&
                      layout.isPublish &&
                      ""
                      // bg-orange-500/10
                    } 
                    `}
                    >
                      <button
                        className={`group flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem] `}
                        type="button"
                        id={`price-per-hand-tooltip${eIndex}${eventRecord?.card}`}
                      >
                        <IKTSVG
                          path={PPPIcon}
                          className={
                            eventRecord?.ppe
                              ? "fill-green-500"
                              : "fill-violet-400"
                          }
                          svgClassName={`w-[.9688rem] h-[.9688rem] group-hover:fill-indigo-500 `}
                        />
                      </button>
                      {/* TICKET IN HAND */}
                      <button
                        className={`group flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem] border-l  ${
                          isAllFilesUploaded && "pointer-events-none"
                        }`}
                        type="button"
                        id={`tickets-in-hand-tooltip${eIndex}${rIndex}`}
                        onClick={() => {
                          // ticketInHandFromListings(eIndex, rIndex);
                          let target = {
                            name: "ticketsInHands",
                            checked: !eventRecord?.ticketsInHands,
                            type: "checkbox",
                          };
                          MemoizedHandleTableField({ target }, eIndex, rIndex);
                        }}
                      >
                        <Tooltip
                          anchorId={`tickets-in-hand-tooltip${eIndex}${rIndex}`}
                          content={
                            eventRecord?.ticketsInHands
                              ? "Remove tickets in hand"
                              : "Tickets in hand"
                          }
                          place={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                          variant="light"
                          className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 ${
                            rIndex === 0 || rIndex === 1 ? "mt-0 ml-2" : "mt-2"
                          }`}
                        />
                        {eventRecord?.ticketsInHands ? (
                          <>
                            <IKTSVG
                              path={handIcon}
                              svgClassName={`w-3 group-hover:fill-indigo-500 group-hover:hidden  ${
                                isAllFilesUploaded
                                  ? "pointer-events-none fill-gray-400 "
                                  : "fill-green-600 "
                              } `}
                            />
                            <IKTSVG
                              path={handCross}
                              svgClassName="fill-violet-500 w-3 group-hover:fill-indigo-500 hover_icon group-hover:block hidden"
                            />
                          </>
                        ) : (
                          <>
                            <IKTSVG
                              path={handCross}
                              svgClassName="fill-violet-500 w-3  group-hover:fill-indigo-500 group-hover:hidden"
                            />
                            <IKTSVG
                              path={handIcon}
                              svgClassName="fill-green-600 w-3 group-hover:fill-indigo-500 hover_icon group-hover:block hidden"
                            />
                          </>
                        )}
                      </button>

                      {/* TICKET UPLOAD */}
                      <button
                        className={`group flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem] border-l ${
                          // ((eventRecord?.ticketType?.id !== "eticket" &&
                          //   eventRecord?.ticketType?.id !== "mobile-link") ||
                          (_.size(layout?.isTableEditable) > 0 ||
                            layout.isDeleteActive) &&
                          `  pointer-events-none cursor-not-allowed`
                        }`}
                        type="button"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#ppeRightCanvas"
                        // aria-controls="ppeRightCanvas"
                        onClick={(e: any) => {
                          if (
                            eventRecord?.ticketType?.id !== "eticket" &&
                            eventRecord?.ticketType?.id !== "pdf" &&
                            eventRecord?.ticketType?.id !== "mobile-qr" &&
                            eventRecord?.ticketType?.id !== "mobile-link"
                          ) {
                            e.preventDefault();
                          } else {
                            handleEticket(event, eventRecord);
                          }

                          //layout.setGetSelectedCount(true);
                        }}
                        id={`eTicket-upload-tooltip${eIndex}${rIndex}`}
                      >
                        <Tooltip
                          anchorId={`eTicket-upload-tooltip${eIndex}${rIndex}`}
                          content={
                            eventRecord?.ticketType?.id !== "eticket" &&
                            eventRecord?.ticketType?.id !== "pdf" &&
                            eventRecord?.ticketType?.id !== "mobile-qr" &&
                            eventRecord?.ticketType?.id !== "mobile-link"
                              ? "Not available"
                              : isAllFilesUploaded
                              ? "Tickets uploaded"
                              : "Upload Ticket"
                          }
                          place={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                          variant="light"
                          className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 ${
                            rIndex === 0 || rIndex === 1 ? "mt-0 ml-2" : "mt-2"
                          }`}
                        />
                        <IKTSVG
                          className={`flex items-center justify-center group-hover:fill-indigo-500 transition ${
                            eventRecord?.ticketType?.id !== "eticket" &&
                            eventRecord?.ticketType?.id !== "pdf" &&
                            eventRecord?.ticketType?.id !== "mobile-qr" &&
                            eventRecord?.ticketType?.id !== "mobile-link"
                              ? // && eventRecord?.ticketType?.id !== "mobile-link"
                                "!fill-gray-400 "
                              : "fill-violet-500"
                          } ${
                            eventRecord?.errors?.["quantity_available"] ===
                              TicketError && "!fill-rose-500"
                          }
                        
                        ${
                          isAllFilesUploaded &&
                          (eventRecord?.ticketType?.id === "eticket" ||
                            eventRecord?.ticketType?.id === "pdf" ||
                            eventRecord?.ticketType?.id === "mobile-qr" ||
                            eventRecord?.ticketType?.id === "mobile-link") &&
                          "!fill-green-600"
                        } 
                        `}
                          path={Upload}
                          svgClassName="w-[.8125rem] h-[.6875rem]"
                        />
                      </button>

                      <button
                        className={`flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem] border-l`}
                        type="button"
                        id="visibility_addInventory"
                        onClick={() => {
                          setIsOpenListingQualityPopup(true);
                        }}
                      >
                        {/* <Tooltip
                          anchorId={`eTicket-upload-tooltip-${event?.id}-${rIndex}`}
                          html={
                            // eventRecord?.visibility === 1
                            //   ? `Listing Quality Low`
                            //   : eventRecord?.visibility === 2
                            //   ? `Listing Quality Low`
                            //   : eventRecord?.visibility === 3
                            //   ? `Listing Quality Level 1`
                            //   : eventRecord?.visibility === 4
                            //   ? `Listing Quality Level 2`
                            //   : eventRecord?.visibility === 5
                            //   ? `Listing Quality Level 3`
                            //   : eventRecord?.visibility === 6
                            //   ? `Listing Quality Level 4`
                            //   : ""
                            tooltipForListingMeter(
                              eventRecord?.visibility,
                              eventRecord?.avg_price
                                ? priceCalculation(
                                    eventRecord?.proceedValue?.value,
                                    eventRecord?.avg_price
                                      ? Number(eventRecord?.avg_price)
                                      : null,
                                    priceFactor
                                  )
                                : false,
                              event
                            )
                          }
                          positionStrategy="fixed"
                          place={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                        /> */}

                        <TooltipPortal
                          id={`eTicket-upload-tooltip-${event?.id}-${rIndex}`}
                          content={
                            // eventRecord?.visibility === 1
                            //   ? `Listing Quality Low`
                            //   : eventRecord?.visibility === 2
                            //   ? `Listing Quality Low`
                            //   : eventRecord?.visibility === 3
                            //   ? `Listing Quality Level 1`
                            //   : eventRecord?.visibility === 4
                            //   ? `Listing Quality Level 2`
                            //   : eventRecord?.visibility === 5
                            //   ? `Listing Quality Level 3`
                            //   : eventRecord?.visibility === 6
                            //   ? `Listing Quality Level 4`
                            //   : ""
                            tooltipForListingMeter(
                              eventRecord?.visibility,
                              eventRecord?.avg_price
                                ? priceCalculation(
                                    eventRecord?.proceedValue?.value,
                                    eventRecord?.avg_price
                                      ? Number(eventRecord?.avg_price)
                                      : null,
                                    priceFactor
                                  )
                                : false,
                              event
                            )
                          }
                          positionStrategy="fixed"
                          place={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                          variant="light"
                          className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
                        />

                        <div
                          id={`eTicket-upload-tooltip-${event?.id}-${rIndex}`}
                          data-tooltip-id={`eTicket-upload-tooltip-${event?.id}-${rIndex}`}
                          // className={`table-meter flex gap-[.0875rem] svg-wrap visibility-case${
                          //   eventRecord?.visibility > 2 &&
                          //   eventRecord?.oldVisibility
                          // }-${visiblityMeterForBelowListing(
                          //   eventRecord?.visibility
                          // )}`}

                          className={`table-meter flex gap-[.0875rem] svg-wrap   ${
                            // categoryExist(event, eventRecord, "withcategory")
                            //   ? colorForMeter(
                            colorForMeter(
                              eventRecord?.avg_price
                                ? priceCalculation(
                                    eventRecord?.proceedValue?.value,
                                    eventRecord?.avg_price
                                      ? Number(eventRecord?.avg_price)
                                      : null,
                                    priceFactor
                                  )
                                : undefined,
                              eventRecord
                            )
                            //   )
                            // : ""
                          } 
                          
                          visibility-case${
                            eventRecord?.oldVisibility > 2
                              ? eventRecord?.oldVisibility - 2
                              : eventRecord?.oldVisibility
                          }-${visiblityMeterForBelowListing(
                            eventRecord?.oldVisibility,
                            eventRecord?.visibility
                          )}`}
                        >
                          <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-l-[1px]"></span>
                          <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                          <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                          {/* <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span> */}
                          <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-r-[1px]"></span>
                        </div>
                      </button>
                    </div>
                    <div
                      id={`collapsePriceExchange${event?.id}-${rIndex}`}
                      className="accordion-collapse collapse w-full h-full border-t rounded-none"
                      aria-labelledby="bodyfour"
                    >
                      <div className="accordion-body  relative inline-flex items-center w-full justify-evenly py-7 pt-[1.9375rem] pb-[2rem] rounded-none #{!important} ">
                        <button
                          type="button"
                          className="border group mx-1 rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center"
                          onClick={() => resetPPEValues(rIndex)}
                        >
                          <IKTSVG
                            path={Refresh}
                            className="fill-violet-500 group-hover:fill-white transition"
                            svgClassName="ml-[.44rem]"
                          />
                        </button>
                        <button
                          type="button"
                          className="group flex mx-1 items-center justify-center h-7 w-7 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded"
                          onClick={() => unSetPPE(rIndex)}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapsePriceExchange${event?.id}-${rIndex}`}
                          aria-expanded="true"
                          aria-controls={`collapsePriceExchange${event?.id}-${rIndex}`}
                        >
                          <IKTSVG
                            className="fill-violet-500 group-hover:fill-white transition"
                            path={Cross}
                          />
                        </button>
                        <Tooltip
                          anchorId={`confirm-tooltip${event?.id}-${rIndex}`}
                          content="Confirm"
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium text-xs z-10"
                        />
                        <button
                          id={`confirm-tooltip${event?.id}-${rIndex}`}
                          type="button"
                          className="border group mx-1 rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center"
                          onClick={() => {
                            setPPE(rIndex);
                          }}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapsePriceExchange${event?.id}-${rIndex}`}
                          aria-expanded="true"
                          aria-controls={`collapsePriceExchange${event?.id}-${rIndex}`}
                        >
                          <IKTSVG
                            path={Check}
                            className="fill-violet-500 group-hover:fill-white transition"
                            svgClassName="ml-[.44rem]"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
              <div
                className={`sticky md:hidden right-0 p-0  bg-white ml-auto min-w-[1.875rem] cursor-pointer h-full z-10`}
                onClick={(e) => {
                  editViewPopOpen(eIndex, rIndex, event);
                }}
              >
                <IKTSVG
                  className={`fill-violet-500 border-l flex items-center justify-center transition bg-inherit h-full`}
                  svgClassName="w-3 h-3"
                  path={EditSquareSVG}
                />
              </div>
            </div>
            <div
              id={`collapsePriceExchange${event?.id}-${rIndex}`}
              className="accordion-collapse collapse border-b"
              aria-labelledby="bodyfour"
            >
              <div className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[5.625rem] border-r p-1 text-center"></div>
              {Object.keys(
                eventRecord?.marketplaces ? eventRecord?.marketplaces : []
              ).length > 0 ? (
                eventRecord?.marketplaces.map(
                  (marketplace: any, marketPlaceIndex: number) => {
                    return (
                      <div className="border-r" key={marketPlaceIndex}>
                        <div className="w-full ">
                          <div>
                            <div>
                              <div className="px-1.5 py-1 font-medium whitespace-nowrap">
                                {marketplace.title}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="text-gray-300">
                              <div className="px-1.5 text-gray-400 font-medium whitespace-nowrap">
                                Proceed price
                              </div>
                              <div className="px-1.5 text-gray-400 font-medium whitespace-nowrap">
                                Display price
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>
                              <div className="p-1.5 font-medium w-0">
                                <PriceField
                                  name={marketplace.exchange_id}
                                  currenncySymbol={target_currency}
                                  value={
                                    eventRecord["ppeExchanges"]?.[
                                      marketplace.exchange_id
                                    ]
                                      ? eventRecord["ppeExchanges"][
                                          marketplace.exchange_id
                                        ]["proceed_price"]
                                      : ""
                                  }
                                  handleOnChange={(data: any) =>
                                    handlePPETableChange(data, eIndex, rIndex)
                                  }
                                />
                              </div>

                              <div className="p-1.5 font-medium w-0 pointer-events-none">
                                <PriceField
                                  disabled
                                  name={marketplace.exchange_id}
                                  value={
                                    eventRecord["ppeExchanges"]?.[
                                      marketplace.exchange_id
                                    ]
                                      ? eventRecord["ppeExchanges"][
                                          marketplace.exchange_id
                                        ]["display_price"]
                                      : ""
                                  }
                                  defaultSymbol={getDisplayPriceCurrency(
                                    marketplace.exchange_id,
                                    eIndex
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div>
                  <div className="flex items-center py-3 px-4 text-rose-500">
                    <p>{Messages.ppeSelectExchange}</p>
                  </div>{" "}
                </div>
              )}
            </div>
            {includes(["failed", "partPublished"], eventRecord?.status) && (
              <div className={`second_row flex border-b bg-gray-100`}>
                <div className="min-w-[2.5rem] max-w-[2.5rem] min-h-[1.9375rem]"></div>
                <div className="p-0 equal-td-height flex items-start sticky left-[2.5rem] z-[1] focus-within:z-[3]">
                  <div
                    className={`error-box border-b border-l border-r ml-1.5 -mt-[1px] rounded-b mb-2 
                    ${eventRecord?.status === "failed" && "row-error-new"}
                    ${
                      eventRecord?.status === "partPublished" &&
                      "row-processing-new"
                    }
                  `}
                  >
                    {Object.keys(
                      eventRecord?.marketplaces ? eventRecord?.marketplaces : []
                    ).length > 0 &&
                      eventRecord?.marketplaces.map(
                        (exchange: any, exchangeIndex: number) => {
                          return (
                            exchange?.error && (
                              <div
                                key={exchangeIndex}
                                className="px-2.5 pb-[.3125rem] pt-0 flex"
                              >
                                <span
                                  className={`
                                text-xs inline-block font-semibold
                                ${
                                  eventRecord?.status === "failed" &&
                                  "text-rose-500"
                                }
                                ${
                                  eventRecord?.status === "partPublished" &&
                                  "text-orange-500"
                                }  
                                `}
                                >
                                  {exchange.title} Error:
                                </span>{" "}
                                <p className="text-gray-600 inline font-normal">
                                  {exchange?.error}
                                </p>
                              </div>
                            )
                          );
                        }
                      )}

                    {eventRecord?.errors?.commonErrors &&
                      isArray(eventRecord?.errors?.commonErrors) &&
                      eventRecord?.errors?.commonErrors.length > 0 && (
                        <p className="text-gray-600 inline font-normal px-2.5 pb-[.3125rem] pt-0 flex">
                          {
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  eventRecord?.errors?.commonErrors.join(
                                    "<br/>"
                                  ),
                              }}
                            />
                          }
                        </p>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  }
);

export default Viewmobile;
