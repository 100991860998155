import { createContext, PropsWithChildren, useContext, useState } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import {
  INITIAL_INSTRUCTION_DETAILS,
  INSTRUCTION_LETTER__COLUMNS_ITEMS,
  INSTRUCTION_LETTER_FILTER_ITEMS,
} from "../../../helpers/const";

export interface LayoutContextModel {
  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  draggedColumns: Array<any>;
  setDraggedColumns: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  filterData: any;
  setFilterData: (data: any) => void;

  meta: any;
  setMeta: (data: any) => void;

  filterOptions: any;
  setFilterOptions: (data: any) => void;

  listings: any;
  setListings: (data: any) => void;

  currentListings: any;
  setCurrentListings: (data: any) => void;

  addEditDrawer: any;
  setAddEditDrawer: (data: any) => void;
}

const InstructionLetterContext = createContext<LayoutContextModel>({
  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  draggedColumns: [],
  setDraggedColumns: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  filterData: [],
  setFilterData: (data: any) => {},

  meta: [],
  setMeta: (data: any) => {},

  filterOptions: [],
  setFilterOptions: (data: any) => {},

  listings: [],
  setListings: (data: any) => {},

  currentListings: [],
  setCurrentListings: (data: any) => {},

  addEditDrawer: [],
  setAddEditDrawer: (data: any) => {},
});

const InstructionLetterProvider = ({ children }: PropsWithChildren) => {
  const globalLayout = useContext(LayoutContext);

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveInstructionLetterFilter;

  let items = getHeaderFilter(
    "saveInstructionLetterFilter",
    isFilterExistInDB,
    INSTRUCTION_LETTER_FILTER_ITEMS
  );

  // COLUMN FILTER - CONDITION
  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings
      ?.InstructionLetterTableColumn;

  let columnsItems = getHeaderFilter(
    "InstructionLetterTableColumn",
    isColumnFilterExistInDB,
    INSTRUCTION_LETTER__COLUMNS_ITEMS
  );

  const [filterItems, setFilterItems] = useState(items);
  const [draggedItems, setDraggedItems] = useState(filterItems);
  const [draggedColumns, setDraggedColumns] = useState([]);
  const [columnItems, setColumnItems] = useState(columnsItems);
  const [filterData, setFilterData] = useState<any>(
    INITIAL_INSTRUCTION_DETAILS
  );
  const [meta, setMeta] = useState<any>();
  const [filterOptions, setFilterOptions] = useState<any>([]);
  const [listings, setListings] = useState<any>([]);
  const [currentListings, setCurrentListings] = useState<any>([]);
  const [addEditDrawer, setAddEditDrawer] = useState<any>({
    isOpen: false,
    isEditable: false,
    data: {},
  });

  const value: LayoutContextModel = {
    filterItems,
    setFilterItems,

    draggedItems,
    setDraggedItems,

    draggedColumns,
    setDraggedColumns,

    columnItems,
    setColumnItems,

    filterData,
    setFilterData,

    meta,
    setMeta,

    filterOptions,
    setFilterOptions,

    listings,
    setListings,

    currentListings,
    setCurrentListings,

    addEditDrawer,
    setAddEditDrawer,
  };

  return (
    <InstructionLetterContext.Provider value={value}>
      {children}
    </InstructionLetterContext.Provider>
  );
};

export { InstructionLetterContext, InstructionLetterProvider };
