const NoMoreItemInTable = () => {
  return (
    <tfoot
      className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
      id={`NoMoreListing`}
    >
      <tr className="border-b bg-white">
        <td colSpan={11} align="center" className="h-[2.8125rem]">
          No more items to show
        </td>
      </tr>
    </tfoot>
  );
};

export default NoMoreItemInTable;
