import { Link } from "react-router-dom";
import { KTSVG } from "../../../components/ui/KTSVG";

const FAQSidebarList = ({
  title,
  path,
  slug,
  currentMenu,
  setClassAdd = () => {},
  classAdd,
  isExternalLink,
}: any) => {
  return (
    <div
      className={`group sb_item p-2 cursor-pointer hover:bg-indigo-500 hover:bg-opacity-[7%] rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500 ${
        slug === currentMenu
          ? `text-indigo-500 bg-indigo-500 bg-opacity-[7%]`
          : ``
      }  `}
    >
      <div onClick={() => setClassAdd(!classAdd)}>
        <Link
          className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500 "
          to={isExternalLink ? isExternalLink : `/faq/${slug}`}
          state={{ status: true }}
          target={isExternalLink ? "_blank" : "_self"}
        >
          <div
            className={`item_icon w-6 h-6 group-hover:bg-indigo-500  flex items-center justify-center rounded-sm mr-2.5 ${
              slug === currentMenu ? `bg-indigo-500` : "bg-violet-500"
            }`}
          >
            <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
              <KTSVG
                svgClassName="fill-white h-3.5 w-4 center"
                path={`menu_icons/${path}.svg`}
              />
            </span>
          </div>
          {title}
        </Link>
      </div>
    </div>
  );
};

export default FAQSidebarList;
