import { Fragment, useContext, useEffect, useMemo, useState } from "react";
// import SearchSVG from "../../../assets/media/icons/standard_icons/search-violet.svg";
import Checkbox from "components/formComponent/Checkbox";
import { ClearButton } from "components/ui/buttons/ClearButton";
import { addCommaInNumber } from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import { size } from "lodash";
import { Tooltip } from "react-tooltip";
import close from "../../../assets/media/icons/other_icons/Clear.svg";
import filter from "../../../assets/media/icons/other_icons/filter.svg";
import refreshSVG from "../../../assets/media/icons/other_icons/refresh.svg";
import crossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { ConnectorContext } from "../core/ConnectorProvider";
import { unMapEvents } from "../core/requests";
import APIConnectorSearchField from "./APIConnectorSearchField";

const MAP_KEYS = {
  map: "mappedEvents",
  unMap: "unMappedEvents",
};

const MAP_UNMAP_BUTTONS = [
  { key: MAP_KEYS.map, title: "Unmap" },
  { key: MAP_KEYS.unMap, title: "Map" },
];

const handleEventsUnmap =
  (key: any, refetch: any, layout: any, setCustomEventsLoading: any) =>
  async () => {
    if (key === MAP_KEYS.unMap) {
      layout?.setEventsMappingState(true);
      return;
    }
    let mappedEvents = layout?.selectedEvents.filter(
      (item: any) => item?.is_mapped
    );

    if (size(mappedEvents) === 0) return;

    let events = mappedEvents.map((item: any) => ({
      tixstock_event_id: item?.event_id,
      external_event_id: item?.id,
    }));

    setCustomEventsLoading(true);
    unMapEvents({
      id: layout?.connectorViewState?.data?.id,
      events,
    })
      ?.then((res) => {
        if (res?.data?.status) {
          // refetch();
          if (layout?.eventsFilters.page === 1) {
            setCustomEventsLoading(true);
            refetch();
          } else {
            layout.setEventsFilters((curr: any) => {
              return {
                ...curr,
                page: 1,
              };
            });
          }

          showAlert("Events mapped successfully", false);
        }
        layout?.setEventsMappingState(false);
        layout?.setSelectedEvents([]);
        layout?.setMappedEvents([]);
      })
      .catch((err) => {
        showAlert("Something went wrong!", true);
      })
      .finally(() => {
        // setCustomEventsLoading(false);
      });
  };

const EventsFilters = (props: any) => {
  const { customEventsLoading, paginateData, refetch, setCustomEventsLoading } =
    props;

  const layout = useContext(ConnectorContext);
  const [total, setTotal] = useState(paginateData?.total);
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState("");
  const [venue, setVenue] = useState("");
  const [performer, setPerformer] = useState("");

  const selectedEvents: any = useMemo(
    () => ({
      [MAP_KEYS.map]:
        layout?.selectedEvents.filter((item) => item?.is_mapped).length ?? 0,
      [MAP_KEYS.unMap]:
        layout?.selectedEvents.filter((item) => !item?.is_mapped).length ?? 0,
    }),
    [layout?.selectedEvents]
  );

  useEffect(() => {
    if (paginateData && paginateData.current_page == 1) {
      setTotal(paginateData?.total);
    }
  }, [paginateData]);

  const handleRefresh = () => {
    layout.setEventsFilters((currentVal: any) => {
      return {
        ...currentVal,
        status: undefined,
        query: undefined,
        category: undefined,
        venue: undefined,
        performer: undefined,
        isMapped: false,
        page: 1,
      };
    });
    setQuery("");
    setCategory("");
    setVenue("");
    setPerformer("");
  };

  const ToggleElemClose = (e: any) => {
    e.preventDefault();
    let submenuWrapper: any = document.querySelector("#filtersMob");
    let eventAccordion: any = document.querySelector("#EventAccordion");
    if (eventAccordion) eventAccordion.classList.remove("hidden");

    if (submenuWrapper) submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  };

  const ToggleElemOpen = (e: any) => {
    e.preventDefault();
    let submenuWrapper: any = document.querySelector("#filtersMob");
    let eventAccordion: any = document.querySelector("#EventAccordion");
    if (submenuWrapper) submenuWrapper.classList.add("activeFIlter");

    if (eventAccordion) eventAccordion.classList.add("hidden");
    document.body.classList.add("overflow-hidden");
  };

  const clearDisable =
    layout?.eventsFilters?.query ||
    layout?.eventsFilters?.category ||
    layout?.eventsFilters?.venue ||
    layout?.eventsFilters?.performer ||
    layout.eventsFilters?.isMapped;

  return (
    <div id="api-connector-events-filters">
      <div
        className="filtersMob max-md:flex max-md:flex-col z-100"
        id="filtersMob"
      >
        <div
          className="flex flex-wrap md:border-b border-t gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
          id={`filters_block`}
        >
          <div
            className="closePopup hidden max-md:flex"
            id="closePopup"
            onClick={ToggleElemClose}
          >
            <IKTSVG
              path={close}
              className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
              svgClassName="w-4 h-4"
            />
          </div>
          <div className={`!w-full flex flex-wrap gap-y-2.5`}>
            {/* SEARCH BAR */}

            <APIConnectorSearchField
              loading={customEventsLoading}
              setFilters={layout.setEventsFilters}
              placeholder={"Search event"}
              name={"query"}
              filters={layout?.eventsFilters}
              value={query}
              setValue={setQuery}
            />

            <div
              className={`w-full sm:w-1/2 lg2:w-[20%] lg:w-1/4 xl:w-[20%] px-[.313rem]   `}
            >
              <div className={`${customEventsLoading && "shimmer-effect"}`}>
                {/* is event mapped */}

                <Checkbox
                  id="mapped_id"
                  label="Is Mapped"
                  name="isMapped"
                  labelclass="text-gray-500"
                  checked={layout.eventsFilters?.isMapped ?? false}
                  tooltip={false}
                  handleOnChange={(data: any) => {
                    layout.setEventsFilters((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.checked,
                        page: 1,
                      };
                    });
                  }}
                />
              </div>
            </div>
            {/* SEARCH BAR */}

            {/* CATEGORY */}

            <APIConnectorSearchField
              loading={customEventsLoading}
              setFilters={layout.setEventsFilters}
              placeholder={"Search category"}
              name={"category"}
              filters={layout?.eventsFilters}
              value={category}
              setValue={setCategory}
            />

            {/* VENUE */}

            <APIConnectorSearchField
              loading={customEventsLoading}
              setFilters={layout.setEventsFilters}
              placeholder={"Search venue"}
              name={"venue"}
              filters={layout?.eventsFilters}
              value={venue}
              setValue={setVenue}
            />

            {/* Performer */}

            <APIConnectorSearchField
              loading={customEventsLoading}
              setFilters={layout.setEventsFilters}
              placeholder={"Search performer"}
              name={"performer"}
              filters={layout?.eventsFilters}
              value={performer}
              setValue={setPerformer}
            />
          </div>
        </div>
        <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
          <button
            className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
              clearDisable
                ? "bg-white text-violet-800 border-gray-300"
                : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
            }`}
            type="button"
            onClick={handleRefresh}
          >
            Clear
          </button>

          <button
            className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
            onClick={ToggleElemClose}
          >
            Apply
          </button>
        </div>
      </div>

      <div
        className="flex flex-wrap customStyle relative bg-white border-t max-sm:justify-between"
        id="report_filter"
      >
        {total === 0 && layout?.clientsEvents?.length > 0 ? null : (
          <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
            {customEventsLoading ? (
              <div className="shimmer-effect inline-block ">
                <span className="opacity-0">
                  {paginateData?.total > 0
                    ? `${paginateData?.total} results`
                    : "0 results"}
                </span>
              </div>
            ) : (
              <>{total > 0 ? `${total} results` : "0 result"}</>
            )}

            {/* {paginateData?.total > 0
            ? `${paginateData?.total} results`
            : "0 result"} */}
          </div>
        )}

        <div
          className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start
        "
        >
          {customEventsLoading ? (
            <div className="flex gap-5">
              <div
                className={`flex !rounded overflow-hidden ${
                  customEventsLoading && "shimmer-effect"
                }`}
                style={{ height: "1.625rem", width: "9.375rem" }}
              >
                &nbsp;
              </div>
            </div>
          ) : (
            <>
              {(layout?.eventsFilters?.query ||
                layout?.eventsFilters?.category ||
                layout?.eventsFilters?.venue ||
                layout?.eventsFilters?.performer ||
                layout.eventsFilters?.isMapped) && (
                <button
                  type="button"
                  className={`group border text-center bg-white rounded min-w-[1.5rem] w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition   ${
                    customEventsLoading && "shimmer-effect"
                  } 
              `}
                  onClick={handleRefresh}
                  id="reset"
                >
                  <Tooltip
                    anchorId={`reset`}
                    content={`Reset`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                  <IKTSVG
                    path={refreshSVG}
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="ml-[.313rem] w-3 h-3"
                  />
                </button>
              )}

              {layout?.selectedEvents?.length > 0 && (
                <Fragment>
                  {MAP_UNMAP_BUTTONS.map(({ key, title }) => (
                    <button
                      key={key}
                      type="button"
                      className={`${
                        selectedEvents[key] === 0
                          ? "pointer-events-none bg-gray-100 text-gray-400"
                          : "text-white hover:text-white bg-violet-500 hover:bg-indigo-500"
                      } group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
                      onClick={handleEventsUnmap(
                        key,
                        refetch,
                        layout,
                        setCustomEventsLoading
                      )}
                      disabled={selectedEvents[key] === 0}
                    >
                      {addCommaInNumber(selectedEvents[key] ?? 0)} {title}{" "}
                      events
                    </button>
                  ))}

                  <p className="text-xs ">
                    {addCommaInNumber(layout?.selectedEvents?.length ?? 0)}{" "}
                    events selected
                  </p>
                </Fragment>
              )}

              {/* QUERY */}
              {layout?.eventsFilters?.query && (
                <div className="flex py-1 text-xs px-2.5 border rounded-full my-0.5">
                  {layout?.eventsFilters?.query}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setEventsFilters((currentVal: any) => {
                        return {
                          ...currentVal,
                          query: undefined,
                          page: 1,
                        };
                      });
                      setQuery("");
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}

              {/* EVENT DATE RANGE */}
              {layout.eventsFilters?.isMapped && (
                <ClearButton
                  name="Is Mapped"
                  isLoading={customEventsLoading}
                  onClick={() => {
                    layout.setEventsFilters((current: any) => ({
                      ...current,
                      isMapped: false,
                    }));
                  }}
                />
              )}

              {/* Category */}
              {layout?.eventsFilters?.category && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {layout?.eventsFilters?.category}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setEventsFilters((currentVal: any) => {
                        return {
                          ...currentVal,
                          category: undefined,
                          page: 1,
                        };
                      });
                      setCategory("");
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}
              {/* Venue */}
              {layout?.eventsFilters?.venue && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {layout?.eventsFilters?.venue}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setEventsFilters((currentVal: any) => {
                        return {
                          ...currentVal,
                          venue: undefined,
                          page: 1,
                        };
                      });
                      setVenue("");
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}

              {/* Performer */}
              {layout?.eventsFilters?.performer && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {layout?.eventsFilters?.performer}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setEventsFilters((currentVal: any) => {
                        return {
                          ...currentVal,
                          performer: undefined,
                          page: 1,
                        };
                      });
                      setPerformer("");
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {/* <Pagination paginateData={paginateData} /> */}
        <div
          className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
          id="filterBtn"
          onClick={ToggleElemOpen}
        >
          <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
            <IKTSVG
              path={filter}
              className="fill-white mr-1.5"
              svgClassName="w-4 h-4"
            />
            Filter results
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventsFilters;
