const VirtualTableRow = ({ ...props }: any) => {
  return (
    <tr
      {...props}
      id="tx-pay-row"
      className={`border-b bg-white  
  `}
    />
  );
};

export default VirtualTableRow;
