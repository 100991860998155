import { useContext } from "react";
import { ConnectorContext } from "../../core/ConnectorProvider";
import BodyContent from "./BodyContent";
import Headers from "./Headers";

const AuthenticationBodyWrapper = () => {
  const layout = useContext(ConnectorContext);
  const state: any = layout?.state;

  return (
    <>
      {state?.activeTab === "Headers" && <Headers />}
      {state?.activeTab === "Body" && <BodyContent />}
    </>
  );
};

export default AuthenticationBodyWrapper;
