import { useContext } from "react";
import { Document, Page } from "react-pdf";
import { Tooltip } from "react-tooltip";
import ClearSVG from "../../../../assets/media/icons/other_icons/Clear.svg";
import Process from "../../../../assets/media/icons/other_icons/process.svg";
import LeftAngleSVG from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import RightAngleSVG from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import Button from "../../../../components/ui/buttons/Button";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { PDFFailedLoad } from "../../../../helpers/const";

const PDFViewer = () => {
  // CONTEXT AND STATES
  const globalLayout = useContext(LayoutContext);
  let { file, page, numPages } = globalLayout?.filePreviewState;

  // PDF LOAD
  function onDocumentLoadSuccess({ numPages }: any) {
    globalLayout.setFilePreviewState((current: any) => {
      return {
        ...current,
        numPages: numPages,
      };
    });
  }

  // UPDATE PAGE
  const upadtePDFPage = (direction: string) => {
    globalLayout?.setFilePreviewState((current: any) => {
      return {
        ...current,
        page:
          direction === "left"
            ? current?.page !== 1 && current?.page - 1
            : current?.page !== numPages && current?.page + 1,
      };
    });
  };

  return (
    <div
      className={`lg2:border-r w-full xxl:h-full lg2:w-1/2 p-4 md:p-5 flex items-center !justify-start flex-col xxl:scrollbar-thin xxl:scrollbar-thumb-violet-800 xxl:scrollbar-thumb-rounded-full`}
    >
      <div className="border rounded w-full align-middle">
        <div className="flex w-full border-b">
          <div className="flex items-center justify-between w-full px-4 py-2">
            <div className="text-xxs md:text-xs">{file?.name}</div>
            <div className="flex">
              {/* PDF - PAGE (-) */}
              <Button
                className={`rounded-l bg-gray-100 hover:bg-gray-200 transition p-2 ${
                  page === 1 && "pointer-events-none"
                }`}
                onClick={() => upadtePDFPage("left")}
              >
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 transition"
                  path={LeftAngleSVG}
                />
              </Button>

              {/* PDF - PAGE (+) */}
              <Button
                className={`rounded-r bg-gray-100 hover:bg-gray-200 transition p-2 ${
                  numPages === page && "pointer-events-none"
                }`}
                onClick={() => upadtePDFPage("right")}
              >
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 transition"
                  path={RightAngleSVG}
                />
              </Button>
            </div>
          </div>

          {/* CLOSE BUTTON */}
          <Button
            className="flex items-center justify-center border-l w-11 group"
            id={"addinventory-pdf-close-btn"}
            onClick={(data: any) =>
              globalLayout?.setFilePreviewState((current: any) => {
                return {
                  ...current,
                  status: false,
                };
              })
            }
          >
            <IKTSVG
              svgClassName="h-5 w-5"
              className="flex items-center justify-center fill-violet-800 group-hover:fill-indigo-500 transition"
              path={ClearSVG}
            />

            <Tooltip
              anchorId={`addinventory-pdf-close-btn`}
              content="Close"
              place="top"
              variant="light"
              className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
            />
          </Button>
        </div>

        {/* PDF VIEW */}
        <div className="uploaded-file-preview overflow-hidden">
          <Document
            file={file.preview}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <IKTSVG
                className="flex items-center justify-center mr-2 mt-5 fill-violet-500 transition animate-spin"
                path={Process}
              />
            }
            error={
              file?.path?.split(".").pop() === "pdf" ? (
                PDFFailedLoad
              ) : (
                <img src={file?.preview} alt="pdf-view" />
              )
            }
          >
            <Page pageNumber={page} pageIndex={page} />
          </Document>
        </div>
        <div className="ml-auto p-2 w-8 text-xs text-gray-400">{page}</div>
      </div>
    </div>
  );
};

export default PDFViewer;
