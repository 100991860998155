/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { ConnectorContext } from "../core/ConnectorProvider";

interface NavTitleInterface {
  title: string;
  imgPath?: string;
  incIconSize?: string;
  src?: string;
  selectedOption?: any;
  imgText?: boolean;
}

// Side Navigation
const NavTitle: React.FC<NavTitleInterface> = ({
  title,
  imgPath,
  incIconSize = false,
  src,
  selectedOption,
  imgText = false,
  offPopup = false,
}: any) => {
  const layout = useContext(ConnectorContext);

  return (
    <>
      <div
        className={`${
          title === selectedOption ? "bg-indigo-500/5" : "bg-white"
        } group sb_item p-2  cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500`}
        onClick={() => {
          layout.setSideOption(title);
        }}
        data-te-collapse-init
        data-te-collapse-collapsed
        data-te-target="#collapseOne5"
        aria-expanded="false"
        aria-controls="collapseOne5"
      >
        <a
          href="#"
          className={`${
            title === selectedOption && "text-indigo-500"
          } flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500`}
        >
          <div
            className={`${
              title === selectedOption ? "bg-indigo-500" : "bg-violet-500"
            } item_icon w-6 h-6 group-hover:bg-indigo-500 flex items-center justify-center rounded-sm mr-2.5`}
          >
            <IKTSVG
              className={` ${
                incIconSize
                  ? "min-w-[1.125rem] max-w-[1.125rem]"
                  : "min-w-[.8125rem] max-w-[.8125rem]"
              } flex items-center justify-center ${
                imgText ? "fill-white" : "fill-white"
              } transition`}
              path={imgPath}
            />
          </div>
          {title}
        </a>
      </div>
    </>
  );
};

export default NavTitle;
