import _, { find, isArray, size } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import close from "../../../../assets/media/icons/other_icons/Clear.svg";
import handIcon from "../../../../assets/media/icons/other_icons/hand-icon.svg";
import PPPIcon from "../../../../assets/media/icons/other_icons/PPPIcon.svg";
import Warning1 from "../../../../assets/media/icons/standard_icons/warning1.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import Checkbox from "../../../../components/formComponent/Checkbox";
import DropDownField from "../../../../components/formComponent/DropDownField";
import FormDatePickerField from "../../../../components/formComponent/FormDatePickerField";
import MultiDropDownField from "../../../../components/formComponent/MultiDropDownField";
import { default as PriceField } from "../../../../components/formComponent/PriceField";
import Textfield from "../../../../components/formComponent/Textfield";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { deliveryTypeOrders } from "../../../../helpers/const";
import {
  decimalNumber,
  handleKeyDown,
  removeCommaFromNumber,
  removeSelectAll,
  resetPPEFields,
  uniqueByNameWithPriority,
  updatePPEPartners,
} from "../../../../helpers/Functions";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import {
  TicketError,
  // updatePPEPartners,
  visibilityMeterCalculationForKeyBoard,
} from "../../core/_functions";

import Messages from "helpers/Messages";
import PPEComponent from "../../../../components/formComponent/PPEComponent";
import { AddInventoryContext } from "../../core/AddInventoryProvider";

const EditAddInventoryMobileView = ({ list, setList }: any) => {
  const coreLayout = useContext(LayoutContext);
  const layout = useContext(AddInventoryContext);
  const mobileEditView = layout?.mobileEditView;
  let target_currency = coreLayout.currencyLabel;
  const [editViewData, setEditViewData] = useState<any>();
  const paperTypeExist: boolean = editViewData?.ticketType?.id === "paper";

  // GET DELIVERY TYPE
  const seletedDeliveryTypes = useMemo(() => {
    if (!editViewData?.deliveryType?.length) {
      return [];
    }
    const deliveryTypes = editViewData.deliveryType.map(
      (dT: any) => _.find(layout.deliveryType, { id: dT.id })?.type
    );
    return _.uniq(_.compact(deliveryTypes));
  }, [editViewData?.deliveryType, layout.deliveryType]);

  // LOCAL VALIDATION
  const instantLocalValidation = useCallback(
    (event: any, eventRecord: any, isPublish: any) => {
      let fieldErrors: any = {};

      const CHECKQTYFILEUPLOAD =
        // isPublish
        //   ? eventRecord?.quantity &&
        //     Number(eventRecord?.quantity) !==
        //       eventRecord?.ticketFiles?.filter(
        //         (obj: any) => JSON.stringify(obj) !== "{}"
        //       )?.length
        isPublish
          ? null
          : eventRecord?.quantity &&
            eventRecord?.ticketFiles?.filter(
              (obj: any) => JSON.stringify(obj) !== "{}"
            )?.length > 0 &&
            Number(eventRecord?.quantity) !==
              eventRecord?.ticketFiles?.filter(
                (obj: any) => JSON.stringify(obj) !== "{}"
              )?.length;

      !eventRecord?.ticketType?.id &&
        (fieldErrors = {
          ...fieldErrors,
          ticket_type: "Ticket type field is required",
        });

      eventRecord?.ticketType?.id === "paper" &&
        eventRecord?.deliveryType?.length > 0 &&
        eventRecord?.deliveryType?.map((singleDelivery: any) => {
          (!eventRecord?.[singleDelivery?.id] ||
            Number(eventRecord?.[singleDelivery?.id]) <= 0) &&
            (fieldErrors = {
              ...fieldErrors,
              [singleDelivery?.id]:
                singleDelivery?.type + " field is required more than zero",
            });
        });

      (!eventRecord?.quantity || CHECKQTYFILEUPLOAD) &&
        (fieldErrors = {
          ...fieldErrors,
          quantity_available: CHECKQTYFILEUPLOAD
            ? TicketError
            : "Quantity field is required",
        });
      !eventRecord?.category?.id &&
        event?.categories &&
        Object.keys(event?.categories).length > 0 &&
        (fieldErrors = {
          ...fieldErrors,
          category: "Category value field is required",
        });

      !eventRecord?.faceValue?.value &&
        (fieldErrors = {
          ...fieldErrors,
          face_value: "Face value field is required",
        });

      //new more than zero
      eventRecord?.faceValue?.value &&
        Number(eventRecord?.faceValue?.value) <= 0 &&
        (fieldErrors = {
          ...fieldErrors,
          face_value: "Face value field is required more than zero",
        });

      !eventRecord?.proceedValue?.value &&
        !eventRecord?.ppe &&
        (fieldErrors = {
          ...fieldErrors,
          sell_price: "Proceed value field is required",
        });

      //new more than zero
      eventRecord?.proceedValue?.value &&
        Number(eventRecord?.proceedValue?.value) <= 0 &&
        (fieldErrors = {
          ...fieldErrors,
          sell_price: "Proceed value field is required more than zero",
        });

      // !eventRecord?.section?.id &&
      //   eventRecord?.category?.sections &&
      //   Object.keys(eventRecord?.category?.sections).length > 0 &&
      //   (fieldErrors = {
      //     ...fieldErrors,
      //     section: "Section value field is required",
      //   });
      !eventRecord?.splitType?.id &&
        (fieldErrors = {
          ...fieldErrors,
          splitType: "Split Type value field is required",
        });
      !eventRecord?.MULTIPLES &&
        eventRecord?.splitType.id === "MULTIPLES" &&
        (fieldErrors = {
          ...fieldErrors,
          MULTIPLES: "Sell in Multiple Field is required",
        });

      !eventRecord?.sub_ticket_type?.id &&
        eventRecord?.ticketType?.id === "eticket" &&
        (fieldErrors = {
          ...fieldErrors,
          sub_ticket_type: "Sub Ticket type Field is required",
        });
      eventRecord?.dateToShip &&
        moment(event?.date).valueOf() <
          moment(
            moment(eventRecord?.dateToShip).format("DD-MMM-YYYY")
          ).valueOf() &&
        (fieldErrors = {
          ...fieldErrors,
          date_to_ship:
            "Date of ship should not be greater than the event date",
        });

      if (eventRecord?.ppe) {
        if (Array.isArray(eventRecord?.ppePartners)) {
          eventRecord?.ppePartners?.forEach((partner: any) => {
            if (
              partner?.value?.value === "0.00" ||
              partner?.value?.value === "0" ||
              partner?.value?.value === 0
            ) {
              fieldErrors["PPP"] = `PPP fields are required more than zero`;
              fieldErrors[
                partner?.name
              ] = `${partner?.name} value field required more than zero`;
            }
          });
        }
      }

      return fieldErrors;
    },
    []
  );

  // UPDATE LOCAL STATE
  useEffect(() => {
    let existRecord = list
      ?.find(
        (item: any, eIndex: number) => eIndex === mobileEditView?.eventIndex
      )
      ?.eventRecords?.find(
        (record: any, rIndex: number) => rIndex === mobileEditView?.recordIndex
      );

    setEditViewData(existRecord);
  }, [list, mobileEditView]);

  // CLOSE POPUP
  const closePopupView = () => {
    const element = document.getElementById("add-inventory-mobile-filter");
    document.body.classList.remove("overflow-hidden-custom");
    if (element) {
      element.classList.remove("activeFIlter");
    }
    layout.setMobileEditView({
      show: false,
      eventIndex: undefined,
      recordIndex: undefined,
    });
  };

  // HANDLE UPDATE
  const handleOnChange = (data: any) => {
    const { target, parentName, id } = data;
    const { name, value, type, checked } = target;

    const newValue = (() => {
      if (parentName === "ppePartners") {
        if (data.target.name === "proceedValue") {
          return data.target.value;
        }
        return updatePPEPartners(
          editViewData["ppePartners"],
          id,
          value,
          "ppePartners",
          editViewData.proceedValue
        );
      }
      if (name === "ticketsInHands" || name === "ppe") {
        return checked;
      }
      return value;
    })();

    // UPDATE STATE
    setEditViewData((current: any) => {
      const updatedData = {
        ...current,
        MULTIPLES:
          name === "splitType" && value?.id !== "MULTIPLES"
            ? ""
            : current?.MULTIPLES,
        section: name === "category" ? undefined : current?.section,
        dateToShip: current?.ticketsInHands ? new Date() : current?.dateToShip,
        [parentName === "ppePartners" ? "ppePartners" : name]: newValue,
        ...(name === "proceedValue" && {
          ppePartners: updatePPEPartners(
            editViewData.ppePartners,
            null,
            null,
            "ppePartners",
            value
          ),
        }),

        ...(name === "sell_price" &&
          (editViewData.ppe === 1 || editViewData.ppe === true) && {
            ppePartners: updatePPEPartners(
              editViewData["ppePartners"],
              null,
              null,
              "ppePartners",
              value
            ),
            ticket_exchange_prices: updatePPEPartners(
              editViewData["ppePartners"],
              null,
              null,
              "ticket_exchange_prices",
              value
            ),
          }),
        ...(name === "sell_price" &&
          (editViewData.ppe === 0 || editViewData.ppe === false) && {
            ppePartners: resetPPEFields(
              editViewData["ppePartners"],
              value,
              "ppePartners"
            ),

            ticket_exchange_prices: resetPPEFields(
              editViewData["ppePartners"],
              value,
              "ticket_exchange_prices"
            ),
          }),
      };

      return {
        ...updatedData,
      };
    });
  };

  // HANDLE UPDATE RECORD
  const handleUpdateRecord = () => {
    let isError: any;
    let editViewUpdated: any;
    setEditViewData((current: any) => {
      let final = {
        ...current,
      };
      let visibility: any = visibilityMeterCalculationForKeyBoard(
        mobileEditView?.event,
        final
      );

      let fieldErrors = instantLocalValidation(
        mobileEditView?.event,
        final,
        false
      );
      isError = fieldErrors;

      editViewUpdated = {
        ...final,
        errors: fieldErrors,
        visibility: visibility && visibility,
        oldVisibility:
          visibility && final?.visibility === visibility
            ? final?.oldVisibility
            : visibility && final?.visibility,
      };

      // UPDATE THE MAIN STATE
      if (!isError || size(isError) === 0) {
        setList((current: any) => {
          return current?.map((event: any, eIndex: number) => {
            if (eIndex === mobileEditView?.eventIndex) {
              return {
                ...event,
                eventRecords: event?.eventRecords?.map(
                  (record: any, rIndex: number) => {
                    if (rIndex === mobileEditView?.recordIndex) {
                      let final = {
                        ...editViewUpdated,
                      };
                      let visibility: any =
                        visibilityMeterCalculationForKeyBoard(event, final);

                      return {
                        ...final,
                        visibility: visibility && visibility,
                        oldVisibility:
                          visibility && final?.visibility === visibility
                            ? final?.oldVisibility
                            : visibility && final?.visibility,
                      };
                    } else return record;
                  }
                ),
              };
            } else return event;
          });
        });

        // CLEAR ALL STATES
        setTimeout(() => {
          closePopupView();
        }, 500);
      } else if (isError) {
        const [firstKey] = Object.keys(isError);
        var inputField: any = document.getElementById(
          firstKey +
            "-" +
            mobileEditView?.eventIndex +
            "-" +
            mobileEditView?.recordIndex
        );
        if (inputField) {
          inputField?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
      return editViewUpdated;
    });
  };

  // HANDLE SELECT / DE-SELECT
  const handleOnSelection = (
    data: any,
    option: any,
    filedName: any,
    visibleOptions: any,
    currentOptionsSelected: any
  ) => {
    let updatedOptions =
      visibleOptions && size(visibleOptions) > 0
        ? visibleOptions
        : data?.target?.id === "select-all"
        ? option
        : null;
    if (data?.target?.id === "select-all") {
      let target = {
        name: filedName,
        value: updatedOptions,
      };
      handleOnChange({ target });
    } else if (data?.target?.id === "deselect-all") {
      let target = {
        name: filedName,
        value: removeSelectAll(currentOptionsSelected, updatedOptions),
      };
      handleOnChange({ target });
    }
  };

  return (
    <div
      id="collapseOne"
      className={`accordion-collapse collapse ${
        coreLayout?.isMobile && "show"
      } `}
      data-te-collapse-item
      data-te-collapse-show
      aria-labelledby="headingOne1"
    >
      <div
        className="filtersMob md:hidden max-md:flex max-md:flex-col"
        id={"add-inventory-mobile-filter"}
      >
        {/* HEADER */}
        <div
          className="closePopup hidden max-md:flex"
          id="closePopup"
          onClick={closePopupView}
        >
          <IKTSVG
            path={close}
            className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
            svgClassName="w-4 h-4"
          />
        </div>

        {/* BODY */}
        <div
          className={`accordion-body -mx-[.3125rem] pt-4 pb-1 px-5 border-y max-md:pt-14 max-md:pb-16  max-md:flex-1 max-md:overflow-auto max-md:content-start  h-full`}
        >
          <div className="flex flex-wrap">
            {/* TICKET TYPE */}
            <div
              className={`table-mobileview-drop font-medium w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5  `}
              id={`ticket_type-${mobileEditView?.eventIndex}-${mobileEditView?.recordIndex}`}
            >
              <DropDownField
                isCapital={true}
                isValueCapital={true}
                value={editViewData?.ticketType}
                isClearable={false}
                options={layout.ticketType}
                placeholder={"Ticket type"}
                name="ticketType"
                handleOnChange={handleOnChange}
                className={editViewData?.errors?.ticket_type && "error-field"}
                errorEventIndex={editViewData?.errors?.ticket_type}
                errorMessage={
                  editViewData?.errors?.ticket_type
                    ? editViewData?.errors?.ticket_type
                    : ""
                }
                dropDownWidth={"min-w-[unset] max-w-[unset] w-full"}
              />
            </div>

            {/* DELIVERY */}
            {paperTypeExist && (
              <div
                className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
              >
                <MultiDropDownField
                  name="deliveryType"
                  isCapital={true}
                  options={layout.deliveryType}
                  value={editViewData?.deliveryType}
                  classNamePrefix={"form_multiDropDown"}
                  placeholder={"Delivery type"}
                  handleOnChange={(data: any) => handleOnChange(data)}
                  getOptionLabel="type"
                />
              </div>
            )}

            {paperTypeExist &&
              isArray(seletedDeliveryTypes) &&
              seletedDeliveryTypes.length > 0 &&
              _.orderBy(seletedDeliveryTypes, deliveryTypeOrders, ["asc"]).map(
                (selected: any) => {
                  let typeTemp = _.find(layout.deliveryType, {
                    type: selected,
                  });
                  let delivery = find(editViewData?.deliveryType, {
                    id: typeTemp?.id,
                  });
                  return delivery ? (
                    <div
                      className={`w-full relative sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5`}
                    >
                      <div className="relative">
                        <Textfield
                          name={delivery?.id}
                          type="text"
                          label={selected}
                          isFocus={(data: any) => {}}
                          passFocus={(data: any) => {}}
                          handleOnChange={(e: any) => handleOnChange(e)}
                          value={
                            editViewData?.[delivery?.id] !== undefined
                              ? editViewData?.[delivery?.id]
                              : Number(delivery?.price)
                              ? Number(delivery?.price)
                              : ""
                          }
                          textFielClassName={`truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5 ${
                            editViewData?.errors?.[delivery?.id] &&
                            "border-rose-500 focus:!border-rose-500  placeholder:text-rose-500"
                          }`}
                        />
                        {editViewData?.[delivery?.id]
                          ? ""
                          : editViewData?.errors?.[delivery?.id] && (
                              <>
                                <TooltipPortal
                                  id={"delivery-type"}
                                  content={editViewData?.errors?.[delivery?.id]}
                                  place="top"
                                  variant="light"
                                  className="text-center z-[99999] !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-20 max-w-[9rem] !text-rose-500"
                                />
                                <div
                                  data-tooltip-id={`delivery-type`}
                                  className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                                >
                                  <IKTSVG
                                    className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                    path={Warning1}
                                  />
                                </div>
                              </>
                            )}
                      </div>
                    </div>
                  ) : (
                    <div className="p-1.5 font-medium">
                      <div className="relative">
                        <input
                          type="number"
                          className={`bg-gray-100 text-gray-400 placeholder:text-gray-400 placeholder:truncate pointer-events-none truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-none rounded focus:border-indigo-500 focus:bg-violet-300/50 px-2.5 123 `}
                          placeholder={selected}
                          disabled={true}
                          value={``}
                        />
                      </div>
                    </div>
                  );
                }
              )}

            {/* QUANTITY */}
            <div
              className={` w-full relative sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5`}
            >
              <div className="relative">
                <Textfield
                  type="number"
                  id={`add-inv-mobile-quantity`}
                  name="quantity"
                  label="Quantity"
                  handleOnChange={(e: any) => handleOnChange(e)}
                  value={editViewData?.quantity}
                  textFielClassName={`truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5 ${
                    editViewData?.errors?.quantity_available &&
                    "border-rose-500 focus:border-rose-500 #{!important} placeholder:text-rose-500"
                  } `}
                  onKeyDown={handleKeyDown}
                />
                {editViewData?.errors?.quantity_available && (
                  <>
                    <TooltipPortal
                      id={`quantity-error`}
                      content={editViewData?.errors?.quantity_available}
                      className="text-center z-[99999] !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-20 max-w-[13.75rem] !text-rose-500"
                    />

                    <div
                      data-tooltip-id={`quantity-error`}
                      className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                    >
                      <IKTSVG
                        className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                        path={Warning1}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* SPLIT TYPE */}
            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 }`}
            >
              <DropDownField
                value={editViewData?.splitType}
                isClearable={false}
                options={layout.splitType}
                placeholder={"Split type"}
                name="splitType"
                handleOnChange={(data: any) => handleOnChange(data)}
                dropDownWidth={"min-w-[unset] max-w-[unset] w-full"}
              />
            </div>

            {/* SPLIT QUANTITY */}
            {editViewData?.splitType?.id === "MULTIPLES" && (
              <div
                className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 }`}
              >
                <Textfield
                  type="text"
                  name="MULTIPLES"
                  id={`add-inv-multiples`}
                  label="Sell In Multiples"
                  handleOnChange={(e: any) => handleOnChange(e)}
                  value={editViewData?.MULTIPLES}
                  textFielClassName={`${
                    editViewData?.splitType?.id === "MULTIPLES"
                  }  truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                />
              </div>
            )}

            {/* MAX DISPLAY */}
            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] min-w-[10.0625rem] px-[.313rem] mb-3.5 `}
            >
              <Textfield
                type="text"
                id={`add-inv-max-display`}
                name="maxDisplayQuantity"
                label="Max display quantity"
                handleOnChange={(e: any) => handleOnChange(e)}
                value={editViewData?.maxDisplayQuantity}
                textFielClassName={`truncate h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
              />
            </div>

            {/* CATEGORY */}
            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
            >
              <DropDownField
                value={
                  editViewData?.category && _.size(editViewData?.category) > 0
                    ? editViewData?.category
                    : undefined
                }
                options={uniqueByNameWithPriority(
                  mobileEditView?.event?.categories,
                  ""
                )}
                placeholder={"Category"}
                isCapital={true}
                isValueCapital={true}
                name="category"
                handleOnChange={(data: any) => handleOnChange(data)}
                className={editViewData?.errors?.category && "error-field"}
                errorEventIndex={editViewData?.errors?.category}
                errorMessage={
                  editViewData?.errors?.category
                    ? editViewData?.errors?.category
                    : ""
                }
                dropDownWidth={"min-w-[unset] max-w-[unset] w-full"}
              />
            </div>

            {/* SECTION */}
            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 proceed-price  `}
            >
              <DropDownField
                value={
                  editViewData?.section &&
                  Object.keys(editViewData?.section)?.length > 0
                    ? {
                        ...editViewData?.section,
                        name:
                          editViewData?.category?.name !==
                          editViewData?.section?.name
                            ? editViewData?.section?.name?.replace(
                                editViewData?.category?.name,
                                ""
                              )
                            : editViewData?.section?.name,
                      }
                    : undefined
                }
                options={uniqueByNameWithPriority(
                  editViewData?.category?.sections,
                  ""
                )?.map((item: any) => {
                  return {
                    id: item.id,
                    name:
                      editViewData?.category?.name !== item.name
                        ? item.name.replace(editViewData?.category?.name, "")
                        : item?.name,
                    originalName: item?.name,
                  };
                })}
                placeholder={"Section/block"}
                isCapital={true}
                isValueCapital={true}
                name="section"
                handleOnChange={(data: any) => handleOnChange(data)}
                // isDisabled={isEventRecordHoldTickets}
                // errorClassName={
                //   eventRecordData?.fieldErrors?.section && "error-field"
                // }
                // errorEventIndex={eventRecordData?.fieldErrors?.section}
                // errorMessage={
                //   eventRecordData?.fieldErrors?.section
                //     ? eventRecordData?.fieldErrors?.section
                //     : ""
                // }
                dropDownWidth={"min-w-[unset] max-w-[unset] w-full"}
              />
            </div>

            {/* ROW */}
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
            >
              <Textfield
                type="text"
                name="row"
                id={`add-inv-row`}
                label="Row"
                handleOnChange={(e: any) => handleOnChange(e)}
                value={editViewData?.row}
                textFielClassName={`h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
              />
            </div>

            {/* FIRST SEAT */}
            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
            >
              <Textfield
                type="text"
                name="firstSeat"
                id={`add-inv-first-seat`}
                label="First seat"
                handleOnChange={(e: any) => handleOnChange(e)}
                value={editViewData?.firstSeat}
                textFielClassName={`h-[1.875rem] w-full text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
              />
            </div>

            {/* FACE VALUE */}
            <div
              className={` w-full  sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 text-xs `}
            >
              <PriceField
                value={
                  editViewData?.faceValue?.value
                    ? removeCommaFromNumber(editViewData?.faceValue?.value)
                    : ""
                }
                currenncySymbol={target_currency}
                textFielClassName={`truncate`}
                name="faceValue"
                handleOnChange={(data: any) => handleOnChange(data)}
                errorClass={`${
                  editViewData?.errors?.face_value
                    ? editViewData?.errors?.face_value
                    : ""
                }`}
                errorMessage={`${
                  editViewData?.errors?.face_value
                    ? editViewData?.errors?.face_value
                    : ""
                }`}
                errorEventIndex={editViewData?.errors?.face_value && 1}
                label="Face value"
                toolTipZindex={`z-[99999]`}
                textfieldType={"number"}
              />
            </div>

            {/* PROCEED PRICE */}
            <div
              className={`w-full  sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 text-xs priceMobField ${
                editViewData?.ppe && "disable"
              }`}
            >
              <PriceField
                value={
                  // Object.keys(
                  //   editViewData?.proceedValue ? editViewData?.proceedValue : []
                  // ).length > 0
                  //   ? removeCommaFromNumber(
                  //       decimalNumber(editViewData?.proceedValue?.value)
                  //     )
                  //   : ""
                  removeCommaFromNumber(editViewData?.proceedValue?.value)
                }
                currenncySymbol={target_currency}
                textFielClassName={`truncate`}
                name="proceedValue"
                handleOnChange={(data: any) => {
                  handleOnChange(data);

                  if (!editViewData?.ppe) {
                    let ppeEvent = {
                      target: {
                        name: "ppePartners",
                        value: editViewData?.ppePartners?.map(
                          (partner: any) => {
                            return {
                              ...partner,
                              value: {
                                ...partner?.value,
                                value: removeCommaFromNumber(
                                  decimalNumber(data.target?.value?.value)
                                ),
                                symbol: data?.target?.value?.symbol,
                              },
                            };
                          }
                        ),
                      },
                    };

                    handleOnChange(ppeEvent);
                  }
                }}
                isDisabled={editViewData?.ppe}
                errorClass={`${
                  editViewData?.errors?.sell_price
                    ? editViewData?.errors?.sell_price
                    : ""
                }`}
                errorMessage={`${
                  editViewData?.errors?.sell_price
                    ? editViewData?.errors?.sell_price
                    : ""
                }`}
                errorEventIndex={editViewData?.errors?.sell_price && 2}
                label="Proceed price"
                toolTipZindex={`z-[99999]`}
                textfieldType={"number"}
              />
            </div>

            <div
              className={`inhand_keyboard w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 min-w-[11.5625rem]`}
            >
              <Checkbox
                label="Price per partner"
                name="ppe"
                id="Priceperpartner-mobile"
                WithIcon={true}
                Icon={PPPIcon}
                IconSize={`w-[.9688rem] h-[.9688rem]`}
                checked={editViewData?.ppe}
                handleOnChange={(data: any) => {
                  handleOnChange(data);
                }}
                AddinventoryHand={true}
                activeViolet={true}
              />
            </div>

            {/* BENEFITS */}
            <div
              className={`font-medium w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5  dd_large`}
            >
              <MultiDropDownField
                name="benefits"
                value={editViewData?.benefits}
                options={layout.benefits}
                classNamePrefix={"form_multiDropDown"}
                placeholder={"Benefits"}
                handleOnChange={(data: any) => handleOnChange(data)}
                // isDisabled={isEventRecordHoldTickets}
                handleOnSelection={(data: any, visibleOptions: any) => {
                  handleOnSelection(
                    data,
                    layout.benefits,
                    "benefits",
                    visibleOptions,
                    editViewData?.benefits
                  );
                }}
              />
            </div>

            {/* RESTRICTION */}
            <div
              className={`font-medium w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5  dd_large`}
            >
              <MultiDropDownField
                name="restrictions"
                value={editViewData?.restrictions}
                options={layout.restrictions}
                classNamePrefix={"form_multiDropDown"}
                placeholder={"Restrictions"}
                handleOnChange={(data: any) => handleOnChange(data)}
                handleOnSelection={(data: any, visibleOptions: any) => {
                  handleOnSelection(
                    data,
                    layout.restrictions,
                    "restrictions",
                    visibleOptions,
                    editViewData?.restrictions
                  );
                }}
                // isDisabled={isEventRecordHoldTickets}
              />
            </div>

            {/* DATE TO SHIP */}
            <div
              className={` w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 `}
            >
              <div className="w-full relative">
                <FormDatePickerField
                  name="dateToShip"
                  maxDate={new Date(mobileEditView?.event?.date)}
                  placeholder={`Date to ship`}
                  value={editViewData?.dateToShip}
                  handleOnChange={(data: any) => {
                    handleOnChange(data);
                  }}
                  disabledKeyboardNavigation={false}
                  isDisabled={editViewData?.ticketsInHands ? true : false}
                  error={editViewData?.errors?.date_to_ship ? true : false}
                  portalId=""
                />
                {editViewData?.errors?.date_to_ship && (
                  <>
                    <TooltipPortal
                      id={`dateship-error-${mobileEditView?.event?.id}-${mobileEditView?.recordIndex}`}
                      content={editViewData?.errors?.date_to_ship}
                      className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-20 max-w-[10rem] !text-rose-500"
                    />
                    <div
                      id={`dateship-error-${mobileEditView?.event?.id}-${mobileEditView?.recordIndex}`}
                      data-tooltip-id={`dateship-error-${mobileEditView?.event?.id}-${mobileEditView?.recordIndex}`}
                      className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                    >
                      <IKTSVG
                        className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                        path={Warning1}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* TICKET IN HAND */}
            <div
              className={`inhand_keyboard w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 min-w-[11.5625rem]`}
            >
              <Checkbox
                checked={editViewData?.ticketsInHands}
                label="Tickets in hand"
                name="ticketsInHands"
                id="ticketsInHands"
                WithIcon={true}
                Icon={handIcon}
                IconSize={"w-[.6875rem] h-[.9063rem]"}
                TooltipContent={
                  "Tickets marked in hand must be delivered within 24h of sale - failure to do so may result in a penalty applied to your account."
                }
                TooltipID={"Tickets-hand"}
                handleOnChange={(data: any) => handleOnChange(data)}
                AddinventoryHand={true}
              />
            </div>
          </div>

          {/* PRICE PER PARTNER */}
          <div
            className={`bg-indigo-500 bg-opacity-[7%] px-5 py-[.9375rem] flex items-center gap-2.5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full ${
              editViewData?.ppe ? "flex" : "hidden"
            }`}
          >
            <PPEComponent
              id={`tx-network-add-inventory`}
              label="TX Network"
              name={`proceedValue`}
              value={
                // Object.keys(
                //   editViewData?.proceedValue ? editViewData?.proceedValue : []
                // ).length > 0
                //   ? removeCommaFromNumber(
                //       decimalNumber(editViewData?.proceedValue?.value)
                //     )
                //   : ""
                removeCommaFromNumber(editViewData?.proceedValue?.value)
              }
              currencySymbol={target_currency}
              isTooltip={true}
              tooltipContent={Messages.txNetworkTooltipMessage}
              // className="bg-opacity-100 border-gray-500"
              errors={editViewData?.errors?.sell_price ? true : false}
              // isPPEActive={true}
              // disabled={true}
              txNetwork={true}
              // isPPEActive={editViewData?.ppe}
              handleOnChange={(data: any) => {
                handleOnChange(data);
              }}
              // isMobile={true}
            />

            {editViewData?.ppePartners?.map((partner: any) => {
              if (partner.name && !partner.is_hidden) {
                return (
                  <React.Fragment key={partner.id}>
                    <PPEComponent
                      id={partner.id}
                      label={partner.name}
                      name={partner.id}
                      value={partner?.value?.value}
                      errors={editViewData?.errors?.[partner?.name]}
                      currencySymbol={
                        partner?.value?.symbol
                          ? partner?.value?.symbol
                          : target_currency
                      }
                      isMatchedValue={
                        removeCommaFromNumber(
                          decimalNumber(
                            editViewData?.eventRecords?.[0]?.proceedValue?.value
                          )
                        ) !==
                        removeCommaFromNumber(
                          decimalNumber(partner?.value?.value)
                        )
                      }
                      tooltipContent={`Your default proceed price across 10+ partners in the TX Network`}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                      }}
                      // isMobile={true}
                    />
                  </React.Fragment>
                );
              } else return <></>;
            })}
          </div>
        </div>

        {/* FOOTER */}
        <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl bg-white z-10 123">
          <button
            type="button"
            className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
            onClick={closePopupView}
          >
            Cancel
          </button>
          <button
            type="button"
            className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
            onClick={() => handleUpdateRecord()}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAddInventoryMobileView;
