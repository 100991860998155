/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from "react";
import { KTSVG } from "../../../../components/ui/KTSVG";
import { handleShift } from "../../../../helpers/Functions";
import { AddInventoryContext } from "../../core/AddInventoryProvider";
import { convertDate, convertTime } from "../../core/_functions";
import { Performer } from "../../core/_models";

const PerformerFields = ({ id }: any) => {
  const layout = useContext(AddInventoryContext);
  let performers = layout.performer;

  // Select All Events
  const handleSelectAll = (data: any, deselect: boolean) => {
    layout.setPerformer((Performers: any) => {
      let finalResult = Performers.filter((performer: any) => performer.id).map(
        (perform: any) => {
          return {
            ...perform,
            selected: data.target.checked,
          };
        }
      );
      return finalResult;
    });
  };

  const selectSinglePerformer = (data: any, eIndex: number) => {
    const tempEvents = (performers: Array<Performer>) => {
      performers[eIndex]["selected"] = data.target.checked;
      return performers.map((performer: any, performerIndex: number) => ({
        ...performer,
      }));
    };
    layout.setPerformer(tempEvents(performers));
  };

  let performerLength = performers.filter(
    (performer: any) => performer.id && performer.selected
  ).length;

  let isCheckedAll = performers?.every((peformer: any) => peformer.selected);

  useEffect(() => {
    const element = document.getElementById("scroll-elem");

    const elem_per_button = document.getElementsByClassName("per_button")[0];
    element?.addEventListener("scroll", () => {
      const elementScrollLeft = element.scrollTop;
      const elementScrollRight =
        element.scrollHeight - element.clientHeight - element.scrollTop;

      if (elementScrollRight === 0) {
        elem_per_button?.classList.remove("per_button");
      } else if (elementScrollRight !== 0) {
        elem_per_button?.classList.add("per_button");
      }
    });
  });

  // SHIFT ALL SELECT
  // const handleShift = (e: any, rIndex: any) => {
  //   let firstIndex = performers?.findIndex(
  //     (first: any, index: any) => first.selected && index
  //   );

  //   if (e.shiftKey && e.target.checked) {
  //     performers?.forEach((record: any, index: any) => {
  //       if (
  //         (index >= firstIndex && index <= rIndex) ||
  //         (index >= rIndex && index <= firstIndex)
  //       ) {
  //         record.selected = true;
  //       } else {
  //         record.selected = false;
  //       }
  //     });
  //   }
  // };
  return (
    <>
      <div
        className="text-xs overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full max-h-[24.375rem] scroll-elem"
        id={`scroll-elem`}
      >
        <table className="w-full text-left min-w-[70rem]">
          <tbody>
            <tr className="sticky top-0 bg-white z-[1]">
              <td className="items-center justify-center border-r  h-[2.5rem] p-1 text-center relative table-cell">
                <label
                  htmlFor="check-all"
                  className="max-w-[2.5rem] absolute cursor-pointer border-b top-0 left-0 w-full h-full bg-white z-0"
                ></label>
                <input
                  type="checkbox"
                  id="check-all"
                  className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 z-[1] relative"
                  checked={isCheckedAll}
                  onChange={(data: any) => handleSelectAll(data, false)}
                />
              </td>
              <td className=" w-full text-sm13 p-0" colSpan={4}>
                <div className="py-2.5 px-3.5 border-b min-h-[2.5rem]">
                  {performers &&
                    performerLength > 0 &&
                    (performers && performerLength <= 1
                      ? `${performerLength} event selected`
                      : `${performerLength} events selected`)}
                </div>
              </td>
            </tr>
            {performers &&
              performers.length > 0 &&
              performers.map((performer: any, eIndex: number) => {
                if (performer.id) {
                  return (
                    <React.Fragment key={eIndex}>
                      <tr>
                        <td className="border-r border-b h-[2.5rem] p-3 ">
                          <label
                            htmlFor={`performerCheck-${eIndex}`}
                            className="min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] absolute cursor-pointer top-0 left-0"
                          ></label>

                          <input
                            type="checkbox"
                            checked={performer.selected}
                            id={`performerCheck-${eIndex}`}
                            className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative"
                            onChange={(data: any) =>
                              selectSinglePerformer(data, eIndex)
                            }
                            onClick={(e) => handleShift(e, eIndex, performers)}
                          />
                        </td>
                        <td className="py-2.5 px-3.5 border-r border-b w-[19.5rem]">
                          {performer?.name}
                        </td>
                        <td className="py-2.5 px-3.5 border-r border-b w-[10rem]">
                          <div className=" inline-flex items-center">
                            <KTSVG
                              className=" fill-violet-800 hover:fill-indigo-800 transition"
                              path="standard_icons/calendar.svg"
                            />
                            {/* <span className="ml-2"> {performer?.date}</span> */}
                            <span className="ml-2">
                              {" "}
                              {performer?.event_date_local
                                ? convertDate(performer?.event_date_local)
                                : "N/A"}
                            </span>
                          </div>
                        </td>
                        <td className="py-2.5 px-3.5 border-r border-b w-[5.3rem] whitespace-nowrap">
                          <div className=" inline-flex items-center text-center">
                            <KTSVG
                              className=" fill-violet-800 hover:fill-indigo-800 transition"
                              path="standard_icons/clock-outline.svg"
                            />
                            {/* <span className="ml-2"> {performer?.time}</span> */}
                            <span className="ml-2">
                              {" "}
                              {performer?.event_date_local
                                ? convertTime(performer?.event_date_local)
                                : "N/A"}
                            </span>
                          </div>
                        </td>
                        <td className="py-2.5 px-3.5  border-b w-[37.5rem]">
                          <div className=" inline-flex items-center">
                            <KTSVG
                              className=" fill-violet-800 hover:fill-indigo-800 transition"
                              path="standard_icons/geo_icon.svg"
                            />
                            {/* <span className="ml-2"> {performer?.address}</span> */}
                            <span className="ml-2">
                              {" "}
                              {performer?.venue_name
                                ? performer?.venue_name
                                : "N/A"}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                }
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PerformerFields;
