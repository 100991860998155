import moment from "moment";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import infoIcon from "../../../assets/media/icons/other_icons/info.svg";
import WarningIcon from "../../../assets/media/icons/standard_icons/warning.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import Card from "../../../components/ui/Card";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  addCommaInNumber,
  getCurrencySymbol,
} from "../../../helpers/Functions";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { getPurchaseListings, getTrackedListings } from "../core/requests";
import ReportCardSub from "./report-card/ReportCardSub";

const TxTradeCard = ({ data, selectedTeams, purchaseData }: any) => {
  // const metaData = data?.meta;
  // const items = data?.data?.list;
  const total = data?.data?.total_tracked_listing;
  const purchaseTotal = purchaseData?.data?.purchase_count;
  const coreLayout = useContext(LayoutContext);

  const [trackedCurrentPage, setTrackedCurrentPage] = useState<any>();
  const [trackedData, setTrackedData] = useState<any>();

  const [purchaseCurrentPage, setPurchaseCurrentPage] = useState<any>();
  const [purchasesData, setPurchasesData] = useState<any>();
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      setTrackedData(data?.data?.list);
      setTrackedCurrentPage(data?.meta);
    }
    if (purchaseData) {
      setPurchasesData(purchaseData?.data?.purchases);
      setPurchaseCurrentPage(purchaseData?.meta);
    }
  }, [data, purchaseData]);
  const fetchTrackedData = async () => {
    let prevPage: number = trackedCurrentPage?.current_page + 1;
    try {
      getTrackedListings({
        // optionsData: layout.salesOptions,
        page: prevPage,
        team_id: selectedTeams,
      }).then((response: any) => {
        if (response) {
          // setData(response.data)
          setTrackedData((prevItems: any) => [
            ...prevItems,
            ...response?.data?.list,
          ]);
          setTrackedCurrentPage(response?.meta);
        }
      });
    } catch (error) {}
  };
  const fetchPurchaseData = async () => {
    let prevPage: number = purchaseCurrentPage?.current_page + 1;
    try {
      getPurchaseListings({
        // optionsData: layout.salesOptions,
        page: prevPage,
        per_page: 10,
        team_id: selectedTeams,
      }).then((response: any) => {
        if (response) {
          // setData(response.data)
          setPurchasesData((prevItems: any) => [
            ...prevItems,
            ...response?.data?.purchases,
          ]);
          setPurchaseCurrentPage(response?.meta);
        }
      });
    } catch (error) {}
  };

  const handleOnBtnClick = (action: any, obj: any) => {
    navigate("../tx-trade", {
      replace: true,
    });
    coreLayout?.setGlobalTxTradeData((pre: any) => {
      return {
        tab:
          action === "view"
            ? "Tracking"
            : action === "Purchases"
            ? "Purchases"
            : "Dashboard",
        eName: obj?.name,
        eId: obj?.tixstock_id,
        goToPurchaseView: !(purchaseData?.data?.details_required > 0),
      };
    });
  };
  return (
    <div className="lg2:w-1/2 w-full md:px-2 md:mb-4 mb-2">
      <Card
        title="TX Trade"
        threeDot={true}
        handleOnTXTradeButtonClick={() => handleOnBtnClick("find-tickets", "")}
        handleClick={() => handleOnBtnClick("find-tickets", "")}
        cardTitle="TX Trade"
      >
        {/* Card sub Heading */}
        <div className="border-b border-gray-200">
          <div className="divide-gray-200  ">
            {/* Card Body */}
            <div className="flex flex-nowrap justify-between p-5 gap-5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <ReportCardSub
                title={"Tracking"}
                reportsCardData={total ? addCommaInNumber(total) : 0}
                buttonText="View"
                handleOnButtonClick={() => handleOnBtnClick("view", "")}
              />
              <ReportCardSub
                title={"Purchases"}
                reportsCardData={
                  purchaseTotal ? addCommaInNumber(purchaseTotal) : 0
                }
                buttonText={
                  purchaseData?.data?.details_required > 0
                    ? "Details required"
                    : "View"
                }
                handleOnButtonClick={() =>
                  handleOnBtnClick("Purchases", purchaseData)
                }
              />
            </div>
          </div>
        </div>
        <div className="flex ">
          <div
            className={`w-1/2 flow-root max-h-48  outline-black  overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full 
             pr-5 border-r`}
            id="txtradetracked"
          >
            {trackedData?.length > 0 ? (
              <>
                {" "}
                <div className="px-5 pr-0  sticky top-0 bg-white text-gray-400 z-10">
                  <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                    <div className="flex-1">
                      <p className="text-xs font-medium">Tracking</p>
                    </div>
                    <div className="inline-flex items-center text-xs font-medium min-w-[5rem]">
                      Ticket price
                    </div>
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={trackedData?.length ? trackedData?.length : 0}
                  next={() => {
                    fetchTrackedData();
                  }}
                  hasMore={
                    trackedCurrentPage?.current_page <
                    trackedCurrentPage?.last_page
                  }
                  loader={Array.from({ length: 3 }, (v, i) => (
                    <div
                      className={`accordion font-medium pl-5  mb-2 ${
                        i === 0 && "mt-2"
                      } `}
                      key={i}
                    >
                      <div
                        className={`accordion-item bg-white !rounded overflow-hidden shimmer-effect`}
                        style={{ height: "30px" }}
                      ></div>
                    </div>
                  ))}
                  // scrollThreshold="20px"
                  scrollThreshold="180px"
                  scrollableTarget="txtradetracked"
                >
                  <ul className="max-h-50 overflow-y-auto">
                    {trackedData?.map((obj: any, index: any) => (
                      <li
                        className="hover:bg-indigo-500/5 pl-5 transition cursor-pointer"
                        onClick={() => {
                          handleOnBtnClick("view", obj);
                        }}
                        key={index}
                      >
                        <div className="outline-black flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                          <div className="flex-1 flex items-start">
                            <p
                              className="text-xs text-gray-900 text- font-medium line-clamp-1"
                              title={obj?.name}
                            >
                              {obj?.name}
                            </p>
                            <span
                              className="flex"
                              data-tooltip-id={`infoTooltip-${index}`}
                            >
                              <IKTSVG
                                path={infoIcon}
                                className="fill-gray-400 hover:fill-indigo-500 ml-1"
                                svgClassName="w-3.5 h-3.5"
                              />
                              <TooltipPortal
                                id={`infoTooltip-${index}`}
                                content={obj?.venue}
                                className="text-center !bg-white !opacity-100  !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
                              />
                            </span>
                          </div>
                          <div className="min-w-[5rem] max-w-[5rem] ellips-text">
                            <p
                              className="text-xs text-gray-900 text- font-medium line-clamp-1"
                              // title={obj?.sell_price}
                            >
                              {obj.sell_price
                                ? `${getCurrencySymbol(
                                    obj.currency
                                  )}${addCommaInNumber(obj.sell_price)}`
                                : ""}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </InfiniteScroll>{" "}
              </>
            ) : (
              <>
                {" "}
                <div className="text-center text-sm py-4">
                  No records could be found at the moment.
                </div>
              </>
            )}
          </div>

          <div
            className={`w-1/2 flow-root max-h-48  outline-black  overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full 
           pl-5`}
            id="txtradepurchases"
          >
            {purchasesData?.length > 0 ? (
              <>
                <div className="px-5 pl-0 sticky top-0 bg-white text-gray-400 z-10">
                  <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                    <div className="flex-1">
                      <p className="text-xs font-medium">Purchases</p>
                    </div>
                    <div className="inline-flex items-center text-xs font-medium pr-[2.1875rem]">
                      Event date
                    </div>
                  </div>
                </div>

                <InfiniteScroll
                  dataLength={purchasesData?.length ? purchasesData?.length : 0}
                  next={() => {
                    fetchPurchaseData();
                  }}
                  hasMore={
                    purchaseCurrentPage?.current_page <
                    purchaseCurrentPage?.last_page
                  }
                  loader={Array.from({ length: 3 }, (v, i) => (
                    <div
                      className={`accordion font-medium pr-5  mb-2 ${
                        i === 0 && "mt-2"
                      } `}
                      key={i}
                    >
                      <div
                        className={`accordion-item bg-white !rounded overflow-hidden shimmer-effect`}
                        style={{ height: "30px" }}
                      ></div>
                    </div>
                  ))}
                  // scrollThreshold="20px"
                  scrollThreshold="180px"
                  scrollableTarget="txtradepurchases"
                >
                  <ul className="max-h-50 overflow-y-auto">
                    {purchasesData?.map((obj: any, index: any) => {
                      const isAttendeeDetailsRequired =
                        (Number(obj?.require_attendee_details) === 1 ||
                          Number(obj?.require_attendee_id) === 1) &&
                        Number(obj?.is_attendee_details_added === 0);

                      return (
                        <li
                          className="hover:bg-indigo-500/5 pr-5 transition cursor-pointer"
                          onClick={() => handleOnBtnClick("Purchases", obj)}
                          key={index}
                        >
                          <div className="outline-black flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                            <div className="w-[68%] flex">
                              <p
                                title={obj?.event}
                                className="text-xs text-gray-900 text- font-medium line-clamp-1"
                              >
                                {obj?.event}
                              </p>
                              <span
                                className="flex"
                                data-tooltip-id={`infoTooltip2-${index}`}
                              >
                                <IKTSVG
                                  path={infoIcon}
                                  className="fill-gray-400 hover:fill-indigo-500 ml-1"
                                  svgClassName="w-3.5 h-3.5"
                                />
                                <TooltipPortal
                                  id={`infoTooltip2-${index}`}
                                  content={obj?.venue}
                                  className="text-center !bg-white !opacity-100  !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
                                />
                              </span>
                            </div>
                            <div className="w-1/4 flex">
                              <p
                                className="text-xs text-gray-900 text- font-medium line-clamp-1"
                                title={
                                  obj?.event_date
                                    ? moment(obj?.event_date).format(
                                        "ddd, DD MMM YYYY, HH:mm"
                                      )
                                    : "-"
                                }
                              >
                                {obj?.event_date
                                  ? moment(obj?.event_date).format(
                                      "ddd, DD MMM YYYY, HH:mm"
                                    )
                                  : "-"}
                              </p>
                              {isAttendeeDetailsRequired ? (
                                <span
                                  className="flex"
                                  data-tooltip-id={`infoTooltip3-${index}`}
                                >
                                  <IKTSVG
                                    path={WarningIcon}
                                    className="fill-rose-500 ml-1"
                                    svgClassName="w-3.5 h-3.5"
                                  />
                                  <TooltipPortal
                                    id={`infoTooltip3-${index}`}
                                    content={
                                      "Attendee delivery details required"
                                    }
                                    className="text-center !bg-white !opacity-100  !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
                                  />
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </InfiniteScroll>
              </>
            ) : (
              <>
                {" "}
                <div className="text-center text-sm py-4">
                  No records could be found at the moment.
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default TxTradeCard;
