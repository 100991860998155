import LockIconOpen from "../../../../assets/media/icons/other_icons/lock-open-solid.svg";
import LockIcon from "../../../../assets/media/icons/other_icons/lock-solid.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";

const HeaderView = ({ title, isLock, handleLock }: any) => {
  return (
    <div className="title_block w-full flex  justify-between min-h-[2.5rem]">
      <div
        className={`title_block w-full border-b  flex  justify-between min-h-[2.5rem]`}
      >
        <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
          {title}
        </h5>
        <button
          type="button"
          className="w-10 flex items-center justify-center group border-l"
          onClick={() => handleLock()}
        >
          <IKTSVG
            path={isLock ? LockIcon : LockIconOpen}
            svgClassName={`group-hover:fill-indigo-500 h-[.9375rem] ${
              isLock
                ? "w-3 fill-violet-500"
                : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default HeaderView;
