/* eslint-disable array-callback-return */
import React, { useContext } from "react";
import SearchSVG from "../../../assets/media/icons/standard_icons/search-violet.svg";
import DropDownField from "../../../components/formComponent/DropDownField";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { InstructionLetterContext } from "../core/InstructionLetterProvider";

const Filters = ({ loading }: any) => {
  const layout = useContext(InstructionLetterContext);
  const filterOptions = Object.values(layout.filterOptions);

  // HEADER FILTER
  const getFilterItems = () =>
    layout.filterItems?.filter((obj: any) => obj?.checked === true);

  // HANDLE CHANGE
  const handleOnChange = (data: any) => {
    layout.setFilterData((current: any) => {
      return {
        ...current,
        [data?.target?.name]: data?.target?.value,
        page: 1,
        per_page: 20,
      };
    });
  };

  return (
    <div
      className="flex flex-wrap border-b border-t gap-y-2.5 py-4 px-[.9375rem] bg-white"
      id={`filters_block`}
    >
      {/* SEARCH WITH TITLE */}
      <div className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%]  px-[.3125rem]`}>
        <div
          className={`relative rounded flex-1 max-w-full md:mb-0 ${
            loading && "shimmer-effect"
          } `}
        >
          <div className="w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <button type="button">
                <IKTSVG
                  className="fill-violet-500"
                  svgClassName="h-3.5 w-3.5"
                  path={SearchSVG}
                />
              </button>
            </div>
            <input
              type="text"
              name="query"
              defaultValue={""}
              id="simple-search-instruction"
              placeholder="Search title"
              className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
              onKeyDown={(event: any) => {
                if (event?.target?.value && event.key === "Enter") {
                  event.preventDefault();
                  handleOnChange(event);
                }
              }}
            />
          </div>
        </div>
      </div>

      {getFilterItems()?.map((columnItem: any, index: number) => {
        switch (columnItem.id) {
          case 0:
            return (
              <React.Fragment key={index}>
                {/* STATUS */}
                <div
                  className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                >
                  <div className={` rounded ${loading && "shimmer-effect"}  `}>
                    <DropDownField
                      placeholder="Status"
                      options={filterOptions}
                      name="status"
                      value={layout?.filterData?.status}
                      handleOnChange={(data: any) => {
                        handleOnChange(data);
                        // layout.setFilterData((current: any) => {
                        //   return {
                        //     ...current,
                        //     status: data.target.value,
                        //   };
                        // });
                      }}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
        }
      })}
    </div>
  );
};

export default Filters;
