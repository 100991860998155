/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { size } from "lodash";
import { useDropzone } from "react-dropzone";
import { Tooltip } from "react-tooltip";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { base64TOURL } from "../../../../../helpers/AssetHelpers";
import { showAlert } from "../../../../../helpers/ShowAlert";
import { KTSVG } from "../../../../ui/KTSVG";

const FileUpload = ({
  userDetail,
  handleFiles,
  deleteFiles,
  formikValues,
  fileLimit = 10,
  setState,
}: any) => {
  // All Functions to handle the PDF's
  const { getRootProps, getInputProps, open } = useDropzone({
    maxSize: 5244899, // 5MB in bytes
    onDrop: (acceptedFiles, rejectedFiles) => {
      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) =>
              error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : "Invalid File Type"
            )
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        showAlert(uniqueCodes?.join(", <br/>"), true);
      } else if (
        userDetail.identity_proof?.length > fileLimit - 1 ||
        acceptedFiles?.length > fileLimit
      ) {
        showAlert(`Cannot select more than ${fileLimit} files`, true);
      } else {
        setState((current: any) => {
          let finalResult = {
            ...current,
            ...formikValues,
            identity_proof: [
              ...current?.identity_proof,
              ...acceptedFiles?.map((file) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  file.base64 = reader.result.substr(
                    reader.result.indexOf(",") + 1
                  );
                };
                return file;
              }),
            ],
          };

          handleFiles(finalResult?.identity_proof);
          return finalResult;
        });
      }
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "application/pdf": [],
    },
  });

  return (
    <>
      <div className="upload_file">
        {userDetail?.identity_proof &&
          size(userDetail?.identity_proof) < fileLimit && (
            <div
              {...getRootProps({ className: "dropzone" })}
              onClick={open}
              className={`upload-label cursor-pointer file_add_trigger px-[.9375rem] py-[.8125rem] bg-indigo-500 bg-opacity-[7%] border border-dashed border-indigo-500/20 hover:border-solid hover:border-indigo-500 transition flex items-center rounded font-basier_med text-sm15`}
            >
              <input {...getInputProps()} />
              <KTSVG
                className="w-5 h-5 flex items-center justify-center bg-indigo-500 fill-white rounded-full mr-[.9375rem]"
                path="other_icons/plus.svg"
                svgClassName="w-2.5 h-2.5"
              />
              Upload files
            </div>
          )}
        <div className="upload_list">
          <TransitionGroup component="div">
            {userDetail?.identity_proof &&
              userDetail?.identity_proof?.length > 0 &&
              userDetail?.identity_proof?.map((file: any, index: number) => {
                return (
                  <CSSTransition key={index} classNames="row" timeout={500}>
                    <div className="single_list flex flex-wrap rounded mt-2.5 transition-all bg-green-600 bg-opacity-[10%]">
                      <div className="file_name flex items-center justify-between px-[.9375rem] py-2.5 flex-1 text-sm13 font-basier">
                        <button
                          type="button"
                          className="transition-all duration-150"
                          onClick={() => {
                            window.open(
                              base64TOURL(file?.base64, file?.type),
                              "_blank"
                            );
                          }}
                          title="Open file in new tab"
                        >
                          {file?.name}
                        </button>
                        <button
                          type="button"
                          id={`fileupload-${index}`}
                          onClick={() => deleteFiles(file, index)}
                        >
                          <Tooltip
                            anchorId={`fileupload-${index}`}
                            content={"Delete"}
                            className="text-center !bg-white !opacity-100 shadow !px-[.3125rem] !py-0.5 font-medium  !text-xxs z-50 max-w-[10.625rem] cursor-default !text-violet-800"
                          />
                          <KTSVG
                            className="w-3 h-[.9375rem] flex items-center justify-center fill-violet-500 hover:fill-indigo-500 rounded-full cursor-pointer"
                            path="other_icons/Delete.svg"
                            svgClassName="w-3 h-[.9375rem]"
                          />
                        </button>
                      </div>
                    </div>
                  </CSSTransition>
                );
              })}
          </TransitionGroup>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
