import { Tooltip } from "react-tooltip";
import CrossSVF from "../../../assets/media/icons/standard_icons/cross.svg";
import WarningSVG from "../../../assets/media/icons/standard_icons/warning1.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";

const TablePopup = ({
  cancelBtn,
  confirmBtn = () => {},
  selectedItem,
  message,
  isConfirm,
  btnRef,
  publishedAlertMsg,
  setList,
  list,
  isBulkdelete = false,
}: any) => {
  return (
    // <div className="fixed top-0 left-0 flex items-center justify-center bg-black/70 h-screen w-full z-50">
    // <div className="flex max-w-[40.25rem]  w-full bg-white rounded px-3 py-2 mx-2.5">
    <div className={`flex w-full bg-white rounded`}>
      {isConfirm ? (
        <div
          className="flex flex-wrap gap-2.5 gap-x-5 justify-end items-center w-full flex-row-reverse w-full
        "
        >
          <div>
            <span className="text-sm13 font-medium mr-2 block">
              {message}
              <span className="text-gray-500 ml-2">{publishedAlertMsg}</span>
            </span>
          </div>

          {/* <div className="flex items-center ml-auto"> */}
          <div className="flex items-center ">
            <button
              className="inline-flex group items-center text-violet-800 border transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 hover:border-indigo-500 hover:text-white"
              type="button"
              onClick={(e: any) => cancelBtn(false)}
            >
              {" "}
              Cancel
            </button>
            <button
              className="ml-2 bg-rose-500  inline-flex group items-center text-white   
              transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 "
              type="button"
              onClick={(e: any) => {
                // console.log(isConfirm,'asdsad');
                confirmBtn("confirm");
                cancelBtn(false);
                if (isBulkdelete) {
                }
              }}
              ref={btnRef}
              id={`delete`}
            >
              {/* <Tooltip
                anchorId="delete"
                content={"Confirm"}
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium !text-xxs z-10 mt-1.5"
              /> */}
              <i className="fas fa-solid fa-trash mr-1.5"></i>
              Delete
            </button>
          </div>
        </div>
      ) : (
        <>
          <IKTSVG
            svgClassName="fill-rose-500 min-w-[1rem] mt-0.5 mr-2 w-4 h-4"
            path={WarningSVG}
          />
          <span className="text-sm font-medium mr-2 text-rose-500">
            {message}
          </span>
          <button
            className="ml-auto mt-0.5 p-1 h-full"
            type="button"
            onClick={(e: any) => cancelBtn(false)}
            id="Cross-alert"
          >
            <Tooltip
              anchorId="Cross-alert"
              content={"Close"}
              place="top"
              variant="light"
              className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium !text-xxs z-10 mt-1.5"
            />
            <IKTSVG
              svgClassName="fill-violet-800 hover:fill-indigo-500 transition w-2.5 h-2.5"
              path={CrossSVF}
            />
          </button>
        </>
      )}
    </div>
    // </div>
    // </div>
  );
};

export default TablePopup;
