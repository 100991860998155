import algoliasearch from "algoliasearch";
import axios from "axios";
import { size } from "lodash";
import moment from "moment";
import {
  REACT_APP_ALGOLIA_API_KEY,
  REACT_APP_ALGOLIA_APPLICATION_ID,
} from "../../../helpers/const";
import { convertDateStringToUnixTimestamp } from "../../../helpers/Functions";
import {
  getConnectedExchange,
  getHeaders,
  mapOrderDataToFormData,
  removeTrailingSlash,
} from "./functions";

const BASEURL: any = process.env.REACT_APP_API_URL;
const REACT_APP_PROXY_URL: any = process.env.REACT_APP_PROXY_URL;

const getClientsListing = (data: any): Promise<any> => {
  let page = data?.filters?.page;
  let per_page = data?.filters?.per_page;
  let status = data.filters?.status;
  let query = data?.filters?.query;
  let order_by = data?.sorting?.order_by;
  let sort_order = data?.sorting?.sort_order;

  let payload = {
    ...data.pagination,
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(status && { status }),
    ...(query && { query }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && { sort_order }),
  };

  return axios
    .post(`api-connector/clients`, payload)
    .then((response) => response.data);
};

const getClientsOptions = (): Promise<any> => {
  return axios
    .get("api-connector/client-options")
    .then((response) => response.data);
};

// VIEW API'S
const fetchThroughMethod = async (data: any): Promise<any> => {
  const { url, httpMethod, headers, body } = data;
  // HEADERS
  const headersObject = getHeaders(headers);
  let newBody = body ? JSON.parse(body) : undefined;

  try {
    const response = await fetch(
      `${removeTrailingSlash(BASEURL)}/${REACT_APP_PROXY_URL}`,
      {
        method: httpMethod?.name,
        headers: {
          ...headersObject,
          "Content-Type": "application/json",
          "X-Target-URL": url,
          "X-Target-Method": httpMethod?.name,
        },
        body:
          httpMethod?.name === "GET" || httpMethod?.name === "DELETE"
            ? undefined
            : JSON.stringify(newBody),
      }
    );
    if (!response.ok) {
      const errorResponseData = await response.json();
      return errorResponseData;
    } else {
      const responseData = await response?.json();
      return {
        ...responseData,
        statusCode: response?.status,
      };
    }
  } catch (error) {
    return error;
  }
};

// VARIABLE API
const postVariable = async (data: any): Promise<any> => {
  const { id, headers } = data;
  const deleteId = headers?.find(
    (item: any, filterIndex: number) => filterIndex === data?.deleteIndex
  );

  let payload = {
    ...(!data?.deleteIndex && { vars: headers }),
    ...(data?.deleteIndex && { deleted_ids: [deleteId?.id] }),
  };
  const response = await axios.post(`api-connector/variables/${id}`, payload);
  return response.data;
};

const getVariable = async (id: string): Promise<any> => {
  const response = await axios.get(`api-connector/variables/${id}`);
  return response.data;
};

// TRIGGERS API
const triggersSaveAPI = async (data: any): Promise<any> => {
  const reseller_id = data?.id;
  const request_type = data?.httpMethod?.name;
  const endpoint_url = data?.url;
  const headers = getHeaders(data?.headers);
  const body = data?.body;
  const auth_required = data?.authRequired;
  const response_mapped_variables = data?.mapping;
  const triggerId = data?.triggerId;
  const tab = data?.tab;
  const name = data?.name?.id ? data?.name?.id : data?.name;

  // PAYLOAD
  let payload = {
    reseller_id,
    request_type,
    endpoint_url,
    auth_required: auth_required ? 1 : 0,
    ...(size(getHeaders(data?.headers)) > 0 && { headers }),
    ...(body && { body }),
    content_type: "application",
    name: tab === "Authentication" ? "auth" : name,
    ...(response_mapped_variables && { response_mapped_variables }),
    ...(triggerId && { id: triggerId }),
  };

  const response = await axios.post(`api-connector/save-triggers`, payload);
  return response.data;
};

const getTriggerListing = async (data: any): Promise<any> => {
  const reseller_id = data?.id;
  const name = data?.name;
  const exclude_names = data?.exclude_names;

  const response = await axios.get(`api-connector/triggers/${reseller_id}`, {
    params: {
      name,
      exclude_names,
    },
  });
  return response.data;
};

const getClientsEvents = async (data: any): Promise<any> => {
  const reseller_id = data?.id;
  const page = data?.filters?.page;
  const event_filter_name = data?.filters?.query;
  let event_filter_category_name = data?.filters?.category;
  let event_filter_venue_name = data?.filters?.venue;
  let event_filter_performer_name = data?.filters?.performer;
  let is_mapped = data?.filters?.isMapped ? 1 : 0;
  let order_by = data?.sorting?.order_by;
  let sort_order = data?.sorting?.sort_order;

  const payload = {
    reseller_id,
    ...(page && { page }),
    //...(per_page && { per_page }),
    ...(event_filter_name && { event_filter_name }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && { sort_order }),
    ...(event_filter_category_name && { event_filter_category_name }),
    ...(event_filter_venue_name && { event_filter_venue_name }),
    ...(event_filter_performer_name && { event_filter_performer_name }),
    ...(is_mapped && { is_mapped }),
  };
  const response = await axios.post(`api-connector/events`, payload);
  return response.data;
};

const searchEventInAlgolia = async (data: any): Promise<any> => {
  const client = algoliasearch(
    REACT_APP_ALGOLIA_APPLICATION_ID,
    REACT_APP_ALGOLIA_API_KEY
  );
  // const dateTimeInYourTimeZone = new Date(data?.datetime);

  // const unixTimestamp = Math.floor(dateTimeInYourTimeZone.getTime() / 1000);

  let checkExchange = getConnectedExchange(data?.allowedAccess);
  let isSourceTixstock = "";
  let exchangeCondition = "";

  if (checkExchange && checkExchange.length > 0) {
    exchangeCondition = checkExchange
      .map((exchange: any) => `exchanges=${exchange}`)
      .join(" OR ");
  } else {
    isSourceTixstock = " is_source_tixstock=1 ";
  }

  const unixTimestamp =
    data?.datetime &&
    convertDateStringToUnixTimestamp(
      moment(data?.datetime).format("YYYY-MM-DD") + "T00:00:00Z"
    );

  const endDate =
    data?.datetime &&
    convertDateStringToUnixTimestamp(
      moment(data?.datetime).format("YYYY-MM-DD") + "T23:59:59Z"
    );
  let date = `AND event_date_local:${unixTimestamp} TO ${endDate}`;
  const algoliaConfiguration = data?.algoliaConfiguration;

  const index = client.initIndex("events");
  return index
    .search(data?.query, {
      filters: `${exchangeCondition} ${isSourceTixstock} ${date}`,
      ...(algoliaConfiguration && algoliaConfiguration),
    })
    .then((response: any) => {
      return response;
    });
};
const mapEvents = async (data: any): Promise<any> => {
  let payload = {
    reseller_id: data?.id,
    mapped_events: data?.events.map((item: any) => {
      return {
        tixstock_event_id: item?.tixstockEventId,
        external_event_id: item?.externalEventId,
        currency: item?.currency,
      };
    }),
  };
  const response = await axios.post(`api-connector/map-events`, payload);
  return response.data;
};

export const unMapEvents = async (data: any): Promise<any> => {
  let payload = {
    reseller_id: data?.id,
    unmapped_events: data?.events,
  };
  const response = await axios.post(`api-connector/unmap-events`, payload);
  return response.data;
};

const getApiConnectorListing = async (params: any) => {
  let order_by = params?.sorting?.order_by;
  let sort_order = params?.sorting?.sort_order;
  let status = Array.isArray(params?.filters?.listingStatuses)
    ? params?.filters?.listingStatuses?.map((status: any) => status?.id)
    : [];

  const payload = {
    reseller_id: params?.id,
    page: params?.filters.page,
    per_page: params?.filters.per_page,
    ...(status?.length > 0 && { status }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && { sort_order }),
  };

  const response = await axios.post(`api-connector/listings`, payload);
  return response?.data;
};

const getApiConnectorListingCounts = async (params: any) => {
  const response = await axios.post(`api-connector/counts`, {
    reseller_id: params.id,
  });
  return response?.data;
};

// dummy api
const getApiConnectorOrders = (params: any) => async () => {
  const payload = mapOrderDataToFormData(params);
  const response = await axios.post(`api-connector/orders`, payload);
  return response?.data;
};

export {
  fetchThroughMethod,
  getApiConnectorListing,
  getApiConnectorListingCounts,
  getApiConnectorOrders,
  getClientsEvents,
  getClientsListing,
  getClientsOptions,
  getTriggerListing,
  getVariable,
  mapEvents,
  postVariable,
  searchEventInAlgolia,
  triggersSaveAPI,
};
