import { StyledButton } from "components/ui/buttons/StyledButton/StyledButton";
import { IKTSVG } from "components/ui/IKTSVG";
import { Tooltip } from "react-tooltip";
import EyeIcon from "../../../../../../assets/media/icons/other_icons/Eye.svg";
import CrossIcon from "../../../../../../assets/media/icons/standard_icons/cross.svg";
import {
  addSubNestedCategory,
  updateNestedCategory,
  updateSubCategory,
} from "../../util";
import { AlgoliaWithDeleteButton } from "../AlgoliaWithDeleteButton";

export const SubCategoryForm = (props: any) => {
  const { subCategory, index, setCategories, subCategoryIndex } = props;

  return (
    <div
      className="w-full gap-4 mb-5 flex flex-wrap relative"
      key={subCategoryIndex}
    >
      <div className="w-full flex gap-4">
        <AlgoliaWithDeleteButton
          id={`sub-${subCategoryIndex}`}
          item={subCategory}
          handleHitSelect={(data: any) =>
            updateSubCategory(index, subCategoryIndex, setCategories, data)
          }
          deleteItem={() =>
            updateSubCategory(index, subCategoryIndex, setCategories, {
              is_deleted: true,
            })
          }
          toggleEdit={() =>
            updateSubCategory(index, subCategoryIndex, setCategories, {
              isEditable: !subCategory?.isEditable,
            })
          }
          onChange={(e: any) =>
            updateSubCategory(index, subCategoryIndex, setCategories, {
              name: e.target.value,
            })
          }
        />
        <StyledButton
          isDisabled={!subCategory?.menuId}
          onClick={() =>
            updateSubCategory(index, subCategoryIndex, setCategories, {
              isToggle: !subCategory?.isToggle,
            })
          }
          className="min-w-min"
          id={`record-plus-${index}${subCategoryIndex}`}
        >
          <Tooltip
            anchorId={`record-plus-${index}${subCategoryIndex}`}
            content={subCategory?.isToggle ? "Close menu" : "View menu"}
            place={`top`}
            variant="light"
            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 mt-1.5`}
          />
          <IKTSVG
            className={`${
              !subCategory?.menuId ? "fill-gray-400" : "fill-violet-500"
            } flex items-center justify-center  group-hover:fill-white transition`}
            svgClassName={`${
              subCategory?.isToggle ? "w-3 h-3" : "w-3.5 h-3.5"
            }`}
            path={subCategory?.isToggle ? CrossIcon : EyeIcon}
          />
        </StyledButton>
      </div>

      {subCategory?.isToggle && (
        <div className="w-full border rounded p-4">
          {subCategory?.sub_menu &&
            subCategory?.sub_menu.map(
              (nestedItem: any, nestedI: any) =>
                !nestedItem?.is_deleted && (
                  <div
                    className="w-full gap-4 mb-2.5 flex  relative"
                    key={nestedI}
                  >
                    <div className="w-full flex gap-4">
                      <AlgoliaWithDeleteButton
                        id={`nested-${nestedI}`}
                        item={nestedItem}
                        handleHitSelect={(data: any) =>
                          updateNestedCategory(
                            index,
                            subCategoryIndex,
                            nestedI,
                            setCategories,
                            data
                          )
                        }
                        deleteItem={() =>
                          updateNestedCategory(
                            index,
                            subCategoryIndex,
                            nestedI,
                            setCategories,
                            {
                              is_deleted: true,
                            }
                          )
                        }
                        toggleEdit={() =>
                          updateNestedCategory(
                            index,
                            subCategoryIndex,
                            nestedI,
                            setCategories,
                            {
                              isEditable: !nestedItem?.isEditable,
                            }
                          )
                        }
                        onChange={(e: any) =>
                          updateNestedCategory(
                            index,
                            subCategoryIndex,
                            nestedI,
                            setCategories,
                            {
                              name: e.target.value,
                            }
                          )
                        }
                      />
                    </div>
                  </div>
                )
            )}
          <StyledButton
            onClick={addSubNestedCategory(
              setCategories,
              index,
              subCategoryIndex
            )}
          >
            Add sub menu
          </StyledButton>
        </div>
      )}
    </div>
  );
};
