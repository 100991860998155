/* eslint-disable array-callback-return */

import { LayoutContext } from "components/core/LayoutProvider";
import { IKTSVG } from "components/ui/IKTSVG";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { showAlert } from "helpers/ShowAlert";
import { size } from "lodash";
import {
  deleteContentListings,
  getPerHomeDesign,
} from "pages/customize/core/_.request";
import { handleScrollTable } from "pages/customize/core/functions";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TableVirtuoso } from "react-virtuoso";
import angleLeftSVG from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import HeaderCellWrapper from "./table/HeaderCellWrapper";
import { ItemContent } from "./table/ItemContent";
import TableRow from "./table/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  md:min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTable = ({ paginateData, loading, setIsLoading, refetch }: any) => {
  const globalLayout = useContext(LayoutContext);
  const {
    isTabActive,
    contentList,
    setContentList,
    setIsDelete,
    isDelete,
    setIsPopupActive,
    setIsLoading: setStateLoading,
    setContentFilter,
  } = useCustomize();
  let data = contentList;

  const [height, setHeight] = useState<any | undefined>(undefined);

  let timeout: any;
  // const nomoreListing = document.getElementById("NoMoreListing");
  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#Report-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      // const nomoreListing = document.getElementById("NoMoreListing");
      // let nomoreListingHeight = nomoreListing?.clientHeight;
      // setTimeout(() => {
      //   nomoreListingHeight = nomoreListing?.clientHeight;
      // }, 2000);

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight;
          // if (nomoreListingHeight) {
          //   elemHeight =
          //     divElement.offsetHeight + theadHeight + nomoreListingHeight;
          // }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 1000);
  };
  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  const tableRef: any = useRef<any>();
  const stickyTableRef: any = useRef<any>();

  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  // HANDLE CHECK
  const handleCheck = (e: any, id: string | number) => {
    setContentList((current: any) => {
      return current?.map((item: any, index: any) => {
        if (item?.id === id) {
          return {
            ...item,
            selected: e?.target?.checked,
          };
        } else return item;
      });
    });
  };

  // HANDLE DELETE
  const handleDelete = (list: any) => {
    deleteContentListings(list?.id).then((response: any) => {
      setIsDelete({
        status: false,
        data: undefined,
      });
      if (response?.data?.status) {
        showAlert(response?.data?.message, false);
        refetch();
      } else {
        showAlert(
          response?.errors?.title
            ? response?.errors?.title
            : Array.isArray(response?.errors)
            ? response?.errors?.join("<br/>")
            : "Something went wrong!",
          true
        );
      }
    });
  };

  const handleView = (data: any) => {
    setStateLoading(true);
    getPerHomeDesign(data?.id).then((response: any) => {
      setStateLoading(false);
      if (size(response?.data) > 0) {
        setIsPopupActive({
          tabActive: isTabActive,
          status: true,
          data: response?.data?.[0],
        });
      }
    });
  };

  useEffect(() => {
    if (isDelete?.confirm) {
      const { list } = isDelete?.data;
      handleDelete(list);
    }
  }, [isDelete?.data && isDelete?.confirm]);

  // HANDLE - SELECT ALL
  const handleSelectAll = (data: any) => {
    setContentList((prev: any) => {
      return prev.map((current: any) => {
        return {
          ...current,
          selected: data?.target?.checked,
        };
      });
    });
  };

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  return (
    <div
      className="accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0 lg2:min-h-[8.75rem] last-auto"
      id={`EventAccordion`}
    >
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 overflow-auto scrollbar-none top-[14.625rem] opacity-0 h-0`}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left  md:min-w-[87.5rem] absolute left-0 top-0">
          <thead className="text-xs text-gray-400 ">
            <tr className="border-b bg-white">
              {/* <th
                className={`min-w-[2.5rem] w-[2.5rem] max-w-[2.5rem] h-[2.5rem] items-center justify-center text-center sticky left-0 p-0 z-10 no-scroll checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                id="checkbox_shad"
              >
                <div
                  className={`h-full flex items-center justify-center border-r w-10`}
                >
                  <input
                    type="checkbox"
                    checked={
                      data &&
                      size(data) > 0 &&
                      data?.every((item: any) => item?.selected)
                    }
                    readOnly
                    className={` w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 `}
                    onChange={(data) => handleSelectAll(data)}
                  />
                </div>
              </th> */}
              <HeaderCellWrapper data={data} />

              <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:min-w-[5rem] w-[5rem]">
                <div
                  className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white hidden"
                  id="left-shad-list"
                >
                  <button
                    className="group rounded-l  transition px-[.5625rem] py-2"
                    type="button"
                    onClick={(e: any) => scroll(-120)}
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                      path={angleLeftSVG}
                      svgClassName="w-1.5 h-[.5625rem]"
                    />
                  </button>
                  <button
                    className="group rounded-r  transition px-[.5625rem] py-2"
                    type="button"
                    onClick={(e: any) => scroll(120)}
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                      path={angleRightSVG}
                      svgClassName="w-1.5 h-[.5625rem]"
                    />
                  </button>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        {/* <EventFields /> */}
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
              ref={tableRef}
              id={`tableId1`}
              onScroll={handleScrollVirtuoso}
              // style={{
              //   height: `${
              //     virtuosoHeight() > height ? virtuosoHeight() : height
              //   }px`,
              // }}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              <TableVirtuoso
                data={data}
                endReached={() => {
                  if (paginateData?.current_page < paginateData?.last_page) {
                    setContentFilter((current: any) => {
                      let nextPage: number = paginateData?.current_page + 1;
                      return {
                        ...current,
                        page: nextPage,
                        per_page: 20,
                      };
                    });
                  }
                }}
                // ref={containerRef}
                useWindowScroll
                overscan={200}
                totalCount={data?.length}
                atBottomThreshold={100}
                id={"Report-lazyload"}
                components={{
                  Table: Table,
                  TableHead: TableHead,
                  TableRow: (props) => {
                    return (
                      <TableRow
                        {...props}
                        // hoveredId={hoveredId}
                        // setHoveredId={setHoveredId}
                        // showOrder={showOrder}
                      />
                    );
                  },
                }}
                // fixedFooterContent={() => {
                //   return (
                //     <tr className="border-b bg-white">
                //       <td colSpan={0}>Hi</td>
                //     </tr>
                //   );
                // }}
                fixedHeaderContent={() => (
                  <tr className="border-b bg-white">
                    <HeaderCellWrapper data={data} />

                    <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:min-w-[5rem] w-[5rem]"></th>
                    {globalLayout?.isMobile && (
                      <th className="sticky right-0 ml-auto p-0 icon_td min-w-[2.5rem] border-b md:hidden"></th>
                    )}
                  </tr>
                )}
                itemContent={(sIndex: any, list: any) => {
                  return (
                    <React.Fragment key={sIndex}>
                      <ItemContent
                        list={list}
                        sIndex={sIndex}
                        handleCheck={handleCheck}
                        handleDelete={(list: any, sIndex: any) => {
                          setIsDelete({
                            status: true,
                            data: {
                              list,
                              sIndex,
                            },
                          });
                        }}
                        handleView={handleView}
                      />
                    </React.Fragment>
                  );
                }}
              />
            </div>

            <div
              id="custom-loader"
              className={`accordion bg-white font-medium pt-2.5  border relative z-[1] `}
            >
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
              ></div>
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* LOADER */}
      {loading && <ProgressBardLoader secondCounter={1.5} />}
    </div>
  );
};

export default DataTable;
