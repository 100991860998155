type Category = {
  id?: string;
  componentType?: { name: string };
  name?: string;
  type?: string;
  type_id?: string;
  is_deleted?: boolean;
  menuId?: string;
  sub_menu?: Category[];
};

type CreateCategoryParams = {
  item: Category;
  parentId: string | undefined;
  position: number;
};

const createCategory = ({
  item,
  parentId,
  position,
}: CreateCategoryParams) => ({
  type: item?.componentType?.name || item?.type,
  ...(item?.menuId && { id: item.menuId }),
  ...(item?.is_deleted && { is_deleted: item.is_deleted }),
  type_id: item.id,
  parent_id: parentId,
  title: item.name,
  status: true,
  position,
});

const isValidCategory = (category: Category) =>
  (category.id && category.componentType?.name) ||
  (category.name && category.type);

const processCategories = (
  categories: Category[],
  parentId: string | undefined
): Category[] => {
  return categories.reduce<Category[]>((results, category, index) => {
    if (isValidCategory(category)) {
      results.push(
        createCategory({ item: category, parentId, position: index })
      );

      if (Array.isArray(category.sub_menu)) {
        const subCategories = processCategories(
          category.sub_menu,
          category.menuId
        );
        results.push(...subCategories);
      }
    }
    return results;
  }, []);
};

const modifyCategories = (
  categories: Category[],
  data: { id?: string }
): Category[] => {
  if (!Array.isArray(categories) || categories.length === 0) return [];
  return processCategories(categories, data.id);
};
export const mapEditRecordDataToFormData = (params: any) => {
  const { data, categories } = params;

  let allCategories = modifyCategories(categories, data);

  return {
    is_main_item: false,
    items: [{ ...data, parent_id: null }, ...allCategories],
  };
};

export const handleScrollTable = () => {
  const EventAccordion = document.getElementById(
    "EventAccordion"
  ) as HTMLElement;

  const topfilterTop: any = document.getElementById("topFilters");

  if (topfilterTop) {
    const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
    // if (topValue.includes("-")) {
    // Check if the value contains a negative sign
    const currentValue = parseInt(topValue); // Extract the numeric value
    const newValue = Math.abs(currentValue); // Make it positive
    topfilterTop && (topfilterTop.style.top = `${newValue}px`); // Apply the new positive value back as a string
    // }
    const windScroll: number = window.scrollY;

    const headingOne: any = document.getElementById("headingOne")?.clientHeight;
    const inventoryaccordion: any =
      document.getElementById("inventoryaccordion")?.clientHeight;
    // const totleScroll = headingOne + inventoryaccordion + 16;
    // const currentTop = parseInt(topfilterTop.style.top || "0");
    const topFiltersHeight = topfilterTop.clientHeight;
    let totleScroll = 0;
    if (window.matchMedia("(max-width: 767px)").matches) {
      totleScroll = topFiltersHeight;
    } else {
      totleScroll = headingOne + inventoryaccordion + 16;
    }

    const stickyHead: any = document.getElementById("sticky-head");
    const stickyShadow: any = document.getElementById("sticky-shadow");
    if (windScroll > totleScroll) {
      EventAccordion?.classList?.add("-mt-10");
      stickyHead?.classList.remove("opacity-0");
      stickyHead?.classList.remove("h-0");
      stickyHead?.classList.add("h-[2.5625rem]");
      stickyShadow.classList.add("!block");
    } else if (windScroll < totleScroll) {
      EventAccordion?.classList?.remove("-mt-10");
      stickyHead?.classList.add("opacity-0");
      stickyHead?.classList.add("h-0");
      stickyHead?.classList.remove("h-[2.5625rem]");
      stickyShadow.classList.remove("!block");
    }
  }
};
