import { useContext, useEffect, useState } from "react";
import backToTOp from "../../../assets/media/icons/other_icons/backtotop.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import { NotificationsContext } from "../core/NotificationsProvider";
import {
  getMarkAsPinned,
  getMarkAsRead,
  getMarkAsUnpinned,
} from "../core/requests";

const Footer = () => {
  const layout = useContext(NotificationsContext);
  const [loader, setLoader] = useState<boolean>();
  const [loaderPin, setLoaderPin] = useState<boolean>();
  const [loaderUnpin, setLoaderUnpin] = useState<boolean>();
  const coreLayout = useContext(LayoutContext);
  // Select All Events
  const handleSelectAll = (data: any) => {
    layout.setNotifications((orders: any) => {
      return orders.map((order: any) => {
        return {
          ...order,
          selected: data.target.checked,
        };
      });
    });
    layout.setSelectedNotifications(
      layout.notifications.map((notification: any) => {
        return notification.id;
      })
    );
  };
  let isSelect = layout?.notifications?.every((item: any) =>
    item.selected === true ? true : false
  );

  // Mark Notifications as Read
  const markAsRead = (data: any) => {
    if (layout.selectedNotifications.length > 0) {
      setLoader(true);
      getMarkAsRead(layout.selectedNotifications)
        .then((response) => {
          if (response.data.status === true) {
            layout.setRefetchNotification(layout.selectedNotifications);
            coreLayout?.setNewNotification(
              coreLayout?.newNotification ? coreLayout?.newNotification + 1 : 1
            );
            layout.setNotifications(
              layout.notifications.map((notification: any) => {
                if (notification.selected) {
                  notification.selected = false;
                  return {
                    ...notification,
                    is_new: false,
                  };
                } else {
                  return notification;
                }
              })
            );
            layout.setSelectedNotifications([]);
            setLoader(false);
          }
        })
        .catch((error) => {
        });
    } else {
      setLoader(true);
      getMarkAsRead(1).then((response: any) => {
        if (response?.data?.status) {
          setLoader(false);
          layout.setRefetchNotification("");
        }
      });
    }
  };

  //Mark Notifications as Pinned
  const markAsPinned = (data: any) => {
    if (layout.selectedNotifications.length > 0) {
      setLoaderPin(true);
      getMarkAsPinned(layout.selectedNotifications)
        .then((response) => {
          if (response.data.status === true) {
            setLoader(false);
            layout.setNotifications(
              layout.notifications.map((notification: any) => {
                if (notification.selected) {
                  notification.selected = false;
                  return {
                    ...notification,
                    is_pinned: true,
                  };
                } else {
                  return notification;
                }
              })
            );
            setLoaderPin(false);
          }
        })
        .catch((error) => {
        });
    }
  };

  // Mark Notifications as Unpinned
  const markAsUnpinned = (data: any) => {
    if (layout.selectedNotifications.length > 0) {
      setLoaderUnpin(true);
      getMarkAsUnpinned(layout.selectedNotifications)
        .then((response) => {
          if (response.data.status === true) {
            setLoader(false);
            layout.setNotifications(
              layout.notifications.map((notification: any) => {
                if (notification.selected) {
                  notification.selected = false;
                  return {
                    ...notification,
                    is_pinned: false,
                  };
                } else {
                  return notification;
                }
              })
            );
            setLoaderUnpin(false);
          }
        })
        .catch((error) => {
        });
    }
  };

  useEffect(() => {}, [layout.notifications]);

  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to show/hide the button based on scroll position
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    // Show the button when the user scrolls down 400px
    if (scrollPosition > 250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {layout.tabValue === "notification" && (
        <footer className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white shadow-3xl z-20 min-h-[3.125rem]">
          <div
            className={`flex w-full py-2 gap-x-4 gap-y-1.5 overflow-auto whitespace-nowrap`}
          >
            <div className="inline-flex items-center text-violet-800 transition font-medium rounded px-2.5 pr-1 py-0.5">
              <input
                id="default-checkbox"
                type="checkbox"
                checked={isSelect}
                readOnly
                className={`w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600`}
                onChange={(data) => handleSelectAll(data)}
              />
              <label
                htmlFor="default-checkbox"
                className={`ml-2 text-sm13 font-medium  dark:text-gray-300 text-gray-900`}
              >
                Select all
              </label>
            </div>
            <button
              type="button"
              className="group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
              onClick={(data) => markAsRead(data)}
              disabled={loader ? true : false}
            >
              {loader ? (
                <KTSVG
                  className="flex items-center justify-center fill-violet-500 transition animate-spin"
                  path="other_icons/process.svg"
                />
              ) : (
                <KTSVG
                  className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
                  path="standard_icons/check-circle.svg"
                />
              )}
              Mark as viewed
            </button>
            <button
              type="button"
              className="group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
              onClick={(data) => markAsPinned(data)}
              disabled={loader ? true : false}
            >
              {loaderPin ? (
                <KTSVG
                  className="flex items-center justify-center fill-violet-500 transition animate-spin"
                  path="other_icons/process.svg"
                />
              ) : (
                <KTSVG
                  className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
                  path="standard_icons/check-circle.svg"
                />
              )}
              Mark as pinned
            </button>
            <button
              type="button"
              className="group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
              onClick={(data) => markAsUnpinned(data)}
              disabled={loader ? true : false}
            >
              {loaderUnpin ? (
                <KTSVG
                  className="flex items-center justify-center fill-violet-500 transition animate-spin"
                  path="other_icons/process.svg"
                />
              ) : (
                <KTSVG
                  className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
                  path="standard_icons/check-circle.svg"
                />
              )}
              Mark as unpinned
            </button>
          </div>
        </footer>
      )}
      <button
        type="button"
        className={`bg-violet-550 hover:bg-indigo-500 rounded-l w-[1.875rem] h-[1.875rem] items-center justify-center flex fixed  right-0 transition duration-300 ${
          isVisible ? "opacity-100 visible" : "opacity-0 invisible"
        } ${
          layout.tabValue === "notification"
            ? " bottom-[4.0625rem] "
            : " bottom-[1rem] "
        } `}
        id="backtotop"
        onClick={scrollToTop}
      >
        <IKTSVG path={backToTOp} svgClassName="w-[.9375rem] h-[.9375rem]" />
      </button>
    </>
  );
};

export default Footer;
