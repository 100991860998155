import _, { isArray } from "lodash";
import React, { Fragment, useContext, useMemo } from "react";
import { deliveryTypeOrders, isMobile } from "../../../../helpers/const";
import { InventoryContext } from "../../core/InventoryProvider";
import TicketTypeExist from "../nonJSXComponent/TicketTypeExist";
import EventHeaderCell from "./EventHeaderCell";

type TheaderCellWrapper = {
  event: any;
  handleSortAction: () => void;
  SortingCurrentID: string;
  setSortingCurrentID: () => void;
};

const HeaderCellWrapper = ({
  event,
  handleSortAction,
  SortingCurrentID,
  setSortingCurrentID,
}: TheaderCellWrapper) => {
  const layout = useContext(InventoryContext);
  const { selectedDeliveryTypes, paperTypeExist } = TicketTypeExist(event);

  const getColumnItems = useMemo(() => {
    return layout.columnItems.filter(
      (columnItem: any) => columnItem.checked === true
    );
  }, [layout.columnItems]);

  return (
    <>
      {getColumnItems?.map((columnItem: any, index: number) => {
        const uniqueKey = `${event?.id}-${columnItem.id}-${index}`;
        switch (columnItem.id) {
          case 0:
            return (
              <Fragment key={`${uniqueKey}-listing-ID`}>
                <EventHeaderCell
                  title="Listing ID"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey="listing_id"
                  minWidth={`7.8125rem`}
                  mobWidth={`pl-[.9375rem]`}
                />
              </Fragment>
            );
          case 1:
            return (
              <Fragment key={`${uniqueKey}-team-name`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Team name"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey="team_name"
                  minWidth={`7.625rem`}
                />
              </Fragment>
            );
          case 2:
            return (
              <Fragment key={`${uniqueKey}-team-member`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Team member"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey="user_name"
                  minWidth={`7.625rem`}
                />
              </Fragment>
            );
          case 3:
            return (
              <Fragment key={`${uniqueKey}-ticket-type`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Ticket type"
                  arrow={true}
                  order={layout.orderState}
                  event={event}
                  postKey={"ticket_type"}
                  minWidth={`7.625rem`}
                  setSortingCurrentID={setSortingCurrentID}
                  SortingCurrentID={SortingCurrentID}
                />

                {paperTypeExist && (
                  <Fragment key={`${uniqueKey}-delivery-type`}>
                    <EventHeaderCell
                      handleSortAction={handleSortAction}
                      title="Delivery type"
                      arrow={false}
                      order={layout.orderState}
                      event={event}
                      postKey={"delivery_type"}
                      minWidth={`7.875rem`}
                    />
                  </Fragment>
                )}

                {paperTypeExist &&
                  isArray(selectedDeliveryTypes) &&
                  _.orderBy(selectedDeliveryTypes, deliveryTypeOrders, [
                    "asc",
                  ]).map((selected: any, selectedIndex: number) => {
                    return (
                      <React.Fragment
                        key={`${uniqueKey}-${selectedIndex}-delivery-options`}
                      >
                        <EventHeaderCell
                          selectedIndex={selectedIndex}
                          handleSortAction={handleSortAction}
                          title={selected}
                          order={layout.orderState}
                          arrow={false}
                          event={event}
                          postKey={selected}
                          minWidth={`7.625rem`}
                        />
                      </React.Fragment>
                    );
                  })}
              </Fragment>
            );
          case 5:
            return (
              <Fragment key={`${uniqueKey}-quantity`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Quantity"
                  arrow={true}
                  order={layout.orderState}
                  event={event}
                  postKey="quantity_available"
                  minWidth={`4.8125rem`}
                  setSortingCurrentID={setSortingCurrentID}
                  SortingCurrentID={SortingCurrentID}
                />
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Sold"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey="sold"
                  minWidth={`4.1875rem`}
                />
              </Fragment>
            );
          case 6:
            return (
              <Fragment key={`${uniqueKey}-split-type`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Split type"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey="split_type"
                  minWidth={`7.625rem`}
                />
              </Fragment>
            );
          case 7:
            return (
              <Fragment key={`${uniqueKey}-sell-in-multiple`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Sell in multiples"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey="split_quantity"
                  minWidth={`7.625rem`}
                />
              </Fragment>
            );
          case 8:
            return (
              <Fragment key={`${uniqueKey}-max-display`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Max display ..."
                  arrow={true}
                  order={layout.orderState}
                  event={event}
                  postKey="quantity_display"
                  minWidth={`7.625rem`}
                  setSortingCurrentID={setSortingCurrentID}
                  SortingCurrentID={SortingCurrentID}
                />
              </Fragment>
            );

          case 9:
            return (
              <Fragment key={`${uniqueKey}-category`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Category"
                  arrow={true}
                  order={layout.orderState}
                  event={event}
                  postKey="category"
                  minWidth={``}
                  mobWidth={`md:min-w-[13.25rem] min-w-[11.25rem]`}
                  setSortingCurrentID={setSortingCurrentID}
                  SortingCurrentID={SortingCurrentID}
                />
              </Fragment>
            );
          case 10:
            return (
              <Fragment key={`${uniqueKey}-sections`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Section/block"
                  arrow={true}
                  order={layout.orderState}
                  event={event}
                  postKey="section"
                  minWidth={``}
                  mobWidth={`md:min-w-[13.25rem] min-w-[11.25rem]`}
                  setSortingCurrentID={setSortingCurrentID}
                  SortingCurrentID={SortingCurrentID}
                />
              </Fragment>
            );
          case 11:
            return (
              <Fragment key={`${uniqueKey}-row`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Row"
                  arrow={true}
                  order={layout.orderState}
                  event={event}
                  postKey="row"
                  minWidth={`7.625rem`}
                  setSortingCurrentID={setSortingCurrentID}
                  SortingCurrentID={SortingCurrentID}
                />
              </Fragment>
            );
          case 12:
            return (
              <Fragment key={`${uniqueKey}-first-seat`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="First seat"
                  arrow={true}
                  order={layout.orderState}
                  event={event}
                  postKey="first_seat"
                  minWidth={`7.625rem`}
                  setSortingCurrentID={setSortingCurrentID}
                  SortingCurrentID={SortingCurrentID}
                />
              </Fragment>
            );
          case 13:
            return (
              <Fragment key={`${uniqueKey}-face-value`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Face value"
                  arrow={true}
                  order={layout.orderState}
                  event={event}
                  postKey="face_value"
                  minWidth={`7.625rem`}
                  setSortingCurrentID={setSortingCurrentID}
                  SortingCurrentID={SortingCurrentID}
                />
              </Fragment>
            );
          case 14:
            return (
              (!layout.proceedPriceToggle || isMobile) && (
                <Fragment key={`${uniqueKey}-proceed-price`}>
                  <EventHeaderCell
                    handleSortAction={handleSortAction}
                    title="Proceed price"
                    arrow={true}
                    order={layout.orderState}
                    event={event}
                    postKey="sell_price"
                    minWidth={`7.625rem`}
                    setSortingCurrentID={setSortingCurrentID}
                    SortingCurrentID={SortingCurrentID}
                  />
                </Fragment>
              )
            );
          case 15:
            return (
              <Fragment key={`${uniqueKey}-benefits`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Benefits"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey=""
                  minWidth={`13.25rem`}
                  mobWidth={`max-md:min-w-[8.75rem]`}
                />
              </Fragment>
            );
          case 16:
            return (
              <Fragment key={`${uniqueKey}-restrictions`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Restrictions"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey=""
                  minWidth={`13.25rem`}
                  mobWidth={`max-md:min-w-[8.75rem]`}
                />
              </Fragment>
            );

          case 17:
            return (
              <Fragment key={`${uniqueKey}-in-hand`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="In hand"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey=""
                  minWidth={`3.625rem`}
                />
              </Fragment>
            );
          case 18:
            return (
              <Fragment key={`${uniqueKey}-date-to-ship`}>
                <EventHeaderCell
                  handleSortAction={handleSortAction}
                  title="Date of ship"
                  arrow={false}
                  order={layout.orderState}
                  event={event}
                  postKey=""
                  minWidth={`8.25rem`}
                />
              </Fragment>
            );
        }
      })}
    </>
  );
};

export default HeaderCellWrapper;
