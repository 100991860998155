/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import _, { size } from "lodash";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import close from "../../../assets/media/icons/other_icons/Clear.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import Checkbox from "../../../components/formComponent/Checkbox";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import DropDownField from "../../../components/formComponent/DropDownField";
import MultiDropDownField from "../../../components/formComponent/MultiDropDownField";
import MultiSelect from "../../../components/formComponent/MultiSelect";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  handleSelectDeselectAll,
  removeSelectAll,
} from "../../../helpers/Functions";
import { QUERIES, SALES_FILTERS_ADMIN } from "../../../helpers/const";
import useScrollIntoViewOnFocus from "../../../hooks/useScrollIntoViewOnFocus";
import { algoliaSearch } from "../../Reports/core/requests";
import useDebounce from "../../add_inventory/core/useDebounce";
import { SalesContext } from "../core/SalesProvider";
import SalesFilterChip from "./SalesFilterChip";

const SalesFilter = ({
  query,
  options,
  handleQuery,
  paginateData,
  debouncedSearch,
  handleKeyDown,
  handleAlgoliaValue,
  teamsOptions,
  shimmerLoader,
}: any) => {
  const layout = useContext(SalesContext);
  const coreLayout = useContext(LayoutContext);
  const [paginateData1, setPaginateData] = useState(paginateData?.total);
  const [algoliaOptions, setAlgoliaOptions] = useState<any>();
  const [performerAlgoliaOptions, setPerformerAlgoliaOptions] = useState<any>(
    []
  );
  const [inputChange, setInputChange] = useState<any>();
  const [performerInput, setPerformerInput] = useState<string>("");
  const teamSelectedRef: any = useRef<any>();
  const teamMemberSelectedRef: any = useRef<any>();
  const marketPlaceSelectedRef: any = useRef<any>();
  const internalReplacementSelectedRef: any = useRef<any>();
  const categorySelectedRef: any = useRef<any>();
  const performerSelectedRef: any = useRef<any>();
  const storeSelectedRef: any = useRef<any>();
  const ticketTypeRef: any = useRef<any>();

  const algoliaDebounce = useDebounce(inputChange, 1200);
  const performerDebounce = useDebounce(performerInput, 600);
  const salesFilter = layout.salesFilterHandle;
  const access = coreLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );

  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;

  useScrollIntoViewOnFocus();

  // HANDLE INPUT CHANGE - ALGOLIA
  // const handleInputChange = useMemo(() => {
  //   return debounce((data, event) => {
  //     if (event.action === "menu-close" || event.action === "set-value") {
  //       if (!data) {
  //         setInputChange((current: any) => {
  //           return inputChange;
  //         });
  //       }
  //     } else {
  //       setInputChange((current: any) => {
  //         if (algoliaDebounce !== data) {
  //           // layout.setSalesFilterHandle((current: any) => {
  //           //   return {
  //           //     ...current,
  //           //     algoliaPage: "",
  //           //   };
  //           // });
  //           setAlgoliaOptions([]);
  //         }
  //         return data;
  //       });
  //     }
  //   });
  // }, [algoliaDebounce, inputChange]);
  const handleInputChange = (data: any, event: any) => {
    if (
      event.action === "menu-close" ||
      event.action === "set-value" ||
      event.action === "input-blur"
    ) {
      if (!data) {
        setInputChange((current: any) => {
          return current;
        });
      }
    } else {
      setInputChange((current: any) => {
        if (algoliaDebounce !== data) {
          setAlgoliaOptions([]);
        }
        return data;
      });
    }
  };

  // ALGOLIA API -
  const { isLoading, data } = useQuery(
    [`${QUERIES.ALGOLIA_SALES}`, algoliaDebounce, salesFilter],
    () => {
      if (inputChange && inputChange.trim() !== "") {
        salesFilter["module"] = "sales";
        salesFilter["tab"] = layout.tabValue;
        return algoliaSearch({ inputChange, salesFilter, coreLayout });
      }
      return Promise.resolve(); // Return an empty resolved promise when algoliaDebounce is falsy or an empty string
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      enabled: algoliaDebounce ? true : false,
      onError: (err) => {},
    }
  );

  let algoliaData = data;
  // LOAD ALGOLIA OPTIONS IN STATE
  useEffect(() => {
    if (data?.hits) {
      setAlgoliaOptions((current: any) => {
        return current?.length > 0
          ? _.uniqBy([...current, ...data?.hits], "id")
          : data?.hits;
      });
    }
  }, [data]);

  // CALL QUERY VALUE VIA API
  useEffect(() => {
    handleAlgoliaValue(algoliaDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algoliaDebounce]);

  // LOAD PERFORMER ALGOLIA
  const { isFetching: isPerformerLoading } = useQuery(
    [`${QUERIES.ALGOLIA_PERFORMERS}`, performerDebounce],
    () => {
      if (performerInput && performerInput.trim() !== "") {
        return algoliaSearch({
          inputChange: performerInput,
          searchIndex: "performers",
        });
      }
      return Promise.resolve(); // Return an empty resolved promise when algoliaDebounce is falsy or an empty string
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      enabled: performerDebounce && performerDebounce.length > 2 ? true : false,
      onError: (err) => {},
      onSettled: (data: any) => {
        if (data && data?.hits?.length > 0) {
          setPerformerAlgoliaOptions(data.hits);
        }
      },
    }
  );

  // RESET ALGOLIA OPTIONS
  useEffect(() => {
    if (_.size(layout.salesFilterHandle?.event_id) === 0) {
      // setAlgoliaOptions([]);
    }
  }, [layout.salesFilterHandle?.event_id]);

  useEffect(() => {
    return () => {
      coreLayout.setGlobalSales("");
    };
  }, []);

  const handleRefresh = () => {
    // inputRef.current.value = "";
    handleQuery("");
    setInputChange("");
    layout.setSalesFilterHandle((currentVal: any) => {
      return {
        team_member: [],
        event_id: [],
        team_id: [],
        page: 1,
      };
    });
  };

  // Function to get index of dragged Filter item
  const getFilterIndex = (id: any) => {
    let index = layout.draggedItems.findIndex((item: any) => item.id === id);
    return index + 1;
  };

  const getFilterValue = isGodAdmin
    ? layout.filterItems
    : layout.filterItems?.filter(
        (obj: any) => !SALES_FILTERS_ADMIN?.includes(obj?.name)
      );
  const getFilterIndexForCheckbox = (id: any) => {
    let index = getFilterValue?.findIndex((item: any) => item?.id === id);
    return index;
  };
  useEffect(() => {
    if (coreLayout.globalSales) {
      handleQuery(coreLayout.globalSales);
      setTimeout(() => {
        layout.setSalesFilterHandle((currentVal: any) => {
          return {
            team_member: [],
            page: 1,
          };
        });
      }, 1000);
    }
  }, [coreLayout.globalSales]);

  useEffect(() => {
    if (coreLayout.globalFulfilled) {
      layout.setTabValue("to_be_fulfilled");
      handleQuery(coreLayout.globalFulfilled);
      coreLayout.setGlobalFulfilled(undefined);
      layout.setSalesFilterHandle((currentVal: any) => {
        return {
          team_member: [],
          page: 1,
        };
      });
    }
  }, [coreLayout.globalFulfilled]);

  const onSearch = (data: any) => {
    if (data?.target?.id !== "search") {
      setInputChange("");
      setAlgoliaOptions([]);
    }
  };

  useEffect(() => {
    if (paginateData) {
      setPaginateData(paginateData?.total);
    }
  }, [paginateData]);

  // const handleSelectAll = (action: boolean) => {
  //   layout.setSalesFilterHandle((currentValue: any) => {
  //     return {
  //       ...currentValue,
  //       ["team_id"]: action
  //         ? teamsOptions.filter(
  //             (option: any) => option.name !== "Select All | De-select All"
  //           )
  //         : [],
  //     };
  //   });
  // };

  // HANDLE SELECT-DESELECT FOT TEAMS
  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any = null
  ) => {
    if (teamSelectedRef && teamSelectedRef?.current) {
      teamSelectedRef.current.blur();
    }
    if (teamMemberSelectedRef && teamMemberSelectedRef?.current) {
      teamMemberSelectedRef.current.blur();
    }
    if (storeSelectedRef && storeSelectedRef?.current) {
      storeSelectedRef.current.blur();
    }
    if (marketPlaceSelectedRef && marketPlaceSelectedRef?.current) {
      marketPlaceSelectedRef.current.blur();
    }
    if (
      internalReplacementSelectedRef &&
      internalReplacementSelectedRef?.current
    ) {
      internalReplacementSelectedRef.current.blur();
    }
    if (categorySelectedRef && categorySelectedRef?.current) {
      categorySelectedRef.current.blur();
    }
    if (performerSelectedRef && performerSelectedRef?.current) {
      performerSelectedRef.current.blur();
    }
    if (ticketTypeRef && ticketTypeRef?.current) {
      ticketTypeRef.current.blur();
    }

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      layout.setSalesFilterHandle
    );
  };

  const handleOnChange = (data: any) => {
    layout.setSalesFilterHandle((currentValue: any) => {
      return {
        ...currentValue,
        [data?.target?.name]: data?.target?.value,
        page: 1,
      };
    });
    layout.setIsSelectAll(false);
  };

  function ToggleElemClose() {
    let submenuWrapper: any = document.querySelector("#filtersMob");
    let eventAccordion: any = document.querySelector("#EventAccordion");
    if (eventAccordion) eventAccordion.classList.remove("hidden");

    submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  }
  function ToggleElemOpen() {
    let submenuWrapper: any = document.querySelector("#filtersMob");
    let eventAccordion: any = document.querySelector("#EventAccordion");
    submenuWrapper.classList.add("activeFIlter");

    eventAccordion.classList.add("hidden");
    document.body.classList.add("overflow-hidden");
  }

  const isTicketTypeExist =
    layout.salesFilterHandle?.ticket_type &&
    size(layout.salesFilterHandle?.ticket_type) > 0 &&
    Object.values(layout.salesFilterHandle?.ticket_type)?.some(
      (item: any) => item
    );

  const clearDisable =
    (layout.salesFilterHandle?.team_member &&
      layout.salesFilterHandle?.team_member.length > 0) ||
    (query !== undefined && debouncedSearch) ||
    (layout.salesFilterHandle?.event_id &&
      layout.salesFilterHandle?.event_id?.length > 0) ||
    layout.salesFilterHandle?.time_to_event ||
    (layout.salesFilterHandle?.deliveryDateRange &&
      layout.salesFilterHandle?.deliveryDateRange?.startDate !== undefined &&
      layout.salesFilterHandle?.deliveryDateRange?.endDate !== undefined) ||
    (layout.salesFilterHandle?.event_date &&
      layout.salesFilterHandle?.event_date?.startDate !== undefined &&
      layout.salesFilterHandle?.event_date?.endDate !== undefined) ||
    (layout.salesFilterHandle?.orderDateRange &&
      layout.salesFilterHandle?.orderDateRange?.startDate !== undefined &&
      layout.salesFilterHandle?.orderDateRange?.endDate !== undefined) ||
    (layout.salesFilterHandle?.order_exchanges &&
      layout.salesFilterHandle?.order_exchanges.length > 0) ||
    layout?.salesFilterHandle?.team_id?.length > 0 ||
    layout?.salesFilterHandle?.internal_order_statuses?.length > 0 ||
    layout?.salesFilterHandle?.category_ids?.length > 0 ||
    layout?.salesFilterHandle?.store_id ||
    layout.salesFilterHandle?.ticket_in_hand ||
    isTicketTypeExist;

  const handleEventSearch = (): void => {
    const isMobileView = window.matchMedia("(max-width: 768px)").matches;
    if (inputChange && isMobileView) {
      layout?.setQuery(inputChange);
    }
  };

  useEffect(() => {
    if (!performerInput) {
      setPerformerAlgoliaOptions(layout.salesFilterHandle?.performer_ids || []);
    }
  }, [layout.salesFilterHandle?.performer_ids, performerInput]);

  return (
    <>
      <div className="filtersMob max-md:flex max-md:flex-col" id={"filtersMob"}>
        <div
          className="flex flex-wrap md:border-b border-t gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
          id={`filters_block`}
        >
          <div
            className="closePopup hidden max-md:flex"
            id="closePopup"
            onClick={ToggleElemClose}
          >
            <IKTSVG
              path={close}
              className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
              svgClassName="w-4 h-4"
            />
          </div>
          <div
            className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%]  px-[.3125rem] z-[3]`}
          >
            {/* SEARCH BAR */}
            <div
              className={`relative rounded flex-1 max-w-full md:mb-0 ${
                shimmerLoader && "shimmer-effect"
              }`}
            >
              {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <button type="button">
                <IKTSVG
                  className="fill-violet-500"
                  svgClassName="h-3.5 w-3.5"
                  path={searchVioletSVG}
                />
              </button>
            </div> */}
              <div
                className="w-full  "
                title={
                  isGodAdmin
                    ? "Search event or order ID or marketplace ID"
                    : "Search event or order ID"
                }
              >
                <MultiSelect
                  name="event_id"
                  options={algoliaOptions}
                  isLoading={isLoading}
                  handleOnChange={(data: any, e: any) => {
                    layout.setSalesFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        ...(e?.action !== "pop-value"
                          ? { [data?.target?.name]: data?.target?.value }
                          : {}),
                        page: 1,
                      };
                    });
                    layout.setIsSelectAll(false);
                  }}
                  onMenuScrollToBottom={(data: any) => {
                    layout.setSalesFilterHandle((currentValue: any) => {
                      let currentPage = currentValue?.algoliaPage + 1;
                      if (algoliaData?.nbPages - 1 >= currentPage) {
                        return {
                          ...currentValue,
                          ...(currentValue?.algoliaPage
                            ? { algoliaPage: currentValue?.algoliaPage + 1 }
                            : { algoliaPage: 1 }),
                        };
                      } else {
                        return { ...currentValue };
                      }
                    });
                  }}
                  onClick={(event: any) => {
                    handleKeyDown(event);
                  }}
                  handleKeyDown={(event: any) => {
                    handleKeyDown(event);
                  }}
                  value={layout.salesFilterHandle?.event_id}
                  inputValue={inputChange}
                  handleInputChange={handleInputChange}
                  onSearch={(data: any) => onSearch(data)}
                  classNamePrefix="inventory_select"
                  className="searchbar-wrap"
                  placeholder={
                    isGodAdmin
                      ? "Search event or order ID or marketplace ID"
                      : "Search event or order ID"
                  }
                />
              </div>
            </div>
          </div>

          {/* Team */}
          {isGodAdmin &&
            getFilterValue?.[`${getFilterIndexForCheckbox(1)}`]?.checked && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                  1
                )} `}
              >
                <div className={`rounded ${shimmerLoader && "shimmer-effect"}`}>
                  <MultiDropDownField
                    isCapital={true}
                    selectedRef={teamSelectedRef}
                    isValueCapital={true}
                    options={teamsOptions}
                    classNamePrefix={"form_multiDropDown"}
                    placeholder={"Teams"}
                    isClearable={true}
                    isMultiSelection={true}
                    name="team_id"
                    handleOnChange={(data: any, actionMeta: any) => {
                      // actionMeta?.option?.name !== "Select All | De-select All" &&
                      layout.setSalesFilterHandle((currentValue: any) => {
                        return {
                          ...currentValue,
                          [data?.target?.name]: data?.target?.value,
                          page: 1,
                        };
                      });
                      layout.setIsSelectAll(false);

                      if (
                        _.size(data?.target?.value) === 0 &&
                        _.size(layout.salesFilterHandle?.team_member) > 0
                      ) {
                        layout.setSalesFilterHandle((currentValue: any) => {
                          return {
                            ...currentValue,
                            team_member: "",
                            page: 1,
                          };
                        });
                      }
                    }}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "team_id",
                        teamsOptions,
                        visibleOptions
                      )
                    }
                    value={layout.salesFilterHandle?.team_id}
                    label="Teams"
                    isMenuPortalTarget={shimmerLoader ? false : true}
                  />
                </div>
              </div>
            )}

          {/* TEAM MEMBER */}
          {!userSpecific &&
            getFilterValue?.[`${getFilterIndexForCheckbox(2)}`]?.checked && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                  2
                )} `}
              >
                <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                  <MultiDropDownField
                    name="team_member"
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Team members"
                    selectedRef={teamMemberSelectedRef}
                    isMultiSelection={true}
                    value={layout.salesFilterHandle?.team_member}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "team_member",
                        options?.team_members,
                        visibleOptions
                      )
                    }
                    handleOnChange={(data: any, actionMeta: any) => {
                      handleOnChange(data);
                    }}
                    options={options?.team_members}
                    isMenuPortalTarget={shimmerLoader ? false : true}
                  />
                </div>
              </div>
            )}

          {/* TICKET TYPE */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(3)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                3
              )}  `}
            >
              <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                <MultiDropDownField
                  options={options?.ticket_type}
                  placeholder="Ticket type"
                  // name="ticket_type"
                  name="ticket_type_dropdown"
                  classNamePrefix={"form_multiDropDown"}
                  // value={layout.salesFilterHandle?.ticket_type}
                  value={layout.salesFilterHandle?.ticket_type_dropdown}
                  isClearable={true}
                  selectedRef={ticketTypeRef}
                  handleOnChange={(data: any) =>
                    layout.setSalesFilterHandle((currentValue: any) => {
                      let final = {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                      };

                      let ticketData = _.map(final?.ticket_type_dropdown, "id");
                      if (ticketData.length > 0) {
                        let ticketTypeObject: any = {};
                        ticketData.forEach((ticketType) => {
                          ticketTypeObject[ticketType] = true;
                        });

                        final = {
                          ...final,
                          ticket_type: ticketTypeObject,
                        };
                      } else {
                        final = {
                          ...final,
                          ticket_type: {},
                        };
                      }

                      return final;
                    })
                  }
                  handleOnSelection={(data: any, visibleOptions: any) => {
                    if (data?.target?.id === "select-all") {
                      layout.setSalesFilterHandle((currentValue: any) => {
                        return {
                          ...currentValue,
                          ticket_type_dropdown:
                            visibleOptions && size(visibleOptions) > 0
                              ? visibleOptions
                              : [...options?.ticket_type],
                          ticket_type: [
                            ...(visibleOptions ?? options?.ticket_type),
                          ].reduce((acc: any, curr: any) => {
                            if (curr.id) {
                              acc[curr.id] = true;
                            }
                            return acc;
                          }, {}),
                          page: 1,
                        };
                      });
                    } else if (data?.target?.id === "deselect-all") {
                      layout.setSalesFilterHandle((currentValue: any) => {
                        const ticketTypeObject = options?.ticket_type.reduce(
                          (acc: any, curr: any) => {
                            if (curr.id) {
                              acc[curr.id] = visibleOptions.some(
                                (option: any) => option.id === curr.id
                              )
                                ? false
                                : true;
                            }
                            return acc;
                          },
                          {}
                        );

                        return {
                          ...currentValue,
                          ticket_type_dropdown: removeSelectAll(
                            currentValue?.ticket_type_dropdown,
                            visibleOptions
                          ),
                          ticket_type: ticketTypeObject,
                          page: 1,
                        };
                      });
                    }
                  }}
                />
              </div>
            </div>
          )}

          {/* TICKET IN HAND */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(4)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                4
              )} `}
            >
              <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                <Checkbox
                  label="Tickets in hand"
                  id="ticket_in_hand"
                  name="ticket_in_hand"
                  labelclass="text-gray-500"
                  checked={layout.salesFilterHandle?.ticket_in_hand}
                  tooltip={false}
                  handleOnChange={(data: any, e: any) => {
                    layout.setSalesFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.checked,
                      };
                    });
                  }}
                />
              </div>
            </div>
          )}

          {/* ORDER BY DATE */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(5)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                5
              )} `}
            >
              <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                <DatePickerField
                  name="orderDateRange"
                  placeholder="Order date"
                  disabledKeyboardNavigation={false}
                  value={
                    layout?.salesFilterHandle?.orderDateRange
                      ? layout?.salesFilterHandle?.orderDateRange
                      : undefined
                  }
                  isDateRange={true}
                  handleOnChange={(data: any) => {
                    handleOnChange(data);
                  }}
                  format="dd/MM/yy"
                />
              </div>
            </div>
          )}
          {/*  DELIVER BY DATE */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(6)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                6
              )} `}
            >
              <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                <DatePickerField
                  name="deliveryDateRange"
                  placeholder="Deliver by date"
                  disabledKeyboardNavigation={false}
                  value={
                    layout?.salesFilterHandle?.deliveryDateRange
                      ? layout?.salesFilterHandle?.deliveryDateRange
                      : undefined
                  }
                  isDateRange={true}
                  handleOnChange={(data: any) => {
                    handleOnChange(data);
                  }}
                  format="dd/MM/yy"
                />
              </div>
            </div>
          )}
          {/* EVENT DATE */}

          {getFilterValue?.[`${getFilterIndexForCheckbox(7)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                7
              )} `}
            >
              <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                <DatePickerField
                  name="event_date"
                  placeholder="Event date"
                  disabledKeyboardNavigation={false}
                  value={
                    layout?.salesFilterHandle?.event_date
                      ? layout?.salesFilterHandle?.event_date
                      : undefined
                  }
                  isDateRange={true}
                  handleOnChange={(data: any) => {
                    handleOnChange(data);
                  }}
                  format="dd/MM/yy"
                />
              </div>
            </div>
          )}

          {/* EVENT DATE */}

          {/* DAYS TO EVENT */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(8)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                8
              )}  `}
            >
              <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                <DropDownField
                  options={options?.time_to_event}
                  placeholder="Days to event"
                  name="time_to_event"
                  getOptionLabel="label"
                  value={layout.salesFilterHandle?.time_to_event}
                  isClearable={true}
                  getOptionValue="value"
                  handleOnChange={(data: any) => {
                    handleOnChange(data);
                  }}
                />
              </div>
            </div>
          )}

          {/* MARKETPLACE */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(9)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                9
              )}`}
            >
              <div className={`rounded ${shimmerLoader && "shimmer-effect"}`}>
                <MultiDropDownField
                  name="order_exchanges"
                  classNamePrefix={"form_multiDropDown"}
                  placeholder="Marketplaces"
                  selectedRef={marketPlaceSelectedRef}
                  value={layout.salesFilterHandle?.order_exchanges}
                  handleOnChange={(data: any) => {
                    handleOnChange(data);
                  }}
                  handleOnSelection={(data: any, visibleOptions: any) =>
                    handleOnSelection(
                      data,
                      "order_exchanges",
                      options?.order_exchanges,
                      visibleOptions
                    )
                  }
                  isMultiSelection={true}
                  options={options?.order_exchanges}
                  isClearable={true}
                  isMenuPortalTarget={shimmerLoader ? false : true}
                />
              </div>
            </div>
          )}

          {/* INTERNAL ORDER STATUS */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(10)}`]?.checked &&
            isGodAdmin && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                  10
                )} `}
              >
                <div className={`rounded ${shimmerLoader && "shimmer-effect"}`}>
                  <MultiDropDownField
                    name="internal_order_statuses"
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Internal order status"
                    value={layout.salesFilterHandle?.internal_order_statuses}
                    handleOnChange={(data: any) => handleOnChange(data)}
                    options={options?.internal_order_statuses}
                    isClearable={true}
                    isMenuPortalTarget={shimmerLoader ? false : true}
                    selectedRef={internalReplacementSelectedRef}
                    isMultiSelection={true}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "internal_order_statuses",
                        options?.internal_order_statuses,
                        visibleOptions
                      )
                    }
                  />
                </div>
              </div>
            )}

          {/* STORES FILTER */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(11)}`]?.checked &&
            isGodAdmin && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                  11
                )} `}
              >
                <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                  <Checkbox
                    label="Include webstores"
                    id="store_id"
                    name="store_id"
                    labelclass="text-gray-500"
                    checked={layout.salesFilterHandle?.store_id}
                    tooltip={false}
                    handleOnChange={(data: any, e: any) => {
                      layout.setSalesFilterHandle((currentValue: any) => {
                        return {
                          ...currentValue,
                          [data?.target?.name]: data?.target?.checked,
                        };
                      });
                    }}
                  />
                </div>
              </div>
            )}

          {/* CATEGORY - DROPDOWN */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(12)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                12
              )} `}
            >
              <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                <MultiDropDownField
                  name="category_ids"
                  classNamePrefix={"form_multiDropDown"}
                  placeholder="Category"
                  value={layout.salesFilterHandle?.category_ids}
                  handleOnChange={(data: any) => handleOnChange(data)}
                  options={options?.categories}
                  isClearable={true}
                  //isMenuPortalTarget={shimmerLoader ? false : true}
                  selectedRef={categorySelectedRef}
                  isMultiSelection={true}
                  handleOnSelection={(data: any, visibleOptions: any) => {
                    handleOnSelection(
                      data,
                      "category_ids",
                      options?.categories,
                      visibleOptions
                    );
                  }}
                />
              </div>
            </div>
          )}

          {/* PERFORMER - DROPDOWN */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(13)}`]?.checked &&
            isGodAdmin && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                  13
                )} `}
              >
                <div className={`${shimmerLoader && "shimmer-effect"} rounded`}>
                  <MultiDropDownField
                    name="performer_ids"
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Performer"
                    value={layout.salesFilterHandle?.performer_ids}
                    isLoading={isPerformerLoading}
                    handleInputChangeCallback={(input: string) => {
                      setPerformerInput(input);
                    }}
                    noOptionsMessageText={
                      !performerInput
                        ? "Search performer"
                        : performerInput.length <= 2
                        ? "Minimum 3 Characters"
                        : "No Options"
                    }
                    handleOnChange={(data: any) => handleOnChange(data)}
                    options={performerAlgoliaOptions}
                    isClearable={true}
                    //isMenuPortalTarget={shimmerLoader ? false : true}
                    selectedRef={performerSelectedRef}
                    isMultiSelection={true}
                    handleOnSelection={(data: any, visibleOptions: any) => {
                      handleOnSelection(
                        data,
                        "performer_ids",
                        performerAlgoliaOptions,
                        visibleOptions
                      );
                    }}
                  />
                </div>
              </div>
            )}

          {/* ORDER STATUS */}
          <span className="order-1 order-2 order-3 order-4 order-5 order-6"></span>
        </div>
        <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
          <button
            className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
              clearDisable
                ? "bg-white text-violet-800 border-gray-300"
                : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
            }`}
            onClick={handleRefresh}
          >
            Clear
          </button>

          <button
            className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
            onClick={() => {
              ToggleElemClose();
              handleEventSearch();
            }}
          >
            Apply
          </button>
        </div>
      </div>

      {/* THIRD LAYE - FILTER CHIP */}
      <SalesFilterChip
        shimmerLoader={shimmerLoader}
        paginateData={paginateData}
        paginateData1={paginateData1}
        query={query}
        debouncedSearch={debouncedSearch}
        handleQuery={handleQuery}
        setInputChange={setInputChange}
        ToggleElemOpen={ToggleElemOpen}
      />
    </>
  );
};

export default memo(SalesFilter);
