import NoDataComponent from "components/tableComponent/NoDataComponent";
import { QUERIES } from "helpers/const";
import _ from "lodash";
import { getContentListings } from "pages/customize/core/_.request";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import AddNewRecordForContent from "pages/customize/widget/AddNewRecordForContent";
import { useQuery } from "react-query";
import DataTable from "../DataTable";

const TableWrapper = () => {
  const { contentFilter, setContentList, contentList, setContentCount } =
    useCustomize();

  const { isLoading, refetch, data } = useQuery(
    [`${QUERIES.CONTENT_LIST}`, contentFilter],
    () => getContentListings(contentFilter),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled(data, error) {
        if (data?.data?.length > 0) {
          setContentList((prev: any) => {
            let updated;

            if (data.meta?.current_page === 1) {
              updated = data.data;
            } else {
              updated = _.uniqBy(
                [...(Array.isArray(prev) ? prev : []), ...data.data],
                "id"
              );
            }
            return updated;
          });
        } else {
          setContentList([]);
        }
        setContentCount(data?.meta);
      },
    }
  );

  return (
    <>
      {isLoading && contentFilter?.page === 1
        ? Array.from({ length: 10 }, (v, i) => (
            <div
              className={`accordion font-medium  mb-2.5 mx-5 ${
                i === 0 && "mt-0"
              } `}
            >
              <div
                key={i}
                className={`accordion-item bg-white !rounded overflow-hidden ${
                  isLoading && "shimmer-effect"
                } `}
                style={{ height: "40px" }}
              ></div>
            </div>
          ))
        : contentList?.length > 0 && (
            <DataTable
              paginateData={data?.meta}
              loading={isLoading}
              refetch={refetch}
            />
          )}

      {contentList?.length === 0 && !isLoading && (
        <>
          <NoDataComponent />
        </>
      )}
      <AddNewRecordForContent refetch={refetch} />
    </>
  );
};

export default TableWrapper;
