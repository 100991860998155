/* eslint-disable jsx-a11y/aria-role */
import { useContext, useState } from "react";

import CloneSVG from "../../../../assets/media/icons/other_icons/Clone.svg";
import CrossSVG from "../../../../assets/media/icons/standard_icons/cross.svg";
import Warning from "../../../../assets/media/icons/standard_icons/warning.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { copy } from "../../../add_inventory/core/_functions";

const BankDetailPopup = ({ showOrder }: any) => {
  const layout = useContext(LayoutContext);
  const txTradeData = layout?.allowedAccess?.fundin_account;
  const [copied, setCopied] = useState<any>();

  const CopyComponent = ({ label, id, value }: any) => {
    return (
      <>
        <div className="text-gray-500 text-sm12 mb-1">{label}</div>
        <div className="relative">
          <input
            type="text"
            value={value}
            className="text-sm12 rounded bg-gray-100 w-full border-none px-2 py-0 block text-violet-800"
            disabled
            id={id}
          />
          <button
            className="flex items-center justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
            type="button"
            onClick={(e: any) => {
              setCopied(copy(value));
              setTimeout(() => {
                setCopied("");
              }, 700);
            }}
          >
            {value && (
              <IKTSVG
                className={`flex items-center justify-center transition ${
                  copied === value
                    ? "fill-green-600  hover:fill-green-600"
                    : "fill-violet-500 hover:fill-indigo-500"
                }`}
                path={CloneSVG}
                svgClassName="w-[.7813rem] h-[.6563rem]"
              />
            )}
          </button>
        </div>
      </>
    );
  };
  return (
    <div
      data-te-modal-init
      className="fixed left-0 top-[3.75rem] z-[2000] hidden h-[calc(100dvh-3.9375rem)] w-full overflow-y-auto overflow-x-hidden outline-none"
      id="topup"
      tabIndex={-1}
      aria-labelledby="topupTitle"
      aria-modal="true"
      role="modal"
    >
      <div
        data-te-modal-dialog-ref
        className="pointer-events-none relative flex min-h-[calc(100%-2rem)] w-auto items-center opacity-0 transition-all duration-300 ease-in-out mx-auto my-5 min-[576px]:min-h-[calc(100%-3.5rem)] max-w-[478px]"
      >
        <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div className="modal-header flex justify-between border-b">
            <h5 className="modal-title mb-0 leading-normal text-sm14 font-semibold py-2.5 px-5">
              Top up your TX Pay account
            </h5>
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-te-modal-dismiss
            >
              <IKTSVG
                className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path={CrossSVG}
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>

          <div className="modal-body relative">
            <div className="top-part p-5 border-b">
              <h3 className="text-sm13 font-semibold mb-5 leading-4">
                Top up by bank transfer from your funding account. Payments can
                take up to 24 hours to appear in your TX Pay account.
              </h3>

              <h2 className="text-sm14 font-semibold leading-4 mb-4">TX Pay</h2>

              {/* <div className="mb-5 error-field mt-[.9375rem] flex items-center flex-wrap text-sm12 font-medium py-[7px] px-2 rounded bg-rose-500 bg-opacity-[7%] text-rose-500">
                <IKTSVG
                  path={Warning}
                  className="fill-rose-500 transition w-4  h-4 flex items-center justify-center mr-2"
                  svgClassName="w-4 h-4"
                />
                <span className="flex-1">
                  Please add{" "}
                  <b>
                    {layout?.allowedAccess?.fundin_account?.txpay_wallet_id}
                  </b>{" "}
                  to the references / remarks when making the bank transfer.
                  This will ensure the money is transferred to your TxPay
                  wallet.
                </span>
              </div> */}

              <div className="fields-wrap">
                <div className="w-full mb-[.9375rem]">
                  <CopyComponent
                    id="acc_holder_name"
                    label="Account name"
                    value={layout?.allowedAccess?.seller_fundin_account?.name}
                  />
                </div>
                <div className="w-full mb-[.9375rem]">
                  <CopyComponent
                    id="acc_holder_number"
                    label="IBAN"
                    value={layout?.allowedAccess?.seller_fundin_account?.iban}
                  />
                </div>
                <div className="w-full mb-[.9375rem]">
                  <CopyComponent
                    id="swift"
                    label="Swift"
                    value={
                      layout?.allowedAccess?.seller_fundin_account?.swift_code
                    }
                  />
                </div>
                <div className="w-full mb-[.9375rem]">
                  <CopyComponent
                    id="reference"
                    label="Reference"
                    value={
                      layout?.allowedAccess?.fundin_account?.txpay_wallet_id
                    }
                  />
                </div>
                <div className="error-field mt-[.9375rem] flex items-center flex-wrap text-sm12 font-medium py-[7px] px-2 rounded bg-rose-500 bg-opacity-[7%] text-rose-500">
                  <IKTSVG
                    path={Warning}
                    className="fill-rose-500 transition w-4 h-4 flex items-center justify-center mr-2"
                    svgClassName="w-4 h-4"
                  />
                  <span className="flex-1">
                    Please include the Reference when making the bank transfer.
                    This ensures your funds are transferred directly to your TX
                    Pay wallet.
                  </span>
                </div>
              </div>
            </div>
            <div className="funding-block p-5">
              <h2 className="text-sm14 font-semibold leading-4 mb-4">
                Funding account
              </h2>
              <div className="add-block text-sm12 font-medium">
                <span className="block">{txTradeData?.bank_name}</span>
                <span className="block">
                  {txTradeData?.account_holder_name}
                </span>
                <span className="block text-gray-500">
                  {txTradeData?.ac_number
                    ? txTradeData?.ac_number
                    : txTradeData?.iban
                    ? txTradeData?.iban
                    : "00000000"}
                </span>
                {/* <span className="block">
                  <a href="#" className="text-indigo-500 hover:underline">
                    Change account
                  </a>
                </span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetailPopup;
