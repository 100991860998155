import { useScrollLeftEffect } from "hooks/useScrollLeftEffect";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import { TableVirtuoso } from "react-virtuoso";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { ConnectorContext } from "../core/ConnectorProvider";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  md:min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTable = ({ data, paginateData }: any) => {
  // VARIABLES AND STATES
  const layout = useContext(ConnectorContext);
  const tableRef: any = useRef();
  const stickyTableRef: any = useRef();
  const { setIsSettled }: any = useScrollLeftEffect({
    elementId: "tableId1",
    headerId: "sticky-head",
  });

  const scroll = (scrollOffset: any) => {
    setIsSettled(true);
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  // const getFilterIndexForCheckbox = (id: any) => {
  //   let index = layout.columnItems.findIndex((item: any) => item.id === id);
  //   return index;
  // };

  // CALCULATE THE HEIGHT OF TABLE VIRTOUSO
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [virtHeight, setVirtHeight] = useState<any>();
  const [hoveredId, setHoveredId] = useState(null);

  // HANDLE ORDER VIEW PAGE
  const handleOrderView = (list: any, index: number) => {
    // reset page of event list on view
    layout.setEventsFilters({ page: 1 });

    if (
      list?.order_id &&
      layout?.connectorViewState?.data?.order_id &&
      list?.order_id === layout?.connectorViewState?.data?.order_id
    ) {
      // display(false);
      layout?.setConnectorViewState({
        status: false,
        data: "",
      });
    } else {
      layout?.setConnectorViewState({
        status: true,
        data: list,
      });
    }
  };

  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#Report-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      if (divElement.offsetHeight > 1) {
        let elemHeight = divElement.offsetHeight + theadHeight;
        const height = virtHeight > elemHeight ? virtHeight : elemHeight;

        if (nomoreListingHeight) {
          elemHeight =
            divElement.offsetHeight + theadHeight + nomoreListingHeight + 1;
        }
        setHeight(`${elemHeight}`);
      }
    }, 800);
  };
  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  return (
    <>
      {/* DEFINE YOUR TABLE HERE */}
      <div
        className="accordion mb-4 font-medium transition-all relative px-5 max-md:px-0"
        id={`EventAccordion`}
      >
        <div
          id="sticky-head"
          className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none`}
          ref={stickyTableRef}
          onScroll={handleScrollHead}
        >
          <table className="text-xs text-left  min-w-[87.5rem] absolute left-0 top-0 w-full">
            <thead className="text-xs text-gray-400 ">
              <tr className="border-b bg-white">
                <HeaderCell
                  title="Name"
                  width="w-[12rem]"
                  arrow={true}
                  postKey="name"
                  padding="pl-5"
                />
                <HeaderCell
                  title="Status"
                  width="w-[12rem]"
                  arrow={false}
                  postKey="username"
                  padding="pl-5"
                />
                <HeaderCell
                  title="URL"
                  // width="min-w-[6.875rem]"
                  arrow={false}
                  postKey="order_id"
                  padding="pl-5"
                />

                <th className="sticky right-0 ml-auto  p-0 w-[5.0625rem]">
                  <div
                    className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
                    id="left-shad-list"
                  >
                    <button
                      className="group rounded-l  hover: transition px-[.5625rem] py-2"
                      id="leftArrow"
                      type="button"
                      onClick={(e: any) => scroll(-120)}
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={angleLeftSVG}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                    <button
                      className="group rounded-r  transition px-[.5625rem] py-2"
                      id="rightArrow"
                      type="button"
                      onClick={(e: any) => scroll(120)}
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={angleRightSVG}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
        <div className="accordion-item bg-white  !rounded overflow-hidden">
          {/* <EventFields /> */}
          <div
            id="EventCollapseBody"
            className="accordion-collapse collapse show"
            aria-labelledby="EventCollapseBody"
            data-te-collapse-show
          >
            <div className="accordion-body relative">
              <div
                className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
                ref={tableRef}
                id={`tableId1`}
                onScroll={handleScrollVirtuoso}
                style={{ height: height > 41 ? `${height}px` : "auto" }}
              >
                <TableVirtuoso
                  data={data}
                  endReached={() => {
                    if (paginateData?.current_page < paginateData?.last_page) {
                      layout.setConnectorFilter((current: any) => {
                        let nextPage: number = paginateData?.current_page + 1;
                        return {
                          ...current,
                          page: nextPage,
                          per_page: 20,
                        };
                      });
                    }
                  }}
                  useWindowScroll
                  overscan={200}
                  totalCount={data?.length}
                  atBottomThreshold={100}
                  id={"Report-lazyload"}
                  components={{
                    Table: Table,
                    TableHead: TableHead,
                    TableRow: (props) => {
                      return (
                        <TableRow
                          {...props}
                          hoveredId={hoveredId}
                          setHoveredId={setHoveredId}
                          showOrder={layout?.connectorViewState}
                        />
                      );
                    },
                    TableFoot: (props) => {
                      if (
                        data?.length === paginateData?.total &&
                        paginateData?.current_page > 1
                      ) {
                        return (
                          <tfoot
                            className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                            id={`NoMoreListing`}
                          >
                            <tr className="border-b bg-white">
                              <td
                                colSpan={11}
                                align="center"
                                className="h-[2.8125rem]"
                              >
                                No more items to show
                              </td>
                            </tr>
                          </tfoot>
                        );
                      } else {
                        return null;
                      }
                    },
                  }}
                  fixedFooterContent={() => {
                    return (
                      <tr className="border-b bg-white">
                        <td colSpan={0}>Hi</td>
                      </tr>
                    );
                  }}
                  fixedHeaderContent={() => (
                    <tr className="border-b bg-white">
                      <HeaderCell
                        title="Name"
                        width="w-[12rem]"
                        arrow={true}
                        postKey="name"
                        padding="pl-5"
                      />
                      <HeaderCell
                        title="Status"
                        width="w-[12rem]"
                        arrow={false}
                        postKey="status"
                        padding="pl-5"
                      />
                      <HeaderCell
                        title="URL"
                        // width="min-w-[6.875rem]"
                        arrow={false}
                        postKey="url"
                        padding="pl-5"
                      />

                      <th className="sticky right-0 ml-auto  p-0 w-[5.0625rem]">
                        <div
                          className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
                          id="left-shad-list"
                        >
                          <button
                            className="group rounded-l  hover: transition px-[.5625rem] py-2"
                            id="leftArrow"
                            type="button"
                            onClick={(e: any) => scroll(-120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={angleLeftSVG}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                          <button
                            className="group rounded-r  transition px-[.5625rem] py-2"
                            id="rightArrow"
                            type="button"
                            onClick={(e: any) => scroll(120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={angleRightSVG}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                        </div>
                      </th>
                    </tr>
                  )}
                  itemContent={(sIndex: any, list: any) => {
                    const matchedView =
                      layout?.connectorViewState?.status &&
                      layout?.connectorViewState?.data &&
                      layout?.connectorViewState?.data?.order_id ===
                        list.order_id;
                    return (
                      <React.Fragment key={sIndex}>
                        {/* CHECKBOX */}
                        <DataCell
                          value={list?.name}
                          classname="block"
                          padding="pl-5"
                          width="w-[12rem]"
                        />

                        <DataCell
                          value={list?.status}
                          classname="block"
                          padding="pl-5"
                          width="w-[12rem]"
                        />
                        <DataCell
                          value={list?.url}
                          classname="block"
                          padding="pl-5"
                          noCapitalize="yes"
                          // width="w-[7.75rem]"
                        />
                        <td className="group sticky right-0 ml-auto p-0 icon_td  border-b">
                          <React.Fragment key={sIndex}>
                            <div
                              className={`${
                                matchedView
                                  ? "bg-indigo-500 bg-opacity-[7%]"
                                  : ""
                              } `}
                            >
                              <div
                                className="flex left-shad relative w-full h-full border-l"
                                id="left-shad-list"
                              >
                                {list?.is_legacy_order == "true" && (
                                  <button className="absolute top-1/2 -translate-y-1/2 right-full text-white text-xxs bg-violet-500 px-[.4375rem] py-1 leading-3 rounded-l whitespace-nowrap cursor-default">
                                    Legacy order
                                  </button>
                                )}

                                <button
                                  className={`group flex items-center justify-center p-1 md:p-1 w-full 
                              `}
                                  type="button"
                                  id={`record-view-tooltip${sIndex}`}
                                  onClick={() => {
                                    handleOrderView(list, sIndex);
                                  }}
                                >
                                  <Tooltip
                                    anchorId={`record-view-tooltip${sIndex}`}
                                    content={`${
                                      layout?.connectorViewState?.data
                                        ?.order_id === hoveredId
                                        ? "Close"
                                        : "View"
                                    }`}
                                    place="top"
                                    variant="light"
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                  />
                                  <IKTSVG
                                    className={`flex items-center justify-center transition group-hover:fill-indigo-500 ${
                                      matchedView
                                        ? "fill-indigo-500"
                                        : "fill-violet-500"
                                    }`}
                                    path={eyeSVG}
                                    svgClassName="w-4 h-[.6875rem]"
                                  />
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </td>
                        {/* </tr> */}
                      </React.Fragment>
                    );
                  }}
                />
              </div>
            </div>

            <div
              id="custom-loader"
              className={`accordion bg-white font-medium pt-2.5 border-t `}
            >
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div>
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div>
              {/* <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div> */}
            </div>
          </div>
        </div>

        {/* CONNECTOR VIEW */}
        {/* {connectorViewState?.status && (
          <ConnectorView
            data={connectorViewState}
            handleClosePopup={() => {
              setConnectorViewState(initialConnectorViewState);
              layout.setHeaders([INITIAL_HEADERS]);
              layout.setSideOption("Variables");
            }}
          />
        )} */}
      </div>
    </>
  );
};

export default DataTable;
