import React, { forwardRef, useContext } from "react";
import angleLeftSVG from "../../../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../../../assets/media/icons/standard_icons/angle_right.svg";
import { LayoutContext } from "../../../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import { PersonalDetailsContext } from "../../core/PersonalDetailsProvider";
import HeaderCell from "./HeaderCell";

const TableHeaderRow = forwardRef((ref: any, addtionalRef: any) => {
  const layout = useContext(PersonalDetailsContext);
  const globalLayout = useContext(LayoutContext);

  //  HEADER GET COLUMNS
  const getColumnItems = () => {
    return layout.columnItems?.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  const getFilterIndexForCheckbox = (id: any) => {
    let index = layout.columnItems.findIndex((item: any) => item.id === id);
    return index;
  };

  // HANDLE SCROLL
  const scroll = (scrollOffset: any) => {
    if (addtionalRef?.tableRef && ref?.stickyTableRef) {
      addtionalRef.tableRef.current.scrollLeft += scrollOffset;
      ref.stickyTableRef.current.scrollLeft += scrollOffset;
    } else {
      ref.stickyTableRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <tr className="border-b bg-white">
      {getColumnItems().map((columnItem: any, index: number) => {
        switch (columnItem.id) {
          case 0:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(0)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[10.375rem] min-w-[10.375rem]"
                    arrow={true}
                    postKey="name"
                    padding="pl-5"
                  />
                </React.Fragment>
              )
            );
          case 1:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(1)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[7.5rem] min-w-[7.5rem]"
                    arrow={true}
                    postKey="firstname"
                  />
                </React.Fragment>
              )
            );
          case 2:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(2)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[7.5rem] min-w-[7.5rem]"
                    arrow={true}
                    postKey="lastname"
                  />
                </React.Fragment>
              )
            );
          case 3:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(3)}`].checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[7.5rem] min-w-[7.5rem]"
                    arrow={true}
                    postKey="package"
                  />
                </React.Fragment>
              )
            );
          case 4:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(4)}`].checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[11.25rem] min-w-[11.25rem]"
                    arrow={true}
                    postKey="status"
                  />
                </React.Fragment>
              )
            );
          case 5:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(5)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[8.75rem] min-w-[8.75rem]"
                    arrow={true}
                    postKey="secondary_user"
                  />
                </React.Fragment>
              )
            );
          case 6:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(6)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[8.75rem] min-w-[8.75rem]"
                    arrow={true}
                    postKey="created_at"
                  />
                </React.Fragment>
              )
            );
          case 7:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(7)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[8.75rem] min-w-[8.75rem]"
                    arrow={true}
                    postKey="last_login"
                  />
                </React.Fragment>
              )
            );
          case 8:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(8)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[8.75rem] min-w-[8.75rem]"
                    arrow={true}
                    postKey="risk_level"
                  />
                </React.Fragment>
              )
            );
          case 9:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(9)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    arrow={false}
                    width="w-[8.75rem] min-w-[8.75rem]"
                    postKey="tx_trade_user"
                  />
                </React.Fragment>
              )
            );
          case 10:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(10)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    arrow={false}
                    width="w-[8.75rem] min-w-[8.75rem]"
                    postKey="tx_trade_user"
                  />
                </React.Fragment>
              )
            );
        }
      })}
      <th className="md:sticky relative right-0 ml-auto  p-0 md:w-[13.0625rem] md:max-w-[13.0625rem] md:min-w-[13.0625rem] w-[8rem] max-w-[8rem] min-w-[8rem]">
        <div
          className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:pointer-events-none"
          id="left-shad-list"
        >
          <button
            className="group rounded-l  hover: transition px-[.5625rem] py-2 max-md:opacity-0"
            id="leftArrow"
            type="button"
            onClick={(e: any) => scroll(-120)}
          >
            <IKTSVG
              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
              path={angleLeftSVG}
              svgClassName="w-1.5 h-[.5625rem]"
            />
          </button>
          <button
            className="group rounded-r  transition px-[.5625rem] py-2 max-md:opacity-0"
            id="rightArrow"
            type="button"
            onClick={(e: any) => scroll(120)}
          >
            <IKTSVG
              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
              path={angleRightSVG}
              svgClassName="w-1.5 h-[.5625rem]"
            />
          </button>
        </div>
      </th>
      {globalLayout?.isMobile && (
        <th className="sticky right-0 ml-auto  p-0 w-10 min-w-[2.5rem]">
          <div
            className="py-[.4375rem] px-4 flex justify-end h-[2.5rem]  bg-white max-md:pointer-events-none"
            id=""
          ></div>
        </th>
      )}
    </tr>
  );
});

export default TableHeaderRow;
