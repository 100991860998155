const HaxPublishGray = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 18.48" {...props}>
      <g
        id="Group_3161"
        data-name="Group 3161"
        transform="translate(-1506 -10.76)"
      >
        <path
          id="Path_47908"
          data-name="Path 47908"
          d="M8,0,0,4.62v9.24l8,4.62,8-4.62V4.62Z"
          transform="translate(1506 10.76)"
        />
        <path
          id="Path_172"
          data-name="Path 172"
          d="M31,422.407l2.605,3.039,5.645-6.513"
          transform="translate(1478.875 -402.266)"
          fill="none"
          stroke="#fff"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default HaxPublishGray;
