import { Tooltip } from "react-tooltip";
import CloneSVG from "../../../assets/media/icons/other_icons/Clone.svg";
import checkSVG from "../../../assets/media/icons/other_icons/check.svg";
import { default as remove } from "../../../assets/media/icons/standard_icons/cross.svg";
import Warning1 from "../../../assets/media/icons/standard_icons/warning1.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";

const MobileUrlLink = ({
  isOpen,
  eventIndex,
  eventRecordIndex,
  handleMobileLinkChange,
  setIsCopied,
  copy,
  isCopied,
  eventRecord,
}: any) => {
  return (
    <div className="px-1.5 col-span-full w-full border-t border-solid ">
      {isOpen?.event?.[eventIndex]?.eventRecords?.[
        eventRecordIndex
      ]?.ticketFiles?.map((ticketFile: any, ticketFileIndex: any) => {
        const errorExistInFileAndroid = eventRecord?.separate_links
          ? !ticketFile?.isValidAndroid
          : !ticketFile?.isValid;
        const errorExistInFileIos = !ticketFile?.isValidIos;
        const clearBtnVisibleForAndroid = eventRecord?.separate_links
          ? ticketFile?.mobileLink?.android && ticketFile?.alreadySave !== 1
          : ticketFile?.mobileLink && ticketFile?.alreadySave !== 1;

        const copyBtnExistForAndroid = eventRecord?.separate_links
          ? ticketFile?.isValidAndroid !== undefined &&
            ticketFile?.mobileLink?.android &&
            ticketFile?.isValidAndroid
          : ticketFile?.mobileLink && ticketFile?.isValid;

        return (
          <>
            <div
              className={`flex px-2 pb-2.5  ${
                ticketFileIndex === 0 ? "pt-2.5" : ""
              } `}
            >
              <div
                className={`con_transform w-full  pb-0 text-sm12  border-violet-900 flex flex-wrap items-center relative`}
              >
                <span className="mr-2 text-sm12">
                  Ticket {ticketFileIndex + 1}
                </span>
                <div className="flex-1 w-full flex gap-2">
                  <div
                    className={` relative ${
                      eventRecord?.separate_links ? "w-1/2" : "w-full"
                    } `}
                  >
                    <div className="relative  w-full">
                      {/* INPUT BOX */}
                      <input
                        id={`mobile-link-${eventRecordIndex}-${ticketFileIndex}`}
                        type="text"
                        value={
                          eventRecord?.separate_links
                            ? ticketFile?.mobileLink?.android
                              ? ticketFile?.mobileLink?.android
                              : ""
                            : ticketFile?.mobileLink
                            ? ticketFile?.mobileLink
                            : ""
                        }
                        placeholder={`Enter ${
                          eventRecord?.separate_links ? "Android " : ""
                        }URL link here`}
                        className={`truncate h-6 ${
                          ticketFile?.isValid ? "pr-[3rem]" : "pr-[1.8rem]"
                        } ${
                          ticketFile?.alreadySave === 1 && "pointer-events-none"
                        } w-full text-sm13 font-medium py-0 border-gray-300 rounded  focus:bg-violet-300/50 focus:border-violet-400  mr-1 ${
                          ticketFile?.errors?.length > 0 ||
                          (ticketFile.hasOwnProperty("isValid") &&
                            errorExistInFileAndroid)
                            ? "border-rose-500"
                            : (ticketFile?.isValid || ticketFile?.isFull) &&
                              "border-green-500"
                        }`}
                        title={
                          ticketFile.hasOwnProperty("isValid") &&
                          errorExistInFileAndroid
                            ? "Invalid URL"
                            : ""
                        }
                        onChange={(e: any) =>
                          handleMobileLinkChange(
                            eventIndex,
                            eventRecordIndex,
                            e,
                            false,
                            false,
                            ticketFileIndex,
                            "android"
                          )
                        }
                      />

                      {/* LINK ERROR */}
                      {ticketFile.hasOwnProperty("isValid") &&
                        errorExistInFileAndroid && (
                          <>
                            <Tooltip
                              anchorId={`mobile-link-${eventIndex}-${eventRecordIndex}error`}
                              content={`Invalid URL`}
                              place="top"
                              variant="light"
                              className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
                            />
                            <div
                              id={`mobile-link-${eventIndex}-${eventRecordIndex}error`}
                              className="absolute top-1/2 -translate-y-1/2 right-6 cursor-default"
                            >
                              <IKTSVG
                                className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                path={Warning1}
                              />
                            </div>
                          </>
                        )}
                    </div>

                    {/* COPY BUTTON */}

                    <span
                      key={ticketFileIndex}
                      className={`flex items-center gap-x-2 absolute right-6 top-1/2 -translate-y-1/2 ${
                        !copyBtnExistForAndroid && "hidden"
                      }`}
                    >
                      <button
                        type="button"
                        id={`mobile-link-text-copy-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                        onClick={(e: any) => {
                          let copiedValues = copy(
                            eventRecord?.separate_links
                              ? ticketFile?.mobileLink?.android
                              : ticketFile?.mobileLink
                              ? ticketFile?.mobileLink
                              : ""
                          );
                          setIsCopied({
                            id: `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}-android`,
                            value: copiedValues,
                          });
                          setTimeout(() => {
                            setIsCopied({
                              id: "",
                              value: "",
                            });
                          }, 200);
                        }}
                      >
                        <Tooltip
                          anchorId={`mobile-link-text-copy-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                          content="Copy URL"
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                        />
                        <IKTSVG
                          className={`flex items-center justify-center transition ${
                            isCopied.value ===
                              (ticketFile?.mobileLink?.android ||
                                ticketFile?.mobileLink) &&
                            isCopied.id ===
                              `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}-android`
                              ? "fill-green-600  hover:fill-green-600"
                              : "fill-violet-500 hover:fill-indigo-500"
                          }`}
                          path={CloneSVG}
                          svgClassName="w-3"
                        />
                      </button>
                    </span>

                    {/* CLEAR BUTTON */}
                    {clearBtnVisibleForAndroid && (
                      <span
                        key={ticketFileIndex}
                        className="flex items-center gap-x-2 absolute right-2 top-1/2 -translate-y-1/2"
                      >
                        <button
                          type="button"
                          id={`mobile-link-text-remove-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                          onClick={(e: any) => {
                            handleMobileLinkChange(
                              eventIndex,
                              eventRecordIndex,
                              e,
                              false,
                              true,
                              ticketFileIndex,
                              "android"
                            );
                          }}
                        >
                          <Tooltip
                            anchorId={`mobile-link-text-remove-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                            content="Clear"
                            place="top"
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                          />
                          <IKTSVG
                            className="fill-violet-500 hover:fill-indigo-500 transition"
                            svgClassName="w-2.5"
                            path={remove}
                          />
                        </button>
                      </span>
                    )}
                  </div>
                  {eventRecord?.separate_links && (
                    <div className="w-1/2 relative">
                      <div className="relative w-full ">
                        {/* INPUT BOX - separatelink */}
                        <input
                          id={`mobile-link-${eventRecordIndex}-${ticketFileIndex}-separate`}
                          type="text"
                          value={
                            ticketFile?.mobileLink?.ios
                              ? ticketFile?.mobileLink?.ios
                              : ""
                          }
                          placeholder={`Enter iOS URL link here`}
                          className={`truncate h-6 ${
                            ticketFile?.isValid ? "pr-[3rem]" : "pr-[1.8rem]"
                          } ${
                            ticketFile?.mobileLink?.ios &&
                            ticketFile?.alreadySave === 1 &&
                            "pointer-events-none"
                          } w-full text-sm13 font-medium py-0 border-gray-300 rounded  focus:bg-violet-300/50 focus:border-violet-400  mr-1 ${
                            ticketFile?.errors?.length > 0 ||
                            (ticketFile.hasOwnProperty("isValid") &&
                              !ticketFile?.isValidIos)
                              ? "border-rose-500"
                              : (ticketFile?.isValid || ticketFile?.isFull) &&
                                "border-green-500"
                          }`}
                          title={
                            ticketFile.hasOwnProperty("isValid") &&
                            !ticketFile?.isValidIos
                              ? "Invalid URL"
                              : ""
                          }
                          onChange={(e: any) =>
                            handleMobileLinkChange(
                              eventIndex,
                              eventRecordIndex,
                              e,
                              false,
                              false,
                              ticketFileIndex,
                              "ios"
                            )
                          }
                        />

                        {/* LINK ERROR */}
                        {ticketFile.hasOwnProperty("isValid") &&
                          errorExistInFileIos && (
                            <>
                              <Tooltip
                                anchorId={`mobile-link-${eventIndex}-${eventRecordIndex}error-separate`}
                                content={`Invalid URL`}
                                place="top"
                                variant="light"
                                className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
                              />
                              <div
                                id={`mobile-link-${eventIndex}-${eventRecordIndex}error-separate`}
                                className="absolute top-1/2 -translate-y-1/2 right-6 cursor-default"
                              >
                                <IKTSVG
                                  className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                  path={Warning1}
                                />
                              </div>
                            </>
                          )}
                      </div>

                      {/* COPY BUTTON */}

                      <span
                        key={ticketFileIndex}
                        className={`flex items-center gap-x-2 absolute right-6 top-1/2 -translate-y-1/2  ${
                          !(
                            ticketFile?.mobileLink?.ios &&
                            ticketFile?.isValidIos
                          ) && "hidden"
                        }`}
                      >
                        <button
                          type="button"
                          id={`mobile-link-text-copy-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}-separate`}
                          onClick={(e: any) => {
                            let copiedValues = copy(
                              ticketFile?.mobileLink?.ios
                                ? ticketFile?.mobileLink?.ios
                                : ""
                            );
                            setIsCopied({
                              id: `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`,
                              value: copiedValues,
                            });
                            setTimeout(() => {
                              setIsCopied({
                                id: "",
                                value: "",
                              });
                            }, 200);
                          }}
                        >
                          <Tooltip
                            anchorId={`mobile-link-text-copy-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}-separate`}
                            content="Copy URL"
                            place="top"
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                          />
                          <IKTSVG
                            className={`flex items-center justify-center transition ${
                              isCopied.value === ticketFile?.mobileLink?.ios &&
                              isCopied.id ===
                                `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`
                                ? "fill-green-600  hover:fill-green-600"
                                : "fill-violet-500 hover:fill-indigo-500"
                            }`}
                            svgClassName="w-3"
                            path={CloneSVG}
                          />
                        </button>
                      </span>

                      {/* CLEAR BUTTON */}
                      {ticketFile?.mobileLink?.ios &&
                        ticketFile?.alreadySave !== 1 && (
                          <span
                            key={ticketFileIndex}
                            className="flex items-center gap-x-2 absolute right-2 top-1/2 -translate-y-1/2"
                          >
                            <button
                              type="button"
                              id={`mobile-link-text-remove-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}-separate`}
                              onClick={(e: any) => {
                                handleMobileLinkChange(
                                  eventIndex,
                                  eventRecordIndex,
                                  e,
                                  false,
                                  true,
                                  ticketFileIndex,
                                  "ios"
                                );
                              }}
                            >
                              <Tooltip
                                anchorId={`mobile-link-text-remove-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}-separate`}
                                content="Clear"
                                place="top"
                                variant="light"
                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                              />
                              <IKTSVG
                                className="fill-violet-500 hover:fill-indigo-500 transition"
                                svgClassName="w-2.5"
                                path={remove}
                              />
                            </button>
                          </span>
                        )}
                    </div>
                  )}
                </div>
              </div>
              {/* CONFIRM BUTTON */}

              {!ticketFile?.isValid && (
                <button
                  type="button"
                  id={`mobile-link-text-confirm-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                  className={`${
                    (eventRecord?.separate_links
                      ? !ticketFile?.mobileLink?.ios ||
                        !ticketFile?.mobileLink?.android
                      : !ticketFile?.mobileLink) &&
                    "pointer-events-none !bg-gray-100 border-none"
                  } ${
                    ticketFile?.mobileLink
                      ? "bg-violet-500  hover:bg-indigo-500 border border-violet-500 "
                      : "bg-white border border-gray-300"
                  } group flex mx-1 items-center justify-center h-6 w-6  hover:border-indigo-500 text-white hover:bg-indigo-500 transition rounded mr-0`}
                  onClick={(e: any) => {
                    handleMobileLinkChange(
                      eventIndex,
                      eventRecordIndex,
                      e,
                      true,
                      false,
                      ticketFileIndex
                    );
                  }}
                >
                  <IKTSVG
                    path={checkSVG}
                    className={` group-hover:fill-white [&_svg]:w-3 transition ${
                      (
                        eventRecord?.separate_links
                          ? !ticketFile?.mobileLink?.ios ||
                            !ticketFile?.mobileLink?.android
                          : !ticketFile?.mobileLink
                      )
                        ? "fill-gray-400"
                        : "fill-violet-500 fill-white"
                    }`}
                  />
                  <Tooltip
                    anchorId={`mobile-link-text-confirm-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                    content="Confirm"
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                </button>
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default MobileUrlLink;
