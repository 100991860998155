/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef } from "react";
// import { Footer, Select } from "flowbite-react";

import Inventory from "../../assets/media/icons/menu_icons/Inventory.svg";
import Overview from "../../assets/media/icons/menu_icons/Overview.svg";
import Settings from "../../assets/media/icons/menu_icons/Settings.svg";
import faq from "../../assets/media/icons/menu_icons/faq.svg";
import salesNew from "../../assets/media/icons/menu_icons/salesNew.svg";
import technicalSupport from "../../assets/media/icons/menu_icons/technicalSupport.svg";
import txPayNew from "../../assets/media/icons/menu_icons/txPayNew.svg";

import PageTitle from "../../components/core/PageTitle";
import { IKTSVG } from "../../components/ui/IKTSVG";
import { KTSVG } from "../../components/ui/KTSVG";

const FaqStatic = () => {
  const canvasRef: any = useRef();

  // const ref = useRef(null);
  const display = () => {
    var pop_width =
      document.getElementsByClassName("buy_canvas")[0].clientWidth;
    let back = document.getElementsByClassName("offcanvas-backdrop");
    if (canvasRef.current.classList.contains("show")) {
      // @ts-ignore
      document.getElementById(
        "table-shrink"
      ).style.marginRight = `${pop_width}px`;
    } else if (!canvasRef.current.classList.contains("show")) {
      // @ts-ignore
      document.getElementById("table-shrink").style.marginRight = "0px";
    }
  };

  return (
    <>
      <PageTitle title="FAQ Centre" />

      <div className="main-content-wrap">
        <div className="flex flex-wrap">
          <div className="sidebar lg2:max-w-[15.0625rem] border-r p-5 w-full bg-white lg2:min-h-[calc(100vh-3.9375rem)] lg2:relative lg2:top-0 sticky top-[3.75rem] z-20">
            <div
              className="group lg2:hidden block mob_sb_menu relative  [&[aria-expanded='true'] .dd_arrow]:rotate-180"
              data-te-collapse-init
              data-te-collapse-collapsed
              data-te-target="#collapseOne5"
              aria-expanded="false"
              aria-controls="collapseOne5"
            >
              <span className="block w-full border rounded px-[.9375rem] py-2.5 text-sm13 font-medium">
                Overview
              </span>
              <span className="dd_arrow absolute right-3 top-1/2 -translate-y-1/2 group-[[data-te-collapse-collapsed]]:rotate-0 rotate-180 transition">
                <span className="w-2.5 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-gray-400">
                  <KTSVG
                    className="w-2.5 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-gray-400"
                    path="other_icons/down-angle.svg"
                    svgClassName="w-2.5 h-2.5"
                  />
                </span>
              </span>
            </div>
            <div
              className="sb_menus lg2:!h-auto lg2:sticky lg2:top-20 lg2:overflow-auto lg2:block hidden absolute left-0 w-full top-full lg2:p-0 px-5 z-20 bg-white"
              id="collapseOne5"
              data-te-collapse-item
              data-te-collapse-collapsed
              aria-labelledby="headingOne5"
            >
              <div className="group sb_item p-2 cursor-pointer bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500 text-indigo-500"
                  href="#"
                >
                  <div className="item_icon w-6 h-6 group-hover:bg-indigo-500 bg-indigo-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={Overview}
                      />
                    </span>
                  </div>
                  Overview
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="item_icon w-6 h-6 group-hover:bg-indigo-500 bg-violet-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={Settings}
                      />
                    </span>
                  </div>
                  Account & settings
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="item_icon w-6 h-6 group-hover:bg-indigo-500 bg-violet-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={Inventory}
                      />
                    </span>
                  </div>
                  Listings
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="item_icon w-6 h-6 group-hover:bg-indigo-500 bg-violet-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={salesNew}
                      />
                    </span>
                  </div>
                  Sales
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="item_icon w-6 h-6 group-hover:bg-indigo-500 bg-violet-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={txPayNew}
                      />
                    </span>
                  </div>
                  Payments
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="item_icon w-6 h-6 group-hover:bg-indigo-500 bg-violet-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={technicalSupport}
                      />
                    </span>
                  </div>
                  Technical support
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="item_icon w-6 h-6 group-hover:bg-indigo-500 bg-violet-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={faq}
                      />
                    </span>
                  </div>
                  Miscellaneous
                </a>
              </div>
            </div>
          </div>

          <div className="sb_content lg2:max-w-[calc(100%-15.0625rem)] w-full bg-no-repeat bg-overviewpattern bg-cover bg-center bg-violet-300">
            <div className="w-full flex justify-center py-4 px-5 bg-white/40">
              <div className="relative flex-1 max-w-[27rem] md:mb-0 mb-2">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <button>
                    <KTSVG
                      className="fill-violet-500"
                      svgClassName="h-3.5 w-3.5"
                      path="other_icons/search-violet.svg"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="border font-medium bg-white border-indigo-500/20 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-8 pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                  placeholder="Search FAQs"
                  required
                />
              </div>
            </div>
            <div className="p-5 ">
              <div className="flex flex-wrap -mx-2.5">
                <div className="single-item lg:w-1/3 px-2.5 mb-5 w-full group cursor-pointer">
                  <div className="lg:py-14 py-6 xl:px-[3.75rem] px-5 h-full bg-white rounded shadow-op1 text-center">
                    <div className="mx-auto mb-5 item_icon w-[4.375rem] h-[4.375rem] group-hover:bg-indigo-500 transition-all bg-violet-500 flex items-center justify-center rounded-xl">
                      <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                        <IKTSVG
                          svgClassName="fill-white h-6 w-7 center"
                          path={Settings}
                        />
                      </span>
                    </div>
                    <h3 className="md:text-2xl text-xl mb-5">
                      Account & settings
                    </h3>
                    <p className="text-[.8125rem] text-gray-600">
                      Everything you need to know about managing your account
                      and settings
                    </p>
                  </div>
                </div>

                <div className="single-item lg:w-1/3 px-2.5 mb-5 w-full group cursor-pointer">
                  <div className="lg:py-14 py-6 xl:px-[3.75rem] px-5 h-full bg-white rounded shadow-op1 text-center">
                    <div className="mx-auto mb-5 item_icon w-[4.375rem] h-[4.375rem] group-hover:bg-indigo-500 transition-all bg-violet-500 flex items-center justify-center rounded-xl">
                      <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                        <IKTSVG
                          svgClassName="fill-white h-6 w-7 center"
                          path={Inventory}
                        />
                      </span>
                    </div>
                    <h3 className="md:text-2xl text-xl mb-5">Listings</h3>
                    <p className="text-[.8125rem] text-gray-600">
                      Get help creating and managing listings across all
                      Tixstock resellers and exchanges
                    </p>
                  </div>
                </div>

                <div className="single-item lg:w-1/3 px-2.5 mb-5 w-full group cursor-pointer">
                  <div className="lg:py-14 py-6 xl:px-[3.75rem] px-5 h-full bg-white rounded shadow-op1 text-center">
                    <div className="mx-auto mb-5 item_icon w-[4.375rem] h-[4.375rem] group-hover:bg-indigo-500 transition-all bg-violet-500 flex items-center justify-center rounded-xl">
                      <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                        <IKTSVG
                          svgClassName="fill-white h-6 w-7 center"
                          path={salesNew}
                        />
                      </span>
                    </div>
                    <h3 className="md:text-2xl text-xl mb-5">Sales</h3>
                    <p className="text-[.8125rem] text-gray-600">
                      Get help managing sales and fulfilment across all Tixstock
                      resellers and exchanges
                    </p>
                  </div>
                </div>

                <div className="single-item lg:w-1/3 px-2.5 mb-5 w-full group cursor-pointer">
                  <div className="lg:py-14 py-6 xl:px-[3.75rem] px-5 h-full bg-white rounded shadow-op1 text-center">
                    <div className="mx-auto mb-5 item_icon w-[4.375rem] h-[4.375rem] group-hover:bg-indigo-500 transition-all bg-violet-500 flex items-center justify-center rounded-xl">
                      <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                        <IKTSVG
                          svgClassName="fill-white h-6 w-7 center"
                          path={txPayNew}
                        />
                      </span>
                    </div>
                    <h3 className="md:text-2xl text-xl mb-5">Payments</h3>
                    <p className="text-[.8125rem] text-gray-600">
                      Common payment related queries
                    </p>
                  </div>
                </div>

                <div className="single-item lg:w-1/3 px-2.5 mb-5 w-full group cursor-pointer">
                  <div className="lg:py-14 py-6 xl:px-[3.75rem] px-5 h-full bg-white rounded shadow-op1 text-center">
                    <div className="mx-auto mb-5 item_icon w-[4.375rem] h-[4.375rem] group-hover:bg-indigo-500 transition-all bg-violet-500 flex items-center justify-center rounded-xl">
                      <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                        <IKTSVG
                          svgClassName="fill-white h-6 w-7 center"
                          path={technicalSupport}
                        />
                      </span>
                    </div>
                    <h3 className="md:text-2xl text-xl mb-5">
                      Technical support
                    </h3>
                    <p className="text-[.8125rem] text-gray-600">
                      Info and technical support for portal related issues
                    </p>
                  </div>
                </div>

                <div className="single-item lg:w-1/3 px-2.5 mb-5 w-full group cursor-pointer">
                  <div className="lg:py-14 py-6 xl:px-[3.75rem] px-5 h-full bg-white rounded shadow-op1 text-center">
                    <div className="mx-auto mb-5 item_icon w-[4.375rem] h-[4.375rem] group-hover:bg-indigo-500 transition-all bg-violet-500 flex items-center justify-center rounded-xl">
                      <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                        <IKTSVG
                          svgClassName="fill-white h-6 w-7 center"
                          path={faq}
                        />
                      </span>
                    </div>
                    <h3 className="md:text-2xl text-xl mb-5">Miscellaneous</h3>
                    <p className="text-[.8125rem] text-gray-600">
                      Can’t find what you’re looking for? Maybe we have it
                      answered here
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};
export default FaqStatic;
