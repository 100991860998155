import { useContext } from "react";
import CheckboxFilter from "../../../tx_trade/component/filters/CheckboxFilter";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";

const CountBoxWrapper = ({ loading }: any) => {
  const layout = useContext(PersonalDetailsContext);
  const counts = layout?.filterOptions?.counts;
  return (
    <div
      className="bg-white border-t accordion-collapse font-medium collapse show"
      id="inventoryaccordion"
      data-te-collapse-item
      data-te-collapse-show
      aria-labelledby="headingOne"
    >
      <div
        className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
        role="tabpanel"
        data-te-tab-active
      >
        <div className="px-5 pt-2">
          <div className="divide-gray-200 divide-y-2">
            <div className="flex flex-nowrap pt-3 -mx-2.5 overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <CheckboxFilter
                title="Total teams"
                trackedLoading={loading}
                count={counts?.total_teams}
              />
              <CheckboxFilter
                title="Total active"
                trackedLoading={loading}
                count={counts?.total_active_teams}
                isCheckbox={true}
                checked={
                  layout?.filterData?.statuses?.length > 0 &&
                  layout?.filterData?.statuses?.find(
                    (status: any) => status?.name === "Active"
                  )
                }
                name="Active"
                handleOnChange={(event: any) => {
                  layout.setFilterData((prevFilterData: any) => {
                    let statuses: any;
                    const selectedStatus = layout.filterOptions.statuses.find(
                      (status: any) => {
                        return status.name === event.target.name;
                      }
                    );
                    if (event.target.checked) {
                      statuses = prevFilterData?.statuses
                        ? [...prevFilterData?.statuses, selectedStatus]
                        : [selectedStatus];
                    } else {
                      statuses = prevFilterData?.statuses?.filter(
                        (status: any) => {
                          return status?.name !== event.target.name;
                        }
                      );
                    }
                    const newFilterData = {
                      ...prevFilterData,
                      statuses,
                      page: 1,
                      per_page: 20,
                    };
                    return newFilterData;
                  });
                }}
              />
              <CheckboxFilter
                title="Total pending"
                trackedLoading={loading}
                count={counts?.total_pending_teams}
                isCheckbox={true}
                checked={
                  layout?.filterData?.statuses?.length > 0 &&
                  layout?.filterData?.statuses?.find(
                    (status: any) => status?.name === "Awaiting Approval"
                  )
                }
                name="Awaiting Approval"
                handleOnChange={(event: any) => {
                  layout.setFilterData((prevFilterData: any) => {
                    let statuses: any;
                    const selectedStatus = layout.filterOptions.statuses.find(
                      (status: any) => {
                        return status.name === event.target.name;
                      }
                    );
                    if (event.target.checked) {
                      statuses = prevFilterData?.statuses
                        ? [...prevFilterData?.statuses, selectedStatus]
                        : [selectedStatus];
                    } else {
                      statuses = prevFilterData?.statuses?.filter(
                        (status: any) => {
                          return status?.name !== event.target.name;
                        }
                      );
                    }
                    const newFilterData = {
                      ...prevFilterData,
                      statuses,
                      page: 1,
                      per_page: 20,
                    };
                    return newFilterData;
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountBoxWrapper;
