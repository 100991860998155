import { useEffect, useState } from "react";
import { handleScroll } from "../helpers/Functions";

export const useScrollLeftEffect = (props: any) => {
  const { elementId, headerId } = props;

  const [isSettled, setIsSettled] = useState(true);

  useEffect(() => {
    if (isSettled) {
      const element = document.getElementById(elementId);
      const stickyHead = document.getElementById(headerId);

      if (element && stickyHead) {
        const scrollHandler = () => handleScroll(element, stickyHead);
        setTimeout(() => {
          scrollHandler();
          setIsSettled(false);
        }, 1000);
      }
    }
  }, [elementId, headerId, isSettled]);

  return { setIsSettled };
};
