/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Virtuoso } from "react-virtuoso";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { KTSVG } from "../../../components/ui/KTSVG";
import { isChildInViewport, scrollToVisible } from "../../../helpers/Functions";
import { NotificationsContext } from "../core/NotificationsProvider";
import { getMarkAsPinned, getMarkAsUnpinned } from "../core/requests";
import NotificationDetail from "../widgets/NotificationDetail";

const NotificationDataTable = ({
  show,
  handleShow,
  paginateData,
  notificationsLoading,
}: any) => {
  const location = useLocation();

  const layout = useContext(NotificationsContext);
  const coreLayout = useContext(LayoutContext);
  const [showNotification, setShowNotification] = useState<any>({
    show: false,
    data: "",
  });
  const [loader, setLoader] = useState<boolean>();

  // HANDLE CHECK
  const handleCheck = (e: any, id: string) => {
    layout.setNotifications((notifications: any) => {
      return notifications.map((notification: any) => {
        if (notification.id === id) {
          let final = {
            ...notification,
            selected: e === true ? true : e?.target?.checked,
          };
          layout.setSelectedNotifications((prev: any) => {
            if (final?.selected) {
              if (prev && prev?.length > 0) {
                return [...prev, id];
              } else {
                return [id];
              }
            } else {
              if (prev && prev.length > 0) {
                return prev.filter((item: any) => item !== id);
              }
              return [];
            }
          });
          return final;
        } else {
          return notification;
        }
      });
    });
  };

  // HANDLE NOTIFICATION VIEW PAGE
  const handleNotificationView = (notification: any, index: number) => {
    setShowNotification({
      show: true,
      data: notification,
      index: index,
    });
  };

  const handlePin = (e: any, id: any) => {
    getMarkAsPinned([id])
      .then((response) => {
        if (response.data.status === true) {
          setLoader(false);
          layout.setRefetchNotification(id);
          layout.setNotifications(
            layout.notifications.map((notification: any) => {
              if (notification.selected) {
                notification.selected = false;
                return {
                  ...notification,
                  is_pinned: true,
                };
              } else {
                return notification;
              }
            })
          );
        }
      })
      .catch((error) => {});
  };

  const handleUnpin = (e: any, id: any) => {
    getMarkAsUnpinned([id])
      .then((response) => {
        if (response.data.status === true) {
          setLoader(false);
          layout.setRefetchNotification(id);
          layout.setNotifications(
            layout.notifications.map((notification: any) => {
              if (notification.selected) {
                notification.selected = false;
                return {
                  ...notification,
                  is_pinned: false,
                };
              } else {
                return notification;
              }
            })
          );
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (location.state?.id) {
      handleNotificationView(location.state, 1);
    }
  }, [location?.state]);

  // SMOOTH SCROLL IN NOTIFICATION-PAGE
  useEffect(() => {
    const isInViewport = isChildInViewport(
      `notification_lists-${showNotification?.index}`
    );
    if (!isInViewport) {
      scrollToVisible(`notification_lists-${showNotification?.index}`);
    }
  }, [showNotification?.index]);

  const ScrollSeekPlaceholder = ({
    height,
    index,
  }: // context: { randomHeights },
  any) => (
    <div
      key={index}
      style={{
        height,
        // width: "710px",
        padding: "8px",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
      className=" abc"
    >
      <div
        className=" shimmer-effect "
        style={{
          // width: "700px!important",
          // height: randomHeights[index % 10],
          height: "50px",
        }}
      ></div>
    </div>
  );

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  return (
    <div
      className="notification_list my-5 font-medium transition-all md:px-5 max-md:!mr-0"
      id="notification_list"
    >
      <div className="inner_notification bg-white !rounded">
        <Virtuoso
          data={layout.notifications}
          endReached={() => {
            if (paginateData?.current_page < paginateData?.last_page) {
              layout?.setNotificationsFilterHandle((current: any) => {
                let nextPage: number = paginateData?.current_page + 1;
                return {
                  ...current,
                  page: nextPage,
                };
              });
            }
          }}
          components={{ ScrollSeekPlaceholder }}
          scrollSeekConfiguration={{
            enter: (velocity) => Math.abs(velocity) > 300,
            exit: (velocity) => {
              const shouldExit = Math.abs(velocity) < 10;
              if (shouldExit) {
                // setVisibleRange(["-", "-"]);
              }
              return shouldExit;
            },
            // change: (_velocity, { startIndex, endIndex }) => setVisibleRange([startIndex, endIndex])
          }}
          useWindowScroll
          overscan={200}
          totalCount={layout.notifications?.length}
          id={"notification-lazyload"}
          itemContent={(index, notification) => {
            let matchedView =
              (showNotification?.show &&
                showNotification?.data &&
                showNotification?.data?.id === notification?.id) ||
              coreLayout.notificationId === notification?.id;
            return (
              <React.Fragment key={index}>
                <div
                  className={`single_notification group flex flex-wrap border-b hover:bg-indigo-500 hover:bg-opacity-[7%] ${
                    matchedView && "bg-indigo-500 bg-opacity-[7%]"
                  } ${notification.is_pinned && "border-y-sky-blue-500"}`}
                  id={`notification_lists-${index}`}
                >
                  <div className=" min-w-[2.5rem] max-w-[2.5rem] border-r p-1 text-center flex items-center justify-center relative cursor-pointer">
                    <input
                      type="checkbox"
                      id={`checkbox-all${index}`}
                      className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500"
                      readOnly
                      checked={notification.selected}
                      onChange={(e: any) => handleCheck(e, notification.id)}
                    />
                    <label
                      htmlFor={`checkbox-all${index}`}
                      className="absolute top-0 left-0 w-full h-full cursor-pointer"
                    ></label>
                  </div>
                  <div className="notification_details flex-1 px-4 py-3">
                    {notification.is_pinned ? (
                      <div
                        className="details flex flex-wrap items-center text-xxs text-sky-blue-500 font-medium mb-1 cursor-pointer"
                        onClick={(e: any) => handleUnpin(e, notification.id)}
                      >
                        <Tooltip
                          anchorId={`record-unpin-tooltip${index}`}
                          content="Mark as Unpinned"
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                        />
                        <span
                          className="flex items-center mr-1"
                          id={`record-unpin-tooltip${index}`}
                        >
                          <KTSVG
                            className="fill-sky-blue-500 h-2.5 w-1.5 transition mr-1.5 block"
                            path="standard_icons/pin-icon.svg"
                          />
                          Pinned
                        </span>
                        <span className="text-sky-blue-500">
                          {notification?.created_at}
                        </span>
                      </div>
                    ) : notification.is_new ? (
                      <div
                        className={
                          "details flex flex-wrap items-center text-xxs  font-medium mb-1 text-green-500 cursor-pointer"
                        }
                        onClick={(e: any) => handlePin(e, notification.id)}
                      >
                        <Tooltip
                          anchorId={`record-pin-tooltip${index}`}
                          content="Mark as Pinned"
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                        />
                        <div
                          className="group-hover:opacity-100 group-hover:visible group-hover:inline-flex opacity-0 invisible hidden overflow-hidden items-center"
                          id={`record-pin-tooltip${index}`}
                        >
                          <KTSVG
                            className="inline-flex fill-sky-blue-500 text-center mr-1"
                            svgClassName="w-1.5 h-2"
                            path="standard_icons/pin-icon.svg"
                          />
                          <span className="pr-1 text-sky-blue-500">
                            Pin To Top
                          </span>
                          <span className="text-gray-500">
                            {notification?.created_at}
                          </span>
                        </div>
                        <div
                          className="flex items-center mr-1 text-green-600 group-hover:hidden"
                          id={`record-pin-tooltip${index}`}
                        >
                          <span className="w-2 h-2 rounded-full bg-green-600 mr-1.5"></span>
                          <span className="px-1 ">New</span>
                          {notification?.created_at}
                        </div>
                        {/* <div className="text-white" dangerouslySetInnerHTML={{
                                      __html: notification.text
                                    }}>
                                    </div> */}
                      </div>
                    ) : notification.is_pinned || notification.is_new ? (
                      <div
                        className={
                          "details flex flex-wrap items-center text-xxs  font-medium mb-1 text-gray-500 cursor-pointer"
                        }
                        onClick={(e: any) => handlePin(e, notification.id)}
                      >
                        <Tooltip
                          anchorId={`record-pin-tooltip${index}`}
                          content="Mark as Pinned"
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                        />
                        <span className="flex items-center mr-1"></span>
                        <span id={`record-pin-tooltip${index}`}>
                          {notification?.created_at}
                        </span>
                      </div>
                    ) : (
                      <div
                        className={
                          "details flex flex-wrap items-center text-xxs  font-medium mb-1 text-sky-blue-500 cursor-pointer"
                        }
                        onClick={(e: any) => handlePin(e, notification.id)}
                      >
                        <div className="group-hover:opacity-100 group-hover:visible group-hover:inline-block opacity-0 invisible hidden overflow-hidden">
                          <Tooltip
                            anchorId={`record-pin-tooltip${index}`}
                            content="Mark as Pinned"
                            place="top"
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                          />
                          <span
                            className="flex items-center mr-1"
                            id={`record-pin-tooltip${index}`}
                          >
                            <KTSVG
                              className="fill-sky-blue-500 h-2.5 w-1.5 transition mr-1.5 block"
                              path="standard_icons/pin-icon.svg"
                            />
                            Pin to Top
                          </span>
                        </div>
                        <span
                          id={`record-pin-tooltip${index}`}
                          className="text-gray-500"
                        >
                          {notification?.created_at}
                        </span>
                      </div>
                    )}
                    <div className="not_content font-medium text-sm12">
                      <h5
                        dangerouslySetInnerHTML={{
                          __html: notification.text,
                        }}
                      ></h5>
                    </div>
                  </div>
                  <div
                    className={`not_eye flex items-center justify-center border-l ${
                      matchedView && ""
                    }`}
                  >
                    <Tooltip
                      anchorId={`record-view-tooltip${index}`}
                      content="View"
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                    />
                    <button
                      className={`p-1 md:p-1 w-10`}
                      type="button"
                      id={`record-view-tooltip${index}`}
                      onClick={() => {
                        handleNotificationView(notification, index);
                      }}
                    >
                      <KTSVG
                        className={`flex items-center justify-center ${
                          matchedView ? "fill-indigo-500" : "fill-violet-500"
                        } ${
                          matchedView ? "" : "hover:fill-indigo-500"
                        } transition`}
                        path="other_icons/Eye.svg"
                        svgClassName="w-4 h-[.6875rem]"
                      />
                    </button>
                  </div>
                </div>
              </React.Fragment>
            );
          }}
        />
        <div
          id="custom-loader"
          className={`accordion bg-white font-medium pt-2.5  border `}
        >
          <div
            className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
            style={{ height: "40px" }}
          ></div>
          <div
            className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
            style={{ height: "40px" }}
          ></div>
          <div
            className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
            style={{ height: "40px" }}
          ></div>
        </div>
        {/* {notificationsLoading && (
          <div
            className={"w-full bg-white flex justify-center items-center"}
            style={{ height: "40px" }}
          >
            <IKTSVG
              className="flex items-center justify-center fill-violet-500 transition animate-spin"
              path={ProcessSVG}
            />
          </div>
        )} */}
      </div>

      {showNotification?.show && (
        <NotificationDetail
          show={showNotification}
          handleShow={(data: any) => setShowNotification(data)}
        />
      )}
    </div>
  );
};

export default NotificationDataTable;
