import { KTSVG } from "../components/ui/KTSVG";

const Loader = ({ position, size, color, border }: any) => {
  return (
    <div className="flex items-center justify-center p-5  fixed left-0 right-0 top-0 bottom-0 m-auto w-full h-full bg-white bg-opacity-50  z-[1045]">
      <KTSVG
        className="flex items-center justify-center fill-violet-500 transition animate-spin"
        path="other_icons/process.svg"
      />
    </div>
  );
};

export default Loader;
