import { useFormik } from "formik";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import TextInput from "../../formComponent/TextInput";
import { KTSVG } from "../../ui/KTSVG";
import { resetPassword } from "./core/_request";

// VALIDATION SCHEMA
const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .label("Password")
    .min(8)
    .max(50)
    .required("Password is required"),
  password_confirmation: Yup.string()
    .required("Password confirmation is required")
    .when("password", {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password and Confirm Password didn't match"
      ),
    }),
  apiError: Yup.string(),
});

const ResetPassword = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const { token } = useParams();
  const navigate = useNavigate();

  // INITIAl CREDENTIALS
  const initialValues = {
    password: "",
    password_confirmation: "",
    apiError: "",
  };

  // SUBMIT LOGIN API
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values: any) => {
      setLoader(true);
      resetPassword({ ...values, token })
        .then((response: any) => {
          if (response?.data?.status) {
            // showAlert(response?.message, false, 2500);
            setTimeout(() => {
              setLoader(false);
              navigate("/login");
            }, 2500);
          } else {
            // showAlert(response?.errors?.title, true, 2500);
            response?.errors?.title &&
              formik.setFieldError("apiError", response?.errors?.title);
            setLoader(false);
          }
        })
        .catch((error: any) => {
          // showAlert(
          //   error?.response?.data?.errors?.title
          //     ? error?.response?.data?.errors?.title
          //     : "Failed to login",
          //   true
          // );
          error?.response?.data?.errors?.title &&
            formik.setFieldError(
              "apiError",
              error?.response?.data?.errors?.title
            );

          setLoader(false);
        });
    },
  });
  const handleClick = () => {
    navigate("../", { replace: true });
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="login_page_main relative flex items-center justify-center min-h-screen bg-violet-600 bg-no-repeat bg-tix-pattern bg-cover bg-top  md:pt-[7.5rem] md:pb-[7.5rem] py-24">
          <div
            className="tix_logo absolute left-0 right-0 m-auto lg2:top-20 md:top-16 top-7"
            onClick={handleClick}
          >
            <KTSVG
              className="min-w-[1.125rem] flex items-center justify-center fill-white transition"
              path="tix_logo.svg"
              svgClassName="max-w-[12.75rem] md:max-w-[15.25rem] "
            />
          </div>
          <div className="login_inner max-w-[73.75rem] flex flex-wrap w-full mx-5 rounded-[.5rem] bg-indigo-500 bg-opacity-[25%]">
            <div className="login_panel md:w-1/2 w-full bg-white px-5 md:py-[3.75rem] py-7 text-center md:rounded-r rounded">
              <div className="max-w-[23.75rem] m-auto">
                <h2 className="md:text-3xl text-2xl font-artex_reg md:mb-6 mb-4 leading-[1.3]">
                  Password Reset Request
                </h2>
                <>
                  {/* RESET PASSWORD FORM */}
                  <div className="form_login new-inp">
                    <div className="w-full mb-5 relative">
                      <TextInput
                        name="password"
                        type="password"
                        required={false}
                        value={formik.values.password}
                        errorClass={
                          formik.touched.password &&
                          formik.errors.password &&
                          "border-rose-500"
                        }
                        label="New Password"
                        id="password"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("password", "")
                        }
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className="text-xs max-md:text-xxs text-rose-500 italic error-msg">
                          {formik.errors.password as string}
                        </div>
                      )}
                    </div>
                    <div className="w-full mb-5 relative">
                      <TextInput
                        name="password_confirmation"
                        type="password"
                        label="Confirm Password"
                        required={false}
                        value={formik.values.password_confirmation}
                        errorClass={
                          formik.touched.password_confirmation &&
                          formik.errors.password_confirmation &&
                          "border-rose-500"
                        }
                        id="password_confirmation"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("password_confirmation", "")
                        }
                      />
                      {formik.touched.password_confirmation &&
                        formik.errors.password_confirmation && (
                          <div className="text-xs max-md:text-xxs text-rose-500 italic error-msg">
                            {formik.errors.password_confirmation as string}
                          </div>
                        )}
                    </div>

                    {formik.errors.apiError && (
                      <div className="text-sm max-md:text-xxs text-rose-500 mb-4 text-center">
                        {formik.errors.apiError as string}
                      </div>
                    )}
                    <button
                      type="submit"
                      className={`${
                        loader && "pointer-events-none loading"
                      } loader-stripe text-sm18 font-artex_reg px-3 py-2.5 w-full bg-indigo-500 rounded hover:bg-green-600 text-white transition mt-2.5`}
                    >
                      {loader ? "Loading..." : "Submit"}
                    </button>
                  </div>
                  <span className="mt-5 block leading-5">
                    <Link
                      to={"/login"}
                      className="text-indigo-500 hover:text-violet-500 text-sm15"
                    >
                      Back to login
                    </Link>
                  </span>
                </>
              </div>
            </div>
            <div className="create_account md:w-1/2 w-full flex items-center justify-center px-5 md:py-[3.75rem] py-7 ">
              <div className="inner_create max-w-[23.75rem] m-auto text-center text-white">
                <h5 className="md:text-3xl text-2xl font-artex_reg md:mb-6 mb-4 leading-[1.3]">
                  Don’t have an account?
                </h5>
                <p className="md:text-sm15 text-sm14 md:leading-6 leading-5 font-basier mb-7">
                  Buyer or seller, Tixstock empowers you to offer access to the
                  world’s most in-demand events.
                </p>
                <button
                  type="button"
                  className=" font-artex_reg  rounded px-5 py-2.5 hover:bg-indigo-500 transition w-full text-sm18  outline-1 outline-white outline hover:outline-indigo-500"
                  onClick={() => (window.location.href = "/sign-up")}
                >
                  Create an account
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
