import { LayoutContext } from "components/core/LayoutProvider";
import React, { useContext, useState } from "react";
import infoIcon from "../../assets/media/icons/other_icons/info.svg";
import PriceField from "../../components/formComponent/PriceField";
import {
  decimalNumber,
  isValidPriceFieldNumber,
} from "../../helpers/Functions";
import TooltipPortal from "../../pages/tx_trade/table/component/TooltipPortal";
import { IKTSVG } from "../ui/IKTSVG";

interface interfacePPE {
  id: string;
  name: string;
  value: string;
  label: string;
  errors?: any;
  isTooltip?: boolean;
  isPPEActive?: boolean;
  isMatchedValue?: boolean;
  className?: string;
  tooltipContent?: string;
  currencySymbol?: any;
  handleOnChange?: any;
  ListPPE?: boolean;
  tooltipWidth?: string;
  disabled?: boolean;
  txNetwork?: boolean;
  errorClass?: any;
  errorMessage?: any;
  // isMobile?: boolean;
  edited?: boolean;
  reff?: any;
  isPriceField?: any;
  fieldMessage?: any;
  isValidationPopupVisible?: any;
  indexPlusLength?: any;
  avgPrice?: any;
}

const PPEComponent: React.FC<interfacePPE> = ({
  id,
  name,
  value,
  label,
  errors,
  isTooltip = false,
  isPPEActive = true,
  isMatchedValue = false,
  className = "",
  tooltipContent = "",
  currencySymbol,
  ListPPE = false,
  handleOnChange = () => {},
  tooltipWidth = "max-w-[12rem]",
  disabled = false,
  txNetwork = false,

  // isMobile = false,

  errorClass = "",
  errorMessage = "",
  edited = false,
  isPriceField = true,
  fieldMessage = "",
  isValidationPopupVisible = false,
  indexPlusLength = "",
  avgPrice = "",
}) => {
  const [focused, setFocused] = useState(false);

  let classes: string = "";
  const coreLayout = useContext(LayoutContext);

  // if (!disabled) {
  //   if (txNetwork) {
  //     classes = "bg-opacity-100 border-gray-500";
  //   } else if (isPPEActive || focused) {
  //     classes = "border-indigo-500";
  //   }
  // } else {
  //   classes = "bg-opacity-50";
  // }

  //USE edited variable for highlight edited field in bulk update

  const isMobile = coreLayout.isMobile;

  return (
    <div
      className={`singleMKTPrice max-w-[11.75rem] border p-[.4375rem] rounded bg-white bg-opacity-50 ${
        !isPPEActive || disabled ? "disable" : ""
      } ${classes}  ${
        isPPEActive && errors
          ? "border-rose-500"
          : className
          ? className
          : isMatchedValue || focused
          ? "border-indigo-500 bg-opacity-100"
          : ""
      } ${ListPPE ? "min-w-[7.875rem] pt-[.1875rem]" : "min-w-[11.75rem]"} ${
        label === "TX Network" && !errors && isPPEActive && "border-gray-500"
      }  ${edited && "edited"}`}
    >
      <div
        className={`title flex items-center text-sm12 leading-[.9375rem] mb-2 ${
          ListPPE ? "mb-[.3125rem]" : "mb-2"
        }`}
      >
        {label}
        {isTooltip && (
          <>
            <div data-tooltip-id={`ppefield-tooltip-${id}`}>
              <IKTSVG
                id={`ppefield-tooltip-${id}`}
                path={infoIcon}
                className={`ml-1 fill-gray-400 block cursor-pointer 000`}
                svgClassName={` hover:fill-indigo-500 w-3.5 h-3.5 fill-gray-400`}
              />
            </div>
            <TooltipPortal
              id={`ppefield-tooltip-${id}`}
              content={tooltipContent}
              place="top"
              variant="light"
              className={`text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium  text-xs z-[999999999] ${tooltipWidth} cursor-default`}
            />
          </>
        )}
      </div>

      <div
        className={`priceFieldPPE proceed-price ${
          !isPPEActive || disabled ? "disable" : ""
        } `}
      >
        <PriceField
          name={name}
          value={value}
          currenncySymbol={currencySymbol}
          handleOnChange={(data: any) => {
            const newData = {
              ...data,
              ...(!txNetwork && { id: id }),
              ...(!txNetwork && { parentName: "ppePartners" }),
            };
            handleOnChange(newData);
          }}
          handleOnFocus={() => {
            setFocused(true);
          }}
          className={`${edited ? "bg-slate-500" : "bg-white"}`}
          textfieldType={isMobile ? "number" : "text"}
          handleOnBlur={(data: any) => {
            if (!isMobile) {
              setFocused(false);
              const newData = {
                ...data,
                target: {
                  ...data.target,
                  value: {
                    ...data.target.value,
                    value: isValidPriceFieldNumber(data.target.value.value)
                      ? decimalNumber(data.target.value.value)
                      : decimalNumber(0),
                  },
                },
                ...(!txNetwork && { id: id }),
                ...(!txNetwork && { parentName: "ppePartners" }),
              };
              handleOnChange(newData);
            }
          }}
          errorClass={errorClass}
          errorMessage={errorMessage}
          errorEventIndex={id}
          isPriceField={
            focused
              ? {
                  ...isPriceField,
                  isFocused: true,
                }
              : false
          }
          fieldMessage={fieldMessage}
          isValidationPopupVisible={isValidationPopupVisible}
          indexPlusLength={indexPlusLength}
          avgPrice={avgPrice}
        />
      </div>
    </div>
  );
};

export default PPEComponent;
