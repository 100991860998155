import { ClearButton } from "components/ui/buttons/ClearButton";
import { IKTSVG } from "components/ui/IKTSVG";
import { size } from "lodash";
import {
  initialContentState,
  initialRecordFilters,
} from "pages/customize/core/_.model";
import { CUSTOMIZE_TAB_KEYS } from "pages/customize/core/constants/constants";
import { TabData } from "pages/customize/hooks/TabData";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import { useMemo } from "react";
import { Tooltip } from "react-tooltip";
import { default as refreshSVG } from "../../../../assets/media/icons/other_icons/refresh.svg";

const FilterChips = ({ setSearchValue }: any) => {
  const { stateReturn, isTabActive } = TabData();

  const {
    records,
    recordFilter,
    contentList,
    contentFilter,
    setContentFilter,
    setRecordFilter,
    contentCount,
    // setInputChange,
  } = useCustomize();

  let shimmerLoader = false;

  const handleRefresh = () => {
    switch (isTabActive) {
      case CUSTOMIZE_TAB_KEYS.DESIGN_MENU:
        setRecordFilter(initialRecordFilters);
        setSearchValue("");
        return;
      case CUSTOMIZE_TAB_KEYS.CONTENT_PAGE:
        setContentFilter(initialContentState);
        // setInputChange("");
        return;

      default:
        return;
    }
  };
  const totalResults = useMemo(() => {
    switch (isTabActive) {
      case CUSTOMIZE_TAB_KEYS.DESIGN_MENU:
        return `${records.length} result${records.length === 1 ? "" : "s"}`;
      case CUSTOMIZE_TAB_KEYS.CONTENT_PAGE:
        return `${contentCount?.total} result${
          contentCount?.total === 1 ? "" : "s"
        }`;

      default:
        return "0 result";
    }
  }, [isTabActive, records, contentList]);
  return (
    <div
      className="flex flex-wrap customStyle relative bg-white  max-sm:justify-between"
      id={`salesFilter`}
    >
      <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
        {totalResults}
      </div>

      <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
        {shimmerLoader ? (
          <div className="flex gap-5">
            <div
              className={`flex !rounded overflow-hidden ${
                shimmerLoader && "shimmer-effect"
              }`}
              style={{ height: "1.625rem", width: "9.375rem" }}
            >
              &nbsp;
            </div>
          </div>
        ) : (
          (isTabActive === CUSTOMIZE_TAB_KEYS.DESIGN_MENU ||
            isTabActive === CUSTOMIZE_TAB_KEYS.CONTENT_PAGE) &&
          (recordFilter?.keyword ||
            size(contentFilter?.eventName) > 0 ||
            contentFilter?.title) && (
            <button
              type="button"
              className={`group border text-center bg-white rounded w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition ${
                shimmerLoader && "shimmer-effect"
              }`}
              onClick={handleRefresh}
              title="Reset"
              id="reset"
            >
              <Tooltip
                anchorId={`reset`}
                content={`Reset`}
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
              />
              <IKTSVG
                path={refreshSVG}
                className="fill-violet-500 group-hover:fill-white transition"
                svgClassName="mx-[.313rem] w-3 h-3"
              />
            </button>
          )
        )}

        {/* DESIGN MENU */}
        {isTabActive === CUSTOMIZE_TAB_KEYS.DESIGN_MENU &&
          recordFilter?.keyword && (
            <ClearButton
              name={recordFilter?.keyword}
              isLoading={false}
              onClick={() => {
                setRecordFilter(initialRecordFilters);
                setSearchValue("");
              }}
            />
          )}

        {/* CONTENT PAGE */}
        {isTabActive === CUSTOMIZE_TAB_KEYS.CONTENT_PAGE &&
          contentFilter?.eventName &&
          size(contentFilter?.eventName) > 0 &&
          contentFilter?.eventName?.map((item: any, index: number) => {
            return (
              <ClearButton
                name={item?.name}
                isLoading={false}
                onClick={() => {
                  setContentFilter((current: any) => {
                    return {
                      ...current,
                      eventName: current?.eventName?.filter(
                        (eventName: any, eventIndex: number) =>
                          eventIndex !== index
                      ),
                    };
                  });
                  // setInputChange("");
                }}
              />
            );
          })}

        {/* CONTENT PAGE */}
        {isTabActive === CUSTOMIZE_TAB_KEYS.CONTENT_PAGE &&
          contentFilter?.title && (
            <ClearButton
              name={contentFilter?.title}
              isLoading={false}
              onClick={() => {
                setContentFilter((pre: any) => {
                  return {
                    ...pre,
                    title: "",
                  };
                });
                // setInputChange("");
              }}
            />
          )}
      </div>
    </div>
  );
};

export default FilterChips;
