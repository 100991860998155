import React from "react";
import SVG from "react-inlinesvg";
import { LoadImages } from "../../helpers/AssetHelpers";
type Props = {
  className?: string;
  path: string;
  svgClassName?: string;
  fill?: string;
  id?: string;
};

const KTSVG: React.FC<Props> = ({
  className = "",
  path,
  svgClassName = "",
  fill,
  id = "",
}: any) => {
  return (
    <span className={`${className}`}>
      <SVG
        cacheRequests={true}
        src={LoadImages(path) as string}
        className={svgClassName}
        fill={fill}
      />
    </span>
  );
};

export { KTSVG };
