export const DownAngleIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="4"
    viewBox="0 0 6 4"
    {...props}
  >
    <path
      id="Path_14"
      data-name="Path 14"
      d="M24,136h6l-3,4Z"
      transform="translate(-24 -136)"
    />
  </svg>
);
