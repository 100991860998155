const GeoIcon = (props: any) => {
  return (
    <svg
      className="min-w-[0.69rem] mr-1.5"
      xmlns="http://www.w3.org/2000/svg"
      width="12.5"
      height="12.5"
      viewBox="0 0 12.5 12.5"
      {...props}
    >
      <path
        d="M8.25,13.25a5,5,0,1,1,5-5,5,5,0,0,1-5,5M8.25,2A6.25,6.25,0,1,0,14.5,8.25,6.25,6.25,0,0,0,8.25,2m0,6.336A.938.938,0,1,1,9.188,7.4a.938.938,0,0,1-.937.938m0-3.313A2.374,2.374,0,0,0,5.875,7.4c0,1.875,2.375,4.063,2.375,4.063S10.625,9.273,10.625,7.4A2.374,2.374,0,0,0,8.25,5.023Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export default GeoIcon;
