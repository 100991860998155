import React, { useContext, useRef, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { SalesContext } from "../../../core/SalesProvider";
import TableRow from "../../../table/component/TableRow";
import HeaderCell from "./HeaderCell";
import ItemContentReplacement from "./ItemContentReplacement";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left bg-white"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 border_th"
    style={{
      ...style,
    }}
  />
);

const OrderNewTable = ({ listing, paginateData }: any) => {
  const layout = useContext(SalesContext);

  const tableRef: any = useRef();
  const [hoveredId, setHoveredId] = useState(null);

  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    // console.log(scrollOffset);
    // console.log(tableRef.current?.scrollLeft);
  };

  return (
    <React.Fragment>
      <div className="accordion mt-0 font-medium transition-all relative  flex-1 h-full ">
        <div className="accordion-item  !rounded overflow-hidden h-full">
          <div
            id="EventCollapseBody"
            className="accordion-collapse collapse show h-full"
            aria-labelledby="EventCollapseBody"
          >
            <div className="accordion-body relative h-full">
              <div
                className={`order-replacement-table  overflow-auto scrollbar-thin scrollbar-thumb-violet-800
                 scrollbar-thumb-rounded-full hover-style2 md:!h-full `}
                ref={tableRef}
                // onScroll={handleScrollVirtuoso}
                // style={{
                //   height: height > 41 ? height : "auto",
                // }}
                style={{
                  // height: "50vh",
                  // @media(max-width:767px){
                  height: listing?.length * 40 + 40,
                  // }
                }}
                id={`tableId1`}
              >
                <TableVirtuoso
                  data={listing}
                  overscan={200}
                  totalCount={listing?.length}
                  id={"sales-repalcement-lazyload"}
                  endReached={() => {
                    if (paginateData?.current_page < paginateData?.last_page) {
                      layout.setOrderNewFilterHandle((current: any) => {
                        let nextPage: number = paginateData?.current_page + 1;
                        return {
                          ...current,
                          page: nextPage,
                          per_page: 10,
                        };
                      });
                    }
                  }}
                  components={{
                    Table: Table,
                    TableHead: TableHead,
                    TableRow: (props: any) => {
                      return (
                        <TableRow
                          {...props}
                          hoveredId={hoveredId}
                          setHoveredId={setHoveredId}
                          isGodAdmin={true}
                        />
                      );
                    },
                    TableFoot: (props) => {
                      if (
                        listing?.length === paginateData?.total &&
                        paginateData?.current_page > 1
                      ) {
                        return (
                          <tfoot
                            className={` text-center text-sm13 py-3 px-2.5 bg-white  border-t`}
                            id={`NoMoreListing`}
                          >
                            <tr className="border-b bg-white">
                              <td
                                colSpan={11}
                                align="center"
                                className="h-[2.8125rem]"
                              >
                                No more items to show
                              </td>
                            </tr>
                          </tfoot>
                        );
                      } else if (paginateData?.current_page > 1) {
                        return (
                          <tfoot
                            id="custom-loader-replacement"
                            className={`accordion bg-white font-medium pt-2.5  border relative z-[1] w-full `}
                          >
                            <tr className="mb-2.5">
                              <td colSpan={11}>
                                <div
                                  id="custom-loader-replacement"
                                  className={`accordion bg-white font-medium pt-2.5  border relative z-[1] `}
                                >
                                  <div
                                    className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
                                  ></div>
                                  <div
                                    className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
                                  ></div>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        );
                      } else {
                        return null;
                      }
                    },
                  }}
                  fixedHeaderContent={() => (
                    <tr className=" bg-white">
                      <HeaderCell
                        title="Category"
                        width="min-w-[11.5rem] pl-5 w-[11.5rem]"
                        arrow={true}
                        postKey="zone_label"
                      />

                      <HeaderCell
                        title="Section/block"
                        width="min-w-[10.625rem] w-[10.625rem]"
                        arrow={true}
                        postKey="section_label"
                      />
                      <HeaderCell
                        title="Row"
                        width="min-w-[3.75rem] w-[3.75rem]"
                        arrow={true}
                        postKey="row"
                      />
                      <HeaderCell
                        title="Seat"
                        width="min-w-[3.75rem] w-[3.75rem]"
                        arrow={true}
                        postKey="first_seat"
                      />
                      <HeaderCell
                        title="Ticket type"
                        width="min-w-[6.875rem] w-[6.875rem]"
                        arrow={true}
                        postKey="ticket_type"
                      />
                      <HeaderCell
                        title="Qty"
                        width="min-w-[3.75rem] w-[3.75rem]"
                        arrow={true}
                        postKey="quantity_available"
                      />
                      <HeaderCell
                        title="Ticket price"
                        width="min-w-[7.5rem] w-[7.5rem]"
                        arrow={true}
                        postKey="sell_price"
                      />
                      <HeaderCell
                        title="Split type"
                        width="min-w-[7.5rem] w-[7.5rem]"
                        arrow={true}
                        postKey="split_type"
                      />
                      <HeaderCell
                        title="Max display ..."
                        width="min-w-[6.875rem] w-[6.875rem]"
                        arrow={true}
                        postKey="quantity_display"
                      />
                      <HeaderCell
                        title="Team/broker"
                        width="min-w-[9.375rem]"
                        arrow={false}
                        postKey="user_name"
                      />

                      <th className="md:sticky relative right-0 ml-auto  p-0 min-w-[7.625rem] w-[7.625rem]">
                        {/* <div
                          className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
                          id="left-shad-list"
                        >
                          <button
                            className="group rounded-l bg-gray-100 hover:bg-indigo-500 transition px-[.5625rem] py-2"
                            type="button"
                            onClick={() => scroll(-120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-white"
                              path={LeftSVG}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                          <button
                            className="group rounded-r bg-gray-100 hover:bg-indigo-500 transition px-[.5625rem] py-2"
                            type="button"
                            onClick={() => scroll(120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-white"
                              path={RightSVG}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                        </div> */}
                      </th>
                    </tr>
                  )}
                  fixedFooterContent={() => {
                    return (
                      <tr className="border-b bg-white">
                        <td colSpan={0}>Hi</td>
                      </tr>
                    );
                  }}
                  itemContent={(sIndex: any, list: any) => {
                    return (
                      <React.Fragment key={sIndex}>
                        <ItemContentReplacement index={sIndex} list={list} />
                      </React.Fragment>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderNewTable;
